import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  ChunkSettings,
  FileRestrictions,
  SelectEvent,
  UploadComponent,
  UploadEvent,
} from "@progress/kendo-angular-upload";
import {
  FileInfo,
  SelectedEventArgs,
  UploadingEventArgs,
} from "@syncfusion/ej2-angular-inputs";
import {
  createElement,
  detach,
  EventHandler,
  isNullOrUndefined,
} from "@syncfusion/ej2-base";
import { AuthGuard } from "app/common/authGuard/auth.guard";
import { Helper } from "app/common/utility/helper";
import { WorksheetFileData } from "app/model/WorksheetFile";
import { BaThemeSpinner } from "app/theme/services";
import { environment } from "environments/environment";
import heic2any from "heic2any";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "file-add",
  templateUrl: "./AddFile.html",
  styleUrls: ["./AddFile.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AddFilesComponent implements OnInit {
  @ViewChild("uploader", { static: false }) uploader: UploadComponent;

  @Input() type: string;
  @Input() jobId: any;
  @Input() vendorId: number;
  @Input() filetype: any;
  @Input() jobPhaseId: any;
  @Input() phasephotoFlag: any;
  @Input() dropAreaId: string;
  @Input() allowedExtensions: string[] = [];
  @Input() maxFileSize: number = 0;
  @Input() adjustHeight: boolean = true;
  @Input() allowMultiple: boolean = true;
  @Input() invalidFileTypeMessage: string;
  @Input() worksheetFileData: WorksheetFileData;
  @Input() changeOrderId: number;
  @Output() uploadCompleted = new EventEmitter<string[]>();
  @Output() FileSelected = new EventEmitter<any>();
  @Input() productId: number = 0;
  uploadCount: number = 0;
  guids: string[] = [];
  dropText: string = "";
  public dropElement: HTMLElement = null;
  public filesDetails: FileInfo[] = [];
  public filesName: string[] = [];
  public filesList: HTMLElement[] = [];
  public uploadWrapper: HTMLElement;
  public parentElement: HTMLElement;
  public Buttons: Object = {
    browse: "Browse",
    clear: "Clear",
    upload: "Upload",
  };

  uploadSaveUrl: string;
  uploadRemoveUrl: string;

  public chunkSettings: ChunkSettings = {
    size: 4 * 1024 * 1024, // 4MB
  };

  readonly myRestrictions: FileRestrictions = {
    maxFileSize: 52428800, // 50 MB
  };

  constructor(
    private authGuard: AuthGuard,
    private spinner: BaThemeSpinner,
    protected toastrService: ToastrService
  ) {}

  ngOnInit() {
    if (this.allowedExtensions.length > 0) {
      this.myRestrictions.allowedExtensions = this.allowedExtensions;
    }
    this.dropText = this.buildText();
    if (!!this.dropAreaId) {
      this.dropElement = document.getElementById(this.dropAreaId);
    }
    if (!this.dropElement) {
      this.dropElement = document.getElementsByClassName(
        "control-section"
      )[0] as HTMLElement;
    }
  }

  onSelect(event: SelectedEventArgs) {
    event.cancel = true;
    if (!this.allowMultiple && this.filesDetails.length) {
      this.toastrService.error(
        "Only 1 file is allowed",
        "Error Selecting File"
      );
      return;
    }
    this.updateSelectedFiles(event);
  }
  async updateSelectedFiles(event: SelectedEventArgs) {
    if (!this.dropElement.querySelector("li")) {
      this.filesDetails = [];
    }
    if (
      isNullOrUndefined(
        document.getElementById("dropArea").querySelector(".e-upload-files")
      )
    ) {
      this.parentElement = createElement("ul", { className: "e-upload-files" });
      document
        .getElementsByClassName("e-upload")[0]
        .appendChild(this.parentElement);
    }
    let validFiles: FileInfo[] = await this.validateFiles(
      event,
      this.filesDetails
    );
    if (validFiles.length === 0) {
      return;
    }
    for (let i: number = 0; i < validFiles.length; i++) {
      this.formSelectedData(validFiles[i], this);
    }
    this.filesDetails = this.filesDetails.concat(validFiles);
    this.FileSelected.emit(this.filesDetails);
  }
  onUploadSuccess(guids: string[]) {
    this.uploadCount++;
    if (this.filesDetails.length <= this.uploadCount) {
      this.uploadCompleted.emit(guids);
    }
  }

  public onUploading(args: UploadingEventArgs) {
    const token = this.authGuard.getToken();
    const guid = Helper.uuidv4();
    this.guids.push(guid);
    if (token) {
      args.currentRequest.setRequestHeader("Authorization", token);
      args.currentRequest.setRequestHeader("mimeType", "multipart/form-data");
      args.currentRequest.setRequestHeader("AppName", "MPB-Web");
      args.currentRequest.setRequestHeader("guids", guid);
    }
  }
  public async validateFiles(
    args: any,
    viewedFiles: FileInfo[]
  ): Promise<FileInfo[]> {
    let modifiedFiles: FileInfo[] = [];
    let validFiles: FileInfo[] = [];
    let isModified: boolean = false;
    if (args.event.type == "drop" || args.event.type == "change") {
      isModified = true;
      let hasFileExceedMaxSize = false;
      let hasInvalidFileType = false;
      let files: FileInfo[] = args.filesData;

      for (let file of files) {
        if (this.maxFileSize > 0 && file.size > this.maxFileSize) {
          hasFileExceedMaxSize = true;
        } else if (
          this.allowedExtensions.length > 0 &&
          this.allowedExtensions.indexOf(file.type.toLowerCase()) == -1
        ) {
          hasInvalidFileType = true;
        } else {
          modifiedFiles.push(file);
        }
      }
      if (hasFileExceedMaxSize) {
        this.toastrService.error(
          `The File size exceeds the size limit. Maximum allowed File size is ${
            this.maxFileSize / 1000000
          } MB.`,
          "Error Uploading File"
        );
      }
      if (hasInvalidFileType) {
        this.toastrService.error(
          this.invalidFileTypeMessage || "Only picture files are supported.",
          "Error uploading File"
        );
      }
    }
    let files: FileInfo[] =
      modifiedFiles.length > 0 || isModified ? modifiedFiles : args.filesData;
    if (this.filesName.length > 0) {
      for (let file of files) {
        if (this.filesName.indexOf(file.name) === -1) {
          this.filesName.push(file.name);
          validFiles.push(file);
        }
      }
    } else {
      for (let file of files) {
        this.filesName.push(file.name);
        validFiles.push(file);
      }
    }
    return validFiles;
  }
  public formSelectedData(file: FileInfo, proxy: any): void {
    if (this.type != "image") {
      let liEle: HTMLElement = createElement("li", {
        className: "e-upload-file-list filesuploadblk",
        attrs: { "data-file-name": file.name },
      });
      let imageTag: HTMLImageElement = <HTMLImageElement>(
        createElement("i", { className: "upload-File fa fa-file-archive-o" })
      );
      let wrapper: HTMLElement = createElement("span", {
        className: "wrapper ",
      });
      wrapper.appendChild(imageTag);
      liEle.appendChild(wrapper);
      liEle.appendChild(
        createElement("div", {
          className: "name file-name",
          innerHTML: file.name,
          attrs: { title: file.name },
        })
      );
      liEle.appendChild(
        createElement("div", {
          className: "file-size",
          innerHTML: proxy.uploadObj.bytesToSize(file.size),
        })
      );
      let clearbtn: HTMLElement;
      clearbtn = createElement("span", {
        id: "removeIcon",
        className: "e-icons e-file-remove-btn",
        attrs: { title: "Remove" },
      });
      EventHandler.add(clearbtn, "click", this.removeFiles, proxy);
      liEle.setAttribute("title", "Ready to Upload");
      let progressbarContainer: HTMLElement;
      progressbarContainer = createElement("progress", {
        className: "progressbar",
        id: "progressBar",
        styles: "border: 4px solid #148804;height:0.5rem;border-radius:2rem",
        attrs: { value: "0", max: "100" },
      });
      liEle.appendChild(clearbtn);
      liEle.appendChild(progressbarContainer);
      this.readURL(liEle, file);
      document.querySelector(".e-upload-files").appendChild(liEle);
      proxy.filesList.push(liEle);
    } else {
      let liEle: HTMLElement = createElement("li", {
        className: "e-upload-file-list__photo",
        attrs: { "data-file-name": file.name },
      });
      let imageTag: HTMLImageElement = <HTMLImageElement>createElement("IMG", {
        className: "upload-image",
        attrs: { alt: "Image" },
      });
      let wrapper: HTMLElement = createElement("span", {
        className: "wrapper",
      });
      wrapper.appendChild(imageTag);
      liEle.appendChild(wrapper);
      liEle.appendChild(
        createElement("div", {
          className: "name file-name",
          innerHTML: file.name,
          attrs: { title: file.name },
        })
      );
      liEle.appendChild(
        createElement("div", {
          className: "file-size",
          innerHTML: proxy.uploadObj.bytesToSize(file.size),
        })
      );
      let clearbtn: HTMLElement;
      clearbtn = createElement("span", {
        id: "removeIcon",
        className: "e-icons e-file-remove-btn",
        attrs: { title: "Remove" },
      });
      EventHandler.add(clearbtn, "click", this.removeFiles, proxy);
      liEle.setAttribute("title", "Ready to Upload");
      let progressbarContainer: HTMLElement;
      progressbarContainer = createElement("progress", {
        className: "progressbar",
        id: "progressBar",
        styles: "border: 4px solid #148804;height:0.5rem;border-radius:2rem",
        attrs: { value: "0", max: "100" },
      });
      liEle.appendChild(clearbtn);
      liEle.appendChild(progressbarContainer);
      this.readURL(liEle, file);
      document.querySelector(".e-upload-files").appendChild(liEle);
      proxy.filesList.push(liEle);
    }
  }

  public removeFiles(args: any): void {
    let removeFile: FileInfo =
      this.filesDetails[
        this.filesList.indexOf(args.currentTarget.parentElement)
      ];
    let statusCode: string = removeFile.statusCode;
    if (statusCode === "2" || statusCode === "1") {
      this.uploader.removeFilesByUid(args.currentTarget.parentElement.id);
    }
    let index: number = this.filesList.indexOf(
      args.currentTarget.parentElement
    );
    this.filesList.splice(index, 1);
    this.filesDetails.splice(index, 1);
    this.filesName.splice(this.filesName.indexOf(removeFile.name), 1);
    if (statusCode !== "2") {
      detach(args.currentTarget.parentElement);
    }
    this.FileSelected.emit(this.filesDetails);
  }
  public readURL(li: HTMLElement, args: any): void {
    let preview: HTMLImageElement = li.querySelector(".upload-image");
    if (preview) {
      let file: File = args.rawFile;
      let reader: FileReader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          preview.src = reader.result as string;
        },
        false
      );
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  }

  private buildText(): string {
    return `Drop your file${this.allowMultiple ? "s" : ""} here`;
  }

  selectEventHandler(e: SelectEvent) {
    let existsInvalidFile = false;
    if (this.allowedExtensions.length > 0) {
      e.files.forEach((p) => {
        if (!this.isFileAllowed(p)) {
          existsInvalidFile = true;
        }
      });
    }

    if (existsInvalidFile) {
      this.toastrService.error(
        this.invalidFileTypeMessage ||
          "File format not supported. please review.",
        "Error uploading File"
      );
      e.preventDefault();
      return;
    }

    e.files.forEach((p) => (p.name = p.rawFile.name));
    e.files.forEach((p) => this.addPreview(p));

    this.FileSelected.emit(e.files);
  }

  isFileAllowed(file): boolean {
    let extension = file.extension.toLowerCase();
    extension = extension.replace(".", "");
    return this.allowedExtensions.includes(extension);
  }

  async addPreview(file) {
    const raw = file.rawFile;
    const fileExtension = file.extension.toLowerCase().trim();

    const supportedExtensions = [
      ".jpg",
      ".jpeg",
      ".jpe",
      ".png",
      ".jif",
      ".jfif",
      ".jfi",
      ".webp",
      ".heic",
      ".bmp",
      ".gif",
      ".svg",
    ];
    if (supportedExtensions.includes(fileExtension) && raw) {
      let reader = new FileReader();
      reader.onload = function (results) {
        const preview = $(
          "<img class='image-preview' style='max-height:100px;width:150px;'>"
        ).attr({ src: reader.result });

        $(
          '.k-file[data-uid="' + file.uid + '"] .k-file-group-wrapper'
        ).replaceWith(preview);
      };

      if (file.extension.toLowerCase() == ".heic") {
        const jpeg = (await heic2any({
          blob: raw,
          toType: "image/jpeg",
          quality: 0.6,
        })) as Blob;
        reader.readAsDataURL(jpeg);
      } else reader.readAsDataURL(raw);
    }
  }

  uploadEventHandler(e: UploadEvent) {
    const token = this.authGuard.getToken();
    const guid = Helper.uuidv4();
    this.guids.push(guid);

    e.headers = e.headers
      .append("Authorization", token)
      .append("mimeType", "multipart/form-data")
      .append("AppName", "MPB-Web")
      .append("guids", guid);

    if (this.vendorId) {
      this.uploadSaveUrl =
        environment.baseUrl +
        "contractor/" +
        this.vendorId +
        "/filetype/" +
        this.filetype;
    } else if (this.changeOrderId) {
      this.uploadSaveUrl =
        environment.baseUrl +
        `jobs/changeorder/${this.changeOrderId}` +
        `/filetype/${this.filetype}` +
        `/jobPhaseId/${this.jobPhaseId || 0}` +
        `?annotate=${true}&isPhoto=${true}`;
    } else if (this.productId) {
      this.uploadSaveUrl = `${environment.baseUrl}product/${this.productId}/media/${this.filetype}`;
    } else {
      const proposalUploadUrl = this.worksheetFileData
        ? `?estimateId=${this.worksheetFileData.estimateId}&order=${this.worksheetFileData.order}`
        : "";
      this.uploadSaveUrl =
        environment.baseUrl +
        "jobs/" +
        this.jobId +
        "/filetype/" +
        this.filetype +
        "/phase/" +
        (this.jobPhaseId || 0) +
        (this.phasephotoFlag ? "?phasephotoFlag=" + this.phasephotoFlag : "") +
        "/files/chunk" +
        proposalUploadUrl;
    }
  }

  completeEventHandler() {
    this.uploadCompleted.emit(this.guids);
  }
}
