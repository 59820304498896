<div class="modal-dialog modal-sm">
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close" (click)="close()">&times;</button>
      <h4 class="modal-title">{{ title || "Confirm" }}</h4>
    </div>
    <div class="modal-body">
      <p>{{ message || "Are you sure?" }}</p>
      <div>
        <div>
          <kendo-dropdownlist
            name="phases"
            kendoTextBox
            [data]="phases"
            textField="PhasePhaseName"
            valueField="ID"
            [(ngModel)]="fromPhase"
            [disabled]="true"
          >
          </kendo-dropdownlist>
        </div>
        <div style="text-align: left; padding: 5px">
          <div>to</div>
        </div>
        <div>
          <kendo-dropdownlist
            name="phases"
            kendoTextBox
            [data]="toPhases"
            textField="PhasePhaseName"
            valueField="ID"
            (selectionChange)="changeToPhaseChanged($event)"
            [(ngModel)]="toPhase"
          >
          </kendo-dropdownlist>
        </div>
      </div>
      <p *ngIf="toPhase == null" style="margin-top: 5px; font-size: 12px">
        {{ message2 }}
      </p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn darkbtn dpbtn"
        (click)="editSave()"
        *ngIf="toShow"
      >
        Save & Edit
      </button>
      <button type="button" class="btn darkbtn" (click)="confirm()">OK</button>
      <button
        type="button"
        class="btn btn-default"
        *ngIf="!isNotification"
        (click)="close()"
      >
        Cancel
      </button>
    </div>
  </div>
</div>
