import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { OrganizationService } from "app/common/services/organization.service";
import { CommonService } from "app/common/services/common.service";
import { OrganizationModel } from "app/model/organizationModel";
import { MessageService } from "primeng/components/common/messageservice";
import { StateModel } from "app/model/statesModel";
import { OrgCameraModel } from "app/model/orgCameraModel";
import { BaThemeSpinner } from "app/theme/services";
import { environmentConstant } from "app/common/utility/environment";
import { GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import * as _ from "lodash";
import { Helper } from "app/common/utility/helper";
import { DialogService } from "ng2-bootstrap-modal";
import { ConfirmComponent } from "app/component/dialog/dialog.component";
import { SharedService } from "app/common/utility/SharedService";
import { BlobUploadmodel } from "app/model/BlobUpoadModel";
import { CustomerService } from "app/common/services/customer.service";
import { TradeModel } from "app/model/tradeModel";
import { CameraModelv2 } from "../../../../model/cameraModel";
import { environment } from "environments/environment";
import { GatewayAccountModel } from "app/model/GatewayAccountModel";
import { InvoiceService } from "app/common/services/invoice.service";
import { PaymentService } from "app/common/services/payment.service";
import { ShippingService } from "app/common/services/Core/Shipping.service";
import { ShippingModel } from "app/model/Core/ShippingModel";
import { fromEvent, merge } from "rxjs";
import { map, takeWhile } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "add-Organizations",
  templateUrl: "./organizationsAddEdit.html",
  styleUrls: ["./organizationsAddEdit.css"],
})
export class OrganizationsAddEditComponent implements OnInit {
  isPhotoSelected: any = false;
  photoDocumentFormData: FormData = null;
  pageSize: number = 50;
  skip: number = 0;
  gridView: GridDataResult;
  data: Object[];
  sort: SortDescriptor[] = [];
  isEditValue: any;
  editedRowIndex: number;
  lstOrganization: OrganizationModel[];
  originalOrganization: OrganizationModel[];
  availableCameraList: any[];
  orgId = this.sharedService.selectedOrganization.ID;
  headerText: string;
  hasQuickBooks: boolean;
  btnText: string;
  workDay: any = [];
  filterQuery = "";
  isFilled: boolean;
  value: string;
  submitted: boolean = false;
  defaultTime = { key: "(GMT -6:00) Central Time (US & Canada)", value: -6 };
  timezoneList: any;
  emailPattern = environmentConstant.emailPattern;
  organization: OrganizationModel = new OrganizationModel();
  editedProduct: any;
  stateList: StateModel[];
  originalStateList: StateModel[];
  lstTrade: TradeModel[] = [];
  selectState: StateModel = {
    Id: -1,
    Guid: "",
    CountryId: 0,
    DisplayOrder: 0,
    Name: "Select State",
    Abbreviation: "",
  };
  cameraList: CameraModelv2[];
  logoUploadResult: BlobUploadmodel;
  orignalAvailableCameraList: OrgCameraModel[];
  multiple: boolean = false;
  allowUnsort: boolean = true;
  WidgetURL: string;
  gatewayAccountsDirty: boolean = false;
  integrationDirty: boolean = false;
  DefaultBillingType = environmentConstant.BillingType;
  orgShippingMethods: ShippingModel[];

  @Input() IsSuperAdmin: boolean = true;
  @Output() refreshData: EventEmitter<any> = new EventEmitter<any>();
  @Input() ID: string;
  startTime: Date = new Date();
  endTime: Date = new Date();

  //Gateway Account variables for ProPay
  gatewayAccountModel = new GatewayAccountModel();
  gatewayAccountList: GatewayAccountModel[] = [];
  showAccountFields: boolean = false;

  @ViewChild("inputZip", { static: false })
  inputZip: ElementRef;
  zipCodeMessage = environmentConstant.zipCodeMessage;
  zipValidate: boolean = false;
  alive: boolean;

  constructor(
    private _spinner: BaThemeSpinner,
    private organizationService: OrganizationService,
    private customerService: CustomerService,
    private shippingService: ShippingService,
    private commonService: CommonService,
    private invoiceService: InvoiceService,
    private paymentService: PaymentService,
    private notificationsService: MessageService,
    private dialogService: DialogService,
    private sharedService: SharedService,
    private toastrService: ToastrService
  ) {
    this.timezoneList = [
      { key: "(GMT -10:00) Hawaii Time (US)", value: -10 }, // doesn't observe DST
      { key: "(GMT -8:00) Pacific Time (US & Canada)", value: -8 },
      { key: "(GMT -7:00) Mountain Time (US & Canada)", value: -7 },

      // NOTE: all of these UTC offsets are assuming DST is NOT in effect
      // which is when both of these timezones line up with MST. We will
      // fix our timezone system in the future so that we can account for
      // DST on the fly.
      { key: `(GMT -7:00) Arizona Time - Except Navajo (US)`, value: -7 }, // doesn't observe DST
      { key: `(GMT -7:00) Arizona Time - Navajo Nation (US)`, value: -7 }, // observes DST.

      { key: "(GMT -5:00) Eastern Time (US & Canada)", value: -5 },
      { key: "(GMT -4:00) Atlantic Time (Canada)", value: -4 },
    ];
  }

  fileChange(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.isPhotoSelected = true;
      const file: File = fileList[0];
      this.photoDocumentFormData = new FormData();
      this.photoDocumentFormData.append("uploadFile", file, file.name);
    }
  }
  // getOrganizationList() {
  //   this._spinner.show();
  //   this.organizationService.getOrganizations().subscribe((organizations) => {
  //     this.lstOrganization = organizations;
  //     this.lstOrganization = this.lstOrganization.filter(x => x.ID !== this.ID);
  //     this.originalOrganization = this.lstOrganization;

  //   });
  // }

  getOrganizationLookup() {
    this.organizationService.getOrganizationsLookUp().subscribe((orgs) => {
      this.lstOrganization = orgs;
      this.lstOrganization = this.lstOrganization.filter(
        (x) => x.ID !== this.ID
      );
      this.originalOrganization = this.lstOrganization;
    });
  }

  public filterOrganization(filter: any): void {
    this.lstOrganization = this.originalOrganization.filter(
      (s) =>
        s.OrganizationName.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }

  addOrgnization() {
    this._spinner.show();
    this.organization.StatTime =
      (this.startTime.getHours() < 10
        ? "0" + this.startTime.getHours()
        : this.startTime.getHours()) +
      ":" +
      (this.startTime.getMinutes() < 10
        ? "0" + this.startTime.getMinutes()
        : this.startTime.getMinutes());
    this.organization.EndTime =
      (this.endTime.getHours() < 10
        ? "0" + this.endTime.getHours()
        : this.endTime.getHours()) +
      ":" +
      (this.endTime.getMinutes() < 10
        ? "0" + this.endTime.getMinutes()
        : this.endTime.getMinutes());
    this.organizationService
      .saveOrganization(this.organization)
      .subscribe((res) => {
        if (res !== null) {
          this.organization = res;
          if (this.photoDocumentFormData) {
            this.organizationService
              .uploadLogo(this.organization.ID, this.photoDocumentFormData)
              .subscribe((data) => {
                if (data) {
                  this.notificationsService.add({
                    severity: "success",
                    summary: "Organization",
                    detail: "Added Successfully!!",
                  });
                  this._spinner.hide();
                  this.refreshData.emit(true);
                } else {
                  this.notificationsService.add({
                    severity: "warn",
                    summary: "Organization",
                    detail: "Logo Upload Falied!!",
                  });
                  this._spinner.hide();
                }
              });
          } else {
            this.notificationsService.add({
              severity: "success",
              summary: "Organization",
              detail: "Added Successfully!!",
            });
            this.refreshData.emit(true);
          }
        }
        this._spinner.hide();
      });
  }

  updateOrganization() {
    this._spinner.show();
    var taxRate = Number(this.organization.TaxRate / 100);
    this.organization.TaxRate = Number(taxRate.toFixed(4));
    this.organization.StatTime =
      (this.startTime.getHours() < 10
        ? "0" + this.startTime.getHours()
        : this.startTime.getHours()) +
      ":" +
      (this.startTime.getMinutes() < 10
        ? "0" + this.startTime.getMinutes()
        : this.startTime.getMinutes());
    this.organization.EndTime =
      (this.endTime.getHours() < 10
        ? "0" + this.endTime.getHours()
        : this.endTime.getHours()) +
      ":" +
      (this.endTime.getMinutes() < 10
        ? "0" + this.endTime.getMinutes()
        : this.endTime.getMinutes());
    this.organizationService
      .updateOrganization(this.organization)
      .subscribe(() => {
        if (this.photoDocumentFormData) {
          this.organizationService
            .uploadLogo(this.organization.ID, this.photoDocumentFormData)
            .subscribe((data) => {
              if (
                this.sharedService.selectedOrganization.ID ===
                this.organization.ID
              ) {
                this.sharedService.selectedOrganization.ID =
                  this.organization.ID;
                this.sharedService.selectedOrganization.OrganizationName =
                  this.organization.OrganizationName;
                this.sharedService.selectedOrganization.State =
                  +this.organization.State;
                this.sharedService.selectedOrganization.Email =
                  this.organization.Email;
                this.sharedService.selectedOrganization.LogoUrl = data;
                this.sharedService.selectedOrganization.WorkingDays =
                  this.organization.WorkingDays;
                this.sharedService.selectedOrganization.IsVendor =
                  this.organization.IsVendor;
                this.sharedService.selectedOrganization.UseGoogleCalendar =
                  this.organization.UseGoogleCalendar;
                this.sharedService.selectedOrganization.StatTime =
                  this.organization.StatTime;
                this.sharedService.selectedOrganization.EndTime =
                  this.organization.EndTime;
                if (this.sharedService.isImpersonated) {
                  var impersonateObj = {
                    IsVendor: this.sharedService.selectedOrganization.IsVendor,
                    WorkingDays:
                      this.sharedService.selectedOrganization.WorkingDays,
                    orgId: this.sharedService.selectedOrganization.ID,
                    isImpersonated: true,
                    OrganizationOrganizationName:
                      this.sharedService.selectedOrganization.OrganizationName,
                    Email: this.sharedService.selectedOrganization.Email,
                    LogoUrl: this.sharedService.selectedOrganization.LogoUrl,
                    State: +this.sharedService.selectedOrganization.State,
                    UseGoogleCalendar:
                      this.sharedService.selectedOrganization.UseGoogleCalendar,
                    StatTime: this.sharedService.selectedOrganization.StatTime,
                    EndTime: this.sharedService.selectedOrganization.EndTime,
                  };
                  localStorage.setItem(
                    "impersonateObj",
                    JSON.stringify(impersonateObj)
                  );
                }
              }
              if (data) {
                this.notificationsService.add({
                  severity: "success",
                  summary: "Organization",
                  detail: "Updated Successfully!!",
                });

                this._spinner.hide();
                this.refreshData.emit(true);
              } else {
                this.notificationsService.add({
                  severity: "warn",
                  summary: "Organization",
                  detail: "Logo Upload Falied!!",
                });
                this._spinner.hide();
              }
            });
        } else {
          if (
            this.sharedService.selectedOrganization.ID === this.organization.ID
          ) {
            this.sharedService.selectedOrganization.ID = this.organization.ID;
            this.sharedService.selectedOrganization.OrganizationName =
              this.organization.OrganizationName;
            this.sharedService.selectedOrganization.State =
              +this.organization.State;
            this.sharedService.selectedOrganization.Email =
              this.organization.Email;
            this.sharedService.selectedOrganization.WorkingDays =
              this.organization.WorkingDays;
            this.sharedService.selectedOrganization.IsVendor =
              this.organization.IsVendor;
            this.sharedService.selectedOrganization.UseGoogleCalendar =
              this.organization.UseGoogleCalendar;
            this.sharedService.selectedOrganization.StatTime =
              this.organization.StatTime;
            this.sharedService.selectedOrganization.EndTime =
              this.organization.EndTime;
            if (this.sharedService.isImpersonated) {
              var impersonateObj = {
                IsVendor: this.sharedService.selectedOrganization.IsVendor,
                WorkingDays:
                  this.sharedService.selectedOrganization.WorkingDays,
                orgId: this.sharedService.selectedOrganization.ID,
                isImpersonated: true,
                OrganizationOrganizationName:
                  this.sharedService.selectedOrganization.OrganizationName,
                Email: this.sharedService.selectedOrganization.Email,
                LogoUrl: this.sharedService.selectedOrganization.LogoUrl,
                State: +this.sharedService.selectedOrganization.State,
                UseGoogleCalendar:
                  this.sharedService.selectedOrganization.UseGoogleCalendar,
                StatTime: this.sharedService.selectedOrganization.StatTime,
                EndTime: this.sharedService.selectedOrganization.EndTime,
              };
              localStorage.setItem(
                "impersonateObj",
                JSON.stringify(impersonateObj)
              );
            }
          }
          this.notificationsService.add({
            severity: "success",
            summary: "Organization",
            detail: "Updated Successfully!!",
          });
          this._spinner.hide();
          this.refreshData.emit(true);
        }
        this.integrationDirty = false;
      });

    //this.updateGatewayAccount();
  }

  updateGatewayAccount() {
    this.gatewayAccountList.forEach((account: GatewayAccountModel) => {
      let gatewayAccount = this.gatewayAccountList.find(
        (c) => c.Id === account.Id
      );
      if (gatewayAccount.Id === 0) {
        account.OrgGuid = this.ID;
        this.invoiceService.postGatwayAccount(account).subscribe();
      } else {
        this.invoiceService.putGatwayAccount(account).subscribe();
      }
    });

    this.notificationsService.add({
      severity: "success",
      summary: "Gateway Account",
      detail: "Updated Successfully!!",
    });

    this.gatewayAccountsDirty = false;
  }

  onSubmit(org) {
    this.validateZipCode(this.organization.Zip);
    if (!this.zipValidate) {
      if (org.valid) {
        var wd: any[] = [];
        this.workDay.forEach((each, index) => {
          if (each) wd[index] = 1;
          else wd[index] = 0;
        });
        this.organization.WorkingDays = wd.join("");
        if (this.ID === "0") {
          this.addOrgnization();
        } else {
          if (this.gatewayAccountsDirty) {
            this.updateGatewayAccount();
            this.updateOrganization();
          } else {
            this.updateOrganization();
          }
        }
      }
    } else {
      this._spinner.hide();
      this.zipValidate = true;
      this.notificationsService.add(environmentConstant.zipCodeError);
    }
  }

  ngOnInit() {
    this.alive = true;
    if (!this.IsSuperAdmin) {
      this.ID = this.sharedService.selectedOrganization.ID;
      //this.IsSuperAdmin = false;
    }
    this._spinner.show();
    this.getStatesList();
    //this.getOrganizationList();
    this.getOrganizationLookup();
    this.getOrganization();
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.setZipCodes();
    }, 500);
  }

  getOrgShipping(ID) {
    this.shippingService.getOrgShippingOptions(this.ID).subscribe((resp) => {
      this.orgShippingMethods = resp.object;
    });
  }

  getOrganizationCamera() {
    this._spinner.show();
    this.organizationService
      .getOrganizationCamerasv2(this.ID)
      .subscribe((data) => {
        this.cameraList = data;
        this.cameraList.forEach((obj) => {
          obj.CameraSerialNumbertxt = obj.SerialNumber;
        });
        this.LoadOrganizationCamera(null);
      });
  }

  public filterCameras(filter: any): void {
    this.availableCameraList = this.orignalAvailableCameraList.filter(
      (s) =>
        s.CameraSerialNumber.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }

  addNewGatewayAccountInfo() {
    this.gatewayAccountModel.AccountName = "";
    this.gatewayAccountModel.AccountNumber = "";
    this.gatewayAccountModel.MerchantId = "";
    this.gatewayAccountModel.Default = false;
    this.gatewayAccountModel.OrgGuid =
      this.sharedService.selectedOrganization.ID;
    this.gatewayAccountModel.Id = 0;
    this.showAccountFields = true;
  }

  editGatewayAccountInfo(event) {
    this.gatewayAccountModel = event;
    this.showAccountFields = true;
  }

  removeGatewayAccountInfo(event) {
    this.gatewayAccountModel = event;
    this.gatewayAccountModel.Deleted = new Date();
    this.paymentService
      .putGatwayAccount(this.gatewayAccountModel)
      .subscribe((response) => {
        this.getGatewayAccountList();
      });
  }

  getGatewayAccount() {
    this.gatewayAccountModel;
    this.paymentService
      .getGatewayAccountByOrg(this.ID)
      .subscribe((response) => {
        this.gatewayAccountModel = response;
      });
  }

  getGatewayAccountList() {
    this.gatewayAccountModel;
    this.paymentService
      .getGatewayAccountListByOrg(this.ID)
      .subscribe((response) => {
        this.gatewayAccountList = response;
      });
  }

  onChangeGatewayAccounts(gatewayAccounts: GatewayAccountModel[]) {
    this.gatewayAccountList = gatewayAccounts;
    this.gatewayAccountsDirty = true;
  }

  getOrganization() {
    if (this.ID === "0") {
      this.headerText = "New";
      this.btnText = "Save";
      this.startTime = new Date(this.startTime.setHours(7, 0));
      this.endTime = new Date(this.endTime.setHours(19, 0));
      var defaultValue = "1111110";
      var workDay = defaultValue.split("");
      //set workdays
      workDay.forEach((each, index) => {
        if (each == "1") this.workDay[index] = true;
        else this.workDay[index] = false;
      });
      this.organization = new OrganizationModel();
    } else {
      this.headerText = "Edit";
      this.hasQuickBooks = true;
      this.btnText = "Update";
      this.getAvailableCameras();
      this.organizationService
        .getOrganizationById(this.ID)
        .subscribe((organization) => {
          this.organization = organization;
          this.organization.TaxRate = Number(
            (this.organization.TaxRate * 100).toFixed(2)
          );
          this.WidgetURL =
            environment.imgBaseUrl +
            "Widget/AddLead?orgGuid=" +
            this.organization.ID;
          this.startTime = new Date(
            this.startTime.setHours(
              parseInt(this.organization.StatTime.split(":")[0]),
              parseInt(this.organization.StatTime.split(":")[1])
            )
          );
          this.endTime = new Date(
            this.endTime.setHours(
              parseInt(this.organization.EndTime.split(":")[0]),
              parseInt(this.organization.EndTime.split(":")[1])
            )
          );
          var workDay = this.organization.WorkingDays.split("");
          //set workdays
          workDay.forEach((each, index) => {
            if (each == "1") this.workDay[index] = true;
            else this.workDay[index] = false;
          });
          this.organization.State = parseInt(this.organization.State);
          this.getOrganizationCamera();
          this.getGatewayAccount();
          this.getGatewayAccountList();
          this.getOrgShipping(this.ID);
        });
    }
    this._spinner.hide();
  }
  getAvailableCameras() {
    this.organizationService.getAvailableCameras().subscribe((data) => {
      this.availableCameraList = data;
      this.orignalAvailableCameraList = data;
    });
  }
  selectionChange(e) {
    const val = this.availableCameraList.find((x) => x.CameraId === e.CameraId);
    e.OrgCameraName = val.CameraSerialNumber;
    e.CameraMACAddress = val.MACAddress;
  }
  // trim space
  splitSpace() {
    this.value = this.organization.OrganizationName.trim();
    return this.value;
  }

  // get States
  getStatesList() {
    this._spinner.show();
    this.commonService.getStates().subscribe((states) => {
      this.stateList = states;
      this.originalStateList = states;
    });
  }
  public filterState(filter: any): void {
    this.stateList = this.originalStateList.filter(
      (s) => s.Name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }
  pageChange(event: PageChangeEvent): void {
    //this._spinner.show();
    this.skip = event.skip;
    this.filterGridData();
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.LoadOrganizationCamera(null);
  }
  clearSearch(value) {
    if (value === null) {
      this.isFilled = true;
    } else {
      this.isFilled = false;
    }
    this.filterQuery = "";
    this.filterGridData();
  }

  filterGrid() {
    this.isFilled = true;
    this.filterGridData();
    if (this.filterQuery.length == 0) {
      this.isFilled = false;
    }
    this.pageChange({ skip: 0, take: this.pageSize });
  }
  filterGridData() {
    this.LoadOrganizationCamera(
      _.filter(
        this.cameraList,
        (row) =>
          Helper.ConvertToLower(row.SerialNumber).indexOf(
            this.filterQuery.trim().toLowerCase()
          ) > -1 ||
          Helper.ConvertToLower(row.MACAddress).indexOf(
            this.filterQuery.trim().toLowerCase()
          ) > -1 ||
          Helper.ConvertToLower(row.OrgCameraName).indexOf(
            this.filterQuery.trim().toLowerCase()
          ) > -1 ||
          Helper.ConvertToLower(row.JobName).indexOf(
            this.filterQuery.trim().toLowerCase()
          ) > -1 ||
          Helper.ConvertToLower(row.FirewareVerion).indexOf(
            this.filterQuery.trim().toLowerCase()
          ) > -1 ||
          Helper.ConvertToLower(row.DateActivated).indexOf(
            this.filterQuery.trim().toLowerCase()
          ) > -1 ||
          Helper.ConvertToLower(row.Model).indexOf(
            this.filterQuery.trim().toLowerCase()
          ) > -1 ||
          (row.Active === true
            ? "active".indexOf(this.filterQuery.trim().toLowerCase()) > -1
            : "inactive".indexOf(this.filterQuery.trim().toLowerCase()) > -1)
      )
    );
  }

  private LoadOrganizationCamera(org): void {
    let cameras = org || this.cameraList;
    cameras = orderBy(cameras, this.sort);

    this.gridView = {
      data: cameras.slice(this.skip, this.skip + this.pageSize),
      total: cameras.length,
    };
    this._spinner.hide();
  }

  addHandler({ sender }) {
    this.closeEditor(sender);
    const obj = new OrgCameraModel();
    obj.OrgCameraName = "";
    obj.CameraMACAddress = "";
    obj.CameraName = "";
    obj.CameraDateActivated = null;
    sender.addRow(obj);
  }

  editHandler({ sender, rowIndex, dataItem }) {
    this.isEditValue = Object.assign({}, dataItem);
    this.closeEditor(sender);
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex);
  }

  cancelHandler({ sender, rowIndex }) {
    if (rowIndex >= 0) {
      const val = this.isEditValue;
      const orgCamera = this.cameraList.find(
        (x) => x.CameraId === val.CameraId
      );
      orgCamera.CameraName = val.CameraName;
    }
    this.closeEditor(sender, rowIndex);
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
  }

  saveHandler({ sender, rowIndex, dataItem, isNew }) {
    if (isNew) {
      dataItem.orgGUID = this.ID;
      this.organizationService
        .saveOrganizationCamera(dataItem, this.ID)
        .subscribe(
          (result) => {
            this.getOrganization();
            this.result(result, "Added Successfully!!", "Organization");
          },
          (error) => this.error(error)
        );
    } else {
      this.organizationService
        .updateOrganizationCamera(dataItem, this.ID)
        .subscribe(
          (result) => {
            this.getOrganization();
            this.result(result, "Updated Successfully!!", "Organization");
          },
          (error) => this.error(error)
        );
    }
    sender.closeRow(rowIndex);

    this.editedRowIndex = undefined;
  }

  removeHandler({ dataItem }) {
    this.deleteOrgCamera(dataItem);
  }
  deleteOrgCamera(orgCamera) {
    const disposable = this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Delete Organization Camera",
        message:
          "Are you sure you want to delete " + orgCamera.SerialNumber + "?",
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.organizationService
            .deleteOrganizationCamere(this.ID, orgCamera.CameraId)
            .subscribe((res) => {
              this.result(res, "Deleted Successfully!!");
              this.getAvailableCameras();
              this.getOrganization();
            });
        }
      });
  }
  private error(error: any) {
    switch (error.status) {
      // case 409:
      //   this.notificationsService.add({
      //     severity: "warn",
      //     summary: "Camera",
      //     detail: "Camera Name already exists!!",
      //   });
      //   break;

      case 409:
        // this.notificationsService.add({
        //   severity: "warn",
        //   summary: this.moduleName,
        //   detail: "Unable to save " + this.moduleName,
        // });
        this.toastrService.error("Camera Name already exists!!");
        this._spinner.hide();
        break;
      case 500:
        this.toastrService.error(error.error.Message);
        this._spinner.hide();
        break;
      default:
        this.toastrService.error(error.message);
        this._spinner.hide();
        break;
    }
  }
  private result(res: any, message: string, title: string = "Camera") {
    if (res !== null) {
      this.notificationsService.add({
        severity: "success",
        summary: title,
        detail: message,
      });
    }
  }

  onCancel() {
    this.refreshData.emit(false);
  }

  /**
   * Method for validate Zip Code values
   * @param zipCodeValue
   */
  validateZipCode(zipCodeValue) {
    if (zipCodeValue != "") {
      environmentConstant.zipRegex.test(zipCodeValue)
        ? (this.zipValidate = false)
        : (this.zipValidate = true);
    } else {
      this.zipValidate = false;
    }
  }

  /**
   * Sets the zip input subscriptions
   */
  setZipCodes(): void {
    const keyup = fromEvent(this.inputZip.nativeElement, "keyup");
    const paste = fromEvent(this.inputZip.nativeElement, "paste");
    const allEvents = merge(keyup, paste);
    allEvents
      .pipe(
        takeWhile(() => this.alive),
        map((event: Event) => {
          return (event.target as HTMLInputElement).value.trim();
        })
      )
      .subscribe((text: string) => {
        if (text != "") {
          environmentConstant.zipRegex.test(text)
            ? (this.zipValidate = false)
            : (this.zipValidate = true);
        } else {
          this.zipValidate = false;
        }
      });
  }

  organizationUpdated(newValue) {
    this.integrationDirty = true;
    this.organization = newValue;
  }
}
