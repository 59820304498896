import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";
import { environmentConstant } from "app/common/utility/environment";
import { from, Observable } from "rxjs";
import { ChangeOrderModel } from "app/model/changeOrderModel ";
import { mergeMap } from "rxjs/operators";
import { v4 as uuidv4 } from "uuid";

@Injectable()
export class ChangeOrderService {
  constructor(private httpService: HttpService) {}

  getChangeOrderByJobID(jobId: any): Observable<ChangeOrderModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getChangeOrderByJobID + jobId + "/changeorder"
    );
  }

  /** Creates a change order file */
  postChangeOrderFile(
    fileId: number,
    newDataUrl: string,
    originalFileName: string,
    changeOrderId: number
  ) {
    const promise = fetch(newDataUrl).then((res) => res.blob());
    return from(promise).pipe(
      mergeMap((blob) => {
        const formData = new FormData();
        formData.append(
          "uploadFile",
          blob,
          `annotation${uuidv4()}__${originalFileName}`
        );
        return this.httpService.PostChangeOrderFileService(
          `changeOrder/${changeOrderId}/jobChangeOrderFile/${fileId}/newVersion`,
          formData
        );
      })
    );
  }
}
