import { JobResourcesRequestPayload } from "./JobAssignResourceModel";
import { Resource } from "./ResourceModel";

export class ToDoListResourceDto {
  IsExternal: boolean;
  ResouceGuid: string;
  ResourceName: string;
}

export class ToDoListDto {
  ID: number;
  EstEndDate: Date;
  StartDate: Date;
  EndDate: Date;
  ScheduledDate: Date;
  JobTypeId: number;
  OrgGUID: string;
  Status: number;
  PhaseName: string;
  JobID: string;
  groupingStatus: string;
  JobName: string;
  OwnerId?: number;
  AssignedToId: number;
  AssignedToInitials: string;
  AssignedBy: string;
  AssignedById?: number;
  Resources: ToDoListResourceDto[];
  constructor() {}
}

export class QuickToDoDto {
  OrgGUID: string;
  TaskName: string;
  TaskDate: Date;
  JobId?: string;
  constructor() {}
}

export class TaskDto {
  OrgGUID: string;
  TaskId: number;
  TaskName: string;
  Comment: string;
  ScheduledOn: Date;
  StartedOn?: Date;
  OwnerId?: number;
  CompletedOn?: Date;
  AssignedBy: string;
  AssignedById?: number;
  JobId?: any;
  StatusId: number;
  Resources: Resource[] = [];
  Reminder: Date;
  constructor() {}
}
