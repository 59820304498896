import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { HttpService } from "../utility/http.service";
import {
  AddRemoveOrganizationOrganizationSetting,
  OrganizationOrganizationSetting,
  Settings,
} from "app/model/Settings";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class SettingService {
  constructor(private http: HttpClient, private httpService?: HttpService) {}

  getOrganizationSettingsByOrganizationId(organizationId: string) {
    const url = `organizationSetting/settings/${organizationId}/organization`;
    return this.httpService.getRequestService(url);
  }

  getOrganizationSettingsNoAuth(organizationId: string): Observable<any[]> {
    const url = `${environment.baseUrl}/organizationSetting/settings/${organizationId}/organization`;
    return this.http.get<any[]>(url);
  }

  checkIfAutoNumberingEnabled(setting: Settings[], key: string): boolean {
    const item = setting.find((x) => x.SettingKey === key);
    if (item) {
      return item.Value === "0" ? false : true;
    }
    return false;
  }

  /**
   * get boolean value from organization settings
   * @param setting organization settings list
   * @param key key to search
   * @param defaultValue default value if key not found
   * @returns boolean value of key
   */
  getBooleanValue(
    setting: Settings[],
    key: string,
    defaultValue: boolean
  ): boolean {
    const item = setting.find((x) => x.SettingKey === key);
    if (item) {
      return item.Value === "0" ? false : true;
    }
    return defaultValue;
  }

  /**
   * get numeric value from organization settings
   * @param setting organization settings list
   * @param key key to search
   * @param defaultValue default value if key not found
   * @returns numeric value of key
   */
  getNumericValue(
    setting: Settings[],
    key: string,
    defaultValue: number
  ): number {
    const item = setting.find((x) => x.SettingKey === key);
    if (item) {
      return +item.Value;
    }
    return defaultValue;
  }

  getOrganizationsSettings(organizationGuid: string) {
    const url = `organizationSetting/settingvalues/${organizationGuid}`;
    return this.httpService.getRequestService(url);
  }

  putOrganizationOrganizationSettings(
    organizationOrganizationSetting: OrganizationOrganizationSetting
  ) {
    const url = `organizationSetting/organization`;
    return this.httpService.putRequestService(
      url,
      organizationOrganizationSetting
    );
  }

  postOrganizationOrganizationSettings(
    organizationOrganizationSettingLists: AddRemoveOrganizationOrganizationSetting
  ) {
    const url = `organizationSetting/settings`;
    return this.httpService.postRequestService(
      url,
      organizationOrganizationSettingLists
    );
  }
}
