<div class="widgets commonFixedSidebar">
  <div class="row">
    <div class="col-md-12 ht100">
      <div class="rightcontent">
        <div class="sideBarHeader">
          <h1>{{ headerText }}</h1>
        </div>
        <div class="tabBlk">
          <form #myForm="ngForm" class="form-horizontal">
            <div class="fixed-layout hoverflow">
              <div class="ht100">
                <div class="row">
                  <div class="col-md-12 col-sm-12 col-xs-12">
                    <label class="form-control-label" for="text">{{
                      searchText
                    }}</label>
                  </div>
                </div>
                <div class="existingcustomer">
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div class="jsearch top_search">
                        <div class="input-group">
                          <input
                            class="inputS"
                            [(ngModel)]="filterQuery"
                            name="filtersearch"
                            (ngModelChange)="filterGrid()"
                            placeholder="{{ 'common.search_for' | translate }}"
                            type="text"
                          />
                          <i class="fa fa-search" aria-hidden="true"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div class="x_panel">
                        <div class="x_content">
                          <div class="kendoheight kendoheight2">
                            <kendo-grid
                              [data]="gridView"
                              [pageSize]="pageSize"
                              #grid="kendoGrid"
                              [skip]="skip"
                              [pageable]="true"
                              (pageChange)="pageChange($event)"
                              [sortable]="{
                                allowUnsort: allowUnsort,
                                mode: multiple ? 'multiple' : 'single'
                              }"
                              [sort]="sort"
                              (sortChange)="sortChange($event)"
                            >
                              <kendo-grid-column
                                headerClass="gridHeading"
                                title=""
                                field="Id"
                                class="gridRow"
                                width="60"
                              >
                                <ng-template
                                  kendoGridCellTemplate
                                  let-dataItem="dataItem"
                                >
                                  <input
                                    type="radio"
                                    name="selectedData"
                                    (change)="selectedData = dataItem"
                                  />
                                </ng-template>
                              </kendo-grid-column>
                              <kendo-grid-column
                                headerClass="gridHeading"
                                class="gridRow"
                                field="{{ fieldName }}"
                                title="Name"
                              >
                              </kendo-grid-column>
                            </kendo-grid>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="fixbtnwrap">
              <div class="btnwrap__center">
                <button
                  type="button"
                  class="btn darkbtn"
                  [disabled]="!selectedData"
                  (click)="onSave()"
                >
                  <i class="fa fa-floppy-o" aria-hidden="true"></i> Save
                </button>
                <button
                  type="button"
                  class="cross btn bthpn2"
                  (click)="oncancel('cancel')"
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
