import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EstimateModalComponent } from "../component/estimate-modal/estimate-modal.component";
import { MatDialogModule } from "@angular/material";
import { EstimateModalService } from "../common/services/estimate-modal.service";

@NgModule({
  declarations: [EstimateModalComponent],
  imports: [CommonModule, MatDialogModule],
  exports: [EstimateModalComponent],
  entryComponents: [EstimateModalComponent],
  providers: [EstimateModalService],
})
export class EstimateModalModule {}
