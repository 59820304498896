import { Component, ChangeDetectorRef } from "@angular/core";
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";

export interface MoreOptionModel {
  title: string;
}
export class MoreOptionDTO {
  selectedOption: any;
}
@Component({
  selector: "app-calendar-more-option",
  templateUrl: "./calendar-more-option.component.html",
  styleUrls: ["./calendar-more-option.component.scss"],
})
export class CalendarMoreOptionComponent
  extends DialogComponent<MoreOptionModel, MoreOptionDTO>
  implements MoreOptionModel {
  Scm: MoreOptionDTO;
  title: string;
  changeDetectorRef: ChangeDetectorRef;
  constructor(
    dialogService: DialogService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(dialogService);
    this.changeDetectorRef = changeDetectorRef;
    this.Scm = new MoreOptionDTO();
    var that = this;
    setTimeout(function () {
      that.changeDetectorRef.detectChanges();
    }, 300);
  }
  Confirm() {
    this.result = this.Scm;
    this.close();
  }
}
