import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CanActivateEstimate } from "app/model/CanActivateEstimate";
import { EstimateTaxInfo } from "app/model/EstimateTaxInfo";
import { EstimateView } from "app/model/EstimateView";
import {
  EstimateDrawSchedule,
  EstimateWorksheetInfo,
  NewEstimateWorksheetInfo,
  UpdateWorksheetStatusDTO,
} from "app/model/EstimateWorksheetInfo";
import { EstimateWorksheetProduct } from "app/model/EstimateWorksheetProduct";
import { PagedEstimateWorksheetResponse } from "app/model/PagedWorksheetsResponse";
import { PagedEstimateWorksheetsSearchParams } from "app/model/PagedWorksheetsSearchParams";
import { SignNowSignersRequest } from "app/model/SignNowModels";
import { WorksheetDetailFilterQuery } from "app/model/WorksheetDetailFilterQuery";
import { WorksheetDetailFromDB } from "app/model/WorksheetDetailObject";
import { WorksheetEstimateViewGroupChecklistItemValues } from "app/model/WorksheetEstimateViewGroupChecklistItemValues";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

const base_url = environment.baseEstimateUrl;

@Injectable({
  providedIn: "root",
})
export class EstimateWorksheetService {
  constructor(private http: HttpClient) {}

  getEstimatesForLead(leadId: string) {
    const url = `${base_url}lead/${leadId}/estimates`;
    return this.http.get<EstimateWorksheetInfo[]>(url);
  }

  deleteEstimate(estimateId: string) {
    const url = `${base_url}estimates/${estimateId}`;
    return this.http.delete(url);
  }

  getEstimateWorksheet(id: string, orgGuid: string) {
    const url = `${base_url}estimates/${orgGuid}/${id}`;
    return this.http.get<EstimateWorksheetInfo>(url);
  }

  exportEstimateById(id: string, estimateExportOptions: FormData) {
    const requestOptions: Object = {
      responseType: "arraybuffer",
      headers: new HttpHeaders(),
    };
    const url = `${base_url}estimates/export/${id}`;
    return this.http
      .post<Blob>(url, estimateExportOptions, requestOptions)
      .pipe(
        map((res: Blob) => {
          const blob = new Blob([res], {
            type: "application/x-zip-compressed",
          });
          return blob;
        })
      );
  }

  updateEstimateTaxInfoById(estimateId: string, estimateInfo: EstimateTaxInfo) {
    return this.http.post(
      `${base_url}estimates/${estimateId}/set-taxinfo`,
      estimateInfo
    );
  }

  getWorkSheetEstimateViewValues(
    estimateWorksheetId: string,
    orgGuid: string,
    isFromEstimate = false
  ) {
    return this.http.get<EstimateView[]>(
      `${base_url}estimates/${orgGuid}/${estimateWorksheetId}/views?isFromEstimate=${isFromEstimate}`
    );
  }

  getWorksheetEstimateViewGroupChecklistItem(
    estimateWorksheetViewId: number,
    viewgroupId: number
  ) {
    return this.http.get<WorksheetEstimateViewGroupChecklistItemValues[]>(
      `${base_url}estimates/worsheetview/${estimateWorksheetViewId}/group/${viewgroupId}/checklistitem`
    );
  }

  markAsDoneWorksheetEstimateViewGroupChecklistItem(item) {
    return this.http.post(
      `${base_url}estimates/group/checklistitem/markasdone`,
      item
    );
  }

  pagedEstimateWorksheets(
    orgGuid: string,
    searchParams: PagedEstimateWorksheetsSearchParams
  ): Observable<PagedEstimateWorksheetResponse> {
    return this.http.post<PagedEstimateWorksheetResponse>(
      `${base_url}estimates/search/${orgGuid}`,
      searchParams
    );
  }

  pagedSummaryEstimateWorksheets(
    orgGuid: string,
    searchParams: PagedEstimateWorksheetsSearchParams
  ): Observable<PagedEstimateWorksheetResponse> {
    return this.http.post<PagedEstimateWorksheetResponse>(
      `${base_url}estimates/summary/search/${orgGuid}`,
      searchParams
    );
  }

  getEstimateWorksheetsByEmployee(
    orgGuid: string,
    worksheetId: string,
    employeeId: number
  ): Observable<EstimateWorksheetInfo[]> {
    return this.http.get<EstimateWorksheetInfo[]>(
      `${base_url}estimates/${orgGuid}/worksheet/${worksheetId}` +
        (employeeId ? `?employeeId=${employeeId}` : ``)
    );
  }

  getEstimateWorksheetProducts(
    estimateId: string,
    orgGuid: string,
    filterParams: WorksheetDetailFilterQuery
  ): Observable<NewEstimateWorksheetInfo> {
    let params = new HttpParams();
    const keys = Object.keys(filterParams);
    keys.forEach((key) => {
      params = params.append(key, filterParams[key]);
    });
    return this.http.get<NewEstimateWorksheetInfo>(
      `${base_url}estimates/${orgGuid}/${estimateId}/products`,
      {
        params: params,
      }
    );
  }
  getBudgetWorksheetProducts(
    estimateId: string,
    orgGuid: string,
    filterParams: WorksheetDetailFilterQuery
  ): Observable<NewEstimateWorksheetInfo> {
    let params = new HttpParams();
    const keys = Object.keys(filterParams);
    keys.forEach((key) => {
      params = params.append(key, filterParams[key]);
    });
    return this.http.get<NewEstimateWorksheetInfo>(
      `${base_url}budget/${orgGuid}/${estimateId}/products`,
      {
        params: params,
      }
    );
  }
  updateWorksheet(newEs: EstimateWorksheetInfo) {
    return this.http.post(`${base_url}estimates/name`, newEs);
  }

  addWorksheetProducts(objToAdd: {
    worksheetDetail: WorksheetDetailFromDB;
  }): Observable<EstimateWorksheetProduct[]> {
    return this.http.post<EstimateWorksheetProduct[]>(
      `${base_url}estimates/products`,
      objToAdd
    );
  }

  updateWorksheetProduct(worksheetProduct: {
    worksheetDetail: WorksheetDetailFromDB;
  }) {
    return this.http.put(`${base_url}estimates/products`, worksheetProduct);
  }

  updateEstimateBudget(worksheetProduct: EstimateWorksheetProduct) {
    return this.http.put(`${base_url}estimateBudget/products`, {
      estimateBudgetActual: {
        id: worksheetProduct.estimateBudgetActual.id,
        worksheetDetailId: worksheetProduct.id,
        qty: worksheetProduct.estimateBudgetActual.qty,
        price: worksheetProduct.estimateBudgetActual.price,
      },
    });
  }

  removeWorksheetProduct(
    estimateId: string,
    orgGuid: string,
    id: number,
    worksheetDetailsList: number[]
  ) {
    return this.http.request(
      "DELETE",
      `${base_url}estimates/${orgGuid}/${estimateId}/products/${id}`,
      {
        body: [...worksheetDetailsList],
      }
    );
  }

  /**
   * Determines whether an Estimate Worksheet can be activated.
   * To do so, the customer must not have an active EstimateWorksheet.
   *
   * @param estimateWorksheetId EstimateWorksheetId
   * @returns Observable<CanActivateEstimate>
   */
  canActivateEstimate(
    estimateWorksheetId: string
  ): Observable<CanActivateEstimate> {
    return this.http.get<CanActivateEstimate>(
      `${base_url}estimates/canActivateEstimate/${estimateWorksheetId}`
    );
  }

  /**
   * Massively updates WorksheetStatus
   *
   * @param estimateWorksheets estimateWorksheets
   */
  changeWorksheetStatus(
    estimateWorksheets: EstimateWorksheetInfo[]
  ): Observable<null> {
    return this.http.put<null>(
      `${base_url}estimates/changeWorksheetStatus/`,
      estimateWorksheets
    );
  }
  /**
   * removes items by kitproduct id on worksheetdetails
   * @param estimateId number
   * @param orgGuid string
   * @param id number
   * @returns Observable<EstimateWorksheetProduct[]>
   */
  removeWorksheetKit(
    estimateId: number,
    orgGuid: string,
    id: number,
    isComplexKit: boolean
  ) {
    return this.http.request<EstimateWorksheetProduct[]>(
      "DELETE",
      `${base_url}estimates/kit/${id}`,
      {
        body: {
          estimateId: estimateId,
          orgGuid: orgGuid,
          id: id,
          isComplexKit: isComplexKit,
        },
      }
    );
  }

  /**
   * Confirms all the changes and updates
   * @param worksheetId
   * @returns
   */
  updateWorksheetWithRelevantChanges(worksheetId: number): Observable<null> {
    return this.http.patch<null>(
      `${environment.baseEstimateUrl}componentRelevantChanges/${worksheetId}`,
      {}
    );
  }

  restoreWorksheetProduct(estimateId: number, orgGuid: string, id: number) {
    return this.http.put<EstimateWorksheetProduct[]>(
      `${base_url}estimates/${orgGuid}/${estimateId}/products/${id}/restore`,
      {}
    );
  }

  restoreWorksheetDetail(worksheetIds: number[]) {
    return this.http.post(
      `${base_url}estimates/products/restore`,
      worksheetIds
    );
  }

  restoreWorksheetKit(
    estimateId: number,
    orgGuid: string,
    id: number,
    isComplexKit: boolean
  ) {
    return this.http.put<EstimateWorksheetProduct[]>(
      `${base_url}estimates/kit/${id}/restore`,
      {
        estimateId: estimateId,
        orgGuid: orgGuid,
        id: id,
        isComplexKit: isComplexKit,
      }
    );
  }

  /**
   * Gives the product data as a worksheetDetail.
   * Used for ADD of worksheetDetails
   * @param estimateWorksheetId
   * @param productId
   * @returns
   */
  getProductAsWorksheetDetail(
    estimateWorksheetId: string,
    productId: number
  ): Observable<WorksheetDetailFromDB> {
    return this.http.get<WorksheetDetailFromDB>(
      `${base_url}estimates/${estimateWorksheetId}/product/${productId}`
    );
  }

  refreshExpirationDate(worksheetId: string, date: Date = null) {
    return this.http.patch(`${base_url}estimates/refresh/expiration`, {
      id: 0,
      guid: worksheetId,
      expirationDate: date,
    });
  }

  updateStatus(status: UpdateWorksheetStatusDTO) {
    return this.http.patch(`${base_url}estimates/update/status`, status);
  }

  duplicateWorksheet(worksheetId: number, newWorksheetName: string) {
    return this.http.post(`${base_url}estimates/duplicate`, {
      worksheetId: worksheetId,
      newWorksheetName: newWorksheetName,
    });
  }

  /** Checks if worksheet have a related SignNow template  */
  checkIfWorksheetHaveTemplate(
    estimateWorksheetId: number
  ): Observable<boolean> {
    return this.http.get<boolean>(
      `${base_url}estimates/sendable/${estimateWorksheetId}`
    );
  }

  unlockEstimateWorksheet(guid: string) {
    return this.http.put(`${base_url}estimates/unlock/${guid}`, null);
  }

  getEstimateWorksheetProductsById(
    estimateId: string,
    orgGuid: string,
    filterParams: WorksheetDetailFilterQuery
  ): Observable<NewEstimateWorksheetInfo> {
    let params = new HttpParams();
    const keys = Object.keys(filterParams);
    keys.forEach((key) => {
      params = params.append(key, filterParams[key]);
    });
    return this.http.get<NewEstimateWorksheetInfo>(
      `${base_url}estimates/${orgGuid}/products/${estimateId}`,
      {
        params: params,
      }
    );
  }

  downloadFiles(filesURLs: string[], filesType: string = null) {
    const requestOptions: Object = {
      responseType: "arraybuffer",
      headers: new HttpHeaders(),
    };
    let url = "";
    if (filesType) url = `${base_url}download/files/${filesType}`;
    else url = `${base_url}download/files`;

    return this.http.post<Blob>(url, filesURLs, requestOptions).pipe(
      map((res: Blob) => {
        const blob = new Blob([res], {
          type: "application/x-zip-compressed",
        });
        return blob;
      })
    );
  }

  toggleWorksheetOptionalDetail(id: number) {
    return this.http.patch(`${base_url}products/optional`, id);
  }

  /**
   * Method to get the estimate view by package
   * @param estimateWorksheetId estimate worksheet guid
   * @param orgGuid organization guid
   * @param isFromEstimate is from estimate
   */
  getEstimateViewByPackage(
    estimateWorksheetId: string,
    orgGuid: string,
    isFromEstimate = false
  ) {
    return this.http.get<EstimateView[]>(
      `${base_url}estimates/${orgGuid}/${estimateWorksheetId}/estimateviews?isFromEstimate=${isFromEstimate}`
    );
  }

  UpdateDrawScheduleIdAndInclusionFlags(
    estimateDrawSchedule: EstimateDrawSchedule
  ) {
    return this.http.put(
      `${base_url}estimates/drawschedule`,
      estimateDrawSchedule
    );
  }

  PostSignNowSigners(signNowSignersPayload: SignNowSignersRequest) {
    return this.http.post(`${base_url}signers`, signNowSignersPayload);
  }
}
