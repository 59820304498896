import { throwError as observableThrowError, Observable } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { AuthGuard } from "../authGuard/auth.guard";
import { MessageService } from "primeng/components/common/messageservice";
import { BaThemeSpinner } from "app/theme/services";
import { environment } from "environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiResponseModel } from "app/model/ApiResponseModel";
import { SharedService } from "./SharedService";
import { ToastrService } from "ngx-toastr";
// tslint:disable:indent

@Injectable()
export class HttpService {
  private baseUrl = environment.baseUrl;
  private baseCoreUrl = environment.baseCoreUrl;
  private baseLocalUrl = "http://localhost:52623/api/";

  constructor(
    private http: HttpClient,
    private authGuard: AuthGuard,
    private sharedService: SharedService,
    private notificationsService: MessageService,
    private _spinner: BaThemeSpinner,
    private toastr: ToastrService
  ) {}

  put<TResponse>(
    url: string,
    requestdata: any
  ): Observable<ApiResponseModel<TResponse>> {
    return this.basePut<ApiResponseModel<TResponse>>(
      this.baseCoreUrl + url,
      requestdata,
      true
    );
  }

  delete<TResponse>(url: string): Observable<ApiResponseModel<TResponse>> {
    return this.baseDelete<ApiResponseModel<TResponse>>(
      this.baseCoreUrl + url,
      true
    );
  }

  post<TResponse>(
    url: string,
    requestdata: any
  ): Observable<ApiResponseModel<TResponse>> {
    return this.basePost<ApiResponseModel<TResponse>>(
      this.baseCoreUrl + url,
      requestdata,
      true
    );
  }

  get<TResponse>(url: string): Observable<ApiResponseModel<TResponse>> {
    return this.baseGet<ApiResponseModel<TResponse>>(
      this.baseCoreUrl + url,
      true
    );
  }

  putRequestService(url, requestdata) {
    return this.basePut<any>(this.baseUrl + url, requestdata);
  }

  postRequestService(url, requestdata): Observable<any> {
    return this.basePost<any>(this.baseUrl + url, requestdata);
  }

  getRequestServiceLocal(url: string): Observable<any> {
    return this.baseGet<any>(this.baseLocalUrl + url);
  }

  postRequestServiceLocal(url, requestdata) {
    return this.basePost<any>(this.baseLocalUrl + url, requestdata);
  }

  putRequestServiceLocal(url, requestdata) {
    return this.basePut<any>(this.baseLocalUrl + url, requestdata);
  }

  getRequestService(url: string, useCore: boolean = false): Observable<any> {
    const theApi = useCore ? this.baseCoreUrl : this.baseUrl;
    return this.baseGet<any>(theApi + url);
  }

  deleteRequestService(url: string) {
    return this.baseDelete<any>(this.baseUrl + url);
  }

  private basePut<TResponse>(
    url,
    requestdata,
    allowAnonymous: boolean = false
  ): Observable<TResponse> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      AppName: "MPB-Web",
    });

    if (this.sharedService.selectedOrganization)
      headers = headers.append(
        "Org-Guid",
        this.sharedService.selectedOrganization.ID
      );

    let token = this.authGuard.getToken(true, allowAnonymous);
    if (token) headers = headers.append("Authorization", token);

    return this.http.put<TResponse>(url, requestdata, { headers }).pipe(
      catchError((err) => {
        switch (err.status) {
          case 401:
            this.notificationsService.add({
              severity: "error",
              summary: "Login",
              detail: "Session Expire!!",
            });

            this.authGuard.logOutUser();
            break;
          case 409:
            return observableThrowError(err);
          default:
            this.notificationsService.add({
              severity: "error",
              summary: "Error",
              detail: "Error Occurred!!",
            });
            this._spinner.hide();
            return observableThrowError(err);
        }
        this._spinner.hide();
      })
    );
  }

  private baseDelete<TResponse>(
    url: string,
    allowAnonymous: boolean = false
  ): Observable<TResponse> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      AppName: "MPB-Web",
    });

    if (this.sharedService.selectedOrganization)
      headers = headers.append(
        "Org-Guid",
        this.sharedService.selectedOrganization.ID
      );

    let token = this.authGuard.getToken(true, allowAnonymous);
    if (token) headers = headers.append("Authorization", token);

    return this.http.delete<TResponse>(url, { headers }).pipe(
      catchError((err) => {
        switch (err.status) {
          case 401:
            this.notificationsService.add({
              severity: "error",
              summary: "Login",
              detail: "Session Expire!!",
            });
            this.authGuard.logOutUser();
            break;
          default:
            this.notificationsService.add({
              severity: "error",
              summary: "Error",
              detail: "Error Occurred!!",
            });
            this._spinner.hide();
            return observableThrowError(err);
        }
        this._spinner.hide();
      })
    );
  }

  private basePost<TResponse>(
    url,
    requestdata,
    allowAnonymous: boolean = false
  ): Observable<TResponse> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      AppName: "MPB-Web",
    });

    if (this.sharedService.selectedOrganization)
      headers = headers.append(
        "Org-Guid",
        this.sharedService.selectedOrganization.ID
      );

    let token = this.authGuard.getToken(true, allowAnonymous);
    if (token) headers = headers.append("Authorization", token);

    let body: any;
    body = JSON.stringify(requestdata);
    return this.http.post<TResponse>(url, body, { headers }).pipe(
      catchError((err) => {
        switch (err.status) {
          case 401:
            this.notificationsService.add({
              severity: "error",
              summary: "Login",
              detail: "Session Expire!!",
            });
            this.authGuard.logOutUser();
            break;
          case 409:
            return observableThrowError(err);
          default:
            // this.notificationsService.add({
            //   severity: "error",
            //   summary: "Error",
            //   detail: "Error Occurred!!",
            // });
            this.toastr.error("Error Occurred!!", "Error");
            this._spinner.hide();
            return observableThrowError(err);
        }
        this._spinner.hide();
      })
    );
  }

  private baseGet<TResponse>(
    url: string,
    allowAnonymous: boolean = false
  ): Observable<TResponse> {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      AppName: "MPB-Web",
    });

    if (
      this.sharedService.selectedOrganization &&
      Object.keys(this.sharedService.selectedOrganization).length > 0
    )
      headers = headers.append(
        "Org-Guid",
        this.sharedService.selectedOrganization.ID
      );

    let token = this.authGuard.getToken(true, allowAnonymous);
    if (token) headers = headers.append("Authorization", token);
    return this.http.get<TResponse>(url, { headers }).pipe(
      catchError((err) => {
        switch (err.status) {
          case 401:
            this.notificationsService.add({
              severity: "error",
              summary: "Login",
              detail: "Unauthorized!!",
            });
            this._spinner.hide();
            this.authGuard.logOutUser();
            break;
          case 302:
            return observableThrowError(err);
          case 306:
            return observableThrowError(err);
          default:
            this.notificationsService.add({
              severity: "error",
              summary: "Error",
              detail: "Error Occurred!!",
            });
            this._spinner.hide();
            return observableThrowError(err);
        }
      })
    );
  }

  getOData<T>(url: string) {
    return this.baseGet<T>(
      this.baseCoreUrl.replace("/api/", "/odata/") + url,
      true
    );
  }

  getRequestServiceNoError(url: string): Observable<any> {
    let headers: HttpHeaders;
    const token = this.authGuard.getToken();
    if (token) {
      headers = new HttpHeaders({
        Authorization: token,
        "Content-Type": "application/json",
        AppName: "MPB-Web",
      });
      return this.http.get(this.baseUrl + url, { headers });
    }
  }

  getNoTokenRequestService(url: string): Observable<any> {
    let headers: HttpHeaders;
    headers = new HttpHeaders({
      "Content-Type": "application/json",
      AppName: "MPB-Web",
    });

    return this.http.get(this.baseUrl + url, { headers });
  }

  getBlobRequestService(url: string): any {
    let headers: HttpHeaders;
    const token = this.authGuard.getToken();
    if (token) {
      headers = new HttpHeaders({
        Authorization: token,
        "Content-Type": "application/json",
        AppName: "MPB-Web",
      });
      return this.http
        .get(this.baseUrl + url, { headers, responseType: "blob" })
        .pipe(
          catchError((err) => {
            switch (err.status) {
              case 401:
                this.notificationsService.add({
                  severity: "error",
                  summary: "Login",
                  detail: "Session Expire!!",
                });
                this._spinner.hide();
                this.authGuard.logOutUser();
                break;
              case 302:
                return observableThrowError(err);
              case 306:
                return observableThrowError(err);
              default:
                this.notificationsService.add({
                  severity: "error",
                  summary: "Error",
                  detail: "Error Occurred!!",
                });
                this._spinner.hide();
                return observableThrowError(err);
            }
          })
        );
    }
  }

  postNoTokenRequestService(url, requestdata) {
    let headers: HttpHeaders;
    headers = new HttpHeaders({
      "Content-Type": "application/json",
      AppName: "MPB-Web",
    });

    let body: any;
    body = JSON.stringify(requestdata);
    return this.http.post(this.baseUrl + url, body, { headers });
  }

  UploadRequestService(url, formData): Observable<any> {
    let headers: HttpHeaders;
    const token = this.authGuard.getToken();
    if (token) {
      headers = new HttpHeaders({
        Authorization: token,
        mimeType: "multipart/form-data",
        AppName: "MPB-Web",
      });

      return this.http.post(this.baseUrl + url, formData, { headers }).pipe(
        catchError((err) => {
          switch (err.status) {
            case 401:
              this.notificationsService.add({
                severity: "error",
                summary: "Login",
                detail: "Session Expire!!",
              });
              this.authGuard.logOutUser();
              this._spinner.hide();
              break;
            default:
              this.notificationsService.add({
                severity: "error",
                summary: "Error",
                detail: "Error Occurred!!",
              });
              this._spinner.hide();
              return observableThrowError(err);
          }
        })
      );
    }
  }

  PostChangeOrderFileService(url: string, formData: FormData) {
    this._spinner.show();
    let headers: HttpHeaders;
    const token = this.authGuard.getToken();
    if (token) {
      headers = new HttpHeaders({
        Authorization: token,
        mimeType: "multipart/form-data",
        AppName: "MPB-Web",
      });

      return this.http.post(this.baseUrl + url, formData, { headers }).pipe(
        catchError((err) => {
          switch (err.status) {
            case 401:
              this.notificationsService.add({
                severity: "error",
                summary: "Login",
                detail: "Session Expire!!",
              });
              this.authGuard.logOutUser();
              break;
            default:
              this.notificationsService.add({
                severity: "error",
                summary: "Error",
                detail: "Error Occurred!!",
              });
              return observableThrowError(err);
          }
        }),
        finalize(() => this._spinner.hide())
      );
    }
  }
}
