import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EstimateWorksheetInfo } from "app/model/EstimateWorksheetInfo";
import { PrincigTemplate } from "app/model/pricingTemplateModel";
import { Project } from "app/model/projectModel";
import { ProjectCategory } from "app/model/projectCategoryModel";
import { WorkAreaSpecs, WorksheetWorkArea } from "app/model/workAreaSpecsModel";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { SharedService } from "../utility/SharedService";

const base_url = environment.baseEstimateUrl;

@Injectable({
  providedIn: "root",
})
export class WizardService {
  constructor(private http: HttpClient, private sharedService: SharedService) {}

  getProjects() {
    const url = `${base_url}Project/List/${this.sharedService.selectedOrganization.ID}`;
    return this.http.get<Project[]>(url);
  }

  getProjectSubType(projectTypeId: number) {
    const url = `${base_url}Projectcategory/List/${projectTypeId}`;
    return this.http.get<ProjectCategory[]>(url);
  }

  getPricingTemplate(projectSubTypeId: number) {
    const url = `${base_url}ProjectPackage/List/${projectSubTypeId}`;
    return this.http.get<PrincigTemplate[]>(url);
  }

  getWorkAreaSpecs(projectPackageId: number) {
    const url = `${base_url}workareaspecs/${projectPackageId}`;
    return this.http.get<WorkAreaSpecs[]>(url);
  }

  AddEstimate(
    estimate: EstimateWorksheetInfo,
    customerId: number,
    customerEmail: string
  ) {
    const url = `${base_url}estimates?customerId=${customerId}&customerEmail=${customerEmail}`;
    return this.http.post(url, estimate);
  }

  //metodo provicional - eliminar
  bulk(listModel: WorksheetWorkArea[]): Observable<Object> {
    const url = `${base_url}bulk`;
    return this.http.post(url, listModel);
  }
}
