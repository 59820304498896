import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ImageAnnotatorComponent } from "../image-annotator.component";
import { SharedModule } from "app/shared/shared.module";
import { ChangeOrderService } from "../../../common/services/changeOrder.service";
@NgModule({
  declarations: [ImageAnnotatorComponent],
  imports: [CommonModule, SharedModule],
  exports: [ImageAnnotatorComponent],
  providers: [ChangeOrderService],
})
export class ImageAnnotatorModule {}
