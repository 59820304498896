import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";
import { environmentConstant } from "app/common/utility/environment";
import { Observable } from "rxjs";
import { SharedService } from "app/common/utility/SharedService";
import { ContractorModel, OrgVendorModel } from "../../model/contractorModel";
@Injectable()
export class ContractorService {
  constructor(
    private httpService: HttpService,
    private sharedService: SharedService
  ) {}

  getAllContractorsByOrgID(): Observable<ContractorModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.contractor +
        "vendor/" +
        this.sharedService.selectedOrganization.ID
    );
  }
  getAllContractorList(): Observable<ContractorModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.contractor +
        this.sharedService.selectedOrganization.ID
    );
  }
  getAllContractors(): Observable<ContractorModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.contractor + "organization"
    );
  }
  getAssignedContractors(orgId: any): Observable<ContractorModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.contractor + orgId + "/notassigned"
    );
  }
  getContractorByVendorId(vendorId) {
    return this.httpService.getRequestService(
      environmentConstant.contractor + vendorId
    );
  }
  getContractorScheduleJobs(vendorId, tradeId) {
    return this.httpService.getRequestService(
      environmentConstant.contractor +
        vendorId +
        "/trade/" +
        tradeId +
        "/organization/" +
        this.sharedService.selectedOrganization.ID
    );
  }
  getContractorFilesByVendorId(vendorId) {
    return this.httpService.getRequestService(
      environmentConstant.contractor + vendorId + "/files/"
    );
  }

  deleteContractorFile(ids: number[]) {
    const params = ids.map((x) => `ids=${x}&`).join("");
    return this.httpService.deleteRequestService(
      environmentConstant.contractor + "vendor/files?" + params
    );
  }

  updateContractor(contractor: OrgVendorModel) {
    return this.httpService.putRequestService(
      environmentConstant.addContractor,
      contractor
    );
  }
  saveContractor(contractor, IsNewVendor) {
    return this.httpService.postRequestService(
      environmentConstant.addContractor + "?IsNewVendor=" + IsNewVendor,
      contractor
    );
  }
  deleteCustomer(id: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.contractor + id
    );
  }
}
