import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";
import { environmentConstant } from "app/common/utility/environment";
import { Observable } from "rxjs";
import { DrawScheduleModel } from "app/model/drawScheduleModel";
import { DrawScheduleTemplate } from "app/model/drawScheduleTemplate";
import { DrawScheduleTemplatePhaseModel } from "app/model/drawScheduleTemplatePhaseModel";
@Injectable()
export class DrawScheduleService {
  constructor(private httpService: HttpService) {}

  getDrawSchedules(orgId: any): Observable<DrawScheduleTemplate[]> {
    return this.httpService.getRequestService(
      environmentConstant.getDrawSchedules + orgId
    );
  }
  getDrawScheduleById(id: any): Observable<DrawScheduleTemplate> {
    return this.httpService.getRequestService(
      environmentConstant.getDrawScheduleById + id
    );
  }
  deleteDrawSchedule(id: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.deleteDrawSchedule + id
    );
  }
  saveDrawSchedule(drawSchedule: DrawScheduleTemplate) {
    return this.httpService.postRequestService(
      environmentConstant.saveDrawSchedule,
      drawSchedule
    );
  }
  updateDrawSchedule(customer: DrawScheduleTemplate) {
    return this.httpService.putRequestService(
      environmentConstant.updateDrawSchedule,
      customer
    );
  }
  deleteDrawScheduletemplatePhase(id: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.deleteDrawScheduletemplatePhase + id
    );
  }
  saveDrawScheduletemplatePhase(
    drawScheduleTemplatePhase: DrawScheduleTemplatePhaseModel
  ) {
    return this.httpService.postRequestService(
      environmentConstant.saveDrawScheduletemplatePhase,
      drawScheduleTemplatePhase
    );
  }
  updateDrawScheduletemplatePhase(
    drawScheduleTemplatePhase: DrawScheduleTemplatePhaseModel
  ) {
    return this.httpService.putRequestService(
      environmentConstant.updateDrawScheduletemplatePhase,
      drawScheduleTemplatePhase
    );
  }
}
