import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";
import { environmentConstant } from "app/common/utility/environment";
import {
  CustomerModel,
  CustomerPhaseEnum,
  CustomerPhaseModel,
  CustomerSearchFilter,
  CustomerSearchModel,
} from "app/model/customerModel";
import { SharedService } from "app/common/utility/SharedService";
import { PasswordModel } from "app/model/PasswordModel";
import { MessageService } from "primeng/components/common/messageservice";
import { AuthGuard } from "app/common/authGuard/auth.guard";
import { BaThemeSpinner } from "app/theme/services";
import { HttpClient } from "@angular/common/http";

import { environment } from "environments/environment";
import { ApiResponseModel } from "app/model/ApiResponseModel";
import { ChangePasswordModel } from "app/model/Core/ChangePasswordModel";
@Injectable()
// tslint:disable:prefer-template
export class CustomerService {
  private baseUrl = environment.baseUrl;
  constructor(
    private httpService: HttpService,
    private sharedService: SharedService,
    private http: HttpClient,
    private notificationsService: MessageService,
    private authGuard: AuthGuard,
    private _spinner: BaThemeSpinner
  ) {}
  getJobList(): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.contractor +
        "/" +
        this.sharedService.selectedOrganization.ID +
        "/jobs"
    );
  }
  getAllCustomerssByOrgId(
    orgId: any,
    roleId: any,
    returnAll: boolean = false
  ): Observable<CustomerModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getCustomer +
        orgId +
        environmentConstant.byroleId +
        roleId +
        `?returnAll=${returnAll}`
    );
  }

  getAllOrganizationContactsAndEmployees(): Observable<CustomerModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getCustomer +
        this.sharedService.selectedOrganization.ID +
        "/all"
    );
  }

  getAllOrganizationContacts(): Observable<CustomerModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getCustomer +
        this.sharedService.selectedOrganization.ID +
        "/contacts"
    );
  }

  getAllOrganizationContactsByBussinessUnitId(
    businessUnitId
  ): Observable<CustomerModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getCustomer +
        this.sharedService.selectedOrganization.ID +
        "/contacts?businessUnitId=" +
        businessUnitId
    );
  }

  getAllOrganizationContactsExceptCustomer(): Observable<CustomerModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getCustomer +
        this.sharedService.selectedOrganization.ID +
        "/assigntocontacts"
    );
  }

  getAllOrganizationCustomers(filter?: any): Observable<CustomerModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getCustomer +
        this.sharedService.selectedOrganization.ID +
        "/customers" +
        "?" +
        filter
    );
  }
  getAllOrganizationCustomerswithOppertnity(): Observable<CustomerModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getCustomer +
        this.sharedService.selectedOrganization.ID +
        "/customerswithoppertunity"
    );
  }
  getAllOrganizationContractors(): Observable<CustomerModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getCustomer +
        this.sharedService.selectedOrganization.ID +
        "/customers/3"
    );
  }
  saveCustomer(customer: CustomerModel, orgId: any) {
    return this.httpService
      .postRequestService(environmentConstant.saveCustomer + orgId, customer)
      .pipe(map((res) => <CustomerModel>res));
  }
  updateCustomer(customer: CustomerModel) {
    return this.httpService.putRequestService(
      environmentConstant.saveCustomer,
      customer
    );
  }
  updateCustomerV2(customer: CustomerModel) {
    return this.httpService.putRequestService(
      environmentConstant.saveCustomer + "v2",
      customer
    );
  }
  getCustomerById(id: any): Observable<CustomerModel> {
    return this.httpService
      .getRequestService(environmentConstant.getCustomerById + id)
      .pipe(map((res) => <CustomerModel>res));
  }
  getLegacyUserbyGuid(userGuid: string) {
    return this.httpService.getRequestService(
      environmentConstant.getLegacyUser + userGuid
    );
  }
  isValidEmail(orgId: any, email: string): Observable<boolean> {
    return this.httpService.getRequestService(
      environmentConstant.getCustomer + orgId + "/email/" + email + "/isvalid"
    );
  }
  isValidUserName(userName: string): Observable<boolean> {
    return this.httpService.getRequestService(
      environmentConstant.isValidUserName + userName + "/isvalid"
    );
  }
  deleteCustomer(orgId: any, id: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.deleteCustomer + orgId + "?customerId=" + id
    );
  }
  getSuperAdmins(): Observable<CustomerModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getSuperAdmin
    );
  }
  getLoginUser(): Observable<CustomerModel> {
    return this.httpService.getRequestService("Account/GetLoginUser/v2");
  }
  changePassword(url, resetPassword: PasswordModel) {
    //   let options: RequestOptions;
    let body: any;
    body = JSON.stringify(resetPassword);
    return this.http.put(this.baseUrl + url, body).pipe(
      catchError((err) => {
        switch (err.status) {
          case 401:
            this.notificationsService.add({
              severity: "error",
              summary: "Login",
              detail: "Session Expire!!",
            });
            this.authGuard.logOutUser();
            break;
          case 409:
            return observableThrowError(err);
          default:
            this.notificationsService.add({
              severity: "error",
              summary: "Error",
              detail: "Error Occurred!!",
            });
            this._spinner.hide();
            return observableThrowError(err);
        }
        this._spinner.hide();
      })
    );
  }

  generatePasswordResetCode(
    model: ChangePasswordModel
  ): Observable<ApiResponseModel<ChangePasswordModel>> {
    return this.httpService.post<ChangePasswordModel>(
      environmentConstant.coreApi.resetPassword(),
      model
    );
  }

  generateResetPasswordCode(
    model: ChangePasswordModel
  ): Observable<ApiResponseModel<ChangePasswordModel>> {
    return this.httpService.post<ChangePasswordModel>(
      environmentConstant.coreApi.resetPasswordCode(),
      model
    );
  }

  resetPassword(
    model: ChangePasswordModel
  ): Observable<ApiResponseModel<ChangePasswordModel>> {
    return this.httpService.put<ChangePasswordModel>(
      environmentConstant.coreApi.resetPassword(),
      model
    );
  }

  getUserListByRoleId(roleId) {
    return this.httpService.getRequestService(
      environmentConstant.organization +
        this.sharedService.selectedOrganization.ID +
        environmentConstant.byroleId +
        roleId
    );
  }

  // To use in UI where selection options will be present.
  static getCustomerPhaseModelList() {
    return (
      Object.keys(CustomerPhaseEnum)
        // typescript generates all an object with every number and string value as a key
        // to emulate an enum. We will just filter on the string value keys.
        .filter((y) => isNaN(Number(y)) && y.toLowerCase() != "none")
        .map((x) => {
          var customerPhase = new CustomerPhaseModel();
          customerPhase.Kind = CustomerPhaseEnum[x];
          customerPhase.Name = x;

          return customerPhase;
        })
    );
  }

  getQtyCustomerDuplicates(
    value: string,
    contactType: string
  ): Observable<number> {
    return this.httpService.getRequestService(
      environmentConstant.qtyCustomerDuplicates +
        this.sharedService.selectedOrganization.ID +
        "/value/" +
        value +
        "/contacttype/" +
        contactType
    );
  }

  postAllOrganizationCustomers(model: CustomerSearchFilter) {
    return this.httpService.post(
      environmentConstant.coreApi.getCustomerList(),
      model
    );
  }
}
