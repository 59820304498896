import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { EstimateProductService } from "app/common/services/estimate-products.service";
import { KitDetailsService } from "app/common/services/kitdetails.service";
import { SharedService } from "app/common/utility/SharedService";
import { EstimateProductModel } from "app/model/EstimateProductModel";
import {
  EstimateWorksheetProduct,
  WorsheetDetailItem,
} from "app/model/EstimateWorksheetProduct";
import {
  Kit,
  KitGroup,
  KitPartProductDetail,
  KitPartSimpleKitDetail,
  KitExtension,
  ProductQuantity,
} from "app/model/KitModel";
import { ProductOption } from "app/model/ProductOptionModel";
import { BaThemeSpinner } from "app/theme";
import { ToastrService } from "ngx-toastr";
import * as _ from "lodash";
import { KitWorksheetAttachabilityResponse } from "app/model/KitWorksheetAttachabilityResponse";
import { WorksheetWorkAreaParameter } from "app/model/WorksheetWorkAreaParameter";
import { MaterialCalculatorService } from "app/common/services/material-calculator.service";
import {
  buildFormulaWithParameterNames,
  getErrorMessageForInifinityResult,
} from "app/common/utility/calculator-utilities";
import {
  MaterialCalculator,
  FormulaEvaluation,
} from "app/model/MaterialsCalculator";
import { Helper } from "app/common/utility/helper";
import { ValidateProductQtys } from "app/common/validators/validate-qty-not-zero";
import { ValidateHasGreaterThanZero } from "app/common/validators/validate-complexkit-qty";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "app-complex-kit-selection-modal",
  templateUrl: "./complex-kit-selection-modal.component.html",
  styleUrls: [
    "../catalog-picker/catalog-picker.scss",
    "./complex-kit-selection-modal.component.scss",
  ],
})
export class ComplexKitSelectionModalComponent implements OnInit, OnDestroy {
  @Input()
  product: EstimateProductModel;
  @Input()
  estimateProduct: EstimateWorksheetProduct;
  @Input()
  worksheetDetailsItems: WorsheetDetailItem[] = [];
  @Input()
  filteredWorksheetProducts: EstimateWorksheetProduct[] = [];
  @Input()
  currentSubTab: number;
  @Input()
  selectOnly: boolean;
  @Input()
  isEdit: boolean;
  @Output()
  handleDisplay = new EventEmitter<boolean>();
  @Output()
  selectedProduct = new EventEmitter<EstimateWorksheetProduct[]>();
  @Input()
  worksheetId: number;
  @Input()
  workAreaParameters: WorksheetWorkAreaParameter[][];
  @Input()
  showCalculatedValues: boolean;
  kit: Kit;
  kitForm: FormGroup;
  kitGroupsForm: FormArray;
  productSearchText: string;
  collapsedKits: KitPartSimpleKitDetail[] = [];
  kitGroups: KitGroup[] = [];
  kitProductDetails: KitPartProductDetail[] = [];
  simpleKitDetails: KitPartSimpleKitDetail[] = [];
  useKitPricing: boolean;
  kitLabel: string = "Add Complex Kit";
  isReview: boolean = false;
  isEditK: boolean = false;
  attachableToWorksheet: boolean = false;
  calculatorsIds: number[] = [];
  kitName: string;
  skuKit: string;
  totalKit: number;
  price: number = 0;
  alive: boolean = false;
  productsQuantity: ProductQuantity[] = [];
  constructor(
    private spinner: BaThemeSpinner,
    private formBuilder: FormBuilder,
    private productService: EstimateProductService,
    private shareService: SharedService,
    private kitService: KitDetailsService,
    private toastrService: ToastrService,
    private calculatorService: MaterialCalculatorService
  ) {}

  /** Returns if the worksheet has the required parameters to calculate a product or kit */
  private getKitEligibilityForWorksheet(): void {
    this.spinner.show();
    this.kitService
      .checkKitWorksheetAvailability(this.calculatorsIds, this.worksheetId)
      .subscribe(
        (data: KitWorksheetAttachabilityResponse) => {
          this.attachableToWorksheet = data.attachableToWorksheet;
          if (!this.attachableToWorksheet) {
            const missingParams = data.missingParameters
              .map((x) => x.parameterName)
              .join(", ");
            const missingParamsQty = data.missingParameters.length > 1;
            this.toastrService.warning(
              `The following parameter${
                missingParamsQty ? "s are" : " is"
              } missing: ${missingParams}.`,
              "Kit can't be attached to Worksheet"
            );
          }
        },
        (error) => {
          this.toastrService.error(
            "Error defining eligibility of kit",
            "Kit Eligibility Validation"
          );
          this.hideSidebar();
        }
      )
      .add(() => this.spinner.hide());
  }

  ngOnInit(): void {
    this.alive = true;
    if (this.filteredWorksheetProducts)
      this.filteredWorksheetProducts = this.filteredWorksheetProducts.filter(
        (x) => !x.deletedOn
      );
    this.setLabel();
    this.spinner.show();
    this.product = this.product || this.estimateProduct.product;
    this.useKitPricing = this.product.useKitPricing;
    this.price = this.product.priceBookPrice;
    this.kitGroupsForm = this.formBuilder.array([]);
    this.kitForm = this.formBuilder.group({
      kitGroupsForm: this.kitGroupsForm,
      complexKitQty: [1, [Validators.required]],
    });

    this.productService
      .getKit(this.product.id, this.shareService.selectedOrganization.ID)
      .subscribe((data) => {
        if (data) {
          this.kit = this.configProductOptions(data);
          this.CheckIfDataResultHasNullValuesInPropertyKitGroupDetails(
            this.kit
          );
          this.kitGroups =
            this.kit &&
            (this.kit.kitGroupDetails !== undefined ||
              this.kit.kitGroupDetails !== null)
              ? this.kit.kitGroupDetails.map((x) => {
                  if (x !== null || x !== undefined) {
                    return x.kitGroup;
                  }
                })
              : [];
          this.kitGroups = this.takeUniqueGroups(this.kitGroups);
          this.kitProductDetails = this.kit
            ? this.kit.kitPartProductDetails
            : [];
          this.simpleKitDetails = this.kit
            ? this.kit.kitPartSimpleKitDetails
            : [];
          this.setCalculatorQtys();
          if (this.worksheetId) {
            this.setCalculatorsIds();
            this.getKitEligibilityForWorksheet();
          } else {
            this.attachableToWorksheet = true;
          }
          this.setPriceToShow();
          this.initForm();
          this.kitName = data.kitProduct.name;
          this.skuKit = data.kitProduct.sku;
        }
      })
      .add(() => this.spinner.hide());
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  setCalculatorQtys() {
    for (let kit of this.simpleKitDetails) {
      if (kit.materialsCalculatorId && this.showCalculatedValues) {
        this.calculatorService
          .evaluateFormulaByWorkAreas(
            this.workAreaParameters,
            kit.materialsCalculator.formula
          )
          .subscribe((data) => (kit.qty = data.result));
      }
    }
  }

  /** Returns an array of all the calculators of the products and kits */
  private setCalculatorsIds() {
    this.kit.kitPartProductDetails.forEach((product) => {
      const { materialsCalculatorId } = product;
      if (materialsCalculatorId) {
        this.calculatorsIds.push(materialsCalculatorId);
      }
    });

    this.kit.kitPartSimpleKitDetails.forEach((kit) => {
      kit.kitPartSimpleKit.kitPartProductDetails.forEach((product) => {
        const { materialsCalculatorId } = product;
        if (materialsCalculatorId) {
          this.calculatorsIds.push(materialsCalculatorId);
        }
      });
    });

    this.calculatorsIds = _.uniq(this.calculatorsIds);
  }

  configProductOptions(data: Kit): Kit {
    const compleKitId = data.kitProduct.id;
    if (this.worksheetDetailsItems.length > 0) {
      data.kitPartProductDetails = data.kitPartProductDetails.filter((x) =>
        this.worksheetDetailsItems.some(
          (y) => y.productId == x.kitPartProduct.id
        )
      );
    }
    data.kitPartProductDetails.forEach((el) => {
      if (this.filteredWorksheetProducts && this.isEdit) {
        const reversedArrayToGetMostCurrents = [
          ...this.filteredWorksheetProducts.filter(
            (x) => x.complexKitId != null
          ),
        ].reverse();
        let productOption: EstimateProductModel =
          reversedArrayToGetMostCurrents.find(
            (x) =>
              x.product.id == el.kitPartProduct.id &&
              x.simpleKitId == null &&
              !x.deletedOn
          ).productOption;

        if (productOption) el.productOption = productOption;

        let product = reversedArrayToGetMostCurrents.find(
          (x) =>
            x.product.id == el.kitPartProduct.id &&
            x.deletedOn == null &&
            x.complexKitId == compleKitId &&
            x.simpleKitId == null
        );

        if (product) {
          el.qty = product.unitQty;
          el.kitPartProduct.priceToShow =
            el.kitPartProduct.usePriceBookPricing && !productOption
              ? el.kitPartProduct.activePrice
              : product.unitPrice;

          if (el.kitPartProduct.usePriceBookPricing) {
            el.kitPartProduct.originalPrice = el.kitPartProduct.activePrice;
          } else if (productOption) {
            el.kitPartProduct.originalPrice = el.kitPartProduct.price;
          } else {
            el.kitPartProduct.originalPrice = product.unitPrice;
          }
        }
      } else if (!this.isEdit) {
        el.kitPartProduct.priceToShow = el.kitPartProduct.activePrice;
        el.kitPartProduct.originalPrice = el.kitPartProduct.activePrice;
      }
      el.kitPartProduct.productOptionsMap =
        el.kitPartProduct.productOptions.map((val) => {
          val.parent = el.kitPartProduct;
          return {
            label: val.productName,
            value: val,
          };
        });
      el.kitPartProduct.productOptionsMap.unshift({
        label: "Select an option",
        value: {
          parent: el.kitPartProduct,
        },
      });
    });

    data.kitPartSimpleKitDetails.forEach((el) => {
      const simpleKitId = el.kitPartSimpleKit.kitProduct.id;
      el.kitPartSimpleKit.kitPartProductDetails.forEach((e) => {
        if (this.isEdit) {
          const currentWorksheetDetailOfProduct =
            this.filteredWorksheetProducts.find(
              (x) =>
                x.deletedOn === null &&
                x.product.id === e.kitPartProduct.id &&
                x.complexKitId === compleKitId &&
                x.simpleKitId === simpleKitId
            );

          if (currentWorksheetDetailOfProduct) {
            e.qty = currentWorksheetDetailOfProduct.unitQty;
            e.productOption = currentWorksheetDetailOfProduct.productOption;
            e.kitPartProduct.priceToShow =
              currentWorksheetDetailOfProduct.unitPrice;
          }
        }

        e.kitPartProduct.productOptionsMap =
          e.kitPartProduct.productOptions.map((val) => {
            val.parent = e.kitPartProduct;
            return {
              label: val.productName,
              value: val,
            };
          });
        e.kitPartProduct.productOptionsMap.unshift({
          label: "Select an option",
          value: {
            parent: e.kitPartProduct,
          },
        });

        e.kitPartProduct.originalPrice = e.kitPartProduct.price;
      });
    });
    return data;
  }

  initForm(): void {
    const complexKitQty =
      (this.estimateProduct && this.estimateProduct.complexKitQty) || 1;

    this.kitForm
      .get("complexKitQty")
      .valueChanges.pipe(takeWhile(() => this.alive))
      .subscribe((qty) => {
        this.calculateTotal(qty);
      });

    this.kitForm.get("complexKitQty").setValue(complexKitQty);

    this.kitGroups.forEach((group) => {
      const simpleKitFormRows = this.formBuilder.array([]);
      const productFormRows = this.formBuilder.array([]);

      const formGroup = this.formBuilder.group({
        simpleKitFormRows: simpleKitFormRows,
        productFormRows: productFormRows,
      });

      this.kitGroupsForm.push(formGroup);

      // build form for simple kits
      this.getSimpleKitsFromGroup(group).forEach((kd) => {
        kd.kitPartSimpleKit.kitProduct.priceToShow =
          kd.kitPartSimpleKit.kitProduct.priceBookPrice * this.validateQty(kd);
        // Active price of simple kits doesnt multiply quantity
        kd.kitPartSimpleKit.kitProduct.activePrice =
          kd.kitPartSimpleKit.kitProduct.priceBookPrice;
        const simpleKitItemFormRows = this.formBuilder.array([]);
        if (this.isEdit) {
          let simpleKitQty = this.getSimpleKitQty(
            kd.kitPartSimpleKit.kitProduct.id
          );
          kd.qty = simpleKitQty;
        }

        let itemKit = this.formBuilder.group({
          qtyControl: [kd.qty],
          simpleKitItemFormRows: simpleKitItemFormRows,
          productId: kd.id,
        });

        this.productsQuantity.push({ id: kd.id, qty: kd.qty });

        itemKit.get("qtyControl").valueChanges.subscribe((value) => {
          const id = itemKit.get("productId").value;
          this.updateProductQtyCheck(id, value);
        });

        itemKit
          .get("qtyControl")
          .setValidators([
            Validators.required,
            ValidateHasGreaterThanZero.checkIfHasGreaterThanZero(
              this.productsQuantity,
              this.kitGroupsForm
            ),
          ]);

        if (this.selectOnly) {
          itemKit.get("qtyControl").disable();
        }
        if (kd.editableQty) {
          this.handleIKitQuantityChange(
            itemKit,
            kd.kitPartSimpleKit.kitProduct
          );
        }
        simpleKitFormRows.push(itemKit);
        // build form for simple kit items
        kd.kitPartSimpleKit.kitPartProductDetails.forEach((item) =>
          this.buildItemFormRows(simpleKitItemFormRows, item, kd)
        );
      });

      // build form for items
      this.getProductsFromGroup(group).forEach((product) => {
        this.buildItemFormRows(productFormRows, product);
      });
    });
  }

  private calculateTotal(qty: number) {
    if (this.kit) {
      this.totalKit = this.useKitPricing
        ? this.product.priceBookPrice * qty
        : this.setTotalByPriceToShow(qty);
    }
  }

  // private getPriceKit(): number {
  //   const totalItems = this.kit.kitPartProductDetails.reduce((total, item) => {
  //     return (total +=
  //       item.kitPartProduct.priceBookPrice * this.validateQty(item));
  //   }, 0);
  //   const totalKits = this.kit.kitPartSimpleKitDetails.reduce((total, item) => {
  //     return (total +=
  //       item.kitPartSimpleKit.kitProduct.priceBookPrice *
  //       this.validateQty(item));
  //   }, 0);
  //   return totalItems + totalKits;
  // }

  private validateQty(
    item: KitPartProductDetail | KitPartSimpleKitDetail
  ): number {
    return item.materialsCalculatorId ? 1 : item.qty;
  }

  private getSimpleKitQty(simpleKitId): number {
    if (this.estimateProduct) {
      let qty = this.estimateProduct.simpleKitQty.find((x) => {
        return x.id === simpleKitId;
      });

      return qty ? qty.qty : 0;
    }
  }

  buildItemFormRows(
    formArr: FormArray,
    item: KitPartProductDetail,
    kitDetail?
  ): void {
    item.qty = Helper.roundNumber(
      item.qty,
      item.kitPartProduct.unitOfMeasure.valueType
    );
    item.kitPartProduct.priceToShow =
      item.kitPartProduct.priceBookPrice * this.validateQty(item);
    const productOptionsControl = new FormControl(item.productOption);

    const formGroup = this.formBuilder.group({
      qtyControl: [item.qty, []],
      productOptionsControl: productOptionsControl,
      productId: item.id,
    });

    // For items inside simple kits
    if (kitDetail) {
      formGroup
        .get("qtyControl")
        .setValidators([
          Validators.required,
          ValidateProductQtys.checkIfQtyIsZero(item.materialsCalculatorId),
        ]);
    } else {
      // For items inside complex Kits
      this.productsQuantity.push({ id: item.id, qty: item.qty });
      formGroup.get("qtyControl").valueChanges.subscribe((value) => {
        const id = formGroup.get("productId").value;
        this.updateProductQtyCheck(id, value);
      });
      formGroup
        .get("qtyControl")
        .setValidators([
          Validators.required,
          ValidateHasGreaterThanZero.checkIfHasGreaterThanZero(
            this.productsQuantity,
            this.kitGroupsForm
          ),
        ]);
    }

    if (this.selectOnly) {
      productOptionsControl.disable();
      formGroup.get("qtyControl").disable();
    }
    if (item.editableQty && kitDetail) {
      this.handleKitItemsQuantitiesChanges(formGroup, item);
    }
    if (item.editableQty && !kitDetail) {
      this.handleItemsQuantityChange(formGroup, item);
    }
    let selected: ProductOption;
    let op: any = item.productOption;
    if (op && op.id > 0) {
      selected = item.kitPartProduct.productOptions.find(
        (x) => x.productId == op.id
      );
    }

    productOptionsControl.setValue(selected);
    formArr.push(formGroup);

    let requiresUserSelection = item.kitPartProduct.productEstimateExt
      ? item.kitPartProduct.productEstimateExt.requiresUserSelection
      : false;

    if (
      item.kitPartProduct.productOptions &&
      item.kitPartProduct.productOptions.length > 0 &&
      requiresUserSelection
    ) {
      productOptionsControl.setValidators(Validators.required);
    }
  }

  getItemFormGroup(groupIndex: number, itemRowIndex: number): AbstractControl {
    const productFormRows = this.kitGroupsForm
      .at(groupIndex)
      .get("productFormRows") as FormArray;
    return productFormRows.at(itemRowIndex);
  }

  getKitItemFormGroup(
    groupIndex: number,
    kitRowIndex: number,
    itemRowIndex: number
  ): AbstractControl {
    const simpleKitFormRows = this.kitGroupsForm
      .at(groupIndex)
      .get("simpleKitFormRows") as FormArray;
    const simpleKitItemFormRows = simpleKitFormRows
      .at(kitRowIndex)
      .get("simpleKitItemFormRows") as FormArray;
    return simpleKitItemFormRows.at(itemRowIndex);
  }

  hasProductOptions(product: EstimateProductModel): boolean {
    return product.productOptions && product.productOptions.length > 0;
  }

  getSimpleKitsFromGroup(group: KitGroup): KitPartSimpleKitDetail[] {
    const res = this.simpleKitDetails.filter(
      (val) => val.kitGroupDetail && val.kitGroupDetail.kitGroup.id === group.id
    );

    return res;
  }

  hasSimpleKitsFromGroup(group: KitGroup): boolean {
    return (
      this.simpleKitDetails.findIndex(
        (val) =>
          val.kitGroupDetail && val.kitGroupDetail.kitGroup.id === group.id
      ) !== -1
    );
  }

  getProductsFromGroup(group: KitGroup): KitPartProductDetail[] {
    const res = this.kitProductDetails.filter(
      (val) => val.kitGroupDetail && val.kitGroupDetail.kitGroup.id === group.id
    );

    return res;
  }

  hasProductsFromGroup(group: KitGroup): boolean {
    return (
      this.kitProductDetails.findIndex(
        (val) =>
          val.kitGroupDetail && val.kitGroupDetail.kitGroup.id === group.id
      ) !== -1
    );
  }

  toggleKitDetailItems(kd: KitPartSimpleKitDetail): void {
    const index = this.collapsedKits.findIndex((val) => val.id === kd.id);

    if (index !== -1) {
      this.collapsedKits.splice(index, 1);
    } else {
      this.collapsedKits.push(kd);
    }
  }

  isKitDetailsCollapsed(kd: KitPartSimpleKitDetail): boolean {
    return this.collapsedKits.findIndex((val) => val.id === kd.id) !== -1;
  }

  getDetailId(
    isEstimate: boolean,
    details: EstimateWorksheetProduct[],
    kitId: number,
    productId: number
  ): number {
    if (!isEstimate) return 0;
    let detailId = details.find(
      (x) =>
        x.simpleKitId == kitId &&
        x.product.id == productId &&
        x.complexKitId == this.estimateProduct.complexKitId
    );
    return detailId ? detailId.id : 0;
  }

  async selectKit() {
    const estimateProducts: EstimateWorksheetProduct[] = [];
    let isGoodToSend: IsGoodToSend = { value: true };
    let listKitGroups = Array.from(this.kitGroups.entries());
    for (const [groupIndex, group] of listKitGroups) {
      const kitGroupForm = this.kitGroupsForm.at(groupIndex);
      const simpleKitFormRows = kitGroupForm.get(
        "simpleKitFormRows"
      ) as FormArray;
      const productFormRows = kitGroupForm.get("productFormRows") as FormArray;
      const entries = Array.from(this.getSimpleKitsFromGroup(group).entries());
      for (const [kitIndex, kit] of entries) {
        const kitItemFormGroup = simpleKitFormRows.at(kitIndex);
        if (kit.materialsCalculatorId && !kit.needsManualQuantity) {
          const result = await this.evaluateFormula(
            kit.materialsCalculator.formula
          );
          const qty = this.handleCalculatorQtys(
            isGoodToSend,
            kit.materialsCalculator,
            kit.kitPartSimpleKit.kitProduct.name,
            result
          );
          kitItemFormGroup.get("qtyControl").setValue(Math.ceil(qty));
        }
        const kitQty = kitItemFormGroup.get("qtyControl").value;
        const simpleKitId = kit.kitPartSimpleKit.kitProduct.id;

        const itemFormArray = kitItemFormGroup.get(
          "simpleKitItemFormRows"
        ) as FormArray;

        for (const [index, item] of Array.from(
          kit.kitPartSimpleKit.kitPartProductDetails.entries()
        )) {
          const product = item.kitPartProduct;
          const itemFormGroup = itemFormArray.at(index);
          if (item.materialsCalculatorId) {
            const result = await this.evaluateFormula(
              item.materialsCalculator.formula
            );
            const calcQty = this.handleCalculatorQtys(
              isGoodToSend,
              item.materialsCalculator,
              item.kitPartProduct.name,
              result
            );
            itemFormGroup.get("qtyControl").setValue(calcQty);
          }
          const complexKitQty = this.kitForm.get("complexKitQty").value;
          const unitQty = Helper.truncateNumber(
            itemFormGroup.get("qtyControl").value,
            6
          );
          const productOption = itemFormGroup.get(
            "productOptionsControl"
          ).value;

          const unitPriceToUse = productOption
            ? productOption.price
            : product.price;

          const qty = Helper.roundNumber(
            unitQty * complexKitQty * kitQty,
            item.kitPartProduct.unitOfMeasure.valueType
          );

          // Products inside simple Kits
          estimateProducts.push({
            id: this.getDetailId(
              this.filteredWorksheetProducts &&
                this.filteredWorksheetProducts.length > 0 &&
                this.isEdit,
              this.filteredWorksheetProducts,
              kit.kitPartSimpleKit.kitProduct.id,
              product.id
            ),
            product: product,
            qty: qty,
            unitPrice: unitPriceToUse,
            kitProductId: kit.kitPartSimpleKit.kitProduct.id,
            kitProduct: kit.kitPartSimpleKit.kitProduct,
            materialsCalculatorId: item.materialsCalculatorId,
            materialsCalculator: item.materialsCalculator,
            worksheetDetailChange:
              KitExtension.getWorksheetDetailChangeForComplex(
                this.worksheetDetailsItems.length > 0,
                this.filteredWorksheetProducts,
                product.id,
                this.kit.kitProduct.id,
                simpleKitId
              ),
            previousQty: KitExtension.getWorksheetDetailPreviousQtyForComplex(
              this.worksheetDetailsItems.length > 0,
              this.filteredWorksheetProducts,
              qty,
              product.id,
              this.kit.kitProduct.id,
              simpleKitId
            ),
            productOption: productOption,
            requiresUserSelection: this.useRequiredSelection(
              productOption,
              product
            ),
            complexKitId: this.kit.kitProduct.id,
            qtyKit: kitQty,
            simpleKitId: simpleKitId,
            needsManualQuantity: item.needsManualQuantity,
            complexKitQty: complexKitQty,
            unitQty: unitQty,
          });
        }
      }

      const groupedProducts = Array.from(
        this.getProductsFromGroup(group).entries()
      );
      for (const [itemIndex, item] of groupedProducts) {
        const productFormGroup = productFormRows.at(itemIndex);
        if (item.materialsCalculatorId && !item.needsManualQuantity) {
          const result = await this.evaluateFormula(
            item.materialsCalculator.formula
          );
          const calcQty = this.handleCalculatorQtys(
            isGoodToSend,
            item.materialsCalculator,
            item.kitPartProduct.name,
            result
          );
          productFormGroup.get("qtyControl").setValue(calcQty);
        }
        const unitQty = productFormGroup.get("qtyControl").value;
        const complexKitQty = this.kitForm.get("complexKitQty").value;
        const qty = Helper.roundNumber(
          unitQty,
          item.kitPartProduct.unitOfMeasure.valueType
        );
        const productOption = productFormGroup.get(
          "productOptionsControl"
        ).value;
        const product = item.kitPartProduct;

        // Products alone
        estimateProducts.push({
          id:
            this.worksheetDetailsItems.length > 0 && this.isEdit
              ? this.worksheetDetailsItems.find(
                  (x) => x.productId == product.id
                ).worhseetDetailId
              : 0,
          product: product,
          qty: qty,
          unitPrice: product.priceToShow,
          kitProductId: this.kit.kitProduct.id,
          kitProduct: this.kit.kitProduct,
          worksheetDetailChange:
            KitExtension.getWorksheetDetailChangeForComplex(
              this.worksheetDetailsItems.length > 0,
              this.filteredWorksheetProducts,
              product.id,
              this.kit.kitProduct.id,
              null
            ),
          previousQty: KitExtension.getWorksheetDetailPreviousQty(
            this.worksheetDetailsItems.length > 0,
            this.filteredWorksheetProducts,
            qty,
            product.id
          ),
          productOption: productOption,
          requiresUserSelection: this.useRequiredSelection(
            productOption,
            product
          ),
          complexKitId: this.kit.kitProduct.id,
          needsManualQuantity: item.needsManualQuantity,
          materialsCalculatorId: item.materialsCalculatorId,
          materialsCalculator: item.materialsCalculator,
          complexKitQty: complexKitQty,
          unitQty: unitQty,
          totalCost: qty * product.price,
        });
      }
    }
    if (isGoodToSend.value) {
      this.selectedProduct.emit(estimateProducts);
    }
    this.handleDisplay.emit(false);
  }

  hideSidebar() {
    this.handleDisplay.emit(false);
  }

  useRequiredSelection(
    optionSelected: any,
    product: EstimateProductModel
  ): boolean {
    return (
      (!optionSelected || optionSelected.id === 0) &&
      product.productOptions.length > 0
    );
  }

  changePrice(e, product: EstimateProductModel): void {
    const po = e.value as ProductOption;
    if (po && po.parent) {
      po.parent.priceToShow = po.price || po.parent.originalPrice;
    }
  }

  private takeUniqueGroups(array: KitGroup[]): KitGroup[] {
    const listOfIds = array.map((x) => x.id);
    const uniqueValues = new Set(listOfIds);
    const uniqueGroups: KitGroup[] = [];

    uniqueValues.forEach((uniqueId) => {
      const group = array.find((x) => x.id == uniqueId);
      uniqueGroups.push(group);
    });

    return uniqueGroups;
  }

  /**
   * sets label text depending on process
   */
  setLabel() {
    if (!this.estimateProduct) return;
    if (this.selectOnly) {
      this.kitLabel = `Review Complex Kit`;
      this.isReview = true;
      this.isEditK = false;
    }
    if (!this.selectOnly && this.isEdit) {
      this.kitLabel = `Edit Kit`;
      this.isEditK = true;
      this.isReview = false;
    }
  }

  /** Process the qtys for the calculator */
  handleCalculatorQtys(
    isGoodToSend: IsGoodToSend,
    calculator: MaterialCalculator,
    productName: string,
    formulaEval: FormulaEvaluation
  ): number {
    const { isInfinite, result } = formulaEval;
    if (isInfinite) {
      const { formula, calculatorName } = calculator;
      const formulaWithNames = buildFormulaWithParameterNames(
        this.workAreaParameters,
        formula
      );
      const errorMessage = getErrorMessageForInifinityResult(
        calculatorName,
        formulaWithNames,
        productName
      );
      this.toastrService.error(errorMessage);
      isGoodToSend.value = false;
    }
    return result;
  }

  /** Evaluate formula in endpoint */
  async evaluateFormula(formula: string): Promise<FormulaEvaluation> {
    return await this.calculatorService
      .evaluateFormulaByWorkAreas(this.workAreaParameters, formula)
      .toPromise();
  }

  /** Handles items included in kits quantity changes */
  private handleKitItemsQuantitiesChanges(
    form: FormGroup,
    kit: KitPartProductDetail
  ) {
    form
      .get("qtyControl")
      .valueChanges.pipe(takeWhile(() => this.alive))
      .subscribe((value: number) => {
        const id = form.get("productId").value;
        const parent = this.kit.kitPartSimpleKitDetails.find((pk) =>
          pk.kitPartSimpleKit.kitPartProductDetails.some((skd) => skd.id === id)
        );
        if (parent.kitPartSimpleKit.kitProduct.useKitPricing) return;
        const parentGroup = this.kitForm.value.kitGroupsForm.find((kgf) =>
          kgf.simpleKitFormRows.some((skfr) => skfr.productId === parent.id)
        );
        const parentControl = parentGroup.simpleKitFormRows.find(
          (pg) => pg.productId === parent.id
        );

        kit.kitPartProduct.priceToShow = kit.kitPartProduct.price * value;
        kit.qty = value;
        const sumTotal = this.sumTotalByPriceToShow(
          parent.kitPartSimpleKit.kitPartProductDetails
        );
        parent.kitPartSimpleKit.kitProduct.priceToShow =
          sumTotal * parentControl.qtyControl;
        parent.kitPartSimpleKit.kitProduct.activePrice = sumTotal;
        this.totalKit = this.useKitPricing
          ? this.product.priceBookPrice
          : this.setTotalByPriceToShow(this.kitForm.get("complexKitQty").value);
      });
  }

  /** sums all prices to show */
  private sumTotalByPriceToShow(kits: KitPartProductDetail[]): number {
    return kits.reduce((total, item) => {
      return (total += item.kitPartProduct.price * this.validateQty(item));
    }, 0);
  }

  /** handles items quantity changes */
  private handleItemsQuantityChange(
    form: FormGroup,
    item: KitPartProductDetail
  ) {
    form
      .get("qtyControl")
      .valueChanges.pipe(takeWhile(() => this.alive))
      .subscribe((value: number) => {
        item.kitPartProduct.priceToShow =
          item.kitPartProduct.priceBookPrice * value;
        const qty = this.kitForm.get("complexKitQty").value;
        this.totalKit = this.useKitPricing
          ? this.product.priceBookPrice * qty
          : this.setTotalByPriceToShow(qty);
      });
  }

  /** handles kits quantity changes */
  private handleIKitQuantityChange(form: FormGroup, kit: EstimateProductModel) {
    form
      .get("qtyControl")
      .valueChanges.pipe(takeWhile(() => this.alive))
      .subscribe((value: number) => {
        const id = form.get("productId").value;
        const kitDetails = this.kit.kitPartSimpleKitDetails.find(
          (x) => x.id === id
        ).kitPartSimpleKit.kitPartProductDetails;
        const qty = this.kitForm.get("complexKitQty").value;
        kit.priceToShow = kit.useKitPricing
          ? kit.priceBookPrice * value
          : this.sumTotalByPriceToShow(kitDetails) * value;
        this.totalKit = this.useKitPricing
          ? this.product.priceBookPrice * qty
          : this.setTotalByPriceToShow(qty);
      });
  }

  /** Set total kit value by prices to show */
  private setTotalByPriceToShow(qty: number) {
    let result = 0;
    this.kit.kitPartProductDetails.forEach(
      (item) => (result += item.kitPartProduct.priceToShow)
    );
    this.kit.kitPartSimpleKitDetails.forEach(
      (x) => (result += x.kitPartSimpleKit.kitProduct.priceToShow)
    );
    return qty * result;
  }

  /** Set initial price to show */
  private setPriceToShow() {
    this.kit.kitPartProductDetails.forEach(
      (detail) =>
        (detail.kitPartProduct.priceToShow =
          detail.kitPartProduct.priceBookPrice * this.validateQty(detail))
    );
    this.kit.kitPartSimpleKitDetails.forEach(
      (detail) =>
        (detail.kitPartSimpleKit.kitProduct.priceToShow =
          detail.kitPartSimpleKit.kitProduct.priceBookPrice *
          this.validateQty(detail))
    );
  }

  private CheckIfDataResultHasNullValuesInPropertyKitGroupDetails(
    dataKit: Kit
  ) {
    dataKit.kitGroupDetails = dataKit.kitGroupDetails.filter((x) => x !== null);
  }

  /**
   * Method to update the product quantity in the array
   */
  private updateProductQtyCheck(id: number, qty: number) {
    const index = this.productsQuantity.findIndex((x) => x.id === id);
    if (index !== -1) {
      this.productsQuantity[index].qty = qty;
    }
  }
}
interface IsGoodToSend {
  value: boolean;
}
