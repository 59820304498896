import { Component, EventEmitter, Output } from "@angular/core";
import { OrganizationService } from "app/common/services/Core/Organization.service";
import { BaThemeSpinner } from "app/theme/services";
import { JobModel } from "app/model/Core/JobModel";
import { FilteredSearchModel, SearchFilter } from "app/model/SearchModel";
import { QuickBooksItemModel } from "app/model/Core/QuickBooksItemModel";
import { SharedService } from "app/common/utility/SharedService";
import { Observable, of } from "rxjs";

@Component({
  selector: "quickbooks-search",
  templateUrl: "./QuickBooksSearch.component.html",
  styleUrls: ["./QuickBooksSearch.css"],
})
export class QuickBooksSearchComponent {
  @Output() public itemSelected: EventEmitter<QuickBooksItemModel> =
    new EventEmitter<QuickBooksItemModel>();

  public items: QuickBooksItemModel[];
  public jobs: JobModel[];

  protected search: FilteredSearchModel<SearchFilter> = <
    FilteredSearchModel<SearchFilter>
  >{
    filter: {
      term: null,
    },
    pagerInfo: {
      getCounts: false,
      take: 25,
      skip: 0,
    },
  };

  public connected: boolean;
  public identifier: string;
  public placeholder: string;
  public context: string;
  public didSearch: boolean = false;

  constructor(
    protected spinner: BaThemeSpinner,
    private sharedService: SharedService,
    protected organizationService: OrganizationService
  ) {}

  protected getStatus(): Observable<boolean> {
    if (this.sharedService.quickBooksConnected) return of(true);

    return Observable.create((observer) => {
      let subscription = this.organizationService
        .getQuickbooksStatus()
        .subscribe((response) => {
          let connected = response.success && response.object;
          this.sharedService.quickBooksConnected = connected;
          if (subscription) subscription.unsubscribe();
          observer.next(connected);
          observer.complete();
        });
    });
  }

  public onItemSelected(item: QuickBooksItemModel): void {
    if (!item) return;
    this.itemSelected.emit(item);
    this.identifier = item.identifier;
  }

  public onSearch(event: KeyboardEvent, input: any): void {
    event.preventDefault();
    event.stopPropagation();

    this.search.filter.term = String(input.value);
    input.value = "";

    this.spinner.show();
    this.items = [];
  }
}
