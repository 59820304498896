import { Injectable, Inject } from "@angular/core";
import { Subject } from "rxjs";
@Injectable()
export class NotifyService {
  private notify = new Subject<any>();
  notifyObservable$ = this.notify.asObservable();

  constructor() {}

  notifyOther(data: any) {
    if (data) {
      this.notify.next(data);
    }
  }
}
