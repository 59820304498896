import { JobNoteModel } from "app/model/jobNoteModel";
import { JobPhasModel } from "app/model/jobPhaseModel";

export class WorkOrderModel {
  ID: string;
  WorkOrderName: string;
  OrgGuid: string;
  WorkOrderTemplateId: number = 12;
  JobAddresId: number;
  CustomerId: number;
  Comments: string;
  ParentJobGuid: string;
  WorkOrderTemplateName: string;
  Status: number;
  Notes: JobNoteModel[];
  Items: JobPhasModel[];
  ParentJobName: string;
  CustomerLastName?: string;
  Color?: string;
  Resources?: string | [];
  StatusId?: number;
  ParentJobId?: string;
  ScheduledDate?: Date;
  constructor() {}
}

export class CalendarSalesAppointmentDTO {
  Contractor: string;
  ContractorGuid?: string | null;
  Ctatus: string;
  JobName: string;
  JobId: string;
  Id: number;
  PhaseName: string;
  StartDate?: Date | null;
  EndDate?: Date | null;
  DaysToComplete: number;
  Color: string;
  OrgGuid: string;
  HomeBuilderId?: number | null;
  CustomerId: number;
  Order: number;
  SeriesGuid?: string | null;
  SeriesId: number;
  ScheduleId?: number | null;
  Resources: any;
  JobTemplateId: number;
  JobTypeName: string;
  StatusId: number;
  ResourceId: number;

  constructor() {}
}
