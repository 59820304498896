import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ImageViewerService {
  private _images: BehaviorSubject<string[]> = new BehaviorSubject([]);
  get images(): Observable<string[]> {
    return this._images.asObservable();
  }
  constructor() {}

  /**
   * Sets images to render viewer
   * @param images An array of images urls
   */
  setViewerImages(images: string[]) {
    this._images.next(images);
  }
}
