import { NotificationsComponent } from "./notifications.component";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { TextBoxModule } from "@progress/kendo-angular-inputs";
import { SidebarModule } from "primeng/primeng";
import { NotificationService } from "../../common/services/notifications.service";
import { routing } from "./notifications.routing";
import { NotificationViewComponent } from "./notificationView/notificationView.component";
import { AppTranslationModule } from "app/app.translation.module";
import { GridModule, ExcelModule } from "@progress/kendo-angular-grid";
import { CommonDataService } from "app/common/services/commonData.service";
import { CommonDirectiveModule } from "app/common/directives/commondirective.module";
import { PipesModule } from "app/shared/pipes.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TextBoxModule,
    SidebarModule,
    routing,
    AppTranslationModule,
    GridModule,
    ExcelModule,
    CommonDirectiveModule,
    PipesModule,
  ],
  declarations: [NotificationsComponent, NotificationViewComponent],
  exports: [NotificationsComponent],
  providers: [NotificationService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NotificationsModule {}
