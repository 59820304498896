import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  Inject,
} from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "estimate-modal",
  templateUrl: "./estimate-modal.component.html",
  styleUrls: ["./estimate-modal.component.scss"],
})
export class EstimateModalComponent implements OnInit {
  @Output() result = new EventEmitter<boolean>(false);
  @Input() title: string = "";
  @Input() message: string = "";
  @Input() approvalButtonText: string;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public dataDialog: {
      title: string;
      message: string;
      approvalButtonText: string;
    }
  ) {
    this.title = dataDialog.title;
    this.message = dataDialog.message;
    this.approvalButtonText = dataDialog.approvalButtonText;
  }

  ngOnInit() {}
}
