<div class="container-fluid p-0 pb-3 vendor-contractor-form-container">
  <div class="section-bg padding-top-1 padding-bottom-1">
    <div class="form-group">
      <div class="row">
        <div class="col-sm-12 mt-2 mb-2">
          <div class="form-group mb0">
            <span class="ui-float-label">
              <input
                id="QuickBooksSearch"
                name="QuickBooksSearch"
                [attr.disabled]="!connected ? '' : null"
                type="text"
                #search
                class="form-control"
                (keydown.enter)="onSearch($event, search)"
                pInputText
              />
              <label for="QuickBooksSearch" class="form-control-label"
                >Search QuickBooks {{ context }}</label
              >
            </span>
          </div>
        </div>
      </div>
      <div *ngIf="items?.length > 0" class="col-sm-12 mt-2 mb-2">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Identifier</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              <tr (click)="onItemSelected(item)" *ngFor="let item of items">
                <td>{{ item.identifier }}</td>
                <td>{{ item.name }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div *ngIf="items?.length <= 0 && didSearch" class="col-sm-12 mt-1">
        <p>No results found.</p>
      </div>
    </div>
  </div>
</div>
