import { Component, Input, OnDestroy } from "@angular/core";
import { ImageInfo } from "app/model/ImageInfo";
import { chain, keyBy } from "lodash";
import { Subscription } from "rxjs";
import { PhotoCarouselService, ShowInterface } from "./photo-carousel.service";

@Component({
  selector: "photo-carousel",
  templateUrl: "./photo-carousel.html",
  styleUrls: ["./photo-carousel.scss"],
})
export class PhotoCarouselComponent implements OnDestroy {
  public width: string = "100%";
  public height: string = "80vh";
  public arrows: boolean = true;
  public thumbnailUrls: string[] = [];
  public fullImageUrls: { [thumbnailUrl: string]: string } = {};
  public _imageUrls: string[] | ImageInfo[];
  @Input() useService = false;
  @Input() set imageUrls(value: string[] | ImageInfo[]) {
    this._imageUrls = value;
    this.thumbnailUrls = [];
    this.fullImageUrls = {};
    if (!value) {
      return;
    }
    if (value.length === 0) {
      return;
    }
    if (typeof value[0] === "string") {
      this.thumbnailUrls = value as string[];
      this.fullImageUrls = keyBy(value as string[]);
    } else {
      const imageInfos = value as ImageInfo[];
      this.thumbnailUrls = imageInfos.map(
        (v) => v.thumbnailUrl || v.fullImageUrl
      );
      this.fullImageUrls = chain(imageInfos)
        .keyBy((img) => img.thumbnailUrl || img.fullImageUrl)
        .mapValues((img) => img.fullImageUrl)
        .value();
    }
  }
  get imageUrls() {
    return this._imageUrls;
  }
  @Input() currentIndex: number;

  carouselSubscription: Subscription;

  private _showing = false;
  get showing() {
    if (this.useService) {
      return this._showing;
    }
    return true;
  }

  constructor(private carouselService: PhotoCarouselService) {
    this.carouselSubscription = this.carouselService.showCarousel.subscribe(
      ({ show, imageUrls, currentIndex }: ShowInterface) => {
        this._showing = show;
        this.imageUrls = imageUrls;
        this.currentIndex = currentIndex;
      }
    );
  }

  ngOnDestroy() {
    this.carouselSubscription.unsubscribe();
  }

  public guardedImageUrls() {
    if (!this.imageUrls) {
      return [];
    }
    return this.imageUrls;
  }

  public hide() {
    this.carouselService.hide();
  }
}
