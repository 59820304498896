import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthenticationService } from "../services/authentication.service";
import { environment } from "environments/environment";
import { MessageService } from "primeng/primeng";

@Injectable()
export class JwtAuthInterceptor implements HttpInterceptor {
  baseUrl = environment.baseEstimateUrl;
  oDataBaseUrl = environment.baseEstimateODataUrl;
  constructor(
    private auth: AuthenticationService,
    private notification: MessageService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = localStorage.getItem("token");

    if (
      request.url.startsWith(this.baseUrl) ||
      (request.url.startsWith(this.oDataBaseUrl) && token)
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json, text/plain, */*",
        },
      });
    }

    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          this.notification.add({
            severity: "error",
            summary: "Login",
            detail: "Session Expire!!",
          });

          this.auth.logout();
        }
        return throwError(err);
      })
    );
  }
}
