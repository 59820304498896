<div class="jobphase-detail">
  <div class="hoverflow">
    <div class="editjobtabform m0 new_work_order">
      <span *ngIf="!IsNew && !isSales">
        <button
          class="mac"
          *ngIf="jobPhase.Status == 2 && isSchedulerRole"
          [disabled]="!permissions.complete"
          (click)="markAsComplete(3)"
        >
          Mark as completed
        </button>
        <button
          class="mac"
          *ngIf="jobPhase.Status == 1 && isSchedulerRole"
          (click)="markAsComplete(2)"
          [disabled]="!permissions.start"
        >
          Start Phase
        </button>
      </span>
      <span *ngIf="!IsNew && isSales && jobPhase.Status === 2">
        <button type="button" class="mac" (click)="onIncrementSale()">
          Promote to next phase
        </button>
      </span>
      <div class="mt15">
        <div class="row">
          <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <div class="ccol1" id="billTo">
                  <div class="Customer">
                    <span class="Customertxt">
                      <span *ngIf="!isSales">Job</span>
                      <span class="customer-sidebar" *ngIf="isSales"
                        >Customer</span
                      >
                    </span>
                    <address class="address">
                      <span *ngIf="JobId">
                        <span>{{ jobData.JobName }}</span>
                        <span>{{ jobData.Address.Address1 }}</span>
                        <span
                          >{{ jobData.Address.City }}
                          <span
                            *ngIf="
                              jobData.Address &&
                              jobData.Address.City &&
                              jobData.Address.State
                            "
                            >,</span
                          >
                          {{ jobData.Address.State
                          }}{{
                            jobData.Address.State &&
                            jobData.Address.StateProvinceAbbreviation
                              ? ","
                              : ""
                          }}{{ jobData.Address.StateProvinceAbbreviation
                          }}{{
                            (jobData.Address.State &&
                              jobData.Address.ZipPostalCode) ||
                            (jobData.Address.StateProvinceAbbreviation &&
                              jobData.Address.ZipPostalCode)
                              ? ","
                              : ""
                          }}{{ jobData.Address.ZipPostalCode }}
                        </span>
                        <span>{{ jobData.Phone }}</span>
                      </span>
                    </address>
                    <button
                      type="button"
                      class="order cursor-pointer"
                      *ngIf="!JobId"
                      (click)="showSlides = 'findJob'"
                    >
                      <i class="fa fa-search"></i> Find Job
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-5 col-sm-6 col-xs-12 pt10">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb18">
                <div class="form-group mb5">
                  <span class="ui-float-label p2-float-label">
                    <input
                      type="number"
                      class="form-control"
                      min="1"
                      pInputText
                      name="Order"
                      pattern="^[1-9][0-9]*$"
                      [(ngModel)]="jobPhase.Order"
                      #Order="ngModel"
                      [ngClass]="{
                        'has-error':
                          Order.invalid && (Order.dirty || Order.touched)
                      }"
                      required
                    />
                    <label for="number" class="form-control-label"
                      >Phase Order</label
                    >
                  </span>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb5">
                <div class="form-group mb5">
                  <kendo-floatinglabel text="Status">
                    <kendo-combobox
                      [data]="statusLst"
                      [valuePrimitive]="true"
                      [filterable]="true"
                      [disabled]="!permissions.editStatus"
                      (filterChange)="filterStatus($event)"
                      [textField]="'Status'"
                      [(ngModel)]="jobPhase.Status"
                      (ngModelChange)="changePhaseStatus()"
                      name="Status"
                      [valueField]="'Value'"
                    ></kendo-combobox>
                  </kendo-floatinglabel>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="col-9">
              <label class="checkbox-inline custom-checkbox">
                <input
                  name="clearToSchedule"
                  type="checkbox"
                  (click)="checkIsReady($event.target.checked)"
                  [(ngModel)]="jobPhase.IsReady"
                />
                <span class="headerCheckbox">Clear To Schedule</span></label
              >
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt15">
            <div class="d-flex">
              <div
                class="form-group Mandat mr-2"
                *ngIf="IsNew && !isCustomPhase"
              >
                <kendo-floatinglabel text="Phase Name" requiredLabel>
                  <kendo-combobox
                    [data]="lstPhase"
                    [valuePrimitive]="true"
                    [filterable]="true"
                    (valueChange)="onChangePhase(jobPhase.OriginalPhaseId)"
                    [ngClass]="{
                      'has-error':
                        OriginalPhaseId.invalid &&
                        (OriginalPhaseId.dirty || OriginalPhaseId.touched)
                    }"
                    (filterChange)="filterPhases($event)"
                    [textField]="'PhaseName'"
                    #OriginalPhaseId="ngModel"
                    [(ngModel)]="jobPhase.OriginalPhaseId"
                    name="OriginalPhaseId"
                    [valueField]="'ID'"
                    required
                  ></kendo-combobox>
                </kendo-floatinglabel>
              </div>
              <div class="form-group Mandat mr-2" *ngIf="isCustomPhase">
                <span class="ui-float-label">
                  <input
                    [appFocus]="true"
                    type="text"
                    class="form-control"
                    pInputText
                    name="PhaseName"
                    #PhaseName="ngModel"
                    [(ngModel)]="jobPhase.PhaseName"
                    (blur)="jobPhase.PhaseName = jobPhase.PhaseName.trim()"
                    [ngClass]="{
                      'has-error':
                        PhaseName.invalid &&
                        (PhaseName.dirty || PhaseName.touched)
                    }"
                    required
                  />
                  <label for="text" class="form-control-label"
                    >Phase Name<span class="text-red"></span
                  ></label>
                </span>
              </div>
              <div>
                <div class="actbtn">
                  <a
                    title="Add Phase"
                    *ngIf="IsNew && !isCustomPhase"
                    (click)="isCustomPhase = true"
                    ><i aria-hidden="true" class="fa fa-plus"></i
                  ></a>
                  <a
                    title="Cancel"
                    *ngIf="IsNew && isCustomPhase"
                    (click)="isCustomPhase = false"
                    ><i aria-hidden="true" class="fa fa-ban"></i
                  ></a>
                </div>
                <!-- <button type="button" class="btn btn-cancel" *ngIf="IsNew && !isCustomPhase"
                                    (click)="isCustomPhase =true;"><i class="fa fa-plus-circle"></i> Add Phase</button>
                                <button type="button" class="btn btn-cancel" *ngIf="IsNew && isCustomPhase"
                                    (click)="isCustomPhase =false;">Cancel</button> -->
              </div>
            </div>
          </div>

          <div
            class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt15"
            *ngIf="!IsNew"
          >
            <div class="form-group">
              <span class="ui-float-label">
                <input
                  [appFocus]="true"
                  type="text"
                  class="form-control"
                  disabled
                  pInputText
                  name="PhaseName"
                  [(ngModel)]="jobPhase.PhaseName"
                />
                <label for="text" class="form-control-label">Phase Name</label>
              </span>
            </div>
          </div>
          <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt15" *ngIf="isCustomPhase">
                        <div class="form-group">
                            <span class="ui-float-label">
                                <input type="text" class="form-control" pInputText name="PhaseName"
                                    [(ngModel)]="jobPhase.PhaseName"  #PhaseName="ngModel" id="PhaseName"
                                    [ngClass]="{'has-error':PhaseName.invalid && (PhaseName.dirty || PhaseName.touched)}"
                                    required>
                                <label for="text" class="form-control-label">Phase Name</label>
                            </span>
                        </div>
                    </div> -->
          <div *ngIf="!isSales" class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
            <div class="form-group">
              <kendo-floatinglabel text="Category">
                <kendo-combobox
                  [data]="catetoryList"
                  [valuePrimitive]="true"
                  [filterable]="true"
                  (filterChange)="filterCategory($event)"
                  [textField]="'CatetoryName'"
                  [(ngModel)]="jobPhase.CategoryId"
                  name="CategoryId"
                  [valueField]="'Id'"
                >
                </kendo-combobox>
              </kendo-floatinglabel>
            </div>
          </div>
          <div *ngIf="!isSales" class="col-md-4 col-sm-6 col-xs-12">
            <div class="form-group">
              <kendo-floatinglabel text="Business Unit">
                <kendo-combobox
                  [data]="lstBusinessUnit"
                  [valuePrimitive]="true"
                  [filterable]="true"
                  (filterChange)="filterBussinessUnit($event)"
                  [textField]="'BusinessUnit1'"
                  [(ngModel)]="jobPhase.BusinessUnitId"
                  name="businessUnitId"
                  [valueField]="'ID'"
                >
                </kendo-combobox>
              </kendo-floatinglabel>
            </div>
          </div>
          <div *ngIf="!isSales" class="col-md-4 col-sm-6 col-xs-12">
            <div class="form-group">
              <kendo-floatinglabel text="Priority">
                <kendo-combobox
                  [data]="priorities"
                  [valuePrimitive]="true"
                  [filterable]="true"
                  [ngClass]="{
                    'has-error':
                      Priority.invalid && (Priority.dirty || Priority.touched)
                  }"
                  (filterChange)="filterPriority($event)"
                  [textField]="'key'"
                  [(ngModel)]="jobPhase.Priority"
                  name="Priority"
                  #Priority="ngModel"
                  [valueField]="'value'"
                ></kendo-combobox>
              </kendo-floatinglabel>
            </div>
          </div>
        </div>
        <div class="background-p2">
          <div class="row p2row-bg">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h3 class="pt">Schedule</h3>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div class="form-group">
                <label class="fu">Target Date</label>
                <span class="ui-float-label">
                  <kendo-datepicker
                    class="full-date"
                    name="EstStartDate"
                    [class.readonly]="!isSchedulerRole"
                    [class.empty]="!jobPhase.EstStartDate"
                    [(ngModel)]="jobPhase.EstStartDate"
                    (valueChange)="setTargetDate(jobPhase.EstStartDate)"
                    #EstStartDate="ngModel"
                    [readonly]="!isSchedulerRole"
                  >
                  </kendo-datepicker>
                </span>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div class="form-group">
                <label class="fu">Scheduled Date</label>
                <span class="ui-float-label">
                  <kendo-datepicker
                    class="full-date"
                    name="ScheduledDate"
                    id="ScheduledDate"
                    [class.readonly]="!isSchedulerRole"
                    [class.empty]="!jobPhase.ScheduledDate"
                    [(ngModel)]="jobPhase.ScheduledDate"
                    (valueChange)="setScheduleDate(jobPhase.ScheduledDate)"
                    #ScheduledDate="ngModel"
                    [readonly]="!isSchedulerRole"
                  >
                  </kendo-datepicker>
                </span>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div class="form-group mar15-p2">
                <label class="fu">Completed On</label>
                <span class="ui-float-label">
                  <kendo-datepicker
                    class="full-date"
                    name="ActualEndDate"
                    [class.readonly]="!isSchedulerRole"
                    [class.empty]="!jobPhase.ActualEndDate"
                    [(ngModel)]="jobPhase.ActualEndDate"
                    (valueChange)="changeStatus(jobPhase.ActualEndDate)"
                    [ngClass]="{
                      'has-error':
                        ActualEndDate.invalid &&
                        (ActualEndDate.dirty || ActualEndDate.touched)
                    }"
                    #ActualEndDate="ngModel"
                    [readonly]="!isSchedulerRole"
                  >
                  </kendo-datepicker>
                </span>
              </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div class="form-group Mandat">
                <span class="ui-float-label">
                  <input
                    type="number"
                    class="form-control"
                    min="1"
                    pInputText
                    name="DaysToComplete"
                    [class.readonly]="!isSchedulerRole"
                    [(ngModel)]="jobPhase.DaysToComplete"
                    (ngModelChange)="
                      onDaysCompleteChanged(jobPhase.DaysToComplete)
                    "
                    #DaysToComplete="ngModel"
                    [ngClass]="{
                      'has-error':
                        DaysToComplete.invalid &&
                        (DaysToComplete.dirty || DaysToComplete.touched)
                    }"
                    required
                    [readonly]="!isSchedulerRole"
                  />
                  <label for="number" class="form-control-label"
                    >Days To Complete</label
                  >
                </span>
              </div>
            </div>
            <!-- <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                            <div class="form-group Mandat">
                                <span class="ui-float-label">
                                    <input type="number" class="form-control" min="1" pInputText name="DaysToCompleteLong"
                                        [(ngModel)]="jobPhase.DaysToCompleteLong"
                                        #DaysToCompleteLong="ngModel"
                                        [ngClass]="{'has-error':DaysToCompleteLong.invalid && (DaysToCompleteLong.dirty || DaysToCompleteLong.touched)}"
                                        required
                                        [readonly]="!isSchedulerRole">
                                    <label for="number" class="form-control-label ">Days To Complete Long</label>
                                </span>
                            </div>
                        </div> -->
            <div class="col-sm-6">
              <div class="Calinmarket ml0">
                <span *ngIf="jobPhase.ActualStartDate"
                  >This phase was marked started on :
                  {{ jobPhase.ActualStartDate | date : "shortDate" }}
                  <span class="calin-icons">
                    <i
                      *ngIf="!EnableStartDateEdit"
                      class="fa fa-pencil"
                      title="Edit"
                      (click)="editStartDate(true)"
                    ></i>
                    <i
                      class="fa fa-ban"
                      *ngIf="EnableStartDateEdit"
                      title="Cancel"
                      (click)="editStartDate(false)"
                    ></i
                  ></span>
                </span>
              </div>
            </div>
            <div class="col-6">
              <request-for-service
                *ngIf="showRequestServiceBadge"
                (requestForServiceEmitter)="openJobPhaseScheduling()"
              >
              </request-for-service>
            </div>
            <div
              *ngIf="EnableStartDateEdit"
              class="col-lg-6 col-md-6 col-sm-6 col-xs-12"
            >
              <div class="form-group">
                <label class="fu">Started On</label>
                <span class="ui-float-label">
                  <kendo-datepicker
                    class="full-date"
                    name="ActualStartDate"
                    [(ngModel)]="jobPhase.ActualStartDate"
                    #ActualStartDate="ngModel"
                  >
                  </kendo-datepicker>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div *ngIf="!isSales" class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="form-group muselect jobphase multilabel">
              <span class="ui-float-label">
                <kendo-floatinglabel text="Parent Phases">
                  <kendo-multiselect
                    [data]="lstActiveJobPhases"
                    [filterable]="true"
                    (filterChange)="filterDependents($event)"
                    id="Dependents"
                    pInputText
                    #Dependents="ngModel"
                    [textField]="'PhaseName'"
                    [valueField]="'ID'"
                    name="Dependents"
                    [(ngModel)]="jobPhase.Dependents"
                  ></kendo-multiselect>
                </kendo-floatinglabel>
              </span>
            </div>
          </div>
          <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="additem calendar assignto-item clearfix">
              <div class="addbtn">
                <a
                  data-placement="top"
                  data-toggle="tooltip"
                  title="Assign To"
                  (click)="showSlides = 'showAssignTo'"
                  *ngIf="permissions.assign"
                >
                  <i aria-hidden="true" class="fa fa-plus-circle"></i> Assign To
                </a>
                <span *ngIf="!permissions.assign">Assigned To</span>
              </div>
            </div>
          </div> -->
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="additem calendar d-flex assignto-item clearfix">
              <h3>Assign this phase To</h3>
              <div class="addbtn float-right">
                <a
                  data-placement="top"
                  data-toggle="tooltip"
                  title="Assign To"
                  (click)="showSlides = 'showAssignTo'"
                  *ngIf="permissions.assign"
                  class="text-decoration-underline"
                >
                  Advanced
                </a>
                <span *ngIf="!permissions.assign">Assigned To</span>
              </div>
            </div>
            <div class="form-group muselect jobphase multilabel">
              <span class="ui-float-label">
                <kendo-floatinglabel
                  [text]="
                    !jobPhase.Resources || jobPhase.Resources.length == 0
                      ? 'Assign To'
                      : ''
                  "
                >
                  <kendo-multiselect
                    #resourcesMultiselect
                    [data]="lstJobPhaseResources"
                    [filterable]="true"
                    (filterChange)="filterPhaseResources($event)"
                    id="Resources"
                    [textField]="'Name'"
                    [valueField]="'Id'"
                    name="Resources"
                    [(ngModel)]="jobPhase.Resources"
                    (valueChange)="onPhaseResourcesChange($event)"
                  ></kendo-multiselect>
                </kendo-floatinglabel>
              </span>
            </div>
          </div>
        </div>
        <!-- <div class="background-p2 p2backgpad">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="form-group">
                        <div class="formfield">
                            <div class="checkbox">
                                <input type="checkbox" [(ngModel)]="jobPhase.UseContractorOrg" name="UseContractorOrg"
                                    (ngModelChange)="contractList()">
                            </div>
                            <label for="number" class="form-control-label ">The owner of this phase will be company
                                personnel</label>
                        </div>
                    </div>
                </div>

                <div [ngClass]="id ===0 ? 'col-md-12' : 'col-md-12'">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12" *ngIf="!jobPhase.UseContractorOrg">
                            <div class="form-group Mandat mar15-p2">
                                <span class="ui-float-label p2-float-label" *ngIf="jobPhase.TradeId">
                                    <input type="text" class="form-control" disabled pInputText name="TradeNameText"
                                        [(ngModel)]="jobPhase.TradeName">
                                    <label for="text" class="form-control-label">Trade Name</label>
                                </span>
                                <kendo-textbox-container *ngIf="!jobPhase.TradeId" floatingLabel="Trade Name" [ngClass]="{'k-state-filled':true}">
                                    <kendo-combobox [data]="lstTrade" [valuePrimitive]="true" [filterable]="true"
                                        (filterChange)="filterTrade($event)" (valueChange)="getTradesById(tradeID)"
                                        [textField]="'TradeName'" [(ngModel)]="tradeID" name="tradeID" [valueField]="'ID'"></kendo-combobox>
                                </kendo-textbox-container>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group mar15-p2">
                                <kendo-textbox-container floatingLabel="Contractor" [ngClass]="{'k-state-filled':true}">
                                    <kendo-combobox [data]="lstContractors" [valuePrimitive]="true" [filterable]="true"
                                        (filterChange)="filterContractor($event)" [textField]="'Company'" [(ngModel)]="jobPhase.SubContractorId"
                                        name="SubContractorId" [valueField]="'Id'"></kendo-combobox>
                                </kendo-textbox-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
      </div>
    </div>
  </div>
  <div class="{{ sideBarClass }}" [ngClass]="showSlides != '' ? 'open' : ''">
    <a class="cancelbtnHead ui-sidebar-close" (click)="showSlides = ''">
      <span class="pi pi-times"></span>
    </a>
    <AssignResource-Component
      *ngIf="showSlides == 'showAssignTo'"
      [AssignedResourceList]="lstAssignedResource"
      [workOrderId]="JobId"
      [LineItemId]="PhaseId"
      [TradeID]="jobPhase.TradeId"
      [BusinessUnitID]="jobPhase.BusinessUnitId"
      [StartDate]="jobPhase.ScheduledDate"
      (sendData)="updateAssignedResource($event)"
    ></AssignResource-Component>
    <assign-job
      *ngIf="showSlides == 'findJob'"
      (sendData)="getJobData($event)"
    ></assign-job>
    <job-phase-scheduleing
      *ngIf="showSlides == 'schedulenow'"
      [JobId]="jobPhase.JobID"
      [phase]="jobPhase"
      (closeSideBar)="hideJobPhaseScheduling($event)"
      [displayFromSidebar]="true"
      [parentIsSecondSidebar]="isSlideOut"
    ></job-phase-scheduleing>
  </div>
  <buildPlan-dialog
    *ngIf="showDialog"
    [showReason]="noReason"
    (closeDialog)="closeSchedule($event)"
    [updateType]="dateType"
    (sendReason)="scheduleReason($event)"
  ></buildPlan-dialog>
</div>
