import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppTranslationModule } from "../../app.translation.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "app/shared/shared.module";
import { AuthenticationService } from "../../common/services/authentication.service";
import { routing } from "./account.routing";
import { HttpService } from "../../common/utility/http.service";
import { AuthGuard } from "../../common/authGuard/auth.guard";
import { InputTextModule } from "primeng/primeng";
import { accountComponent } from "app/pages/account/account.component";
import { changePasswordComponent } from "app/pages/account/changePassword/changePassword.component";
import { TextBoxModule } from "@progress/kendo-angular-inputs";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { GridModule } from "@progress/kendo-angular-grid";
import { changePasswordService } from "app/common/services/changePassword.service";
import { SetupPasswordComponent } from "./setup-password/setup-password.component";
@NgModule({
  imports: [
    CommonModule,
    AppTranslationModule,
    ReactiveFormsModule,
    FormsModule,
    InputTextModule,
    SharedModule,
    routing,
    TextBoxModule,
    LayoutModule,
    GridModule,
  ],
  declarations: [
    accountComponent,
    changePasswordComponent,
    SetupPasswordComponent,
  ],
  providers: [
    HttpService,
    AuthGuard,
    AuthenticationService,
    changePasswordService,
  ],
  exports: [SetupPasswordComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AccountModule {}
