import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { Router } from "@angular/router";
import { BreadcrumbService } from "app/common/services/Core/Breadcrumb.service";
import { EstimateWorksheetService } from "app/common/services/estimate-worksheet.service";
import { JobService } from "app/common/services/job.service";
import {
  EstimateWorksheetInfo,
  WorksheetStatus,
} from "app/model/EstimateWorksheetInfo";
import { JobContactInfoModel } from "app/model/jobContactModel";
import { BaThemeSpinner } from "app/theme/services/baThemeSpinner";
import { tap } from "rxjs/operators";
import { EstimateWizardModalComponent } from "../estimate-wizard-modal/estimate-wizard-modal.component";

@Component({
  selector: "app-estimate-list-modal",
  templateUrl: "./estimate-list-modal.component.html",
  styleUrls: ["./estimate-list-modal.component.scss"],
})
export class EstimateListModalComponent implements OnInit {
  private jobId: string;
  jobName: string;
  private customerGuid: string;
  estimates: EstimateWorksheetInfo[];
  private selectedEstimate: EstimateWorksheetInfo;
  worksheetStatusList = WorksheetStatus;
  openUpdateDialog: boolean = false;
  worksheetForModal: EstimateWorksheetInfo;
  affectBreadCrumb: boolean;
  markupPercentage: number;
  isHomebuilderSelected: boolean;

  constructor(
    private router: Router,
    private spinner: BaThemeSpinner,
    private estimateService: EstimateWorksheetService,
    private matDialogService: MatDialog,
    private dialogRef: MatDialogRef<EstimateListModalComponent>,
    private breadcrumbService: BreadcrumbService,
    @Inject(MAT_DIALOG_DATA)
    data: {
      jobId: string;
      jobName: string;
      estimates: EstimateWorksheetInfo[];
      customerGuid: string;
      affectBreadCrumb?: boolean;
      markupPercentage: number;
      isHomebuilderSelected: boolean;
    }
  ) {
    this.jobId = data.jobId;
    this.jobName = data.jobName;
    this.estimates = data.estimates;
    this.selectedEstimate = this.estimates[0];
    this.customerGuid = data.customerGuid;
    this.affectBreadCrumb = data.affectBreadCrumb;
    this.markupPercentage = data.markupPercentage;
    this.isHomebuilderSelected = data.isHomebuilderSelected;
  }

  get WorksheetStatusEnum() {
    return WorksheetStatus;
  }

  ngOnInit(): void {
    this.markupPercentage = this.markupPercentage ? this.markupPercentage : 0;
    if (this.affectBreadCrumb) {
      this.breadcrumbService.clearSegments(
        "SALES FUNNEL",
        "/pages/sales/funnel"
      );
    }
  }

  close() {
    this.dialogRef.close();
    this.openUpdateDialog = false;
  }

  createEstimate() {
    this.dialogRef.close();
    this.matDialogService.open(EstimateWizardModalComponent, {
      panelClass: "estimate-list-dialog",
      data: {
        jobId: this.jobId,
        jobName: this.jobName,
        customerGuid: this.customerGuid,
        markupPercentage: this.markupPercentage,
        isHomebuilderSelected: this.isHomebuilderSelected,
      },
    });
  }

  deleteEstimate(estimate: EstimateWorksheetInfo) {
    this.spinner.show();
    this.estimateService
      .deleteEstimate(estimate.guid)
      .subscribe(() => {
        const itemIndex = this.estimates.indexOf(estimate);
        this.estimates.splice(itemIndex, 1);
      })
      .add(() => this.spinner.hide());
  }

  selectEstimate(estimate: EstimateWorksheetInfo) {
    this.selectedEstimate = estimate;
  }

  openEstimate(estimate: EstimateWorksheetInfo): void {
    if (estimate.needsRecalculation || estimate.isThereNewActivePriceBook) {
      this.showUpdateRequiredModal(estimate);
    } else {
      this.router.navigate([`/pages/estimate/worksheet/${estimate.guid}`]);
      this.dialogRef.close();
    }
  }

  showUpdateRequiredModal(worksheet: EstimateWorksheetInfo): void {
    this.worksheetForModal = worksheet;
    this.openUpdateDialog = true;
  }

  closeUpdateModal(): void {
    this.openUpdateDialog = false;
  }
}
