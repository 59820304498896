import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { JobService } from "app/common/services/job.service";
import { ImageInfo } from "app/model/ImageInfo";
import { JobPhotoModel } from "app/model/jobPhotoModel";
import { UserPhotoModel } from "app/model/userPhotoModel";
import { DialogService } from "ng2-bootstrap-modal";
import { ConfirmComponent } from "../dialog/dialog.component";
import {
  ImageAnnotatorService,
  JobPhotoForAnnotation,
} from "../image-annotator/image-annotator.service";
import { ShareJobfileDialogComponent } from "../share-jobfile-dialog/share-jobfile-dialog.component";
import heic2any from "heic2any";
import { DatePipe } from "@angular/common";
@Component({
  selector: "app-image-thumbnail",
  templateUrl: "./image-thumbnail.component.html",
  styleUrls: ["./image-thumbnail.component.scss"],
})
export class ImageThumbnailComponent implements OnInit {
  @Input() jobPhoto: JobPhotoModel;
  @Input() userPhoto: UserPhotoModel;
  @Input() checkbox = false;
  @Input() clickable = true;
  @Input() canAnnotate = true;
  @Input() canPin = true;
  @Input() canDelete = true;
  @Input() hover = true;
  @Input() allUserPhotos: UserPhotoModel[];
  @Input() allJobPhotos: JobPhotoModel[];
  @Input() imageWidth?: number | string;
  @Input() jobId: string;

  @Output() onChecked = new EventEmitter<boolean>();
  @Output() onDelete = new EventEmitter<UserPhotoModel>();
  @Output() onUpdated = new EventEmitter<UserPhotoModel>();
  @Output() openPreview = new EventEmitter<number>();

  visibleSidebar = false;
  showDetails = false;
  thumbnailHeic: string;
  plexPhoto: UserPhotoModel;
  readonly dateFormat: string = "MM/dd/yyyy HH:mm";

  constructor(
    private annotatorService: ImageAnnotatorService,
    private jobService: JobService,
    private dialogService: DialogService
  ) {}

  ngOnInit() {
    this.plexPhoto = this.getPlexPhoto();
    if (this.thumbnail.toLowerCase().includes(".heic") && !this.thumbnailHeic) {
      this.heicToJpg(this.thumbnail);
    }
  }

  get thumbnail() {
    if (this.plexPhoto.ThumbnailPath) {
      return this.thumbnailHeic
        ? this.thumbnailHeic
        : this.plexPhoto.ThumbnailPath;
    }
    return this.plexPhoto.PhotoPath;
  }

  get selected() {
    if (this.jobPhoto) {
      return this.jobPhoto.selected;
    }
    return false;
  }
  set selected(value: boolean) {
    if (this.jobPhoto) {
      this.jobPhoto.selected = value;
    }
  }

  get titleDisplay() {
    if (!this.plexPhoto.Title) {
      return "";
    }
    return this.plexPhoto.Title.length > 30
      ? this.plexPhoto.Title.substring(0, 30) + "..."
      : this.plexPhoto.Title;
  }

  getPlexPhoto(): UserPhotoModel {
    if (!this.userPhoto && !this.jobPhoto) {
      throw "userPhoto and jobPhoto cannot both be undefined; one of them must be provided";
    }
    return {
      JobFileId: this.userPhoto ? this.userPhoto.JobFileId : this.jobPhoto.ID,
      JobFileGuid: this.userPhoto
        ? this.userPhoto.JobFileGuid
        : this.jobPhoto.Guid,
      JobGuid: this.userPhoto ? this.userPhoto.JobGuid : this.jobPhoto.JobGUID,
      PhotoPath: this.userPhoto
        ? this.userPhoto.PhotoPath
        : this.jobPhoto.BlobUrl,
      ThumbnailPath: this.userPhoto
        ? this.userPhoto.ThumbnailPath || this.userPhoto.PhotoPath
        : this.jobPhoto.ThumbnailUrl || this.jobPhoto.BlobUrl,
      PhotoName: this.userPhoto
        ? this.userPhoto.PhotoName
        : this.jobPhoto.FileName,
      Title: this.userPhoto ? this.userPhoto.Title : this.jobPhoto.Title,
      Description: this.userPhoto
        ? this.userPhoto.Description
        : this.jobPhoto.Description,
      OwnerInitials: this.userPhoto ? this.userPhoto.OwnerInitials : "",
      DisplayDateTime: "",
      CreatedOn: this.userPhoto
        ? this.userPhoto.CreatedOn
        : (this.jobPhoto.CreatedOn as unknown as string),
      Comments: this.userPhoto
        ? this.userPhoto.Comments
        : this.jobPhoto.Comments,
      Tags: this.userPhoto ? this.userPhoto.Tags : this.jobPhoto.Tags,
      IsJobThumbnail: this.userPhoto
        ? this.userPhoto.IsJobThumbnail
        : this.jobPhoto.IsJobThumbnail,
      JobChangeOrderId: this.userPhoto
        ? this.userPhoto.JobChangeOrderId
        : this.jobPhoto.JobChangeOrderId,
      DisplayCreatedOn: this.userPhoto
        ? this.userPhoto.DisplayCreatedOn
          ? this.getDateTakenFormatted(this.userPhoto.DisplayCreatedOn)
          : this.getDateTakenFormatted(this.userPhoto.DisplayDateTime)
        : this.getDateTakenFormatted(this.jobPhoto.DisplayCreatedOn),
      UploadedOn: this.userPhoto
        ? this.getDateTakenFormatted(this.userPhoto.UploadedOn, true)
        : this.getDateTakenFormatted(this.jobPhoto.UploadedOn, true),
    };
  }

  get isPinned() {
    return this.plexPhoto.IsJobThumbnail;
  }

  set description(value: string) {
    this.plexPhoto.Description = value;
    const photo: { Description: string } = this.userPhoto || this.jobPhoto;
    photo.Description = value;
  }
  get description() {
    return this.plexPhoto.Description;
  }

  set title(value: string) {
    this.plexPhoto.Title = value;
    const photo: { Title: string } = this.userPhoto || this.jobPhoto;
    photo.Title = value;
  }
  get title() {
    return this.plexPhoto.Title;
  }

  actionStyle() {
    if (!this.hover) {
      return {
        display: "inline-block",
      };
    }
    return {};
  }

  pinnedStyle() {
    const style = this.actionStyle();
    if (this.isPinned) {
      return {
        ...style,
        display: "inline-block",
      };
    }
    return style;
  }

  pinnedWrapperStyle() {
    if (this.isPinned) {
      return {
        position: "relative",
        left: "-2px",
        top: "-2px",
      };
    }
  }

  heicToJpg(heicPath) {
      fetch(heicPath)
      .then((res) => res.blob())
      .then((blob) => {
        if (blob.type == "image/jpeg") {
          this.thumbnailHeic = heicPath;
        } else {
          heic2any({
            blob,
            toType: "image/jpeg",
            quality: 0.5,
          }).then((jpgResult) => {
            let reader = new FileReader();
            reader.onload = (r) =>
              (this.thumbnailHeic = reader.result as string);
            reader.readAsDataURL(jpgResult as Blob);
          }).catch((e) => {
            this.thumbnailHeic = heicPath;
          });
        }
      });
  }

  annotatePhoto(readonly = false) {
    const photo = this.plexPhoto;
    const photoForAnnotation: JobPhotoForAnnotation = {
      ID: photo.JobFileId,
      FileName: photo.PhotoName,
      BlobUrl: photo.PhotoPath,
      JobChangeOrderId: photo.JobChangeOrderId ? photo.JobChangeOrderId : null,
      JobGUID: this.jobId,
    };
    const handle = this.annotatorService.show({
      photo: photoForAnnotation,
      readonly,
    });
    handle.accepted.subscribe(() => {
      this.onUpdated.emit(photo);
    });
  }

  deletePhoto() {
    this.onDelete.emit(this.plexPhoto);
  }

  onPreview() {
    let imageUrls: ImageInfo[];
    if (this.allUserPhotos) {
      imageUrls = this.allUserPhotos.map((p) => ({
        fullImageUrl: p.PhotoPath,
        thumbnailUrl: p.ThumbnailPath,
      }));
    } else if (this.allJobPhotos) {
      imageUrls = this.allJobPhotos.map((p) => ({
        fullImageUrl: p.BlobUrl,
        thumbnailUrl: p.ThumbnailUrl,
      }));
    } else {
      imageUrls = [
        {
          fullImageUrl: this.plexPhoto.PhotoPath,
          thumbnailUrl: this.plexPhoto.ThumbnailPath,
        },
      ];
    }
    const currentIndex = imageUrls.findIndex(
      (img) => img.fullImageUrl === this.plexPhoto.PhotoPath
    );
    this.openPreview.emit(currentIndex);
  }

  openDetail() {
    if (this.clickable) {
      this.visibleSidebar = true;
      this.showDetails = true;
    }
  }

  selectDeselect() {
    this.onChecked.emit(this.selected);
  }

  share() {
    this.dialogService.addDialog(ShareJobfileDialogComponent, {
      jobFileId: this.plexPhoto.JobFileId,
      jobId: this.plexPhoto.JobGuid,
      changeOrderFileId: this.plexPhoto.JobChangeOrderId
        ? this.plexPhoto.JobChangeOrderId
        : null,
    });
  }

  detailUpdated(e: UserPhotoModel) {
    this.onUpdated.emit(e);
    if (e) {
      this.title = e.Title;
      this.description = e.Description;
    }
  }

  onHideSliderHide(e) {
    this.visibleSidebar = false;
    this.showDetails = false;
  }

  updateIcons(e: UserPhotoModel) {
    const photo: { Tags: boolean; Comments: boolean } =
      this.userPhoto || this.jobPhoto;
    if (e && photo) {
      photo.Tags = e.Tags;
      photo.Comments = e.Comments;
    }
  }

  forceDownload(blob, filename) {
    var a = document.createElement("a");
    a.download = filename;
    a.href = blob;
    // For Firefox https://stackoverflow.com/a/32226068
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  //  the anchor [download] attribute only works for same-origin
  // paths. therefore, we have to turn the image path into a blob
  // in order to force the client to download it
  download() {
    fetch(this.plexPhoto.PhotoPath, {
      headers: new Headers({
        Origin: location.origin,
      }),
      mode: "cors",
    })
      .then((response) => response.blob())
      .then((blob) => {
        let blobUrl = window.URL.createObjectURL(blob);
        this.forceDownload(blobUrl, this.plexPhoto.PhotoName);
      })
      .catch((e) => console.error(e));
  }

  pinnedClicked() {
    const verb = this.isPinned ? "remove" : "set";
    this.dialogService
      .addDialog(ConfirmComponent, {
        title: this.isPinned ? "Unset Job Thumbnail" : "Set Job Thumbnail",
        message: `Do you want to ${verb} this photo as the job thumbnail?`,
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          const photo = this.plexPhoto;
          const photoTuhmbnail = { ...photo };
          this.jobService
            .postJobThumbnailAssignmentOrUnassignment(
              photo.JobGuid,
              photo.JobFileId
            )
            .subscribe(() => {
              photoTuhmbnail.IsJobThumbnail = this.isPinned ? false : true;
              this.jobService.onUpdatePinnedPhoto.next(photoTuhmbnail);
              this.onUpdated.emit(photo);
            });
        }
      });
  }

  /**
   * sets the date taken prop of the photo
   * @param dateTaken date string
   * @returns formatted date string or N/A
   */
  private getDateTakenFormatted(
    dateTaken: string | Date,
    transform: boolean = false
  ): string {
    if (dateTaken === "N/A" || !dateTaken) {
      return "N/A";
    } else {
      const datePipe = new DatePipe("en-US");
      dateTaken = transform ? dateTaken + "Z" : dateTaken;
      return datePipe.transform(new Date(dateTaken), this.dateFormat);
    }
  }
}
