import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { TaskDto } from "app/model/ToDoListDto";
import { SharedService } from "app/common/utility/SharedService";
import { CommonService } from "app/common/services/common.service";
import { JobResourcesRequestPayload } from "app/model/JobAssignResourceModel";
import { WorkOrderService } from "app/common/services/workOrder.service";
import { MessageService } from "primeng/primeng";
import { BaThemeSpinner } from "app/theme/services";
import { ConfirmComponent } from "app/component/dialog/dialog.component";
import { DialogService } from "ng2-bootstrap-modal";
import { environmentConstant } from "app/common/utility/environment";
import {
  FULL_PERMISSIONS,
  TaskPermissions,
  TodoPermissionsService,
} from "../todo-permissions.service";
@Component({
  selector: "app-todo-advanced",
  templateUrl: "./todo-advanced.component.html",
  styleUrls: ["./todo-advanced.component.scss"],
})
export class TodoAdvancedComponent implements OnInit {
  task: TaskDto = new TaskDto();
  showSlides: string = "";
  loggedInUserId: number;
  lstAssignedResource: any = [];
  isAssignedUpdated: boolean;
  assignResourceModel: JobResourcesRequestPayload;
  previousResources: any = [];
  statusLst: { Value: string; key: number; className: string }[];
  originalStatusList: any[];
  EnableStartDateEdit: boolean = false;
  previousActStartDate: Date;
  @Input() TaskId: any;
  @Input() JobId: any;
  @Output() updateToDoList: EventEmitter<any> = new EventEmitter<any>();
  headerText: string;
  buttonText: string;
  permissions: TaskPermissions = FULL_PERMISSIONS;
  datePickerSteps: any = { minute: 15 };

  constructor(
    private sharedService: SharedService,
    private commonService: CommonService,
    private notificationsService: MessageService,
    private workOrderService: WorkOrderService,
    private _spinner: BaThemeSpinner,
    private dialogService: DialogService,
    private todoPermissionsService: TodoPermissionsService
  ) {}
  ngOnInit() {
    this.loggedInUserId = this.sharedService.loggedInUser.Id;
    this.getStatus();

    if (this.TaskId > 0) {
      this.getTask();
      this.headerText = "Edit";
      this.buttonText = "Update";
    } else {
      this.buttonText = "Save";
      this.headerText = "Add";
      this.task.ScheduledOn = new Date();
    }
  }
  getTask() {
    this._spinner.show();
    this.commonService.getTodoListTask(this.TaskId).subscribe((data) => {
      this.permissions =
        this.todoPermissionsService.getPermissionsForTask(data);
      this.updateStatusLstBasedOnPermissions();
      this.previousResources = JSON.parse(JSON.stringify(data.Resources));
      this.task = data;
      this.JobId = data.JobId;
      this.task.ScheduledOn =
        this.task.ScheduledOn === null ? null : new Date(this.task.ScheduledOn);
      this.task.CompletedOn =
        this.task.CompletedOn === null ? null : new Date(this.task.CompletedOn);
      this.task.StartedOn =
        this.task.StartedOn === null ? null : new Date(this.task.StartedOn);
      this.previousActStartDate = this.task.StartedOn;
      this._spinner.hide();
    });
  }
  onSubmit() {
    this._spinner.show();
    this.task.JobId = this.JobId;
    if (this.task.ScheduledOn) {
      this.task.ScheduledOn = new Date(this.task.ScheduledOn);
      this.task.ScheduledOn = new Date(
        Date.UTC(
          this.task.ScheduledOn.getFullYear(),
          this.task.ScheduledOn.getMonth(),
          this.task.ScheduledOn.getDate()
        )
      );
    }
    if (this.task.StartedOn) {
      this.task.StartedOn = new Date(this.task.StartedOn);
      this.task.StartedOn = new Date(
        Date.UTC(
          this.task.StartedOn.getFullYear(),
          this.task.StartedOn.getMonth(),
          this.task.StartedOn.getDate()
        )
      );
      if (this.task.CompletedOn) {
        this.task.CompletedOn = new Date(this.task.CompletedOn);
        this.task.CompletedOn = new Date(
          Date.UTC(
            this.task.CompletedOn.getFullYear(),
            this.task.CompletedOn.getMonth(),
            this.task.CompletedOn.getDate()
          )
        );
        this.task.ScheduledOn =
          this.task.ScheduledOn || new Date(this.task.CompletedOn);
        this.task.StartedOn =
          this.task.StartedOn || new Date(this.task.CompletedOn);
      }
    }
    this.task.OrgGUID = this.sharedService.selectedOrganization.ID;
    if (this.task.TaskId > 0) {
      this.commonService.UpdateTodoListTask(this.task).subscribe((data) => {
        let yFilter = this.previousResources.map((itemY) => {
          return itemY.Id;
        });
        let filteredAssignTo = this.task.Resources.filter(
          (itemX) => !yFilter.includes(itemX.Id)
        );
        if (filteredAssignTo.length > 0) {
          this.assignResourceModel = new JobResourcesRequestPayload();
          this.assignResourceModel.Resources = filteredAssignTo;
          this.assignResourceModel.JobPhaseId = data.TaskId;
          this.assignResourceModel.JobID = data.JobId;
          this.workOrderService
            .saveAssignedResource(this.assignResourceModel)
            .subscribe((result) => {
              this.result(result, "Updated Successfully!!", "To Do Task");
              this.updateToDoList.emit(true);
            });
        } else {
          this.result(data, "Updated Successfully!!", "To Do Task");
          this.updateToDoList.emit(true);
        }
      });
    } else {
      this.commonService.saveTodoListTask(this.task).subscribe((data) => {
        let yFilter = this.previousResources.map((itemY) => {
          return itemY.Id;
        });
        let filteredAssignTo = this.task.Resources.filter(
          (itemX) => !yFilter.includes(itemX.Id)
        );
        if (filteredAssignTo.length > 0) {
          this.assignResourceModel = new JobResourcesRequestPayload();
          this.assignResourceModel.Resources = filteredAssignTo;
          this.assignResourceModel.JobPhaseId = data.TaskId;
          this.assignResourceModel.JobID = data.JobId;
          this.result(data, "Added Successfully!!", "To Do Task");
          this.updateToDoList.emit(true);
        } else {
          this.result(data, "Added Successfully!!", "To Do Task");
          this.updateToDoList.emit(true);
        }
      });
    }
  }
  updateAssignedResource(event) {
    if (event == "cancel") {
      this.showSlides = "";
    } else {
      if (event.length > 0) {
        event.forEach((element) => {
          this.task.Resources.push(element);
          this.isAssignedUpdated = true;
        });
        this.lstAssignedResource = JSON.parse(
          JSON.stringify(this.task.Resources)
        );
      }
      this.showSlides = "";
    }
  }
  private result(res: any, message, title) {
    if (res !== null) {
      this.notificationsService.add({
        severity: "success",
        summary: title,
        detail: message,
      });
      this._spinner.hide();
    }
  }
  getInitals(str) {
    if (!str) return "";
    var result = str
      .split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), "");
    if (result && result.length > 2) {
      return result.slice(0, 2);
    }
    return result;
  }
  deleteAssignedResource(assignedId) {
    if (!this.permissions.editAssignedEmployee) {
      return;
    }
    var assignedTo = this.task.Resources.find((obj) => obj.Id == assignedId);
    var index = this.task.Resources.findIndex((obj) => obj.Id == assignedId);
    const disposable = this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Delete Assigned Resource",
        // tslint:disable-next-line:prefer-template
        message:
          "Are you sure you want to delete assigned resource" +
          " " +
          assignedTo.Name +
          "?",
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          let yFilter = this.previousResources.map((itemY) => {
            return itemY.Id;
          });
          let filteredAssignTo = this.task.Resources.filter(
            (itemX) => !yFilter.includes(itemX.Id)
          );
          if (filteredAssignTo.findIndex((obj) => obj.Id == assignedId) != -1) {
            this.task.Resources.splice(index, 1);
            this.lstAssignedResource = JSON.parse(
              JSON.stringify(this.task.Resources)
            );
          } else {
            this._spinner.show();
            this.workOrderService
              .deleteAssignedResources(
                assignedId,
                this.JobId,
                this.task.TaskId,
                assignedTo.IsExternal
              )
              .subscribe(
                (result) => {
                  this.result(
                    result,
                    "Deleted Successfully!!",
                    "Assign Resource"
                  );
                  this.task.Resources.splice(index, 1);
                  this.lstAssignedResource = JSON.parse(
                    JSON.stringify(this.task.Resources)
                  );
                  this.updateToDoList.emit(true);
                  this._spinner.hide();
                },
                (error) =>
                  this.error(error, "Unable to Delete assigned resource")
              );
            this._spinner.hide();
          }
        }
      });
  }
  getStatus() {
    this.originalStatusList = environmentConstant.statusTask;
    if (!this.permissions.complete) {
      this.originalStatusList = this.originalStatusList.filter(
        (status) => status.Value !== "Complete"
      );
    }
    if (!this.permissions.close) {
      this.originalStatusList = this.originalStatusList.filter(
        (status) => status.Value !== "Closed"
      );
    }
    this.task.StatusId = 1;
  }
  public filterStatus(filter: any): void {
    this.statusLst = this.originalStatusList.filter(
      (s) => s.Status.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }
  updateStatusLstBasedOnPermissions() {
    if (!this.permissions.complete) {
      this.originalStatusList = this.originalStatusList.filter(
        (status) => status.Value !== "Complete"
      );
    }
    if (!this.permissions.close) {
      this.originalStatusList = this.originalStatusList.filter(
        (status) => status.Value !== "Closed"
      );
    }
    this.statusLst = this.originalStatusList;
  }
  //CHANGING STATUS ALSO CHANGED THE DATES
  changePhaseStatus() {
    if (this.task.StatusId === 2) {
      if (!this.task.StartedOn) {
        this.task.CompletedOn = null;
        this.task.StartedOn = new Date();
      } else {
        this.task.CompletedOn = null;
      }
    } else if (this.task.StatusId === 3 && !this.task.CompletedOn) {
      if (!this.task.StartedOn) this.task.StartedOn = new Date();
      this.task.CompletedOn = new Date();
    }
  }
  //WHEN CHANGING COMPLETED DATE
  changeStatus(completedOn) {
    //if (!completedOn) return;
    if (completedOn) {
      this.task.StatusId = 3;
    } else {
      this.task.StatusId = 2;
    }
    if (!this.task.CompletedOn) {
      this.task.StartedOn = new Date();
    }
  }
  editStartDate(IsEdit) {
    if (IsEdit) {
      this.EnableStartDateEdit = true;
      this.previousActStartDate = this.task.StartedOn;
    } else {
      this.EnableStartDateEdit = false;
      this.task.StartedOn = this.previousActStartDate;
    }
  }
  cancel() {
    this.updateToDoList.emit(false);
  }
  private error(error: any, message: string) {
    switch (error.status) {
      case 409:
        this.notificationsService.add({
          severity: "warn",
          summary: "To Do Task",
          detail: message,
        });
        break;
      default:
        break;
    }
  }

  changeReminder(reminderDate) {
    console.log(reminderDate);
  }
}
