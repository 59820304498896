import { Component, OnInit } from "@angular/core";
import { PasswordModel } from "app/model/PasswordModel";
import { changePasswordService } from "app/common/services/changePassword.service";
import { MessageService } from "primeng/components/common/messageservice";
import { BaThemeSpinner } from "app/theme/services";
import { Location } from "@angular/common";
import { CustomerModel } from "app/model/customerModel";
import { CustomerService } from "app/common/services/customer.service";
import { SharedService } from "app/common/utility/SharedService";
import { Router } from "@angular/router";

@Component({
  selector: "app-changePassword",
  templateUrl: "./changePassword.html",
  styleUrls: [],
})
export class changePasswordComponent implements OnInit {
  ConfirmPassword: any;
  isMatched: boolean;
  changePassword: PasswordModel;
  customer: any;
  UserName: string;
  passwordPattern: string;
  passValidationMes: string = "";
  confirmPassModel = { confirmPassword: "" };
  constructor(
    private passwordService: changePasswordService,
    private customerService: CustomerService,
    private sharedService: SharedService,
    private notificationsService: MessageService,
    private _spinner: BaThemeSpinner,
    private _location: Location,
    private router: Router
  ) {
    this.changePassword = new PasswordModel();
    this.customer = new CustomerModel();
    this.confirmPassModel.confirmPassword = null;
    this.passwordPattern = "^([a-zA-Z0-9]{6,32})$";
  }

  ngOnInit() {
    this.getLoginCustomer();
    this._spinner.hide();
  }
  getLoginCustomer() {
    this.customerService.getLoginUser().subscribe((loggedInData) => {
      this.UserName = loggedInData.UserName;
    });
  }

  matchPassword(value) {
    if (this.changePassword.NewPassword.length > 32) {
      this.passValidationMes =
        "Password max length exceeding " +
        (this.changePassword.NewPassword.length - 32) +
        " characters";
    } else {
      this.passValidationMes =
        "Password must be 6-32 characters long including uppercase/lowercase or numbers";
    }
    if (this.changePassword.NewPassword != value) this.isMatched = true;
    else this.isMatched = false;
  }

  onSubmit(form) {
    if (form.valid) {
      this.changePassword.UserName = this.UserName;
      this.changePassword.ValidateRequest = true;
      this.changePassword.NewPasswordFormat = 0;
      this.passwordService.updatePassword(this.changePassword).subscribe(
        (result) => {
          if (result.Success)
            this.result(result, "Password Changed Successfully!!");
          else this.error("Current Password don't match.");
        },
        (error) => this.error(error)
      );
    }
  }

  private error(error: any) {
    this.notificationsService.add({
      severity: "warn",
      summary: "Change Password",
      detail: error,
    });
  }

  private result(res: any, message: string) {
    this.notificationsService.add({
      severity: "success",
      summary: "Change Password",
      detail: message,
    });
    this._spinner.hide();
    this.router.navigate(["pages", "scheduler"]);
  }
  onCancel() {
    this._location.back();
  }
}
