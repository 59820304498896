import { MatDialog, MatDialogRef } from "@angular/material";
import { EstimateModalComponent } from "../../component/estimate-modal/estimate-modal.component";

export class EstimateModalService {
  constructor(private _dialog: MatDialog) {}

  show(
    title: string,
    message: string,
    approvalButtonText: string
  ): MatDialogRef<EstimateModalComponent, boolean> {
    return this._dialog.open(EstimateModalComponent, {
      data: {
        title: title,
        message: message,
        approvalButtonText: approvalButtonText,
      },
    });
  }
}
