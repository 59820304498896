import { Component, OnInit, ViewChild } from "@angular/core";
import { Routes } from "@angular/router";

import { BaMenuService } from "../theme";
import { PAGES_MENU } from "./pages.menu";
import { SharedService } from "app/common/utility/SharedService";
import { ToDoListDto } from "../model/ToDoListDto";
import { TodoListComponent } from "./account/todo/todo-list/todo-list.component";
@Component({
  selector: "pages",
  template: `
    <ba-sidebar (SlideoutEvent)="ToggleSlide($event)"></ba-sidebar>
    <ba-page-top
      #CommonContainer
      (onHide)="onHideSliderHide($event)"
      (SlideOut)="toggleToDolist($event)"
    ></ba-page-top>
    <div class="al-main">
      <div class="al-content">
        <ba-content-top></ba-content-top>
        <router-outlet></router-outlet>
        <app-image-annotator></app-image-annotator>
        <photo-carousel [useService]="true"></photo-carousel>
        <p-sidebar
          [(visible)]="OrganizationSlideOut"
          class="addeditclient organization-slider"
          position="right"
          [baseZIndex]="10000"
          appendTo="body"
        >
          <add-Organizations
            *ngIf="OrganizationSlideOut"
            [IsSuperAdmin]="false"
            [ID]="sharedService.selectedOrganization.ID"
            (refreshData)="onHideSliderHide($event)"
          ></add-Organizations>
        </p-sidebar>
        <p-sidebar
          [(visible)]="toDoListSlideout"
          (onHide)="toggleToDolist($event)"
          class="addeditclient app-todo-list"
          [ngClass]="toDoListSlideout ? 'todosidebar' : ''"
          position="right"
          [baseZIndex]="10000"
          appendTo="body"
        >
          <app-todo-list
            *ngIf="toDoListSlideout"
            #todoList
            (addEvent)="AddToDoList($event)"
            (updateEvent)="EditPhase($event)"
          ></app-todo-list>
          <div [ngClass]="ContainerSlideOut ? 'sidebar2 open' : 'sidebar2'">
            <a
              class="cancelbtnHead ui-sidebar-close"
              (click)="ContainerSlideOut = false; showTask = false"
            >
              <span class="pi pi-times"></span>
            </a>
            <app-todo-advanced
              *ngIf="ContainerSlideOut && showTask"
              [TaskId]="TaskId"
              (updateToDoList)="refreshToDoList($event)"
            >
            </app-todo-advanced>
            <common-container
              *ngIf="ContainerSlideOut && CommonContainerData && !isLineItem"
              [IsPhase]="CommonContainerData.IsPhase"
              (updateEvent)="refreshToDoList($event)"
              [JobPhaseId]="CommonContainerData.JobPhaseId"
              [sideBarClass]="'sidebar3'"
              [JobID]="CommonContainerData.JobID"
              [CallingComponent]="CommonContainerData.CallingComponent"
            >
            </common-container>
            <Add-LineItem-Component
              *ngIf="ContainerSlideOut && CommonContainerData && isLineItem"
              [isAddWorkOrder]="false"
              [WorkOrderID]="CommonContainerData.JobID"
              [lineItemId]="CommonContainerData.JobPhaseId"
              (updateData)="refreshToDoList($event)"
            ></Add-LineItem-Component>
          </div>
        </p-sidebar>
      </div>
    </div>
    <footer class="al-footer clearfix graybg">
      <img
        src="./assets/images/pentair-logo.png"
        alt=""
        class="left-logo"
      /><img src="./assets/images/PentairMPB-logo.png" alt="" />&copy; 2022 -
      Pentair Pool Builder
    </footer>
    <ba-back-top position="200"></ba-back-top>
  `,
  styleUrls: ["./pageComponent.css"],
})
export class Pages implements OnInit {
  selectedOrganization = this.sharedService.selectedOrganization;
  OrganizationSlideOut: boolean = false;
  ContainerSlideOut: boolean = false;
  CommonContainerData: any;
  isLineItem: boolean = false;
  toDoListSlideout: boolean = false;
  showTask: boolean = false;
  TaskId: number;
  @ViewChild("todoList", { static: false }) todoList: TodoListComponent;
  constructor(
    private _menuService: BaMenuService,
    public sharedService: SharedService
  ) {}

  ngOnInit() {
    this._menuService.updateMenuByRoutes(<Routes>PAGES_MENU);
  }
  ToggleSlide(event) {
    if (event == "Organization") {
      this.OrganizationSlideOut = true;
      this.ContainerSlideOut = false;
    }
  }
  toggleToDolist(e) {
    this.toDoListSlideout = !this.toDoListSlideout;
    this.ContainerSlideOut = false;
    this.showTask = false;
    this.OrganizationSlideOut = false;
  }
  ToggleContainerSlide(event) {
    if (event.component == "ContainerSlideOut") {
      this.ContainerSlideOut = false;
      this.OrganizationSlideOut = false;
      this.showTask = false;
      var that = this;
      setTimeout(function () {
        that.ContainerSlideOut = true;
        that.OrganizationSlideOut = false;
        if (event.CallingComponent == "work order") {
          that.isLineItem = true;
        } else {
          that.isLineItem = false;
        }
        that.CommonContainerData = event;
      }, 50);
    }
  }
  onHideSliderHide(e) {
    this.ContainerSlideOut = false;
    this.CommonContainerData = false;
    this.showTask = false;
    this.OrganizationSlideOut = false;
  }
  refreshToDoList(e) {
    if (e) {
      this.todoList.getToDoList();
    }
    this.showTask = false;
    this.ContainerSlideOut = false;
    this.CommonContainerData = false;
  }

  AddToDoList(e) {
    this.showTask = true;
    this.ContainerSlideOut = true;
    this.TaskId = e.ID;
  }
  EditPhase(phase: ToDoListDto) {
    this.showTask = false;
    var eve: any = new Object();
    eve.component = "ContainerSlideOut";
    switch (phase.JobTypeId) {
      case 1:
        eve.CallingComponent = "job phase";
        eve.IsPhase = true;
        eve.JobPhaseId = phase.ID;
        eve.JobID = phase.JobID; //jobphase isphase=true JobPhaseId=id JobID
        break;
      case 2:
        eve.CallingComponent = "job phase";
        eve.IsPhase = true;
        eve.JobPhaseId = phase.ID;
        eve.JobID = phase.JobID; //jobphase isphase=true JobPhaseId=id JobID
        break;
      case 3:
        eve.CallingComponent = "punchlist";
        eve.IsPhase = true;
        eve.JobPhaseId = phase.ID;
        eve.JobID = phase.JobID; //Punchlist isphase=true JobPhaseId=id JobID
        break;
      case 4:
        eve.CallingComponent = "work order";
        eve.JobID = phase.JobID; //workorderItem  JobID
        eve.JobPhaseId = phase.ID;
        break;
    }
    // this.visibleSidebar = false;
    // this.showSlides = '';
    this.ToggleContainerSlide(eve);
  }
}
