import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";
import { environmentConstant } from "app/common/utility/environment";
import { Observable } from "rxjs";
import { BlobDataModel } from "app/model/MediaResource/BlobDataModel";
import { ApiResponseModel } from "app/model/ApiResponseModel";
import { BlobUploadmodel } from "app/model/BlobUpoadModel";
import { BlobInformation } from "app/model/BlobInformation";

@Injectable()
export class BlobService {
  constructor(private httpService: HttpService) {}

  get(blobGuid: string): Observable<ApiResponseModel<BlobDataModel>> {
    return this.httpService.get(environmentConstant.coreApi.getBlob(blobGuid));
  }

  addRange(
    blobInformation: BlobInformation,
    form: FormData
  ): Observable<BlobUploadmodel[]> {
    const blobInformationSerialized = encodeURIComponent(
      JSON.stringify(blobInformation)
    );
    return this.httpService.UploadRequestService(
      `blob?blobInformation=${blobInformationSerialized}`,
      form
    );
  }
}
