<p-tabView>
  <p-tabPanel header="Primary Contact" [selected]="true">
    <div class="contacts">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <div class="form-group">
            <span class="ui-float-label">
              <input
                [appFocus]="true"
                type="text"
                class="form-control"
                id="customerFirstName"
                name="customerFirstName"
                pInputText
                maxlength="50"
                [(ngModel)]="model.FirstName"
                #customerFirstName="ngModel"
                [ngClass]="{
                  'has-error':
                    customerFirstName.invalid &&
                    (customerFirstName.dirty || customerFirstName.touched)
                }"
                maxlength="100"
                required
                preventBackSlashAndDoubleQuote
              />
              <label for="text" class="form-control-label">
                First Name
                <span class="text-red">*</span>
              </label>
            </span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <div class="form-group">
            <span class="ui-float-label">
              <input
                type="text"
                class="form-control"
                id="customerLastName"
                name="customerLastName"
                #customerLastName="ngModel"
                pInputText
                maxlength="100"
                [(ngModel)]="model.LastName"
                [ngClass]="{
                  'has-error':
                    customerLastName.invalid &&
                    (customerLastName.dirty || customerLastName.touched)
                }"
                [required]="jobType == 2 || jobType == 0"
                preventBackSlashAndDoubleQuote
              />
              <label for="text" class="form-control-label">
                Last Name
                <span *ngIf="jobType == 2 || jobType == 0" class="text-red"
                  >*</span
                >
              </label>
            </span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <div class="form-group">
            <span class="ui-float-label">
              <input
                *ngIf="isEdit"
                type="tel"
                class="form-control"
                id="customerPrimaryPhone"
                name="customerPrimaryPhone"
                pInputText
                maxlength="50"
                [(ngModel)]="model.PrimaryPhone"
                (blur)="validatePhone(model.PrimaryPhone)"
                #customerPrimaryPhone="ngModel"
                [required]="jobType == 2 || jobType == 0"
                [ngClass]="{
                  'has-phone-error':
                    (customerPrimaryPhone.invalid &&
                      (customerPrimaryPhone.dirty ||
                        customerPrimaryPhone.touched)) ||
                    isDuplicateCustomerPhone
                }"
              />
              <p-inputMask
                *ngIf="!isEdit"
                id="customerPrimaryPhone"
                mask="999-999-9999"
                slotChar=" "
                [(ngModel)]="model.PrimaryPhone"
                #customerPrimaryPhone="ngModel"
                autoClear="false"
                styleClass="form-control"
                name="customerPrimaryPhone"
                (onBlur)="validatePhone(model.PrimaryPhone)"
                [required]="jobType == 2 || jobType == 0"
                maxlength="50"
                [ngClass]="{
                  'has-phone-error':
                    (customerPrimaryPhone.invalid &&
                      (customerPrimaryPhone.dirty ||
                        customerPrimaryPhone.touched)) ||
                    isDuplicateCustomerPhone
                }"
              >
              </p-inputMask>
              <label for="text" class="form-control-label"
                >Primary Phone
                <span *ngIf="jobType == 2 || jobType == 0" class="text-red"
                  >*</span
                >
              </label>
            </span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
          <div class="form-group">
            <span class="ui-float-label">
              <input
                type="email"
                class="form-control"
                id="customerEmail"
                name="customerEmail"
                pInputText
                maxlength="200"
                [(ngModel)]="model.Email"
                (blur)="validateEmail(model.Email, customerEmail.valid)"
                #customerEmail="ngModel"
                [pattern]="emailPattern"
                [ngClass]="{
                  'has-email-error':
                    (customerEmail.invalid &&
                      (customerEmail.dirty || customerEmail.touched)) ||
                    isDuplicateCustomerEmail
                }"
              />
              <label for="text" class="form-control-label">Email</label>
            </span>
          </div>
        </div>
        <div
          *ngIf="isDuplicateCustomerEmail || isDuplicateCustomerPhone"
          class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
        >
          <p class="duplicateCustomer">
            {{ duplicateErrorMessage }}
          </p>
        </div>
        <div class="col-12 form-row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <h3 class="pt">
              {{
                jobType == 0 && type != "opportunity" ? "Employee" : "Customer"
              }}
              Address
            </h3>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="form-group">
              <span class="ui-float-label">
                <input
                  type="text"
                  class="form-control"
                  pInputText
                  name="address1"
                  id="address1"
                  #address1="ngModel"
                  [(ngModel)]="model.Address.Address1"
                  maxlength="100"
                  [ngClass]="{
                    'has-error':
                      address1.invalid && (address1.dirty || address1.touched)
                  }"
                  [required]="jobType == 2"
                  autocomplete="off"
                  ngx-google-places-autocomplete
                  [options]="options"
                  (onAddressChange)="onAddressChange($event)"
                  placeholder=""
                />
                <label for="text" class="form-control-label">
                  Address
                  <span *ngIf="jobType == 2" class="text-red">*</span>
                </label>
              </span>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="form-group">
              <span class="ui-float-label">
                <input
                  type="text"
                  class="form-control"
                  pInputText
                  name="caddress2"
                  id="caddress2"
                  [(ngModel)]="model.Address.Address2"
                  maxlength="100"
                />
                <label for="text" class="form-control-label"
                  >Subdivision/Area</label
                >
              </span>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
            <div class="form-group">
              <span class="ui-float-label">
                <input
                  type="text"
                  class="form-control"
                  id="jobCustomerAddressCity"
                  name="jobCustomerAddressCity"
                  pInputText
                  [(ngModel)]="model.Address.City"
                  [ngClass]="{
                    'has-error':
                      jobCustomerAddressCity.invalid &&
                      (jobCustomerAddressCity.dirty ||
                        jobCustomerAddressCity.touched)
                  }"
                  #jobCustomerAddressCity="ngModel"
                  pattern="([a-zA-Z0-9.'-]{2,200}\s*)+"
                  trim="blur"
                  maxlength="200"
                />
                <label for="text" class="form-control-label">City</label>
              </span>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
            <div class="form-group">
              <kendo-floatinglabel
                text="State/Province"
                class="fontWeightLighterSliderDropdowns"
              >
                <kendo-combobox
                  [data]="states"
                  name="state1"
                  #state1="ngModel"
                  [valuePrimitive]="true"
                  [filterable]="true"
                  (valueChange)="filterStates($event)"
                  (filterChange)="filterStates($event)"
                  [textField]="'Abbreviation'"
                  [(ngModel)]="model.Address.StateProvinceId"
                  [valueField]="'Id'"
                  #statesInput
                >
                </kendo-combobox>
              </kendo-floatinglabel>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
            <div class="form-group">
              <span class="ui-float-label">
                <i
                  *ngIf="zipValidate"
                  tooltipZIndex="12000"
                  [pTooltip]="zipCodeMessage"
                  class="fa fa-info-circle color-red zip-alert"
                >
                </i>
                <input
                  #inputZip
                  type="text"
                  class="form-control"
                  id="czip"
                  name="czip"
                  pInputText
                  oninput="this.value = this.value.toUpperCase()"
                  [(ngModel)]="model.Address.ZipPostalCode"
                  [ngClass]="{
                    'has-error': zipValidate
                  }"
                  (keydown.enter)="searchByZIP($event)"
                />
                <label for="text" class="form-control-label">
                  Zip/Postal Code
                </label>
              </span>
            </div>
          </div>
          <div class="col-4" *ngIf="jobType != 0">
            <div class="form-group">
              <span class="ui-float-label">
                <input
                  type="text"
                  class="form-control"
                  pInputText
                  name="gateCode"
                  id="gateCode"
                  [(ngModel)]="model.Address.GateCode"
                  maxlength="200"
                />
                <label for="text" class="form-control-label">Gate Code</label>
              </span>
            </div>
          </div>
          <div class="col-4" *ngIf="jobType != 0">
            <div class="form-group">
              <span class="ui-float-label">
                <input
                  type="text"
                  class="form-control"
                  pInputText
                  name="parcelCode"
                  id="parcelCode"
                  [(ngModel)]="model.Address.ParcelCode"
                  maxlength="200"
                />
                <label for="text" class="form-control-label">Parcel Code</label>
              </span>
            </div>
          </div>
          <div class="col-4" *ngIf="jobType != 0">
            <div
              class="form-group"
              style="background-color: #f1f3f6 !important"
            >
              <div class="formfield">
                <div class="checkbox mt10">
                  <label class="checkbox-inline custom-checkbox nowrap">
                    <input
                      type="checkbox"
                      name="hasPets"
                      [(ngModel)]="model.Address.HasPets"
                    />
                    <span>Property Has Pets</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Secondary Contact" *ngIf="jobType != 0">
    <div class="contacts">
      <div class="row">
        <div class="clo-lg-6 col-md-6 col-sm-12">
          <div class="form-group">
            <span class="ui-float-label">
              <input
                type="text"
                pInputText
                id="SecondaryFirstName"
                [(ngModel)]="model.SecondaryFirstName"
                class="form-control"
                name="SecondaryFirstName"
                #SecondaryFirstName="ngModel"
                maxlength="100"
                preventBackSlashAndDoubleQuote
              />
              <label for="text" class="form-control-label">First Name</label>
            </span>
          </div>
        </div>
        <div class="clo-lg-6 col-md-6 col-sm-12">
          <div class="form-group">
            <span class="ui-float-label">
              <input
                type="text"
                pInputText
                class="form-control"
                id="SecondaryLastName"
                name="SecondaryLastName"
                [(ngModel)]="model.SecondaryLastName"
                #SecondaryLastName="ngModel"
                maxlength="100"
                preventBackSlashAndDoubleQuote
              />
              <label for="text" class="form-control-label">Last Name</label>
            </span>
          </div>
        </div>
        <div class="clo-lg-6 col-md-6 col-sm-12">
          <div class="form-group">
            <span class="ui-float-label">
              <p-inputMask
                mask="999-999-9999"
                slotChar=" "
                [(ngModel)]="model.SecondaryPhone"
                #SecondaryPhone="ngModel"
                autoClear="false"
                styleClass="form-control"
                name="SecondaryPhone"
              >
              </p-inputMask>
              <label for="text" class="form-control-label">Phone</label>
            </span>
          </div>
        </div>
        <div class="clo-lg-6 col-md-6 col-sm-12">
          <div class="form-group">
            <span class="ui-float-label">
              <input
                type="SecondaryEmail"
                class="form-control"
                id="SecondaryEmail"
                name="SecondaryEmail"
                pInputText
                [(ngModel)]="model.SecondaryEmail"
                #email="ngModel"
                [pattern]="emailPattern"
                maxlength="250"
              />
              <label for="text" class="form-control-label">Email </label>
            </span>
          </div>
        </div>
      </div>
    </div>
  </p-tabPanel>
</p-tabView>
