import { Injectable } from "@angular/core";
import { CountModel } from "app/model/Core/JobFileModel";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UpdateCountService {
  private updateEvents = new BehaviorSubject<boolean>(false);
  count$ = this.updateEvents.asObservable();

  private _counts = new BehaviorSubject<CountModel>(null);
  get counts(): Observable<CountModel> {
    return this._counts.asObservable();
  }

  updateCount(val: CountModel) {
    this._counts.next(val);
  }
  update() {
    this.updateEvents.next(true);
  }
}
