<div class="main-container">
  <div class="text">
    <i *ngIf="enableProposed" class="fa fa-envelope-open-o text__icon"></i>
    <i *ngIf="enableViewRequest" class="fa fa-envelope-o text__icon"></i>
    <span>{{ displayText }}</span>
  </div>
  <div class="badge">
    <p class="badge__prefix">Click To</p>
    <span class="link">
      <a *ngIf="enableStart" (click)="onStart()">START</a>
      <a *ngIf="enableComplete" (click)="onComplete()">COMPLETE</a>
      <a *ngIf="enableViewRequest" (click)="onViewRequest()">VIEW REQUEST</a>
      <a *ngIf="enableRequestService" (click)="onRequestService()"
        >REQUEST SERVICE</a
      >
    </span>
    <span class="link" *ngIf="enableProposed">
      <a (click)="onAccept()">ACCEPT</a> |
      <a class="link--secondary" (click)="onReject()">REJECT</a>
    </span>
  </div>
</div>
