<p-sidebar
  [(visible)]="visibleSidebar"
  class="notificationsBox"
  position="right"
  (onHide)="onHideSliderHide($event)"
  [baseZIndex]="10000"
>
  <div class="headingwrap clearfix">
    <strong>Notifications</strong>
  </div>
  <div class="msg-list">
    <ul class="notifications">
      <div *ngIf="!notificationData.length && display" class="px-4 pt-2">
        No notifications are available at this time
      </div>
      <li
        [ngClass]="msg.Viewed ? 'msgRead' : 'msgUnread'"
        *ngFor="let msg of notificationData"
      >
        <i class="notiword">{{msg.NotificationType}}</i>
        <p (click)="openMessage(msg)">
          <span class="text-truncate" title="{{msg.NotificationName}}"
            ><b>{{msg.NotificationName}}</b></span
          >
          <span class="text-truncate" title="{{msg.JobName}}"
            >Job Name : <b>{{msg.JobName}}</b></span
          >
          <span>{{msg.CreateOn | date: 'medium'}}</span>
        </p>
        <span class="right-trash">
          <a (click)="deleteNotification(msg.Id)"
            ><i class="fa fa-trash-o" title="Delete" aria-hidden="true"></i
          ></a>
        </span>
      </li>
    </ul>
  </div>
  <div class="footerNotification">
    <a
      routerLink="notification/notification"
      (click)="visibleSidebar = false && openMessageItem = false"
      class="pull-left"
      >See all notifications</a
    >
    <a (click)="clearAllNotifications()" class="pull-right">Clear all</a>
  </div>
</p-sidebar>

<div class="sidebar-2 open" *ngIf="openMessageItem">
  <div class="headingwrap clearfix">
    <ul class="notifications details">
      <li>
        <i class="notiword">{{notificationItem.NotificationType}}</i>
        <p>
          <span
            class="text-truncate"
            title="{{notificationItem.NotificationName}}"
            ><b>{{notificationItem.NotificationName}}</b></span
          >
          <span class="text-truncate" title="{{notificationItem.JobName}}"
            >Job Name : {{notificationItem.JobName}}</span
          >
        </p>
        <span class="right-trash">
          <i class="fa fa-times" (click)="closeMsg()"></i>
        </span>
      </li>
    </ul>
  </div>
  <div class="msg-detail" [innerHTML]="notificationItem.Message"></div>
</div>
