<div class="estimate-theme mat-dialog mat-dialog--l">
  <mat-dialog-content>
    <h1 class="mat-dialog__header">{{ groupName }}</h1>
    <h2 class="mat-dialog__subtitle">Checklist</h2>
    <p class="mt-3 pb-2">
      Please check all items so the stage can be marked as done
    </p>
    <table class="mat-dialog__table--stretch-width">
      <thead>
        <tr>
          <th>Order</th>
          <th>Checklist Title</th>
          <th>Description</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of checklistItems">
          <td>{{ item.order }}</td>
          <td>{{ item.title }}</td>
          <td>{{ item.description }}</td>
          <td>
            <mat-checkbox
              [checked]="item.status"
              (change)="markItemAsDone($event, item)"
            ></mat-checkbox>
          </td>
        </tr>
      </tbody>
    </table>
  </mat-dialog-content>
  <mat-dialog-actions
    class="mat-dialog__actions mat-dialog__actions--align-right"
  >
    <button class="button button--secondary" (click)="close()">Cancel</button>
    <button
      class="button button--primary"
      [disabled]="disableMarkAsDone"
      (click)="masrkAsDone()"
    >
      Complete Checklist
    </button>
  </mat-dialog-actions>
</div>
