import { Component, Input, OnInit } from "@angular/core";
import { EstimateWorksheetService } from "app/common/services/estimate-worksheet.service";
import { JobPhasModel } from "app/model/jobPhaseModel";
import { BaThemeSpinner } from "app/theme/services";
import { SharedService } from "../../../common/utility/SharedService";

@Component({
  selector: "bill-of-materials-grid",
  templateUrl: "./bill-of-materials-grid.component.html",
  styleUrls: ["./bill-of-materials-grid.component.css"],
})
export class BillOfMaterialsGridComponent implements OnInit {
  @Input() worksheetId: string;
  private _phase: JobPhasModel;
  bomItems: { name: string; qty: number }[] = [];
  cols: any[];

  @Input() set phase(value: JobPhasModel) {
    if (value) {
      this._phase = value;
      this.getData();
    }
  }
  get phase(): JobPhasModel {
    return this._phase;
  }

  constructor(
    private worksheetService: EstimateWorksheetService,
    private sharedService: SharedService,
    private spinner: BaThemeSpinner
  ) {}

  ngOnInit() {
    this.setTableColumns();
  }

  private getData() {
    if (parseInt(this.worksheetId) == 0){
      this.bomItems = [];
      return;
    }
    this.spinner.show();
    this.worksheetService
      .getEstimateWorksheetProductsById(
        this.worksheetId,
        this.sharedService.selectedOrganization.ID,
        {
          jobPhaseId: this.phase.OriginalPhaseId,
          filterDeleteds: true,
          estimateId: parseInt(this.worksheetId),
        }
      )
      .subscribe((data) => {
        this.spinner.hide();
        this.bomItems = data.worksheetDetail.map((wd) => ({
          name: wd.productName,
          qty: wd.qty,
        }));
      });
  }

  private setTableColumns(): void {
    this.cols = [
      { field: "name", header: "Product Name", textCenter: false },
      { field: "qty", header: "Qty", textCenter: true },
    ];
  }
}
