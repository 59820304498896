<table class="table">
  <thead>
    <tr>
      <th style="padding-left: 5px !important">Items</th>
      <th>Sync</th>
      <th>Import</th>
      <th class="qb-note-header-cell" style="width: 100% !important">
        Sync Notes
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Invoices</td>
      <td class="text-center">
        <input
          type="checkbox"
          [ngModel]="invoices"
          (click)="invoicesClick(); organizationUpdated()"
        />
      </td>
      <td></td>
      <td class="qb-note-cell">
        Syncs invoices, payments, products, categories, vendors, and customers
        to QuickBooks
      </td>
    </tr>
    <tr>
      <td>Products</td>
      <td class="text-center">
        <input
          type="checkbox"
          [ngModel]="products"
          (click)="products = !products; organizationUpdated()"
          [disabled]="invoices"
        />
      </td>
      <td class="text-center import-cell text-nowrap">
        <button
          type="button"
          class="btn mr-1 btn-secondary import-button"
          *ngIf="!organization.QBProductsImported"
          (click)="onSubmit(EntityTypeEnum.Product)"
        >
          Import
        </button>
        <div *ngIf="organization.QBProductsImported">
          {{ organization.QBProductsImported | date : "MM/dd/yyyy" }}
        </div>
      </td>
      <td class="qb-note-cell">
        Syncs new products and categories automatically to QuickBooks
      </td>
    </tr>
    <tr>
      <td>Vendors</td>
      <td class="text-center">
        <input
          type="checkbox"
          [ngModel]="vendors"
          (click)="vendors = !vendors; organizationUpdated()"
          [disabled]="invoices"
        />
      </td>
      <td class="text-center import-cell">
        <button
          type="button"
          class="btn mr-1 btn-secondary import-button"
          *ngIf="!organization.QBVendorsImported"
          (click)="onSubmit(EntityTypeEnum.Vendor)"
        >
          Import
        </button>
        <div *ngIf="organization.QBVendorsImported">
          {{ organization.QBVendorsImported | date : "MM/dd/yyyy" }}
        </div>
      </td>
      <td class="qb-note-cell">
        Syncs new vendors automatically to QuickBooks
      </td>
    </tr>
    <tr>
      <td>Customers</td>
      <td class="text-center">
        <input
          type="checkbox"
          [ngModel]="customers"
          [disabled]="invoices"
          (click)="customers = !customers; organizationUpdated()"
        />
      </td>
      <td class="text-center import-cell">
        <button
          type="button"
          class="btn mr-1 btn-secondary import-button"
          *ngIf="!organization.QBCustomersImported"
          (click)="onSubmit(EntityTypeEnum.Customer)"
        >
          Import
        </button>
        <div *ngIf="organization.QBCustomersImported">
          {{ organization.QBCustomersImported | date : "MM/dd/yyyy" }}
        </div>
      </td>
      <td class="qb-note-cell">
        Syncs new customers automatically to QuickBooks
      </td>
    </tr>
    <tr>
      <td>Employees</td>
      <td class="text-center">
        <input
          type="checkbox"
          [ngModel]="employees"
          (click)="employees = !employees; organizationUpdated()"
        />
      </td>
      <td class="text-center import-cell">
        <button
          type="button"
          class="btn mr-1 btn-secondary import-button"
          *ngIf="!organization.QBEmployeesImported"
          (click)="onSubmit(EntityTypeEnum.Employee)"
        >
          Import
        </button>
        <div *ngIf="organization.QBEmployeesImported">
          {{ organization.QBEmployeesImported | date : "MM/dd/yyyy" }}
        </div>
      </td>
      <td class="qb-note-cell">
        Syncs new employees automatically to QuickBooks
      </td>
    </tr>
    <tr>
      <td>Bills</td>
      <td class="text-center">
        <input
          type="checkbox"
          [ngModel]="bills"
          (click)="bills = !bills; organizationUpdated()"
        />
      </td>
      <td></td>
      <td class="qb-note-cell">Syncs bills to QuickBooks</td>
    </tr>
  </tbody>
</table>
