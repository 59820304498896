import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WorksheetWorkArea } from "app/model/WorksheetWorkArea";
import { environment } from "environments/environment";
import { Observable } from "rxjs";

const baseUrl = environment.baseEstimateUrl;

@Injectable({
  providedIn: "root",
})
export class WorksheetWorkAreaService {
  constructor(private http: HttpClient) {}

  getAllByEstimateWorksheetId(
    estimateWorksheetId: string,
    filterShowOnCustomerProposal: boolean = false
  ): Observable<WorksheetWorkArea[]> {
    const url = `${baseUrl}WorksheetWorkAreas/GetAllByEstimateWorksheetId/${filterShowOnCustomerProposal}/${estimateWorksheetId}`;
    return this.http.get<WorksheetWorkArea[]>(url);
  }

  bulk(listModel: WorksheetWorkArea[]): Observable<Object> {
    const url = `${baseUrl}WorksheetWorkAreas/bulk`;
    return this.http.post(url, listModel);
  }

  updateWorksheetWorkaAreaProp(
    prop: string,
    worksheetWorkAreaId: number,
    value: boolean
  ) {
    return this.http.patch(`${baseUrl}WorksheetWorkAreas/update/${prop}`, {
      worksheetWorkAreaId,
      value,
    });
  }

  updateWorksheetWorkAreas(listModel: WorksheetWorkArea[]): Observable<Object> {
    const url = `${baseUrl}WorksheetWorkAreas/update/worksheetworkareas`;
    return this.http.post(url, listModel);
  }
}
