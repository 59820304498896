<div
  class="page-top clearfix"
  baScrollPosition
  maxHeight="50"
  (scrollChange)="scrolledChanged($event)"
  [ngClass]="{scrolled: isScrolled}"
>
  <a
    routerLink="/pages/scheduler"
    class="al-logo clearfix"
    [ngClass]="{'nav-sm':isMenuCollapsed}"
    ><img
      *ngIf="sharedService.selectedOrganization.LogoUrl"
      [src]="sharedService.selectedOrganization.LogoUrl"
      title="{{sharedService.selectedOrganization.OrganizationName}}"
    />
    <!-- <label
            *ngIf="!sharedService.selectedOrganization.LogoUrl">{{sharedService.selectedOrganization.OrganizationName}}</label> -->
  </a>
  <a
    href
    (click)="toggleMenu()"
    class="collapse-menu-link ion-navicon icon icon-menu"
  ></a>

  <kendo-dropdownlist
    *ngIf="parentHasChildren"
    [disabled]="sharedService.isImpersonated"
    [data]="childOrgs"
    [(ngModel)]="clickedOrg"
    [textField]="'OrganizationName'"
    [valueField]="'ID'"
    (selectionChange)="ChildOrgImpersonate($event)"
    style="margin: 12px; width: 250px; font-size: small; vertical-align: middle"
    class="align-dropdown icon-background icon-silver"
  >
  </kendo-dropdownlist>

  <div class="impersonated" *ngIf="sharedService.isImpersonated">
    Active Company:
    <b> {{sharedService.selectedOrganization.OrganizationName}}</b>
    <span (click)="revertImpersonate()">finish Session</span>
  </div>
  <!-- <div class="impersonated" *ngIf="!sharedService.isImpersonated"><b>Organization Name: {{sharedService.selectedOrganization.OrganizationName}}</b></div> -->

  <div class="top-right">
    <div id="contact-us" class="contact-us">&nbsp;</div>
    <div id="weather-appcues-widget">
      <i
        class="appcues-widget-icon icon fa fa-bolt"
        (click)="displayWeatherRescheduler = true;"
      ></i>
    </div>
    <div id="chat-appcues-widget" (click)="displayChat= true" *ngIf="canChat">
      <i
        class="appcues-widget-icon icon fa fa-comment"
        [ngClass]="{'appcues-in': chatNotifications > 0, 'appcues-slide': chatNotifications > 0 }"
        [attr.data-appcues-count]="chatNotifications"
      ></i>
    </div>
    <div id="appcues-widget">
      <i class="appcues-widget-icon icon fa fa-university"></i>
    </div>
    <div class="todoandcalendar-blk">
      <!-- <a title="My Calendar">
            <div class="calender-blk"><span class="month">Mar</span>
                <h3 class="date">31</h3>
            </div>
        </a> -->
      <a title="To Do List" (click)="SlideOut.emit(true)"
        ><i class="fa fa-file-text-o"></i
      ></a>
    </div>
    <div>
      <a
        href="javascript:void(0)"
        class="dropdown-toggle bellIcon"
        id="msg-dd1"
        (click)="notifications.show()"
      >
        <i class="fa fa-bell-o"></i>
        <span *ngIf="notifications.unViewedItems"
          >{{notifications.unViewedItems}}</span
        >
      </a>
    </div>
    <div class="user-profile clearfix">
      <div class="dropdown al-user-profile">
        <a
          class="profile-toggle-link dropdown-toggle"
          id="user-profile-dd"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <i class="fa fa-user-o"></i>
          <span class="ptxt"
            ><span>{{fullName}}</span><i class="fa fa-angle-down"></i
          ></span>
        </a>
        <ul
          class="dropdown-menu top-dropdown-menu profile-dropdown"
          aria-labelledby="user-profile-dd"
        >
          <li class="dropdown-item">
            <a (click)="showSlides='profile'; visibleSidebar=true"
              ><i class="fa fa-user"></i>Profile</a
            >
          </li>
          <li class="dropdown-item">
            <a routerLink="/pages/account/account/changePassword"
              ><i class="fa fa-unlock-alt"></i>Change Password</a
            >
          </li>
          <li class="dropdown-item">
            <a href class="signout" (click)="signOut()"
              ><i class="fa fa-power-off"></i>Sign out</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<notifications #notifications class="notification"></notifications>
<p-sidebar
  [(visible)]="visibleSidebar"
  position="right"
  [baseZIndex]="10000"
  class="addeditclient"
  (onHide)="onHideSliderHide($event)"
  appendTo="body"
>
  <add-Client
    *ngIf="showSlides=='profile'"
    [profileId]="logInUserId"
    (updateEvent)="onHideSliderHide($event)"
  >
  </add-Client>
</p-sidebar>
<chat [(visible)]="displayChat"></chat>

<p-sidebar
  class="addeditclient medium"
  [(visible)]="displayWeatherRescheduler"
  position="right"
  (onHide)="onHideSliderHide($event)"
  [baseZIndex]="10000"
  appendTo="body"
>
  <slideout-container [headerName]="'Weather Rescheduler'">
    <weather-rescheduler
      *ngIf="displayWeatherRescheduler"
      (onHide)="onHideSliderHide($event)"
    ></weather-rescheduler>
  </slideout-container>
</p-sidebar>
<!-- routerLink="/pages/clients/clientAddEdit/{{logInUserId}}/profile" -->
