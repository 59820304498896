import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { JobService } from "app/common/services/job.service";
import { JobContactInfoModel } from "app/model/jobContactModel";
import { BaThemeSpinner } from "app/theme/services/baThemeSpinner";
import { tap } from "rxjs/operators";

@Component({
  selector: "app-lead-contacts",
  templateUrl: "./lead-contacts.component.html",
  styleUrls: ["./lead-contacts.component.scss"],
})
export class LeadContactsComponent implements OnInit {
  private jobId: string;
  jobName: string;
  contacts: JobContactInfoModel[];

  constructor(
    private jobService: JobService,
    private spinner: BaThemeSpinner,
    private dialogRef: MatDialogRef<LeadContactsComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      jobId: string;
      jobName: string;
    }
  ) {
    this.jobId = data.jobId;
    this.jobName = data.jobName;
  }

  ngOnInit() {
    this.spinner.show();
    this.jobService
      .getAllJobContactsByJobID(this.jobId)
      .pipe(tap(() => this.spinner.hide()))
      .subscribe((data) => (this.contacts = data));
  }

  close() {
    this.dialogRef.close();
  }
}
