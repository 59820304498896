import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";

export function conditionalRequired(shouldBeRequired: boolean): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      shouldBeRequired &&
      (control.value === null ||
        control.value === undefined ||
        control.value === "")
    ) {
      return { required: true };
    }
    return null;
  };
}
