import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";
import { SharedService } from "app/common/utility/SharedService";
import { environmentConstant } from "app/common/utility/environment";

import { Observable } from "rxjs";

import { ApiResponseModel as Api } from "app/model/ApiResponseModel";
import { UserModel } from "app/model/Core/UserModel";
import { Catalog, SystemCatalog } from "app/model/Core/catalogModel";
import { Category } from "app/model/Core/categoryModel";
import { Product } from "app/model/Core/productModel";
import {
  SearchFilter,
  FilteredSearchModel,
  SearchModel,
} from "app/model/SearchModel";
import { Featured, EntityType } from "app/model/Core/featured";
import { AppTheme } from "app/model/Core/appTheme";
import { Pager } from "app/model/Pager";
import { OrganizationModel } from "app/model/Core/OrganizationModel";
import { QuickBooksImportModel } from "app/model/Core/QuickBooksImportModel";
import { QuickBooksItemModel } from "app/model/Core/QuickBooksItemModel";
import { TradeModel } from "app/model/Core/TradeModel";
import { OrgVendorModel } from "app/model/Core/OrgVendorModel";
import { CatalogInfo } from "app/model/Core/CatalogInfo";

@Injectable()
export class OrganizationService {
  guidEmpty: string = "00000000-0000-0000-0000-000000000000";
  constructor(
    private sharedService: SharedService,
    private httpService: HttpService
  ) {}

  getTrades(): Observable<Api<TradeModel[]>> {
    return this.httpService.get<TradeModel[]>(
      environmentConstant.coreApi.getTrades(
        this.sharedService.selectedOrganization.ID
      )
    );
  }

  postJobsWhiteboardSearch(
    orgGuid: string,
    model: FilteredSearchModel<any>,
    shouldReturnRoles: boolean = false
  ): Observable<Api<any>> {
    return this.httpService.post(
      environmentConstant.coreApi.getJobs(orgGuid, shouldReturnRoles),
      model
    );
  }

  getLeads(
    orgGuid: string,
    includeDeleted: boolean,
    model: FilteredSearchModel<any>
  ): Observable<Api<any>> {
    return this.httpService.post(
      environmentConstant.coreApi.getLeads(orgGuid, includeDeleted),
      model
    );
  }

  getJobWhiteBoard(jobGuid: string): Observable<Api<any[]>> {
    return this.httpService.get<any[]>(
      environmentConstant.coreApi.getJob(jobGuid)
    );
  }

  pinJob(jobGuid: string): Observable<Api<boolean>> {
    return this.httpService.put<boolean>(
      environmentConstant.coreApi.pinJob(jobGuid),
      null
    );
  }

  unpinJob(jobGuid: string): Observable<Api<boolean>> {
    return this.httpService.put<boolean>(
      environmentConstant.coreApi.unpinJob(jobGuid),
      null
    );
  }

  postConstructionWhiteboardCounts(
    orgGuid: string,
    model: FilteredSearchModel<any>
  ): Observable<Api<any>> {
    return this.httpService.post(
      environmentConstant.coreApi.getConstructionJobCounts(orgGuid),
      model
    );
  }

  postSalesWhiteboardCounts(
    orgGuid: string,
    model: FilteredSearchModel<any>
  ): Observable<Api<any>> {
    return this.httpService.post(
      environmentConstant.coreApi.getSalesJobCounts(orgGuid),
      model
    );
  }

  // getConstructionJobWhiteBoardCounts(
  //   orgGuid: string,
  //   templateGuid: string,
  //   userGuid: string
  // ): Observable<Api<any[]>> {
  //   templateGuid = templateGuid == undefined ? this.guidEmpty : templateGuid;
  //   userGuid = userGuid == undefined ? this.guidEmpty : userGuid;
  //   return this.httpService.get<any[]>(
  //     environmentConstant.coreApi.getConstructionJobWhiteboardCounts(
  //       orgGuid,
  //       templateGuid,
  //       userGuid
  //     )
  //   );
  // }
  // getSalesJobWhiteBoardCounts(
  //   orgGuid: string,
  //   templateGuid: string,
  //   userGuid: string
  // ): Observable<Api<any[]>> {
  //   templateGuid = templateGuid == undefined ? this.guidEmpty : templateGuid;
  //   userGuid = userGuid == undefined ? this.guidEmpty : userGuid;

  //   return this.httpService.get<any[]>(
  //     environmentConstant.coreApi.getSalesJobWhiteboardCounts(
  //       orgGuid,
  //       templateGuid,
  //       userGuid
  //     )
  //   );
  // }

  searchQuickBooksCustomers(
    model: FilteredSearchModel<SearchFilter>
  ): Observable<Api<Pager<QuickBooksItemModel>>> {
    return this.httpService.post<Pager<QuickBooksItemModel>>(
      environmentConstant.coreApi.searchQuickbooksCustomers(
        this.sharedService.selectedOrganization.ID
      ),
      model
    );
  }

  searchQuickBooksEmployees(
    model: FilteredSearchModel<SearchFilter>
  ): Observable<Api<Pager<QuickBooksItemModel>>> {
    return this.httpService.post<Pager<QuickBooksItemModel>>(
      environmentConstant.coreApi.searchQuickbooksEmployees(
        this.sharedService.selectedOrganization.ID
      ),
      model
    );
  }

  searchQuickBooksVendors(
    model: FilteredSearchModel<SearchFilter>
  ): Observable<Api<Pager<QuickBooksItemModel>>> {
    return this.httpService.post<Pager<QuickBooksItemModel>>(
      environmentConstant.coreApi.searchQuickbooksVendors(
        this.sharedService.selectedOrganization.ID
      ),
      model
    );
  }

  searchQuickBooksProducts(
    model: FilteredSearchModel<SearchFilter>
  ): Observable<Api<Pager<QuickBooksItemModel>>> {
    return this.httpService.post<Pager<QuickBooksItemModel>>(
      environmentConstant.coreApi.searchQuickbooksProducts(
        this.sharedService.selectedOrganization.ID
      ),
      model
    );
  }

  importQuickbooks(model: QuickBooksImportModel): Observable<Api<boolean>> {
    return this.httpService.post<boolean>(
      environmentConstant.coreApi.postQuickbooksImport(
        this.sharedService.selectedOrganization.ID
      ),
      model
    );
  }

  getQuickbooksStatus(): Observable<Api<boolean>> {
    return this.httpService.get<boolean>(
      environmentConstant.coreApi.getQuickbooksStatus(
        this.sharedService.selectedOrganization.ID
      )
    );
  }

  connectQuickbooks(): Observable<Api<string>> {
    return this.httpService.post<string>(
      environmentConstant.coreApi.postQuickbooksConnect(
        this.sharedService.selectedOrganization.ID
      ),
      null
    );
  }

  disconnectQuickbooks(): Observable<Api<boolean>> {
    return this.httpService.put<boolean>(
      environmentConstant.coreApi.putQuickbooksDisconnect(
        this.sharedService.selectedOrganization.ID
      ),
      null
    );
  }

  createVendor(model: OrgVendorModel): Observable<Api<OrgVendorModel>> {
    return this.httpService.post<OrgVendorModel>(
      environmentConstant.coreApi.postOrgVendor(
        this.sharedService.selectedOrganization.ID
      ),
      model
    );
  }

  getAllOrgCatalogs(): Observable<Api<Catalog[]>> {
    return this.httpService.get<Catalog[]>(
      environmentConstant.coreApi.getPostOrgCatalogs(
        this.sharedService.selectedOrganization.ID
      )
    );
  }

  getAllSystemCatalogs(): Observable<Api<SystemCatalog[]>> {
    return this.httpService.get<SystemCatalog[]>(
      environmentConstant.coreApi.getAllCatalogs()
    );
  }

  updateOrgCatalog(catalog: Catalog): Observable<Api<Catalog>> {
    return this.httpService.put<Catalog>(
      environmentConstant.coreApi.putDeleteOrgCatalog(catalog.guid),
      catalog
    );
  }

  addOrgCatalog(catalog: Catalog) {
    return this.httpService.post<Catalog>(
      environmentConstant.coreApi.getPostOrgCatalogs(
        this.sharedService.selectedOrganization.ID
      ),
      catalog
    );
  }

  deleteOrgCatalog(orgCatalogGuid: string): Observable<Api<Catalog>> {
    return this.httpService.delete<Catalog>(
      environmentConstant.coreApi.putDeleteOrgCatalog(orgCatalogGuid)
    );
  }

  getCategoriesForCatalog(orgCatalogGuid: string): Observable<Api<Category[]>> {
    return this.httpService.get<Category[]>(
      environmentConstant.coreApi.categories(orgCatalogGuid)
    );
  }

  addCategoryForCatalog(
    orgCatalogGuid: string,
    payload: Category
  ): Observable<Api<Category>> {
    return this.httpService.post<Category>(
      environmentConstant.coreApi.categories(orgCatalogGuid),
      payload
    );
  }

  addManagedCategory(
    orgCatalogGuid: string,
    categoryGuid
  ): Observable<Api<any>> {
    const o = {
      guid: categoryGuid,
    };
    return this.httpService.post<any>(
      environmentConstant.coreApi.categories(orgCatalogGuid),
      o
    );
  }

  updateCategoryForCatalog(category: Category): Observable<Api<Category>> {
    return this.httpService.put<Category>(
      environmentConstant.coreApi.getPutDeleteCategory(category.guid),
      category
    );
  }

  getProductsForCategory(categoryGuid: string): Observable<Api<Product[]>> {
    return this.httpService.get<Product[]>(
      environmentConstant.coreApi.products(categoryGuid)
    );
  }

  getBlobs(blobGuid): Observable<Api<Blob>> {
    return this.httpService.get<Blob>(
      environmentConstant.coreApi.getBlobs(blobGuid)
    );
  }

  getFeatured(): Observable<Api<Featured[]>> {
    return this.httpService.get<Featured[]>(
      environmentConstant.coreApi.featured(
        this.sharedService.selectedOrganization.ID
      )
    );
  }

  setFeatured(
    itemGuid: string,
    entityType: EntityType
  ): Observable<Api<Featured>> {
    const featuredItem = <Featured>{
      entityGuid: itemGuid,
      entityType,
    };

    return this.httpService.post<Featured>(
      environmentConstant.coreApi.featured(
        this.sharedService.selectedOrganization.ID
      ),
      featuredItem
    );
  }

  deleteFeatured(featuredGuid: string): Observable<Api<Featured>> {
    return this.httpService.delete(
      environmentConstant.coreApi.getPutDeleteFeaturedEntity(featuredGuid)
    );
  }

  searchContractors(
    model: FilteredSearchModel<SearchFilter>
  ): Observable<Api<Pager<UserModel>>> {
    return this.httpService.post<Pager<UserModel>>(
      environmentConstant.coreApi.searchOrgContractors(
        this.sharedService.selectedOrganization.ID
      ),
      model
    );
  }

  searchCustomers(
    model: FilteredSearchModel<SearchFilter>
  ): Observable<Api<Pager<UserModel>>> {
    return this.httpService.post<Pager<UserModel>>(
      environmentConstant.coreApi.searchOrgCustomers(
        this.sharedService.selectedOrganization.ID
      ),
      model
    );
  }

  searchEmployees(
    model: FilteredSearchModel<SearchFilter>
  ): Observable<Api<Pager<UserModel>>> {
    return this.httpService.post<Pager<UserModel>>(
      environmentConstant.coreApi.searchOrgEmployees(
        this.sharedService.selectedOrganization.ID
      ),
      model
    );
  }

  searchUsers(
    model: FilteredSearchModel<SearchFilter>
  ): Observable<Api<Pager<UserModel>>> {
    return this.httpService.post<Pager<UserModel>>(
      environmentConstant.coreApi.searchOrgUsers(
        this.sharedService.selectedOrganization.ID
      ),
      model
    );
  }

  searchExternals(
    model: FilteredSearchModel<SearchFilter>
  ): Observable<Api<Pager<UserModel>>> {
    return this.httpService.post<Pager<UserModel>>(
      environmentConstant.coreApi.searchOrgExternals(
        this.sharedService.selectedOrganization.ID
      ),
      model
    );
  }

  searchProducts(searchTerm?: string): Observable<Api<Pager<Product>>> {
    const search = <FilteredSearchModel<SearchFilter>>{
      filter: {
        term: searchTerm,
      },
      pagerInfo: {
        getCounts: true,
        take: 5,
        skip: 0,
      },
    };

    return this.httpService.post<Pager<Product>>(
      environmentConstant.coreApi.searchProducts(
        this.sharedService.selectedOrganization.ID
      ),
      search
    );
  }

  getActiveTheme(): Observable<Api<AppTheme>> {
    return this.httpService.get<AppTheme>(
      environmentConstant.coreApi.getPutPostOrgAppTheme(
        this.sharedService.selectedOrganization.ID
      )
    );
  }

  setActiveTheme(theme: AppTheme): Observable<Api<AppTheme>> {
    return this.httpService.put<AppTheme>(
      environmentConstant.coreApi.getPutPostOrgAppTheme(
        this.sharedService.selectedOrganization.ID
      ),
      theme
    );
  }

  getOrgVendorList(orgGuid: string): Observable<Api<OrganizationModel[]>> {
    return this.httpService.get<OrganizationModel[]>(
      environmentConstant.coreApi.getOrgVendorList(orgGuid)
    );
  }

  getManagedCategories(orgCatalogGuid: string): Observable<Api<any>> {
    return this.httpService.get(
      environmentConstant.coreApi.getPostManagedCategories(orgCatalogGuid)
    );
  }

  getCatalogInfo(orgCatalogGuid: string): Observable<Api<any>> {
    return this.httpService.get(
      environmentConstant.coreApi.getPostOrgCatalogInfo(orgCatalogGuid)
    );
  }

  addCatalogInfo(
    orgCatalogGuid: string,
    payload: CatalogInfo
  ): Observable<Api<any>> {
    return this.httpService.post(
      environmentConstant.coreApi.getPostOrgCatalogInfo(orgCatalogGuid),
      payload
    );
  }

  syncQuickbookItem(
    quickbookIdentifier: number,
    productGuid: string,
    productId: number
  ): Observable<Api<any>> {
    const params = {
      QuickBooksIdentifier: quickbookIdentifier,
      ProductGuid: productGuid,
      ProductId: productId,
    };
    return this.httpService.post<any>(
      environmentConstant.coreApi.syncQuickbookItem(
        this.sharedService.selectedOrganization.ID
      ),
      params
    );
  }
}
