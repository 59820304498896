<div class="picture-group" [ngClass]="{uploading: uploadInProgress}">
  <div class="picture-wrapper" (click)="bringFileSelector();">
    <img [src]="picture" *ngIf="picture && !ImageURL" />
    <img
      id="categoryimg"
      *ngIf="ImageURL"
      src="{{pictureModel | imageFilter : pictureModel }}"
    />
    <img [src]="defaultPicture" *ngIf="!!!picture && !!defaultPicture" />

    <div class="loading" *ngIf="uploadInProgress">
      <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
    </div>
  </div>
  <i
    class="ion-ios-close-outline"
    (click)="removePicture();"
    *ngIf="picture && canDelete"
  ></i>
  <a class="change-picture" (click)="bringFileSelector();">Select Image</a>
  <input
    #fileUpload
    ngFileSelect
    [options]="uploaderOptions"
    (onUpload)="_onUpload($event)"
    (uploadOutput)="beforeUpload($event)"
    type="file"
    hidden="true"
  />
</div>
