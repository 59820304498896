import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { BaThemeSpinner } from "app/theme/services";
import { JobPhotoModel } from "app/model/jobPhotoModel";
import { JobPhotoService } from "app/common/services/jobphoto.service";
import { JobPhaseService } from "app/common/services/jobPhase.service";
import { DialogService } from "ng2-bootstrap-modal";
import { ConfirmComponent } from "app/component/dialog/dialog.component";
import { JobPhotoPaginationModel } from "app/model/jobPhotoModel";
import { MessageService } from "primeng/components/common/messageservice";
import { GuidMatcherService } from "app/common/utility/guid-matcher.service";
import { EntityCache } from "app/common/utility/EntityCache";
import { PhotoEnum } from "app/common/directives/commonEnum";
import { UpdateCountService } from "app/common/utility/updateCount.service";
import { UpdateCountType } from "app/model/Core/JobFileModel";
import { EstimateWorksheetService } from "app/common/services/estimate-worksheet.service";

@Component({
  selector: "job-photo-info-card",
  templateUrl: "./job-photo-info.component.html",
  styleUrls: ["./job-photo-info.component.css"],
  providers: [GuidMatcherService],
})
export class JobPhotoCardComponent implements OnInit {
  get lstPhotos() {
    return this.photosCache.items();
  }

  photosCache = new EntityCache<JobPhotoModel>();

  currentpage: number = 1;
  pageSize: number = 100;
  totalRecords: number = null;
  imageUrls: string[];
  enableDelete: boolean = false;
  dataIdToEdit: number;
  visibleSidebar: boolean = false;
  showSlides: string = "";
  selectAll = false;

  @Input() jobId: any = 0;
  @Input() JobPhaseId: number = 0;
  @Input() CallingComponent: string;
  @Input() isFirstSlide: boolean;
  @Input() sideBarClass: any;
  @Input() isSales: any;
  @Input() isPhase = false;
  @Input() isWorkOrder: boolean = false;
  @Input() isFromPhotos: boolean = false;
  @Output() photosRetrieved = new EventEmitter<JobPhotoPaginationModel>();
  @Output() updatePhotoCount = new EventEmitter<any>();
  constructor(
    private _spinner: BaThemeSpinner,
    private jobPhotoService: JobPhotoService,
    private jobPhaseService: JobPhaseService,
    private dialogService: DialogService,
    private notificationsService: MessageService,
    private guidMatcher: GuidMatcherService<JobPhotoModel>,
    private updateCountService: UpdateCountService,
    private downloadFilesService: EstimateWorksheetService
  ) {}
  ngOnInit() {
    if (!this.sideBarClass || this.sideBarClass == "") {
      this.sideBarClass = "sidebar2";
    }
    if (this.isSales == null) this.isSales = false;
  }

  get isThisForPhase() {
    return this.isPhase || this.JobPhaseId > 0;
  }

  get isThisForNewPhase() {
    return this.isPhase && !this.JobPhaseId;
  }

  changejobId(jobId) {
    this.jobId = jobId;
    this.getPhotos();
  }
  //Get all photos data by ID
  getPhotos() {
    this._spinner.show();
    return new Promise<JobPhotoModel[]>((resolve) => {
      if (this.isThisForPhase && this.JobPhaseId && !this.isSales) {
        const jobId = this.jobId ? this.jobId : null;
        this.jobPhaseService
          .getPhotosByJobPhaseId(
            this.JobPhaseId,
            this.currentpage,
            this.pageSize,
            PhotoEnum.User,
            jobId
          )
          .subscribe((data) => {
            this.photosCache.clear();
            // data.items.forEach((item) => {
            //   var createdDate = new Date(item.CreatedOn + "Z");
            //   item.CreatedOn = createdDate;
            // });
            this.photosCache.addItems(data.items);
            this.totalRecords = data.totalItemCount;
            this.photosRetrieved.emit(data);
            for (var i in this.lstPhotos) {
              this.lstPhotos[i].selected = false;
            }
            this.updateCounters(data.totalCount);
            this._spinner.hide();
            resolve(this.lstPhotos);
          });
      } else {
        this.jobPhotoService
          .getJobPhotosByJobID(
            this.jobId,
            this.currentpage,
            this.pageSize,
            PhotoEnum.User
          )
          .subscribe((data) => {
            let list = data.items;
            // data.items.forEach((item) => {
            //   var createdDate = new Date(item.CreatedOn + "Z");
            //   item.CreatedOn = createdDate;
            // });
            if (this.isThisForNewPhase) {
              list = this.guidMatcher.matchGuids(list, (p) => p.Guid);
              this.photosCache.addItems(list);
              this.photosRetrieved.emit({
                items: this.lstPhotos,
                page: this.currentpage,
                pageSize: this.pageSize,
                totalItemCount: data.totalItemCount,
              });
              this.totalRecords = this.lstPhotos.length;
            } else {
              this.photosCache.clear();
              this.photosCache.addItems(data.items);
              this.totalRecords = data.totalItemCount;
              this.photosRetrieved.emit(data);
            }
            for (var i in this.lstPhotos) {
              this.lstPhotos[i].selected = false;
            }
            this._spinner.hide();
            resolve(this.lstPhotos);
          });
      }
    });
  }
  async onNewPhotos(guids: string[]) {
    this.showSlides = "";
    this.visibleSidebar = false;
    this.guidMatcher.addGuid(guids);
    setTimeout(async () => {
      await this.getPhotos();
      this.updatePhotoCount.emit(this.lstPhotos.length);
      if (this.isThisForNewPhase) {
        const photoIds = this.photosCache.getItems(guids).map((p) => p.ID);
        if (Array.isArray(photoIds) && photoIds.length)
          this.jobPhotoService
            .deleteSelectedJobPhoto(photoIds)
            .subscribe(() => {});
      }
    }, 2000);
  }
  loadData(e) {
    this.currentpage = e.first / this.pageSize + 1;
    this.getPhotos();
  }
  selectDeselectAllPhotos(isAll: boolean) {
    if (isAll) {
      this.enableDelete = false;
      this.lstPhotos.forEach((photo) => {
        photo.selected = this.selectAll;
      });
    } else {
      var res = [];
      var photos = this.lstPhotos.find((photo) => photo.selected == false);
      res.push(photos);
    }

    var selected = [];
    var selPhotos = this.lstPhotos.filter((x) => x.selected == true);
    if (selPhotos.length > 0) {
      selPhotos.forEach((photo) => {
        selected.push(photo);
      });
    }

    if (selected.length > 0) {
      this.enableDelete = true;
    } else {
      this.enableDelete = false;
    }
  }

  onAddNewData(id, text) {
    this.dataIdToEdit = id;
    this.visibleSidebar = true;
    this.showSlides = text;
  }
  deleteJobPhotos() {
    var selectedPhotos = this.lstPhotos.filter((x) => x.selected == true);
    if (selectedPhotos && selectedPhotos.length > 0) {
      const disposable = this.dialogService
        .addDialog(ConfirmComponent, {
          title: "Delete Photos",
          message:
            "Are you sure you want to delete " +
            selectedPhotos.length +
            (selectedPhotos.length == 1 ? " photo?" : " photos?"),
        })
        .subscribe((isConfirmed) => {
          if (isConfirmed) {
            this._spinner.show();
            var selectedPhotos = this.lstPhotos.filter(
              (x) => x.selected == true
            );
            let intArr = [];
            selectedPhotos.filter((item) => {
              intArr.push(item.ID);
            });

            this.jobPhotoService
              .deleteSelectedJobPhoto(intArr)
              .subscribe((res) => {
                this.result(res, "Deleted Successfully!!", "Photo");
                this.photosCache.deleteItems(selectedPhotos.map((p) => p.Guid));
                this.getPhotos();
                this.selectAll = false;
                this.enableDelete = false;
                this.updatePhotoCount.emit(this.lstPhotos.length);
              });
          }
        });
    } else {
      this.notificationsService.add({
        severity: "warn",
        summary: "Photos",
        detail: "Please select photo to delete.",
      });
    }
  }

  private result(res: any, message, title) {
    if (res !== null) {
      this.notificationsService.add({
        severity: "success",
        summary: title,
        detail: message,
      });
      this._spinner.hide();
    }
  }
  deleteAllJobPhotos() {
    const disposable = this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Delete All Photo",
        message: "Are you sure you want to delete all photos of this job?",
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this._spinner.show();
          this.jobPhotoService.deleteJobPhoto(this.jobId).subscribe((res) => {
            this.result(res, "Deleted Successfully!!", "All Photos");
            this.getPhotos();
            this.enableDelete = false;
            this.updatePhotoCount.emit(0);
          });
        }
      });
  }
  deleteJobPhoto(photo: JobPhotoModel) {
    const disposable = this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Delete Photo",
        message: "Are you sure you want to delete " + photo.FileName + "?",
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.jobPhotoService.deletePhoto(photo.ID).subscribe((res) => {
            this.result(res, "Deleted Successfully!!", "Photo");
            this.selectAll = false;
            this.enableDelete = false;
            this.photosCache.delete(photo.Guid);
            this.getPhotos();
            this.updatePhotoCount.emit(this.lstPhotos.length);
          });
        }
      });
  }

  onHide(e) {
    this.showSlides == "";
    this.visibleSidebar = false;
  }

  /** updates the job counters */
  private updateCounters(count: number) {
    this.updateCountService.updateCount({
      entityId: this.JobPhaseId,
      count: count,
      countType: UpdateCountType.Photos,
    });
    this.updatePhotoCount.emit(count);
  }

  downloadPhotos() {
    const selectedPhotosURLs = this.lstPhotos
      .filter((x) => x.selected)
      .map((x) => x.BlobUrl);

    if (selectedPhotosURLs.length == 0) return;
    this._spinner.show();
    this.downloadFilesService.downloadFiles(selectedPhotosURLs).subscribe(
      (response: any) => {
        this._spinner.hide();
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute("download", "photos.zip");
        document.body.appendChild(downloadLink);
        downloadLink.click();
      },
      (error) => {
        this.notificationsService.add({
          severity: "error",
          summary: "Download Photos",
          detail: "Error downloading photos",
        });
        console.log(error);
        this._spinner.hide();
      }
    );
  }
}
