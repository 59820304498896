<div class="customPop">
  <div class="modal-dialog modal-sm Modalmd">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Schedule Update</h4>
      </div>
      <div class="modal-body custom-modal">
        <!-- <p  class="check">Do you want to update dependent phases?
                    <input type="checkbox" [(ngModel)]="scheduleUpdate.updateDependents" name="updateDependents" >
                </p>
                -->
        <p class="check">
          Changing the scheduled date will impact dependent phases.
        </p>
        <p>What would you like to do?</p>
        <span class="mt10">
          <label class="radio-inline custom-radio nowrap">
            <input
              type="radio"
              name="DependentAction"
              id="DependentAction"
              [(ngModel)]="scheduleUpdate.DependentAction"
              [value]="4"
            />
            <span>Reschedule phase only.</span>
          </label>
        </span>
        <span class="mt10">
          <label class="radio-inline custom-radio nowrap">
            <input
              type="radio"
              name="DependentAction"
              id="DependentAction"
              [(ngModel)]="scheduleUpdate.DependentAction"
              [value]="1"
            />
            <span>Reschedule phase and its dependents.</span>
          </label>
        </span>

        <!-- <span class="mt10">
                    <label class="radio-inline custom-radio nowrap">
                        <input type="radio" name="DependentAction" id="DependentAction" [(ngModel)]="scheduleUpdate.DependentAction"
                            [value]="3">
                        <span>Reschedule phase and reset dependents.</span>
                    </label>
                </span> -->
        <!-- <span class="mt10" *ngIf="updateType=='schedule'">
                    <label class="radio-inline custom-radio">
                        <input type="radio" name="DependentAction" id="DependentAction" [(ngModel)]="scheduleUpdate.DependentAction"
                            [value]="2">
                        <span>Reschedule phase and send out schedule request for dependents.</span>
                    </label>
                </span> -->
      </div>
      <div class="form-group cdrop" *ngIf="showReason">
        <kendo-floatinglabel text="What is the reason for this change">
          <kendo-dropdownlist
            kendoTextBox
            name="leadSourceId"
            [valuePrimitive]="true"
            [(ngModel)]="scheduleUpdate.reasonId"
            [data]="changeReasonList"
            textField="Reason"
            valueField="Id"
          >
          </kendo-dropdownlist>
        </kendo-floatinglabel>
      </div>
      <div class="form-group mt10 cdrop" *ngIf="scheduleUpdate.reasonId > 0">
        <span class="ui-float-label">
          <textarea
            class="form-control mb-0"
            name="Comments"
            maxlength="500"
            id="comments"
            name="comments"
            [(ngModel)]="scheduleUpdate.Comments"
            rows="5"
          ></textarea>
          <label for="text" class="form-control-label">Comments</label>
        </span>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn darkbtn" (click)="confirm()">
          OK
        </button>
        <button type="button" class="btn btn-default" (click)="close()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
