
    <ba-sidebar (SlideoutEvent)="ToggleSlide($event)"></ba-sidebar>
    <ba-page-top
      #CommonContainer
      (onHide)="onHideSliderHide($event)"
      (SlideOut)="toggleToDolist($event)"
    ></ba-page-top>
    <div class="al-main">
      <div class="al-content">
        <ba-content-top></ba-content-top>
        <router-outlet></router-outlet>
        <app-image-annotator></app-image-annotator>
        <photo-carousel [useService]="true"></photo-carousel>
        <p-sidebar
          [(visible)]="OrganizationSlideOut"
          class="addeditclient organization-slider"
          position="right"
          [baseZIndex]="10000"
          appendTo="body"
        >
          <add-Organizations
            *ngIf="OrganizationSlideOut"
            [IsSuperAdmin]="false"
            [ID]="sharedService.selectedOrganization.ID"
            (refreshData)="onHideSliderHide($event)"
          ></add-Organizations>
        </p-sidebar>
        <p-sidebar
          [(visible)]="toDoListSlideout"
          (onHide)="toggleToDolist($event)"
          class="addeditclient app-todo-list"
          [ngClass]="toDoListSlideout ? 'todosidebar' : ''"
          position="right"
          [baseZIndex]="10000"
          appendTo="body"
        >
          <app-todo-list
            *ngIf="toDoListSlideout"
            #todoList
            (addEvent)="AddToDoList($event)"
            (updateEvent)="EditPhase($event)"
          ></app-todo-list>
          <div [ngClass]="ContainerSlideOut ? 'sidebar2 open' : 'sidebar2'">
            <a
              class="cancelbtnHead ui-sidebar-close"
              (click)="ContainerSlideOut = false; showTask = false"
            >
              <span class="pi pi-times"></span>
            </a>
            <app-todo-advanced
              *ngIf="ContainerSlideOut && showTask"
              [TaskId]="TaskId"
              (updateToDoList)="refreshToDoList($event)"
            >
            </app-todo-advanced>
            <common-container
              *ngIf="ContainerSlideOut && CommonContainerData && !isLineItem"
              [IsPhase]="CommonContainerData.IsPhase"
              (updateEvent)="refreshToDoList($event)"
              [JobPhaseId]="CommonContainerData.JobPhaseId"
              [sideBarClass]="'sidebar3'"
              [JobID]="CommonContainerData.JobID"
              [CallingComponent]="CommonContainerData.CallingComponent"
            >
            </common-container>
            <Add-LineItem-Component
              *ngIf="ContainerSlideOut && CommonContainerData && isLineItem"
              [isAddWorkOrder]="false"
              [WorkOrderID]="CommonContainerData.JobID"
              [lineItemId]="CommonContainerData.JobPhaseId"
              (updateData)="refreshToDoList($event)"
            ></Add-LineItem-Component>
          </div>
        </p-sidebar>
      </div>
    </div>
    <footer class="al-footer clearfix graybg">
      <img
        src="./assets/images/pentair-logo.png"
        alt=""
        class="left-logo"
      /><img src="./assets/images/PentairMPB-logo.png" alt="" />&copy; 2022 -
      Pentair Pool Builder
    </footer>
    <ba-back-top position="200"></ba-back-top>
  