import { Injectable } from "@angular/core";
import { HttpService } from "../../../app/common/utility/http.service";
import { environmentConstant } from "../../../app/common/utility/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { OrderDTO } from "app/model/InvoiceOrderModel";
import { InvoiceCategoryModel } from "app/model/InvoiceCategory";
import { ProductDTO } from "app/model/AddProductModel";
import { SimpleProductModel } from "app/model/CategoryProductModel";
import { categorySimpleModel } from "app/model/categorySimpleDTO";
import { SharedService } from "app/common/utility/SharedService";
import { CatalogModel } from "app/model/CatalogModel";
import { OrderModel } from "app/model/OrderModel";
import { OrderNoteModel } from "app/model/OrderNote";
import { ProcessPaymentModel } from "app/model/PaymentModel";
import { DiscountModel } from "app/model/DiscountModel";
import { TransactionRequestModel } from "app/model/TransactionRequestModel";
import { SplitInvoiceModel } from "app/model/SplitInvoiceRequest";
import { RefundRequest } from "app/model/RefundRequestModel";
import { AddInvoiceModel } from "app/model/AddInvoiceModel";
import { GatewayAccountModel } from "app/model/GatewayAccountModel";
@Injectable()
// tslint:disable:prefer-template
export class InvoiceService {
  [x: string]: any;
  constructor(
    private httpService: HttpService,
    private sharedService: SharedService
  ) {}
  saveOrder(customerId: any) {
    return this.httpService.postRequestService(
      environmentConstant.addInvoice + customerId,
      null
    );
  }
  getCatalogs(orgId: any, useCore?: boolean): any {
    return this.httpService.getRequestService(
      environmentConstant.getCatalogs(orgId, useCore)
    );
  }
  getCategoryListbyCatalogId(
    catalogId: number
  ): Observable<categorySimpleModel> {
    return this.httpService.getRequestService(
      environmentConstant.getCategory +
        catalogId +
        "/orgGuid=" +
        this.sharedService.selectedOrganization.ID
    );
  }
  getProductbyCatId(categoryId: number): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getproductbycategoryId + categoryId
    );
  }
  moveUpDownCategory(cat1, cat2) {
    return this.httpService.putRequestService(
      environmentConstant.moveCategoryPosition + cat1 + "&categoryId2=" + cat2,
      null
    );
  }
  saveCategory(categoryModel: InvoiceCategoryModel) {
    return this.httpService.putRequestService(
      environmentConstant.saveCategoryV2,
      categoryModel
    );
  }
  getCategoryById(categoryId: number): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getCategorydata + categoryId
    );
  }
  getTreeCategoryList(id: number): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getTreeCategory +
        id +
        "/orgGuid=" +
        this.sharedService.selectedOrganization.ID
    );
  }
  deleteCategory(Id: number) {
    return this.httpService.deleteRequestService(
      environmentConstant.deleteProductCategory + Id
    );
  }
  saveProduct(productModel: ProductDTO) {
    return this.httpService.postRequestService(
      environmentConstant.saveProduct,
      productModel
    );
  }
  editProduct(productModel: ProductDTO) {
    return this.httpService.putRequestService("product", productModel);
  }
  getCategoryList(categoryId): Observable<SimpleProductModel> {
    return this.httpService.getRequestService(
      environmentConstant.getProductCategory + categoryId
    );
  }
  getManufacturList(orgGuid: any, includeDeleted: boolean): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getManufacturelist +
        orgGuid +
        "&includeDeleted=" +
        includeDeleted
    );
  }
  taxCategory(orgGuid): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.taxList + orgGuid
    );
  }
  getProductDetailsbyId(id: number, isshow: boolean): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getProductDetailbyId +
        id +
        "?includeRequiredProductsInfo=" +
        isshow
    );
  }

  getSystemDefaultProductDetails(systemOrg: string): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getSystemProductDetails + systemOrg
    );
  }

  deleteProduct(id: number) {
    return this.httpService.deleteRequestService(
      environmentConstant.deleteProduct + id
    );
  }
  //invoice
  getCustomerbyOrguid(): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getCustomer +
        this.sharedService.selectedOrganization.ID +
        "/customers"
    );
  }
  getFullProductInfo(productIdobj: any): Observable<any> {
    return this.httpService.postRequestService(
      environmentConstant.fullproductinfo,
      productIdobj
    );
  }
  saveInvoiceCustomer(AddInvoiceModel: AddInvoiceModel, moduleId: number) {
    return this.httpService.postRequestService(
      environmentConstant.saveInvoice + moduleId,
      AddInvoiceModel
    );
  }
  saveInvoice(invoiceModel: OrderModel, shouldSyncQuickBooks) {
    const urlParameters = `?sendtoQB=${shouldSyncQuickBooks}`;
    const url = environmentConstant.updateInvoice + urlParameters;

    return this.httpService.putRequestService(url, invoiceModel);
  }
  //print invoice
  printInvoiceById(invoiceId: number) {
    return this.httpService.postRequestService(
      environmentConstant.printInvoice + invoiceId,
      null
    );
  }
  deleteInvoice(Id: number) {
    return this.httpService.deleteRequestService(
      environmentConstant.deleteInvoice + Id
    );
  }
  UndeleteInvoice(id: number) {
    return this.httpService.putRequestService(
      environmentConstant.undeleteInvoice + id,
      null
    );
  }
  getInvoiceList(
    guid: any,
    isJobInvoice,
    includeDeleted: boolean,
    moduleId: number
  ): Observable<OrderModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getInvoiceList +
        guid +
        "&isJobInvoice=" +
        isJobInvoice +
        "&moduleId=" +
        moduleId +
        "&includeDeleted=" +
        includeDeleted
    );
  }

  getReceivables(
    guid: any,
    isJobInvoice,
    includeDeleted: boolean,
    moduleId: number
  ): Observable<OrderModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getReceivables +
        guid +
        "&isJobInvoice=" +
        isJobInvoice +
        "&moduleId=" +
        moduleId +
        "&includeDeleted=" +
        includeDeleted
    );
  }

  // Gets orders and invoices for a customer
  getInvoiceListByCustomerId(
    orgGuid: any,
    customerGuid: any,
    includeDeleted: boolean,
    startDate?: Date,
    endDate?: Date
  ): Observable<OrderModel[]> {
    var queryString = "";
    if (startDate) {
      queryString += "&startDate=" + this.formatDate(startDate);
    }
    if (endDate) {
      queryString += "&endDate=" + this.formatDate(endDate);
    }

    return this.httpService.getRequestService(
      "invoices/customer?orgGuid=" +
        orgGuid +
        "&customerGuid=" +
        customerGuid +
        "&includeDeleted=" +
        includeDeleted +
        queryString
    );
  }

  getPastDueInvoice(orgGuid, dueDateto: any): Observable<OrderModel> {
    return this.httpService.getRequestService(
      environmentConstant.getPastDue + orgGuid + "&dueDateTo=" + dueDateto
    );
  }
  getPastDueFromDate(dueDateto: any, dueDatefrom: any): Observable<OrderModel> {
    return this.httpService.getRequestService(
      environmentConstant.getPastDue +
        this.sharedService.selectedOrganization.ID +
        "&dueDateTo=" +
        dueDateto +
        "&dueDateFrom=" +
        dueDatefrom
    );
  }
  getInvoicebyDate(
    moduleId: number,
    startDate: any,
    endDate: any,
    includeDeleted: boolean
  ): Observable<OrderModel> {
    return this.httpService.getRequestService(
      environmentConstant.getInvoiceList +
        this.sharedService.selectedOrganization.ID +
        "&moduleId=" +
        moduleId +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate +
        "&includeDeleted=" +
        includeDeleted
    );
  }
  getInvoicebyId(orderId: number): Observable<OrderModel> {
    return this.httpService.getRequestService(
      environmentConstant.getOrderbyId + orderId
    );
  }
  jobDrawSchedules(orderId: number): Observable<OrderModel> {
    return this.httpService.getRequestService(
      environmentConstant.jobDrawSchedule + orderId
    );
  }
  getCardsDetails(custId: number, includeDeleted): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getCardsbyCustId +
        custId +
        "&includeDeleted=" +
        includeDeleted
    );
  }
  getNotesList(orderId, showHidden): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getNotesList + orderId
    );
  }
  saveNote(orderNoteModel: OrderNoteModel) {
    return this.httpService.postRequestService(
      environmentConstant.saveNote,
      orderNoteModel
    );
  }
  deleteInvoiceNote(Id: number) {
    return this.httpService.deleteRequestService(
      environmentConstant.deleteInvoiceNote + Id
    );
  }
  //payment
  getPaymentMethod(): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getPaymentList
    );
  }
  getPaymentData(): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getPaymentData +
        this.sharedService.selectedOrganization.ID
    );
  }
  getPaymentbyDate(startDate: any, endDate: any): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getPaymentbyDate +
        this.sharedService.selectedOrganization.ID +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate
    );
  }
  // saveProcessPayment(paymentModel: ProcessPaymentModel) {
  //     return this.httpService.putRequestService(environmentConstant.paymentProcess, paymentModel);
  // }

  //Gateway Account 12/13/19
  getGatewayAccountListByOrg() {
    return this.httpService.getRequestService(
      environmentConstant.getGatewayAccountByOrg +
        this.sharedService.selectedOrganization.ID
    );
  }

  getGatewayAccountByOrg() {
    return this.httpService.getRequestService(
      environmentConstant.gatewayAccountDefault +
        this.sharedService.selectedOrganization.ID
    );
  }

  postGatwayAccount(gatewayAccount: GatewayAccountModel) {
    return this.httpService.postRequestService(
      environmentConstant.gatewayAccount,
      gatewayAccount
    );
  }

  putGatwayAccount(gatewayAccount: GatewayAccountModel) {
    return this.httpService.putRequestService(
      environmentConstant.gatewayAccount,
      gatewayAccount
    );
  }

  // deleteGatwayAccount(gatewayAccount: GatewayAccountModel) {
  //     return this.httpService.deleteRequestService(environmentConstant.gatewayAccount, gatewayAccount);
  // }

  getTransactionById(id: number) {
    return this.httpService.getRequestService(
      environmentConstant.orderTransactionById + id
    );
  }

  //Processing payment new 12/10/19
  getPaymentsById(tId: number) {
    return this.httpService
      .getRequestService(
        environmentConstant.getPaymentById +
          tId +
          this.sharedService.selectedOrganization.ID
      )
      .pipe(map((res) => res.json()));
  }
  getNewCardResponsebyId(id: number) {
    return this.httpService
      .getRequestService(
        environmentConstant.cardlookupId +
          id +
          this.sharedService.selectedOrganization.ID
      )
      .pipe(map((res) => res.json()));
  }

  //discount
  getDiscountById(discountid: number): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getDiscountbyId + discountid
    );
  }
  addDiscount(discountModel: DiscountModel) {
    return this.httpService.postRequestService(
      environmentConstant.addDiscount,
      discountModel
    );
  }
  updateDiscount(discountModel: DiscountModel) {
    return this.httpService.putRequestService(
      environmentConstant.updateDiscount,
      discountModel
    );
  }
  getDiscountData(catalogid): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getDiscountData +
        catalogid +
        "&OrgGuid=" +
        this.sharedService.selectedOrganization.ID
    );
  }
  deleteDiscount(Id: number) {
    return this.httpService.deleteRequestService(
      environmentConstant.deleteDiscount + Id
    );
  }
  //transaction
  voidTransaction(transId: number) {
    return this.httpService.postRequestService(
      environmentConstant.voidTransaction + transId,
      null
    );
  }
  moveTransaction(transReqModel: TransactionRequestModel) {
    return this.httpService.putRequestService(
      environmentConstant.moveTransactions,
      transReqModel
    );
  }
  refundTransaction(refundReq: RefundRequest) {
    return this.httpService.postRequestService(
      environmentConstant.refundTransaction,
      refundReq
    );
  }
  //split
  splitInvoice(splitModel: SplitInvoiceModel) {
    return this.httpService.putRequestService(
      environmentConstant.splitModel,
      splitModel
    );
  }

  formatDate(date: Date) {
    let month =
      date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let year = date.getFullYear();

    return [year, month, day].join("-");
  }
}
