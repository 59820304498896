import { Injectable, OnInit, ViewChild } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { environmentConstant } from "./environment";
import { SalesCalendarComponent } from "app/pages/sales/salesCalendar/salesCalendar.component";
import { promise } from "protractor";
import { resolve } from "path";
import { BaThemeSpinner } from "app/theme/services";

declare var gapi: any;
@Injectable({
  providedIn: "root",
})
export class AuthService {
  LoggedInUser: any;
  constructor(private _spinner: BaThemeSpinner) {}
  async initClient(callback) {
    var that = this;
    await gapi.load("client", async () => {
      callback();
    });
  }
  loadClient() {
    return gapi.load("client");
  }
  initGapiClient() {
    return gapi.client.init({
      apiKey: environmentConstant.apiKey,
      clientId: environmentConstant.clientId,
      discoveryDocs: [
        "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
      ],
      scope: "https://www.googleapis.com/auth/calendar",
    });
  }
  loadCalendar() {
    return gapi.client.load("calendar", "v3");
  }
  async ProcessLoginRequest() {
    const googleAuth = await gapi.auth2.getAuthInstance();
    if (googleAuth.isSignedIn.get()) {
      return gapi.auth2
        .getAuthInstance()
        .currentUser.get()
        .reloadAuthResponse();
    } else {
      return googleAuth.signIn();
    }
  }

  MakeUserAdmin(calendarId, email, role) {
    return gapi.client.calendar.acl.insert({
      calendarId: calendarId,
      resource: {
        role: role,
        scope: {
          type: "user",
          value: email,
        },
      },
    });
  }
  AddCalendar(summary, discription, timezone) {
    return gapi.client.calendar.calendars.insert({
      resource: {
        summary: summary,
        description: discription,
        timeZone: timezone,
      },
    });
  }
  DeleteCalendar(calId) {
    return gapi.client.calendar.calendars.delete({
      calendarId: calId,
    });
  }
  editCalendar = (calId, summary, discription, timezone, backgroundColor) => {
    return gapi.client.calendar.calendarList.update({
      calendarId: calId,
      colorRgbFormat: true,
      resource: {
        summary: summary,
        description: discription,
        timeZone: timezone,
        backgroundColor: backgroundColor,
        foregroundColor: this.getContrastYIQ(backgroundColor),
      },
    });
  };
  getContrastYIQ(hexcolor) {
    hexcolor = hexcolor.replace("#", "");
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    var yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "#000000" : "#ffffff";
  }
  getCalendarColors() {
    return gapi.client.calendar.colors.get({});
  }
  logOut() {
    return gapi.auth2.getAuthInstance().signOut();
  }

  getCalendar() {
    return gapi.client.calendar.calendarList.list();
  }

  GetEvents(CalendarObj) {
    return gapi.client.calendar.events.list({
      calendarId: CalendarObj.calendarId,
      timeMax: CalendarObj.endDate,
      timeMin: CalendarObj.startDate,
      showHiddenInvitations: true,
      singleEvents: true,
    });
  }

  async insertEvent(appointment: any, calendarID) {
    return gapi.client.calendar.events.insert({
      calendarId: calendarID,
      sendUpdates: "all",
      resource: appointment,
    });
  }

  async updateEvent(appointment: any, eventid, calendarID) {
    return gapi.client.calendar.events.update({
      calendarId: calendarID,
      eventId: eventid,
      sendUpdates: "all",
      resource: appointment,
    });
  }

  getCalendarEvent(calendarId, eventId) {
    return gapi.client.calendar.events.get({
      calendarId: calendarId,
      eventId: eventId,
    });
  }

  DeleteCalendarEvent(calendarId, eventId) {
    return gapi.client.calendar.events.delete({
      calendarId: calendarId,
      eventId: eventId,
      sendUpdates: "all",
    });
  }
}
