import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
} from "@angular/forms";
import { Parameter, WorkAreaSpecs } from "app/model/workAreaSpecsModel";
import { CalculatorsParametersService } from "../services/calculators-parameters.service";
import { MaterialCalculatorService } from "../services/material-calculator.service";
import { Helper } from "./helper";

export class BaseFormula {
  public worksheetWorkAreasForm: FormGroup;
  public isValidFormula: boolean;
  public errorMessage: string = "";
  constructor(
    public calculatorParametersService: CalculatorsParametersService,
    public calculatorService: MaterialCalculatorService
  ) {}

  /**
   * Sets the calculated parameters
   * @param control form control
   * @param BreakException manage exception
   */
  async setCalculatedParams(
    control: AbstractControl,
    BreakException,
    paramsFromWorkAreas?
  ) {
    const calculatedParams = (
      control.get("worksheetWorkAreaParameters") as FormArray
    ).controls.filter((v) => v.value && v.value.calculatorId);

    for (let param of calculatedParams) {
      await this.setParams(param, control, BreakException, paramsFromWorkAreas);
    }
  }

  /**
   * Sets parameters for evaluation
   * @param param calculated param
   * @param control form control
   * @param BreakException manage exception
   */
  async setParams(
    param: AbstractControl,
    control: AbstractControl,
    BreakException,
    paramsFromWorkAreas
  ) {
    const relatedParams = this.calculatorParametersService.getCalculatorParams(
      param.value.calculatorId,
      paramsFromWorkAreas
    );

    const params = relatedParams.map((pa) =>
      (control as FormGroup)
        .getRawValue()
        .worksheetWorkAreaParameters.find((w) => w.parameterId === pa.id)
    );

    await this.evalFormulas(params, param, BreakException);
  }

  /**
   * Evals formulas and throws exceptions if requested
   * @param params parameters for calculations
   * @param param form control
   * @param BreakException manage exception
   */
  async evalFormulas(
    params: any[],
    param: AbstractControl,
    BreakException = null
  ) {
    let formula = param.value.formula;
    params.forEach((formParam) => {
      formula = formula.replace(
        `%${formParam.parameterId}%`,
        `${formParam.value}`
      );
    });
    param.get("value").setErrors(null);
    param.get("isCalculating").setValue(true, { emitEvent: false });

    const data = await this.calculatorService
      .evaluateFormula(formula)
      .toPromise();

    param.get("isCalculating").setValue(false, { emitEvent: false });
    let result = Helper.roundNumber(data.result, param.get("valueType").value);

    const isValidFormula = data.syntaxIsValid && !data.isInfinite;
    if (isValidFormula) {
      param.get("value").setValue(result, { emitEvent: false });
      param.get("value").setErrors(null);
      return;
    }
    this.isValidFormula = false;
    param.get("value").setErrors({ incorrect: true });
    param.get("value").markAllAsTouched();

    const errorMessage = this.setErrorMessage(
      formula,
      param.value.parameterName,
      param as FormGroup
    );

    this.worksheetWorkAreasForm.setErrors({ invalid: true });

    param.get("errorMsg").setValue(errorMessage, { emitEvent: false });

    param.setErrors({ invalid: true });

    if (BreakException) throw BreakException;
  }

  /**
   * Checks if formulas area valid
   * @returns true or false if the formulas are valid
   */
  async checkFormulas(workAreas?: WorkAreaSpecs[]) {
    let BreakException = {};
    try {
      (
        this.worksheetWorkAreasForm.get("worksheetWorkAreas") as FormArray
      ).controls.forEach((x) => {
        (x.get("parameterGroups") as FormArray).controls.forEach(async (y) => {
          let valor = y.value.workAreaId;

          let wokrsheetParametersControls = (
            y.get("worksheetWorkAreaParameters") as FormArray
          ).controls;
          for (let val of wokrsheetParametersControls) {
            let workAreaParams = null;
            if (workAreas) {
              const workAreaToUse = workAreas.find((x) => x.id === valor);
              let parameterGroupId = val.value.parameterGroupId;
              let parameterGroups = workAreaToUse.parameterGroups.find(
                (x) => x.id == parameterGroupId
              );
              workAreaParams = parameterGroups.parameters;
            }

            if (val.value.calculatorId) {
              await this.setParams(val, y, null, workAreaParams);
            } else {
              const errorMessage = this.setGeneralErrMsgs(val as FormGroup);
              val.get("errorMsg").setValue(errorMessage, { emitEvent: false });
            }
          }
        });
      });

      return true;
    } catch (e) {
      if (e !== BreakException) throw e;
      return false;
    }
  }

  /**
   * Sets the error message for calculations
   * @param formula formula to show
   * @param parameterName parameter name to show
   * @returns string with the error message for calculations
   */
  setErrorCalculatorMessage(
    formula: string,
    parameterName: string,
    control: FormGroup
  ): string {
    let finalErrorMsg = "";
    const calculatorError = `- This parameter is being calculated as follows:
            <b style="font-weight:bold">${parameterName} = ${formula}</b>
            Please check the dependent parameters and try again.<br>`;

    if (
      control.controls.value.errors &&
      control.controls.value.errors.incorrect
    ) {
      finalErrorMsg += calculatorError;
    }

    return finalErrorMsg;
  }

  setErrorMessage(
    formula: string,
    parameterName: string,
    control: FormGroup
  ): string {
    let finalErrMsg = "";
    const calcErrMsg = this.setErrorCalculatorMessage(
      formula,
      parameterName,
      control
    );
    const generalErrMsg = this.setGeneralErrMsgs(control);

    finalErrMsg = finalErrMsg + calcErrMsg + generalErrMsg;

    return finalErrMsg;
  }

  setGeneralErrMsgs(control: FormGroup): string {
    let finalErrorMsg = "";

    if (
      control.controls.value.errors &&
      control.controls.value.errors.min &&
      control.controls.value.errors.min.min
    ) {
      const minErr = `- This Field must be equal or greater than
        ${control.controls.value.errors.min.min}.<br>`;
      finalErrorMsg += minErr;
    }

    if (
      control.controls.value.errors &&
      control.controls.value.errors.pattern
    ) {
      const patternErr = `- Field must be valid as ${control.controls.valueType.value}.<br>`;
      finalErrorMsg += patternErr;
    }

    if (
      control.controls.value.errors &&
      control.controls.value.errors.required
    ) {
      const requiredErr = `- This field is required.<br>`;

      finalErrorMsg += requiredErr;
    }

    if (
      control.controls.textValue.errors &&
      control.controls.textValue.errors.required
    ) {
      const requiredErr = `- This field is required.<br>`;
      finalErrorMsg += requiredErr;
    }

    if (
      control.controls.booleanValue.errors &&
      control.controls.booleanValue.errors.required
    ) {
      const requiredErr = `- This field is required.<br>`;
      finalErrorMsg += requiredErr;
    }

    return finalErrorMsg;
  }
}
