import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from "@angular/core";
import { ChatService } from "app/common/services/Chat.service";
import { MessageModel } from "app/model/Chat/MessageModel";
import { Subscription, Observable } from "rxjs";
import { DialogService } from "ng2-bootstrap-modal";
import { ConfirmComponent } from "../dialog/dialog.component";
import { ChatUserModel } from "app/model/Chat/ChatUserModel";

@Component({
  selector: "chat-conversation",
  templateUrl: "./ChatConversation.component.html",
  styles: [
    `
      :host {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
        padding: 0;
        background-color: #e6eaea;
      }

      :host p {
        font-weight: 400 !important;
      }

      :host .container-fluid {
        background-color: white;
        height: 100%;
      }

      :host .chat-messages {
        overflow-y: scroll;
        overflow-x: hidden;
        padding: 0;
        margin: 0;
        height: calc(100% - 40px);
      }

      :host .chat-messages::-webkit-scrollbar {
        width: 8px;
        background: transparent;
      }
      :host .chat-messages::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
      }
      :host .chat-messages ul {
        width: 100%;
      }

      :host .chat-messages ul li {
        display: inline-block;
        clear: both;
        float: left;
        margin: 5px 0 5px 0;
        width: 100%;
        font-size: 13px;
        position: relative;
      }

      :host .chat-messages ul li:hover {
        background-color: #a9bed1;
      }

      :host .chat-messages ul li:nth-last-child(1) {
        margin-bottom: 20px;
      }

      :host .chat-messages ul li.chat-sent {
        float: right;
      }

      :host .chat-messages ul li.chat-sent p.chat-message {
        float: right;
        clear: both;
      }

      :host .chat-messages ul li.chat-sent p.chat-message {
        background: #435f7a;
        color: #f5f5f5;
      }

      :host .chat-messages ul li.chat-received p.chat-message {
        background: #4da755;
        color: #f5f5f5;
      }

      .chat-received {
        padding-top: 5px;
      }
      .chat-sent {
        padding-top: 5px;
      }

      :host .chat-messages ul li p.chat-message {
        display: inline-block;
        padding: 8px 13px;
        border-radius: 20px;
        word-break: break-word;
        line-height: 130%;
        margin: 0px 5px 9px 5px !important;
      }

      :host .chat-messages ul li p.chat-meta span {
        display: block;
      }

      :host .chat-messages ul li.chat-sent p.chat-meta span {
        float: right;
        clear: both;
      }

      p.chat-meta {
        margin-bottom: 0px;
      }
      :host .chat-messages ul li p.chat-meta span.chat-created {
        font-size: 10px;
        margin-left: 5px;
        margin-right: 5px;
      }

      :host .chat-message-input {
        width: 100%;
        height: 40px;
        margin: 0;
      }
      :host .chat-message-input {
        position: relative;
        padding-left: 10px;
      }
      :host .chat-message-input input {
        float: left;
        border: none;
        width: calc(100% - 40px);
        padding: 9px 32px 10px 15px;
        color: #32465a;
        border: 1px solid #32465a;
        border-radius: 5px;
      }
      .chat-message-name {
        font-size: 11px;
        line-height: 10px;
        margin-left: 5px;
        margin-right: 5px;
      }

      :host .chat-message-input input:focus {
        outline: none;
      }

      :host .chat-message-input button {
        border: none;
        width: 40px;
        height: 41px;
        cursor: pointer;
        background: #32465a;
        color: #f5f5f5;
        right: 10px;
        position: absolute;
        border-radius: 0 5px 5px 0;
      }

      :host .chat-message-input button:hover {
        background: #435f7a;
      }

      :host .chat-message-input button:focus {
        outline: none;
      }

      .chat-loading {
        width: 100%;
        text-align: center;
        color: #2c3e50;
        margin-top: 20px;
        font-size: 14px;
      }
      .text-danger {
        font-size: 12px;
      }

      :host .chat-messages ul li.chat-system {
        float: left !important;
      }

      :host li.chat-system p.chat-message {
        background-color: transparent !important;
        float: left !important;
        color: #777777 !important;
      }

      :host .chat-messages ul li:hover .message-options {
        display: block;
      }

      .message-options {
        position: absolute;
        right: 10px;
        top: -15px;
        height: 25px;
        border: 1px solid #435f7a;
        width: auto;
        border-radius: 5px;
        padding: 3px;
        white-space: nowrap;
        background-color: #e6eaea;
        display: none;
        font-size: 11px;
      }

      .message-options p {
        cursor: pointer;
      }

      .chat-messages ul li.chat-sent .message-options {
        left: 10px;
        right: auto;
      }
    `,
  ],
})
export class ChatConversationComponent implements OnInit, OnDestroy {
  @ViewChild("messages", { static: false }) messageContainer: ElementRef;
  @Input() user: ChatUserModel;

  public readonly: Observable<boolean>;
  public items: MessageModel[];
  public isLoading: boolean = true;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private dialogService: DialogService,
    private chatService: ChatService
  ) {}

  public ngOnInit(): void {
    this.readonly = this.chatService.readonly;
    this.items = this.chatService.messages;
    this.setSubscriptions();
  }

  private setSubscriptions(): void {
    this.subscriptions.add(
      this.chatService.onMessage.subscribe(() => {
        this.scrollToBottom();
      })
    );

    this.subscriptions.add(
      this.chatService.onHistory.subscribe(() => {
        this.isLoading = false;
        this.scrollToBottom();
      })
    );
  }

  public onScroll(event: any): void {
    if (event.target.scrollTop === 0) {
      this.chatService.getMessages();
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private scrollToBottom(): void {
    setTimeout(
      () =>
        (this.messageContainer.nativeElement.scrollTop =
          this.messageContainer.nativeElement.scrollHeight + 150)
    );
  }

  public onMessage(input: any) {
    let content = String(input.value);
    input.value = "";
    if (!content) return;
    this.chatService.sendMessage(content);
  }

  public onDeleteMessage(message: MessageModel): void {
    this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Delete",
        message: "Are you sure you want to delete this message?",
        toShow: false,
        isNotification: false,
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          this.chatService.deleteMessage(message);
        }
      });
  }
}
