import { Component, OnInit } from "@angular/core";
import { AuthorizationCodeUrlResponse } from "app/model/SignNowModels";
import { SignNowService } from "../../../common/services/signNow.service";
import { SharedService } from "../../../common/utility/SharedService";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "sign-now-connector",
  templateUrl: "./sign-now-connector.component.html",
  styleUrls: ["./sign-now-connector.component.scss"],
})
export class SignNowConnectorComponent implements OnInit {
  private readonly _orgGuid = this.sharedService.selectedOrganization.ID;
  connected: boolean = false;
  finishedLoading: boolean = false;

  constructor(
    private signNowService: SignNowService,
    private sharedService: SharedService,
    private toastrService: ToastrService
  ) {}

  ngOnInit() {
    this.verifyOrg();
  }

  /** Gets auth code url to connect */
  onConnect() {
    this.signNowService
      .getAuthCodeUrl(this._orgGuid)
      .subscribe((data: AuthorizationCodeUrlResponse) => {
        window.open(data.url);
      });
  }

  /** Verifies if the organization is registered in the db */
  private verifyOrg() {
    this.signNowService.verifyOrg(this._orgGuid).subscribe(
      (response: boolean) => {
        this.connected = response;
        this.finishedLoading = true;
      },
      (error) => this.toastrService.error("An error has ocurred")
    );
  }

  /** Disconnects the SignNow service from the current organization */
  disconnect() {
    this.signNowService.disconnectOrganization(this._orgGuid).subscribe(
      (data) => {
        this.signNowService.disconnectMenuTrigger();
        this.connected = false;
      },
      (error) => this.toastrService.error("An error has ocurred")
    );
  }
}
