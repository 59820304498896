import {
  Injectable,
  OnInit,
  AfterViewInit,
  OnDestroy,
  Input,
  Renderer2,
  NgZone,
  RendererFactory2,
  Output,
} from "@angular/core";
import { Subscription, fromEvent, Subject } from "rxjs";
import { tap, take } from "rxjs/operators";
import { State, process } from "@progress/kendo-data-query";
import { RowClassArgs } from "@progress/kendo-angular-grid";

const tableRow = (node) => node.tagName.toLowerCase() === "tr";

const closest = (node, predicate) => {
  while (node && !predicate(node)) {
    node = node.parentNode;
  }

  return node;
};

@Injectable({
  providedIn: "root",
})
export class KendoGridReorderService implements AfterViewInit, OnDestroy {
  public dragComplete: Subject<boolean> = new Subject<boolean>();

  private renderer: Renderer2;
  public data: any = [];
  public gridData = process(this.data, {});
  public currentSubscription: Subscription;

  constructor(private rendererFactory: RendererFactory2, private zone: NgZone) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public ngAfterViewInit(): void {
    //this.currentSubscription = this.handleDragAndDrop();
  }

  public ngOnDestroy(): void {
    this.currentSubscription.unsubscribe();
  }

  public dataStateChange(state: State): void {
    //this.state = state;
    this.gridData = process(this.data, {});
    if (typeof this.currentSubscription != "undefined")
      this.currentSubscription.unsubscribe();
    /*this.zone.onStable
      .pipe(take(1))
      .subscribe(() => (this.currentSubscription = this.handleDragAndDrop()));*/
  }

  public rowCallback(context: RowClassArgs) {
    if (typeof context.dataItem == "undefined") return null;
    return { dragging: context.dataItem.dragging };
  }

  public handleDragAndDrop(): Subscription {
    const sub = new Subscription(() => {});
    let draggedItemIndex;

    const tableRows = Array.from(
      document.querySelectorAll(".k-grid-content tr")
    );
    tableRows.forEach((item) => {
      this.renderer.setAttribute(item, "draggable", "true");
      const dragStart = fromEvent<DragEvent>(item, "dragstart");
      const dragOver = fromEvent(item, "dragover");
      const dragEnd = fromEvent(item, "dragend");

      sub.add(
        dragStart
          .pipe(
            tap(({ dataTransfer }) => {
              try {
                const dragImgEl = document.createElement("span");
                dragImgEl.setAttribute(
                  "style",
                  "position: absolute; display: block; top: 0; left: 0; width: 0; height: 0;"
                );
                document.body.appendChild(dragImgEl);
                dataTransfer.setDragImage(dragImgEl, 0, 0);
              } catch (err) {
                console.log(err);
                // IE doesn't support setDragImage
              }
              try {
                // Firefox won't drag without setting data
                dataTransfer.setData("application/json", "");
              } catch (err) {
                console.log(err);
                // IE doesn't support MIME types in setData
              }
            })
          )
          .subscribe(({ target }) => {
            const row: HTMLTableRowElement = <HTMLTableRowElement>target;
            draggedItemIndex = row.rowIndex;
            const dataItem = this.gridData.data[draggedItemIndex];
            dataItem.dragging = true;
          })
      );

      sub.add(
        dragOver.subscribe((e: any) => {
          e.preventDefault();
          const dataItem = this.gridData.data.splice(draggedItemIndex, 1)[0];
          const dropIndex = closest(e.target, tableRow).rowIndex;
          const dropItem = this.gridData.data[dropIndex];

          draggedItemIndex = dropIndex;
          this.zone.run(() =>
            this.gridData.data.splice(dropIndex, 0, dataItem)
          );
        })
      );

      sub.add(
        dragEnd.subscribe((e: any) => {
          e.preventDefault();
          const dataItem = this.gridData.data[draggedItemIndex];
          dataItem.dragging = false;

          this.zone.run(() => this.dragComplete.next(dataItem));
        })
      );
    });
    return sub;
  }
}
