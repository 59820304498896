export class OrganizationBasicModel {
  ID: string;
  //Contact_Id: number;
  Address: string;
  State: number;
  Zip: string;
  City: string;
  Phone: string;
  Email: string;
  TimeZoneOffset: number;
  OrganizationName: string;
  WorkingDays: string = "1111110";
  LogoUrl: string;
  OrganizationID: number;
  IsVendor: boolean;
  constructor() {}
}
