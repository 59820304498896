import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { ControlContainer, NgForm } from "@angular/forms";
import { JobTypeEnum, MPBStatusEntity } from "app/common/directives/commonEnum";
import { CategoryService } from "app/common/services/category.service";
import { CommonService } from "app/common/services/common.service";
import { JobService } from "app/common/services/job.service";
import { JobPhaseService } from "app/common/services/jobPhase.service";
import { PhaseService } from "app/common/services/phase.service";
import { RequestForServiceService } from "app/common/services/request-for-service.service";
import { WorkOrderService } from "app/common/services/workOrder.service";
import { environmentConstant } from "app/common/utility/environment";
import {
  CommonSubscription,
  SharedService,
} from "app/common/utility/SharedService";
import { ConfirmComponent } from "app/component/dialog/dialog.component";
import {
  ScheduleErrorDialogComponent,
  SchedulErrorDTO,
} from "app/component/schedule-error-dialog/schedule-error-dialog.component";
import { AddressModel } from "app/model/addressModel";
import { UserRoleEnum } from "app/model/Core/UserRoleEnum";
import { JobResourcesRequestPayload } from "app/model/JobAssignResourceModel";
import {
  JobPhaseChildIds,
  JobPhaseEditResponse,
  JobPhasModel,
} from "app/model/jobPhaseModel";
import { BusinessUnitModel } from "app/model/organizationModel";
import { PhaseModel } from "app/model/phaseModel";
import { Resource } from "app/model/ResourceModel";
import { TradeModel } from "app/model/tradeModel";
import {
  UpdatePhaseScheduleRequestModel,
  UpdateReason,
} from "app/model/UpdatePhaseScheduleRequestModel";
import {
  FULL_PERMISSIONS,
  TaskPermissions,
  TodoPermissionsService,
} from "app/pages/account/todo/todo-permissions.service";
import { BaThemeSpinner } from "app/theme/services";
import { DialogService } from "ng2-bootstrap-modal";
import { MessageService } from "primeng/components/common/messageservice";
import { of, Subject } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  takeWhile,
} from "rxjs/operators";
import { BuildPlanStoreService } from "../../../common/services/build-plan-store.service";
import { JobPhaseStatusEnum } from "../../../common/directives/commonEnum";
import { FilteredSearchModel, SearchFilter } from "app/model/SearchModel";

@Component({
  selector: "jobphase-details",
  templateUrl: "./jobphasedetails.component.html",
  styleUrls: ["./jobphasedetails.component.css"],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class JobPhaseDetailsComponent implements OnInit, OnDestroy {
  jobPhase: JobPhasModel = new JobPhasModel();
  updateReason: UpdatePhaseScheduleRequestModel;
  lstTrade: TradeModel[];
  lstPhase: PhaseModel[];
  orgPhases: PhaseModel[];
  lstActiveJobPhases: JobPhasModel[] = [];
  originalPhaseList: any[] = [];
  lstContractors: any;
  originalContractors: any;
  hasReason: boolean = false;
  disableOrder: boolean = true;
  isActive: boolean;
  businessRuleText: string = "";
  selectedJobPhase: number = 0;
  priorities: any[];
  originalPriorities: any[];
  statusLst: any[];
  originalStatusList: any[];
  isMarked: boolean = false;
  scheduleDate: any;
  showDialog: boolean = false;
  previousScheduleDate: Date;
  previousTargetDate: Date;
  previousActStartDate: Date;
  previousCompletedDate: Date;
  catetoryList: any = [];
  originalCatetoryList: any = [];
  showJobDetails: boolean = false;
  IsEstimatedUpdate: boolean = false;
  scheduleData: any;
  noReason: boolean = false;
  lstOrignalTrade: any;
  tradeID: number = 0;
  showSlides: string = "";
  isNewAssigned: boolean = false;
  previousResources: any = [];
  isCustomPhase: boolean = false;
  IsNew: boolean = false;
  dateType: any;
  jobData: any = new Object();
  lstAssignedResource: any = [];
  isAssignedUpdated: boolean;
  lstBusinessUnit: BusinessUnitModel[] = [];
  lstOrignalBusinessUnit: BusinessUnitModel[] = [];
  previousStartDate: Date;
  EnableStartDateEdit: boolean = false;
  assignResourceModel: JobResourcesRequestPayload;
  // Schedulers will have the ability to start phases.
  isSchedulerRole: boolean;
  OriginalDTC: number = 0;
  PopUpDirty: boolean = false;
  daysCompleteChanged: Subject<string> = new Subject<string>();
  permissions: TaskPermissions = FULL_PERMISSIONS;
  hasParentWorksheetId: boolean = false;
  private alive: boolean = true;
  updateReasons: UpdateReason[] = [];
  showRequestServiceBadge: boolean = false;
  lstJobPhaseResources: Resource;
  filterChanged = new Subject();

  protected searchJobResultsPayload: FilteredSearchModel<SearchFilter> = <
    FilteredSearchModel<SearchFilter>
  >{
    filter: {
      term: "",
    },
    pagerInfo: {
      getCounts: false,
      take: 25,
      skip: 0,
    },
    takeAll: true,
  };

  @Input() JobData: any;
  @Input() JobId: any;
  @Input() PhaseId: number;
  @Input() sideBarClass: any;
  @Input() isSales: boolean;
  @Input() isSlideOut: boolean = false;
  @Output() updateCommonCard: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeSlide: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateJobID: EventEmitter<any> = new EventEmitter<any>();
  @Output() jobSeleted: EventEmitter<any> = new EventEmitter<any>();
  @Output() updatePhaseID: EventEmitter<any> = new EventEmitter<any>();
  @Output() onPhaseChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() incrementSale: EventEmitter<JobPhasModel> =
    new EventEmitter<JobPhasModel>();
  @Output() updateBuildPlan: EventEmitter<any> = new EventEmitter<any>();
  @Output() worksheetId: EventEmitter<number> = new EventEmitter<number>();
  constructor(
    private dialogService: DialogService,
    private phaseService: PhaseService,
    private jobPhaseService: JobPhaseService,
    private _spinner: BaThemeSpinner,
    private notificationsService: MessageService,
    private sharedService: SharedService,
    private commonService: CommonService,
    private categoryService: CategoryService,
    private workOrderService: WorkOrderService,
    private commonsubs: CommonSubscription,
    private jobService: JobService,
    private todoPermissionsService: TodoPermissionsService,
    private bpStoreService: BuildPlanStoreService,
    private requestForService: RequestForServiceService
  ) {}
  ngOnInit() {
    this.loadJobPhaseDetailsData();
  }

  onDaysCompleteChanged(query: number) {
    if (query === null || query === undefined) {
      this.daysCompleteChanged.next(null);
      return;
    }

    var string = query.toString();
    this.daysCompleteChanged.next(string);
  }

  onLoad() {
    this.getPhasesByJobID();
    this.getJobPhaseResources();
    // this.getJobDetails();
    // this.getStatus();
    // this.getPriority();
    // this.getCategoriesByOrgId();
    // this.getJobBuildPlan();
    // this.getBusinessUnit();
  }
  getBusinessUnit() {
    this.commonService
      .getBusinessUnit(this.sharedService.selectedOrganization.ID)
      .subscribe((data) => {
        this.lstBusinessUnit = data;
        this.lstOrignalBusinessUnit = data;
      });
  }
  public filterBussinessUnit(filter: any): void {
    this.lstBusinessUnit = this.lstOrignalBusinessUnit.filter(
      (s) => s.BusinessUnit1.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }
  // setDummyJobPhase() {
  //     this.jobPhase.OrgGUID = this.sharedService.selectedOrganization.ID;
  //     this.jobPhaseService.setJobhaseDummy(this.jobPhase, this.JobId).subscribe((data) => {
  //         this.jobPhase = data;
  //         this.jobPhase.Resources = new Array<Resource>();
  //         this.updatePhaseID.emit(this.jobPhase.ID);
  //         this._spinner.hide();
  //     });
  // }
  getPhasesByJobID() {
    if (this.JobId) {
      this._spinner.show();
      this.jobPhaseService.getJobPhaseByBasic(this.JobId).subscribe((data) => {
        this.getJobDetails();
        if (this.IsNew) {
          this.lstActiveJobPhases = data;
          this.disableOrder = false;
          this.jobPhase.Order = this.lstActiveJobPhases.length + 1;
          this.originalPhaseList = this.lstActiveJobPhases;
        } else {
          this.lstActiveJobPhases = data.filter(
            (list) => list.ID != this.PhaseId
          );
          this.originalPhaseList = this.lstActiveJobPhases;
        }
        if (this.jobPhase.Dependents) {
          if (this.lstActiveJobPhases.length > 0) {
            var dependent = this.jobPhase.Dependents;
            dependent.forEach((element) => {
              this.lstActiveJobPhases = this.lstActiveJobPhases.filter(
                (list) => list.PhaseName != element.PhaseName
              );
            });
          }
        }
      });
    }
  }

  getJobPhaseResources() {
    this.jobPhaseService
      .searchJobPhaseResources(
        this.sharedService.selectedOrganization.ID,
        this.searchJobResultsPayload
      )
      .subscribe(
        (p) =>
          (this.lstJobPhaseResources = (p.object as any).map((o) => {
            return { Id: o.id, Name: o.name, IsExternal: o.isContractor };
          }))
      );
  }

  getPriority() {
    this.priorities = environmentConstant.priority;
    this.originalPriorities = environmentConstant.priority;
    this.getCategoriesByOrgId();
  }
  public filterPriority(filter: any): void {
    this.priorities = this.originalPriorities.filter(
      (s) => s.key.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }
  getStatus() {
    var status = (this.jobPhase.JobTypeId = JobTypeEnum.Construction
      ? MPBStatusEntity.ConstructionPhase
      : MPBStatusEntity.SalesPhase);
    this.jobPhaseService.getStatusId(status).subscribe((data) => {
      this.getPriority();
      this.originalStatusList = data;
    });
  }
  updateStatusLstBasedOnPermissions() {
    if (!this.permissions.complete) {
      this.originalStatusList = this.originalStatusList.filter(
        (status) => status.Value !== "Complete"
      );
    }
    if (!this.permissions.close) {
      this.originalStatusList = this.originalStatusList.filter(
        (status) => status.Value !== "Closed"
      );
    }
    this.statusLst = this.originalStatusList;
  }

  public filterStatus(filter: any): void {
    this.statusLst = this.originalStatusList.filter(
      (s) => s.Status.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }
  getJobBuildPlan() {
    this._spinner.show();
    if (this.IsNew) {
      this.getPhaseByOrgId();
      this.updateStatusLstBasedOnPermissions();
    } else {
      this.jobPhaseService
        .getJobPhaseByIdBasic(this.PhaseId)
        .subscribe((data) => {
          this.showRequestServiceBadge =
            !data.IsComplete && data.Status !== JobPhaseStatusEnum.InProgress;
          this.getBusinessUnit();
          this.permissions = this.todoPermissionsService.getPermissionsForTask(
            this.isSales ? {} : data // sales phases don't care about permissions, so just pass {} so we can stub with full permissions
          );
          this.updateStatusLstBasedOnPermissions();
          this.jobPhase = data;
          this.OriginalDTC = this.jobPhase.DaysToComplete;
          this.jobPhase.UseContractorOrg = !this.jobPhase.UseContractorOrg;
          this.jobPhase.EstStartDate =
            this.jobPhase.EstStartDate === null
              ? null
              : new Date(data.EstStartDate);
          this.jobPhase.EstEndDate =
            this.jobPhase.EstEndDate === null
              ? null
              : new Date(data.EstEndDate);
          this.jobPhase.ActualStartDate =
            this.jobPhase.ActualStartDate === null
              ? null
              : new Date(data.ActualStartDate);
          this.jobPhase.ActualEndDate =
            this.jobPhase.ActualEndDate === null
              ? null
              : new Date(data.ActualEndDate);
          this.jobPhase.ScheduledDate =
            this.jobPhase.ScheduledDate === null
              ? null
              : new Date(data.ScheduledDate);
          const pScheduleDate = this.jobPhase.ScheduledDate;
          this.previousScheduleDate = pScheduleDate;
          const pTrgetDate = this.jobPhase.EstStartDate;
          this.previousTargetDate = pTrgetDate;
          const pActStartDate = this.jobPhase.ActualStartDate;
          this.previousActStartDate = pActStartDate;
          const pCompletedDate = this.jobPhase.ActualEndDate;
          this.previousCompletedDate = pCompletedDate;
          this.tradeID = this.jobPhase.TradeId;
          if (this.jobPhase.Resources) {
            this.lstAssignedResource = JSON.parse(this.jobPhase.Resources);
            this.jobPhase.Resources = JSON.parse(
              JSON.stringify(this.lstAssignedResource)
            );
          } else {
            this.jobPhase.Resources = [];
          }
          this.previousResources = JSON.parse(
            JSON.stringify(this.jobPhase.Resources)
          );

          this.jobData.JobName = data.JobName;
          this.jobData.Phone = data.Phone;
          this.jobData.Address = JSON.parse(data.Address);
          if (this.jobPhase.Dependents) {
            this.jobPhase.Dependents = JSON.parse(this.jobPhase.Dependents);

            // this.jobPhase.Dependents.forEach((element) => {
            //   element.ID = element.Id; //leave this for matching on the multi-select list
            // });
          }
          this.requestForService.setJobPhaseDetails(this.jobPhase);
          this._spinner.hide();
        });
    }
  }

  onIncrementSale() {
    this.incrementSale.emit(this.jobPhase);
  }

  editStartDate(IsEdit) {
    if (IsEdit) {
      this.EnableStartDateEdit = true;
      this.previousActStartDate = this.jobPhase.ActualStartDate;
    } else {
      this.EnableStartDateEdit = false;
      this.jobPhase.ActualStartDate = this.previousActStartDate;
    }
  }
  updateAssignedResource(event) {
    if (event == "cancel") {
      this.showSlides = "";
    } else {
      //this.jobPhase.Resources = [];
      if (event.length > 0) {
        event.forEach((element) => {
          this.jobPhase.Resources = this.jobPhase.Resources || [];
          this.jobPhase.Resources.push(element);
          this.isAssignedUpdated = true;
        });
        this.isNewAssigned = true;
        this.lstAssignedResource = JSON.parse(
          JSON.stringify(this.jobPhase.Resources)
        );
      }
      this.showSlides = "";
      //Job resources handle by assign to, doesn't need to be part of job phase put
      let yFilter = this.previousResources.map((itemY) => {
        return itemY.Id;
      });
      let filteredAssignTo = this.jobPhase.Resources.filter(
        (itemX) => !yFilter.includes(itemX.Id)
      );
      if (filteredAssignTo.length > 0) {
        this.assignResources(filteredAssignTo, this.jobPhase.ID, this.JobId);
      }
    }
  }
  getCategoriesByOrgId() {
    this.categoryService
      .getCategoriesByEntityId(this.sharedService.selectedOrganization.ID, 1)
      .subscribe((data) => {
        this.getJobBuildPlan();
        this.catetoryList = data;
        this.originalCatetoryList = data;
      });
  }
  public filterCategory(filter: any): void {
    this.catetoryList = this.originalCatetoryList.filter(
      (s) => s.CatetoryName.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }

  getJobDetails() {
    let jobType = this.isSales ? 1 : 2;
    this.jobService.getJobById(this.JobId, jobType).subscribe(
      (response) => {
        this.getStatus();
        this.jobData = response;
        this.jobData.JobName = response.JobName + " - " + response.JobNumber;
        this.jobData.Phone = response.Customer.PrimaryPhone;
        this.jobData.Address = response.Customer.Address;
        this.handleWorksheetId(response.WorksheetId);
      },
      (err) => {
        if (err.status === 404) {
          this.notificationsService.add({
            severity: "error",
            summary: "Job Phase",
            detail: "Job does not exist",
          });
        }
      }
    );
  }

  getJobData(event) {
    if (event == "cancel") {
      this.showSlides = "";
    } else {
      this.jobData = {};
      this.jobData.JobName = event.JobName;
      this.jobData.Phone = event.PrimaryPhone;
      this.jobData.Address = new AddressModel();
      this.jobData.Address.Address1 = event.Address;
      this.jobData.Address.City = event.City;
      this.jobData.Address.StateProvinceAbbreviation = event.State;
      this.jobData.Address.ZipPostalCode = event.Zip;
      this.jobData.JobID = event.JobId;
      this.JobData = this.jobData.JobID;
      this.showSlides = "";
      this.jobSeleted.emit(true);
      this.updateJobID.emit(this.jobData.JobID);
      this.JobId = event.JobId;
      //this.setDummyJobPhase();
      //this.getPhasesByJobID();
    }
  }
  getAddressString(address) {
    let addressString: string = "";

    if (address && address.Address1 && address.Address1 !== "") {
      addressString += address.Address1;
    }
    if (address && address.City && address.City !== "") {
      addressString += ", ";
      addressString += address.City;
    }
    if (
      address &&
      address.StateProvinceAbbreviation &&
      address.StateProvinceAbbreviation !== ""
    ) {
      addressString += ", ";
      addressString += address.StateProvinceAbbreviation;
    }
    if (address && address.ZipPostalCode && address.ZipPostalCode !== "") {
      addressString += ", ";
      addressString += address.ZipPostalCode;
    }
    if (addressString) {
      return addressString;
    } else {
      return null;
    }
  }
  //mark the phase as completed
  markAsComplete(status) {
    if (status === 2) {
      this.isMarked = true;
      this.jobPhase.Status = status;
      if (!this.jobPhase.ActualStartDate) {
        this.jobPhase.ActualStartDate = new Date();
      }
    } else {
      this.isMarked = true;
      this.jobPhase.Status = 3;
      if (this.jobPhase.ActualStartDate) {
        this.jobPhase.ActualEndDate = new Date();
      } else {
        this.jobPhase.ActualStartDate = new Date();
        this.jobPhase.ActualEndDate = new Date();
      }
    }
    this.commonsubs.updateAssignedResource(true);
  }
  deleteAssignedResource(assignedId, assigned) {
    if (!this.permissions.editAssignedEmployee) {
      return;
    }
    var assignedTo = assigned
      ? assigned
      : this.jobPhase.Resources.find((obj) => obj.Id == assignedId);
    var index = this.jobPhase.Resources.findIndex(
      (obj) => obj.Id == assignedId
    );

    const disposable = this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Delete Assigned Resource",
        // tslint:disable-next-line:prefer-template
        message:
          "Are you sure you want to delete assigned resource" +
          " " +
          assignedTo.Name +
          "?",
        toShow: false,
        isNotification: false,
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this._spinner.show();
          this.workOrderService
            .deleteAssignedResources(
              assignedId,
              this.JobId,
              this.PhaseId,
              assignedTo.IsExternal
            )
            .subscribe(
              (result) => {
                this.result(
                  result,
                  "Deleted Successfully!!",
                  "Assign Resource"
                );
                if (!assigned) {
                  this.jobPhase.Resources.splice(index, 1);
                }
                this.lstAssignedResource = JSON.parse(
                  JSON.stringify(this.jobPhase.Resources)
                );
                this._spinner.hide();
              },
              (error) => this.error(error, "Unable to Delete assigned resource")
            );
          this._spinner.hide();
        }
      });
  }

  //CHANGING STATUS ALSO CHANGED THE DATES
  changePhaseStatus() {
    if (this.jobPhase.Status === 2) {
      if (!this.jobPhase.ActualStartDate) {
        this.jobPhase.ActualEndDate = null;
        this.jobPhase.ActualStartDate = new Date();
      } else {
        this.jobPhase.ActualEndDate = null;
      }
    } else if (this.jobPhase.Status === 3 && !this.jobPhase.ActualEndDate) {
      if (!this.jobPhase.ActualStartDate)
        this.jobPhase.ActualStartDate = new Date();
      this.jobPhase.ActualEndDate = new Date();
    } else {
      this.jobPhase.ActualStartDate = null;
      this.jobPhase.ActualEndDate = null;
    }
  }

  changeCompletedOn(days) {
    if (!days) return;
    //if (!this.PopUpDirty) {
    if (this.jobPhase.DaysToComplete != this.OriginalDTC) {
      this.dateType = "daystocomplete";

      if (
        this.jobPhase.ScheduledDate ||
        this.jobPhase.ActualStartDate ||
        this.jobPhase.EstStartDate
      ) {
        this.showDialog = true;
        this.PopUpDirty = true;
      }
    }
    //}
  }

  //WHEN CHANGING COMPLETED DATE
  changeStatus(actStartDate) {
    if (!actStartDate) return;

    if (this.PhaseId != 0) {
      if (this.previousCompletedDate) this.noReason = true;
      else this.noReason = false;
    }

    this.dateType = "completed";

    if (actStartDate) {
      this.jobPhase.Status = 3;
    } else {
      this.jobPhase.Status = 2;
    }
    if (!this.jobPhase.ActualStartDate) {
      this.jobPhase.ActualStartDate = new Date();
    }
  }

  public filterDependents(filter: any): void {
    this.lstActiveJobPhases = this.originalPhaseList.filter(
      (s) => s.PhaseName.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }

  public filterPhaseResources(filter: any): void {
    this.searchJobResultsPayload.filter.term = filter;
    this.filterChanged.next();
  }

  public filterTrade(filter: any): void {
    this.lstTrade = this.lstOrignalTrade.filter(
      (s) => s.TradeName.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }
  setScheduleDate(scheduleDate) {
    if (!scheduleDate) return;

    this.dateType = "schedule";
    if (this.PhaseId != 0) {
      if (this.previousScheduleDate) this.noReason = true;
      else this.noReason = false;
    }
    if (this.jobPhase.Status == 1) {
      this.showDialog = true;
      this.PopUpDirty = true;
    } else {
      const oldDateFormatted = this.previousScheduleDate
        ? new Date(this.previousScheduleDate).toISOString().split("T")[0]
        : "N/A";
      const newDateFormatted = new Date(scheduleDate)
        .toISOString()
        .split("T")[0];
      let scheduleUpdate = {
        reasonId: -1,
        DependentAction: 4,
        Comments: `Phase scheduled date has changed from "${oldDateFormatted}" to "${newDateFormatted}" and the status at the time is ${this.jobPhase.Status}.`,
      };
      this.scheduleReason(scheduleUpdate);
    }
  }
  setTargetDate(targetDate) {
    if (!targetDate) return;
    // if (!this.PopUpDirty) {
    this.dateType = "target";

    // if (this.PhaseId != 0) {
    //     if (this.previousTargetDate)
    //         this.noReason = true;
    //     else

    // }
    this.noReason = false;
    this.showDialog = true;
    this.PopUpDirty = true;
    // }
  }
  closeSchedule(status) {
    this.showDialog = false;
  }
  scheduleReason(data) {
    this.scheduleData = data;
    this.addReasons(data);
  }

  addReasons(data: any): void {
    let isDuplicateDateType: boolean =
      this.updateReasons.find((x) => x.DateType === this.dateType) !==
      undefined;
    if (isDuplicateDateType) {
      this.updateReasons.find(
        (x) => x.DateType === this.dateType
      ).UpdateReasons = data;
    } else {
      switch (this.dateType) {
        case "target":
          this.updateReasons.push({
            DateType: this.dateType,
            UpdateReasons: data,
            StartDate: this.jobPhase.EstStartDate,
            UpdateType: 1,
          });
          break;
        case "schedule":
          this.updateReasons.push({
            DateType: this.dateType,
            UpdateReasons: data,
            StartDate: this.jobPhase.ScheduledDate,
            UpdateType: 2,
          });
          break;
        default:
          console.error("unsupported date type: " + this.dateType);
      }
    }
  }

  updateScheduleReason(status, data) {
    this.updateReason = new UpdatePhaseScheduleRequestModel();
    switch (this.dateType) {
      case "target":
        this.updateReason.StartDate = this.jobPhase.EstStartDate;
        this.updateReason.UpdateType = 1;
        break;
      case "schedule":
        this.updateReason.StartDate = this.jobPhase.ScheduledDate;
        this.updateReason.UpdateType = 2;
        break;
      // case 'completed':
      //     this.updateReason.StartDate = this.jobPhase.ActualEndDate;
      //     this.updateReason.UpdateType = 3;
      //     break;
      case "daystocomplete":
        this.updateReason.DaysToComplete = this.jobPhase.DaysToComplete;
        if (this.jobPhase.ScheduledDate) {
          this.updateReason.StartDate = this.jobPhase.ScheduledDate;
          this.updateReason.UpdateType = 2;
        } else if (this.jobPhase.EstStartDate) {
          this.updateReason.StartDate = this.jobPhase.EstStartDate;
          this.updateReason.UpdateType = 1;
        } else this.updateReason.UpdateType = null;
        break;
      default:
        break;
    }
    this.updateReason.PhaseId = this.jobPhase.ID;
    this.updateReason.PhaseChangeReason = data.reasonId;
    this.updateReason.DependentAction = data.DependentAction;
    this.updateReason.Comments = data.Comments;
    this.updateReason.HasMoreReasons = this.updateReasons.length > 1;
    this.updateReason.Reasons = this.updateReasons;
    this.updateReason.sendNotification = false;
    if (!this.updateReason.StartDate) return;
    return new Promise((resolve, reject) => {
      //    setTimeout(() => {
      this._spinner.show();
      //     }, 200);
      this.jobPhaseService
        .updateEstimatedDate(this.updateReason)
        .pipe(catchError((error) => of(reject(error))))
        .subscribe((result) => {
          this.bpStoreService.handleUpdateDependentDate(result.JobPhases);
          resolve(result);
        })
        .add(() => this._spinner.hide());
    });
  }

  setActualStartDate(actualStartDate) {
    //this.hasReason = false;

    if (this.PhaseId != 0) {
      if (this.previousActStartDate) this.noReason = true;
      else this.noReason = false;

      this.IsEstimatedUpdate = false;
    }

    this.showDialog = this.noReason;
    if (
      this.jobPhase.ActualStartDate &&
      this.jobPhase.ActualEndDate &&
      this.jobPhase.ActualStartDate <= this.jobPhase.ActualEndDate
    ) {
      const pActStartDate = this.jobPhase.ActualStartDate;
      this.previousActStartDate = pActStartDate;
    }
    if (this.jobPhase.ActualStartDate && this.jobPhase.ActualEndDate) {
      this.jobPhase.Status = 3;
    } else if (this.jobPhase.ActualStartDate && !this.jobPhase.ActualEndDate) {
      this.jobPhase.Status = 2;
    } else {
      this.jobPhase.Status = 1;
    }
    if (!actualStartDate) {
      this.jobPhase.ActualEndDate = null;
    }
  }
  onChangePhase(e) {
    const data = this.lstPhase.find((x) => x.ID === e);
    if (data && data.UseContractorOrg) {
      this.jobPhase.SubContractorId = data.ContractorId;
      this.lstContractors = null;
      this.jobPhase.TradeId = data.TradeId;
      this.jobPhase.TradeName = data.TradeName;
      this.jobPhase.UseContractorOrg = !data.UseContractorOrg;
    } else {
      this.jobPhase.SubContractorId = data ? data.ContractorId : null;
      this.jobPhase.UseContractorOrg = data ? !data.UseContractorOrg : null;
      this.jobPhase.TradeId = null;
      this.tradeID = null;
      this.jobPhase.TradeName = null;
      this.lstContractors = null;
      this.originalContractors = null;
    }
    this.jobPhase.DaysToComplete = data ? data.DaysToComplete : 0;
    this.jobPhase.PhaseName = data ? data.PhaseName : "";
    this.onPhaseChange.emit(e);
  }
  onChangeCheckBox(e) {
    const data = this.lstPhase.find((x) => x.ID === e);
    if (data && this.jobPhase.UseContractorOrg) {
      this.jobPhase.SubContractorId = data.ContractorId;
      this.lstContractors = null;
      this.jobPhase.TradeId = data.TradeId;
      this.jobPhase.TradeName = data.TradeName;
      this.jobPhase.UseContractorOrg = !data.UseContractorOrg;
    } else {
      this.jobPhase.SubContractorId = null;
      this.jobPhase.TradeId = null;
      this.tradeID = null;
      this.jobPhase.TradeName = null;
      this.lstContractors = null;
      this.originalContractors = null;
    }
    this.jobPhase.DaysToComplete = data.DaysToComplete;
    this.jobPhase.PhaseName = data.PhaseName;
  }

  getPhaseByOrgId() {
    this.phaseService
      .getAllPhasesByOrgId(this.sharedService.selectedOrganization.ID)
      .subscribe((data) => {
        this.lstPhase = data;
        this.orgPhases = data;
        this._spinner.hide();
      });
  }
  public filterPhases(filter: any): void {
    this.lstPhase = this.orgPhases.filter(
      (s) => s.PhaseName.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }

  onCancel() {
    this.closeSlide.emit(true);
  }
  getJobPhaseOrder(businessRule, selectedJP) {
    const orderNO = this.lstActiveJobPhases.find(
      (x) => x.ID === selectedJP
    ).Order;
    if (businessRule === "After") {
      this.jobPhase.Order = orderNO + 1;
    } else {
      if (this.lstActiveJobPhases.length === 1) {
        this.jobPhase.Order = 1;
      } else {
        this.jobPhase.Order = orderNO - 1;
      }
    }
  }
  ProcessRequest(
    form,
    childIds?: JobPhaseChildIds,
    shouldClose: boolean = true
  ) {
    if (this.jobPhase.Resources == undefined) this.jobPhase.Resources = [];
    this.jobPhase.UseContractorOrg = !this.jobPhase.UseContractorOrg;
    this.jobPhase.ResourcesDetails = [...this.jobPhase.Resources];
    if (this.PhaseId === 0) {
      this.jobPhase.ChildIds = childIds;
      this.jobPhase.OrgGUID = this.sharedService.selectedOrganization.ID;

      this.jobPhase.JobTypeId = 2;
      if (!this.jobPhase.TradeId) {
        this.jobPhase.TradeId = this.tradeID > 0 ? this.tradeID : null;
      }
      if (!this.jobPhase.OriginalPhaseId) this.jobPhase.OriginalPhaseId = 0;
      this.bpStoreService.createJobPhase(
        this.jobPhase,
        this.JobId,
        shouldClose
      );
    } else {
      if (!this.jobPhase.TradeId) {
        this.jobPhase.TradeId = this.tradeID > 0 ? this.tradeID : null;
      }
      this.jobPhase.Resources = [];
      let reload: boolean = false;
      if (!this.scheduleData) reload = true;
      this.bpStoreService.editJobPhase(this.jobPhase, reload, shouldClose);
    }
  }

  /**
   * Handles success phase edit request
   * @param result Response from edit endpoint
   */
  private async onEditSuccess(result: JobPhaseEditResponse) {
    if (this.scheduleData) {
      if (this.scheduleData.updateDependents) {
        await this.updateScheduleReason(true, this.scheduleData);
      } else {
        await this.updateScheduleReason(false, this.scheduleData);
      }
    }
    let that = this;
    that.result(result, "Updated Successfully!!");
    this.updateBuildPlan.emit(result.ShouldClose);
  }

  /**
   * Handles success phase creation request
   * @param result Response from endpoint
   */
  private onCreateSuccess(result: JobPhaseEditResponse) {
    let that = this;
    //that.updateCountService.update();
    that.result(result, "Added Successfully!!");
    this.updateBuildPlan.emit(result.ShouldClose);
  }

  onPhaseResourcesChange(event) {
    const addedResources = event.filter(
      (element: any) =>
        !this.previousResources.some((prev) => prev.Id === element.Id)
    );
    const removedResources = this.previousResources.filter(
      (prev: any) => !event.some((element) => element.Id === prev.Id)
    );
    if (!!addedResources && addedResources.length > 0) {
      this.assignResources(addedResources, this.PhaseId, this.JobId);
    }
    if (!!removedResources && removedResources.length > 0) {
      this.deleteAssignedResource(removedResources[0].Id, removedResources[0]);
    }

    this.previousResources = [...event];
  }

  assignResources(newResources: Resource[], jobPhaseId: number, jobId: string) {
    this.assignResourceModel = new JobResourcesRequestPayload();
    this.assignResourceModel.Resources = newResources;
    this.assignResourceModel.JobPhaseId = jobPhaseId;
    this.assignResourceModel.JobID = jobId;

    this.assignResourceModel.Resources.forEach((r) => {
      r.ResourceId = r.Id;
    });

    if (jobPhaseId == undefined) return;
    this.workOrderService
      .saveAssignedResource(this.assignResourceModel)
      .subscribe((result) => {});
  }
  onSubmit(form, childIds?: JobPhaseChildIds, shouldClose: boolean = true) {
    if (form.valid) {
      this.isNewAssigned = false;

      if (this.jobPhase.ActualStartDate) {
        this.jobPhase.ActualStartDate = new Date(this.jobPhase.ActualStartDate);
        this.jobPhase.ActualStartDate = new Date(
          this.jobPhase.ActualStartDate.getFullYear(),
          this.jobPhase.ActualStartDate.getMonth(),
          this.jobPhase.ActualStartDate.getDate()
        );
      }
      if (this.jobPhase.ActualEndDate) {
        this.jobPhase.ActualEndDate = new Date(this.jobPhase.ActualEndDate);
        this.jobPhase.ActualEndDate = new Date(
          this.jobPhase.ActualEndDate.getFullYear(),
          this.jobPhase.ActualEndDate.getMonth(),
          this.jobPhase.ActualEndDate.getDate()
        );
      }
      if (this.jobPhase.EstStartDate) {
        this.jobPhase.EstStartDate = new Date(this.jobPhase.EstStartDate);
        this.jobPhase.EstStartDate = new Date(
          this.jobPhase.EstStartDate.getFullYear(),
          this.jobPhase.EstStartDate.getMonth(),
          this.jobPhase.EstStartDate.getDate()
        );
      }
      if (this.jobPhase.EstEndDate) {
        this.jobPhase.EstEndDate = new Date(this.jobPhase.EstEndDate);
        this.jobPhase.EstEndDate = new Date(
          this.jobPhase.EstEndDate.getFullYear(),
          this.jobPhase.EstEndDate.getMonth(),
          this.jobPhase.EstEndDate.getDate()
        );
      }
      if (this.jobPhase.ScheduledDate) {
        this.jobPhase.ScheduledDate = new Date(this.jobPhase.ScheduledDate);
        this.jobPhase.ScheduledDate = new Date(
          this.jobPhase.ScheduledDate.getFullYear(),
          this.jobPhase.ScheduledDate.getMonth(),
          this.jobPhase.ScheduledDate.getDate()
        );
      }
      if (
        this.jobPhase.ActualEndDate &&
        this.jobPhase.ActualEndDate < this.jobPhase.ActualStartDate
      ) {
        var data = new SchedulErrorDTO();
        data.EndDate = this.jobPhase.ActualEndDate;
        data.StartDate = this.jobPhase.ActualStartDate;
        this.dialogService
          .addDialog(ScheduleErrorDialogComponent, { Scm: data })
          .subscribe((datum) => {
            if (datum) {
              this.jobPhase.ActualStartDate = datum.StartDate;
              this.jobPhase.ActualEndDate = datum.EndDate;
              this.ProcessRequest(form, childIds, shouldClose);
            }
          });
      } else {
        this.ProcessRequest(form, childIds, shouldClose);
      }
    }
  }
  private error(
    error: any,
    message: string = "Job Phase Name already exists!!"
  ) {
    switch (error.status) {
      case 409:
        this.notificationsService.add({
          severity: "warn",
          summary: "Job Phase",
          detail: message,
        });
        break;
      default:
        break;
    }
  }
  private result(res: any, message: string, title: string = "Job Phase") {
    if (res !== null) {
      this.notificationsService.add({
        severity: "success",
        summary: title,
        detail: message,
      });
      this._spinner.hide();
      if (title == "Job Phase") {
        this.closeSlide.emit(true);
      }
    }
  }
  private dateError(type) {
    this.notificationsService.add({
      severity: "warn",
      summary: type + " Date",
      detail: "Start Date should not be gretaer than End Date",
    });
  }
  getInitals(str) {
    if (!str) return "";
    var result = str
      .split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), "");
    if (result && result.length > 2) {
      return result.slice(0, 2);
    }
    return result;
  }

  checkIsReady(checked) {
    this.jobPhase.IsReady = checked;
  }

  ngOnDestroy() {
    this.alive = false;
    this.closeSlide.emit(true);
    if (this.isNewAssigned) {
      this.onCancel();
    }
  }

  /** Logic to emit the parent's worksheet id */
  private handleWorksheetId(parentId: number) {
    this.worksheetId.emit(parentId || 0);
  }

  openJobPhaseScheduling() {
    this.showSlides = "schedulenow";
  }

  hideJobPhaseScheduling() {
    this.showSlides = "";
  }

  loadJobPhaseDetailsData() {
    this._spinner.show();
    this.IsNew = false;
    this.daysCompleteChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((model) => {
        this.changeCompletedOn(model);
      });

    this.jobData = {};
    this.PopUpDirty = false;
    this.jobData.Address = {};
    if (this.PhaseId === 0) {
      this.IsNew = true;
      // if (this.JobId)
      //     this.setDummyJobPhase();
    }
    this.onLoad();
    if (!this.sideBarClass || this.sideBarClass == "") {
      this.sideBarClass = "sidebar2";
    }

    // Look at the users's roles to check for scheduler.
    let viewer = this.sharedService.user;
    this.isSchedulerRole =
      viewer.roles.find(
        (x) =>
          x == UserRoleEnum.Scheduler ||
          x == UserRoleEnum.Admin ||
          x == UserRoleEnum.SuperAdmin ||
          x == UserRoleEnum.SalesManager ||
          x == UserRoleEnum.SalesRep
      ) != undefined;
    this.bpStoreService.afterEdit
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => {
        if (data !== null) {
          this.PhaseId ? this.onEditSuccess(data) : this.onCreateSuccess(data);
          this.bpStoreService.resetAfterEdit();
          this.PhaseId = 0;
        }
      });

    this.requestForService.hasBeenUpdated
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => {
        if (data) {
          this.getJobBuildPlan();
        }
      });
    this.filterChanged
      .pipe(
        debounceTime(700),
        takeWhile(() => this.alive)
      )
      .subscribe(() => {
        this.getJobPhaseResources();
      });
  }
}
