import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { WorkOrderModelV2 } from "app/model/WorkOrderV2Model";
import { CustomerModel } from "app/model/customerModel";
import { WorkOrderService } from "app/common/services/workOrder.service";
import { CustomerInfo } from "app/model/CustomerInfoModel";
import { mergeMap, map, toArray } from "rxjs/operators";
import { from } from "rxjs";

@Component({
  selector: "workOrder-grid",
  templateUrl: "./WorkOrderGrid.component.html",
  styleUrls: ["./WorkOrderGrid.component.scss"],
})
export class WorkOrderGridComponent implements OnInit {
  @Input() customer: CustomerModel;
  @Output() closed = new EventEmitter<boolean>();

  public selectedDateRange = { start: null, end: null };

  workOrderList: WorkOrderModelV2[];
  statusColorMap = [
    { statusValue: 2, color: "orange" },
    { statusValue: 22, color: "orange" },
    { statusValue: 15, color: "orange" },
    { statusValue: 3, color: "green" },
    { statusValue: 23, color: "green" },
    { statusValue: 16, color: "green" },
  ];

  constructor(private workOrderService: WorkOrderService) {}

  ngOnInit() {
    this.getWorkOrders();
  }

  getWorkOrders() {
    this.workOrderService
      .getWorkOrderListByCustomerId(
        this.customer.CustomerGuid,
        25,
        this.selectedDateRange.start,
        this.selectedDateRange.end
      )
      .pipe(
        mergeMap((response) => {
          return from(response).pipe(
            map((x) => {
              if (x.StatusId == 3)
                  x.StartDate = x.CompleteDate ? new Date(x.CompleteDate) : null;
              else if (x.StatusId == 2)
                x.StartDate = x.StartDate ? new Date(x.StartDate) : null;
              else
                x.StartDate = x.ScheduledDate ? new Date(x.ScheduledDate) : null;
              return x;
            })
          );
        }),
        toArray()
      )
      .subscribe((x) => {
        let sortedWorkOrderList = x.sort((a, b) => a["Aging"] - b["Aging"]);
        this.workOrderList = sortedWorkOrderList;
      });
  }

  getUserListForWorkOrder(workOrderListIndex): CustomerInfo[] {
    let userList = this.workOrderList[workOrderListIndex].Resources.map((x) => {
      let user = new CustomerInfo();

      let resourceNames = x["Name"].split(/\s/);
      user.FirstName = resourceNames[0];
      user.LastName = resourceNames[1];
      user.isExternal = x["IsExternal"];
      return user;
    });

    return userList;
  }

  getCombinedResourceNames(resourceList): string {
    let namesList = resourceList.map((x) => x.Name);
    return namesList.join(", ");
  }

  getStatusDescription(statusValue): string {
    let statusText = "";

    if (statusValue == 21 || statusValue == 1) {
      statusText = "Not Started";
    } else if (statusValue == 22 || statusValue == 2) {
      statusText = "In Progress";
    } else {
      statusText = "Complete";
    }

    return statusText;
  }

  // ***************************************************************
  // Events
  // ***************************************************************
  dateRangeSelected(e) {
    console.log(this.selectedDateRange.start);
    this.getWorkOrders();
  }

  reloadGrid(e) {
    e.preventDefault();
    this.getWorkOrders();
  }

  viewDetail(itemGuid) {
    window.open("./#/contractor/workorder/" + itemGuid);
  }

  onCancel() {
    this.closed.emit(true);
  }
}

class GridDataItemProperty {
  private _displayName: string;
  value: any;
  translator: any;

  get displayName(): string {
    if (this.translator !== null) {
      return this.translator(this.value);
    } else {
      return this._displayName;
    }
  }
}
