import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { TextBoxModule } from "@progress/kendo-angular-inputs";
import { PhotoCarouselComponent } from "./photo-carousel.component";
import { ScrollViewModule } from "@progress/kendo-angular-scrollview";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DropDownsModule,
    TextBoxModule,
    ScrollViewModule,
  ],
  declarations: [PhotoCarouselComponent],
  exports: [PhotoCarouselComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PhotoCarouselModule {}
