import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";
import { SharedService } from "app/common/utility/SharedService";
import { environmentConstant } from "app/common/utility/environment";

import { Observable } from "rxjs";

import { ApiResponseModel as Api } from "app/model/ApiResponseModel";
import { ShippingMethodModel } from "app/model/Core/ShippingMethodModel";
import { ShippingModel } from "app/model/Core/ShippingModel";

@Injectable()
export class ShippingService {
  private orgGuid: string;

  constructor(
    private sharedService: SharedService,
    private httpService: HttpService
  ) {
    this.orgGuid = this.sharedService.selectedOrganization.ID;
  }

  getShippingMethods(): Observable<Api<ShippingModel[]>> {
    return this.httpService.get<ShippingModel[]>(
      environmentConstant.coreApi.ShippingMethods()
    );
  }

  getOrgShippingOptions(orgGuid: string): Observable<Api<ShippingModel[]>> {
    return this.httpService.get<ShippingModel[]>(
      environmentConstant.coreApi.getShippingOptions(orgGuid)
    );
  }

  editShippingOptions(
    shippingmethod: ShippingModel
  ): Observable<Api<ShippingModel[]>> {
    return this.httpService.put<ShippingModel[]>(
      environmentConstant.coreApi.putShippingOptions(shippingmethod.guid),
      shippingmethod
    );
  }
}
