import { Component, ChangeDetectorRef, OnInit } from "@angular/core";
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { CustomerRoleModel } from "app/model/customerRoleModel";
import { JobFileService } from "app/common/services/jobFile.service";
import { BaThemeSpinner } from "app/theme/services";
import { JobService } from "app/common/services/job.service";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { Helper } from "app/common/utility/helper";

import { isEmail } from "validator";

export interface ShareJobFileDialog {
  jobFileId: number;
  jobId: string;
  changeOrderFileId?: number;
}

@Component({
  selector: "app-share-jobfile-dialog",
  templateUrl: "./share-jobfile-dialog.component.html",
  styleUrls: ["./share-jobfile-dialog.component.scss"],
})
export class ShareJobfileDialogComponent
  extends DialogComponent<ShareJobFileDialog, boolean>
  implements ShareJobFileDialog, OnInit {
  jobFileId: number;
  jobId: string;
  allRoles: CustomerRoleModel[];
  filteredRoles: CustomerRoleModel[];
  selectedRoles: CustomerRoleModel[] = [];
  emailAddresses: string[] = [];
  phoneNumbers: string[] = [];
  changeOrderFileId: number;

  constructor(
    dialogService: DialogService,
    private spinner: BaThemeSpinner,
    private jobService: JobService,
    private jobFileService: JobFileService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(dialogService);
    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    }, 300);
  }

  ngOnInit() {
    this.jobService
      .getContactRoles(this.jobId)
      .subscribe((roles: CustomerRoleModel[]) => {
        this.allRoles = this.filteredRoles = roles;
        this.changeDetectorRef.detectChanges();
      });
  }

  share() {
    this.spinner.show();
    this.jobFileService
      .shareJobFile({
        JobFileId: this.jobFileId,
        CustomerRoleIds: this.selectedRoles.map((r) => r.Id),
        Emails: this.emailAddresses,
        PhoneNumbers: this.phoneNumbers,
        JobChangeOrderFileId: this.changeOrderFileId,
      })
      .subscribe(() => {
        this.spinner.hide();
        // indicate that sharing was successful
        this.result = true;
        this.close();
      });
  }

  public filterChange(filter: any): void {
    this.filteredRoles = this.allRoles.filter(
      (s) => s.Name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }

  public emailNormalizer = (text: Observable<string>): any =>
    text.pipe(
      map((input) => {
        return isEmail(input) ? input : undefined;
      })
    );

  public phoneNumberNormalizer = (text: Observable<string>): any =>
    text.pipe(
      map((input) => {
        return Helper.formatPhone(input);
      })
    );
}
