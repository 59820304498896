export class MessegeService {
  body: string;
  subject: string;
  CustomerId: number;
  constructor() {}
  reset() {
    this.body = "";
    this.subject = "";
  }
}
