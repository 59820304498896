<div
  class="estimate-theme mat-dialog catalog-picker__modal catalog-picker__modal--product"
>
  <h1 class="estimate-typography-pop-up-titles" matDialogTitle>{{ Title }}</h1>
  <h3 class="mb-2 product-name-title">{{ product.name }}</h3>
  <p
    *ngIf="
      product.productEstimateExt !== null &&
      !product.productEstimateExt.materialsCalculatorId
    "
  >
    Item/Labor/Service quantities can only be positive. Decimals can be allowed
    or not depending on the Value Type of the Unit of Measure. This can be
    configured in the Units of Measure module.
  </p>
  <mat-dialog-content>
    <form [formGroup]="productForm">
      <table class="table-estimateapp table-estimateapp__package-modal">
        <thead>
          <tr>
            <th class="align-right width__4">Qty</th>
            <th class="align-right width__2 sku nowrap">SKU #</th>
            <th class="width__6 product-name nowrap">Product Name</th>
            <th
              class="catalog-picker__modal-cell--align-right width__2 align-right price"
            >
              Price
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            [ngClass]="{
              'catalog-picker__has-product-options': false
            }"
          >
            <td class="width__4 align-right input-product-selection">
              <input
                type="number"
                decimalFormat
                [decimals]="product.unitOfMeasure.valueType"
                [negative]="false"
                [maxSize]="6"
                formControlName="qtyControl"
                class="width__3 align-right input-number"
                *ngIf="!useCalculator"
              />
              <i
                class="fa svg-icon__function"
                *ngIf="!isEdit && useCalculator"
              ></i>
              <span *ngIf="isEdit && useCalculator">{{ this.productQty }}</span>
            </td>
            <td class="width__2 align-right">
              {{ product.sku }}
            </td>
            <td class="width__6">
              {{ product.name }}
            </td>
            <td
              class="catalog-picker__modal-cell--align-right width__2 align-right"
            >
              {{ price | currency : "USD" : "symbol" : "1.2-2" }}
            </td>
          </tr>
          <!-- Product Options hidden -->
          <!-- <tr
            class="catalog-picker__product-options"
            *ngIf="hasProductOptions && !hideOptions"
          >
            <td></td>
            <td></td>
            <td>
              <div class="dropdown-pentair-std">
                <p-dropdown
                  [options]="productOptionsSelectItems"
                  [autoWidth]="false"
                  formControlName="productOptionsControl"
                  appendTo="body"
                  styleClass="prime-dropdown w-100"
                  (onChange)="changePrice($event)"
                ></p-dropdown>
                <div
                  *ngIf="
                    !productForm.get('productOptionsControl').valid &&
                    productForm.get('productOptionsControl').touched
                  "
                >
                  <span
                    *ngIf="
                      productForm.get('productOptionsControl').errors.required
                    "
                  >
                    This field is required for this product
                  </span>
                </div>
              </div>
            </td>
            <td></td>
          </tr> -->
        </tbody>
      </table>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions
    class="mat-dialog__actions mat-dialog__actions--align-right pb-2 mat-dialog__actions--sidebar"
  >
    <button class="button button--secondary" (click)="hideSidebar()">
      Close
    </button>
    <button
      class="button button--primary"
      (click)="selectProduct()"
      [disabled]="!productForm.valid || !attachableToWorksheet"
    >
      {{ isEdit ? "Save" : "Add Selection" }}
    </button>
  </mat-dialog-actions>
</div>
