import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ChangeDetectorRef,
  AfterContentChecked,
} from "@angular/core";
import { QuickBooksDataModel } from "app/model/Core/QuickBooksDataModel";
import { QuickBooksModel } from "app/model/Core/QuickBooksModel";
import { NgForm, NgModel } from "@angular/forms";
import { OrganizationService } from "app/common/services/Core/Organization.service";
import { SharedService } from "app/common/utility/SharedService";

@Component({
  selector: "quickbooks-options",
  templateUrl: "./QuickBooksOptions.component.html",
  styleUrls: ["./QuickBooksOptions.scss"],
})
export class QuickBooksOptionsComponent implements OnInit, AfterContentChecked {
  @ViewChild("QuickBooksSync", { static: true }) syncField: NgModel;
  @ViewChild("QuickBooksIdentifier", { static: true }) identifierField: NgModel;

  @Input() model: QuickBooksModel;
  @Input() form: NgForm;
  @Input() set identifier(value: string) {
    if (this.model.quickBooksData && value) {
      this.model.quickBooksData.identifier = value;

      if (this.form) {
        let control = this.form.controls[this.identifierField.name];
        if (control)
          setTimeout(() => {
            control.markAsDirty();
          });
      }
    }
  }
  public connected: boolean;

  constructor(
    private detector: ChangeDetectorRef,
    private sharedService: SharedService,
    private organizationService: OrganizationService
  ) {}

  public ngAfterContentChecked(): void {
    this.setModel();
    this.detector.detectChanges();
  }

  private setModel(): void {
    if (this.model.quickBooksData) return;
    this.model.quickBooksData = <QuickBooksDataModel>{};
    this.model.quickBooksData.sync = true;
  }

  private getStatus(): void {
    this.connected = this.sharedService.quickBooksConnected;
    if (this.connected) return;

    let subscription = this.organizationService
      .getQuickbooksStatus()
      .subscribe((response) => {
        this.connected = response.success && response.object;
        this.sharedService.quickBooksConnected = this.connected;
        if (subscription) subscription.unsubscribe();
      });
  }

  public ngOnInit(): void {
    this.getStatus();
    this.setModel();

    if (this.form) {
      this.form.addControl(this.syncField);
      this.form.addControl(this.identifierField);
    }
  }
}
