import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";
import { UserModel } from "app/model/Core/UserModel";
import { UserRoleEnum } from "app/model/Core/UserRoleEnum";
import { Settings } from "app/model/Settings";

export class SharedService {
  loggedInUser: any;
  quickBooksConnected: boolean = false;
  selectedOrganization: any;
  isImpersonated: boolean = false;
  selectedJob: any = {};
  organizationSettings: Settings[] = [];
  public adminRoles: UserRoleEnum[] = [
    UserRoleEnum.Admin,
    UserRoleEnum.SuperAdmin,
    UserRoleEnum.Executive,
  ];

  public externalRoles: UserRoleEnum[] = [
    UserRoleEnum.Customer,
    UserRoleEnum.SubContractor,
    UserRoleEnum.Opportunity,
  ];

  public employeeRoles: UserRoleEnum[] = [
    UserRoleEnum.SalesManager,
    UserRoleEnum.SalesRep,
    UserRoleEnum.Scheduler,
    UserRoleEnum.ProjectManager,
    UserRoleEnum.Superintendent,
    UserRoleEnum.Contacts,
    UserRoleEnum.SalesTeamWithAdmin,
    UserRoleEnum.CustomerwithOpportunity,
    UserRoleEnum.PhaseOwner,
    UserRoleEnum.CameraOfflineNotification,
    UserRoleEnum.Accounting,
    UserRoleEnum.ServiceTech,
  ];

  public financialRoles: UserRoleEnum[] = [
    UserRoleEnum.SuperAdmin,
    UserRoleEnum.Admin,
    UserRoleEnum.Accounting,
    UserRoleEnum.ViewFinancialData,
  ];

  get user(): UserModel {
    if (!this.loggedInUser) return;

    let model = <UserModel>{
      id: this.loggedInUser.Id,
      guid: this.loggedInUser.CustomerGuid,
      userName: this.loggedInUser.UserName,
      email: this.loggedInUser.Email,
      firstName: this.loggedInUser.FirstName,
      lastName: this.loggedInUser.LastName,
      orgGuid: this.loggedInUser.OrgId,
      fullName: `${this.loggedInUser.FirstName} ${this.loggedInUser.LastName}`,
      roles: this.loggedInUser.CustomerRoles.map(
        (x) => UserRoleEnum[UserRoleEnum[x.Id]]
      ),
    };

    model.hasRole = function (role: UserRoleEnum): boolean {
      return this.roles.indexOf(role) >= 0;
    };

    model.hasAnyRole = function (...roles: UserRoleEnum[]): boolean {
      return this.roles.some((x) => roles.indexOf(x) >= 0);
    };

    model.hasRoles = function (...roles: UserRoleEnum[]): boolean {
      return roles.every((x) => this.roles.indexOf(x) >= 0);
    };

    model.isAdmin = model.hasAnyRole(...this.adminRoles);
    model.isEmployee = model.isAdmin || model.hasAnyRole(...this.employeeRoles);
    model.isExternal = model.hasAnyRole(...this.externalRoles);
    model.isSuperAdmin = model.hasRole(UserRoleEnum.SuperAdmin);
    model.isFinancial = model.hasAnyRole(...this.financialRoles);

    return model;
  }

  dispose() {
    this.isImpersonated = false;
    this.loggedInUser = {};
    this.selectedOrganization = {};
  }

  disposeSelectedJob() {
    this.selectedJob = {};
  }
}
@Injectable()
export class CommonSubscription {
  private subject = new Subject<boolean>();

  updateAssignedResource(isupdated: boolean) {
    this.subject.next(isupdated);
  }
  getUpdateAssignResource(): Observable<any> {
    return this.subject.asObservable();
  }
}
