import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ChatComponent } from "./Chat.component";
import { ChatConversationComponent } from "./ChatConversation.component";
import {
  ChatManagerComponent,
  FilterGroupsPipe,
  GroupNamePipe,
  GroupIconPipe,
  UserActivityPipe,
} from "./ChatManager.component";
import { ChatService } from "app/common/services/Chat.service";
import { DialogService } from "ng2-bootstrap-modal";
import { WindowModule } from "@progress/kendo-angular-dialog";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { DialogModule } from "primeng/primeng";
import { ChatAddEditGroupPanelComponent } from "./ChatAddEditGroupPanel.component";
import { FormsModule } from "@angular/forms";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { MatExpansionModule } from "@angular/material/expansion";
import { SearchUserModule } from "../SearchUser/SearchUser.module";

@NgModule({
  imports: [
    CommonModule,
    DropDownsModule,
    FormsModule,
    DialogModule,
    MatExpansionModule,
    NgbTooltipModule,
    WindowModule,
    SearchUserModule,
  ],
  declarations: [
    FilterGroupsPipe,
    GroupNamePipe,
    UserActivityPipe,
    GroupIconPipe,
    ChatComponent,
    ChatManagerComponent,
    ChatAddEditGroupPanelComponent,
    ChatConversationComponent,
  ],
  exports: [ChatComponent],
  providers: [DialogService, ChatService],
})
export class ChatModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ChatModule,
      providers: [ChatService],
    };
  }
}
