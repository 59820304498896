import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { FormGroup, FormArray, ValidationErrors } from "@angular/forms";
import { set, uniq } from "lodash";

@Injectable()
export class ProductSelectionValidatorService {
  private _errorMessage = new BehaviorSubject<string>("");
  get errorMessage() {
    return this._errorMessage.asObservable();
  }

  constructor() {}

  processErrorMessages(errors: SelectionValidationErrors): void {
    this.getErrorMessage(errors).then((message) => {
      this._errorMessage.next(message);
    });
  }

  private getErrorMessage(errors: SelectionValidationErrors): Promise<string> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const errorMessages: string[] = [];
        if (errors.isDuplicate) {
          errorMessages.push(
            "This product has already been added to the worksheet. Please choose another one\n"
          );
        }

        if (errors.isKitEmpty) {
          errorMessages.push("There is an empty kit\n");
        }

        if (errors.hasWorkAreaError) {
          errorMessages.push(
            "There are missing parameters in the work areas\n"
          );
        }

        if (errors.hasRootObjError) {
          errorMessages.push(
            "There was an error in the calculator of one of the products\n"
          );
        }

        if (errors.hasCalculatorProductOptionError) {
          errorMessages.push(
            "The calculator of this product option had problems. Please choose another\n"
          );
        }

        if (errors.hasCalculatorProductError) {
          errorMessages.push(
            "The calculator of the product without product options had problems\n"
          );
        }

        if (errors.hasKitItemError) {
          errorMessages.push("The calculator of a kit item had problems\n");
        }

        if (errors.form) {
          const formErrors = this.getFormErrors(errors.form);
          formErrors.forEach((error) => {
            if (error.maxlength) {
              errorMessages.push(
                `An items name length is not valid, maximum is ${error.maxlength.requiredLength}\n`
              );
            }

            if (error.min) {
              errorMessages.push(
                `An item's quantity of ${error.min.actual} is not valid, minimum is ${error.min.min}, if you are using a calculator please verify your values and parameters\n`
              );
            }

            if (error.required) {
              errorMessages.push(
                "A value is missing, please verify the form\n"
              );
            }
            // triggered when is the only one
            if (
              error.invalidValue &&
              !formErrors.some(
                (err) => "maxLength" in err || "min" in err || "required" in err
              )
            ) {
              errorMessages.push(
                "One or more fields are not valid, please verify"
              );
            }
          });
        }

        const result = errorMessages.length
          ? `${uniq(errorMessages).join(". ").trimRight()}.`
          : null;

        resolve(result);
      }, 100);
    });
  }

  private getFormErrors(form: FormGroup | FormArray): ValidationErrors[] {
    const errors: ValidationErrors[] = [];
    const recursiveFunc = (form: FormGroup | FormArray) => {
      Object.keys(form.controls).forEach((field) => {
        const control = form.get(field);
        if (control && control.errors) errors.push(control.errors);
        if (control instanceof FormGroup || control instanceof FormArray)
          recursiveFunc(control);
      });
    };
    recursiveFunc(form);
    return errors;
  }
}

export interface SelectionValidationErrors {
  isDuplicate?: boolean;
  isKitEmpty?: boolean;
  hasWorkAreaError?: boolean;
  hasRootObjError?: boolean;
  hasCalculatorProductOptionError?: boolean;
  hasCalculatorProductError?: boolean;
  hasKitItemError?: boolean;
  form: FormGroup;
}
