<div>
  <div class="image-annotator-backdrop" *ngIf="showBackdrop">
    <i
      class="fa fa-times close-button"
      title="close"
      (click)="hideMarker()"
    ></i>
    <div class="image-annotator-container" #container>
      <div>
        <img
          #image
          [src]="photo.BlobUrl"
          draggable="false"
          crossorigin="anonymous"
          (click)="showMarker()"
        />
        <span *ngIf="!annotationActive && !readonly">Click To Edit</span>
      </div>
    </div>
  </div>
</div>
