import { Component, Input, OnInit } from "@angular/core";
import { orderBy } from "@progress/kendo-data-query";
import { ConfirmComponent } from "app/component/dialog/dialog.component";
import { SortDescriptor } from "@progress/kendo-data-query";
import { PageChangeEvent } from "@progress/kendo-angular-grid";
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationService } from "app/common/services/notifications.service";
import { BaThemeSpinner } from "app/theme/services";
import { MessageService } from "primeng/components/common/messageservice";
import { DialogService } from "ng2-bootstrap-modal";
import { GridDataResult, GridComponent } from "@progress/kendo-angular-grid";
import { NotificationModel } from "app/model/notificationModel";
import { environmentConstant } from "app/common/utility/environment";
import { SharedService } from "app/common/utility/SharedService";
import { Helper } from "app/common/utility/helper";
import * as _ from "lodash";
import { ExcelExportData } from "@progress/kendo-angular-excel-export";
import { process } from "@progress/kendo-data-query";

@Component({
  selector: "notification-view.",
  templateUrl: "./notificationView.html",
})
export class NotificationViewComponent implements OnInit {
  isFilled: boolean = false;
  headerText: string = "";
  id: any;
  lstNotifaictin: NotificationModel[];

  pageSize: number = 50;
  skip: number = 0;
  gridView: GridDataResult;
  data: Object[];
  sort: SortDescriptor[] = [];
  multiple: boolean = false;
  allowUnsort: boolean = true;
  filterQuery = "";
  visibleSidebar: boolean = false;
  showAddEdit: boolean = false;

  @Input() notificationData: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationService,
    private _spinner: BaThemeSpinner,
    private notificationsService: MessageService,
    private dialogService: DialogService,
    private sharedService: SharedService
  ) {
    this.allData = this.allData.bind(this);
  }

  ngOnInit() {
    this.getJobId();
  }
  onHideSliderHide(e) {
    this.showAddEdit = false;
    this.visibleSidebar = false;
  }
  editAlert() {
    this.visibleSidebar = true;
    this.showAddEdit = true;
  }
  updateList(e) {
    this.showAddEdit = false;
    this.visibleSidebar = false;
    if (e) {
      this.getJobId();
    }
  }
  getJobId() {
    this.route.parent.params.subscribe((params) => {
      this.id = params["id"];
      this.getnotificationByJobID(this.id);
    });
  }
  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }
  public allData(): ExcelExportData {
    const result: ExcelExportData = {
      data: process(this.lstNotifaictin, {}).data,
    };

    return result;
  }

  getnotificationByJobID(id) {
    this._spinner.show();
    this.notificationService.getNotifications(false).subscribe((data) => {
      this.lstNotifaictin = data.Notifications;
      this.loadnotification(null);
    });
  }
  clearSearch(value) {
    if (value == null) this.isFilled = true;
    else this.isFilled = false;
    this.filterQuery = "";
    this.filterGridData();
  }

  pageChange(event: PageChangeEvent): void {
    // this._spinner.show();
    this.skip = event.skip;
    this.filterGridData();
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadnotification(null);
  }

  getStatus(statusId) {
    if (statusId && statusId > 0) {
      let status: any;
      status = environmentConstant.status.find((obj) => obj.key === statusId);
      return status ? status.Value : "";
    }
  }
  filterGridData() {
    this.loadnotification(
      _.filter(
        this.lstNotifaictin,
        (row) =>
          Helper.ConvertToLower(row.JobName).indexOf(
            this.filterQuery.trim().toLowerCase()
          ) > -1 ||
          Helper.ConvertToLower(row.Message).indexOf(
            this.filterQuery.trim().toLowerCase()
          ) > -1 ||
          Helper.ConvertToLower(row.NotificationName).indexOf(
            this.filterQuery.trim().toLowerCase()
          ) > -1 ||
          Helper.ConvertToLower(row.NotificationType).indexOf(
            this.filterQuery.trim().toLowerCase()
          ) > -1
      )
    );
  }
  filterGrid() {
    this.isFilled = true;
    this.filterGridData();
    this.pageChange({ skip: 0, take: this.pageSize });
  }

  private loadnotification(notification): void {
    let notifications = notification || this.lstNotifaictin;
    notifications = orderBy(notifications, this.sort);

    this.gridView = {
      data: notifications.slice(this.skip, this.skip + this.pageSize),
      total: notifications.length,
    };
    this._spinner.hide();
  }
  deleteNotification(notification) {
    var notificationName = this.lstNotifaictin.find(
      (obj) => obj.Id == notification
    );
    const disposable = this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Delete Notification",
        // tslint:disable-next-line:prefer-template
        message:
          "Are you sure you want to delete Notification" +
          " " +
          notificationName.NotificationName +
          "?",
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this._spinner.show();
          this.notificationService.deleteNotification(notification).subscribe(
            (result) => {
              this.result(result, "Deleted Successfully!!");
              this.getnotificationByJobID(this.id);
            },
            (error) => this.error(error, "Notification", "Unable to Delete ")
          );
        }
      });
  }
  private error(error: any, area, detail) {
    this.notificationsService.add({
      severity: "warn",
      summary: area,
      // tslint:disable-next-line:prefer-template
      detail: detail + "!!",
    });
    this._spinner.hide();
  }
  private result(res: any, message: string) {
    this.notificationsService.add({
      severity: "success",
      summary: "Notification",
      detail: message,
    });
    this._spinner.hide();
  }
}
