import { FormGroup } from "@angular/forms";
import { EstimateProductModel } from "./EstimateProductModel";
import { MaterialCalculator } from "./MaterialsCalculator";
import { KitDetails } from "./KitDetails";

export class ProjectPackageDetail {
  id: number;
  packageId: number;
  productId: number;
  qty: number;
  productName: string;
  orgGuid: string;
  isNew: boolean;
  retailPrice: number;
  unitOfMeasure: string;
  viewGroupId: number;
  sku?: string;
  materialsCalculator: MaterialCalculator;
  children: ProjectPackageDetail[];
  productTypeId?: number;
  materialsCalculatorId?: number;
  form?: FormGroup;
  kitProductId?: number;
  kitDetails?: KitDetails;
  totalCost?: number;
  unitKitQty?: number;

  product?: EstimateProductModel;

  hasChildren?: boolean;
  isKit?: boolean;
  isSimpleKit?: boolean;
  isComplexKit?: boolean;

  // displays
  useCalculator?: boolean;
  hasEditableQty?: boolean;
  viewGroupsId: number[];
}
