<div class="bill-of-materials">
  <h3 class="estimate-theme font-weight-bold">BILL OF MATERIALS</h3>
  <hr />
  <div class="estimate-worksheet__group-row pb-2">
    <div class="estimate-worksheet__group-name">
      {{ phase.PhaseName }}
      <span> ({{ bomItems.length }})</span>
    </div>
  </div>
  <p-table [value]="bomItems" [columns]="cols" [scrollable]="true">
    <ng-template pTemplate="header">
      <tr>
        <th
          *ngFor="let col of cols"
          [ngClass]="{ 'text-center': col.textCenter }"
        >
          {{ col.header }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-columns="columns">
      <tr>
        <td
          *ngFor="let col of columns"
          [ngClass]="{ 'text-center': col.textCenter }"
        >
          {{ item[col.field] }}
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <tr class="d-flex justify-content-center no-records">
        <td [attr.colspan]="columns.length">No records found</td>
      </tr>
    </ng-template>
  </p-table>
</div>
