import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BoolToTextPipe } from "app/common/directives/bool-to-text.pipe";
import { SafeHtmlPipe } from "app/common/directives/safeHtmlPipe";
import { ProposalBoolToAddRemovePipe } from "../common/directives/bool-to-add-remove.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [SafeHtmlPipe, BoolToTextPipe, ProposalBoolToAddRemovePipe],
  exports: [SafeHtmlPipe, BoolToTextPipe, ProposalBoolToAddRemovePipe],
})
export class PipesModule {}
