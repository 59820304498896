import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { GatewayAccountModel } from "app/model/GatewayAccountModel";
import { PaymentService } from "app/common/services/payment.service";
import { SharedService } from "app/common/utility/SharedService";
import { InvoiceService } from "app/common/services/invoice.service";

@Component({
  selector: "add-gateway-accounts",
  templateUrl: "./add-gateway-accounts.component.html",
  styleUrls: ["./add-gateway-accounts.component.scss"],
})
export class AddGatewayAccountsComponent implements OnInit {
  @Input() gatewayAccounts: GatewayAccountModel[];
  // @Input() roles: CustomerRoleModel[];
  // @Input() contacts: CustomerModel[];
  @Output() gatewayAccountListChange: EventEmitter<
    GatewayAccountModel[]
  > = new EventEmitter<GatewayAccountModel[]>();

  // filteredRoles: CustomerRoleModel[];
  filteredGatewayAccount: GatewayAccountModel[];
  editingIndex: number;
  editingValue: GatewayAccountModel;
  addingValue: GatewayAccountModel;

  constructor(
    private paymentService: PaymentService,
    private invoiceService: InvoiceService
  ) {}

  ngOnInit() {
    // this.paymentService.getGatewayAccountListByOrg().subscribe(data => {
    //   this.gatewayAccounts = data;
    // })
  }

  getAccountName(index: number) {
    return this.gatewayAccounts[index].AccountName;
  }

  getAccountNumber(index: number) {
    return this.gatewayAccounts[index].AccountNumber;
  }

  getMerchantId(index: number) {
    return this.gatewayAccounts[index].MerchantId;
  }

  getDefault(index: number) {
    return this.gatewayAccounts[index].Default;
  }

  addGatewayAccount() {
    this.editingIndex = null;
    this.editingValue = null;
    this.addingValue = new GatewayAccountModel();
  }

  cancelEdit() {
    this.editingIndex = null;
    this.editingValue = null;
  }

  cancelAdd() {
    this.addingValue = null;
  }

  changeAccountName(value: string) {
    this.editingValue.AccountName = value;
  }

  changeAccountNumber(value: string) {
    this.editingValue.AccountNumber = value;
  }

  changeMerchantId(value: string) {
    this.editingValue.MerchantId = value;
  }

  changeDefault(value: boolean) {
    this.editingValue.Default = value;
  }

  changeAddAccountName(value: string, index: number) {
    this.addingValue.AccountName = value;
  }

  changeAddAccountNumber(value: string, index: number) {
    this.addingValue.AccountNumber = value;
  }

  changeAddMerchantId(value: string, index: number) {
    this.addingValue.MerchantId = value;
  }

  changeAddDefault(value: boolean, index: number) {
    this.addingValue.Default = value;
  }

  deleteGatewayAccount(index: number) {
    let account = this.gatewayAccounts[index];
    account.Deleted = new Date();
    this.invoiceService.putGatwayAccount(account).subscribe();
    this.gatewayAccounts.splice(index, 1);
    this.gatewayAccountListChange.emit(this.gatewayAccounts);
  }

  editGatewayAccount(index: number) {
    this.addingValue = null;
    this.editingIndex = index;
    this.editingValue = { ...this.gatewayAccounts[index] };
  }

  filterAccountName(value) {
    this.filteredGatewayAccount = this.gatewayAccounts.filter((c) =>
      c.AccountName.toLowerCase().includes(value.toLowerCase())
    );
  }

  saveGatewayAccount(index: number) {
    this.gatewayAccounts[index] = { ...this.editingValue };

    if (this.editingValue.Default === true) {
      this.gatewayAccounts.forEach((account) => {
        if (this.editingValue.AccountNumber !== account.AccountNumber) {
          account.Default = false;
        }
      });
    }

    this.editingIndex = null;
    this.editingValue = null;

    this.gatewayAccountListChange.emit(this.gatewayAccounts);
  }

  saveAddGatewayAccount() {
    this.gatewayAccounts.unshift({ ...this.addingValue });

    if (this.addingValue.Default === true) {
      this.gatewayAccounts.forEach((account) => {
        if (this.addingValue.AccountNumber !== account.AccountNumber) {
          account.Default = false;
        }
      });
    }
    this.addingValue = null;
    this.gatewayAccountListChange.emit(this.gatewayAccounts);
  }
}
