<div class="modal-dialog modal-sm">
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close" (click)="close()">&times;</button>
      <h4 class="modal-title">{{ title }}</h4>
    </div>
    <div class="modal-body">
      <p>{{ message }}</p>
      <form #myForm="ngForm" class="form-horizontal">
        <div class="row p2row-bg">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="form-group">
              <label class="fu">Select Started On</label>
              <span class="ui-float-label">
                <kendo-datepicker
                  class="full-date"
                  name="EstStartDate"
                  [(ngModel)]="Scm.StartDate"
                  #EstStartDate="ngModel"
                  required
                >
                </kendo-datepicker>
              </span>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="form-group">
              <label class="fu">Select Completed On</label>
              <span class="ui-float-label">
                <kendo-datepicker
                  class="full-date"
                  name="ScheduledDate"
                  [(ngModel)]="Scm.EndDate"
                  [ngClass]="{ 'has-error': Scm.EndDate < Scm.StartDate }"
                  #ScheduledDate="ngModel"
                  required
                >
                </kendo-datepicker>
              </span>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn darkbtn dpbtn"
        (click)="Confirm()"
        [disabled]="Scm.StartDate > Scm.EndDate"
        *ngIf="myForm.valid"
      >
        Update
      </button>
      <button type="button" class="btn btn-default" (click)="close()">
        Cancel
      </button>
    </div>
  </div>
</div>
