import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "slideout-container",
  templateUrl: "./slideout-container.component.html",
  styleUrls: ["./slideout-container.component.scss"],
})
export class SlideoutContainerComponent implements OnInit {
  headerText = "";

  @Input() visible: boolean;
  @Input() headerName: string;
  constructor() {}

  ngOnInit() {
    this.headerText = this.headerName;
  }
}
