<div class="container-fluid p-0">
  <div class="row">
    <div class="col-lg-12">
      <h3 class="pt">Connect SignNow</h3>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12" *ngIf="finishedLoading">
      <button
        (click)="onConnect()"
        type="button"
        class="btn btn-primary"
        *ngIf="!connected"
      >
        Connect
      </button>
      <button
        *ngIf="connected"
        (click)="disconnect()"
        type="button"
        class="btn btn-danger"
      >
        Disconnect SignNow
      </button>
    </div>
  </div>
</div>
