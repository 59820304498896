import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";
import { environmentConstant } from "app/common/utility/environment";
import { Observable } from "rxjs";
import { ApiResponseModel as Api } from "app/model/ApiResponseModel";
import { OrgVendorModel } from "app/model/Core/OrgVendorModel";

@Injectable()
export class OrgVendorService {
  constructor(private httpService: HttpService) {}

  updateVendor(model: OrgVendorModel): Observable<Api<OrgVendorModel>> {
    return this.httpService.put<OrgVendorModel>(
      environmentConstant.coreApi.putOrgVendor(model.guid),
      model
    );
  }

  getVendor(vendorGuid: string): Observable<Api<OrgVendorModel>> {
    return this.httpService.get<OrgVendorModel>(
      environmentConstant.coreApi.getOrgVendor(vendorGuid)
    );
  }
}
