import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { NgaModule } from "app/theme/nga.module";
import { BaseSharedModule } from "./baseShared.module";
import { OrganizationsAddEditComponent } from "app/pages/configuration/organizations/organizationsAddEdit/organizationsAddEdit.component";
import { OrganizationsIntegrationComponent } from "app/pages/configuration/organizations/organizations-integration/organizations-integration.component";
import { SignNowConnectorComponent } from "app/component/SignNow/sign-now-connector/sign-now-connector.component";
import { MenuModule } from "primeng/primeng";
import { OrganizationSettingsComponent } from "app/pages/configuration/organizations/organization-settings/organization-settings.component";

const SHARED_MODULES = [BaseSharedModule, NgaModule, MenuModule];

@NgModule({
  imports: [...SHARED_MODULES],
  exports: [...SHARED_MODULES, OrganizationsAddEditComponent],
  declarations: [
    OrganizationsAddEditComponent,
    OrganizationsIntegrationComponent,
    SignNowConnectorComponent,
    OrganizationSettingsComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
