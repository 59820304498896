import { Component, Input, Output, EventEmitter } from "@angular/core";
import { SharedService } from "app/common/utility/SharedService";

@Component({
  selector: "ba-menu-item",
  templateUrl: "./baMenuItem.html",
  styleUrls: ["./baMenuItem.scss"],
})
export class BaMenuItem {
  constructor(public sharedService: SharedService) {}
  @Input() menuItem: any;
  @Input() child: boolean = false;

  @Output() itemHover = new EventEmitter<any>();
  @Output() toggleSubMenu = new EventEmitter<any>();
  @Output() slideOut = new EventEmitter<any>();
  public onHoverItem($event): void {
    this.itemHover.emit($event);
  }
  public onClick(event): void {
    this.slideOut.emit(event);
  }
  public onToggleSubMenu($event, item): boolean {
    $event.item = item;
    this.toggleSubMenu.emit($event);
    return false;
  }
}
