<div
  class="container-fluid p-0 vendor-contractor-form-container col-lg-12 col-md-12 col-sm-12 col-xs-12 mb0"
>
  <div class="section-bg">
    <div class="form-group mb0">
      <div
        class="row quick-book-options__acess-component layout-vertical-center"
      >
        <div class="col-lg-8 col-md-5 col-sm-12 col-xs-12 mb-3">
          <h3 class="pt">QuickBooks Options</h3>
        </div>
        <div class="col-lg-4 col-md-7 col-sm-12 col-xs-12 mb-3">
          <div class="switchBox">
            <p class="switchBox__text mr-2">sync</p>
            <label class="switch">
              <input
                [attr.disabled]="!connected ? '' : null"
                #QuickBooksSync="ngModel"
                type="checkbox"
                name="QuickBooksSync"
                [(ngModel)]="model.quickBooksData.sync"
              />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
        <div class="col-sm-12 mb-2">
          <div class="form-group mb0">
            <span class="ui-float-label">
              <input
                [attr.disabled]="!connected ? '' : null"
                type="text"
                class="form-control"
                id="QuickBooksIdentifier"
                pInputText
                [(ngModel)]="model.quickBooksData.identifier"
                #QuickBooksIdentifier="ngModel"
                [ngClass]="{
                  'has-error':
                    QuickBooksIdentifier.invalid &&
                    (QuickBooksIdentifier.dirty || QuickBooksIdentifier.touched)
                }"
                name="QuickBooksIdentifier"
                maxlength="250"
              />
              <label for="QuickBooksIdentifier" class="form-control-label"
                >Identifier Number</label
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
