import { NgModule, APP_INITIALIZER } from "@angular/core";
import { CommonModule } from "@angular/common";

import { routing } from "./pages.routing";
import { SharedModule } from "app/shared/shared.module";
import { AppTranslationModule } from "../app.translation.module";
import { Pages } from "./pages.component";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { InputTextModule, SidebarModule } from "primeng/primeng";
import { AuthGuard, Loggedout } from "app/_gaurd/auth.gaurd";
import { CustomerService } from "../../app/common/services/customer.service";
import { MessegeService } from "app/common/services/MessegeService.service";
import { CommonSubscription } from "app/common/utility/SharedService";
import { ContainerComponentModule } from "../component/Container/containerComponent.module";
import { JobPhaseService } from "../../app/common/services/jobPhase.service";
import { PhaseService } from "app/common/services/phase.service";
import { UpdateCountService } from "app/common/utility/updateCount.service";
import { CategoryService } from "app/common/services/category.service";
import { WorkOrderService } from "app/common/services/workOrder.service";
import { TradeService } from "app/common/services/trade.service";
import { JobService } from "app/common/services/job.service";
import { PunchListService } from "app/common/services/punchList.service";
import { TodoModule } from "./account/todo/todo.module";
import { ImageAnnotatorModule } from "app/component/image-annotator/image-annotator/image-annotator.module";
import { PhotoCarouselModule } from "app/component/photo-carousel/photo-carousel.module";
import { CommonComponentModule } from "app/component/commoncomponent.module";
import { RedirectModule } from "app/shared/redirect.module";
@NgModule({
  imports: [
    CommonModule,
    AppTranslationModule,
    SharedModule,
    TodoModule,
    routing,
    DropDownsModule,
    DateInputsModule,
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,
    InputTextModule,
    SidebarModule,
    ContainerComponentModule,
    ImageAnnotatorModule,
    PhotoCarouselModule,
    CommonComponentModule,
    RedirectModule,
  ],
  declarations: [Pages],
  providers: [
    AuthGuard,
    Loggedout,
    CustomerService,
    MessegeService,
    CommonSubscription,
    JobPhaseService,
    PhaseService,
    UpdateCountService,
    CategoryService,
    WorkOrderService,
    TradeService,
    JobService,
    PunchListService,
  ],
})
export class PagesModule {}
