import { Component, OnInit } from "@angular/core";
import { QuickBooksSearchComponent } from "./QuickBooksSearch.component";

@Component({
  selector: "quickbooks-search-vendors",
  templateUrl: "./QuickBooksSearch.component.html",
  styleUrls: ["./QuickBooksSearch.css"],
})
export class QuickBooksSearchVendorsComponent extends QuickBooksSearchComponent {
  public ngOnInit(): void {
    this.context = "vendors";
    this.placeholder = "Search by name";
    this.getStatus().subscribe((connected) => (this.connected = connected));
  }

  public onSearch(event: KeyboardEvent, input: any): void {
    super.onSearch(event, input);

    let subscription = this.organizationService
      .searchQuickBooksVendors(this.search)
      .subscribe((response) => {
        if (response.success) {
          this.items = response.object.items;
        }
        this.didSearch = true;
        this.spinner.hide();
        subscription.unsubscribe();
      });
  }
}
