import { SharedService } from "app/common/utility/SharedService";
import { Injectable } from "@angular/core";
import { HttpService } from "../utility/http.service";
import { environmentConstant } from "../utility/environment";
import { Observable } from "rxjs";
import { MessageTemplate } from "app/model/MessageTemplate";

@Injectable()
export class NotificationService {
  constructor(
    private httpService: HttpService,
    private sharedService: SharedService
  ) {}

  getNotifications(TakeTop25: boolean) {
    return this.httpService.getRequestServiceNoError(
      environmentConstant.notification +
        this.sharedService.loggedInUser.Id +
        "?TakeTop25=" +
        TakeTop25
    );
  }
  viewNotification(nId: any) {
    return this.httpService.putRequestService(
      environmentConstant.notification + nId + environmentConstant.viewed,
      null
    );
  }
  deleteNotification(nId: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.notification + nId
    );
  }
  clearAllNotification() {
    return this.httpService.deleteRequestService(
      environmentConstant.notification +
        this.sharedService.loggedInUser.Id +
        environmentConstant.clearall
    );
  }
  getNotificationsByOrganization() {
    return this.httpService.getRequestServiceNoError(
      environmentConstant.notification +
        this.sharedService.selectedOrganization.ID +
        "/organization"
    );
  }

  getDeliveryTypes() {
    return this.httpService.getRequestServiceNoError(
      environmentConstant.notification + "deliverytypes"
    );
  }

  putMessageTemplate(messageTemplate: MessageTemplate) {
    return this.httpService.putRequestService(
      environmentConstant.notification + "messagetemplate",
      messageTemplate
    );
  }

  postMessageTemplate(messageTemplate: MessageTemplate) {
    return this.httpService.postRequestService(
      environmentConstant.notification + "messagetemplate",
      messageTemplate
    );
  }
}
