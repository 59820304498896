<div class="widgets commonFixedSidebar">
  <div class="row">
    <div class="col-md-12 ht100">
      <div class="rightcontent">
        <div class="sideBarHeader">
          <h1>{{ headerText }} To Do Item</h1>
        </div>
        <div class="tabBlk">
          <form #myForm="ngForm" class="form-horizontal">
            <div class="fixed-layout hoverflow">
              <div class="mt15">
                <div class="row">
                  <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
                    <div class="form-group">
                      <span class="ui-float-label">
                        <input
                          type="text"
                          class="form-control"
                          pInputText
                          name="TaskName"
                          [(ngModel)]="task.TaskName"
                          #TaskName="ngModel"
                          [ngClass]="{
                            'has-error':
                              TaskName.invalid &&
                              (TaskName.dirty || TaskName.touched)
                          }"
                          maxlength="150"
                        />
                        <label for="text" class="form-control-label"
                          >To Do Item</label
                        >
                      </span>
                    </div>
                  </div>
                  <div
                    class="col-lg-5 col-md-5 col-sm-6 col-xs-12"
                    *ngIf="statusLst"
                  >
                    <div class="form-group Mandat">
                      <kendo-floatinglabel text="Status">
                        <kendo-combobox
                          [data]="statusLst"
                          [disabled]="!permissions.editStatus"
                          [valuePrimitive]="true"
                          [filterable]="true"
                          (filterChange)="filterStatus($event)"
                          [textField]="'Value'"
                          [(ngModel)]="task.StatusId"
                          (ngModelChange)="changePhaseStatus()"
                          name="Status"
                          [valueField]="'key'"
                          required
                        ></kendo-combobox>
                      </kendo-floatinglabel>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="form-group mb10">
                      <span class="ui-float-label">
                        <textarea
                          class="form-control mb0"
                          name="Comment"
                          [disabled]="!permissions.editDescription"
                          [(ngModel)]="task.Comment"
                          #Comment="ngModel"
                          maxlength="500"
                          id="description"
                          name="description"
                          rows="3"
                          pInputText
                        ></textarea>
                        <label for="text" class="form-control-label"
                          >Description</label
                        >
                      </span>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="background-p2">
                      <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div class="form-group mb10">
                            <label class="fu">Scheduled Date</label>
                            <span class="ui-float-label">
                              <kendo-datepicker
                                [(ngModel)]="task.ScheduledOn"
                                #ScheduledOn="ngModel"
                                name="ScheduledOn"
                                [ngClass]="{
                                  'has-error':
                                    ScheduledOn.invalid &&
                                    (ScheduledOn.dirty || ScheduledOn.touched)
                                }"
                                class="full-date"
                                name="Scheduled Date"
                                required
                              >
                              </kendo-datepicker>
                            </span>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div class="form-group">
                            <label class="fu">Completed On</label>
                            <span class="ui-float-label">
                              <kendo-datepicker
                                class="full-date"
                                [(ngModel)]="task.CompletedOn"
                                #CompletedOn="ngModel"
                                (valueChange)="changeStatus(task.CompletedOn)"
                                name="CompletedOn"
                              >
                              </kendo-datepicker>
                            </span>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div class="form-group">
                            <label class="fu">Reminder</label>
                            <span class="ui-float-label">
                              <kendo-datetimepicker
                                class="full-date"
                                name="followUpdate"
                                [steps]="datePickerSteps"
                                [(ngModel)]="task.Reminder"
                                placeholder=""
                              >
                              </kendo-datetimepicker>
                            </span>
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div class="Calinmarket mb10" *ngIf="task.StartedOn">
                            <span
                              >This phase was marked started on :
                              {{ task.StartedOn | date : "shortDate" }}
                              <span class="calin-icons">
                                <i
                                  *ngIf="!EnableStartDateEdit"
                                  class="fa fa-pencil"
                                  title="Edit"
                                  (click)="editStartDate(true)"
                                ></i>
                                <i
                                  class="fa fa-ban"
                                  *ngIf="EnableStartDateEdit"
                                  title="Cancel"
                                  (click)="editStartDate(false)"
                                ></i
                              ></span>
                            </span>
                          </div>
                        </div>
                        <div
                          *ngIf="EnableStartDateEdit"
                          class="col-lg-6 col-md-6 col-sm-6 col-xs-12"
                        >
                          <div class="form-group mb0">
                            <label class="fu">Started On</label>
                            <span class="ui-float-label">
                              <kendo-datepicker
                                class="full-date"
                                name="StartedOn"
                                [(ngModel)]="task.StartedOn"
                                #StartedOn="ngModel"
                              >
                              </kendo-datepicker>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="assignby">
                      <h3
                        *ngIf="
                          task.TaskId > 0 &&
                          task.AssignedById > 0 &&
                          task.AssignedById != loggedInUserId
                        "
                      >
                        Assign By:{{ task.AssignedBy }}
                      </h3>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="additem assignto-item clearfix">
                      <div class="addbtn">
                        <a
                          data-placement="top"
                          data-toggle="tooltip"
                          title="Assign To"
                          (click)="showSlides = 'showAssignTo'"
                          *ngIf="permissions.assign  && task.Resources.length < 1"
                        >
                          <i aria-hidden="true" class="fa fa-plus-circle"></i>
                          Assign To
                        </a>
                        <span
                          *ngIf="
                            !permissions.assign || task.Resources.length >= 1
                          "
                          >Assigned To</span
                        >
                      </div>
                    </div>
                    <div class="assigned-items">
                      <ul>
                        <li *ngFor="let resource of task.Resources">
                          <a
                            class="cancelbtnHead ui-sidebar-close"
                            (click)="deleteAssignedResource(resource.Id)"
                          >
                            <span class="pi pi-times"></span>
                          </a>
                          <span
                            class="short-name"
                            [ngClass]="
                              resource.IsExternal ? 'bg-green' : 'bg-blue'
                            "
                            >{{ getInitals(resource.Name) }}</span
                          ><span class="hover-content">{{
                            resource.Name
                          }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="fixbtnwrap">
              <div class="btnwrap__center">
                <button
                  type="button"
                  (click)="onSubmit()"
                  *ngIf="myForm.dirty || isAssignedUpdated"
                  [disabled]="!myForm.valid"
                  class="btn darkbtn"
                >
                  <i class="fa fa-floppy-o"></i> {{ buttonText }}
                </button>
                <button (click)="cancel()" class="cross btn bthpn2">
                  Close
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="sidebar3" [ngClass]="showSlides != '' ? 'open' : ''">
  <a class="cancelbtnHead ui-sidebar-close" (click)="showSlides = ''">
    <span class="pi pi-times"></span>
  </a>
  <todo-assign-resource
    *ngIf="showSlides == 'showAssignTo'"
    [AssignedResourceList]="lstAssignedResource"
    [workOrderId]="JobId"
    [LineItemId]="task.TaskId"
    [StartDate]="task.ScheduledOn"
    (sendData)="updateAssignedResource($event)"
  ></todo-assign-resource>
</div>
