<kendo-daterange>
  <div class="row mb-2">
    <div class="col-4">
      <div class="daterangeInput">
        <label>Start Date</label>
        <kendo-dateinput
          kendoDateRangeStartInput
          class="full-date"
          [format]="'MM/dd/yyyy'"
          [(value)]="selectedDateRange.start"
        >
        </kendo-dateinput>
        <span class="k-icon k-i-calendar"></span>
      </div>
    </div>
    <div class="col-4">
      <div class="daterangeInput">
        <label>End Date</label>
        <kendo-dateinput
          kendoDateRangeEndInput
          class="full-date"
          [format]="'MM/dd/yyyy'"
          [(value)]="selectedDateRange.end"
        >
        </kendo-dateinput>
        <span class="k-icon k-i-calendar"></span>
      </div>
    </div>
    <div class="col-4 text-right">
      <button (click)="reloadGrid($event)" class="k-button reload">
        Reload <span class="k-icon k-i-reload-sm"></span>
      </button>
    </div>
    <kendo-daterange-popup
      (close)="dateRangeSelected($event)"
      [animate]="false"
    ></kendo-daterange-popup>
  </div>
</kendo-daterange>
<kendo-grid [data]="workOrderList">
  <kendo-grid-column
    headerClass="gridHeading"
    class="gridRow"
    title=""
    width="30"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="dropdown cud">
        <i class="fa fa-ellipsis-v" id="menu1" data-toggle="dropdown"> </i>
        <ul class="dropdown-menu" aria-labelledby="menu1">
          <li>
            <a (click)="viewDetail(dataItem.ID)">
              <i class="fa fa-eye"></i>{{ "actions.view" | translate }}
            </a>
          </li>
        </ul>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    headerClass="gridHeading"
    class="gridRow"
    field="WorkOrderName"
    title="WO#"
  ></kendo-grid-column>
  <kendo-grid-column
    headerClass="gridHeading"
    class="gridRow"
    field="Subject"
    title="Subject"
  ></kendo-grid-column>
  <kendo-grid-column
    headerClass="gridHeading"
    class="gridRow"
    field="Resources"
    title="Assign To"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ getCombinedResourceNames(dataItem.Resources) }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    headerClass="gridHeading"
    class="gridRow"
    field="IsWarranty"
    title="Is Warranty"
  ></kendo-grid-column>
  <kendo-grid-column
    headerClass="gridHeading"
    class="gridRow"
    field="StatusId"
    title="Status"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ getStatusDescription(dataItem.StatusId) }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    headerClass="gridHeading"
    class="gridRow"
    field="StartDate"
    title="Date"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{
        dataItem.StartDate == null ? "" : 
        dataItem.StartDate.toLocaleString(undefined, {
          year: "numeric",
          month: "numeric",
          day: "numeric"
        })
      }}
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
<div class="fixbtnwrap">
  <div class="btnwrap">
    <button type="button" class="btn btn-cancel" (click)="onCancel()">
      Close
    </button>
  </div>
</div>
