<ul
  id="al-sidebar-list"
  class="al-sidebar-list"
  [ngStyle]="{ 'height': menuHeight + 'px' }"
  (mouseleave)="hoverElemTop=outOfArea"
>
  <ba-menu-item
    [menuItem]="item"
    (itemHover)="hoverItem($event)"
    (toggleSubMenu)="toggleSubMenu($event)"
    (slideOut)="slideOut($event)"
    *ngFor="let item of menuItems"
  ></ba-menu-item>
</ul>
<div
  class="sidebar-hover-elem"
  [ngStyle]="{top: hoverElemTop + 'px', height: hoverElemHeight + 'px'}"
  [ngClass]="{'show-hover-elem': showHoverElem }"
></div>
