import { NgModule } from "@angular/core";

import { RedirectComponent } from "app/common/utility/Redirect.component";

@NgModule({
  imports: [],
  exports: [RedirectComponent],
  declarations: [RedirectComponent],
  providers: [],
})
export class RedirectModule {}
