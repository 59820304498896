import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { WorkOrderService } from "app/common/services/workOrder.service";
import { BaThemeSpinner } from "app/theme/services";
import { SharedService } from "app/common/utility/SharedService";
import { JobListModelV2 } from "app/model/jobListModelV2";
import { MessageService } from "primeng/components/common/messageservice";
import { WorkOrderModelV2 } from "app/model/WorkOrderV2Model";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import {
  GridDataResult,
  PageChangeEvent,
} from "@progress/kendo-angular-grid/dist/es2015";
import { DialogService } from "ng2-bootstrap-modal";
import { ConfirmComponent } from "app/component/dialog/dialog.component";
import { AddressModel } from "app/model/addressModel";
import { Resource } from "app/model/ResourceModel";
import { WorkOrderLineItemModel } from "app/model/WorkOrderLineItemModel";
import { NgForm, ControlContainer } from "@angular/forms";
import { CommonService } from "app/common/services/common.service";
import { BusinessUnitModel } from "app/model/organizationModel";
import { JobResourcesRequestPayload } from "app/model/JobAssignResourceModel";
import { environmentConstant } from "app/common/utility/environment";
import { UpdateCountService } from "app/common/utility/updateCount.service";
import { MPBCategoryEntity } from "app/common/directives/commonEnum";
@Component({
  selector: "work-order-details",
  templateUrl: "./workorderdetails.component.html",
  styleUrls: ["./workorderdetails.component.css"],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class WorkOrderDetailsComponent implements OnInit {
  workOrderV2: WorkOrderModelV2;
  showAddLineItem: boolean = false;
  showSlides: string = "";
  isFindJob: boolean = true;
  statusLst: any[] = [];
  originalStatusList: any[] = [];
  lstLineItem: WorkOrderLineItemModel[] = [];
  sort: SortDescriptor[] = [];
  skip: number = 0;
  gridView: GridDataResult;
  pageSize: number = 50;
  LineItemId: number;
  isaddWorkOrder: boolean = false;
  dataIdToEdit: number;
  currentpage: number = 1;
  totalRecords: number = null;
  visibleSidebar: boolean = false;
  selectedTab: number = 0;
  jobListV2: JobListModelV2;
  showCarousel: boolean = false;
  currentIndex: number;
  selectAll: boolean = false;
  buttonText: string = "";
  isChecked: boolean = false;
  lstAssignedResource: any;
  isAssignedUpdated: boolean = false;
  isNewAssigned: boolean = false;
  assignedsWereUpdated: boolean = false;
  lstCategory: any[] = [];
  originalCategoryList: any[] = [];
  multiple: boolean = false;
  allowUnsort: boolean = true;
  EnableStartDateEdit: boolean = false;
  previousStartDate: Date;
  lstBusinessUnit: BusinessUnitModel[] = [];
  lstOrignalBusinessUnit: BusinessUnitModel[] = [];
  @Input() JobData: any;
  @Input() WorkOrderID: any;
  @Output() closeSlide: EventEmitter<any> = new EventEmitter<any>();
  @Output() onWarrantyChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() jobSeleted: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateWorkorderID: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateWorkorderNumber: EventEmitter<any> = new EventEmitter<any>();
  previousResources: any = [];
  assignResourceModel: any;

  constructor(
    private parentForm: NgForm,
    private workOrderService: WorkOrderService,
    private _spinner: BaThemeSpinner,
    private sharedService: SharedService,
    private commonService: CommonService,
    private notificationsService: MessageService,
    private dialogService: DialogService,
    private updateCountsService: UpdateCountService
  ) {
    this.workOrderV2 = new WorkOrderModelV2();
    this.workOrderV2.OrgGuid = this.sharedService.selectedOrganization.ID;

    //Try to lookup the user for the selected job (if one is selected)
    //If no job is selected, temporarily assign the current user id.  This is
    //needed so that a dummy work order record can be saved.  The real customer
    //Id will be applied once the job is selected

    if (this.sharedService.selectedJob == null) {
      this.workOrderV2.CustomerId = this.sharedService.loggedInUser.Id;
    } else if (Object.keys(this.sharedService.selectedJob).length === 0) {
      this.workOrderV2.CustomerId = this.sharedService.loggedInUser.Id;
    } else if (this.sharedService.selectedJob.CustomerId != null) {
      this.workOrderV2.CustomerId = this.sharedService.selectedJob.CustomerId;
    } else if (this.sharedService.selectedJob.customer != null) {
      this.workOrderV2.CustomerId = this.sharedService.selectedJob.customer.id;
    } else {
      this.workOrderV2.CustomerId = this.sharedService.loggedInUser.Id;
    }
  }

  ngOnInit() {
    this._spinner.show();
    if (!this.WorkOrderID) {
      this.isaddWorkOrder = true;
      this.isFindJob = true;
      this.buttonText = "Save";
      this.setDummyWorkOrder();
    } else {
      this.isFindJob = false;
      this.buttonText = "Update";
    }
    this.getStatus();
    this.getCategory();
    this.getBusinessUnit();
    this.getWorkOrderBYId();
  }
  getBusinessUnit() {
    this.commonService
      .getBusinessUnit(this.sharedService.selectedOrganization.ID)
      .subscribe((data) => {
        this.lstBusinessUnit = data;
        this.lstOrignalBusinessUnit = data;
      });
  }
  public filterBussinessUnit(filter: any): void {
    this.lstBusinessUnit = this.lstOrignalBusinessUnit.filter(
      (s) => s.BusinessUnit1.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }
  pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadWorkOrder(null, this.lstLineItem);
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadWorkOrder(null, this.lstLineItem);
  }
  getCategory() {
    this.workOrderService
      .getCategoriesByEntityId(
        this.sharedService.selectedOrganization.ID,
        MPBCategoryEntity.WorkOrder
      )
      .subscribe((data) => {
        this.lstCategory = data;
        this.originalCategoryList = data;
        this._spinner.hide();
      });
  }

  public filterCategory(filter: any): void {
    this.lstCategory = this.originalCategoryList.filter(
      (s) => s.CatetoryName.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }

  setDummyWorkOrder() {
    var job = this.sharedService.selectedJob;

    if (job != null) if (Object.keys(job).length !== 0) this.isFindJob = false;

    if (this.isaddWorkOrder) {
      this.workOrderService
        .setWorkOrderDummy(this.workOrderV2)
        .subscribe((data) => {
          this.workOrderV2 = data;
          if (job) {
            if (Object.keys(job).length !== 0) {
              var phone = job.PrimaryPhone;
              if (typeof phone == "undefined") phone = job.Phone;
              if (
                typeof phone == "undefined" &&
                typeof job.customer != "undefined"
              )
                phone = job.customer.phoneNumber;
              if (typeof phone == "undefined") phone = null;

              this.workOrderV2.JobName = job.JobName;
              this.workOrderV2.Phone = phone;
              let isCoreAddressModel =
                typeof job.Address.address1 != "undefined" ? true : false;
              this.setAddressModel(isCoreAddressModel, job);
              this.isFindJob = false;
              this.jobSeleted.emit(true);
              this.workOrderV2.ParentJobId = job.JobID;
            }
          }
          this.workOrderV2.Resources = new Array<Resource>();
          this.updateWorkorderID.emit(this.workOrderV2.ID);
        });
    }
  }

  setAddressModel(isCoreAddressModel: boolean, job) {
    this.workOrderV2.Address = new AddressModel();
    if (isCoreAddressModel) {
      this.createCoreAddressModel(job);
    } else {
      this.createLegacyAddressModel(job);
    }
  }

  createCoreAddressModel(job) {
    this.workOrderV2.JobAddresId = job.Address.id;
    this.workOrderV2.Address.Address1 = job.Address.address1;
    this.workOrderV2.Address.City = job.Address.city;
    this.workOrderV2.Address.StateProvinceAbbreviation =
      job.Address.stateProvinceAbbreviation;
    this.workOrderV2.Address.ZipPostalCode = job.Address.zipPostalCode;
  }

  createLegacyAddressModel(job) {
    this.workOrderV2.JobAddresId = job.Address.Id;
    this.workOrderV2.Address.Address1 = job.Address.Address1;
    this.workOrderV2.Address.City = job.Address.City;
    this.workOrderV2.Address.StateProvinceAbbreviation =
      job.Address.StateProvinceAbbreviation;
    this.workOrderV2.Address.ZipPostalCode = job.Address.ZipPostalCode;
  }

  getWorkOrderBYId() {
    if (!this.isaddWorkOrder) {
      this.workOrderService
        .getWorkorderbyId(this.WorkOrderID)
        .subscribe((data) => {
          this.workOrderV2 = data;
          this.workOrderV2.ScheduledDate = this.setDate(
            this.workOrderV2.ScheduledDate
          );
          this.workOrderV2.CompleteDate = this.setDate(
            this.workOrderV2.CompleteDate
          );
          this.workOrderV2.RemindMeOnDay = this.setDate(
            this.workOrderV2.RemindMeOnDay
          );
          this.workOrderV2.TargetDate = this.setDate(
            this.workOrderV2.TargetDate
          );
          this.workOrderV2.StartDate = this.setDate(this.workOrderV2.StartDate);
          this.updateWorkorderNumber.emit(this.workOrderV2.WorkOrderNumber);

          if (
            this.workOrderV2.Resources &&
            this.workOrderV2.Resources.length > 0
          ) {
            this.lstAssignedResource = JSON.parse(
              JSON.stringify(this.workOrderV2.Resources)
            );
            this.previousResources = JSON.parse(
              JSON.stringify(this.workOrderV2.Resources)
            );
          } else {
            this.lstAssignedResource = [];
            this.previousResources = [];
          }
          this.onChangeWaranty();
          if (this.workOrderV2.ParentJobId) this.jobSeleted.emit(true);
          this.getLineItemList();
        });
    }
  }

  setDate(date) {
    return date ? new Date(date) : null;
  }

  onChangeWaranty() {
    this.onWarrantyChange.emit(this.workOrderV2.IsWarranty);
  }
  public filterStatus(filter: any): void {
    this.statusLst = this.originalStatusList.filter(
      (s) => s.Status.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }

  getStatus() {
    this.statusLst = environmentConstant.status;
    this.originalStatusList = environmentConstant.status;
    // this.workOrderService.getStatusId(6).subscribe(
    //     data => {
    //         this.statusLst = data;
    //         this.originalStatusList = data;
    //     }
    // );
  }

  private error(error: any, message) {
    this.notificationsService.add({
      severity: "warn",
      summary: "Work Order",
      detail: message,
    });
    this._spinner.hide();
  }

  private result(res: any, message, title) {
    if (res !== null) {
      this.notificationsService.add({
        severity: "success",
        summary: title,
        detail: message,
      });
      this._spinner.hide();
    }
  }

  updateData(e) {
    this.showSlides = "";
    this.getLineItemList();
  }

  getLineItemList() {
    this.workOrderService
      .getWorkOrderLineItem(this.workOrderV2.ID)
      .subscribe((data) => {
        this.lstLineItem = data;
        this.lstLineItem.forEach((item) => {
          item.Resources.forEach((obj) => {
            if (!item.ResourcesText) {
              item.ResourcesText = obj.Name;
            } else {
              item.ResourcesText = item.ResourcesText + ", " + obj.Name;
            }
          });
        });
        this.loadWorkOrder(null, this.lstLineItem);
      });
  }

  private loadWorkOrder(workOrder, list): void {
    let lineItem = workOrder || list;
    lineItem = orderBy(lineItem, this.sort);
    if (this.totalRecords) {
      this.gridView = {
        data: list,
        total: this.totalRecords,
      };
    } else {
      this.gridView = {
        data: lineItem.slice(this.skip, this.skip + this.pageSize),
        total: lineItem.length,
      };
    }
    this._spinner.hide();
  }

  updateAssignedResource(event) {
    if (event == "cancel") {
      this.showSlides = "";
    } else {
      if (event.length > 0) {
        event.forEach((element) => {
          this.workOrderV2.Resources.push(element);
          this.isAssignedUpdated = true;
        });
        this.isNewAssigned = true;
        this.lstAssignedResource = JSON.parse(
          JSON.stringify(this.workOrderV2.Resources)
        );
      }
      this.showSlides = "";
    }
  }

  getWOData(event) {
    if (event == "cancel") {
      this.showSlides = "";
    } else {
      this.isFindJob = false;
      this.workOrderV2.JobName = event.JobName;
      this.workOrderV2.Phone = event.PrimaryPhone;
      this.workOrderV2.JobAddresId = event.JobAddresId;
      this.workOrderV2.Address = new AddressModel();
      this.workOrderV2.Address.Id = event.JobAddresId;
      this.workOrderV2.Address.Address1 = event.Address1;
      this.workOrderV2.Address.City = event.City;
      this.workOrderV2.Address.StateProvinceAbbreviation = event.Abbreviation;
      this.workOrderV2.Address.ZipPostalCode = event.ZipPostalCode;
      this.workOrderV2.CustomerId = event.CustomerId;
      this.jobListV2 = event;
      this.workOrderV2.ParentJobId = event.JobId;
      this.showSlides = "";
      this.jobSeleted.emit(true);
    }
  }

  saveWorkOrder(form) {
    this._spinner.show();
    if (form.valid) {
      this.isNewAssigned = false;
      if (this.workOrderV2.ScheduledDate) {
        this.workOrderV2.ScheduledDate = new Date(
          this.workOrderV2.ScheduledDate
        );
        this.workOrderV2.ScheduledDate = new Date(
          Date.UTC(
            this.workOrderV2.ScheduledDate.getFullYear(),
            this.workOrderV2.ScheduledDate.getMonth(),
            this.workOrderV2.ScheduledDate.getDate()
          )
        );
      }
      if (this.workOrderV2.CompleteDate) {
        this.workOrderV2.CompleteDate = new Date(this.workOrderV2.CompleteDate);
        this.workOrderV2.CompleteDate = new Date(
          Date.UTC(
            this.workOrderV2.CompleteDate.getFullYear(),
            this.workOrderV2.CompleteDate.getMonth(),
            this.workOrderV2.CompleteDate.getDate()
          )
        );
      }
      if (this.workOrderV2.RemindMeOnDay) {
        this.workOrderV2.RemindMeOnDay = new Date(
          this.workOrderV2.RemindMeOnDay
        );
        this.workOrderV2.RemindMeOnDay = new Date(
          Date.UTC(
            this.workOrderV2.RemindMeOnDay.getFullYear(),
            this.workOrderV2.RemindMeOnDay.getMonth(),
            this.workOrderV2.RemindMeOnDay.getDate()
          )
        );
      }
      if (this.workOrderV2.StartDate) {
        this.workOrderV2.StartDate = new Date(this.workOrderV2.StartDate);
        this.workOrderV2.StartDate = new Date(
          Date.UTC(
            this.workOrderV2.StartDate.getFullYear(),
            this.workOrderV2.StartDate.getMonth(),
            this.workOrderV2.StartDate.getDate()
          )
        );
      }
      if (this.workOrderV2.TargetDate) {
        this.workOrderV2.TargetDate = new Date(this.workOrderV2.TargetDate);
        this.workOrderV2.TargetDate = new Date(
          Date.UTC(
            this.workOrderV2.TargetDate.getFullYear(),
            this.workOrderV2.TargetDate.getMonth(),
            this.workOrderV2.TargetDate.getDate()
          )
        );
      }

      this.workOrderService
        .updateWorkOrderv2(this.isaddWorkOrder, this.workOrderV2)
        .subscribe(
          (result) => {
            this.updateCountsService.update();
            let yFilter = this.previousResources.map((itemY) => {
              return itemY.Id;
            });
            let filteredAssignTo = this.workOrderV2.Resources.filter(
              (itemX) => !yFilter.includes(itemX.Id)
            );
            if (filteredAssignTo.length > 0) {
              this.assignResourceModel = new JobResourcesRequestPayload();
              this.assignResourceModel.Resources = filteredAssignTo;
              this.assignResourceModel.JobPhaseId = 0;
              this.assignResourceModel.JobID = this.workOrderV2.ID;
              this.workOrderService
                .saveAssignedResource(this.assignResourceModel)
                .subscribe((result) => {
                  this.result(
                    result,
                    this.isaddWorkOrder
                      ? "Added Successfully!!"
                      : "Updated Successfully!!",
                    "Work Order"
                  );
                  this.closeSlide.emit(true);
                });
            } else {
              this.result(
                result,
                this.isaddWorkOrder
                  ? "Added Successfully!!"
                  : "Updated Successfully!!",
                "Work Order"
              );
              this.closeSlide.emit(true);
            }
          },
          (error) => this.error(error, "Unable to save Work Order")
        );
    } else {
      this._spinner.hide();
    }
  }

  deleteLineItem(lineItemId) {
    var LineItemName = this.lstLineItem.find((obj) => obj.ID == lineItemId);
    const disposable = this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Delete Line Item Work Order",
        // tslint:disable-next-line:prefer-template
        message:
          "Are you sure you want to delete Work Order Line Item" +
          " " +
          LineItemName.LineItemName +
          "?",
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this._spinner.show();
          this.workOrderService.deleteWorkOrderLineItem(lineItemId).subscribe(
            (result) => {
              this.result(result, "Deleted Successfully!!", "Work Order");
              this.getLineItemList();
            },
            (error) =>
              this.error(error, "Unable to Delete Work Order Line Item")
          );
        }
      });
  }

  deleteAssignedResource(assignedId) {
    var assignedTo = this.workOrderV2.Resources.find(
      (obj) => obj.Id == assignedId
    );
    var index = this.workOrderV2.Resources.findIndex(
      (obj) => obj.Id == assignedId
    );
    const disposable = this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Delete Assigned Resource",
        // tslint:disable-next-line:prefer-template
        message:
          "Are you sure you want to delete assigned resource" +
          " " +
          assignedTo.Name +
          "?",
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this._spinner.show();
          this.workOrderService
            .deleteAssignedResources(
              assignedId,
              this.workOrderV2.ID,
              0,
              assignedTo.IsExternal
            )
            .subscribe(
              (result) => {
                this.result(
                  result,
                  "Deleted Successfully!!",
                  "Assign Resource"
                );
                this.workOrderV2.Resources.splice(index, 1);
                this.lstAssignedResource = JSON.parse(
                  JSON.stringify(this.workOrderV2.Resources)
                );
                this.parentForm.form.markAsDirty();
                this.assignedsWereUpdated = true;
                this._spinner.hide();
              },
              (error) => this.error(error, "Unable to Delete assigned resource")
            );
          this._spinner.hide();
        }
      });
  }
  getInitals(str) {
    if (!str) return "";
    var result = str
      .split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), "");
    if (result && result.length > 2) {
      return result.slice(0, 2);
    }
    return result;
  }

  onStatusChange() {
    if (this.workOrderV2.StatusId === 22) {
      if (!this.workOrderV2.StartDate) {
        this.workOrderV2.CompleteDate = null;
        this.workOrderV2.StartDate = new Date();
      } else {
        this.workOrderV2.CompleteDate = null;
      }
    } else if (
      this.workOrderV2.StatusId === 23 &&
      !this.workOrderV2.CompleteDate
    ) {
      this.workOrderV2.StartDate = new Date();
      this.workOrderV2.CompleteDate = new Date();
    } else if (this.workOrderV2.StatusId === 21) {
      this.workOrderV2.StartDate = null;
      this.workOrderV2.CompleteDate = null;
    }
  }
  changeCompleteDateStatus(completeDate) {
    if (!completeDate) return;

    if (!this.workOrderV2.StartDate) {
      this.workOrderV2.StartDate = new Date();
    }
  }
  editStartDate(IsEdit) {
    if (IsEdit) {
      this.EnableStartDateEdit = true;
      this.previousStartDate = this.workOrderV2.StartDate;
    } else {
      this.EnableStartDateEdit = false;
      this.workOrderV2.StartDate = this.previousStartDate;
    }
  }
  ngOnDestroy() {
    // this.sharedService.selectedJob = undefined;
    if (this.isNewAssigned || this.assignedsWereUpdated) {
      this.closeSlide.emit(true);
    }
  }
  sendWorkOrderEmail() {
    this._spinner.show();
    this.workOrderService.sendWorkOrderEmail(this.WorkOrderID).subscribe(
      (result) => {
        this.result(result, "Sent Successfully!!", "Email");

        this._spinner.hide();
      },
      (error) => this.error(error, "Unable to Send Email")
    );
    this._spinner.hide();
  }
}
