<div class="estimate-theme catalog-picker__modal catalog-picker__modal--kit">
  <h1 matDialogTitle class="estimate-typography-pop-up-titles">
    {{ kitLabel }}
  </h1>
  <h3 class="mt-2 mb-2 product-name-title">{{ kitName }}</h3>
  <h3>SKU# {{ skuKit }}</h3>
  <mat-dialog-content>
    <form [formGroup]="productsForm">
      <div class="workarea-fields">
        <div>
          <div class="input-pentair-std input-pentair-workarea">
            <label> Qty </label>
            <input
              type="text"
              formControlName="kitQty"
              currencyMask
              [options]="{
                prefix: '',
                thousands: '',
                decimal: '',
                align: 'left',
                precision: 0,
                allowNegative: false
              }"
              min="1"
              max="9999"
              class="mt-3 p-2 w-100"
              [readonly]="selectOnly"
            />
          </div>
        </div>
        <div class="product-kit-total">
          Total
          <span>
            {{ totalKit | currency : "USD" : "symbol" : "1.2-2" }}
          </span>
        </div>
      </div>
      <p class="mb-2 mt-3">
        Simple Kit quantities can only be positive, no decimals are allowed.
      </p>
      <p class="mb-3">
        If the editable quantity feature is enabled
        <i
          class="fa fa-info-circle green-color"
          pTooltip="This feature can be enabled in the Catalog (per product) in the Kit Details Tab."
          tooltipPosition="bottom"
          tooltipStyleClass="tooltip"
        ></i>
        , product quantities within the Simple Kit can be positive or negative,
        including decimals
        <i
          class="fa fa-info-circle green-color"
          pTooltip="Decimal values can be allowed or not, depending on the Value Type of the Unit of Measure. This can be configured in the Units of Measure module."
          tooltipPosition="bottom"
          tooltipStyleClass="tooltip"
        ></i>
        . No zeros are allowed.
      </p>
      <div class="simple-kit__scroll-table-container">
        <table>
          <thead>
            <tr>
              <th class="width__2 align-right">Qty</th>
              <th class="width__2 align-right sku">SKU #</th>
              <th class="width__6 product-name">Product Name</th>
              <th
                class="catalog-picker__modal-cell--align-right width__2 price"
              >
                Price
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="productRows">
              <ng-container
                [formGroupName]="i"
                *ngFor="
                  let product of kit?.kitPartProductDetails;
                  let i = index
                "
              >
                <tr
                  [ngClass]="{
                    'catalog-picker__has-product-options': false
                  }"
                >
                  <td class="width__2 align-right">
                    <span
                      *ngIf="
                        !product.editableQty &&
                        (!product.materialsCalculatorId || showCalculatedValues)
                      "
                    >
                      {{ product.qty }}
                    </span>
                    <span
                      *ngIf="
                        !product.editableQty &&
                        product.materialsCalculatorId &&
                        !showCalculatedValues
                      "
                    >
                      <i class="fa svg-icon__function"></i>
                    </span>
                    <input
                      *ngIf="product.editableQty"
                      decimalFormat
                      [decimals]="
                        product.kitPartProduct.unitOfMeasure.valueType
                      "
                      [negative]="true"
                      [maxSize]="6"
                      [allowZero]="false"
                      class="width__4 align-right input-number"
                      type="number"
                      formControlName="qtyControl"
                    />
                  </td>
                  <td class="width__2 align-right">
                    {{ product.kitPartProduct.sku }}
                  </td>
                  <td class="width__6">
                    {{ product.kitPartProduct.name }}
                  </td>
                  <td class="catalog-picker__modal-cell--align-right width__2">
                    <span>
                      {{
                        product.kitPartProduct.priceBookPrice
                          | currency : "USD" : "symbol" : "1.2-2"
                      }}
                    </span>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions
    class="mat-dialog__actions mat-dialog__actions--align-right pb-2 mat-dialog__actions--sidebar"
  >
    <button class="button button--secondary" (click)="hideSidebar()">
      Close
    </button>
    <button
      *ngIf="!selectOnly"
      class="button button--primary"
      (click)="selectKit()"
      [disabled]="!productsForm.valid"
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
