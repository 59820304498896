import { NgModule, ModuleWithProviders } from "@angular/core";

import { BaThemeConfig } from "./theme.config";

import { BaThemeConfigProvider } from "./theme.configProvider";

import {
  BaBackTop,
  BaCheckbox,
  BaContentTop,
  BaMenuItem,
  BaMenu,
  BaMsgCenter,
  BaMultiCheckbox,
  BaPageTop,
  BaPictureUploader,
  BaSidebar,
  BaFileUploader,
} from "./components";

import { BaScrollPosition, BaSlimScroll, BaThemeRun } from "./directives";

import { BaProfilePicturePipe } from "./pipes";

import {
  BaImageLoaderService,
  BaMenuService,
  BaThemePreloader,
  BaThemeSpinner,
} from "./services";

import { EmailValidator, EqualPasswordsValidator } from "./validators";
import { BaseSharedModule } from "app/shared/baseShared.module";

const NGA_COMPONENTS = [
  BaBackTop,
  BaCheckbox,
  BaContentTop,
  BaMenuItem,
  BaMenu,
  BaMsgCenter,
  BaMultiCheckbox,
  BaPageTop,
  BaPictureUploader,
  BaSidebar,
  BaFileUploader,
];

const NGA_DIRECTIVES = [BaScrollPosition, BaSlimScroll, BaThemeRun];

const NGA_PIPES = [BaProfilePicturePipe];

const NGA_SERVICES = [
  BaImageLoaderService,
  BaThemePreloader,
  BaThemeSpinner,
  BaMenuService,
];

const NGA_VALIDATORS = [EmailValidator, EqualPasswordsValidator];

@NgModule({
  declarations: [...NGA_PIPES, ...NGA_DIRECTIVES, ...NGA_COMPONENTS],
  imports: [BaseSharedModule],
  exports: [...NGA_PIPES, ...NGA_DIRECTIVES, ...NGA_COMPONENTS],
})
export class NgaModule {
  static forRoot(): ModuleWithProviders {
    return <ModuleWithProviders>{
      ngModule: NgaModule,
      providers: [
        BaThemeConfigProvider,
        BaThemeConfig,
        ...NGA_VALIDATORS,
        ...NGA_SERVICES,
      ],
    };
  }
}
