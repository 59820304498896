import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppTranslationModule } from "app/app.translation.module";
import { GridModule } from "@progress/kendo-angular-grid";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";

import { JobGridComponent } from "./JobList/JobGrid.component";
import { WorkOrderGridComponent } from "app/modules/JobUI/WorkOrder/WorkOrderGrid.component";
import { WorkOrderCardListComponent } from "./WorkOrder/WorkOrderCardList.component";
import { MPBGenericUIModule } from "../MPBGenericUI/MPBGenericUI.module";

@NgModule({
  imports: [
    CommonModule,
    GridModule,
    DateInputsModule,
    AppTranslationModule,
    MPBGenericUIModule,
  ],
  declarations: [
    JobGridComponent,
    WorkOrderCardListComponent,
    WorkOrderGridComponent,
  ],
  exports: [
    JobGridComponent,
    WorkOrderCardListComponent,
    WorkOrderGridComponent,
  ],
})
export class JobUIModule {}
