<div class="estimate-theme mat-dialog mat-dialog--l estimate-list-modal-dialog">
  <mat-dialog-content>
    <h2 class="mat-dialog__header">Estimates for {{ jobName }}</h2>
    <p class="mat-dialog__subtitle">
      There are {{ estimates.length }} estimates logged in the system for this
      prospect. Please select the one you want to work on.
    </p>
    <h3 class="mt-3 btn__add-data" style="padding-bottom: .5em">
      <a (click)="createEstimate()">
        <span>New Estimate</span>
        <i class="svg-icon svg-icon__circle-add svg-icon--action"></i>
      </a>
    </h3>
    <div class="estimate-theme-list__modal">
      <table class="mat-dialog__table--stretch-width">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>Estimate Name</th>
            <th>Project Type</th>
            <th>Last Edited</th>
            <th>Active</th>
          </tr>
        </thead>
        <tbody>
          <tr
            [ngClass]="{
              'active-background':
                estimate.worksheetStatus === worksheetStatusList.Active
            }"
            *ngFor="let estimate of estimates"
          >
            <td>
              <i
                title="Open Estimate"
                class="svg-icon svg-icon__baseline-open_in_new svg-icon--action"
                (click)="openEstimate(estimate)"
              ></i>
            </td>
            <td class="width__2">
              <div
                *ngIf="
                  estimate.worksheetStatus === WorksheetStatusEnum.Active &&
                  (estimate.needsRecalculation ||
                    estimate.isThereNewActivePriceBook)
                "
                (click)="showUpdateRequiredModal(estimate)"
              >
                <a href="javascript:void(0)"
                  ><i
                    class="fa fa-refresh estimate-worksheet__refresh-icon"
                    aria-hidden="true"
                  ></i
                ></a>
              </div>
            </td>
            <td>{{ estimate.estimateName }}</td>
            <td>{{ estimate.projectName }}</td>
            <td>{{ estimate.lastUpdatedOn | date: "MM/dd/yyyy h:mm a" }}</td>
            <td class="text-align-center">
              <i
                *ngIf="estimate.worksheetStatus === worksheetStatusList.Active"
                title="Active"
                class="fa fa-check"
              ></i>
              <!-- <i
                *ngIf="
                  estimate.worksheetStatus === worksheetStatusList.Inactive
                "
                title="Inactive"
                class="fa fa-times"
              ></i> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions
    class="mat-dialog__actions mat-dialog__actions--align-right"
  >
    <button class="button button--secondary" (click)="close()">Cancel</button>
  </mat-dialog-actions>
</div>

<p-dialog
  header="Update Required"
  [(visible)]="openUpdateDialog"
  modal="true"
  styleClass="prime-dialog"
  [style]="{
    minWidth: '500px',
    maxWidth: '500px',
    maxHeight: '70%',
    overflow: 'auto'
  }"
  [autoZIndex]="false"
  [positionTop]="40"
>
  <app-update-calculation-modal
    *ngIf="openUpdateDialog"
    [worksheet]="worksheetForModal"
    (closeEvent)="closeUpdateModal()"
    (updateSuccessEvent)="close()"
  >
  </app-update-calculation-modal>
</p-dialog>
