import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { JobFileModel } from "app/model/jobFile";
import { environment } from "environments/environment";
import { ApiResponseModel } from "app/model/ApiResponseModel";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class SpectrumService {
  private baseUrl = environment.baseEstimateUrl;

  constructor(private httpService: HttpClient) {}

  exportSpectrumFiles(jobId: string): Observable<JobFileModel[]> {
    return this.httpService.post<JobFileModel[]>(
      `${this.baseUrl}spectrum/export-files/${jobId}`,
      null
    );
  }
}
