import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class WeatherReschedulerService {
  private _rescheduledJob: BehaviorSubject<string[]> = new BehaviorSubject<
    string[]
  >([]);
  get rescheduledJob(): Observable<string[]> {
    return this._rescheduledJob.asObservable();
  }
  updateJob(jobsIds: string[]) {
    this._rescheduledJob.next(jobsIds);
  }
  constructor() {}
}
