import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { BaThemeSpinner } from "app/theme/services";
import { MessageService } from "primeng/components/common/messageservice";
import { ContractorService } from "app/common/services/contractor.service";
import { JobPhaseService } from "app/common/services/jobPhase.service";
import { SchedulingRequest } from "app/model/schedulingModel";
import { SharedService } from "app/common/utility/SharedService";
import { DialogService } from "ng2-bootstrap-modal";
import { ConfirmComponent } from "app/component/dialog/dialog.component";
import { RequestForServiceService } from "app/common/services/request-for-service.service";

@Component({
  selector: "job-phase-scheduleing",
  templateUrl: "./jobPhaseScheduleing.html",
  styleUrls: ["./jobPhaseScheduleing.css"],
})
export class JobPhaseSchdeuleingComponent implements OnInit {
  @Input() JobId: string;
  @Input() isBuildPlan: boolean;
  @Input() phase: any;
  @Input() displayFromSidebar: boolean = false;
  @Input() parentIsSecondSidebar: boolean = false;
  @Output() closeSideBar: EventEmitter<any> = new EventEmitter<any>();
  filterQuery: any;
  phaseId: any;
  SubContractorId: any;
  schdeuledContractorJobs: any;
  UseContractorOrg: any;
  contractorName: any;
  contractorNameOrg: any;
  schdeuledDate: Date = null;
  jobName: any = "";
  startDate: Date;
  endDate: Date;
  dateRange = true;
  Comments: string;
  resources: any = [];
  isThirdSidebar: boolean = false;
  constructor(
    private _spinner: BaThemeSpinner,
    private notificationsService: MessageService,
    private contractorService: ContractorService,
    private jobPhaseService: JobPhaseService,
    private sharedService: SharedService,
    private dialogService: DialogService,
    private requestForService: RequestForServiceService
  ) {}

  ngOnInit() {
    this._spinner.show();
    this.GetContractorScheduleJobs(this.phase);
    this.isThirdSidebar = this.displayFromSidebar && this.parentIsSecondSidebar;
  }

  GetContractorScheduleJobs(phase) {
    if (this.phase.Resources) {
      this.resources =
        typeof this.phase.Resources === "string"
          ? JSON.parse(this.phase.Resources)
          : this.phase.Resources;
    }

    if (!this.resources || !this.resources[0]) {
      phase.SubContractorId = 0;
    } else {
      let contr = this.resources.find((x) => x.IsExternal == true);
      if (contr) {
        phase.SubContractorId = contr.Id;
        phase.Contractor = contr.Name;
        phase.UseContractorOrg = contr.IsExternal;
      } else {
        phase.SubContractorId = 0;
      }
    }
    if (phase.Scheduled) {
      this.schdeuledDate = new Date(phase.Scheduled);
    }
    this.phaseId = phase.JobPhaseId ? phase.JobPhaseId : phase.ID;
    this.SubContractorId = phase.SubContractorId;
    this.contractorName = phase.Contractor;
    this.contractorNameOrg = phase.Contractor;
    this.UseContractorOrg = phase.UseContractorOrg;
    this.jobName = phase.JobName;
    this.phase.JobPhaseName = this.phase.JobPhaseName
      ? this.phase.JobPhaseName
      : this.phase.PhaseName;
    this.contractorService
      .getContractorScheduleJobs(phase.SubContractorId, null) //get contractot based on orgId.
      .subscribe((data) => {
        this.schdeuledContractorJobs = data;
        this.schdeuledContractorJobs.forEach((element) => {
          element.IsSelected =
            element.SubContractorId.toUpperCase() === this.SubContractorId;
        });
        this._spinner.hide();
      });
  }
  contractorChange(ph) {
    this.contractorName = ph.ContractorName;
    this.SubContractorId = ph.SubContractorId;
    this.UseContractorOrg = ph.UseContractorOrg;
  }
  getInitals(str) {
    if (!str) return "";
    var result = str
      .split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), "");
    if (result && result.length > 2) {
      return result.slice(0, 2);
    }
    return result;
  }

  private result(res: any, message: string) {
    this.notificationsService.add({
      severity: "success",
      summary: "Schedule",
      detail: message,
    });
  }

  onCancel() {
    this.closeSideBar.emit(false);
  }

  schdeule: any = {};
  updateSchedule(form) {
    this._spinner.show();

    this.schdeule.JobPhaseId = this.phaseId;
    this.schdeule.SubContractorId = this.SubContractorId;
    this.schdeule.UseContractorOrg = this.UseContractorOrg;
    if (this.schdeuledDate) {
      this.schdeuledDate = new Date(this.schdeuledDate);
      this.schdeule.ScheduledDate = new Date(
        Date.UTC(
          this.schdeuledDate.getFullYear(),
          this.schdeuledDate.getMonth(),
          this.schdeuledDate.getDate()
        )
      );
    }
    this.schdeule.ScheduledDate = this.schdeuledDate;

    this.jobPhaseService
      .updatejobPhaseSchedule(this.schdeule)
      .subscribe((data) => {
        this.result(data, "Updated Successfully");
        this.closeSideBar.emit(true);
      });
  }

  sendScheduelRequest() {
    const disposable = this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Send Schedule Request",
        message:
          "Are you sure you want to send schedule request to " +
          this.contractorName +
          "?",
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this._spinner.show();
          let req = new SchedulingRequest();
          req.JobPhaseId = this.phaseId;
          req.PhaseOwnerId = this.SubContractorId;
          req.RequestedById = this.sharedService.loggedInUser.Id;
          req.Comments = this.Comments;
          req.JobId = this.JobId;
          req.DateRange = this.dateRange;
          if (this.dateRange) {
            req.StartDate = new Date(
              Date.UTC(
                this.startDate.getFullYear(),
                this.startDate.getMonth(),
                this.startDate.getDate()
              )
            );
            req.EndDate = new Date(
              Date.UTC(
                this.endDate.getFullYear(),
                this.endDate.getMonth(),
                this.endDate.getDate()
              )
            );
          }
          this.jobPhaseService
            .addDateOfServiceRequest(req)
            .subscribe((data) => {
              this.result(data, "Request Send Successfully");
              this._spinner.hide();
              this.closeSideBar.emit(true);
              this.requestForService.updateAndReset();
            });
        }
      });
  }
}
