import { Directive, ElementRef, Renderer2, AfterViewInit } from "@angular/core";

@Directive({
  selector: "[requiredLabel]",
})
export class RequiredLabelDirective implements AfterViewInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    const label = this.el.nativeElement.querySelector(".k-label");

    if (label) {
      const asterisk = this.renderer.createElement("span");
      this.renderer.setProperty(asterisk, "innerHTML", "*");
      this.renderer.setStyle(asterisk, "color", "red");
      this.renderer.setStyle(asterisk, "margin-left", "4px");
      this.renderer.appendChild(label, asterisk);
    }
  }
}
