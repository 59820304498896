import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import {
  CancelEvent,
  DataStateChangeEvent,
  EditEvent,
  GridComponent,
  GridDataResult,
  RemoveEvent,
  SaveEvent,
} from "@progress/kendo-angular-grid";
import { process, State } from "@progress/kendo-data-query";
import { CommonService } from "app/common/services/common.service";
import { ContractorService } from "app/common/services/contractor.service";
import { DashboardService } from "app/common/services/dashboard.service";
import { EstimateWorksheetService } from "app/common/services/estimate-worksheet.service";
import { JobChangeOrderService } from "app/common/services/jobChangeOrder.service";
import { JobFileService } from "app/common/services/jobFile.service";
import { JobPhaseService } from "app/common/services/jobPhase.service";
import { JobPhotoService } from "app/common/services/jobphoto.service";
import { UpdateJobFilesService } from "app/common/services/update-job-files.service";
import { Helper } from "app/common/utility/helper";
import { UpdateCountService } from "app/common/utility/updateCount.service";
import {
  ConfirmComponent,
  ConfirmModel,
} from "app/component/dialog/dialog.component";
import { UpdateCountType } from "app/model/Core/JobFileModel";
import { fileTypeModel } from "app/model/FileTypeModel";
import { JobFileModel } from "app/model/jobFile";
import { BaThemeSpinner } from "app/theme/services";
import { DialogService } from "ng2-bootstrap-modal";
import { MessageService } from "primeng/components/common/messageservice";
import { Menu } from "primeng/primeng";
import { Subject } from "rxjs";
import { debounceTime, takeWhile } from "rxjs/operators";
import { JobPhotoModel } from "../../model/jobPhotoModel";
import { DocumentService } from "app/common/services/document.service";

@Component({
  selector: "app-jobfile-info-card",
  templateUrl: "./jobfile-info-card.component.html",
  styleUrls: ["./jobfile-info-card.component.scss"],
})
export class JobfileInfoCardComponent implements OnInit, OnDestroy {
  @ViewChild("divOne", { static: false }) div: ElementRef;
  private _jobId: string;
  @Input() set JobId(value: string) {
    this._jobId = value;
  }
  get JobId(): string {
    return this._jobId;
  }
  @Output() refreshPinnedfiles: EventEmitter<any> = new EventEmitter<any>();
  @Output() addFile: EventEmitter<void> = new EventEmitter<void>();
  @Output() filesCount: EventEmitter<number> = new EventEmitter<number>();

  @Input() changeOrderId: number;
  @Input() changeOrder: any;
  @Input() isPhase: boolean;
  @Input() jobPhaseId: number;
  @Input() isPunchlist: boolean;
  @Input() headerText: string;
  @Input() isVendor: boolean = false;
  @Input() vendorId: number;
  @Input() listenToChanges: boolean = false;
  //grid
  @Input() includePagination: boolean = true;
  @Input() pageSize: number = 100;

  //columns
  @Input() showCreatedOnColumn: boolean = true;
  @Input() showFileTypeColumn: boolean = true;
  @Input() showPhaseColumn: boolean = false;
  @Input() showPinColumn: boolean = false;

  //headers
  @Input() hideGridHeader: boolean = false;
  @Input() showFullHeader: boolean = true;

  //sidebars
  @Input() showMiddleBar: boolean;
  @Input() useOwnAddSidebar: boolean;

  //styling
  @Input() gridStyle: Object;

  private readonly onFilesDeleteSuccessMsg: string =
    "Files Deleted Successfully";
  private readonly errorMsg: string = "An error has ocurred";

  private alive: boolean = true;
  files: JobFileModel[] = [];
  filesVendor: [] = [];
  visibleSidebar: boolean = false;
  selectAll: boolean = false;
  selectedFile: JobFileModel;
  displayMiddleBar = false;
  dataFromUrl: boolean = false;
  formGroup: FormGroup;
  isediting: boolean = false;
  opacityIcon: boolean = true;

  private isForChangeOrder: boolean = false;
  private editedRowIndex: number;
  private currentSender: GridComponent;
  state: State = {
    skip: 0,
    take: this.pageSize,
    filter: {
      logic: "and",
      filters: [],
    },
  };
  gridData: GridDataResult;
  searchTextChanged = new Subject();
  searchText: string = "";
  fileTypes: fileTypeModel[];

  constructor(
    private jobFileService: JobFileService,
    private jobPhotoService: JobPhotoService,
    private _spinner: BaThemeSpinner,
    private dashboardService: DashboardService,
    private dialogService: DialogService,
    private notificationsService: MessageService,
    private jobChangeOrderService: JobChangeOrderService,
    private jobPhaseService: JobPhaseService,
    private router: ActivatedRoute,
    private contractorService: ContractorService,
    private updateCountService: UpdateCountService,
    private updateFilesService: UpdateJobFilesService,
    private commonService: CommonService,
    private downloadFilesService: EstimateWorksheetService,
    private documentService: DocumentService
  ) {
    this.searchTextChanged.pipe(debounceTime(1000)).subscribe(() => {
      this.reloadFiles();
    });
  }

  ngOnInit() {
    this.getFileType();
    if (this.router.snapshot.data.parentHasJobGuid) {
      this.handleFromUrl();
      return;
    }
    this.handleRequest();
    if (this.listenToChanges) {
      this.updateFilesService.changeHasOcurred
        .pipe(takeWhile(() => this.alive))
        .subscribe((data) => {
          if (data) this.getFileslistByJobID();
        });
    }
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  /** public method to change the job id */
  changeJobId(jobId: string) {
    this.JobId = jobId;
    this.getFileslistByJobID();
  }

  /** Handles pinning and unpinning a file */
  onFilePin(file: JobFileModel) {
    this.dialogService
      .addDialog(ConfirmComponent, this.pinDialogData(file.IsPinned))
      .subscribe(
        (isConfirmed) => {
          if (isConfirmed) {
            this._spinner.show();
            this.dashboardService
              .UpdateFilePinnedState(file.ID, !file.IsPinned)
              .subscribe((data) => {
                this.getFileslistByJobID();
                this.refreshPinnedfiles.emit();
              });
          }
        },
        (error) => this.result(this.errorMsg, true)
      )
      .add(() => this._spinner.hide());
  }

  /** Handles deleting a file */
  deleteJobFile({ dataItem, sender }: RemoveEvent) {
    this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Delete File",
        message: "Are you sure you want to delete " + dataItem.FileName + "?",
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          if (this.currentSender) this.closeEditor(this.currentSender);
          if (this.isForChangeOrder) {
            this.deleteChangeOrderFile(dataItem.ID);
            return;
          }
          this.deleteFile(dataItem.ID, dataItem.IsPinned);
        }
      });
  }

  /** Triggers request to delete a change order file */
  private deleteChangeOrderFile(id: number) {
    this.jobChangeOrderService
      .deleteChangeOrderFile(id)
      .subscribe(
        (res) => {
          this.onDeleteSuccess();
        },
        (error) => this.result(this.errorMsg, true)
      )
      .add(() => this._spinner.hide());
  }

  /** Triggers request to delete a file */
  private deleteFile(id: number, isPinned: boolean) {
    this.jobFileService
      .deletefilePhoto(id)
      .subscribe(
        (res) => {
          this.onDeleteSuccess();
          if (isPinned) {
            this.refreshPinnedfiles.emit();
          }
        },
        (error) => this.result(this.errorMsg, true)
      )
      .add(() => this._spinner.hide());
  }

  /** Triggers request to delete a file */
  deleteVendorFile() {
    let message = "Are you sure you want to delete ";
    let filesSelected = this.files.filter((x) => x.selected);
    if (filesSelected != null) {
      let filesSelectedIds = filesSelected.map((x) => x.ID);
      if (filesSelected.length > 1) {
        message = message + filesSelected.length + " files ?";
      } else {
        message = message + filesSelected[0].FileName + "?";
      }
      this.dialogService
        .addDialog(ConfirmComponent, {
          title: "Delete File",
          message: message,
        })
        .subscribe((isConfirmed) => {
          if (isConfirmed) {
            this.contractorService
              .deleteContractorFile(filesSelectedIds)
              .subscribe(
                (res) => {
                  this.onDeleteSuccess();
                },
                (error) => this.result(this.errorMsg, true)
              )
              .add(() => this._spinner.hide());
          }
        });
    }
  }

  /** Triggers request to delete a file */
  deleteAllVendorFile() {
    let message = "Are you sure you want to delete all files for this vendor?";
    this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Delete File",
        message: message,
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          let allFilesId = this.files.map((x) => x.ID);
          this.contractorService
            .deleteContractorFile(allFilesId)
            .subscribe(
              (res) => {
                this.onDeleteSuccess();
              },
              (error) => this.result(this.errorMsg, true)
            )
            .add(() => this._spinner.hide());
        }
      });
    /* }  */
  }

  /** Triggers a notificationi */
  private result(message: string, isError: boolean = false) {
    this.notificationsService.add({
      severity: isError ? "error" : "success",
      summary: "Files",
      detail: message,
    });
  }

  /** Handles hiding the slide action */
  onHideSliderHide(e: string) {
    if (e == "true") {
      this.getFileslistByJobID();
    }
    this.visibleSidebar = false;
  }

  /** Handles selecting and de-selecting files */
  selectDeselectAllPhotos(isAll: boolean) {
    let selectedItems = this.files.filter((x) => x.selected);
    selectedItems.length > 0
      ? (this.opacityIcon = false)
      : (this.opacityIcon = true);
    if (isAll) {
      this.files.forEach((file) => {
        file.selected = this.selectAll;
      });
    }
  }

  /** Deletes selected files */
  deleteJobFiles() {
    const selectedPhotosIds = this.getSelectedFilesIds(this.files);
    if (selectedPhotosIds.length) {
      this.dialogService
        .addDialog(ConfirmComponent, {
          title: "Delete Files",
          message:
            "Are you sure you want to delete " +
            selectedPhotosIds.length +
            (selectedPhotosIds.length == 1 ? " File?" : " Files?"),
        })
        .subscribe((isConfirmed) => {
          if (isConfirmed) {
            this.handleDeleteRequest(selectedPhotosIds);
          }
        });
    }
  }

  /** Deletes change order files */
  private deleteChangeOrderFiles(selectedFilesIds: number[]) {
    this.jobChangeOrderService
      .deleteChangeOrderFiles(selectedFilesIds)
      .subscribe(
        (res) => {
          this.result(this.onFilesDeleteSuccessMsg);
          this.handleRequest();
        },
        (error) => this.result(this.errorMsg, true)
      )
      .add(() => this._spinner.hide());
  }

  /** Deletes jobs files */
  private deleteFiles(selectedFilesIds: number[]) {
    this.jobPhotoService
      .deleteSelectedJobPhoto(selectedFilesIds)
      .subscribe(
        (res) => {
          this.result(this.onFilesDeleteSuccessMsg);
          this.handleRequest();
        },
        (error) => this.result(this.errorMsg, true)
      )
      .add(() => this._spinner.hide());
  }

  /** Handles the 3-dot menu */
  handleMenu(file: JobFileModel, menu: Menu, ev: PointerEvent) {
    this.selectedFile = file;
    menu.toggle(ev);
  }

  /** View a file */
  view(file: JobFileModel) {
    this.documentService.openFile(file.BlobUrl);
  }

  handleEdit(ev: EditEvent) {
    const { dataItem } = ev;
    this.currentSender = ev.sender;
    this.closeEditor(ev.sender);

    this.formGroup = new FormGroup({
      FileName: new FormControl(
        dataItem.FileName.replace(/\..+$/, ""),
        Validators.required
      ),
      FileTypeName: new FormControl(dataItem.JobFileTypeId),
      extension: new FormControl(Helper.getFileExtension(dataItem.FileName)),
    });

    this.editedRowIndex = ev.rowIndex;
    ev.sender.editRow(ev.rowIndex, this.formGroup);
    this.isediting = true;
  }

  /** Handles the patch name request */
  handlePatchName(newName: string, fileTypeId: number) {
    if (this.isForChangeOrder) {
      this.patchChangeOrderFileName(newName);
      return;
    }

    this.patchJobFileName(newName, fileTypeId);
  }

  handleFilter(state: DataStateChangeEvent) {
    this.state = state;
    this.gridData = process(this.files, this.state);
  }

  clearFilter() {
    this.state.filter = {
      logic: "and",
      filters: [],
    };

    this.handleFilter(this.state as DataStateChangeEvent);
  }

  /** Patches a job file name */
  private patchJobFileName(
    newName: string,
    fileTypeId: number,
    sender: GridComponent = this.currentSender
  ) {
    this._spinner.show();
    this.jobFileService
      .patchJobFileName(this.selectedFile.ID, newName, fileTypeId)
      .subscribe(
        (data) => {
          this.finnishPatch(data, fileTypeId);
        },
        (error) => {
          this.result(this.errorMsg, true);
          this.closeEditor(sender);
        }
      )
      .add(() => this._spinner.hide());
  }

  /** Patches a changer order file name */
  private patchChangeOrderFileName(
    newName: string,
    sender: GridComponent = this.currentSender
  ) {
    this._spinner.show();
    this.jobFileService
      .patchChangeOrderFileName(this.selectedFile.ID, newName)
      .subscribe(
        (data) => {
          this.finnishPatch(data);
        },
        (error) => {
          this.result(this.errorMsg, true);
          this.closeEditor(sender);
        }
      )
      .add(() => this._spinner.hide());
  }

  /** Logic to open the correct sidebar */
  handleSidebar() {
    if (this.useOwnAddSidebar) {
      if (this.showMiddleBar) {
        this.displayMiddleBar = true;
        return;
      }
      this.visibleSidebar = true;
      return;
    }

    this.addFile.emit();
  }

  /** Logic to call the right request */
  handleRequest(closeSidebar: boolean = false) {
    if (closeSidebar) {
      this.displayMiddleBar = false;
      this.visibleSidebar = false;
    }
    setTimeout(() => {
      if (this.isPhase && this.jobPhaseId !== 0) {
        this.getFilesListByPhase();
        this.updateFilesService.notifyChangeHasOcurred(true);
        return;
      }

      if (this.JobId) {
        this.getFileslistByJobID();
        return;
      }

      if (this.changeOrderId) {
        this.isForChangeOrder = true;
        this.getFileslistByChangeOrderId();
      }

      if (this.isVendor && this.vendorId !== 0) {
        this.getFilesListByVendorId();
      }
    }, 2000);
  }

  /** Get files by changer order id */
  private getFileslistByChangeOrderId() {
    this._spinner.show();
    this.jobChangeOrderService
      .getChangeOrderFile(this.changeOrderId, 1, 2000, this.searchText)
      .subscribe(
        (data) => {
          this.setDateStringToDate(data.items);
          this.files = data.items;
          this.gridData = process(this.files, this.state);
          this.onRequestSuccess(this.changeOrderId);
        },
        (error) => this.result(this.errorMsg, true)
      )
      .add(() => this._spinner.hide());
  }

  /** Get files by job phase id */
  private getFilesListByPhase() {
    this.jobPhaseService
      .getFilesByJobPhaseId(this.jobPhaseId, 1, 2000, this.searchText)
      .subscribe(
        (data) => {
          this.setDateStringToDate(data.items);
          this.files = this.orderByPinned(data.items);
          this.gridData = process(this.files, this.state);
          this.onRequestSuccess(this.jobPhaseId);
        },
        (error) => this.result(this.errorMsg, true)
      )
      .add(() => this._spinner.hide());
  }

  /** Get files by job id */
  private getFileslistByJobID() {
    this._spinner.show();
    this.visibleSidebar = false;
    this.displayMiddleBar = false;
    this.jobFileService
      .getJobfilesByJobID(this.JobId, 1, 2000, this.searchText)
      .subscribe(
        (data) => {
          this.setDateStringToDate(data.items);
          data.items.sort((a, b) => (a.FileName > b.FileName ? 1 : -1));
          this.files = this.orderByPinned(data.items);
          this.gridData = process(this.files, this.state);
          this.onRequestSuccess(this.JobId);
        },
        (error) => this.result(this.errorMsg, true)
      )
      .add(() => this._spinner.hide());
  }

  private getFilesListByVendorId() {
    this._spinner.show();
    this.contractorService
      .getContractorFilesByVendorId(this.vendorId)
      .subscribe(
        (data) => {
          this.setDateStringToDateVendor(data);
          this.files = data;
          this.gridData = process(this.files, this.state);
          this.onRequestSuccess(this.vendorId);

          this._spinner.hide();
        },
        (error) => this.result(this.errorMsg, true)
      );
  }

  /** Handles the component when data comes from the url */
  private handleFromUrl() {
    this.dataFromUrl = true;
    this.showPhaseColumn = true;
    this.gridStyle = {
      height: "41rem",
    };
    this.router.parent.params
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => {
        this.JobId = data.id;
        this.handleRequest();
        this.useOwnAddSidebar = true;
      });
  }
  /** Closes editor */
  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    if (grid) {
      grid.closeRow(rowIndex);
      grid.rowClass;
    }
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }
  /** Handles saving an edit event */
  saveHandler(ev: SaveEvent) {
    this.selectedFile = ev.dataItem;
    const fileName =
      ev.formGroup.value.FileName + "." + ev.formGroup.value.extension;
    this.currentSender = ev.sender;
    const fileTypeId = ev.formGroup.value.FileTypeName;
    this.handlePatchName(fileName, fileTypeId);
  }
  /** Cancels editing */
  cancelHandler(args: CancelEvent): void {
    args.sender.closeRow(args.rowIndex);
    this.isediting = false;
  }

  /** Logic to call endpoint to delete files */
  private handleDeleteRequest(selectedPhotosIds: number[]) {
    this._spinner.show();
    if (this.currentSender) {
      this.closeEditor(this.currentSender);
      this.isediting = false;
    }

    this.isForChangeOrder
      ? this.deleteChangeOrderFiles(selectedPhotosIds)
      : this.deleteFiles(selectedPhotosIds);
  }
  /** Handles a successfull delete */
  private onDeleteSuccess(): void {
    this.editedRowIndex = null;
    this.result(this.onFilesDeleteSuccessMsg);
    this.handleRequest();
    this.updateFilesService.notifyChangeHasOcurred(true);
  }
  /** Handles a successfull patch */
  private finnishPatch(data: JobFileModel, fileTypeId: number = 0) {
    const editedFile = this.files.find((x) => x.ID === data.ID);
    let fileTypeName: string = "";
    if (fileTypeId !== 0) {
      const fileType = this.fileTypes.find((x) => x.Id === fileTypeId);
      if (fileType) {
        fileTypeName = fileType.FileTypeName;
      }
    }

    if (editedFile !== undefined) {
      editedFile.FileName = data.FileName;
      editedFile.FileTypeName = fileTypeName;
    }

    this.closeEditor(this.currentSender);
    this.isediting = false;
    this.selectedFile = null;
    this.currentSender = null;
    this.getFileslistByJobID();
  }

  /** Handles data when the request is successfull */
  private onRequestSuccess(id: number | string) {
    this.filesCount.emit(this.files.length);
    this.updateCountService.updateCount({
      entityId: id,
      countType: UpdateCountType.Files,
      count: this.files.length,
    });
  }

  /** Order by pinned first */
  private orderByPinned(files: JobFileModel[]): JobFileModel[] {
    return [...files].sort((a, b) => Number(b.IsPinned) - Number(a.IsPinned));
  }

  /** Returns ids of the files where selected option is true */
  private getSelectedFilesIds(files: JobFileModel[]): number[] {
    return files.filter((x) => x.selected).map((x) => x.ID);
  }

  /** Logic of texts of the pin/unpin dialog */
  private pinDialogData(isPinned: boolean): ConfirmModel {
    const pinUnpin = isPinned ? "unpin" : "pin";
    return {
      message: `Do you want to ${pinUnpin} this file `,
      title: `${Helper.capitalizeString(pinUnpin)} File`,
    };
  }

  /** Iterates over a set of files and transforms it's date from string to date data type */
  private setDateStringToDate(data: JobPhotoModel[]) {
    //necessary for the date filter to work
    data.forEach((f) => (f.CreatedOn = new Date(`${f.CreatedOn}Z`)));
  }

  /** Iterates over a set of files and transforms it's date from string to date data type */
  private setDateStringToDateVendor(data) {
    //necessary for the date filter to work
    data.forEach((f) => (f.CreatedOn = new Date(f.CreatedOn)));
  }

  /**
   * triggers the searchTextChanged to search files
   */
  searchTextChange(): void {
    this.searchTextChanged.next();
  }

  /**
   * reload the files after the search trigger
   */
  reloadFiles(): void {
    if (this.isPhase) {
      this.getFilesListByPhase();
      return;
    }
    if (this.isForChangeOrder) {
      this.getFileslistByChangeOrderId();
      return;
    }
    this.getFileslistByJobID();
  }

  /**
   * get file types from backend
   */
  private getFileType(): void {
    this.commonService.getFileTypeByOrgId().subscribe((data) => {
      this.fileTypes = data;
    });
  }

  private getSelectedFilesURLs(files: JobFileModel[]): string[] {
    return files.filter((x) => x.selected).map((x) => x.BlobUrl);
  }

  /**
   * download zipped files
   */
  downloadJobFiles(): void {
    const selectedPhotosURLs: string[] = this.getSelectedFilesURLs(this.files);
    if (selectedPhotosURLs.length == 0) return;
    this._spinner.show();
    this.downloadFilesService.downloadFiles(selectedPhotosURLs).subscribe(
      (response: any) => {
        this._spinner.hide();
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute("download", "files.zip");
        document.body.appendChild(downloadLink);
        downloadLink.click();
      },
      (error) => {
        this.result(this.errorMsg, true);
        this._spinner.hide();
      }
    );
  }
}
