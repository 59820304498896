import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { EstimateProductService } from "app/common/services/estimate-products.service";
import { SharedService } from "app/common/utility/SharedService";
import { EstimateProductModel } from "app/model/EstimateProductModel";
import { EstimateWorksheetProduct } from "app/model/EstimateWorksheetProduct";
import { ProductOption } from "app/model/ProductOptionModel";
import { WorksheetDetailChange } from "app/model/WorksheetDetailChange";
import { BaThemeSpinner } from "app/theme";
import { ToastrService } from "ngx-toastr";
import { SelectItem } from "primeng/primeng";
import { WorksheetWorkAreaParameter } from "app/model/WorksheetWorkAreaParameter";
import { KitDetailsService } from "app/common/services/kitdetails.service";
import { KitWorksheetAttachabilityResponse } from "app/model/KitWorksheetAttachabilityResponse";
import { MaterialCalculatorService } from "app/common/services/material-calculator.service";
import {
  buildFormulaWithParameterNames,
  getErrorMessageForInifinityResult,
} from "app/common/utility/calculator-utilities";
import { Helper } from "app/common/utility/helper";

@Component({
  selector: "app-product-selection-modal",
  templateUrl: "./product-selection-modal.component.html",
  styleUrls: ["./product-selection-modal.scss"],
})
export class ProductSelectionModalComponent implements OnInit, OnChanges {
  productOptions: ProductOption[];
  productForm: FormGroup;
  productOptionsSelectItems: SelectItem[];
  price: number = 0;
  hasProductOptions: boolean = false;

  displayPrice: number;

  @Input()
  product: EstimateProductModel;

  @Input()
  estimateProduct: EstimateWorksheetProduct;

  @Input()
  hideOptions: boolean;

  @Output()
  handleDisplay = new EventEmitter<boolean>();

  @Input()
  isEdit: boolean;

  @Input()
  workAreaParameters: WorksheetWorkAreaParameter[][];

  @Input()
  worksheetId: number;

  @Output()
  selectedProduct: EventEmitter<EstimateWorksheetProduct[]> = new EventEmitter<
    EstimateWorksheetProduct[]
  >();

  Title: string;
  useCalculator: boolean;
  attachableToWorksheet: boolean;
  calculatorFormula: string;
  productQty: number;
  calculatorId: number = null;
  calculatorName: string = null;
  constructor(
    private formBuilder: FormBuilder,
    private changeRef: ChangeDetectorRef,
    private estimateProductService: EstimateProductService,
    private _spinner: BaThemeSpinner,
    private toastrService: ToastrService,
    private _sharedService: SharedService,
    private kitService: KitDetailsService,
    private calculatorService: MaterialCalculatorService
  ) {}

  ngOnChanges() {
    if (this.product) {
      this.setLabel();
      this.productOptions = this.product.productOptions;
      this.productOptionsSelectItems = this.productOptions.map((val) => ({
        label: val.productName,
        value: val,
      }));
      this.productOptionsSelectItems.unshift({
        label: "Select an option",
        value: null,
      });
    }
  }

  ngOnInit(): void {
    this.product = this.product || this.estimateProduct.product;
    this.price = this.product.priceBookPrice;
    this.setLabel();
    this.productQty = this.estimateProduct ? this.estimateProduct.qty : 1;
    this.initForm();
    this.configProductOption();
    this.changeRef.detectChanges();
    this.itemUsesCalculator();
    this.attachableToWorksheet = true;
    if (this.worksheetId && this.useCalculator && !this.isEdit) {
      this.getEligibilityForWorksheet();
    }
  }

  private initForm() {
    this.productForm = this.formBuilder.group({
      qtyControl: [this.productQty, [Validators.required, Validators.min(1)]],
      productOptionsControl: [],
    });
  }

  private configProductOption() {
    this.productOptions = this.product.productOptions;
    this.productOptionsSelectItems = this.productOptions.map((val) => ({
      label: val.productName,
      value: val,
    }));
    this.productOptionsSelectItems.unshift({
      label: "Select an option",
      value: null,
    });

    if (
      this.productOptions &&
      this.productOptions.length > 0 &&
      this.requiredSelection()
    ) {
      this.productForm
        .get("productOptionsControl")
        .setValidators(Validators.required);
    }

    let optionSelected = this.product.productOptions.find(
      (x) => x.productId == this.product.productOptionId
    );

    this.productForm.get("productOptionsControl").setValue(optionSelected);

    this.hasProductOptions =
      this.productOptions && this.productOptions.length > 0;
  }

  /** get the actual price of the selected product */
  private getProductRealPrice() {
    this.estimateProductService
      .getProductRealPrice(this.product.id)
      .subscribe(
        (data) => {
          if (data) {
            this.product.activePrice = data.priceBookPrice;
          }
        },
        (error) => {
          this.error(error, "Error queryning product real price");
        }
      )
      .add(() => this._spinner.hide());
  }

  private constructWorkSheetProduct(qty: number) {
    let worksheetProduct = new EstimateWorksheetProduct();

    worksheetProduct.product = this.product;
    worksheetProduct.unitPrice = this.price;
    worksheetProduct.id = this.estimateProduct ? this.estimateProduct.id : null;
    worksheetProduct.qty = qty;
    worksheetProduct.unitQty = qty;
    worksheetProduct.productOption = this.productForm.controls.productOptionsControl.value;
    worksheetProduct.requiresUserSelection = this.requiresUserSelection;
    worksheetProduct.materialsCalculatorId = this.calculatorId;
    worksheetProduct.totalCost = this.price * qty;
    if (worksheetProduct.productOption) {
      worksheetProduct.productOptionId = worksheetProduct.productOption.id;
    }

    if (this.isEdit) {
      const initialQty = this.estimateProduct.qty || 1;
      worksheetProduct.previousQty = initialQty;
      worksheetProduct.worksheetDetailChange = {
        id: this.estimateProduct.worksheetDetailChange.id || 0,
        previousQty: initialQty,
        newQty: qty,
        createdBy: this._sharedService.user.fullName,
        worksheetDetailId: this.estimateProduct.id,
      } as WorksheetDetailChange;
    }

    return worksheetProduct;
  }

  requiredSelection(): boolean {
    return (
      this.product.productEstimateExt.requiresUserSelection &&
      !this.product.productOptionId
    );
  }

  get requiresUserSelection() {
    return (
      this.hasProductOptions &&
      !this.productForm.get("productOptionsControl").value
    );
  }

  selectProduct(): void {
    let qty: number = Helper.truncateNumber(
      this.productForm.get("qtyControl").value,
      6
    );
    this.buildAndEmitWorksheetProduct(qty);
    this.handleDisplay.emit(false);
  }

  private buildAndEmitWorksheetProduct(qty: number): void {
    let worksheetProduct = this.constructWorkSheetProduct(qty);
    this.selectedProduct.emit([worksheetProduct]);
  }

  /** change the price when you select product option */
  changePrice(e): void {
    if (!e.value) {
      this.price = this.product.usePriceBookPricing
        ? this.product.activePrice
        : this.product.price;
    } else {
      this.price = this.productForm.get("productOptionsControl").value.price;
    }
  }

  hideSidebar() {
    this.handleDisplay.emit(false);
  }

  /**
   * sets label text depending on process
   */
  private setLabel() {
    this.isEdit
      ? (this.Title = `Edit ${this.product.productType.name}`)
      : (this.Title = `Add ${this.product.productType.name}`);
  }
  error(error: any, message: string) {
    console.log(error);
    this.toastrService.error(message, "Products");
  }

  private itemUsesCalculator(): void {
    this.useCalculator = !!(
      this.product.productEstimateExt &&
      this.product.productEstimateExt.useCalculator
    );
  }

  private getEligibilityForWorksheet() {
    this._spinner.show();
    const {
      productEstimateExt: { materialsCalculatorId },
    } = this.product;
    this.kitService
      .checkKitWorksheetAvailability(
        [materialsCalculatorId],
        this.worksheetId,
        true
      )
      .subscribe(
        (data: KitWorksheetAttachabilityResponse) => {
          this.attachableToWorksheet = data.attachableToWorksheet;
          if (this.attachableToWorksheet) {
            this.calculatorFormula = data.calculatorFormula;
            this.calculatorId = data.materialsCalculatorId;
            this.calculatorName = data.materialsCalculatorName;
          } else {
            this.displayUnavailableProductMessage(data);
          }
        },
        (error) =>
          this.toastrService.error(
            "Error defining eligibility of product",
            "Product Eligibility Validation"
          )
      )
      .add(() => this._spinner.hide());
  }

  private displayUnavailableProductMessage(
    response: KitWorksheetAttachabilityResponse
  ) {
    const missingParams = response.missingParameters
      .map((x) => x.parameterName)
      .join(", ");
    const missingParamsQty = response.missingParameters.length > 1;
    this.toastrService.warning(
      `The following parameter${
        missingParamsQty ? "s are" : " is"
      } missing: ${missingParams}.`,
      "Product can't be attached to Worksheet"
    );
  }
}
