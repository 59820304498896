import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { JobNoteService } from "../../common/services/jobNotes.service";
import { JobNoteModel } from "../../model/jobNoteModel";
import { BaThemeSpinner } from "../../theme/services";
import { MessageService } from "primeng/components/common/messageservice";
import { SharedService } from "../../common/utility/SharedService";
import { JobPhaseService } from "../../common/services/jobPhase.service";
import { Helper } from "app/common/utility/helper";
import { GuidMatcherService } from "app/common/utility/guid-matcher.service";
import { EntityCache } from "app/common/utility/EntityCache";
import { ToastrService } from "ngx-toastr";
import { EditorComponent } from "@tinymce/tinymce-angular";
import { TextEditorConfigService } from "../../common/services/textEditorConfig.service";

@Component({
  selector: "app-job-notes-info-card",
  templateUrl: "./job-notes-info-card.component.html",
  styleUrls: ["./job-notes-info-card.component.scss"],
  providers: [GuidMatcherService],
})
export class JobNotesInfoCardComponent implements OnInit {
  @Input() JobId: any;
  @Input() JobName: string;

  get lstNotes() {
    return this.notesCache.items();
  }

  notesCache = new EntityCache<JobNoteModel>();

  jobNote: JobNoteModel = new JobNoteModel();
  loggedInUser: string;
  jobNameDisplay: string;
  editorConfig: EditorComponent["init"];
  @Input() IsDashboard: boolean = false;
  @Input() IsHeaderEnable: boolean = true;
  @Input() JobPhaseId: number;
  @Input() isSales: boolean;
  @Input() isPhase = false;
  @Output() notesRetrieved = new EventEmitter<JobNoteModel[]>();
  @Output() updateNoteCount = new EventEmitter<any>();

  constructor(
    private jobNoteService: JobNoteService,
    private _spinner: BaThemeSpinner,
    private notificationsService: MessageService,
    private jobPhaseService: JobPhaseService,
    private sharedService: SharedService,
    private guidMatcher: GuidMatcherService<JobNoteModel>,
    private toastr: ToastrService,
    private textEditorConfig: TextEditorConfigService
  ) {}

  ngOnInit() {
    this.editorConfig = { ...this.textEditorConfig.editorConfig, height: 250 };
    try {
      this.loggedInUser =
        this.sharedService.loggedInUser.FirstName +
        " " +
        (this.sharedService.loggedInUser.LastName
          ? this.sharedService.loggedInUser.LastName
          : "");
      this.jobNameDisplay = !!this.JobName ? ` - ${this.JobName}` : "";
    } catch {}
    if (this.JobId) {
      this._spinner.show();
      this.changeJobId(this.JobId);
    }
    if (this.isSales == null) this.isSales = false;
  }

  get isThisForPhase() {
    return this.isPhase || this.JobPhaseId > 0;
  }

  get isThisForNewPhase() {
    return this.isPhase && !this.JobPhaseId;
  }

  async changeJobId(jobId) {
    this.JobId = jobId;
    await this.getNotes();
    this.updateNoteCount.emit(this.lstNotes.length);
  }
  getNotes() {
    return new Promise<JobNoteModel[]>((resolve) => {
      if (this.isThisForPhase && this.JobPhaseId && !this.isSales) {
        this.jobPhaseService
          .getJobPhaseNotes(this.JobPhaseId)
          .subscribe((data: JobNoteModel[]) => {
            this.notesCache.clear();
            if (data) {
              this.notesCache.addItems(data);
            }
            this.notesRetrieved.emit(data);
            this._spinner.hide();
            resolve(this.lstNotes);
          });
      } else {
        this.jobNoteService.getJobNotesByJobID(this.JobId).subscribe((data) => {
          let list = data;
          if (this.isThisForNewPhase) {
            list = this.guidMatcher.matchGuids(list);
          } else {
            this.notesCache.clear();
          }
          this.notesCache.addItems(list);
          this._spinner.hide();
          this.notesRetrieved.emit(this.lstNotes);
          resolve(this.lstNotes);
        });
      }
    });
  }
  getInitals(str: string) {
    return Helper.getInitials(str);
  }
  onSubmit(form: any) {
    if (!form.valid) {
      return false;
    }
    if (this.jobNote && this.JobId == 0) {
      this.notificationsService.add({
        severity: "error",
        summary: "Note",
        detail: "Please! You must select a Job first to add a note.",
      });
      return false;
    }
    this._spinner.show();
    const guid = Helper.uuidv4();
    this.jobNote.JobGUID = this.JobId;
    this.jobNote.NoteType = "1";
    this.jobNote.Guid = guid;
    if (this.JobPhaseId) {
      this.jobNote.JobPhaseId = this.JobPhaseId;
    }
    this.jobNoteService.saveJobNote(this.jobNote).subscribe(
      async (result) => {
        form.reset();
        // TODO: high z index for this toaster
        this.result(result, "Added Successfully!!");
        this.guidMatcher.addGuid(guid);
        await this.getNotes();
        this.updateNoteCount.emit(this.lstNotes.length);
        if (this.isThisForNewPhase) {
          const note = this.notesCache.get(guid);
          this.jobNoteService.deleteJobNote(note.ID).subscribe(async () => {
            await this.getNotes();
            this.updateNoteCount.emit(this.lstNotes.length);
          });
        }
        this.jobNote = new JobNoteModel();
      },
      (error) => this.error(error, "Unable to save Note")
    );
  }
  private error(error: any, message) {
    switch (error.status) {
      case 409:
        // this.notificationsService.add({
        //   severity: "warn",
        //   summary: "Note",
        //   detail: message,
        // });
        this.toastr.error(message, "Note");
        break;
      default:
        break;
    }
    this._spinner.hide();
  }
  deleteJobNote(guid: string) {
    this.notesCache.delete(guid);
    this.getNotes();
    this.updateNoteCount.emit(this.lstNotes.length);
  }

  private result(res: any, message: string) {
    // --> old version
    // this.notificationsService.add({
    //   severity: "success",
    //   summary: "Note",
    //   detail: message,
    // });
    // --> new version
    this.toastr.success(message, "Note");
    this._spinner.hide();
  }
}
