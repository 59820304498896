import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "[decimalFormat]",
})
export class DecimalFormatDirective {
  @Input("decimals") decimals: number = 0;
  @Input("negative") negative: boolean;
  @Input("infinityDecimals") infinityDecimals: boolean;
  @Input("maxSize") maxSize: number = 0;
  @Input("allowZero") allowZero: boolean = true;
  @Input("enableIntWithMoreThan3Digits")
  enableIntWithMoreThan3Digits: boolean = false;

  private checkAllowNegative(value: string) {
    if (this.decimals <= 0) {
      return String(value).match(new RegExp(/^-?\d+$/));
    } else {
      var regExpString =
        "^-?\\s*((\\d+(\\.\\d{0," +
        this.decimals +
        "})?)|((\\d*(\\.\\d{1," +
        this.decimals +
        "}))))\\s*$";
      return String(value).match(new RegExp(regExpString));
    }
  }

  private check(value: string) {
    if (this.decimals <= 0) {
      return String(value).match(new RegExp(/^\d+$/));
    } else {
      var regExpString =
        "^\\s*((\\d+(\\.\\d{0," +
        this.decimals +
        "})?)|((\\d*(\\.\\d{1," +
        this.decimals +
        "}))))\\s*$";
      return String(value).match(new RegExp(regExpString));
    }
  }

  public validateValue(value: string): boolean {
    if (this.negative) {
      return this.checkAllowNegative(value) !== null;
    } else {
      return this.check(value) !== null;
    }
  }

  private checkIfIsNumeric(value: string) {
    return String(value).match(new RegExp(/^-?\d+$/));
  }

  /**
   * Used for non infinete decimal values
   * @param oldValue
   * @param event
   */
  private run(oldValue: string, event: KeyboardEvent | ClipboardEvent) {
    setTimeout(() => {
      let currentValue: string = this.el.nativeElement.value;

      if (!this.allowZero && parseInt(currentValue) == 0) {
        this.control.control.setValue(oldValue);
        return (this.el.nativeElement.value = oldValue);
      }

      let intSize = Math.trunc(Math.abs(parseInt(currentValue))).toString()
        .length;
      if (intSize > this.maxSize && this.maxSize > 0) {
        this.control.control.setValue(oldValue);
        return (this.el.nativeElement.value = oldValue);
      }

      let allowNegative = this.negative;

      if ((<KeyboardEvent>event).key === "." && this.decimals < 1) {
        this.setValueAndCursorPosition(oldValue);
        return;
      }

      if ((<KeyboardEvent>event).key === "-" && !allowNegative) {
        this.control.control.setValue(oldValue);
        if (this.check(oldValue)) this.control.control.setErrors(null);

        return (this.el.nativeElement.value = oldValue);
      }

      if ((<KeyboardEvent>event).key === "-" && oldValue.includes("-")) {
        this.control.control.setValue(oldValue);
        return (this.el.nativeElement.value = oldValue);
      }

      if (
        (<KeyboardEvent>event).key === "Backspace" ||
        (<KeyboardEvent>event).key === "Delete"
      ) {
        if (currentValue === "") {
          this.control.control.setErrors({ required: true });
        }
        return;
      }
      if (allowNegative) {
        if (
          !["", "-"].includes(currentValue) &&
          !this.checkAllowNegative(currentValue) &&
          !this.enableIntWithMoreThan3Digits
        ) {
          this.control.control.setValue(oldValue);
          this.el.nativeElement.value = oldValue;
          return;
        }

        if (this.checkAllowNegative(currentValue)) {
          this.control.control.setErrors(null);
          return;
        }
      } else {
        if (currentValue !== "" && !this.check(currentValue)) {
          this.control.control.setValue(oldValue);
          this.el.nativeElement.value = oldValue;
          return;
        }
      }

      if (currentValue !== "" && this.validateValue(currentValue)) {
        this.control.control.setErrors(null);
      } else if (currentValue === "") {
        this.control.control.setErrors({ required: true });
      }
    });
  }

  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    if (this.infinityDecimals && !this.negative) {
      this.el.nativeElement.value = this.el.nativeElement.value
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*)\./g, "$1");
    } else if (this.infinityDecimals && this.negative) {
      const oldValue = this.el.nativeElement.value;
      setTimeout(() => {
        const currentValue = this.el.nativeElement.value;
        const isValid = new RegExp("^-", "g").test(currentValue);
        if ((<KeyboardEvent>event).key === "-" && oldValue.includes("-")) {
          return (this.el.nativeElement.value = oldValue);
        }

        if (currentValue.includes("-") && !isValid) {
          return (this.el.nativeElement.value = oldValue);
        }
      });
    } else {
      this.run(this.el.nativeElement.value, event);
    }
  }

  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent) {
    this.run(this.el.nativeElement.value, event);
  }

  @HostListener("blur", ["$event"])
  onBlur(event: any) {
    this.run(this.el.nativeElement.value, event);
  }

  private setValueAndCursorPosition(oldValue: string): void {
    this.control.control.setValue(oldValue);
    this.el.nativeElement.value = oldValue;

    const inputElement = this.el.nativeElement;
    inputElement.type = "text";

    const valueLength = oldValue.length;
    inputElement.setSelectionRange(valueLength, valueLength);
    inputElement.focus();

    inputElement.type = "number";
  }
}
