<div class="estimate-theme mat-dialog">
  <h2 mat-dialog-title class="mat-dialog__header">{{ title }}</h2>
  <mat-dialog-content class="mat-typography">
    <p class="mat-dialog__subtitle">
      {{ message }}
    </p>
  </mat-dialog-content>

  <mat-dialog-actions
    class="mat-dialog__actions mat-dialog__actions--align-right mat-dialog-actions"
  >
    <div>
      <button mat-button mat-dialog-close class="button button--secondary">
        Cancel
      </button>
      <button
        mat-button
        [mat-dialog-close]="true"
        class="button button--primary"
      >
        {{ approvalButtonText }}
      </button>
    </div>
  </mat-dialog-actions>
</div>
