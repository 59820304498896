import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";
import { BaThemeSpinner } from "app/theme/services";
import { GridDataResult } from "@progress/kendo-angular-grid";
import * as _ from "lodash";
import { MessageService } from "primeng/components/common/messageservice";
import { ChecklistService } from "app/common/services/Core/Checklist.service";
import { Checklist } from "app/component/Checklist/Models/Checklist";
import { SharedService } from "app/common/utility/SharedService";
import { CustomerService } from "app/common/services/customer.service";
import { UpdateCountService } from "app/common/utility/updateCount.service";

@Component({
  selector: "checklistAdd",
  templateUrl: "checklistAdd.html",
  styleUrls: ["./checklistAdd.css"],
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      html,
      body,
      checklistAdd {
        height: 100%;
      }
    `,
  ],
})
export class AddChecklistComponent implements OnInit {
  headerText: string = "";
  id: any;
  lstChecklists: Checklist[];
  checklist: Checklist;
  eventsAdded: boolean = false;
  visibleSidebar: boolean = false;
  gridView: GridDataResult;
  selectedChecklist: any = [];
  dirty: boolean = false;
  employees: any;
  originalEmployees: any;
  isSubmitting: boolean = false;
  @Input() guid: string;
  @Input() phasechecklist: boolean;
  @Output() closeSlide: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() newChecklist: EventEmitter<string> = new EventEmitter<string>();
  constructor(
    private checklistService: ChecklistService,
    private sharedService: SharedService,
    private _spinner: BaThemeSpinner,
    private notificationsService: MessageService,
    private customerService: CustomerService,
    private updateCountsService: UpdateCountService
  ) {}
  ngOnInit() {
    this.getEmployees();
    this.getChecklists();
  }

  getChecklists() {
    this.checklistService
      .getChecklistByOrg(this.sharedService.selectedOrganization.ID)
      .subscribe((data) => {
        this.lstChecklists = data.object;
        this.gridView = {
          data: data.object,
          total: data.object.length,
        };
      });
  }

  selectChecklist(event, dataItem) {
    this.dirty = true;
    this.isSubmitting = false;
    if (event.target.checked) {
      this.selectedChecklist.push(dataItem);
    } else {
      var index = this.selectedChecklist.findIndex(
        (x) => x.guid == dataItem.guid
      );
      if (index > -1) this.selectedChecklist.splice(index, 1);
    }

    if (this.selectedChecklist.length == 0) this.dirty = false;

    //this.selectedChecklist = dataItem;
    // this.checklistService
    //   .getChecklistItems(this.selectedChecklist.guid)
    //   .subscribe((data) => {
    //     this.selectedChecklist.items = data.object;
    //     this.dirty = true;
    //   });
  }

  getEmployees() {
    this.customerService.getAllOrganizationContacts().subscribe((data) => {
      if (data) {
        data.forEach((x) => {
          x.Name = x.FirstName + " " + x.LastName;
        });
      }
      this.employees = data;
      this.originalEmployees = data;
    });
  }

  closeslideout() {
    this.closeSlide.emit(true);
  }

  assignToChanged(e, dataItem) {
    let checklist = this.lstChecklists.find((x) => x.guid == dataItem.guid);
    checklist.assignee = { guid: null };
    checklist.assignee.guid = e.CustomerGuid;
  }

  onSubmit() {
    this.isSubmitting = true;
    if (this.phasechecklist) {
      this.selectedChecklist.forEach((list) => {
        this.checklistService.postJobPhaseChecklists(this.guid, list).subscribe(
          (result) => {
            this.result(result, "Checklist added Successfully!");
            this.refreshPage.emit(true);
            this.newChecklist.emit(result.object.guid);
          },
          (error) => this.error(error, "Unable to add checklist")
        );
      });
      // this.refreshPage.emit(true);
    } else {
      this.selectedChecklist.forEach((list) => {
        this.checklistService.postJobChecklists(this.guid, list).subscribe(
          (result) => {
            this.result(result, "Checklist added Successfully!!");
            this.refreshPage.emit(true);
            this.newChecklist.emit(result.object.guid);
          },
          (error) => this.error(error, "Unable to add checklist")
        );
      });
      this.updateCountsService.update();
    }
  }

  private result(res: any, message: string) {
    this.isSubmitting = false;
    this.notificationsService.add({
      severity: "success",
      summary: "Job",
      detail: message,
    });
    this._spinner.hide();
  }
  private error(error: any, msg: any) {
    this.isSubmitting = false;
    this.notificationsService.add({
      severity: "warn",
      summary: "Work Order",
      detail: msg,
    });
    this._spinner.hide();
  }
}
