<div class="row m-0">
  <search-user
    *ngIf="!group.system"
    (userSelected)="onUserSelected($event)"
  ></search-user>
</div>

<div *ngIf="users.length > 0" class="row m-0 mt-3 chat-participants">
  <h3>Participating users</h3>
  <ul class="list-group chat-users">
    <li *ngFor="let c of users" class="list-group-item">
      {{ c.guid !== user.guid ? c.fullName : "Me" }}
      <i
        *ngIf="user?.isAdmin && c.guid !== user.guid && !group.system"
        (click)="onRemoveUser(c)"
        class="fa fa-times-circle"
        aria-hidden="true"
      ></i>
    </li>
  </ul>
</div>

<div class="row m-0 pb-3">
  <form
    #f="ngForm"
    class="w-100"
    name="form"
    (keydown.enter)="$event.preventDefault()"
    (ngSubmit)="onSubmit(f)"
  >
    <div class="row m-0">
      <div class="col-12">
        <div *ngIf="group.system" class="form-group">
          <input
            *ngIf="!edit"
            type="text"
            name="groupName"
            class="form-control"
            [(ngModel)]="group.name"
            required
            placeholder="Enter group name"
          />

          <kendo-multiselect
            [disabled]="edit"
            class="mt-3"
            name="groupRoles"
            [data]="roles"
            [valuePrimitive]="true"
            textField="name"
            valueField="id"
            [(ngModel)]="group.roles"
          ></kendo-multiselect>

          <small class="form-text text-muted"
            >Leaving roles blank allows all registered users under this
            organization to participate.</small
          >
          <small *ngIf="edit" class="form-text text-muted"
            >Roles cannot be changed after the group has been created.</small
          >
        </div>
      </div>
    </div>

    <div *ngIf="edit && group.userGuid === user.guid" class="row m-0">
      <div class="col-12">
        <button (click)="onDeleteGroup()" type="button" class="btn btn-danger">
          Delete group
        </button>
      </div>
    </div>

    <div *ngIf="!group.system || (group.system && !edit)" class="row m-0">
      <div class="col-6">
        <button *ngIf="f.form.valid" type="submit" class="btn btn-primary">
          Save
        </button>
      </div>
      <div class="col-6">
        <button (click)="onComplete()" type="button" class="btn btn-secondary">
          Cancel
        </button>
      </div>
    </div>
  </form>
</div>
