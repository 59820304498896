import { Component, OnInit } from "@angular/core";
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
export interface ContactsDialogModel {
  title: string;
  message: string;
}
@Component({
  selector: "app-contacts-dialog",
  templateUrl: "contacts-dialog.component.html",
  styleUrls: ["contacts-dialog.component.scss"],
})
export class ContactsDialogComponent
  extends DialogComponent<ContactsDialogModel, boolean>
  implements ContactsDialogModel
{
  message: string;
  title: string;
  constructor(dialogService: DialogService) {
    super(dialogService);
  }

  confirm() {
    this.result = true;
    this.close();
  }

  cancel() {
    this.result = false;
    this.close();
  }

  ngOnInit() {}
}
