import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { environmentConstant } from "app/common/utility/environment";
import { JobPhaseService } from "app/common/services/jobPhase.service";
import { SharedService } from "app/common/utility/SharedService";
import { Helper } from "app/common/utility/helper";
import { DialogService } from "ng2-bootstrap-modal";
import { WeatherRescheduleModalComponent } from "app/component/weather-reschedule-modal/weather-reschedule-modal.component";
import {
  UpdatePhaseScheduleRequestModel,
  UpdatePhaseScheduleArrayRequest,
} from "app/model/UpdatePhaseScheduleRequestModel";
import { MessageService } from "primeng/components/common/messageservice";
import { DatePipe } from "@angular/common";
import { WeatherReschedulerService } from "app/common/services/weather-rescheduler.service";

@Component({
  selector: "weather-rescheduler",
  templateUrl: "./weather-rescheduler.component.html",
  styleUrls: ["./weather-rescheduler.component.scss"],
})
export class WeatherReschedulerComponent implements OnInit {
  jobTypeData: Array<{ name: string }> = [
    { name: "Construction" },
    { name: "Maintenance" },
    { name: "Service" },
  ];
  jobType = this.jobTypeData[0];
  resources: any = [];
  phaseUpdatesRequested: UpdatePhaseScheduleRequestModel[] = [];

  calendarData: Array<{ id: number; name: string }> = [
    { id: 0, name: "Today" },
    { id: 1, name: "Tomorrow" },
    { id: 2, name: "Next Two Weeks" },
    { id: 3, name: "This Month" },
    { id: 4, name: "Next Month" },
  ];

  calendarDay = this.calendarData[0];

  phases: any[] = [];
  filteredPhases: any[] = [];
  totalSelected: any[] = [];
  phaseSelected: boolean = false;
  updateRequested: boolean = false;
  searchCriteria: string = "";
  orgGuid = this.sharedService.selectedOrganization.ID;

  @Output() onHide: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private sharedService: SharedService,
    private jobPhaseService: JobPhaseService,
    private dialogService: DialogService,
    private notificationsService: MessageService,
    private datePipe: DatePipe,
    private weatherSchedulerService: WeatherReschedulerService
  ) {}

  ngOnInit() {
    this.getScheduledPhases();
  }

  phaseClicked(e, phase) {
    if (e == true) {
      this.totalSelected.push(phase);
    } else {
      var i = this.totalSelected.findIndex(
        (x) => x.JobPhaseId == phase.JobPhaseId
      );
      this.totalSelected.splice(i, 1);
    }
  }

  toggleSelectedPhases(all: boolean) {
    if (all) {
      this.filteredPhases.forEach((x) => {
        x.phaseSelected = true;
        this.totalSelected.push(x);
      });
    } else {
      this.totalSelected = [];
      this.filteredPhases.forEach((x) => {
        x.phaseSelected = false;
      });
    }
  }

  getScheduledPhases() {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    this.jobPhaseService
      .getPhasesScheduledNow(this.sharedService.selectedOrganization.ID)
      .subscribe((data) => {
        if (data) {
          data.forEach((phase) => {
            phase.phaseSelected = false;
            var resources = JSON.parse(phase.Resources);
            phase.Resources = resources;
            var finishDate = this.addDays(
              phase.Scheduled,
              phase.DaysToComplete
            );
            phase.FinshDate = this.datePipe.transform(finishDate, "MM/dd/yyyy");
          });
        }

        this.phases = data;
        this.filteredPhases = this.phases;
      });
  }

  getStatus(phase) {
    var q = new Date();
    var m = q.getMonth();
    var d = q.getDate();
    var y = q.getFullYear();
    var date = new Date(y, m, d);
    var endDate: any;
    if (phase.EstEndDate) {
      endDate = new Date(
        new Date(phase.EstEndDate).getFullYear(),
        new Date(phase.EstEndDate).getMonth(),
        new Date(phase.EstEndDate).getDate()
      );
    }
    return phase.StatusId == 3
      ? "Completed"
      : phase.StatusId == 2
      ? endDate < date
        ? phase.JobTypeId != 7
          ? "Past Due"
          : "In Progress"
        : "In Progress"
      : "Not Started";
  }

  getStatusClass(phase) {
    var q = new Date();
    var m = q.getMonth();
    var d = q.getDate();
    var y = q.getFullYear();
    var date = new Date(y, m, d);
    var endDate: any;
    if (phase.EstEndDate) {
      endDate = new Date(
        new Date(phase.EstEndDate).getFullYear(),
        new Date(phase.EstEndDate).getMonth(),
        new Date(phase.EstEndDate).getDate()
      );
    }
    return phase.StatusId == 3
      ? "completed"
      : phase.StatusId == 2!
      ? endDate < date
        ? phase.JobTypeId != 7
          ? "pastDue"
          : "inProgress"
        : "inProgress"
      : "notStarted";
  }

  getInitals(str) {
    if (!str) return "";
    var result = str
      .split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), "");
    if (result && result.length > 2) {
      return result.slice(0, 2);
    }
    return result;
  }

  getMonthText(date) {
    if (date) {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      date = new Date(date);
      return monthNames[date.getMonth()];
    }
    return "";
  }

  getDateText(date) {
    if (date) {
      date = new Date(date);
      return date.getDate();
    }
    return "";
  }

  getLocationString(phase) {
    if (!phase) {
      return " ";
    }
    let addressString: string = "";
    if (phase.Address1) {
      addressString += phase.Address1;
    }
    if (phase.City) {
      addressString += ", ";
      addressString += phase.City;
    }
    if (phase.Abbreviation) {
      addressString += ", ";
      addressString += phase.Abbreviation;
    }
    if (phase.ZipPostalCode) {
      addressString += ", ";
      addressString += phase.ZipPostalCode;
    }
    if (addressString) {
      return addressString;
    } else {
      return " ";
    }
  }

  StartUpdateRequest() {
    const disposable = this.dialogService
      .addDialog(WeatherRescheduleModalComponent)
      .subscribe((Confirmed) => {
        if (Confirmed) {
          let days = Confirmed.days;
          let withDependents = Confirmed.withDependents;
          let changeReasonId = Confirmed.ChangeReasonId;
          let changeReason = Confirmed.ChangeReason;
          const jobsIdsToUpdate: string[] = [];

          this.totalSelected.forEach((phase) => {
            var dateScheduled = new Date(phase.Scheduled);
            dateScheduled.setDate(dateScheduled.getDate() + days);
            let updateRequest: UpdatePhaseScheduleRequestModel = {
              PhaseId: phase.JobPhaseId,
              StartDate: dateScheduled,
              UpdateType: 2,
              PhaseChangeReason: changeReasonId,
              Comments: changeReason,
              DependentAction: withDependents == true ? 1 : 4,
              DaysToComplete: phase.DaysToComplete,
            };

            this.phaseUpdatesRequested.push(updateRequest);
            jobsIdsToUpdate.push(phase.JobId);
          });

          var request: UpdatePhaseScheduleArrayRequest = {
            Phases: this.phaseUpdatesRequested,
          };
          this.jobPhaseService
            .updatePhasesScheduleDelay(request)
            .subscribe((data) => {
              this.result("", "Phases updated");
              this.weatherSchedulerService.updateJob(jobsIdsToUpdate);
            })
            .add(() => this.onHide.emit("weather"));
        }
      });
  }

  onCancel() {
    this.onHide.emit("weather");
    this.assignCopy();
  }

  private result(res: any, message: string) {
    this.notificationsService.add({
      severity: "success",
      summary: "Update Successful",
      detail: message,
    });
  }

  assignCopy() {
    this.filteredPhases = Object.assign([], this.phases);
  }

  filterSearchCriteria() {
    this.filteredPhases = this.phases.filter(
      (row) =>
        Helper.ConvertToLower(row.JobName).indexOf(
          this.searchCriteria.trim().toLowerCase()
        ) > -1 ||
        Helper.ConvertToLower(row.JobPhaseName).indexOf(
          this.searchCriteria.trim().toLowerCase()
        ) > -1 ||
        Helper.ConvertToLower(row.Address1).indexOf(
          this.searchCriteria.trim().toLowerCase()
        ) > -1 ||
        Helper.ConvertToLower(row.City).indexOf(
          this.searchCriteria.trim().toLowerCase()
        ) > -1 ||
        Helper.ConvertToLower(row.Abbreviation).indexOf(
          this.searchCriteria.trim().toLowerCase()
        ) > -1 ||
        Helper.ConvertToLower(row.ZipPostalCode).indexOf(
          this.searchCriteria.trim().toLowerCase()
        ) > -1
    );
  }

  filterByDateSelection(evt) {
    this.filteredPhases = this.phases; //reset list
    var today = new Date();
    var endDate = new Date("12/31/2078");
    today.setHours(0, 0, 0, 0);

    switch (evt.id) {
      //tomorrow
      case 1: {
        today = this.addDays(today, 1);
        break;
      }
      //next two weeks
      case 2: {
        endDate = this.addDays(today, 14);
        break;
      }
      //this month
      case 3: {
        today = new Date(today.getFullYear(), today.getMonth(), 1);
        endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      }
      //next month
      case 4: {
        today = new Date(today.getFullYear(), today.getMonth() + 1, 1);
        endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        break;
      }
      default: {
      }
    }

    if (evt.id == 3 || evt.id == 4) {
      this.filteredPhases = this.phases.filter(
        (x) =>
          new Date(x.Scheduled) >= new Date(today) &&
          new Date(x.Scheduled) <= new Date(endDate)
      );
    } else {
      this.filteredPhases = this.phases.filter(
        (x) =>
          (new Date(x.Scheduled) >= new Date(today) ||
            new Date(x.Scheduled) < new Date(today)) &&
          new Date(x.Scheduled) <= new Date(endDate)
      );
    }
  }

  addDays = function (startdate, days) {
    var date = new Date(startdate);
    date.setDate(date.getDate() + days);
    return date;
  };
}
