import { JobChangeOrderNoteModel } from "app/model/jobChangeOrderNote";
import { JobChangeOrderFileModel } from "app/model/jobChangeOrderFile";
export class JObChangeOrderModel {
  Id: number;
  OrgId: string;
  JobId: string;
  ChangeOrderNumber: number;
  CreatedOn: Date;
  CreatedBy: number;
  PriorityId: number;
  StatusId: number;
  COName: string;
  Description: string;
  RequiresCustomerApproval: boolean;
  ApprovalDueBy: Date;
  ApprovedOn: Date;
  RequiresPayment: boolean;
  PaymentDueBy: Date;
  Amount: number = 0.0;
  UpdatedOn: Date;
  DeletedOn: Date;
  DateApprovedRejected: Date;
  ChangeOrderApproved: boolean;
  ApprovedById: number;
  createDrawSchedule: boolean = false;
  CreateDrawOnApproval: boolean = false;
  SignatureBlobId: number;
  JobChangeOrderFiles: JobChangeOrderFileModel[];
  JobChangeOrderNotes: JobChangeOrderNoteModel[];
  MPBStatuStatus: string;
  UserId: number;
  EstimateWorksheetId?: number;
  FileTypeId?: number;
  EWAApproverId?: number;

  // UI fields:
  EwaAproval: boolean;
  constructor() {}
}
