import { UserModel } from "app/model/Core/UserModel";
import { JobPhasModel } from "app/model/jobPhaseModel";
export class Checklist {
  type: number = 0;
  reccuringFreq: number = 0;
  name: string = "";
  description: string = "";
  options?: string[] = [];
  required: boolean = false;
  result?: string;
  items?: ChecklistItem[];
  order: number = 0;
  applyJobs: boolean = false;
  applyJobPhases: boolean = false;
  status: number = 0;
  statusTxt?: string = "";
  assigneeRole?: number = 0;
  assignee? = { guid: null };
  guid?: string;
  updated?: Date;
  created: Date;
  completed?: Date;
  jobPhase?: JobPhasModel;
  categoryId?: number;
  id?: number;
}

export class ChecklistItem {
  type: number = 0;
  reccuringFreq: number = 0;
  name: string = "";
  description: string = "";
  options?: string[] = [];
  required: boolean = false;
  result?: string;
  order: number = 0;
  applyJobs: boolean = false;
  applyJobPhases: boolean = false;
  guid?: string;
  updated?: Date;
  created: Date;
}
