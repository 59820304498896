import { Resource } from "app/model/ResourceModel";

export class WorkOrderLineItemModel {
  ID: number;
  LineItemName: string;
  RemindMeOnDay: Date;
  CategoryId: number;
  StatusId: number;
  Status: string;
  Description: string;
  OrgGuid: string;
  IsWarranty: boolean;
  IsPublic: boolean;
  ScheduledDate: Date;
  CompleteDate: Date;
  StartDate: Date;
  Resources: Resource[] = [];
  ResourcesText: string;
  TargetDate: Date;
  DaysToComplete: number;
}
