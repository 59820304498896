import { Component, Input, Output, EventEmitter } from "@angular/core";
import { JobPhaseService } from "app/common/services/jobPhase.service";
import { UpdateCountService } from "app/common/utility/updateCount.service";

@Component({
  selector: "buildPlan-dialog",
  templateUrl: "./buildPlan-dialog.html",
})
export class BuidPlanDialogComponent {
  changeReasonList: any;
  originalChangeList: any;
  scheduleUpdate = { reasonId: 0, DependentAction: 4, Comments: "" };
  @Input() updateType: any = "";
  @Input() showReason: boolean;
  @Input() showDialog: boolean;
  @Input() dragEvent: any;
  @Output() getDragEventBack: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeDialog: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendReason: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private jobPhaseService: JobPhaseService,
    private updateCountService: UpdateCountService
  ) {}
  ngOnInit() {
    this.jobPhaseService.getChangeReasons().subscribe((value) => {
      this.changeReasonList = value;
      this.originalChangeList = value;
    });
  }
  public filterChangeList(filter: any): void {
    this.changeReasonList = this.originalChangeList.filter(
      (s) => s.Reason.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }
  confirm() {
    if (this.scheduleUpdate.reasonId === 0) this.scheduleUpdate.reasonId = -1;
    this.sendReason.emit(this.scheduleUpdate);
    this.closeDialog.emit(true);
  }
  close() {
    this.closeDialog.emit(true);
    if (this.dragEvent) {
      this.getDragEventBack.emit(this.dragEvent);
    }
  }
}
