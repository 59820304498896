import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DeleteOpResponse } from "app/model/DeleteOpResponse";
import { MaterialCalculator } from "app/model/MaterialsCalculator";
import {
  CheckIfAffectsWorksheetsResponse,
  ParamaterToUpdate,
  ParameterFromDB,
  ParameterPaginated,
  ParameterToBeInserted,
} from "app/model/Parameter";
import { UnitOfMeasure } from "app/model/UnitOfMeasure";

import { environment } from "environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ParametersService {
  constructor(private http: HttpClient) {}

  /**
   * Gets all the units of measure available
   * @returns
   */
  getUnitsOfMeasure(orgGuid: string): Observable<UnitOfMeasure[]> {
    return this.http.get<UnitOfMeasure[]>(
      `${environment.baseEstimateUrl}unitsofmeasure/${orgGuid}`
    );
  }

  /**
   * Gets all the parameters frmo the DB
   * @returns
   */
  getParametersListPaginated(
    orgGuid: string,
    skip: number,
    itemsPerPage: number,
    searchText: string = null,
    sortBy: string = null,
    sortDirection: number = null
  ): Observable<ParameterPaginated> {
    let url = `${environment.baseEstimateUrl}parameter/getListOnPages/${orgGuid}?skip=${skip}&itemsPerPage=${itemsPerPage}`;

    if (sortBy) {
      url += `&sortBy=${sortBy}&sortDirection=${sortDirection}`;
    }

    return this.http.post<ParameterPaginated>(url, { searchText: searchText });
  }

  /**
   * Deleting the parameter from the DB
   * @param parameterId
   * @returns
   */
  deleteParameter(parameterId: number): Observable<DeleteOpResponse> {
    return this.http.delete<DeleteOpResponse>(
      `${environment.baseEstimateUrl}parameter/${parameterId}`
    );
  }

  insertParameter(
    parameterToBeInserted: ParameterToBeInserted
  ): Observable<ParameterFromDB> {
    return this.http.post<ParameterFromDB>(
      `${environment.baseEstimateUrl}parameter/insert`,
      parameterToBeInserted
    );
  }

  /**
   * Bringsa list of all the parameters with the same parameterName
   * @param parameterName
   * @returns
   */
  checkUsedName(
    parameterName: string,
    orgGuid: string
  ): Observable<ParameterFromDB[]> {
    return this.http.post<ParameterFromDB[]>(
      `${environment.baseEstimateUrl}parameter/checkUsedNames`,
      {
        parameterName: parameterName,
        orgGuid: orgGuid,
      }
    );
  }

  /**
   * Updates a parameter with the provided data
   * @param parameterToUpdate
   * @returns
   */
  updateParameter(parameterToUpdate: ParamaterToUpdate): Observable<null> {
    return this.http.patch<null>(
      `${environment.baseEstimateUrl}parameter/${parameterToUpdate.id}`,
      parameterToUpdate
    );
  }

  getParameterList(orgGuid: string) {
    return this.http.get<ParameterFromDB[]>(
      `${environment.baseEstimateUrl}parameter/getList/${orgGuid}`
    );
  }

  /**
   * Checks if updating or deleting a parameter affects other worksheets that are using it
   * @param parameterId
   * @returns An object with details of the affected worksheets if there is any and a boolean
   */
  checkIfWorksheetsAreAffected(
    parameterId: number
  ): Observable<CheckIfAffectsWorksheetsResponse> {
    return this.http.get<CheckIfAffectsWorksheetsResponse>(
      `${environment.baseEstimateUrl}parameter/checkIfAffectsWorksheets/${parameterId}`
    );
  }
}
