import { Pipe, PipeTransform } from "@angular/core";
import { environmentConstant } from "../utility/environment";
import { environment } from "environments/environment";

@Pipe({
  name: "imageFilter",
})
export class ImagePipe implements PipeTransform {
  private imgBaseUrl = environment.imgBaseUrl + "Content/images/";

  transform(categoryData: any): string {
    let image = "";
    let customString = "";
    if (categoryData && categoryData.Id != undefined && categoryData.Id) {
      if (categoryData.Id.toString().length == 1) {
        customString = "000000";
      } else if (categoryData.Id.toString().length == 2) {
        customString = "00000";
      } else if (categoryData.Id.toString().length == 3) {
        customString = "0000";
      } else if (categoryData.Id.toString().length == 4) {
        customString = "000";
      } else if (categoryData.Id.toString().length == 5) {
        customString = "00";
      } else if (categoryData.Id.toString().length == 6) {
        customString = "0";
      }
      let value = categoryData.Id;
      let imgType = categoryData.MimeType.split("/");
      image =
        this.imgBaseUrl +
        customString +
        value +
        "_0." +
        imgType[1] +
        "?" +
        new Date().getTime();
      //let thumbNailSize = 100;
      //image = this.imgBaseUrl + customString + value + "_" + categoryData.SeoFilename + "_" + thumbNailSize + "." + imgType[1]+ "?" + new Date().getTime();
    } else {
      image = categoryData;
    }
    return image;
  }
}
