import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { AddEditBuildPlanComponent } from "./addeditbuildplan.component";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { TextBoxModule } from "@progress/kendo-angular-inputs";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { InputTextModule } from "primeng/primeng";
import { BuilPlanDialogModule } from "app/component/buildPlan-dialog/buildPlan-dialog.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DropDownsModule,
    TextBoxModule,
    DateInputsModule,
    InputTextModule,
    BuilPlanDialogModule,
  ],
  declarations: [AddEditBuildPlanComponent],
  exports: [AddEditBuildPlanComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AddEditBuildPlanModule {}
