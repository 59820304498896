import { Injectable } from "@angular/core";
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
  HubConnectionState,
} from "@microsoft/signalr";
import { environment } from "environments/environment";
import { AuthGuard } from "../authGuard/auth.guard";
import { Subject, BehaviorSubject } from "rxjs";
import { GroupModel } from "app/model/Chat/GroupModel";
import { MessageModel } from "app/model/Chat/MessageModel";
import { ApiResponseModel } from "app/model/ApiResponseModel";
import { ChatActionModel } from "app/model/Chat/ChatActionModel";
import { SharedService } from "../utility/SharedService";
import { UserModel } from "app/model/Core/UserModel";
import { Pager, DetailedPagerInfo } from "app/model/Pager";
import { FilteredSearchModel } from "app/model/SearchModel";
import { UserRoleEnum } from "app/model/Core/UserRoleEnum";
import { ChatUserModel } from "app/model/Chat/ChatUserModel";

@Injectable({
  providedIn: "root",
})
export class ChatService {
  public user: ChatUserModel;
  public groups: GroupModel[] = [];
  public messages: MessageModel[] = [];

  get hasPermission(): boolean {
    return (
      this.user && (this.user.hasRole(UserRoleEnum.Chat) || this.user.isAdmin)
    );
  }

  public onMessage: Subject<MessageModel> = new Subject<MessageModel>();
  public onHistory: Subject<boolean> = new Subject<boolean>();
  public onNotify: Subject<MessageModel> = new Subject<MessageModel>();
  public onMessageDeleted: Subject<MessageModel> = new Subject<MessageModel>();
  public onGroupUsersChanged: Subject<GroupModel> = new Subject<GroupModel>();
  public onAddGroup: Subject<GroupModel> = new Subject<GroupModel>();

  public loadedGroups: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public selectedGroup: BehaviorSubject<GroupModel> = new BehaviorSubject(
    undefined
  );
  public readonly: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public unreadCount: BehaviorSubject<number> = new BehaviorSubject(0);

  private onMessageEvent: string = "Message";
  private onRemoveMessageEvent: string = "RemoveMessage";
  private onRemoveUserFromGroupEvent: string = "RemoveUserFromGroup";
  private onAddUserToGroupEvent: string = "AddUserToGroup";
  private onAddGroupEvent: string = "AddGroup";
  private onMessagesEvent: string = "Messages";
  private onClearMessageUnreadEvent: string = "ClearMessageUnread";
  private onDeleteGroupEvent: string = "DeleteGroup";
  private onGroupsEvent: string = "Groups";
  private onUserConnectedEvent: string = "OnUserConnected";
  private onUserDisconnectedEvent: string = "OnUserDisconnected";

  private _lastSelectedGroup: GroupModel;
  private _selectedGroup: GroupModel;
  private _readonly: boolean;

  private connection: HubConnection;
  private messageSearch: FilteredSearchModel<GroupModel> = <
    FilteredSearchModel<GroupModel>
  >{};
  private totalMessageCount: number = 0;
  private messageTake: number = 15;
  private loadingMessages: boolean = false;
  private didLoadGroups: boolean = false;
  private hasEvents: boolean = false;
  private retryCount: number = 4;

  private _minimized: boolean = false;
  get minimized(): boolean {
    return this._minimized;
  }
  set minimized(value: boolean) {
    this._minimized = value;
    this.clearUnreadMessages(this._selectedGroup);
  }

  constructor(
    private sharedService: SharedService,
    private authGuard: AuthGuard
  ) {}

  private async start(retryCount: number = 0) {
    try {
      await this.connection.start();
    } catch {
      retryCount++;
      if (retryCount >= this.retryCount) {
        this.connection = null;
        throw new Error("Retry count exceeded");
      }
      await this.sleep(5000);
      await this.start(retryCount);
    }
  }

  public async connect(): Promise<HubConnection> {
    this.user = <ChatUserModel>this.sharedService.user;
    if (!this.hasPermission || !environment.chat) return;

    if (
      !this.connection ||
      this.connection.state === HubConnectionState.Disconnected
    ) {
      return this.initialize();
    }

    if (this.connection.state === HubConnectionState.Connected) {
      return Promise.resolve(this.connection);
    }

    await this.sleep(5000);
    return this.connect();
  }

  private sleep(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  private removeGroup(group: GroupModel): void {
    var index = this.groups.findIndex((x) => x.guid === group.guid);
    if (index > -1) this.groups.splice(index, 1);
  }

  private setUnreadCount(): void {
    this.unreadCount.next(
      this.groups.reduce((a, b) => a + (b["unreadCount"] || 0), 0)
    );
  }

  private async initialize(): Promise<HubConnection> {
    this.connection = new HubConnectionBuilder()
      .withUrl(environment.baseChatUrl, {
        accessTokenFactory: () => this.authGuard.getToken(false),
      })
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    try {
      await this.start();

      this.connection.onclose(this.onClose);
      this.getGroups();
      this.setEvents();
    } catch {
      this.connection = null;
    }

    return this.connection;
  }

  private onClose = async (error?: Error) => {
    this.connection.onclose(null);
    await this.initialize();
  };

  private setEvents(): void {
    if (this.hasEvents) return;
    this.hasEvents = true;

    this.connection.on(
      this.onMessageEvent,
      (response: ApiResponseModel<MessageModel>) => {
        if (response.success) {
          let group = this.groups.find(
            (x) => x.guid === response.object.groupGuid
          );
          if (!group) return;

          group.latestMessage = response.object;

          let isSelected =
            this._selectedGroup &&
            response.object.groupGuid === this._selectedGroup.guid;
          if (isSelected) {
            this.clearUnreadMessages(group);
            this.messages.push(response.object);
            this.onMessage.next(response.object);
          }

          if ((!isSelected || this.minimized) && !response.object.system) {
            group.unreadCount++;
            this.setUnreadCount();
            this.onNotify.next(response.object);
          }
        }
      }
    );

    this.connection.on(
      this.onUserConnectedEvent,
      (response: ApiResponseModel<ChatUserModel>) => {
        if (response.success) {
          this.setUserStatus(response.object, true);
        }
      }
    );

    this.connection.on(
      this.onUserDisconnectedEvent,
      (response: ApiResponseModel<ChatUserModel>) => {
        if (response.success) {
          this.setUserStatus(response.object, false);
        }
      }
    );

    this.connection.on(
      this.onRemoveMessageEvent,
      (response: ApiResponseModel<MessageModel>) => {
        if (
          response.success &&
          this._selectedGroup &&
          response.object.groupGuid === this._selectedGroup.guid
        ) {
          var index = this.messages.findIndex(
            (x) => x.guid === response.object.guid
          );
          if (index > -1) this.messages.splice(index, 1);

          this.onMessageDeleted.next(response.object);
        }
      }
    );

    this.connection.on(
      this.onRemoveUserFromGroupEvent,
      (response: ApiResponseModel<ChatActionModel<GroupModel>>) => {
        if (response.success) {
          let group = this.groups.find(
            (x) => x.guid === response.object.context.guid
          );
          if (!group) return;

          if (this.user.guid === response.object.user.guid) {
            this.setReadOnly(
              this._selectedGroup && this._selectedGroup.guid === group.guid
            );
            this.removeGroup(group);
          } else {
            group.users = response.object.context.users;
            this.onGroupUsersChanged.next(group);
          }
        }
      }
    );

    this.connection.on(
      this.onAddUserToGroupEvent,
      (response: ApiResponseModel<ChatActionModel<GroupModel>>) => {
        if (response.success) {
          if (this.user.guid === response.object.user.guid) {
            this.groups.push(response.object.context);
            this.onAddGroup.next(response.object.context);
          }

          let group = this.groups.find(
            (x) => x.guid === response.object.context.guid
          );
          if (group) {
            group.users = response.object.context.users;
            this.onGroupUsersChanged.next(group);
          }
        }
      }
    );

    this.connection.on(
      this.onDeleteGroupEvent,
      (response: ApiResponseModel<GroupModel>) => {
        if (response.success) {
          let group = this.groups.find((x) => x.guid === response.object.guid);
          if (!group) return;

          this.removeGroup(group);
        }
      }
    );

    this.connection.on(
      this.onAddGroupEvent,
      (response: ApiResponseModel<GroupModel>) => {
        if (response.success) {
          this.groups.push(response.object);
          this.onAddGroup.next(response.object);
        }
      }
    );

    this.connection.on(
      this.onMessagesEvent,
      (response: ApiResponseModel<Pager<MessageModel>>) => {
        if (response.success) {
          let history = false;
          if (this.messageSearch.pagerInfo.getCounts) {
            this.totalMessageCount = response.object.info.totalCount;
            this.messageSearch.pagerInfo.getCounts = false;
            history = true;
          }

          for (let message of response.object.items) {
            this.messages.unshift(message);
          }

          this.messageSearch.pagerInfo.skip = this.messages.length;

          if (history) {
            this.onHistory.next(true);
          }
        }

        this.loadingMessages = false;
      }
    );

    this.connection.on(
      this.onGroupsEvent,
      (response: ApiResponseModel<GroupModel[]>) => {
        if (response.success) {
          this.groups.length = 0;
          for (let group of response.object) {
            this.groups.push(group);
          }

          this.setUnreadCount();
          this.loadedGroups.next(true);
        }
      }
    );
  }

  private setUserStatus(user: ChatUserModel, online: boolean): void {
    for (let group of this.groups) {
      if (group.system) continue;
      var managed = group.users.find((x) => x.guid === user.guid);
      if (managed) {
        managed.online = online;
      }
    }
  }

  private resetMessages(): void {
    this.messages.length = 0;
    this.messageSearch.filter = this._selectedGroup;
    this.messageSearch.pagerInfo = <DetailedPagerInfo>{
      take: this.messageTake,
      skip: 0,
      getCounts: true,
    };
  }

  public getGroups(): void {
    if (this.didLoadGroups) return;
    this.didLoadGroups = true;
    this.connect().then((connection) => {
      !connection ? undefined : connection.invoke(this.onGroupsEvent);
    });
  }

  public getMessages(): void {
    if (!this._selectedGroup || this.loadingMessages) return;
    this.loadingMessages = true;

    this.connect().then((connection) => {
      !connection
        ? undefined
        : connection.invoke(this.onMessagesEvent, this.messageSearch);
    });
  }

  public deleteMessage(message: MessageModel): void {
    this.connect().then((connection) => {
      !connection
        ? undefined
        : connection.invoke(this.onRemoveMessageEvent, message);
    });
  }

  public deleteGroup(group: GroupModel): void {
    if (!group) return;
    this.connect().then((connection) => {
      !connection
        ? undefined
        : connection.invoke(this.onDeleteGroupEvent, group);
    });
  }

  public addGroup(group: GroupModel): void {
    this.connect().then((connection) => {
      !connection ? undefined : connection.invoke(this.onAddGroupEvent, group);
    });
  }

  public clearUnreadMessages(group: GroupModel): void {
    if (!group) return;
    this.connect().then((connection) => {
      !connection
        ? undefined
        : connection.invoke(this.onClearMessageUnreadEvent, group);
    });
    group.unreadCount = 0;
    this.setUnreadCount();
  }

  public removeFromGroup(group: GroupModel, user: ChatUserModel): void {
    if (!group || !user) return;
    let action = <ChatActionModel<GroupModel>>{
      user: user,
      context: group,
    };

    this.connect().then((connection) => {
      !connection
        ? undefined
        : connection.invoke(this.onRemoveUserFromGroupEvent, action);
    });
  }

  public addToGroup(group: GroupModel, user: ChatUserModel): void {
    if (!group || !user) return;
    let action = <ChatActionModel<GroupModel>>{
      user: user,
      context: group,
    };

    this.connect().then((connection) => {
      !connection
        ? undefined
        : connection.invoke(this.onAddUserToGroupEvent, action);
    });
  }

  public sendMessage(content: string): void {
    if (!this._selectedGroup || this._readonly) return;

    let message = <MessageModel>{
      content: content,
      groupGuid: this._selectedGroup.guid,
    };

    this.connect().then((connection) => {
      !connection ? undefined : connection.invoke(this.onMessageEvent, message);
    });
  }

  private setReadOnly(readonly: boolean) {
    this._readonly = readonly;
    this.readonly.next(this._readonly);
  }

  public selectGroup(group: GroupModel): void {
    if (!group || this._selectedGroup === group) return;

    this._lastSelectedGroup = !this._lastSelectedGroup
      ? group
      : this._selectedGroup;
    this._lastSelectedGroup.selected = false;

    this.clearUnreadMessages(group);

    group.selected = true;
    this._selectedGroup = group;
    this.setReadOnly(false);
    this.selectedGroup.next(this._selectedGroup);

    this.resetMessages();
    this.getMessages();
  }
}
