import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ElementRef,
} from "@angular/core";
import { ChecklistService } from "app/common/services/Core/Checklist.service";
import { SharedService } from "app/common/utility/SharedService";
import { MessageService } from "primeng/primeng";
import { Checklist } from "../Models/Checklist";
import { BaThemeSpinner } from "app/theme/services";
import {
  ChecklistItemTypeEnum,
  EnumChecklistStatus,
} from "app/common/directives/commonEnum";
import { CustomerService } from "app/common/services/customer.service";
import {
  GeneralReportModel,
  ReportSourceModel,
} from "app/model/GeneralReportModel";
import { environment } from "environments/environment";
import { MatDialog } from "@angular/material";
import { ReportComponent } from "app/component/report/report.component";
import { ReportsEnum } from "app/model/ReportsEnum";

@Component({
  selector: "checklistitems",
  templateUrl: "./checklistitems.component.html",
  styleUrls: ["./checklistitems.component.scss"],
})
export class ChecklistitemsComponent implements OnInit {
  @Input() checklist: Checklist;
  @Input() jobPhaseGuid: string;
  @Input() jobGuid: string;
  @Output() close = new EventEmitter();
  @Output() update = new EventEmitter();

  checklistData: any;
  status = 0;
  canEdit: boolean = false;
  boolenOptions = [
    { id: 1, value: "Yes" },
    { id: 2, value: "No" },
  ];
  pageSize: number = 12;

  checklistItems = [];
  public defaultItem: { id: number; value: string } = {
    id: null,
    value: "Select",
  };

  employees: any = [];
  originalEmployees: any = [];
  assignTo: any = null;
  previousAssignTo: any = null;
  assignToHasChanged: boolean = false;
  assignToCanRemove: boolean = false;
  statusBtnTxt: string = "Complete";

  isSuccess: boolean = false;
  isError: boolean = false;
  isDirty: boolean = false;
  countRequired = 0;
  countError = 0;
  Notify = false;
  reportModel: GeneralReportModel;
  showAddEdit: boolean = false;
  slideBar: string = "";
  visibleSidebar: boolean = false;
  showPrint: boolean = false;

  constructor(
    private checklistService: ChecklistService,
    private sharedService: SharedService,
    private notificationsService: MessageService,
    private customerService: CustomerService,
    private elRef: ElementRef,
    private _spinner: BaThemeSpinner,
    private matDialogService: MatDialog
  ) {}

  ngOnInit() {
    this.getEmployees();
    this.LoadChecklistItems(this.checklist.guid);
  }

  ngAfterContentChecked() {
    var vtextarea = this.elRef.nativeElement.querySelectorAll("textarea");
    for (let i = 0; i < vtextarea.length; i++) {
      var height = vtextarea[i].style.height.split("px");
      let pxHeight = Number(height[0]);
      let pxScroll = vtextarea[i].scrollHeight;
      if (pxHeight > pxScroll) {
        vtextarea[i].style.height = 45 + "px";
      } else {
        vtextarea[i].style.height = vtextarea[i].scrollHeight + "px";
      }

      // vtextarea[i].style.height = 45 + 'px';
      //vtextarea[i].style.height +
    }
  }

  validateUser() {
    var user = this.sharedService.loggedInUser;
    if (this.assignTo != null) {
      if (user.CustomerGuid == this.assignTo.CustomerGuid) this.canEdit = true;
    }
    if (this.sharedService.user.isAdmin || this.assignTo == null)
      this.canEdit = true;

    this.showPrint = true;
  }

  getChecklist(guid) {
    this.checklistService.getChecklistByGuid(guid).subscribe((response) => {
      this.checklistData = response.object;
      this.status = this.checklistData.status;
      this.statusBtnTxt =
        this.checklistData.status == 0 ? "Complete" : "Reopen";
      this.assignTo =
        response.object.assignee != null
          ? this.originalEmployees.find(
              (x) => x.CustomerGuid == response.object.assignee.guid
            )
          : null;
      this.previousAssignTo =
        response.object.assignee != null
          ? this.originalEmployees.find(
              (x) => x.CustomerGuid == response.object.assignee.guid
            )
          : null;

      this.assignToCanRemove = response.object.assignee == null ? false : true;
      this.validateUser();
    });
  }

  clickStatus() {
    let checklist = this.checklistData;
    this.status =
      checklist.status != EnumChecklistStatus.Complete
        ? EnumChecklistStatus.Complete
        : EnumChecklistStatus.None;

    checklist.status = this.status;

    if (this.jobPhaseGuid != null) {
      this.updateJobPhaseChecklist(checklist);
    } else {
      this.updateJobChecklist(checklist);
    }
  }

  updateJobPhaseChecklist(checklist) {
    this.checklistService
      .putJobPhaseChecklist(
        this.jobPhaseGuid,
        this.checklistData.guid,
        checklist
      )
      .subscribe((response: any) => {
        if (response.success) {
          this.checklistData = response.object;
          this.checklist = response.object;
          this.statusBtnTxt =
            response.object.status == 0 ? "Complete" : "Reopen";
          this.update.emit(this.checklistData);
          this.assignToHasChanged = false;
          this.assignToCanRemove =
            response.object.assignee == null ? false : true;
          this.isSuccess = true;
          this.notificationsService.add({
            severity: "success",
            summary: "Success",
            detail: "Updated successfully",
          });
        } else {
          this.isError = true;
          this.notificationsService.add({
            severity: "error",
            summary: "Error",
            detail: "Update failed",
          });
        }
      });
  }

  updateJobChecklist(checklist) {
    this.checklistService
      .putJobChecklist(this.jobGuid, this.checklistData.guid, checklist)
      .subscribe((response: any) => {
        if (response.success) {
          this.checklistData = response.object;
          this.checklist = response.object;
          this.statusBtnTxt =
            response.object.status == 0 ? "Complete" : "Reopen";
          this.update.emit(this.checklistData);
          this.assignToHasChanged = false;
          this.assignToCanRemove =
            response.object.assignee == null ? false : true;
          this.isSuccess = true;
          this.notificationsService.add({
            severity: "success",
            summary: "Success",
            detail: "Updated successfully",
          });
        } else {
          this.isError = true;
          this.notificationsService.add({
            severity: "error",
            summary: "Error",
            detail: "Update failed",
          });
        }
      });
  }

  LoadChecklistItems(guid) {
    this.checklistService.getChecklistItems(guid).subscribe((data) => {
      this.checklistItems = data.object;
      this.checklistItems.forEach((x) => {
        var cnt = 0;
        if (x.options.length > 0) {
          var list = JSON.parse(JSON.stringify(x.options)).sort((a, b) =>
            a > b ? 1 : -1
          );

          x.selectionItems = [];
          list.forEach((i) => {
            cnt++;
            x.selectionItems.push({ id: cnt, value: i });
          });
        } else {
          if (x.type == ChecklistItemTypeEnum.Checkbox) {
            x.selectionItems = this.boolenOptions;
          }
        }
        if (
          x.type == ChecklistItemTypeEnum.Checkbox ||
          x.type == ChecklistItemTypeEnum.ComboBox
        ) {
          x.selectedResult = x.selectionItems.find(
            (item) => item.value == x.result
          );
        }
      });

      this.CheckForRequiredFields();
    });
  }

  getEmployees() {
    this.customerService.getAllOrganizationContacts().subscribe((data) => {
      if (data) {
        data.forEach((x) => {
          x.Name = x.FirstName + " " + x.LastName;
        });
      }
      this.employees = data;
      this.originalEmployees = data;
      this.getChecklist(this.checklist.guid);
    });
  }

  assignToChanged(e) {
    this.previousAssignTo = this.assignTo;
    this.assignTo = e.CustomerGuid;
    this.assignToHasChanged = true;
  }

  assignToConfirmed() {
    if (this.assignTo != null) {
      this.checklistData.assignee = { guid: null };
      this.checklistData.assignee.guid = this.assignTo.CustomerGuid;
    }
    if (this.jobPhaseGuid != null) {
      this.updateJobPhaseChecklist(this.checklistData);
    } else {
      this.updateJobChecklist(this.checklistData);
    }
  }

  assignToCleared() {
    this.assignTo = null;
    this.checklistData.assignee = null;
    this.assignToConfirmed();
  }

  assignToCancelled() {
    this.assignTo = this.previousAssignTo;
    this.assignToHasChanged = false;
  }

  UpdateChecklistState() {
    this.checklist.assignee.guid = this.assignTo;
  }

  CheckForRequiredFields() {
    this.countRequired = 0;
    this.checklistItems.forEach((item) => {
      if (item.required && (item.result == null || item.result == ""))
        this.countRequired++;
    });
  }

  onCancel() {
    this.close.emit();
  }

  onSave() {
    this.isError = false;
    this.isSuccess = false;
    this.countRequired = 0;

    this.isDirty = false;
    this._spinner.show();

    this.checklistService
      .putChecklistItems(this.checklistItems)
      .subscribe((response) => {
        if (response.success) {
          //this.close.emit();
        } else {
          this.countError++;
        }

        this._spinner.hide();
        if (this.countError == 0) {
          this.isSuccess = true;
          this.CheckForRequiredFields();
          this.notificationsService.add({
            severity: "success",
            summary: "Success",
            detail: "Updated items successfully",
          });
          //this.close.emit();
        } else {
          this.isError = true;
          this.notificationsService.add({
            severity: "error",
            summary: "Error",
            detail: "Item failed to update",
          });
          this.isDirty = true;
        }
      });
  }

  SelectionChanged(e, dataItem) {
    dataItem.result = e.value;
    this.isDirty = true;
    this.defaultItem = null;
    this.CheckForRequiredFields();
  }

  /**
   * prints report for checklists
   */
  printParent() {
    let reportName =
      this.checklist.jobPhase || this.jobPhaseGuid
        ? ReportsEnum.JobPhaseChecklistReport
        : ReportsEnum.ChecklistReport;
    this.reportModel = new GeneralReportModel();
    var reportSource = new ReportSourceModel();
    reportSource.report = `NAME/${environment.reportDataSource}/${reportName}`;
    reportSource.parameters = {
      env: environment.reportServer,
      CheckListGuid: this.checklist.guid,
    };
    this.reportModel.reportSource = reportSource;
    this.matDialogService.open(ReportComponent, {
      width: "800px",
      height: "500px",
      panelClass: "dialog-report",
      data: this.reportModel,
    });
  }
  /**
   * hide slider
   */
  onHideSliderHide() {
    this.visibleSidebar = false;
    this.slideBar = "";
  }
}
