<div class="modal-dialog modal-sm">
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close" (click)="close()">&times;</button>
      <h4 class="modal-title">{{ title }}</h4>
    </div>
    <div class="modal-body">
      <p>{{ message }}</p>
      <p>{{ message2 }}</p>
      <div>
        <label class="checkbox-inline custom-checkbox nowrap">
          <input
            type="checkbox"
            (change)="updateDependents($event.target.checked)"
          />
          <span class="fontSize">Reschedule phase and its dependents</span>
        </label>
      </div>
      <div style="margin-top: 10px">
        <label class="fontSize">Delay the selected phases by </label>
        <input
          class="inputBox fontSize"
          (keyup)="restrictMaxValue($event)"
          onKeyPress="if(this.value.length == 3) return false"
          type="number"
          max="365"
          [(ngModel)]="enteredDays"
        />
        <label class="fontSize">days </label>
      </div>
      <div class="form-group cdrop" style="margin-top: 10px; margin-bottom: 0">
        <kendo-floatinglabel
          text="What is the reason for this change"
          [ngClass]="{ 'k-state-filled': true }"
        >
          <kendo-dropdownlist
            kendoTextBox
            [valuePrimitive]="true"
            (selectionChange)="UpdateChangeReason($event)"
            [data]="changeReasonList"
            textField="Reason"
            valueField="Id"
            required
          >
          </kendo-dropdownlist>
        </kendo-floatinglabel>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn darkbtn"
        (click)="Confirm()"
        [disabled]="!hasReasonSelected"
      >
        Ok
      </button>
      <button type="button" class="btn btn-default" (click)="close()">
        Cancel
      </button>
    </div>
  </div>
</div>
