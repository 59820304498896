<div class="widgets commonFixedSidebar addedit-lineitem-pg">
  <div class="row">
    <div class="col-md-12 ht100">
      <div class="rightcontent">
        <div class="sideBarHeader">
          <h1>{{ headerText }} Line Item</h1>
        </div>
        <div class="tabBlk">
          <form #myForm="ngForm" class="form-horizontal">
            <div class="fixed-layout hoverflow">
              <div class="mt15">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-lg-5 col-md-5 col-sm-5 col-xs-12">
                        <div class="form-group Mandat">
                          <kendo-floatinglabel text="Status">
                            <kendo-combobox
                              [data]="statusLst"
                              [valuePrimitive]="true"
                              [filterable]="true"
                              (filterChange)="filterStatus($event)"
                              [textField]="'Status'"
                              [(ngModel)]="workOrderlineItem.StatusId"
                              name="Status"
                              (valueChange)="onStatusChange()"
                              required
                              [valueField]="'Id'"
                            ></kendo-combobox>
                          </kendo-floatinglabel>
                        </div>
                      </div>
                      <div class="id-wrap">
                        <!--Id: 61-->
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb30">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="grey-bg p5">
                          <div class="form-group">
                            <span class="ui-float-label">
                              <input
                                type="text"
                                [(ngModel)]="workOrderlineItem.LineItemName"
                                id="LineItemName"
                                name="LineItemName"
                                #LineItemName="ngModel"
                                maxlength="50"
                                class="form-control"
                                pInputText
                                required
                                [ngClass]="{
                                  'has-error':
                                    LineItemName.invalid &&
                                    (LineItemName.dirty || LineItemName.touched)
                                }"
                              />
                              <label
                                for="text"
                                class="form-control-label"
                                required
                                >Line Item Name
                                <span class="text-red">*</span>
                              </label>
                            </span>
                          </div>
                          <div class="form-group mb0">
                            <span class="ui-float-label">
                              <textarea
                                class="form-control"
                                name="Comments"
                                maxlength="500"
                                [(ngModel)]="workOrderlineItem.Description"
                                rows="3"
                                pInputText
                              ></textarea>
                              <label for="text" class="form-control-label"
                                >Description</label
                              >
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb10">
                    <div class="grey-bg p5 pt20">
                      <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div class="form-group">
                            <label class="fu">Target Date</label>
                            <span class="ui-float-label">
                              <kendo-datepicker
                                class="full-date"
                                name="TargetDate"
                                [(ngModel)]="workOrderlineItem.TargetDate"
                                #TargetDate="ngModel"
                              ></kendo-datepicker>
                            </span>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div class="form-group mb0">
                            <label for="" class="fu">Scheduled Date</label>
                            <span class="ui-float-label">
                              <kendo-datepicker
                                class="full-date"
                                name="ScheduledDate"
                                [(ngModel)]="workOrderlineItem.ScheduledDate"
                                #ScheduledDate="ngModel"
                              >
                              </kendo-datepicker>
                            </span>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div class="form-group mb0">
                            <label for="" class="fu">Completed On</label>
                            <span class="ui-float-label">
                              <kendo-datepicker
                                class="full-date"
                                name="CompleteDate"
                                (valueChange)="
                                  changeCompleteDateStatus(
                                    workOrderlineItem.CompleteDate
                                  )
                                "
                                [(ngModel)]="workOrderlineItem.CompleteDate"
                                #CompleteDate="ngModel"
                              ></kendo-datepicker>
                            </span>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div class="form-group">
                            <span class="ui-float-label p2-float-label">
                              <input
                                type="number"
                                class="form-control bg-white"
                                min="1"
                                pInputText
                                name="DaysToComplete"
                                [(ngModel)]="workOrderlineItem.DaysToComplete"
                                #DaysToComplete="ngModel"
                              />
                              <label for="number" class="form-control-label"
                                >Days To Complete</label
                              >
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="row" *ngIf="workOrderlineItem.StartDate">
                        <div class="col-sm-6">
                          <p class="startedon_para mt0">
                            This line item was marked started on :
                            <span
                              >{{
                                workOrderlineItem.StartDate | date : "shortDate"
                              }}
                              <span class="calin-icons">
                                <i
                                  *ngIf="!EnableStartDateEdit"
                                  class="fa fa-pencil"
                                  title="Edit"
                                  (click)="editStartDate(true)"
                                ></i>
                                <i
                                  class="fa fa-ban"
                                  *ngIf="EnableStartDateEdit"
                                  title="Cancel"
                                  (click)="editStartDate(false)"
                                ></i></span
                            ></span>
                          </p>
                        </div>
                        <div
                          *ngIf="EnableStartDateEdit"
                          class="col-lg-6 col-md-6 col-sm-6 col-xs-12"
                        >
                          <div class="form-group mb5">
                            <label class="fu">Started On</label>
                            <span class="ui-float-label">
                              <kendo-datepicker
                                class="full-date"
                                name="StartDate"
                                [(ngModel)]="workOrderlineItem.StartDate"
                                #StartDate="ngModel"
                              ></kendo-datepicker>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb20">
                    <div class="p5">
                      <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div class="form-group">
                            <label for="" class="fu">Remind Me On Day</label>
                            <span class="ui-float-label">
                              <kendo-datepicker
                                class="full-date"
                                name="RemindMeOnDay"
                                [(ngModel)]="workOrderlineItem.RemindMeOnDay"
                                #RemindMeOnDay="ngModel"
                              >
                              </kendo-datepicker>
                            </span>
                          </div>
                        </div>

                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div class="form-group">
                            <kendo-floatinglabel text="Category">
                              <kendo-combobox
                                [data]="lstCategory"
                                [valuePrimitive]="true"
                                [filterable]="true"
                                (filterChange)="filterCategory($event)"
                                [textField]="'CatetoryName'"
                                [(ngModel)]="workOrderlineItem.CategoryId"
                                name="CategoryId"
                                [valueField]="'Id'"
                              >
                              </kendo-combobox>
                            </kendo-floatinglabel>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div class="additem assignto-item clearfix">
                      <h3 class="bg-none p0">Assign To</h3>
                      <div
                        class="addbtn"
                        (click)="
                          showSlides3 = 'LineItemAssignTo';
                          lstAssignedResource = workOrderlineItem.Resources
                        "
                      >
                        <a
                          data-placement="top"
                          data-toggle="tooltip"
                          title="Assign To"
                        >
                          <i aria-hidden="true" class="fa fa-plus-circle"></i>
                        </a>
                      </div>
                    </div>
                    <div class="assigned-items">
                      <ul>
                        <li
                          *ngFor="let resource of workOrderlineItem.Resources"
                        >
                          <a
                            class="cancelbtnHead ui-sidebar-close"
                            (click)="deleteAssignedResource(resource.Id)"
                          >
                            <span class="pi pi-times"></span>
                          </a>
                          <span
                            class="short-name"
                            [ngClass]="
                              resource.IsExternal ? 'bg-green' : 'bg-blue'
                            "
                            >{{ getInitals(resource.Name) }}</span
                          ><span class="hover-content">{{
                            resource.Name
                          }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="h50"></div>
            </div>
            <div class="fixbtnwrap">
              <div class="btnwrap__center">
                <button
                  type="button"
                  (click)="saveWorkOrderLineItem()"
                  class="btn darkbtn"
                  *ngIf="myForm.dirty || isUpdated"
                  [disabled]="!myForm.valid"
                >
                  <i class="fa fa-floppy-o" aria-hidden="true"></i>
                  {{ buttonText }}
                </button>
                <button
                  type="button"
                  class="cross btn bthpn2"
                  (click)="onCanel()"
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div [ngClass]="showSlides3 != '' ? 'sidebar3 open' : 'sidebar3'">
  <a class="cancelbtnHead ui-sidebar-close" (click)="showSlides3 = ''">
    <span class="pi pi-times"></span>
  </a>
  <AssignResource-Component
    *ngIf="showSlides3 == 'LineItemAssignTo'"
    [AssignedResourceList]="lstAssignedResource"
    [StartDate]="workOrderlineItem.ScheduledDate"
    [workOrderId]="WorkOrderID"
    [LineItemId]="lineItemId"
    (sendData)="updateAssignedResource($event)"
  ></AssignResource-Component>
</div>
