import { Component, OnInit, Input } from "@angular/core";
import { GeneralReportModel } from "app/model/GeneralReportModel";

@Component({
  selector: "app-general-reports",
  templateUrl: "./general-reports.component.html",
  styleUrls: ["./general-reports.component.scss"],
})
export class GeneralReportsComponent implements OnInit {
  viewerContainerStyle: any;
  constructor() {}
  @Input() reportData: GeneralReportModel;
  title: string = "Reports";
  ngOnInit() {
    this.viewerContainerStyle = {
      position: "relative",
      // width: '1000px',
      height: "100%",
      // ['font-family']: 'ms sans serif'
    };
  }

  pageReady() {
    document.getElementById("trv-main-menu-export-format-list").innerHTML = [
      '<li tabindex="-1" class="k-item k-menu-item k-first" role="menuitem"><a tabindex="-1" href="#" data-command="telerik_ReportViewer_export" data-command-parameter="PDF" class="k-link k-menu-link"><span>Acrobat (PDF) file</span></a></li>',
      '<li tabindex="-1" class="k-item k-menu-item" role="menuitem"><a tabindex="-1" href="#" data-command="telerik_ReportViewer_export" data-command-parameter="CSV" class="k-link k-menu-link"><span>CSV (comma delimited)</span></a></li>',
      '<li tabindex="-1" class="k-item k-menu-item" role="menuitem"><a tabindex="-1" href="#" data-command="telerik_ReportViewer_export" data-command-parameter="XLSX" class="k-link k-menu-link"><span>Excel Worksheet</span></a></li>',
      '<li tabindex="-1" class="k-item k-menu-item" role="menuitem"><a tabindex="-1" href="#" data-command="telerik_ReportViewer_export" data-command-parameter="PPTX" class="k-link k-menu-link"><span>PowerPoint Presentation</span></a></li>',
      '<li tabindex="-1" class="k-item k-menu-item" role="menuitem"><a tabindex="-1" href="#" data-command="telerik_ReportViewer_export" data-command-parameter="RTF" class="k-link k-menu-link"><span>Rich Text Format</span></a></li>',
      '<li tabindex="-1" class="k-item k-menu-item" role="menuitem"><a tabindex="-1" href="#" data-command="telerik_ReportViewer_export" data-command-parameter="IMAGE" class="k-link k-menu-link"><span>TIFF file</span></a></li>',
      '<li tabindex="-1" class="k-item k-menu-item k-last" role="menuitem"><a tabindex="-1" href="#" data-command="telerik_ReportViewer_export" data-command-parameter="DOCX" class="k-link k-menu-link"><span>Word Document</span></a></li>',
    ].join("");
  }
}
