export class UpdatePhaseScheduleRequestModel {
  PhaseId: number;
  StartDate: Date;
  UpdateType: number;
  IsEstimatedUpdate?: boolean;
  UpdateDependents?: boolean;
  PhaseChangeReason: number;
  Comments: string;
  DependentAction: number;
  DaysToComplete: number;
  HasMoreReasons?: boolean;
  Reasons?: UpdateReason[] = [];
  sendNotification?: boolean;
  constructor() {}
}
export class ScheduleChangeModel {
  Id: number;
  ChangedOn: Date;
  PhaseName: string;
  ChangeReason: string;
  ChangedBy: string;
  constructor() {}
}

export class UpdatePhaseScheduleArrayRequest {
  Phases: UpdatePhaseScheduleRequestModel[];
}

export class UpdateReason {
  DateType: string;
  UpdateReasons: ScheduleReason;
  StartDate: Date;
  UpdateType: number;
}

export class ScheduleReason {
  Comments: string;
  DependentAction: number;
  reasonId: number;
}
