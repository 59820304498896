import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { FilterDescriptor, State } from "@progress/kendo-data-query";
import { TaxingAuthority } from "app/model/TaxingAuthority";
import { environment } from "environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { finalize, map } from "rxjs/operators";
import { BaThemeSpinner } from "../../theme/services/baThemeSpinner/baThemeSpinner.service";

@Injectable({
  providedIn: "root",
})
export class TaxingAuthoritiesService extends BehaviorSubject<GridDataResult> {
  private url: string = `${environment.baseEstimateUrl}OrgTaxAuthority/`;

  constructor(private http: HttpClient, private spinner: BaThemeSpinner) {
    super(null);
  }

  /**
   * Handles subscription of the async grid data
   * @param orgGuid Organization Id
   * @param state Grid state
   */
  query(orgGuid: string, state: State): void {
    this.fetch(orgGuid, state).subscribe((x: GridDataResult) => super.next(x));
  }

  /**
   * Gets all tax authority data as grid data
   * @param orgGuid Organization Id
   * @returns Observable of grid data
   */
  queryAll(orgGuid: string): Observable<GridDataResult> {
    this.spinner.show();
    return this.getAll(orgGuid).pipe(
      map((response) => <GridDataResult>{ data: response }),
      finalize(() => this.spinner.hide())
    );
  }

  /**
   * Gets paginated tax authority data ready for display in grid
   * @param orgGuid Organization Id
   * @param state Grid state
   * @returns Paginated tax authority grid data
   */
  private fetch(orgGuid: string, state: State): Observable<GridDataResult> {
    this.spinner.show();
    let url = this.getUrlPaginated(orgGuid, state);
    return this.http.get(url).pipe(
      map(
        (response) =>
          <GridDataResult>{
            data: response["taxingAuthorities"],
            total: response["totalRecords"],
          }
      ),
      finalize(() => this.spinner.hide())
    );
  }

  /**
   * Post request to API to create a new taxing authority
   * @param taxAuth New tax authority model
   * @returns Created tax authority model
   */
  create(taxAuth: TaxingAuthority): Observable<TaxingAuthority> {
    return this.http.post<TaxingAuthority>(`${this.url}create`, taxAuth);
  }

  /**
   * Delete request to API to remove a taxing authority
   * @param id Tax auhtority id to delete
   * @returns
   */
  delete(id: number): Observable<Object> {
    return this.http.delete(`${this.url}delete/${id}`);
  }

  /**
   * Put request to API to edit a taxing authority entity
   * @param taxAuth Tax authority model to edit
   * @returns Edited tax authority model
   */
  edit(taxAuth: TaxingAuthority): Observable<TaxingAuthority> {
    return this.http.put<TaxingAuthority>(`${this.url}edit`, taxAuth);
  }

  /**
   * Get all taxing auhtorities
   * @param orgGuid Organization Id
   * @param onlyActives Flag to get only non-deleted data
   * @returns Observable of taxing authorities data
   */
  getAll(
    orgGuid: string,
    onlyActives: boolean = false
  ): Observable<TaxingAuthority[]> {
    return this.http.get<TaxingAuthority[]>(
      `${this.url}index/${orgGuid}?onlyActives=${onlyActives}`
    );
  }

  /**
   * Builder of the url for the API request to get paginated data
   * @param orgGuid Organization Id
   * @param state Grid state
   * @returns Url with parameters to get paginated tax authorities from API
   */
  private getUrlPaginated(orgGuid: string, state: State): string {
    let baseUrl = `${this.url}organization?orgGuid=${orgGuid}&skip=${state.skip}&take=${state.take}`;
    const queryStr = state.filter.filters
      .map(
        (f: FilterDescriptor) =>
          `${f.field}=${f.value}&${f.field}Operator=${f.operator}`
      )
      .join("&");
    return queryStr === "" ? baseUrl : baseUrl + "&" + queryStr;
  }
}
