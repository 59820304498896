<div class="row">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <h3 class="pt">Shipping Information</h3>
  </div>
  <form novalidate #myForm="ngForm">
    <kendo-grid
      [data]="shippingMethods"
      (edit)="editHandler($event)"
      (cancel)="cancelHandler($event)"
      (save)="saveHandler($event)"
    >
      <kendo-grid-command-column title="" width="80">
        <ng-template kendoGridCellTemplate>
          <div class="acButton">
            <button kendoGridSaveCommand [disabled]="myForm.invalid">
              <span title="{{ 'common.save' | translate }}">
                <i class="fa fa-floppy-o" aria-hidden="true"></i>
              </span>
            </button>
            <button kendoGridCancelCommand>
              <span title="{{ 'common.cancel' | translate }}">
                <i class="fa fa-ban" aria-hidden="true"></i>
              </span>
            </button>
          </div>
          <div class="dropdown cud">
            <i class="fa fa-ellipsis-v" id="menu1" data-toggle="dropdown"> </i>
            <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
              <li kendoGridEditCommand>
                <a class="edit" title="Edit">
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                  {{ "common.edit" | translate }}
                </a>
              </li>
            </ul>
          </div>
        </ng-template>
      </kendo-grid-command-column>
      <kendo-grid-column
        field="displayName"
        title="Shipping Method"
      ></kendo-grid-column>
      <kendo-grid-column
        field="rate"
        editor="numeric"
        title="Shipping Cost"
        width="100"
        [style]="{ 'text-align': 'right' }"
        [format]="'n2'"
      >
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <input
            [(ngModel)]="dataItem.rate"
            name="Rate"
            class="k-textbox"
            type="number"
            maxlength="10"
            required
          />
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="active"
        editor="boolean"
        title="Is Active"
        width="100"
      >
        <ng-template kendoGridEditTemplate let-dataItem="dataItem">
          <input
            [(ngModel)]="dataItem.active"
            name="Active"
            type="checkbox"
            formnovalidate
          />
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </form>
</div>
