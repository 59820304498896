<div class="modal-dialog modal-sm">
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close" (click)="close()">&times;</button>
      <h4 class="modal-title">{{ title }}</h4>
    </div>
    <div class="modal-body">
      <div class="row p2row-bg pt-2">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="form-group">
            <kendo-textbox-container
              floatingLabel="Summary"
              [ngClass]="{ 'k-state-filled': true }"
            >
              <input
                [(ngModel)]="Calendar.Summary"
                kendoTextBox
                name="summary"
                #summary="ngModel"
                floatLabelType="Auto"
                type="text"
                class="form-control"
              />
            </kendo-textbox-container>
          </div>
        </div>

        <div
          class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-20 colorpicker"
          *ngIf="Calendar.backgroundColor"
        >
          <label>Background Color</label>
          <div class="form-group">
            <input
              class="colorpick form-control"
              readonly
              [cpPosition]="'left'"
              [(colorPicker)]="Calendar.backgroundColor"
              [style.background]="Calendar.backgroundColor"
              (colorPickerChange)="updateColor = true"
            />
            <span
              class="color-icon"
              title="Color Picker"
              [(colorPicker)]="Calendar.backgroundColor"
              (colorPickerChange)="updateColor = true"
            >
              <img src="./assets/images/cp.svg" />
            </span>
          </div>
        </div>

        <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" *ngIf="Calendar.foregroundColor">
            <div class="form-group">
              <input class="colorpick form-control" readonly [cpPosition]="'left'"
                [(colorPicker)]="Calendar.foregroundColor" [style.background]="Calendar.foregroundColor"
                (colorPickerChange)="updateColor = true;" />
              <span class="color-icon" title="Color Picker" [(colorPicker)]="Calendar.foregroundColor"
                (colorPickerChange)="updateColor = true;">
                <img src="./assets/images/cp.svg">
              </span>
            </div>
          </div> -->

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="form-group mb5">
            <kendo-textbox-container
              floatingLabel="Description"
              [ngClass]="{ 'k-state-filled': true }"
            >
              <textarea
                class="form-control mb0"
                kendoTextBox
                [(ngModel)]="Calendar.Description"
                name="Description"
                #Description="ngModel"
                rows="5"
                cols="50"
                style="width: 100%; height: 60px !important; resize: vertical"
                floatLabelType="Auto"
                type="text"
              ></textarea>
            </kendo-textbox-container>
            <!-- <ejs-textbox [multiline]='true' [(ngModel)]="Calendar.Description" name="description"
                #description="ngModel" multiline="true">
              </ejs-textbox> -->
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="form-group timezone-sel customDropDown">
            <ejs-dropdownlist
              [dataSource]="timezones"
              [fields]="{ text: 'Text', value: 'Value' }"
              [placeholder]="'Select Timezone'"
              [(ngModel)]="Calendar.Timezone"
              floatLabelType="Auto"
            >
            </ejs-dropdownlist>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn darkbtn dpbtn"
        (click)="Confirm()"
        [disabled]="!Calendar.Summary || Calendar.Summary.trim() == ''"
      >
        {{ BtnText }}
      </button>
      <button type="button" class="btn btn-default" (click)="close()">
        Cancel
      </button>
    </div>
  </div>
</div>
