<div class="estimate-theme mat-dialog mat-dialog--l">
  <mat-dialog-content>
    <h2 class="mat-dialog__header">Select Group</h2>
    <p class="mat-dialog__subtitle padding-bottom-2">
      Select the Group in which the Product will be add added to.
    </p>
    <form [formGroup]="groupsForm">
      <div class="dropdown-pentair-std">
        <label class="dropdown-pentair-std__title">Group Name</label>
        <p-dropdown
          [options]="groupSelectItems"
          formControlName="groupsControl"
          appendTo="body"
          placeholder="Select"
          styleClass="prime-dropdown w-100"
        ></p-dropdown>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions
    class="mat-dialog__actions mat-dialog__actions--align-right"
  >
    <button class="button button--secondary button--md" matDialogClose>
      Cancel
    </button>
    <button
      class="button button--primary button--md"
      (click)="selectProduct()"
      [disabled]="!groupsForm.valid"
    >
      Save
    </button>
  </mat-dialog-actions>
</div>
