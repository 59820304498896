import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { DialogService } from "ng2-bootstrap-modal";
import { ConfirmComponent } from "../dialog/dialog.component";
import { BaThemeSpinner } from "app/theme/services";
import { MessageService } from "primeng/components/common/messageservice";
import { QuickBooksImportModel } from "app/model/Core/QuickBooksImportModel";
import { EntityTypeEnum } from "app/model/Core/EntityTypeEnum";
import { OrganizationModel } from "app/model/organizationModel";
import { OrganizationService as OrganizationServiceCore } from "app/common/services/Core/Organization.service";
import { OrganizationService } from "app/common/services/organization.service";

@Component({
  selector: "quickbooks-import",
  templateUrl: "./QuickBooksImport.component.html",
  styleUrls: ["./QuickBooksImport.scss"],
})
export class QuickBooksImportComponent implements OnInit {
  public model: QuickBooksImportModel = <QuickBooksImportModel>{};
  EntityTypeEnum = EntityTypeEnum;
  public vendors: boolean;
  public products: boolean;
  public employees: boolean;
  public customers: boolean;
  public invoices: boolean;
  public bills: boolean;

  @Input() organization: OrganizationModel;
  @Output() organizationChange: EventEmitter<OrganizationModel> =
    new EventEmitter<OrganizationModel>();

  constructor(
    private _spinner: BaThemeSpinner,
    private dialogService: DialogService,
    private notificationsService: MessageService,
    private organizationServiceCore: OrganizationServiceCore,
    private organizationService: OrganizationService
  ) {}

  public ngOnInit(): void {
    this.vendors = this.organization.QBSyncVendors;
    this.products = this.organization.QBSyncProducts;
    this.employees = this.organization.QBSyncEmployees;
    this.customers = this.organization.QBSyncCustomers;
    this.invoices = this.organization.QBSyncInvoices;
    this.bills = this.organization.QBSyncBills;
  }

  public onSubmit(entityType: EntityTypeEnum): void {
    this.model.entities = entityType;

    this.dialogService
      .addDialog(ConfirmComponent, {
        title: "THIS CANNOT BE UNDONE.",
        message: `Duplicate imports will be ignored. Are you sure you want to continue?`,
        toShow: false,
        isNotification: false,
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          this._spinner.show();
          let subscription = this.organizationServiceCore
            .importQuickbooks(this.model)
            .subscribe((response: any) => {
              this._spinner.hide();

              if (response !== null) {
                if (
                  response.success === true &&
                  response.object !== null &&
                  response.response.code === 200
                ) {
                  let success = true;
                  response.success && response.object;
                  this.notificationsService.add({
                    severity: success ? "success" : "error",
                    summary: "QuickBooks Import",
                    detail: success
                      ? "Complete!"
                      : response.Response.Description,
                  });
                  this.organizationService
                    .getOrganizationById(this.organization.ID)
                    .subscribe((o) => {
                      this.organization = o;
                      this.organizationChange.emit(this.organization);
                    });
                } else {
                  this.notificationsService.add({
                    severity: "error",
                    summary: "QuickBooks Import",
                    detail: response.Response.Description,
                  });
                }
              }

              if (subscription) subscription.unsubscribe();
            });
        }
      });
  }

  invoicesClick() {
    this.products = !this.invoices || !!this.organization.QBProductsImported;
    this.vendors = !this.invoices || !!this.organization.QBVendorsImported;
    this.customers = !this.invoices || !!this.organization.QBCustomersImported;
    this.invoices = !this.invoices;
  }

  organizationUpdated() {
    this.organization.QBSyncVendors = this.vendors;
    this.organization.QBSyncProducts = this.products;
    this.organization.QBSyncEmployees = this.employees;
    this.organization.QBSyncCustomers = this.customers;
    this.organization.QBSyncInvoices = this.invoices;
    this.organization.QBSyncBills = this.bills;
    this.organizationChange.emit(this.organization);
  }
}
