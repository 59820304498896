<div class="widgets commonFixedSidebar assignresource-pg">
  <div class="row">
    <div class="col-md-12 ht100">
      <div class="rightcontent">
        <div class="sideBarHeader">
          <h1>{{ headerText }}</h1>
        </div>
        <div class="tabBlk">
          <form #myForm="ngForm" class="form-horizontal">
            <div class="fixed-layout hoverflow">
              <div class="">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 mt15">
                    <div class="form-group mb5">
                      <label class="fu">Available Date</label>
                      <span class="ui-float-label">
                        <kendo-datepicker
                          class="full-date"
                          name="startDate"
                          [(ngModel)]="StartDate"
                          #startDate="ngModel"
                        ></kendo-datepicker>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="existingcustomer">
                  <div class="d-flex">
                    <span>
                      <label class="radio-inline custom-radio nowrap">
                        <input
                          type="radio"
                          name="radio"
                          id="Status"
                          checked="true"
                          (click)="filterResources('internal')"
                        />
                        <span>Employees</span>
                      </label>
                    </span>
                    <span class="lm30">
                      <label class="radio-inline custom-radio nowrap">
                        <input
                          type="radio"
                          name="radio"
                          id="Status"
                          (click)="filterResources('external')"
                        />
                        <span>External</span>
                      </label>
                    </span>
                    <div class="addbtn ml-4">
                      <a
                        data-placement="top"
                        data-toggle="tooltip"
                        title="Add"
                        (click)="ShowSlide = true"
                      >
                        <i aria-hidden="true" class="fa fa-plus-circle"></i>
                      </a>
                    </div>
                  </div>
                  <div class="d-flex mt-2">
                    <div class="flex-grow-1" *ngIf="selectedType == 'internal'">
                      <div class="form-group">
                        <kendo-floatinglabel text="Business Unit">
                          <kendo-combobox
                            [data]="lstBusinessUnit"
                            [valuePrimitive]="true"
                            [filterable]="true"
                            (filterChange)="filterBussinessUnit($event)"
                            [textField]="'BusinessUnit1'"
                            [(ngModel)]="BusinessUnitID"
                            name="BusinessUnitID"
                            [valueField]="'ID'"
                          ></kendo-combobox>
                        </kendo-floatinglabel>
                      </div>
                    </div>
                    <div class="flex-grow-1" *ngIf="selectedType == 'external'">
                      <div class="form-group">
                        <kendo-floatinglabel text="Trade">
                          <kendo-combobox
                            [data]="lstTrade"
                            [valuePrimitive]="true"
                            [filterable]="true"
                            (filterChange)="filterTrade($event)"
                            [textField]="'TradeName'"
                            [(ngModel)]="TradeID"
                            name="TradeID"
                            [valueField]="'ID'"
                          ></kendo-combobox>
                        </kendo-floatinglabel>
                      </div>
                    </div>
                    <div class="actbtn ml-2">
                      <a (click)="getResourcesList()" title="Find">
                        <i class="fa fa-search" aria-hidden="true"></i>
                      </a>
                    </div>
                  </div>

                  <div class="row">
                    <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
											<div class="jsearch top_search p0">
												<div class="input-group">
													<input class="inputS" [(ngModel)]="filterQuery" name="filtersearch"
														(ngModelChange)="filterGrid()"
														placeholder="{{'common.search_for'|translate}}" type="text">
													<i class="fa fa-search" aria-hidden="true"></i>
												</div>
											</div>
										</div> -->
                    <div
                      class="col-xs-12 col-sm-12 col-md-12 col-lg-12 assignresource-internal-tab"
                    >
                      <div class="x_panel">
                        <div class="x_content mb0">
                          <div class="assignto">
                            <kendo-grid
                              class="assigntogrid"
                              [data]="gridView"
                              [pageSize]="pageSize"
                              #grid="kendoGrid"
                              [skip]="skip"
                              [pageable]="true"
                              (pageChange)="pageChange($event)"
                              [sortable]="{
                                allowUnsort: allowUnsort,
                                mode: multiple ? 'multiple' : 'single'
                              }"
                              [sort]="sort"
                              (sortChange)="sortChange($event)"
                            >
                              <kendo-grid-column
                                headerClass="gridHeading"
                                title=""
                                field="Id"
                                class="gridRow"
                                width="30"
                              >
                                <ng-template
                                  kendoGridCellTemplate
                                  let-dataItem="dataItem"
                                >
                                  <label
                                    class="checkbox-inline custom-checkbox nowrap"
                                  >
                                    <input
                                      type="checkbox"
                                      name="selectedData"
                                      [checked]="dataItem.selected"
                                      (change)="
                                        selectedResource($event, dataItem)
                                      "
                                    />
                                    <span class="grid-checkbox"></span>
                                  </label>
                                </ng-template>
                              </kendo-grid-column>
                              <kendo-grid-column
                                headerClass="gridHeading"
                                class="gridRow text-center"
                                width="60"
                                title=""
                              >
                                <ng-template
                                  kendoGridCellTemplate
                                  let-dataItem="dataItem"
                                >
                                  <span
                                    class="short-name"
                                    [ngClass]="
                                      dataItem.IsExternal
                                        ? 'bg-green'
                                        : 'bg-blue'
                                    "
                                    >{{ getInitals(dataItem.Name) }}</span
                                  >
                                </ng-template>
                              </kendo-grid-column>
                              <kendo-grid-column
                                headerClass="gridHeading"
                                class="gridRow"
                                field="{{ fieldName }}"
                                title="Name"
                              >
                              </kendo-grid-column>
                            </kendo-grid>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="fixbtnwrap">
              <div class="btnwrap__center">
                <button
                  type="button"
                  class="btn darkbtn"
                  *ngIf="assignResourceModel.Resources.length > 0"
                  (click)="onSave()"
                >
                  <i class="fa fa-floppy-o" aria-hidden="true"></i> Save
                </button>
                <button
                  type="button"
                  class="cross btn bthpn2"
                  (click)="oncancel('cancel')"
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div [ngClass]="ShowSlide ? 'sidebar3 open' : 'sidebar3'">
  <a class="cancelbtnHead ui-sidebar-close" (click)="ShowSlide = false">
    <span class="pi pi-times"></span>
  </a>
  <add-Contact
    *ngIf="selectedType == 'internal'"
    [uId]="0"
    [type]="'employee'"
    [isQuickAdd]="true"
    [BussinessUnitID]="BusinessUnitID"
    (updateEvent)="ShowSlide = false; getResourcesList()"
    [noNavigation]="true"
  ></add-Contact>
  <add-contract
    *ngIf="selectedType == 'external'"
    [vendorId]="0"
    [isQuickAdd]="true"
    [TradeId]="TradeID"
    (updateEvent)="ShowSlide = false; getResourcesList()"
  ></add-contract>
</div>
