import { Injectable } from "@angular/core";
import {
  MarginMarkupStatus,
  NewEstimateWorksheetInfo,
} from "app/model/EstimateWorksheetInfo";
import { WorksheetDetailFilterQuery } from "app/model/WorksheetDetailFilterQuery";
import { WorksheetDetailFromDB } from "app/model/WorksheetDetailObject";
import { BehaviorSubject, forkJoin, Observable, Subject } from "rxjs";
import { SharedService } from "../utility/SharedService";
import { EstimateWorksheetService } from "./estimate-worksheet.service";
import * as _ from "lodash";
import {
  AddingCompleteEvent,
  AddingProcess,
  CompleteEvent,
  DeletingCompleteEvent,
  DeletingProcess,
  EditingCompleteEvent,
  IsEditing,
  ReviewingProcess,
  ViewFilters,
} from "app/model/EstimateWorksheetEventsFromService";
import { EstimateView } from "app/model/EstimateView";
import { WorksheetWorkAreaService } from "./worksheetWorkArea.service";
import { WorksheetWorkArea } from "app/model/WorksheetWorkArea";
import { SettingService } from "./settings.service";

@Injectable({
  providedIn: "root",
})
export class EstimateWorksheetSharedDataService {
  // Events
  readonly onReady = new BehaviorSubject(false);
  readonly onDeleteWorksheetDetail = new Subject<DeletingProcess>();
  readonly onAddigProccess = new Subject<AddingProcess>();
  readonly onEditingProcess = new Subject<IsEditing>();
  readonly onCompleteEditingProcess = new Subject<EditingCompleteEvent>();
  readonly onCompleteAddingProcess = new Subject<AddingCompleteEvent>();
  readonly onCompleteRestoreProcess = new Subject<CompleteEvent>();
  readonly onCompleteDeletingProcess = new Subject<DeletingCompleteEvent>();
  readonly onReviewWorksheetDetail = new Subject<ReviewingProcess>();
  readonly onError = new Subject<Error>();
  readonly onOpenAddedParams = new Subject<boolean>();

  estimateWorksheetId: string;
  estimateWorksheetData: BehaviorSubject<NewEstimateWorksheetInfo>;
  estimateViews: BehaviorSubject<EstimateView[]>;
  currentFiltersForViews = new BehaviorSubject<ViewFilters>({});
  worksheetDetailsOnTab: BehaviorSubject<WorksheetDetailFromDB[]>;
  workAreas: BehaviorSubject<WorksheetWorkArea[]>;
  isLocked: boolean;
  marginMarkupKey: string = "MARGIN_MARKUP_DISPLAY_TYPE";
  marginMarkupValue: number = 0;
  marginMarkupOptions: typeof MarginMarkupStatus = MarginMarkupStatus;
  isMarkup: boolean = false;
  isCustomerSheetView = new BehaviorSubject<boolean>(false);

  constructor(
    private worksheetHttpService: EstimateWorksheetService,
    private sharedService: SharedService,
    private workAreaHttpService: WorksheetWorkAreaService,
    private settingService: SettingService
  ) {}

  /**
   * Inits the service by requesting the basic information form the DB
   * Createss the subjects and behavior subjects
   * Notifies to all subcribers when it is ready to be used
   * @param estimateWorksheetId
   * @param filteredParams
   */
  init(
    estimateWorksheetId: string,
    filteredParams?: WorksheetDetailFilterQuery
  ): void {
    this.estimateWorksheetId = estimateWorksheetId;

    const observables: Array<Observable<any>> = [];

    observables.push(
      this.worksheetHttpService.getEstimateWorksheetProducts(
        this.estimateWorksheetId,
        this.sharedService.selectedOrganization.ID,
        filteredParams || {}
      )
    );

    observables.push(
      this.worksheetHttpService.getEstimateViewByPackage(
        this.estimateWorksheetId,
        this.sharedService.selectedOrganization.ID,
        true
      )
    );

    observables.push(
      this.workAreaHttpService.getAllByEstimateWorksheetId(
        this.estimateWorksheetId
      )
    );

    forkJoin(observables).subscribe(([estimateWorksheet, views, workAreas]) => {
      const estimateWorksheetData: NewEstimateWorksheetInfo = estimateWorksheet;

      // Creating the WorksheetDetailFromDB object with all its methods
      estimateWorksheetData.worksheetDetail =
        estimateWorksheetData.worksheetDetail.map((x) =>
          WorksheetDetailFromDB.createObj(x)
        );

      this.estimateWorksheetData =
        new BehaviorSubject<NewEstimateWorksheetInfo>(estimateWorksheetData);

      this.estimateViews = new BehaviorSubject<EstimateView[]>(views);
      this.worksheetDetailsOnTab = new BehaviorSubject<WorksheetDetailFromDB[]>(
        [...estimateWorksheetData.worksheetDetail]
      );

      this.workAreas = new BehaviorSubject<WorksheetWorkArea[]>(workAreas);
      this.onReady.next(true);
    });
  }

  /**
   * Gets the worksheet data and its details from the DB.
   * Notifies to all subcribers when it is ready
   * @param filterParams
   */
  getWorksheetDetailsFromDB(filterParams?: WorksheetDetailFilterQuery): void {
    const queryParams = filterParams ? filterParams : {};

    this.worksheetHttpService
      .getEstimateWorksheetProducts(
        this.estimateWorksheetId,
        this.sharedService.selectedOrganization.ID,
        queryParams
      )
      .subscribe((data: NewEstimateWorksheetInfo) => {
        const estimateWorksheetData: NewEstimateWorksheetInfo = data;
        estimateWorksheetData.worksheetDetail =
          estimateWorksheetData.worksheetDetail.map((x) =>
            WorksheetDetailFromDB.createObj(x)
          );
        this.estimateWorksheetData.next(estimateWorksheetData);
      });
  }

  /**
   * Gets the estimate views from the DB and notifies to all susbcribers
   */
  getEstimateViews(): void {
    this.worksheetHttpService
      .getWorkSheetEstimateViewValues(
        this.estimateWorksheetId,
        this.sharedService.selectedOrganization.ID,
        true
      )
      .subscribe((estimateViews) => {
        this.estimateViews.next(estimateViews);
      });
  }

  /**
   * Deletes a worksheet Details and notifies to all subcribers
   * @param worksheetDetailId
   */
  deleteWorksheetDetail(worksheetDetailId: number): void {
    this.onDeleteWorksheetDetail.next({ isDeleting: true });
    const worksheetDetailToDelete = this.estimateWorksheetData
      .getValue()
      .worksheetDetail.find((x) => {
        return x.id === worksheetDetailId;
      });

    const worksheetDetailsList = WorksheetDetailFromDB.flat(
      worksheetDetailToDelete
    );
    const worksheetDetailsIds = worksheetDetailsList.map((x) => x.id);

    this.worksheetHttpService
      .removeWorksheetProduct(
        this.estimateWorksheetId,
        this.sharedService.selectedOrganization.ID,
        worksheetDetailId,
        worksheetDetailsIds
      )
      .subscribe(
        () => {
          this.onCompleteDeletingProcess.next({
            wasCompleted: true,
            hadErrors: false,
          });
          this.onDeleteWorksheetDetail.next({ isDeleting: true });
        },
        (error) => {
          this.onCompleteDeletingProcess.next({
            wasCompleted: false,
            hadErrors: true,
            error: error,
          });
        }
      );
  }

  /**
   * Updates a worksheetDetail and notifies to all subcribers
   * @param worksheetDetail new data
   */
  updateWorksheetDetail(worksheetDetail: WorksheetDetailFromDB): void {
    this.worksheetHttpService
      .updateWorksheetProduct({
        worksheetDetail: worksheetDetail,
      })
      .subscribe(
        () => {
          this.onEditingProcess.next({
            isEditing: false,
            target: worksheetDetail,
          });

          this.onCompleteEditingProcess.next({
            wasCompleted: true,
            hadErrors: false,
          });
        },
        (error) => {
          this.onError.next(error);
          this.onCompleteEditingProcess.next({
            wasCompleted: false,
            hadErrors: true,
            error: error,
          });
        }
      );
  }

  /**
   * Adds a product to the worksheet.
   * Makes the request and communicates the result of the operation
   * to all the other components
   * @param worksheetDetail
   */
  addWorksheetDetail(worksheetDetail: WorksheetDetailFromDB): void {
    this.worksheetHttpService
      .addWorksheetProducts({
        worksheetDetail: worksheetDetail,
      })
      .subscribe(
        () => {
          this.onCompleteAddingProcess.next({
            hadErrors: false,
            wasCompleted: true,
          });
        },
        (error) => {
          this.onCompleteAddingProcess.next({
            hadErrors: true,
            wasCompleted: true,
            error: error,
          });
        }
      );
  }

  /**
   * Restores a product.
   * Makes the request and communicates the result of the operation
   * to all the other components
   * @param worksheetDetailId
   */
  restoreWorksheetDetail(worksheetDetailId): void {
    const estimateWorksheet = this.estimateWorksheetData.getValue();
    const target = estimateWorksheet.worksheetDetail.find(
      (x) => x.id === worksheetDetailId
    );
    const targetAsFlat = WorksheetDetailFromDB.flat(target);
    const idsToSend = targetAsFlat.map((x) => x.id);
    this.worksheetHttpService.restoreWorksheetDetail(idsToSend).subscribe(
      () => {
        this.onCompleteRestoreProcess.next({
          wasCompleted: true,
          hadErrors: false,
        });
      },
      (error) => {
        this.onCompleteRestoreProcess.next({
          wasCompleted: false,
          hadErrors: true,
          error: error,
        });
      }
    );
  }

  /**
   * Gets the details from the DB and updates
   * all the components to use this most recent data
   */
  getWorkAreasFromDB(): void {
    this.workAreaHttpService
      .getAllByEstimateWorksheetId(this.estimateWorksheetId)
      .subscribe((workAreas) => {
        this.workAreas.next(workAreas);
      });
  }

  /**
   * sets the status for the worksheet and to be readable from the childs
   * @param status the status of the worksheet if is loked or unlocked
   */
  setWorksheetStatus(status: boolean): void {
    this.isLocked = status;
  }

  /**
   * Get organization setting for margin/markup
   * @returns setting value
   */
  getOrganizationSettingIsMarkup(): boolean {
    this.marginMarkupValue = this.getOrganizationSettingMarkupMargin();
    this.isMarkup = this.marginMarkupValue === this.marginMarkupOptions.Markup;

    return this.isMarkup;
  }

  /**
   * Method to get the margin/markup value from the organization settings
   * @returns
   */
  getOrganizationSettingMarkupMargin(): number {
    return this.settingService.getNumericValue(
      this.sharedService.organizationSettings,
      this.marginMarkupKey,
      this.marginMarkupOptions.Markup
    );
  }
}
