import { Helper } from "./helper";
import { WorksheetWorkAreaParameter } from "../../model/WorksheetWorkAreaParameter";

/** Match parameter ids with numeric values and evaluates the resulting operation */
export function evaluateFormula(
  workAreaParameters: WorksheetWorkAreaParameter[],
  formula: string
): number {
  if (workAreaParameters.length == 0 || !formula) return 0;
  const paramNames = Helper.stringToLettersArray(formula, "%");
  let formulaValues = formula;
  paramNames.forEach((id: string) => {
    let param = workAreaParameters.find((param) => param.parameterId === +id);
    if (param)
      formulaValues = formulaValues.replace(`%${id}%`, `${param.value}`);
  });
  return isValidResult(formulaValues) ? evaluateResult(formulaValues) : 0;
}

/** Evaluates an operation */
export function evaluateResult(values: string): number {
  return Function(`return ${values}`)();
}

/** Returns if formula is valid to perform operations */
function isValidResult(formula: string): boolean {
  return !formula.includes("%");
}

/** Calculate values for various work area params */
export function calculateValues(
  workAreasParams: WorksheetWorkAreaParameter[][],
  formula: string
): number {
  let formulaResult = 0;
  workAreasParams.forEach((wa) => {
    formulaResult += evaluateFormula(wa, formula);
  });

  return workAreasParams.length === 0 ? 1 : formulaResult;
}

export function buildFormulaWithParameterNames(
  workAreasParams: WorksheetWorkAreaParameter[][],
  formula: string
): string {
  let formulaWithParamNames = `${formula}`;
  let workAreaWithZeroValue: WorksheetWorkAreaParameter[];
  if (Array.isArray(workAreasParams[0])) {
    workAreaWithZeroValue = workAreasParams.find((wap) =>
      wap.some((pa) => pa.value === 0)
    );
  } else {
    workAreaWithZeroValue =
      workAreasParams as unknown as WorksheetWorkAreaParameter[];
  }
  const paramNames = Helper.stringToLettersArray(formula, "%");
  paramNames.forEach((id: string) => {
    let param = workAreaWithZeroValue.find(
      (param) => param.parameterId === +id
    );
    formulaWithParamNames = formulaWithParamNames.replace(
      `%${id}%`,
      `${param.parameter.parameterName}`
    );
  });
  return formulaWithParamNames;
}

export function getErrorMessageForInifinityResult(
  calculatorName: string,
  formula: string,
  productName: string
): string {
  return `For the ${calculatorName} :  
    ${formula} from ${productName}, the variables are invalid 
    because the divisor cannot be 0.`;
}
