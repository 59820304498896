import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";
import { SharedService } from "app/common/utility/SharedService";
import { environmentConstant } from "app/common/utility/environment";
import { Observable } from "rxjs";
import { ApiResponseModel as Api } from "app/model/ApiResponseModel";
import { SearchFilter, FilteredSearchModel } from "app/model/SearchModel";
import { Pager } from "app/model/Pager";
import { JobModel } from "app/model/Core/JobModel";
import { UserModel } from "app/model/Core/UserModel";

@Injectable()
export class UserService {
  constructor(private httpService: HttpService) {}

  searchJobs(
    userGuid: string,
    model: FilteredSearchModel<SearchFilter>
  ): Observable<Api<Pager<JobModel>>> {
    return this.httpService.post<Pager<JobModel>>(
      environmentConstant.coreApi.searchUserJobs(userGuid),
      model
    );
  }

  searchContractors(
    userGuid: string,
    model: FilteredSearchModel<SearchFilter>
  ): Observable<Api<Pager<UserModel>>> {
    return this.httpService.post<Pager<UserModel>>(
      environmentConstant.coreApi.searchUserContractors(userGuid),
      model
    );
  }

  searchLeads(
    userGuid: string,
    model: FilteredSearchModel<SearchFilter>
  ): Observable<Api<Pager<UserModel>>> {
    return this.httpService.post<Pager<UserModel>>(
      environmentConstant.coreApi.searchUserLeads(userGuid),
      model
    );
  }

  searchCustomers(
    userGuid: string,
    model: FilteredSearchModel<SearchFilter>
  ): Observable<Api<Pager<UserModel>>> {
    return this.httpService.post<Pager<UserModel>>(
      environmentConstant.coreApi.searchUserCustomers(userGuid),
      model
    );
  }

  getUser(userGuid: string): Observable<Api<UserModel>> {
    return this.httpService.get(environmentConstant.coreApi.getUser(userGuid));
  }
  saveUser(userGuid: string, model: UserModel): Observable<Api<UserModel>> {
    return this.httpService.post(
      environmentConstant.coreApi.getUser(userGuid),
      model
    );
  }
  updateUser(userGuid: string, model: UserModel): Observable<Api<UserModel>> {
    return this.httpService.put(
      environmentConstant.coreApi.getUser(userGuid),
      model
    );
  }
  deleteUser(userGuid: string): Observable<Api<UserModel>> {
    return this.httpService.delete(
      environmentConstant.coreApi.getUser(userGuid)
    );
  }
}
