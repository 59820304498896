import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { environment } from "././environments/environment";
import { AppModule } from "./app/app.module";

if (environment.useProdMode) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
