import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { WorkOrderService } from "app/common/services/workOrder.service";
import { BaThemeSpinner } from "app/theme/services";
import {
  SharedService,
  CommonSubscription,
} from "app/common/utility/SharedService";
import { MessageService } from "primeng/components/common/messageservice";
import { Helper } from "app/common/utility/helper";
import * as _ from "lodash";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import {
  GridDataResult,
  PageChangeEvent,
} from "@progress/kendo-angular-grid/dist/es2015";
import { JobResourcesRequestPayload } from "app/model/JobAssignResourceModel";
import { Resource } from "app/model/ResourceModel";
import { TradeModel } from "app/model/tradeModel";
import { TradeService } from "app/common/services/trade.service";
import { CommonService } from "app/common/services/common.service";
import { BusinessUnitModel } from "app/model/organizationModel";

@Component({
  selector: "todo-assign-resource",
  templateUrl: "./AssignResourceToDo.component.html",
  styleUrls: ["./AssignResourceToDo.css"],
})
export class AssignResourceToDoComponent implements OnInit {
  lstTrade: TradeModel[];
  lstOrignalTrade: TradeModel[];
  lstBusinessUnit: BusinessUnitModel[] = [];
  lstOrignalBusinessUnit: BusinessUnitModel[] = [];
  headerText: string = "";
  lstAssignResource: any[] = [];
  lstOrignalAssignResource: any[] = [];
  filterQuery = "";
  isFilled: boolean;
  pageSize: number = 50;
  sort: SortDescriptor[] = [];
  gridView: GridDataResult;
  skip: number = 0;
  assignResourceModel: JobResourcesRequestPayload;
  searchText: string = "";
  fieldName: string;
  selectedTab: number = 0;
  selectedType: string = "internal";
  @Input() workOrderId: any;
  @Input() LineItemId: any;
  @Input() BusinessUnitID: number = 0;
  @Input() TradeID: number = 0;
  @Input() AssignedResourceList: any;
  @Input() StartDate: Date = null;
  @Output() sendData: EventEmitter<any> = new EventEmitter<any>();
  ShowSlide: boolean = false;
  constructor(
    private _spinner: BaThemeSpinner,
    private sharedService: SharedService,
    private notificationsService: MessageService,
    private workorderService: WorkOrderService,
    private tradeService: TradeService,
    private commonService: CommonService,
    private commonsubs: CommonSubscription
  ) {}

  multiple: boolean = false;
  allowUnsort: boolean = true;
  ngOnInit() {
    this.headerText = "Assign To";
    this.searchText = "Search By Name";
    this.fieldName = "Name";
    if (!this.StartDate) {
      this.StartDate = new Date();
    }
    this.assignResourceModel = new JobResourcesRequestPayload();
    this.assignResourceModel.Resources = new Array<Resource>();
    this.assignResourceModel.JobPhaseId = 0;
    this.assignResourceModel.JobID = this.workOrderId;
    this.getBusinessUnit();
    this.getTradesByOrgId();
  }

  getTradesByOrgId() {
    this.tradeService
      .getAllTradesByOrgId(this.sharedService.selectedOrganization.ID)
      .subscribe((data) => {
        if (data) {
          this.lstTrade = data;
          this.lstOrignalTrade = data;
        }
      });
  }
  public filterTrade(filter: any): void {
    this.lstTrade = this.lstOrignalTrade.filter(
      (s) => s.TradeName.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }
  getBusinessUnit() {
    this.commonService
      .getBusinessUnit(this.sharedService.selectedOrganization.ID)
      .subscribe((data) => {
        this.lstBusinessUnit = data;
        this.lstOrignalBusinessUnit = data;
      });
  }
  public filterBussinessUnit(filter: any): void {
    this.lstBusinessUnit = this.lstOrignalBusinessUnit.filter(
      (s) => s.BusinessUnit1.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }
  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadClients(null);
  }
  pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.filterGridData();
  }

  filterGrid() {
    this.isFilled = true;
    this.filterGridData();
    if (this.filterQuery.length == 0) {
      this.isFilled = false;
    }
    this.pageChange({ skip: 0, take: this.pageSize });
  }
  filterGridData() {
    this.loadClients(
      _.filter(
        this.lstAssignResource,
        (row) =>
          Helper.ConvertToLower(row.Name).indexOf(
            this.filterQuery.trim().toLowerCase()
          ) > -1
      )
    );
  }
  private loadClients(client): void {
    let clients = client || this.lstAssignResource;
    clients = orderBy(clients, this.sort);

    this.gridView = {
      data: clients.slice(this.skip, this.skip + this.pageSize),
      total: clients.length,
    };
    this._spinner.hide();
  }
  getResourcesList() {
    this._spinner.show();
    let schdeduleDate = this.StartDate
      ? this.StartDate.getMonth() +
        1 +
        "-" +
        this.StartDate.getDate() +
        "-" +
        this.StartDate.getFullYear()
      : null;

    if (
      !this.lstOrignalBusinessUnit ||
      !this.lstOrignalBusinessUnit.find((x) => x.ID == this.BusinessUnitID)
    ) {
      this.BusinessUnitID = 0;
    }
    if (
      !this.lstOrignalTrade ||
      !this.lstOrignalTrade.find((x) => x.ID == this.TradeID)
    ) {
      this.TradeID = 0;
    }

    this.workorderService
      .getAssignResource(
        this.sharedService.selectedOrganization.ID,
        schdeduleDate,
        this.selectedType == "external" ? true : false,
        this.selectedType == "external" ? this.TradeID : this.BusinessUnitID
      )
      .subscribe((data) => {
        this.lstAssignResource = [];
        let lst: any[] = data;
        if (this.AssignedResourceList && this.AssignedResourceList.length > 0) {
          lst.forEach((element) => {
            var findResource = this.AssignedResourceList.find(
              (item) => item.Id.toLowerCase() == element.Id.toLowerCase()
            );
            if (!findResource) this.lstAssignResource.push(element);
          });
        } else {
          this.lstAssignResource = data;
        }
        this.lstOrignalAssignResource = JSON.parse(
          JSON.stringify(this.lstAssignResource)
        );
        this.filterResources(this.selectedType);
        this._spinner.hide();
      });
  }
  onFind() {}
  selectedResource(event, dataItem) {
    this.assignResourceModel.Resources = [];
    this.gridView.data.forEach((item) => (item.selected = false));
    if (event.target.checked) {
      var resource = {
        Id: dataItem.Id,
        Name: dataItem.Name,
        IsExternal: dataItem.IsExternal,
      };
      this.assignResourceModel.Resources.push(resource);
      dataItem.selected = true;
    }
  }
  onSave() {
    if (this.LineItemId) {
      if (this.LineItemId > 0) {
        this.assignResourceModel.JobPhaseId = this.LineItemId;
      }
    }
    // if (this.LineItemId > 0) {
    //     this.assignResourceModel.JobPhaseId = this.LineItemId;
    // }
    // this.workorderService.saveAssignedResource(this.assignResourceModel).subscribe(
    //     (result) => {
    //         this.result(result, 'Assigned Successfully!!');
    //         this.oncancel(this.assignResourceModel.Resources);
    //         this.commonsubs.updateAssignedResource(true);
    //     },
    //     (error) => this.error(error, 'Unable to Assigned Resource'));
    // } else {
    this.oncancel(this.assignResourceModel.Resources);
    this.commonsubs.updateAssignedResource(true);
    // }
  }

  onTabSelected(event) {
    this.selectedTab = event.index;
    this.filterResources(this.selectedType);
  }

  filterResources(key) {
    this.selectedType = key;
    switch (key) {
      // case 'all':
      //     if (this.selectedTab == 0)
      //         this.lstAssignResource = this.lstOrignalAssignResource.filter(x => x.IsBusy == false);
      //     else
      //         this.lstAssignResource = this.lstOrignalAssignResource.filter(x => x.IsBusy == true);
      //     break;
      case "internal":
        this.lstAssignResource = this.lstOrignalAssignResource.filter(
          (x) => x.IsExternal == false
        );
        // if (this.selectedTab == 0)
        //     this.lstAssignResource = this.lstOrignalAssignResource.filter(x => x.IsBusy == false && x.IsExternal == false);
        // else
        //     this.lstAssignResource = this.lstOrignalAssignResource.filter(x => x.IsBusy == true && x.IsExternal == false);
        break;
      case "external":
        this.lstAssignResource = this.lstOrignalAssignResource.filter(
          (x) => x.IsExternal == true
        );

        // if (this.selectedTab == 0)
        //     this.lstAssignResource = this.lstOrignalAssignResource.filter(x => x.IsBusy == false && x.IsExternal == true);
        // else
        //     this.lstAssignResource = this.lstOrignalAssignResource.filter(x => x.IsBusy == true && x.IsExternal == true);
        break;
    }
    this.selectedType = key;
    this.filterGridData();
  }

  private error(error: any, message) {
    this.notificationsService.add({
      severity: "warn",
      summary: "Assign Resource",
      detail: message,
    });
    this._spinner.hide();
  }
  private result(res: any, message) {
    if (res !== null) {
      this.notificationsService.add({
        severity: "success",
        summary: "Assign Resource",
        detail: message,
      });
      this._spinner.hide();
    }
  }
  oncancel(text) {
    this.sendData.emit(text);
  }

  getInitals(str) {
    if (!str) return "";
    var result = str
      .split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), "");
    if (result && result.length > 2) {
      return result.slice(0, 2);
    }
    return result;
  }
}
