import {
  Component,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  Renderer,
} from "@angular/core";
import { UploaderOptions } from "ngx-uploader";
import heic2any from "heic2any";
import { endsWith } from "lodash";
import { BaThemeSpinner } from "app/theme/services";

@Component({
  selector: "ba-picture-uploader",
  styleUrls: ["./baPictureUploader.scss"],
  templateUrl: "./baPictureUploader.html",
})
export class BaPictureUploader {
  @Input() defaultPicture: string = "";
  @Input() picture: string = "";
  @Input() pictureModel: any;
  @Input() uploaderOptions: UploaderOptions;
  @Input() canDelete: boolean = true;
  @Input() ImageURL: boolean;
  @Output() onUpload = new EventEmitter<any>();
  @Output() onUploadCompleted = new EventEmitter<any>();
  @Output() fileChange = new EventEmitter<File>();

  @ViewChild("fileUpload", { static: true }) public _fileUpload: ElementRef;

  public uploadInProgress: boolean;

  constructor(private renderer: Renderer, private spinner: BaThemeSpinner) {}

  beforeUpload(uploadingFile): void {
    let files = (this._fileUpload.nativeElement as HTMLInputElement).files;

    if (files.length) {
      const file = files[0];
      this._changePicture(file);

      if (!this._canUploadOnServer()) {
        this.uploadInProgress = false; //uploadingFile.setAbort();
      } else {
        this.uploadInProgress = true;
      }
    }
  }

  bringFileSelector(): boolean {
    this.renderer.invokeElementMethod(this._fileUpload.nativeElement, "click");
    return false;
  }

  removePicture(): boolean {
    this.picture = "";
    return false;
  }

  async _changePicture(file: File): Promise<void> {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      (event: Event) => {
        this.picture = (<any>event.target).result;
      },
      false
    );
    if (endsWith(file.name.toLowerCase(), ".heic")) {
      this.spinner.show();
      const jpeg = (await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 0.6,
      })) as Blob;
      reader.readAsDataURL(jpeg);
      const jpegFile = new File([jpeg], file.name);
      this.fileChange.emit(jpegFile);
      this.spinner.hide();
    } else {
      reader.readAsDataURL(file);
      this.fileChange.emit(file);
    }
  }

  _onUpload(data): void {
    if (data["done"] || data["abort"] || data["error"]) {
      this._onUploadCompleted(data);
    } else {
      this.onUpload.emit(data);
    }
  }

  _onUploadCompleted(data): void {
    this.uploadInProgress = false;
    this.onUploadCompleted.emit(data);
  }

  _canUploadOnServer(): boolean {
    return !!this.uploaderOptions;
  }
}
