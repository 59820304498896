import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { EstimateWorksheetInfo } from "../../model/EstimateWorksheetInfo";

@Injectable({
  providedIn: "root",
})
export class EstimateJobService {
  private baseUrl = environment.baseEstimateUrl;
  constructor(private httpService: HttpClient) {}

  getJobsForDropdown(orgGuid: string) {
    return this.httpService.get(`${this.baseUrl}jobs/dropdown/${orgGuid}`);
  }

  updateJobWorksheetId(jobId: string): Observable<void> {
    return this.httpService.patch<void>(`${this.baseUrl}job/worksheet`, {
      jobId,
    });
  }

  getCustomerByJobId(jobGuid: string): Observable<any> {
    return this.httpService.get<any>(`${this.baseUrl}jobs/customer/${jobGuid}`);
  }

  getEstimateByJobGuid(jobGuid: string) {
    return this.httpService.get<EstimateWorksheetInfo>(
      `${this.baseUrl}jobs/worksheet/${jobGuid}`
    );
  }
}
