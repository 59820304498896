import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SearchUserComponent } from "./SearchUser.component";
import { OrganizationService } from "app/common/services/Core/Organization.service";
import { AccountService } from "app/common/services/Core/Account.service";

@NgModule({
  imports: [CommonModule],
  declarations: [SearchUserComponent],
  exports: [SearchUserComponent],
  providers: [OrganizationService, AccountService],
})
export class SearchUserModule {}
