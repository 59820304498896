import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  CancelEvent,
  EditEvent,
  GridComponent,
  GridDataResult,
  RemoveEvent,
  SaveEvent,
} from "@progress/kendo-angular-grid";
import { State, process } from "@progress/kendo-data-query";
import { JobFileService } from "app/common/services/jobFile.service";
import { Helper } from "app/common/utility/helper";
import { JobFileModel } from "app/model/jobFile";
import { BaThemeSpinner } from "app/theme";
import { ConfirmComponent } from "../dialog/dialog.component";
import { DialogService } from "ng2-bootstrap-modal";
import { MessageService } from "primeng/primeng";
import { EstimateWorksheetService } from "app/common/services/estimate-worksheet.service";

@Component({
  selector: "app-export-file-list",
  templateUrl: "./export-file-list.component.html",
  styleUrls: ["./export-file-list.component.scss"],
})
export class ExportFileListComponent implements OnInit {
  @Input() fileList: JobFileModel[];
  filesType: string = "csv";

  gridData: GridDataResult;
  private currentSender: GridComponent;
  private editedRowIndex: number;
  formGroup: FormGroup;
  isediting: boolean = false;
  selectedFile: JobFileModel;

  state: State = {
    skip: 0,
    take: 100,
    filter: {
      logic: "and",
      filters: [],
    },
  };

  private readonly onFilesDeleteSuccessMsg: string =
    "Files Deleted Successfully";
  private readonly errorMsg: string = "An error has ocurred";

  constructor(
    private spinner: BaThemeSpinner,
    private jobFileService: JobFileService,
    private dialogService: DialogService,
    private notificationsService: MessageService,
    private downloadFilesService: EstimateWorksheetService
  ) {}

  ngOnInit() {
    this.gridData = process(this.fileList, this.state);
  }

  /** View a file */
  view(file: JobFileModel) {
    window.open(file.BlobUrl);
  }

  handleEdit(ev: EditEvent) {
    const { dataItem } = ev;
    this.currentSender = ev.sender;
    this.closeEditor(ev.sender);

    this.formGroup = new FormGroup({
      FileName: new FormControl(
        dataItem.FileName.replace(/\..+$/, ""),
        Validators.required
      ),
      FileTypeName: new FormControl(dataItem.JobFileTypeId),
      extension: new FormControl(Helper.getFileExtension(dataItem.FileName)),
    });

    this.editedRowIndex = ev.rowIndex;
    ev.sender.editRow(ev.rowIndex, this.formGroup);
    this.isediting = true;
  }

  /** Closes editor */
  private closeEditor(grid: GridComponent, rowIndex = this.editedRowIndex) {
    if (grid) {
      grid.closeRow(rowIndex);
      grid.rowClass;
    }
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  /** Handles saving an edit event */
  saveHandler(ev: SaveEvent) {
    this.selectedFile = ev.dataItem;
    const fileName =
      ev.formGroup.value.FileName + "." + ev.formGroup.value.extension;
    this.currentSender = ev.sender;
    const fileTypeId = ev.formGroup.value.FileTypeName;
  }

  /** Cancels editing */
  cancelHandler(args: CancelEvent): void {
    args.sender.closeRow(args.rowIndex);
    this.isediting = false;
  }

  /** Handles deleting a file */
  deleteJobFile({ dataItem, sender }: RemoveEvent) {
    this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Delete File",
        message: "Are you sure you want to delete " + dataItem.FileName + "?",
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          if (this.currentSender) this.closeEditor(this.currentSender);
          this.deleteFile(dataItem.ID, dataItem.IsPinned);
        }
      });
  }

  /** Triggers request to delete a file */
  private deleteFile(id: number, isPinned: boolean) {
    this.jobFileService
      .deletefilePhoto(id)
      .subscribe(
        (res) => {
          this.onDeleteSuccess();
        },
        (error) => this.result(this.errorMsg, true)
      )
      .add(() => this.spinner.hide());
  }

  /** Handles a successfull delete */
  private onDeleteSuccess(): void {
    this.editedRowIndex = null;
    this.result(this.onFilesDeleteSuccessMsg);
  }

  /**
   * download zipped files
   */
  downloadJobFiles(): void {
    const selectedPhotosURLs: string[] = this.getSelectedFilesURLs(
      this.fileList
    );
    if (selectedPhotosURLs.length == 0) return;
    this.spinner.show();
    this.downloadFilesService
      .downloadFiles(selectedPhotosURLs, this.filesType)
      .subscribe(
        (response: any) => {
          this.spinner.hide();
          let dataType = response.type;
          let binaryData = [];
          binaryData.push(response);
          let downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(
            new Blob(binaryData, { type: dataType })
          );
          downloadLink.setAttribute("download", "files.zip");
          document.body.appendChild(downloadLink);
          downloadLink.click();
        },
        (error) => {
          this.result(this.errorMsg, true);
          this.spinner.hide();
        }
      );
  }

  private getSelectedFilesURLs(files: JobFileModel[]): string[] {
    return files.filter((x) => x.selected).map((x) => x.BlobUrl);
  }

  /** Triggers a notificationi */
  private result(message: string, isError: boolean = false) {
    this.notificationsService.add({
      severity: isError ? "error" : "success",
      summary: "Files",
      detail: message,
    });
  }
}
