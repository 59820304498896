export const environmentConstant = {
  demoOrg: "25c0440f-0d87-44b7-8151-8fc81ff5c023",
  systemOrg: "0C524380-A44D-42B7-9C32-DF28B5F42A71",
  //license Agreement
  licensePrivacy: "/application/privacy",
  // Email pattern
  emailPattern:
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
  // bit rate
  bitrate: "common/bitrate",
  // CatalogId
  catalogId: 1,
  // firmware
  getFirmwaresUrl: "firmware?includeDeleted=false",
  getFirmwaresByIdUrl: "firmware/",
  saveFirmware: "firmware",
  updateFirmware: "firmware",
  deleteFirmware: "firmware/",
  uploadFirmware: "firmware/",
  // notification
  notification: "notification/",
  viewed: "/viewed",
  clearall: "/clearall",
  // version2
  v2: "v2/",
  // hot deals
  deals: "deals/",
  getHotDeals: "deals/organization/",
  saveHotDeal: "deals",
  //
  // campaigns
  campaigns: "campaigns/",
  getCampaigns: "campaigns/organization/",
  saveCampaign: "campaigns",
  getJobTokens: "campaigns/jobTokens",
  //
  // Stores
  stores: "store/",
  getStores: "store/organization/",
  saveStore: "store/",
  //
  organization: "organization/",
  parentChildOrgs: "parentchildorgs/",
  // Camera
  getCamerasUrl: "camera?includeDeleted=false",
  getCamerasUrlV2: "camera/v2?includeDeleted=false",
  getCameraById: "camera/",
  saveCamera: "camera",
  updateCamera: "camera",
  deleteCamera: "camera/",
  cameracontrols: "cameracontrols/",
  config: "/config",
  // jobV2
  organizationList: "/organization/list",
  // getAllAvailabeCamers: 'organization /' { organizationid } / availablecamera
  // Organization
  getOrganizationUrl: "organization?includeDeleted=false",
  getOrganizationLookUp: "organizationlookup?includeDeleted=false",
  getOrganizationViewUrl: "organizationview?includeDeleted=false",
  getOrganizationByIdUrl: "organization/",
  updateOrganization: "organization",
  saveOrganization: "organization",
  deleteOrganization: "organization/",
  organizationCameras: "organization/",
  updateOrganizationCamera: "organization/",
  saveOrganizationCamera: "organization/",
  deleteOrganizationCamera: "organization/",
  availableCamere: "camera/available",
  availableCameraByOrg: "organization/",
  getCameraLogFilesByCameraID: "camera/",
  // Model
  getCamerasModelUrl: "model?includeDeleted=false",
  getCamerasModelByIdUrl: "model/",
  saveModel: "model",
  updateModel: "model",
  deleteModel: "model/",
  // Common
  getStatesUrl: "common/states",
  getStatus: "common/mpbstatus",
  getschedulereason: "phasechangereason/organization/",
  deletescheduleReason: "phasechangereason/",
  getleadSource: "salesleadsource/organization/",
  deleteleadSource: "salesleadsource/",
  fileType: "filetype/",
  // contractor
  contractor: "contractor/",
  addContractor: "contractor",
  // Delivery Types
  deliveryTypes: "common/deliveryTypes",
  // ROLES
  roles: "roles",
  rolesByOrg: "roles/organization/",
  rolesOfJobContacts: (jobId: string) => `roles/jobs/${jobId}/contacts`,
  // getCustomerByOrgId: 'organization/FC20A4B6-4C52-4A4E-AF44-8371F3C511E8/customers/3',
  // Token
  getToken: "token",
  // dashboard
  getFinanceDashboard: "dasbboard/finance/",
  getTemplateDashboard: "dasbboard/jobtemplate/",
  getRevenueDashboard: "dasbboard/revenue/",
  getSalesDashboard: "dasbboard/sales/",
  getJobsWhiteboard: "dasbboard/whiteboard/",
  getWhiteBoardByJobId: "dashboard/whiteboard/",
  jobPhaseStatus: "dasbboard/jobphasesstatus/",
  // Sales
  dashboard: "/dashboardv2",
  sales: "sales/",
  address: "address/",
  getWeatherAddressLookUp: "getweatheraddresslookup/",
  shippingAddress: "shippingAddress/",
  salesphase: "salesphase/",
  gallery: "/gallery",
  getSales: "sales/gallery",
  saveAppointment: "appointment?SendNotfication=",
  // Trade
  getTrageByOrgId: "trade/organization/",
  deleteTrade: "trade/",
  getTradeById: "trade/",
  saveTrade: "trade/organization/",
  updateTrade: "trade/organization/",
  // Phase
  getPhaseByOrgId: "phase/",
  getSysPhaseByOrgId: "phase/system",
  deletePhase: "phase/",
  getPhaseById: "phase/",
  savePhase: "phase",
  updatePhase: "phase",
  linkPhaseAlert: "phasealert/phase",
  toPhase: "/phase/",
  getPhaseByTradeId: "phase/trade/",
  getContractor: "/contractors",
  // Phase Alert
  getPhaseAlertByOrgId: "phasealert/",
  getPhasealertByPhaseId: "phasealert/organization/",
  savePhaseAlert: "phasealert",
  updatePhaseAlert: "phasealert",
  getPhaseAlertById: "phasealert/",
  deletePhaseAlert: "phasealert/",
  deletePhaseAlertPhase: "phasealert/phase",
  // Job Template
  getJobTemplatePhaseByJobTemplateId: "",
  getJobtemplatephaseByJobPhaseId: "",
  saveJobTemplatePhase: "jobtemplatephase",
  saveJobTemplatePhasedragdrop: "jobtemplatephasedragdrop",
  updateJobTemplatePhase: "jobtemplatephase",
  deleteJobTemplatePhase: "jobtemplatephase/",
  getJobTemplateByOrgId: "jobtemplate/organisation/",
  getSysJobTemplateByOrgId: "jobtemplate/system",
  getJobTemplate: "jobtemplate/",
  getJobTemplateV2: "v2/jobtemplatephases/",
  saveJobTemplateByTemplateId: "",
  updateJobTemplate: "jobtemplate",
  saveJobTemplate: "jobtemplate",
  deleteJobTemplate: "jobtemplate/",
  paymentJobTemplate: "/drawschedule",
  editDrawSchedule: "drawschedule/",
  // client
  getLegacyUser: "users/",
  getCustomer: "customer/",
  byroleId: "/customers/",
  builderJobList: "builder/",
  saveCustomer: "customer/",
  getCustomerById: "customer/",
  deleteCustomer: "customer/",
  getSuperAdmin: "customer/superadmins",
  isValidUserName: "customer/username/",
  qtyCustomerDuplicates: "customer/qtycustomerduplicates/",
  // email message
  saveEmail: "message",
  sendEmail: "message/sendemail",
  // Category
  getCategoryByOrgId: "categories/organization/",
  getJobCategoryByOrgId: "category/",
  deleteCategory: "category/",
  getCategoryById: "category/",
  saveCategory: "category",
  updateCategory: "category",
  // System Categories
  saveSystemCategory: "common/mpbEntity",
  updateSystemCategory: "common/mpbEntity",
  getSytemCategories: "common/mpbEntity",
  deleteSystemCategory: "common/mpbEntity/",
  // Draw Schedule
  getDrawSchedules: "drawscheduletemplate/organisation/",
  getDrawScheduleById: "drawscheduletemplate/",
  deleteDrawSchedule: "drawscheduletemplate?id=",
  saveDrawSchedule: "drawscheduletemplate",
  updateDrawSchedule: "drawscheduletemplate",
  saveDrawScheduletemplatePhase: "drawScheduletemplatephase",
  updateDrawScheduletemplatePhase: "drawScheduletemplatephase",
  deleteDrawScheduletemplatePhase: "drawScheduletemplatephase/",
  paymentStatus: "/complete",
  // Whiteboard
  // whiteBoard : '/whiteboard',
  // JOB
  checkIfContractIsSigned: "jobs/checkifcontractissigned/",
  saveJob: "jobs",
  getallJob: "jobs/",
  getJob: "jobs/",
  updateJob: "jobs",
  getBuilder: "builder/job/",
  deleteJob: "jobs",
  getjobId: "?jobId=",
  jobData: "/data",
  job: "job/",
  //contacts
  contacts: "/contacts",
  // customer
  customer: "customer",
  // JOB camera
  getAllJobCameras: "jobcamera/",
  saveJobcamera: "jobcamera",
  updateJobCamera: "jobcamera",
  deleteJobCamera: "jobcamera?jobid=",
  // Build Plan
  getBuildPlanByJobID: "jobs/",
  getJobPhaseById: "jobphase/",
  scheduledNow: "jobphase/schedulednow/",
  saveJobPhase: "jobphase/",
  updateJobPhase: "jobphase/",
  updatePhasesScheduleDates: "jobphase/schedule",
  updatePhasesScheduleDelay: "jobphase/schedule/delay",
  deleteJobPhase: "jobphase/",
  getJobPhaseNOtes: "jobphase/",
  changeReasons: "changeReasons",
  // Punch List
  getPunchListByJobID: "jobs/",
  savePunchList: "punchlist/",
  getPunchListById: "punchlist/",
  getPunchlistFiles: "punchlist/",
  getPunchListPhotos: "punchlist/",
  getPunchListNotes: "punchlist/",
  savePunchListNotes: "punchlist/",
  createWorkOrder: "jobs/workorder?punchListItemId=",
  LinkWorkOrderWithPunchlistItem: "jobs/workorder/",
  // Work Order
  getWorkOrderByJobID: "jobs/",
  getWorkOrderByOrgId: "workorder/organization/",
  workOrder: "workorder/",
  // Change Order
  getChangeOrderByJobID: "jobs/",
  getInvoicesByJobID: "jobs/",
  // Job Photos
  getJobPhotosByJobID: "jobs/",
  getJobPhotosUrl: "",
  saveJobPhoto: "jobs/",
  deleteJobPhoto: "jobs/",
  // Job File
  getJobFilesByJobID: "jobs/",
  jobThumbnailAssignmentUnassignment: (jobGuid: string, jobFileId: number) =>
    `jobs/${jobGuid}/thumbnailAssignmentUnassignment/${jobFileId}`,
  getSearchJobPhaseResources: (orgId: string) =>
    `organizations/${orgId}/jobphaseresource/search`,
  deleteFilePhoto: "jobs/",
  getBlobModelUrl: "blob/",
  jobFile: "jobfile/",
  jobFileNote: "jobfilenote/",
  jobFileTag: "jobfiletag/",
  shareJobFile: "jobs/file/share",
  jobFileNewVersion: (fileId: number) => `jobfile/${fileId}/newVersion`,
  jobFileAllVersions: (fileId: number) => `jobfile/${fileId}/allVersions`,
  // Job Notes
  getJobNotesByJobID: "jobs/",
  saveJobNote: "jobs/jobnote",
  updateJobNote: "jobs/jobnote",
  deleteJobNote: "jobs/jobnote/",
  getJobNoteById: "jobnote/",
  // JOb Equipment
  getJobEquipmentByJobID: "jobs/",
  getJobDelete: "jobs/",
  // JOb selectionSheet
  getSelectionSheetbyIdUrl: "jobs/",
  deleteSelectionSheet: "jobs/selectionsheet/",
  getSelectionFeature: "selectionsheet/",
  getSelectionManufacturers: "selectionsheet/",
  getSelectionMaterials: "selectionsheet/",
  updateSelectionSheet: "jobs/selectionsheet",
  saveSelectionSheet: "jobs/selectionsheet",
  // JOb Change Order
  getJobChangeOrdersByJobID: "jobs/",
  saveJobChangeOrder: "jobs/changeorder",
  updateJobChangeOrder: "jobs/changeorder",
  deleteJobChangeOrder: "jobs/changeorder/",

  homebuilder: "homebuilder/",
  jurisdiction: "jurisdiction/",

  // Selection Sheet
  getSelectionSheetFeature: "selectionsheet/feature?orgId=",
  saveSelectionSheetFeature: "selectionsheet/feature",
  updateSelectionSheetFeature: "selectionsheet/feature",
  deleteSelectionSheetFeature: "selectionsheet/feature?id=",
  getSelectionSheetManufacture: "selectionsheet/manufacturers?orgId=",
  saveSelectionSheetManufacture: "selectionsheet/manufacturers",
  updateSelectionSheetManufacture: "selectionsheet/manufacturers",
  deleteSelectionSheetManufacture: "selectionsheet/manufacturers?id=",
  getSelectionSheetMaterial: "selectionsheet/materials?orgId=",
  saveSelectionSheetMaterial: "selectionsheet/materials",
  updateSelectionSheetMaterial: "selectionsheet/materials",
  deleteSelectionSheetMaterial: "selectionsheet/materials?id=",
  // changePassword
  changePassword: "customer/changepassword",
  resetPassword: "account/resetPassword",
  // password recovery
  passwordRecovery: "passwordrecovery/",
  // JOb Activity Log
  getJobActivityLogsByJobID: "jobs/",
  deleteJobActivityLog: "jobs/activitylog/",
  executive: "executive",
  // Invoice
  addInvoice: "addinvoice/customerId=",
  getCatalogs: (orgid, isCore) =>
    isCore ? `Organizations/${orgid}/catalogs` : `catalogs/OrgGuid=${orgid}`,
  getCategory: "productcategories/catalogid=",
  getproductbycategoryId: "product/categoryId=",
  //getProductsByAccountingCatalog: 'accountingproducts/orgGuid=',
  getCategorydata: "productcategory/id=",
  getTreeCategory: "categoriestree/catalogId=",
  saveCategoryV2: "productcategory",
  deleteProductCategory: "productcategory?Id=",
  saveProduct: "product",
  getProductCategory: "product/categoryId=",
  getManufacturelist: "manufacturers?orgGuid=",
  taxList: "taxcategories?orgGuid=",
  moveCategoryPosition: "swapproductcategorydisplayorder?categoryId=",
  getProductDetailbyId: "product/productId=",
  getSystemProductDetails: "product/systemOrg=",
  deleteProduct: "product?productId=",
  fullproductinfo: "fullproductinfo",
  saveInvoice: "addinvoice?moduleId=",
  updateInvoice: "saveinvoice",
  printInvoice: "printinvoice?id=",
  deleteInvoice: "invoice/id=",
  undeleteInvoice: "undeleteinvoice?id=",
  processPayment: "processpayment",
  jobDrawSchedule: "jobdrawschedules/",
  getJobDrawScheduleById: "jobs/drawschedule/",
  getOrderbyId: "getallorderdetails/orderId=",
  getCardsbyCustId: "cards?customerId=",
  saveNote: "ordernote",
  deleteInvoiceNote: "ordernote/id=",
  getInvoiceList: "invoices?guid=",
  getReceivables: "receivables?guid=",
  getPastDue: "pastdues?orgGuid=",
  getNotesList: "ordernotes/orderid=",
  getPaymentList: "paymentmethods/orgGuid=",
  getPaymentData: "payments?orgGuid=",
  getPaymentbyDate: "payments?orgGuid=",
  getPaymentById: "payments/id=?orgGuid=",
  cardlookupId: "cardlookup/id=",
  getGatewayAccountByOrg: "gatewayaccountlist/orgGuid=",
  gatewayAccountDefault: "gatewayaccount/orgGuid=",
  gatewayAccount: "gatewayaccount",
  orderTransactionById: "ordertransaction/id=",

  //discount
  getDiscountbyId: "discount/id=",
  addDiscount: "discount",
  updateDiscount: "discount",
  getDiscountData: "catalogdiscounts?catalog=",
  deleteDiscount: "discount/id=",
  // transaction
  voidTransaction: "voidpayment?transactionId=",
  moveTransactions: "movetransactions",
  refundTransaction: "refundpayment",
  splitModel: "splitinvoice",
  // WIPdashborad
  jobInfo: ["jobs/", "/jobinfo"],
  pinnedFiles: "jobs/",
  updatefilePinnedState: "jobs/files/",
  apiKey: "AIzaSyAKHa2pCkZ_MJf1W0NrXtB_B5h5h139u6I",
  clientId:
    "41995130335-0jg7dvth05sadrslq7re7hbi6ngct6b7.apps.googleusercontent.com",
  calendarId: "5oe98efrajaimbvo6e2mspsrpg@group.calendar.google.com",
  staticData: {
    leadSource: [
      { key: "Web", value: 1 },
      { key: "Print", value: 2 },
    ],
    phaseData: [
      { key: "Lead", value: 1 },
      { key: "Prospect", value: 2 },
      { key: "Proposal", value: 3 },
      { key: "Contract", value: 4 },
    ],
    jobFileType: [
      { key: "3D Rendering", value: 14 },
      { key: "A&A", value: 12 },
      { key: "Construction Plan", value: 3 },
      { key: "Contracts", value: 4 },
      { key: "Equipment", value: 17 },
      { key: "Invoice", value: 2 },
      { key: "Layout", value: 11 },
      { key: "Photo", value: 1 },
      { key: "Plumbing Stub Out", value: 16 },
      { key: "Proposal", value: 8 },
      { key: "Selection Sheet", value: 15 },
      { key: "Survey", value: 13 },
      { key: "Workorders", value: 5 },
    ],
    jobFileStage: [
      { key: "Before", value: 1 },
      { key: "During", value: 2 },
      { key: "After", value: 3 },
    ],
  },
  PaymentStatus: [
    { id: 10, name: "Pending" },
    { id: 20, name: "Authorized" },
    { id: 30, name: "Paid" },
    { id: 40, name: "Partially Refunded" },
    { id: 50, name: "Refunded" },
    { id: 60, name: "Voided" },
    { id: 70, name: "Deleted" },
  ],
  InvoiceStatus: [
    { id: 10, name: "Pending" },
    { id: 20, name: "Processing" },
    { id: 30, name: "Completed" },
    { id: 40, name: "Cancelled" },
  ],
  ShippingStatus: [
    { id: 10, name: "Not Required" },
    { id: 20, name: "Not Shipped" },
    { id: 25, name: "Partially Shipped" },
    { id: 30, name: "Shipped" },
    { id: 40, name: "Delivered" },
  ],
  ShippingMethods: [
    { id: 1, name: "Store Pick-Up" },
    { id: 2, name: "Standard" },
    { id: 3, name: "Deliver on Next Visit" },
  ],
  EnumTransactionType: [
    { id: 1, name: "Sale" },
    { id: 3, name: "AVSOnly" },
    { id: 7, name: "Credit" },
    { id: 8, name: "Adjustment" },
    { id: 9, name: "RepeatSale" },
  ],
  RemainderPeriod: [
    { id: 1, name: "15 Minutes" },
    { id: 3, name: "30 Minutes" },
    { id: 7, name: "45 Minutes" },
    { id: 8, name: "1 Hour" },
    { id: 9, name: "1 Day" },
  ],
  RecurringFrequency: [
    { id: 0, name: "None" },
    { id: 1, name: "Hourly" },
    { id: 2, name: "Daily" },
    { id: 3, name: "Monthly" },
    { id: 4, name: "Annually" },
  ],
  DiscountType: [
    { Id: 1, value: "Assigned To Order Total" },
    { Id: 2, value: "Assigned To Skus" },
    { Id: 5, value: "Assigned To Categories" },
    { Id: 6, value: "Assigned To Manufacturers" },
    { Id: 10, value: "Assigned To Shipping" },
    { Id: 20, value: "Assigned To Order SubTotal" },
  ],
  BillingType: [
    { id: 0, name: "None" },
    { id: 1, name: "Project" },
    { id: 2, name: "Progress" },
  ],
  DiscountLimitationType: [
    { Id: 0, value: "Unlimited" },
    { Id: 15, value: "NTimesOnly" },
    { Id: 25, value: "NTimesPerCustomer" },
  ],
  CalatogType: [
    { Id: 1, value: "Accounting" },
    { Id: 2, value: "Gallery" },
    { Id: 3, value: "Equipments" },
    { Id: 8, value: "Online Store" },
  ],
  CatalogEnum: {
    Accounting: 1,
    Pools: 2,
    Equipment: 3,
    Spa: 4,
    Feature: 5,
    ODL: 6,
    Service: 7,
    OnlineStore: 8,
    Instore: 9,
    PoolDesigner: 10,
  },
  rating: [
    { key: "None", value: 0 },
    { key: "Hot", value: 1 },
    { key: "Warm", value: 2 },
    { key: "Cold", value: 3 },
  ],
  saleDashBoardView: {
    Year: 1,
    Month: 2,
    Week: 3,
  },
  selectionSheetUOM: [
    { key: "Square Feet", value: 1 },
    { key: "Linear Feet", value: 2 },
    { key: "Perimeter Feet", value: 3 },
    { key: "Each", value: 4 },
    { key: "Total Interior Area", value: 5 },
  ],

  YearView: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  daysView: [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
  businessRule: [
    { key: "After", value: false },
    { key: "Before ", value: true },
  ],
  priority: [
    { key: "Low", value: 1 },
    { key: "Normal", value: 2 },
    { key: "High", value: 3 },
  ],
  logType: [
    { key: "Information", value: 1 },
    { key: "Warning", value: 2 },
    { key: "Error", value: 3 },
  ],
  // changes to 'status' should reflect in 'allStatus'
  status: [
    { Value: "Not Started", key: 1, className: "" },
    { Value: "In Progress", key: 2, className: "progres" },
    { Value: "Complete", key: 3, className: "done" },
    { Value: "Dead", key: 4, className: "" },
    { Value: "Stopped", key: 5, className: "stopped" },
  ],
  allStatus: [
    { Value: "Not Started", key: 1, className: "" },
    { Value: "In Progress", key: 2, className: "progres" },
    { Value: "Complete", key: 3, className: "done" },
    { Value: "Dead", key: 4, className: "" },
    { Value: "Stopped", key: 5, className: "stopped" },
    { Value: "Deleted", key: 8, className: "" },
  ],
  statusTask: [
    { Value: "Not Started", key: 1, className: "" },
    { Value: "In Progress", key: 2, className: "progres" },
    { Value: "Complete", key: 3, className: "done" },
    { Value: "Closed", key: 4, className: "" },
  ],

  jobStatus: [
    { Value: "Start", key: 1, className: "done" },
    { Value: "Finish", key: 2, className: "progres" },
    { Value: "", key: 3, className: "fa fa-check" },
  ],
  notes: [
    { Value: "Sales", key: 99 },
    { Value: "Build Plan", key: 1 },
    { Value: "Punch List", key: 2 },
    { Value: "Change Order", key: 3 },
    { Value: "Work Order", key: 4 },
  ],
  jobType: [
    { Value: "All", key: 0 },
    { Value: "Sales", key: 1 },
    { Value: "Construction", key: 2 },
    { Value: "PunchList", key: 3 },
    { Value: "WorkOrder", key: 4 },
    { Value: "ChangeOrder", key: 5 },
    { Value: "DrawSchedule", key: 6 },
  ],
  jobTemplateTypes: [
    { value: "New Construction", key: 1 },
    { value: "Remodel/Renovation", key: 2 },
    { value: "Landscaping", key: 3 },
    { value: "Pergola", key: 4 },
    { value: "Other", key: 5 },
  ],
  cameraControls: [
    { key: "Change to AP Mode", value: 1, icon: "fa fa-camera" },
    { key: "Update Firmware", value: 2, icon: "fa fa-cloud-download" },
    { key: "Upload Logs", value: 3, icon: "fa fa-cloud-upload" },
    { key: "Update Settings", value: 4, icon: "fa fa-floppy-o" },
    { key: "Reboot Camera", value: 5, icon: "fa fa-refresh" },
  ],
  drawSchdeuleType: [
    { key: "Percentage", value: 1 },
    { key: "Flat", value: 2 },
    { key: "Custom", value: 3 },
  ],
  states: [
    {
      Id: 3,
      Guid: "7eb7783b-db2c-4ed3-9bf4-a1105ca29864",
      CountryId: 1,
      Name: "Alabama",
      Abbreviation: "AL",
      DisplayOrder: 1,
    },
    {
      Id: 4,
      Guid: "b8e41f71-9f07-4128-bf49-1f8d815964c5",
      CountryId: 1,
      Name: "Alaska",
      Abbreviation: "AK",
      DisplayOrder: 1,
    },
    {
      Id: 7,
      Guid: "8ec8207b-72cf-4c1f-be48-5dc35dfba116",
      CountryId: 1,
      Name: "Arizona",
      Abbreviation: "AZ",
      DisplayOrder: 1,
    },
    {
      Id: 8,
      Guid: "f7c1b5bc-3b39-4789-892a-fdb95f3128c5",
      CountryId: 1,
      Name: "Arkansas",
      Abbreviation: "AR",
      DisplayOrder: 1,
    },
    {
      Id: 9,
      Guid: "6166e61d-bf13-4176-a6e2-0dd82de39947",
      CountryId: 1,
      Name: "California",
      Abbreviation: "CA",
      DisplayOrder: 1,
    },
    {
      Id: 10,
      Guid: "05b246da-2797-4245-82c7-3064f57c54e9",
      CountryId: 1,
      Name: "Colorado",
      Abbreviation: "CO",
      DisplayOrder: 1,
    },
    {
      Id: 11,
      Guid: "aa4211a6-bf9e-4c17-98ac-41c0eb75b2de",
      CountryId: 1,
      Name: "Connecticut",
      Abbreviation: "CT",
      DisplayOrder: 1,
    },
    {
      Id: 12,
      Guid: "ec5dbb00-85ba-4ca4-83b8-74aec7c5ce51",
      CountryId: 1,
      Name: "Delaware",
      Abbreviation: "DE",
      DisplayOrder: 1,
    },
    {
      Id: 15,
      Guid: "73133912-1a40-4210-9cd3-ce361793789e",
      CountryId: 1,
      Name: "Florida",
      Abbreviation: "FL",
      DisplayOrder: 1,
    },
    {
      Id: 16,
      Guid: "0dc7ba92-5d27-4bb2-b2e3-7bc8f77b19c1",
      CountryId: 1,
      Name: "Georgia",
      Abbreviation: "GA",
      DisplayOrder: 1,
    },
    {
      Id: 18,
      Guid: "9b71ddce-b481-4f72-8475-c46af4e657aa",
      CountryId: 1,
      Name: "Hawaii",
      Abbreviation: "HI",
      DisplayOrder: 1,
    },
    {
      Id: 19,
      Guid: "dd49f310-597b-48b9-9444-cf25259adea1",
      CountryId: 1,
      Name: "Idaho",
      Abbreviation: "ID",
      DisplayOrder: 1,
    },
    {
      Id: 20,
      Guid: "01def938-6030-45d3-8eea-41c92bde79f1",
      CountryId: 1,
      Name: "Illinois",
      Abbreviation: "IL",
      DisplayOrder: 1,
    },
    {
      Id: 21,
      Guid: "f7eca6c1-7bdd-408e-bc6d-61d07a11495c",
      CountryId: 1,
      Name: "Indiana",
      Abbreviation: "IN",
      DisplayOrder: 1,
    },
    {
      Id: 22,
      Guid: "47316649-093b-4f92-9100-c42fd7a2d703",
      CountryId: 1,
      Name: "Iowa",
      Abbreviation: "IA",
      DisplayOrder: 1,
    },
    {
      Id: 23,
      Guid: "2aefcfb3-2cb2-412b-90e0-1522ac1101dc",
      CountryId: 1,
      Name: "Kansas",
      Abbreviation: "KS",
      DisplayOrder: 1,
    },
    {
      Id: 24,
      Guid: "cad7ccbc-835b-446b-bffe-82c0bf1973e8",
      CountryId: 1,
      Name: "Kentucky",
      Abbreviation: "KY",
      DisplayOrder: 1,
    },
    {
      Id: 25,
      Guid: "e0c316bc-ef3f-40dc-8750-61f11180e8b1",
      CountryId: 1,
      Name: "Louisiana",
      Abbreviation: "LA",
      DisplayOrder: 1,
    },
    {
      Id: 26,
      Guid: "de8228b7-b30e-4d77-8499-90afc51c72fc",
      CountryId: 1,
      Name: "Maine",
      Abbreviation: "ME",
      DisplayOrder: 1,
    },
    {
      Id: 27,
      Guid: "",
      CountryId: 1,
      Name: "Marshall Islands",
      Abbreviation: "MH",
      DisplayOrder: 1,
    },
    {
      Id: 28,
      Guid: "a6d29f2e-5774-42ac-b570-8d0927fa2f77",
      CountryId: 1,
      Name: "Maryland",
      Abbreviation: "MD",
      DisplayOrder: 1,
    },
    {
      Id: 29,
      Guid: "2e684b12-e759-45c9-8945-208b2d4383a3",
      CountryId: 1,
      Name: "Massachusetts",
      Abbreviation: "MA",
      DisplayOrder: 1,
    },
    {
      Id: 30,
      Guid: "6ace15ca-579a-4138-91a7-cf3e4641bb33",
      CountryId: 1,
      Name: "Michigan",
      Abbreviation: "MI",
      DisplayOrder: 1,
    },
    {
      Id: 31,
      Guid: "140bbdcc-5d19-4346-87de-dd54a2aedbdb",
      CountryId: 1,
      Name: "Minnesota",
      Abbreviation: "MN",
      DisplayOrder: 1,
    },
    {
      Id: 32,
      Guid: "8dd4ac98-d532-40a6-8d16-47c8290da399",
      CountryId: 1,
      Name: "Mississippi",
      Abbreviation: "MS",
      DisplayOrder: 1,
    },
    {
      Id: 33,
      Guid: "301fe59e-ca4c-4ced-8bc4-5e2155330953",
      CountryId: 1,
      Name: "Missouri",
      Abbreviation: "MO",
      DisplayOrder: 1,
    },
    {
      Id: 34,
      Guid: "4de4b1f3-bdf9-4637-89ef-0e1ee4d90d60",
      CountryId: 1,
      Name: "Montana",
      Abbreviation: "MT",
      DisplayOrder: 1,
    },
    {
      Id: 35,
      Guid: "82b8f151-a4ed-4643-9be7-423e03b679e2",
      CountryId: 1,
      Name: "Nebraska",
      Abbreviation: "NE",
      DisplayOrder: 1,
    },
    {
      Id: 36,
      Guid: "521a060d-c0c1-43c9-9fb2-a4decd5fb968",
      CountryId: 1,
      Name: "Nevada",
      Abbreviation: "NV",
      DisplayOrder: 1,
    },
    {
      Id: 37,
      Guid: "e03a9c76-df9f-4cc6-aa83-9ccaeb841315",
      CountryId: 1,
      Name: "New Hampshire",
      Abbreviation: "NH",
      DisplayOrder: 1,
    },
    {
      Id: 38,
      Guid: "542dec93-b6a1-4ca3-b777-961f4c56add0",
      CountryId: 1,
      Name: "New Jersey",
      Abbreviation: "NJ",
      DisplayOrder: 1,
    },
    {
      Id: 39,
      Guid: "bebf7724-6b35-425f-91a0-c313ee4b5115",
      CountryId: 1,
      Name: "New Mexico",
      Abbreviation: "NM",
      DisplayOrder: 1,
    },
    {
      Id: 40,
      Guid: "a6736e1c-771e-4aa7-981b-6a48a308c4df",
      CountryId: 1,
      Name: "New York",
      Abbreviation: "NY",
      DisplayOrder: 1,
    },
    {
      Id: 41,
      Guid: "d39e6dba-c911-47c0-8402-8b96d1db891e",
      CountryId: 1,
      Name: "North Carolina",
      Abbreviation: "NC",
      DisplayOrder: 1,
    },
    {
      Id: 42,
      Guid: "16862d55-3f0f-4d29-9ac6-ae76deb2f949",
      CountryId: 1,
      Name: "North Dakota",
      Abbreviation: "ND",
      DisplayOrder: 1,
    },
    {
      Id: 43,
      Guid: "",
      CountryId: 1,
      Name: "Northern Mariana Islands",
      Abbreviation: "MP",
      DisplayOrder: 1,
    },
    {
      Id: 44,
      Guid: "15a44847-efff-49f7-a248-8a624016d226",
      CountryId: 1,
      Name: "Ohio",
      Abbreviation: "OH",
      DisplayOrder: 1,
    },
    {
      Id: 45,
      Guid: "b9b1cc39-385e-4c59-8f4b-155dca077390",
      CountryId: 1,
      Name: "Oklahoma",
      Abbreviation: "OK",
      DisplayOrder: 1,
    },
    {
      Id: 46,
      Guid: "4701ea25-683b-449c-bb48-c72f95a095e3",
      CountryId: 1,
      Name: "Oregon",
      Abbreviation: "OR",
      DisplayOrder: 1,
    },
    {
      Id: 48,
      Guid: "697cb4cb-9047-4a1c-afc1-33883bfe5aa0",
      CountryId: 1,
      Name: "Pennsylvania",
      Abbreviation: "PA",
      DisplayOrder: 1,
    },
    {
      Id: 50,
      Guid: "8e963109-6923-4f94-b074-2f72559975a8",
      CountryId: 1,
      Name: "Rhode Island",
      Abbreviation: "RI",
      DisplayOrder: 1,
    },
    {
      Id: 51,
      Guid: "9bfe6119-2881-423c-9047-cf5906abce50",
      CountryId: 1,
      Name: "South Carolina",
      Abbreviation: "SC",
      DisplayOrder: 1,
    },
    {
      Id: 52,
      Guid: "ef71103c-a67d-4d41-8583-531a9d61303a",
      CountryId: 1,
      Name: "South Dakota",
      Abbreviation: "SD",
      DisplayOrder: 1,
    },
    {
      Id: 53,
      Guid: "ffb22959-ab64-4b48-b7c2-1dfb80ef5951",
      CountryId: 1,
      Name: "Tennessee",
      Abbreviation: "TN",
      DisplayOrder: 1,
    },
    {
      Id: 54,
      Guid: "5b0a14d3-89ee-4b09-9eda-00b80122a6d4",
      CountryId: 1,
      Name: "Texas",
      Abbreviation: "TX",
      DisplayOrder: 1,
    },
    {
      Id: 55,
      Guid: "b320179e-cd5e-4041-bec4-0b427e008548",
      CountryId: 1,
      Name: "Utah",
      Abbreviation: "UT",
      DisplayOrder: 1,
    },
    {
      Id: 56,
      Guid: "26e1bcdf-bf09-4510-aec1-ecdbc875febd",
      CountryId: 1,
      Name: "Vermont",
      Abbreviation: "VT",
      DisplayOrder: 1,
    },
    {
      Id: 58,
      Guid: "1094162d-f842-4e4e-b79e-7f2fd6747866",
      CountryId: 1,
      Name: "Virginia",
      Abbreviation: "VA",
      DisplayOrder: 1,
    },
    {
      Id: 59,
      Guid: "31ef2331-b325-415a-88e6-4428ec0983d1",
      CountryId: 1,
      Name: "Washington",
      Abbreviation: "WA",
      DisplayOrder: 1,
    },
    {
      Id: 60,
      Guid: "c85b7a7b-4705-4f45-83ff-e7bd4fa7ee22",
      CountryId: 1,
      Name: "West Virginia",
      Abbreviation: "WV",
      DisplayOrder: 1,
    },
    {
      Id: 61,
      Guid: "db7a19ec-2480-458f-9ba9-57cb1e7420ec",
      CountryId: 1,
      Name: "Wisconsin",
      Abbreviation: "WI",
      DisplayOrder: 1,
    },
    {
      Id: 62,
      Guid: "61380508-bf1d-43b5-a158-8761646580a0",
      CountryId: 1,
      Name: "Wyoming",
      Abbreviation: "WY",
      DisplayOrder: 1,
    },
    {
      Id: 80,
      Guid: "8810653A-9A86-4414-84AE-124DFA0F7058",
      CountryId: 2,
      Name: "Alberta",
      Abbreviation: "AB",
      DisplayOrder: 1,
    },
    {
      Id: 81,
      Guid: "C7FDC258-0444-4800-AD71-2B53CAF54071",
      CountryId: 2,
      Name: "British Columbia",
      Abbreviation: "BC",
      DisplayOrder: 1,
    },
    {
      Id: 82,
      Guid: "F871C35D-A691-4834-9CDF-E58A0CA83740",
      CountryId: 2,
      Name: "Manitoba",
      Abbreviation: "MB",
      DisplayOrder: 1,
    },
    {
      Id: 83,
      Guid: "B7080A43-962D-4551-9D10-037F8872A1EC",
      CountryId: 2,
      Name: "New Brunswick",
      Abbreviation: "NB",
      DisplayOrder: 1,
    },
    {
      Id: 84,
      Guid: "B61FD0C2-F829-4620-9380-7BBFC3100CFD",
      CountryId: 2,
      Name: "Newfoundland and Labrador",
      Abbreviation: "NL",
      DisplayOrder: 1,
    },
    {
      Id: 85,
      Guid: "B387FA6D-8155-47DF-9541-90CFD7B593D6",
      CountryId: 2,
      Name: "Nova Scotia",
      Abbreviation: "NS",
      DisplayOrder: 1,
    },
    {
      Id: 86,
      Guid: "C4522E33-05EC-4D78-B309-09E5BD5D6FCD",
      CountryId: 2,
      Name: "Northwest Territories",
      Abbreviation: "NT",
      DisplayOrder: 1,
    },
    {
      Id: 87,
      Guid: "85D56658-38C1-4430-8FEC-64601F2E78A9",
      CountryId: 2,
      Name: "Nunavut",
      Abbreviation: "NU",
      DisplayOrder: 1,
    },
    {
      Id: 88,
      Guid: "5A0DD609-50A6-42BF-8181-FC6D9D6C7849",
      CountryId: 2,
      Name: "Ontario",
      Abbreviation: "ON",
      DisplayOrder: 1,
    },
    {
      Id: 89,
      Guid: "EC16723D-DE0B-42DC-B00B-E71313CE81E5",
      CountryId: 2,
      Name: "Prince Edward Island",
      Abbreviation: "PE",
      DisplayOrder: 1,
    },
    {
      Id: 90,
      Guid: "40C979C3-E954-42AA-AA65-9C6E6BCF9E4C",
      CountryId: 2,
      Name: "Quebec",
      Abbreviation: "QC",
      DisplayOrder: 1,
    },
    {
      Id: 91,
      Guid: "243ACD2D-B639-49F9-9DE1-2C96BAE4678D",
      CountryId: 2,
      Name: "Saskatchewan",
      Abbreviation: "SK",
      DisplayOrder: 1,
    },
    {
      Id: 92,
      Guid: "FDAED6C4-97E0-4DD4-A632-5872EF9A1204",
      CountryId: 2,
      Name: "Yukon",
      Abbreviation: "YT",
      DisplayOrder: 1,
    },
  ],
  colorArray: [
    "#ff6d60",
    "#5bd8b0",
    "#f34f42",
    "#ffc600",
    "#ccdf4f",
    "#b96bb3",
    "#8fc13a",
    "#f3d02d",
    "#4098b7",
    "#63b6c9",
  ],
  templateDesing: [
    { standardDesQtyTot: "65BCF739-9798-4F15-91F9-264A7475359A" },
    { standardDesQtyUomTot: "9F6D023D-7CE0-43FF-AA87-4718BA90A6C1" },
    { standardDesTot: "A5F43A8C-8B7B-498F-8A2E-174B579011AF" },
    { standardDesQtyTotBottom: "95FD7548-C479-4330-8196-EAD94F83E776" },
    { summaryGroup: "9818B2D6-B053-42C7-9F65-503CF90386A8" },
  ],
  importTypes: [
    {
      name: "Product",
      value: "Product",
    },
    {
      name: "Customer",
      value: "Customer",
    },
  ],
  reportQueryAction: {
    ADD: "ADD",
    EDIT: "EDIT",
  },
  reportTypes: [{ text: "WIP", value: 1 }],
  reportCategories: [
    { text: "Cat 1", value: 1 },
    { text: "Cat 2", value: 1 },
  ],
  reportOperators: [
    {
      text: "Is equal to",
      value: "eq",
    },
    {
      text: "Is not equal to",
      value: "ne",
    },
    {
      text: "Is empty",
      value: "isnull",
    },
    {
      text: "Is not empty",
      value: "isnotnull",
    },
    {
      text: "Contains",
      value: "contains",
    },
    {
      text: "Does not contain",
      value: "doesnotcontain",
    },
    {
      text: "Starts with",
      value: "startswith",
    },
    {
      text: "Ends with",
      value: "endswith",
    },
    // {
    //   text: "Is empty",
    //   value: "isempty",
    // },
    // {
    //   text: "Is not empty",
    //   value: "isnotempty",
    // },
    {
      text: "Greater than",
      value: "gt",
    },
    {
      text: "Greater than or equal to",
      value: "ge",
    },
    {
      text: "Less than",
      value: "lt",
    },
    {
      text: "Less than or equal to",
      value: "le",
    },
  ],
  checklistTypes: [
    { value: 1, text: "Checklist" },
    { value: 2, text: "Custom Field" },
  ],
  localStorageKeys: {
    MENU: "menu.isCollapsed",
  },
  // Core API Swagger
  // https://mobilepoolbuildercore-staging.azurewebsites.net/swagger/index.html
  coreApi: {
    // Account
    account: () => `Account`,
    sessionCheck: () => `Account/session/check`,
    login: () => `Account/login`,
    logout: () => `Account/logout`,
    changePassword: () => `Account/changePassword`,
    resetPassword: () => `Account/resetPassword`,
    resetPasswordCode: () => `Account/resetPassword/auto`,

    getStates: () => `common/states`,

    searchAccountCustomers: () => `account/customers/search`,
    searchAccountContractors: () => `account/contractors/search`,
    searchAccountLeads: () => `account/leads/search`,

    // AppThemes
    defaultTheme: () => `AppThemes/default`,
    editTheme: (appThemeGuid) => `AppThemes/${appThemeGuid}`,
    getThemes: () => `AppThemes`,

    // blobs
    getBlobs: (blobGuid) => `Blobs/${blobGuid}`,

    // ChangeOrders
    getChangOrder: (changeOrderGuid) => `ChangeOrders/${changeOrderGuid}`,
    putChangeOrder: (changeOrderGuid) =>
      `ChangeOrders/${changeOrderGuid}/approve`,
    searchChangeOrders: (orgGuid: string) =>
      `changeOrders/${orgGuid}/changeOrders/search`,
    getCOStatuses: () => "ChangeOrders/statuses",
    // chat
    postChat: (groupName) => `Chat/groups/${groupName}/participants`,
    getChat: (groupName) => `Chat/groups/${groupName}`,

    // Categories
    getPutDeleteCategory: (categoryGuid) => `Categories/${categoryGuid}`,
    products: (categoryGuid) => `Categories/${categoryGuid}/products`,
    getPostManagedProducts: (categoryGuid) =>
      `Categories/${categoryGuid}/managedProducts`,

    // organizations:
    getJobs: (orgGuid, shouldReturnRoles = false) =>
      `organizations/${orgGuid}/jobs/search?shouldReturnRoles=${shouldReturnRoles}`,
    getLeads: (orgGuid: string, includeDeleted: boolean) =>
      `organizations/${orgGuid}/jobs/Leadsearch?includeDeleted=${includeDeleted}`,
    getConstructionJobCounts: (orgGuid) =>
      `organizations/${orgGuid}/jobCounts/construction`,
    getSalesJobCounts: (orgGuid) => `organizations/${orgGuid}/jobCounts/sales`,
    getJob: (jobGuid) => `jobs/${jobGuid}`,
    pinJob: (jobGuid) => `jobs/${jobGuid}/pin`,
    unpinJob: (jobGuid) => `jobs/${jobGuid}/unpin`,
    // getConstructionJobWhiteboardCounts: (orgGuid, templateGuid, userGuid) =>
    //   `organizations/${orgGuid}/jobCounts/construction/${templateGuid}/user/${userGuid}`,
    // getSalesJobWhiteboardCounts: (orgGuid, templateGuid, userGuid) =>
    //   `organizations/${orgGuid}/jobCounts/sales/${templateGuid}/user/${userGuid}`,
    getPostOrgCatalogs: (orgGuid) => `Organizations/${orgGuid}/catalogs`,
    getProduct: (productGuid) => `products/${productGuid}`,
    searchProducts: (orgGuid) => `Organizations/${orgGuid}/products/search`,
    featured: (orgGuid) => `Organizations/${orgGuid}/featured`,
    getPutPostOrgAppTheme: (orgGuid) => `Organizations/${orgGuid}/appTheme`,
    getOrgVendorList: (orgGuid) => `Organizations/${orgGuid}/vendors`,
    postOrgVendor: (orgGuid) => `Organizations/${orgGuid}/vendors`,
    getTrades: (orgGuid) => `Organizations/${orgGuid}/trades`,
    getQuickbooksStatus: (orgGuid) =>
      `Organizations/${orgGuid}/quickBooks/status`,
    postQuickbooksConnect: (orgGuid) =>
      `Organizations/${orgGuid}/quickBooks/connect`,
    putQuickbooksDisconnect: (orgGuid) =>
      `Organizations/${orgGuid}/quickBooks/disconnect`,
    postQuickbooksImport: (orgGuid) =>
      `Organizations/${orgGuid}/quickBooks/import`,
    searchQuickbooksEmployees: (orgGuid) =>
      `Organizations/${orgGuid}/quickBooks/employees/search`,
    searchQuickbooksCustomers: (orgGuid) =>
      `Organizations/${orgGuid}/quickBooks/customers/search`,
    searchQuickbooksVendors: (orgGuid) =>
      `Organizations/${orgGuid}/quickBooks/vendors/search`,
    searchQuickbooksProducts: (orgGuid) =>
      `Organizations/${orgGuid}/quickBooks/products/search`,
    syncQuickbookItem: (orgGuid) =>
      `Organizations/${orgGuid}/syncQuickbooksItem`,

    //OrgVendor
    putOrgVendor: (orgVendorGuid) => `OrgVendors/${orgVendorGuid}`,
    getOrgVendor: (orgVendorGuid) => `OrgVendors/${orgVendorGuid}`,

    // Catalogs
    getAllCatalogs: () => `Catalogs`,
    getCatalogInfo: (catalogGuid) => `Catalogs/${catalogGuid}/info`,

    // orgCatalogs:
    putDeleteOrgCatalog: (orgCatalogGuid) => `OrgCatalogs/${orgCatalogGuid}`,
    categories: (orgCatalogGuid) => `OrgCatalogs/${orgCatalogGuid}/categories`,
    getPostManagedCategories: (orgCatalogGuid) =>
      `OrgCatalogs/${orgCatalogGuid}/managedCategories`,
    getPostOrgCatalogInfo: (orgCatalogGuid) =>
      `OrgCatalogs/${orgCatalogGuid}/info`,

    // FeaturedEntities
    getPutDeleteFeaturedEntity: (featuredGuid) =>
      `FeaturedEntities/${featuredGuid}`,

    // products:
    getPutDeleteProduct: (productGuid) => `Products/${productGuid}`,
    getPostDeleteProductThumbnail: (productGuid) =>
      `Products/${productGuid}/thumbnail`,

    getMediaResources: (baseUrl) => `${baseUrl}/mediaResources`,
    deleteMediaResource: (baseUrl, mediaResourceGuid) =>
      `${baseUrl}/mediaResources/${mediaResourceGuid}`,
    putMediaResource: (baseUrl, mediaResourceGuid) =>
      `${baseUrl}/mediaResources/${mediaResourceGuid}`,
    postMediaResourceImage: (baseUrl) => `${baseUrl}/images`,
    postMediaResourceVideo: (baseUrl) => `${baseUrl}/videos`,

    getBlob: (blobGuid) => `blobs/${blobGuid}`,

    searchOrgContractors: (orgGuid) =>
      `organizations/${orgGuid}/contractors/search`,
    searchOrgCustomers: (orgGuid) =>
      `organizations/${orgGuid}/customers/search`,
    searchOrgEmployees: (orgGuid) =>
      `organizations/${orgGuid}/employees/search`,
    searchOrgUsers: (orgGuid) => `organizations/${orgGuid}/users/search`,
    searchOrgExternals: (orgGuid) =>
      `organizations/${orgGuid}/externals/search`,
    searchOrgJobPhases: (orgGuid: string) =>
      `organizations/${orgGuid}/jobphases/search`,

    getUser: (userGuid) => `users/${userGuid}`,
    searchUserJobs: (userGuid) => `users/${userGuid}/jobs/search`,
    searchUserCustomers: (userGuid) => `users/${userGuid}/customers/search`,
    searchUserContractors: (userGuid) => `users/${userGuid}/contractors/search`,
    searchUserLeads: (userGuid) => `users/${userGuid}/leads/search`,

    getJobConnections: (jobGuid) => `jobs/${jobGuid}/connections`,

    // Shipping:
    ShippingMethods: () => `ShippingMethods`,
    getShippingOptions: (orgGuid) => `Organizations/${orgGuid}/shippingOptions`,
    postShippingOptions: (orgGuid) =>
      `Organizations/${orgGuid}/shippingOptions`,
    putShippingOptions: (shippingOptionGuid) =>
      `ShippingOptions/${shippingOptionGuid}`,
    deleteShippingOptions: (shippingOptionGuid) =>
      `ShippingOptions/${shippingOptionGuid}`,

    // Org Vendors
    getVendorContacts: (orgVendorGuid) =>
      `OrgVendors/${orgVendorGuid}/contacts`,
    postVendorContacts: (orgVendorGuid) =>
      `OrgVendors/${orgVendorGuid}/contacts`,

    // VendorContacts
    getContactInfo: (contactGuid) => `VendorContacts/${contactGuid}`,
    putContactInfo: (contactGuid) => `VendorContacts/${contactGuid}`,
    deleteVendorContact: (contactGuid) => `VendorContacts/${contactGuid}`,

    // entityInfo
    getPutDeleteEntityInfo: (entityInfoGuid) => `EntityInfo/${entityInfoGuid}`,
    getEntityInfoMediaResources: (entityInfoGuid) =>
      `EntityInfo/${entityInfoGuid}/mediaResources`,
    putDeleteEntityInfoMediaResources: (entityInfoGuid) =>
      `EntityInfo/{entityInfoGuid}/mediaResources/{mediaResourceGuid}`,
    postEntityInfoImage: (entityInfoGuid) =>
      `EntityInfo/${entityInfoGuid}/images`,
    postEntityInfoVideo: (entityInfoGuid) =>
      `EntityInfo/${entityInfoGuid}/videos`,

    //Checklist
    postOrgChecklistSearch: (orgGuid) =>
      `organizations/${orgGuid}/checklists/search`,
    getChecklistByGuid: (checklistGuid: string) =>
      `Checklists/${checklistGuid}`,
    getChecklistItems: (checklistGuid) => `Checklists/${checklistGuid}/items`,
    deleteChecklist: (
      checklistGuid,
      applyJobs,
      applyJobPhases,
      applySettingsPhase
    ) => `Checklists/${checklistGuid}
      ?applyJobs=${applyJobs}&applyJobPhases=${applyJobPhases}&applyPhases=${applySettingsPhase}`,
    postChecklist: (checklistGuid) => `Checklists/${checklistGuid}`,
    putChecklist: (checklistGuid) => `Checklists/${checklistGuid}`,
    getChecklistByJob: (jobGuid) => `Checklists/job/${jobGuid}`,
    getChecklistByOrg: (orgGuid, queryParams?) =>
      `Organizations/${orgGuid}/checklists?${queryParams}`,
    postChecklistByOrg: (orgGuid) => `Organizations/${orgGuid}/checklists`,
    deleteOrgChecklist: (orgGuid, checklistGuid) =>
      `Organization/${orgGuid}/Checklists/${checklistGuid}`,
    getJobTemplateChecklists: (jobTemplateGuid) =>
      `JobTemplates/${jobTemplateGuid}/checklists`,
    postJobTemplateChecklists: (jobTemplateGuid) =>
      `JobTemplates/${jobTemplateGuid}/checklists`,
    deleteJobTemplateChecklist: (jobTemplateGuid, checklistGuid, applyJobs) =>
      `JobTemplates/${jobTemplateGuid}/checklists/${checklistGuid}?applyJobs=${applyJobs}`,
    getJobTemplatePhaseChecklists: (jobPhaseTemplateGuid) =>
      `JobTemplatePhases/${jobPhaseTemplateGuid}/checklists`,
    postJobTemplatePhaseChecklists: (jobPhaseTemplateGuid) =>
      `JobTemplatePhases/${jobPhaseTemplateGuid}/checklists`,
    deleteJobTemplatePhaseChecklist: (
      jobPhaseTemplateGuid,
      checklistGuid,
      applyJobPhases
    ) =>
      `JobTemplatePhases/${jobPhaseTemplateGuid}/checklists/${checklistGuid}?applyJobPhases=${applyJobPhases}`,
    getJobChecklists: (jobGuid) => `Jobs/${jobGuid}/checklists`,
    postJobChecklists: (jobGuid) => `Jobs/${jobGuid}/checklists`,
    putJobChecklist: (jobGuid, checklistGuid) =>
      `Jobs/${jobGuid}/checklists/${checklistGuid}`,
    deleteJobChecklist: (jobGuid, checklistGuid) =>
      `Jobs/${jobGuid}/checklists/${checklistGuid}`,
    getJobPhaseChecklists: (jobPhaseGuid) =>
      `JobPhases/${jobPhaseGuid}/checklists`,
    postJobPhaseChecklists: (jobPhaseGuid) =>
      `JobPhases/${jobPhaseGuid}/checklists`,
    putJobPhaseChecklist: (jobPhaseGuid, checklistGuid) =>
      `JobPhases/${jobPhaseGuid}/Checklists/${checklistGuid}`,
    deleteJobPhaseChecklist: (jobPhaseGuid, checklistGuid) =>
      `JobPhases/${jobPhaseGuid}/Checklists/${checklistGuid}`,

    //ChecklistItems
    putChecklistItem: (checklistItemGuid) =>
      `ChecklistItems/${checklistItemGuid}`,
    putChecklistItems: (checklistItems) => `ChecklistItems`,
    deleteChecklistItem: (
      checklistItemGuid,
      applyJobs,
      applyJobPhases,
      applySettingsPhase
    ) =>
      `ChecklistItems/${checklistItemGuid}?applyJobs=${applyJobs}&applyJobPhases=${applyJobPhases}&applyPhases=${applySettingsPhase}`,
    reOrderChecklistItems: () => `ChecklistItems/reOrder`,
    //HomeBuilder
    homebuilders: (orgGuid) => `homebuilder/${orgGuid}/all`,
    homebuilder: () => `homebuilder`,
    deleteHomeBuilder: (guid) => `homebuilder/${guid}`,
    homebuilderCateogories: (orgGuid) => `category/${orgGuid}/entity/10`,

    //Jurisdiction
    jurisdictions: (orgGuid) => `jurisdiction/${orgGuid}/all`,
    jurisdiction: () => `jurisdiction`,
    deleteJurisdiction: (guid) => `jurisdiction/${guid}`,
    getReportsList: (moduleId) => `reportserver/category/${moduleId}/reports`,
    getCustomerList: () => `customers/organizationCustomers`,
  },

  // Zip Code Message
  zipCodeMessage:
    "Allowed Zip Codes\nUS:77627\nUS(Zip+4):77627-2627\nCanadian:A1A 1A1",
  zipRegex:
    /^\d{5}(?:[-\s]\d{4})?$|^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ])\ {0,1}(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/,
  zipCodeError: {
    severity: "error",
    summary: "Error",
    detail: "The Zip Code is invalid!",
    closable: false,
  },
  defaultZipCodeCountry: "US",
};
