export class GatewayAccountModel {
  Id: number = 0;
  MerchantId: string;
  Deleted?: Date;
  AccountName: string;
  AccountNumber: string;
  Created: Date;
  Default: Boolean = false;
  OrgGuid: string;
  Updated?: Date;
}
