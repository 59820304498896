import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { FormulaEvaluation, FormulasEvaluationReq, FormulasEvaluationResult, MaterialCalculator } from "app/model/MaterialsCalculator";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { DeleteOpResponse } from "app/model/DeleteOpResponse";
import { WorksheetWorkAreaParameter } from "app/model/WorksheetWorkAreaParameter";

@Injectable({
  providedIn: "root",
})
export class MaterialCalculatorService {
  constructor(private http: HttpClient) {}

  /**
   * Gets all the materials Calculator
   * @param orgGuid
   * @returns
   */
  getMaterialCalculators(orgGuid: string): Observable<MaterialCalculator[]> {
    return this.http.get<MaterialCalculator[]>(
      `${environment.baseEstimateUrl}MaterialsCalculator/${orgGuid}`
    );
  }

  /**
   * Deletes a material calculator
   * @param id
   * @returns
   */
  deleteMaterialCalculator(id: number): Observable<DeleteOpResponse> {
    return this.http.delete<DeleteOpResponse>(
      `${environment.baseEstimateUrl}MaterialsCalculator/${id}`
    );
  }

  /**
   * Creates a material calculator
   * @param orgGuid
   * @param calculator
   * @returns
   */
  createMaterialCalculator(calculator: MaterialCalculator) {
    return this.http.post(
      `${environment.baseEstimateUrl}MaterialsCalculator/create`,
      calculator
    );
  }

  editMaterialCalculator(calculator: MaterialCalculator) {
    return this.http.patch(
      `${environment.baseEstimateUrl}MaterialsCalculator/edit`,
      calculator
    );
  }

  evaluateFormula(formula: string) {
    return this.http.post<FormulaEvaluation>(
      `${environment.baseEstimateUrl}MaterialsCalculator/eval`,
      {formula}
    );
  }

  evaluateFormulaByWorkAreas(workAreasParameters: WorksheetWorkAreaParameter[][], formula: string) {
    return this.http.post<FormulaEvaluation>(
      `${environment.baseEstimateUrl}MaterialsCalculator/eval-workareas`,
      {formula, workAreasParameters}
    );
  }

  evaluateFormulasByParameters(formulas: FormulasEvaluationReq[]) {
    return this.http.post<FormulasEvaluationResult[]>(
      `${environment.baseEstimateUrl}MaterialsCalculator/EvaluateFormulasByParameters`, formulas
    );
  }
}
