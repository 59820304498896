import { Injectable } from "@angular/core";
import { HttpService } from "../../../app/common/utility/http.service";
import { environmentConstant } from "../../../app/common/utility/environment";
import { Observable, Subject } from "rxjs";
import { JobListModel } from "../../../app/model/JobListModel";
import { JobSimpleModel } from "../../../app/model/jobSimpleModel";
import { JobModel } from "../../../app/model/jobModel";
import { JobCameraModel } from "../../../app/model/jobCameraModel";
import { OrgCameraModel } from "../../../app/model/orgCameraModel";
import { DrawSchedulePaymentModel } from "../../../app/model/DrawSchedulePaymentModel";
import { StatusModel } from "../../../app/model/statusModel";
import { JobListModelV2 } from "../../model/jobListModelV2";
import { ScheduleChangeModel } from "../../model/UpdatePhaseScheduleRequestModel";
import {
  JobContactInfoModel,
  JobContactModel,
} from "../../model/jobContactModel";
import { CustomerModel } from "../../../app/model/customerModel";
import { map } from "rxjs/operators";
import { UserPhotoModel } from "app/model/userPhotoModel";
import { FilteredSearchModel } from "app/model/SearchModel";
import { WorkOrderFilter } from "app/model/WorkOrderFilter";
import { ApiResponseModel } from "app/model/ApiResponseModel";
import { WorkOrderDTO } from "app/model/workOrderDTO";
import { Pager } from "app/model/Pager";

@Injectable()
// tslint:disable:prefer-template
export class JobService {
  readonly onUpdateJob = new Subject<boolean>();
  readonly onUpdatePinnedPhoto = new Subject<UserPhotoModel>();
  constructor(private httpService: HttpService) {}

  getAllJobsByOrgId(
    id: any,
    statusId: any,
    startDate: string,
    endDate: string
  ): Observable<JobListModel[]> {
    const url =
      environmentConstant.getallJob +
      id +
      "/organization?jobStatus=" +
      statusId +
      "&startDate=" +
      startDate.toString() +
      "&endDate=" +
      endDate.toString();
    return this.httpService.getRequestService(url);
  }
  getAllJobsByOrgIdv2(id: any, statusId: any, jobType = 2): any {
    const url =
      environmentConstant.getallJob +
      id +
      "/organization/listV2?jobStatus=" +
      statusId +
      "&take=2500" +
      "&jobType=" +
      jobType;
    return this.httpService.getRequestService(url);
  }
  getAllJobsByOrgIdPaged(
    id: any,
    statusId: any,
    filterquery: string,
    page: number,
    pageSize: number,
    sort: string,
    dir: string
  ): any {
    const url =
      environmentConstant.getallJob +
      id +
      "/organization/listV2?jobStatus=" +
      statusId +
      "&term=" +
      filterquery +
      "&page=" +
      page +
      "&take=" +
      pageSize +
      "&sort=" +
      sort +
      "&direction=" +
      dir;
    return this.httpService.getRequestService(url);
  }
  getJobViewByJobId(id: any, statusId: any): any {
    const url = environmentConstant.getallJob + id + "?jobStatus=" + statusId;
    return this.httpService.getRequestService(url);
  }
  getAllJobsByCustomerId(customerId: any): Observable<JobListModel[]> {
    const url = environmentConstant.getallJob + customerId + "/customer";
    return this.httpService.getRequestService(url);
  }
  getAllWorkOrderByorgId(orgId: any) {
    const url = environmentConstant.getWorkOrderByOrgId + orgId;
    return this.httpService.getRequestService(url);
  }
  getAllWorkOrderByorgIdv2(orgId: any) {
    const url = environmentConstant.getWorkOrderByOrgId + orgId + "/v2";
    return this.httpService.getRequestService(url);
  }
  saveJob(
    job: JobSimpleModel,
    orgId: any,
    drawScheduleId,
    sharedCustomerAddress: boolean,
    closeSalesJob: boolean
  ): Observable<JobModel> {
    return this.httpService.postRequestService(
      environmentConstant.saveJob +
        "?drawScheduleTemplate=" +
        drawScheduleId +
        "&shareCustomerAddress=" +
        sharedCustomerAddress +
        "&closeSalesJob=" +
        closeSalesJob,
      job
    );
  }
  updateJob(job: JobSimpleModel, drawScheduleId, jobTemplateId) {
    return this.httpService.putRequestService(
      environmentConstant.updateJob +
        "?drawScheduleTemplate=" +
        drawScheduleId +
        "&jobTemplateId=" +
        jobTemplateId,
      job
    );
  }

  getContactRoles(id: string) {
    return this.httpService.getRequestService(
      environmentConstant.rolesOfJobContacts(id)
    );
  }

  getJobDrawScheduleById(id) {
    return this.httpService.getRequestService(
      environmentConstant.getJobDrawScheduleById + id
    );
  }

  getDrawScheduleWithJobId(guid) {
    return this.httpService.getRequestService("jobs/" + guid + "/drawschedule");
  }

  updateJobWithDrawSchedule(
    job: JobSimpleModel,
    drawScheduleId,
    jobTemplateId
  ) {
    return this.httpService.putRequestService(
      environmentConstant.updateJob +
        "?drawScheduleTemplate=" +
        drawScheduleId +
        "&jobTemplateId=" +
        jobTemplateId,
      job
    );
  }
  getPaymentListByJobId(id: any) {
    return this.httpService.getRequestService(
      environmentConstant.getallJob +
        id +
        environmentConstant.paymentJobTemplate
    );
  }
  postJobThumbnailAssignmentOrUnassignment(id: string, jobFileId: number) {
    return this.httpService.postRequestService(
      environmentConstant.jobThumbnailAssignmentUnassignment(id, jobFileId),
      {}
    );
  }

  getJobBillByJobId(id: any, jobPhaseId) {
    return this.httpService.getRequestService(
      environmentConstant.getallJob + id + "/jobbill?jobPhaseId=" + jobPhaseId
    );
  }
  uploadJobBill(jobId: any, billId: any, formData: any): Observable<any> {
    return this.httpService.UploadRequestService(
      environmentConstant.getallJob + jobId + "/jobbill/" + billId,
      formData
    );
  }
  getJobBillByBillId(id: any) {
    return this.httpService.getRequestService(
      environmentConstant.getallJob + "jobbill/" + id
    );
  }
  updateJobBill(item: any, shouldSyncQuickBooks): any {
    return this.httpService.putRequestService(
      environmentConstant.getallJob +
        item.JobId +
        "/jobbill" +
        `?sendtoQB=${shouldSyncQuickBooks}`,
      item
    );
  }
  saveJobBill(item: any, shouldSyncQuickBooks): any {
    return this.httpService.postRequestService(
      environmentConstant.getallJob +
        item.JobId +
        "/jobbill" +
        `?sendtoQB=${shouldSyncQuickBooks}`,
      item
    );
  }
  deleteJobBillById(Id: any): any {
    return this.httpService.deleteRequestService(
      environmentConstant.getallJob + "jobbill/" + Id
    );
  }
  updateJobBillItem(Id: any, item: any): any {
    return this.httpService.putRequestService(
      environmentConstant.updateJob + "/jobbill/" + Id + "/item",
      item
    );
  }
  deleteJobBillItem(Id: any): any {
    return this.httpService.deleteRequestService(
      environmentConstant.updateJob + "/jobbill/item/" + Id
    );
  }
  saveJobBillItem(Id: any, item: any): any {
    return this.httpService.postRequestService(
      environmentConstant.updateJob + "/jobbill/" + Id + "/item",
      item
    );
  }
  getEditDrawSchedule(id: any) {
    return this.httpService.getRequestService(
      environmentConstant.getallJob + environmentConstant.editDrawSchedule + id
    );
  }
  getJobPhaseList(orgId, jobId) {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhaseById +
        orgId +
        environmentConstant.getjobId +
        jobId
    );
  }
  getJobPhaseListv2(orgId, jobId) {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhaseById + jobId + "/v2"
    );
  }
  getJobPhaseListbasic(jobId) {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhaseById + jobId + "/basic"
    );
  }
  getStatusId(statusId: number): Observable<StatusModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getStatus + "/" + statusId
    );
  }
  doEditDrawSchedule(data: DrawSchedulePaymentModel) {
    return this.httpService.putRequestService(
      environmentConstant.saveJob + environmentConstant.paymentJobTemplate,
      data
    );
  }
  addDrawSchedule(data: DrawSchedulePaymentModel) {
    return this.httpService.postRequestService(
      environmentConstant.saveJob + environmentConstant.paymentJobTemplate,
      data
    );
  }
  deleteDrawSchedule(id: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.saveJob +
        environmentConstant.paymentJobTemplate +
        "/" +
        id
    );
  }
  deletePayment(id: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.saveJobPhoto +
        environmentConstant.editDrawSchedule +
        id
    );
  }

  getJobById(id: any, jobType: number = 0): Observable<JobModel> {
    return this.httpService.getRequestService(
      environmentConstant.getJob +
        id +
        "/job?isLighterPayload=true&jobType=" +
        jobType
    );
  }
  getBuilderById(id: any) {
    return this.httpService
      .getRequestService(environmentConstant.getBuilder + id)
      .pipe(
        map((data) => {
          data.StartDate = new Date(data.StartDate);
          return data;
        })
      );
  }
  getJobByOrgId(orgId: any) {
    return this.httpService.getRequestService(
      environmentConstant.getJob + orgId + environmentConstant.jobData
    );
  }
  deleteJob(id: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.deleteJob + "?id=" + id
    );
  }

  getCameraListByJobId(jobId: any): Observable<JobCameraModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getallJob + jobId + "/cameras"
    );
  }
  getCameraListByOrgId(orgId): Observable<JobCameraModel[]> {
    return this.httpService.getRequestService(
      "v2/organization/" + orgId + "/liveview"
    );
  }
  getLiveCameraListByJobId(jobId): Observable<JobCameraModel[]> {
    return this.httpService.getRequestService(
      "v2/jobcamera/" + jobId + "/liveview"
    );
  }
  getAllJobsByOrgIdV2(orgId, jobStatus: any): Observable<JobListModelV2[]> {
    return this.httpService.getRequestService(
      environmentConstant.getallJob +
        orgId +
        environmentConstant.organizationList +
        "?jobStatus=" +
        jobStatus
    );
  }
  getAllScheduleChangesByJobID(jobID): Observable<ScheduleChangeModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getallJob + jobID + "/jobschedulechange"
    );
  }
  getAllScheduleChangesByJobIDPhaseID(
    jobID,
    jobPhaseID
  ): Observable<ScheduleChangeModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getallJob +
        jobID +
        "/jobschedulechange?jobPhaseId=" +
        jobPhaseID
    );
  }
  getAllJobContactsByJobID(jobID): Observable<JobContactInfoModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getallJob + jobID + "/jobcontacts"
    );
  }

  deleteJobContact(jobId: any, contactId: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.deleteJob + "/" + jobId + "/jobcontact/" + contactId
    );
  }
  saveJobContactByJobId(jobId: any, jobContact: JobContactModel, role) {
    return this.httpService.postRequestService(
      environmentConstant.getJob +
        jobId +
        environmentConstant.contacts +
        "?userRole=" +
        role,
      jobContact
    );
  }
  saveJobContactByNewCustomer(jobId: any, jobContact: CustomerModel) {
    return this.httpService.postRequestService(
      environmentConstant.getJob + jobId + "/" + environmentConstant.customer,
      jobContact
    );
  }
  makeGuestPreview(jobId: any, cameraId: any, isGuestPreview: boolean) {
    return this.httpService.putRequestService(
      environmentConstant.job +
        jobId +
        "/" +
        environmentConstant.getAllJobCameras +
        cameraId +
        "/" +
        isGuestPreview,
      {}
    );
  }

  updateDrawSchedule(draw) {
    return this.httpService.putRequestService(
      environmentConstant.getJob + "drawschedule",
      draw
    );
  }

  /**
   * Get max job number by organization
   * @param organizationGuid organization guid
   * @returns max job number
   */
  getMaxJobNumberByOrganization(
    organizationGuid: string,
    jobType: number
  ): any {
    const url = `${environmentConstant.getJob}${organizationGuid}/maxjobnumber/${jobType}`;
    return this.httpService.getRequestService(url);
  }

  getWorkOrdersDataFiltered(
    workOrdersFilter: FilteredSearchModel<WorkOrderFilter>,
    orgGuid: string
  ): Observable<ApiResponseModel<Pager<WorkOrderDTO>>> {
    const url = `${environmentConstant.workOrder}${orgGuid}/search`;
    return this.httpService.post(url, workOrdersFilter);
  }
}
