<div class="container">
  <div class="row user-search">
    <div class="col-12">
      <label for=""><i class="fa fa-search" aria-hidden="true"></i></label>
      <input
        type="text"
        #search
        class="form-control"
        (keydown.enter)="onSearch(search)"
        placeholder="Enter name, email or username"
      />
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-3">
      <button
        type="button"
        class="btn btn-block"
        (click)="searchContext = 0"
        [ngClass]="searchContext == 0 ? 'btn-primary' : 'btn-secondary'"
      >
        Employees
      </button>
    </div>
    <div class="col-3">
      <button
        type="button"
        class="btn btn-block"
        (click)="searchContext = 1"
        [ngClass]="searchContext == 1 ? 'btn-primary' : 'btn-secondary'"
      >
        Customers
      </button>
    </div>
    <div class="col-3">
      <button
        type="button"
        class="btn btn-block"
        (click)="searchContext = 2"
        [ngClass]="searchContext == 2 ? 'btn-primary' : 'btn-secondary'"
      >
        Contractors
      </button>
    </div>
    <div class="col-3">
      <button
        type="button"
        class="btn btn-block"
        (click)="searchContext = 3"
        [ngClass]="searchContext == 3 ? 'btn-primary' : 'btn-secondary'"
      >
        Leads
      </button>
    </div>
  </div>
  <div *ngIf="items?.length > 0" class="row mt-3">
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          <tr (click)="onUserSelected(item)" *ngFor="let item of items">
            <td>{{ item.fullName }}</td>
            <td>{{ item.email ? item.email : "n/a" }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="items?.length <= 0 && didSearch" class="row mt-3">
    <p>No results found.</p>
  </div>
</div>
