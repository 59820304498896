import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BaThemeSpinner } from "app/theme/services";
import {
  CustomerModel,
  CustomerPhaseEnum,
  CustomerPhaseModel,
} from "app/model/customerModel";
import { CustomerService } from "app/common/services/customer.service";
import { MessageService } from "primeng/components/common/messageservice";
import { StateModel } from "app/model/statesModel";
import { AddressModel } from "app/model/addressModel";
import { CustomerRoleModel } from "app/model/customerRoleModel";
import { CommonService } from "app/common/services/common.service";
import { CommonDataService } from "app/common/services/commonData.service";
import { environmentConstant } from "app/common/utility/environment";
import { SharedService } from "app/common/utility/SharedService";
import { EmailPayload, EnumEmailType } from "app/model/emailMessageModel";
import { EmailMessageService } from "app/common/services/emailmessage.service";
import { ConfirmComponent } from "app/component/dialog/dialog.component";
import { DialogService } from "ng2-bootstrap-modal";
import { FormControl } from "@angular/forms";
import { take } from "rxjs/operators";
import { QuickBooksDataModel } from "app/model/Core/QuickBooksDataModel";
import { UserRoleEnum } from "app/model/Core/UserRoleEnum";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "add-Client",
  templateUrl: "./clientAddEdit.html",
  styleUrls: ["./clientAddEdit.css"],
})
export class ClientAddEdit implements OnInit {
  userNameToEdit: string;
  isValidUserNameVal: boolean;
  isValidUserNameText: string;
  isChecked = false;
  headerText: string;
  btnText: string;
  customer: CustomerModel;
  stateList: StateModel[];
  originalStateList: StateModel[];
  customerRole: CustomerRoleModel[] = [];
  customerPhaseList: CustomerPhaseModel[] = [];
  headerData: string;
  pId: number;
  chatRole: CustomerRoleModel = {
    Id: 18,
    Name: "Chat",
    Active: true,
    OrgGuid: null,
  };
  lastSendDate: Date;
  hideSaveButton: boolean = false;
  isSuperAdmin: boolean = false;
  isUsernameValid: boolean = true;
  emailPattern = environmentConstant.emailPattern;
  selectState: StateModel = {
    Id: -1,
    Guid: "",
    CountryId: 0,
    DisplayOrder: 0,
    Name: "Select State",
    Abbreviation: "",
  };
  @Input() custId: number;
  @Output() updateEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() newCustomerInfo: EventEmitter<any> = new EventEmitter<any>();
  @Input() profileId: number;
  @Input() isSlideOut: boolean;
  @ViewChild("myForm", { static: false }) myForm: any;

  zipValidate: boolean = false;
  isFinancial: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private _spinner: BaThemeSpinner,
    private customerService: CustomerService,
    private notificationsService: MessageService,
    private commonService: CommonService,
    private commonDataService: CommonDataService,
    private router: Router,
    private sharedService: SharedService,
    private toastrService: ToastrService
  ) {
    this.customer = new CustomerModel();
    this.customer.Address = new AddressModel();
  }
  ngOnInit() {
    this._spinner.show();
    this.isSuperAdmin = this.sharedService.user.hasRole(
      UserRoleEnum.SuperAdmin
    );
    this.isFinancial = this.sharedService.user.isFinancial;
    if (this.router.url.includes("profile")) {
      this.route.params.subscribe((params) => {
        this.pId = params["id"];
      });
    } else if (this.profileId && this.profileId > 0) {
      this.pId = this.profileId;
    }
    this.getStatesList();
    this.getCustomerPhaseList();
  }
  getStatesList() {
    this.commonService.getStates().subscribe((states) => {
      this.stateList = states;
      this.originalStateList = states;
      this.getCustomerById();
    });
  }

  getCustomerPhaseList() {
    this.customerPhaseList = CustomerService.getCustomerPhaseModelList();
  }

  public filterState(filter: any): void {
    this.customer.Address.StateProvince = this.stateList[0];
    this.customer.Address.StateProvinceId = filter.Id;
    this.customer.Address.StateProvinceAbbreviation = filter.Abbreviation;

    this.stateList = this.originalStateList.filter(
      (s) => s.Abbreviation.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }

  getCustomer() {
    if (this.custId === 0) {
      this.headerText = "Add";
      this.btnText = "Save";
      this.headerData = "Customer";
      this.customer = new CustomerModel();
      this.customer.Phase == CustomerPhaseEnum.Job;
      this.customer.Address.StateProvinceId =
        this.commonService.getCurretnOrganizationStateId();
      this._spinner.hide();
    } else {
      if (
        this.router.url.includes("profile") ||
        (this.profileId && this.profileId > 0)
      ) {
        this.headerData = "Profile";
        this.headerText = "Edit";
        this.btnText = "Update";
      } else {
        this.headerText = "Edit";
        this.btnText = "Update";
        this.headerData = "Customer";
      }

      this.customerService
        .getCustomerById(this.custId ? this.custId : this.pId)
        .subscribe((data) => {
          if (data) {
            //quickbooks deserialize hack
            if ((<any>data).QuickBooksData) {
              data.quickBooksData = <QuickBooksDataModel>{
                sync: (<any>data).QuickBooksData.Sync,
                identifier: (<any>data).QuickBooksData.Identifier,
              };
            }

            this.customer = data;
            // This will force the form validation to give error feedback when existing customers have a
            // phase value of 0, which we don't want allowed.
            this.customer.Phase =
              this.customer.Phase == CustomerPhaseEnum.None
                ? null
                : this.customer.Phase;
            this.userNameToEdit = this.customer.UserName;
            this.customer.Disable = this.customer.Active ? false : true;
            if (data.Address === undefined || data.Address === null) {
              this.customer.Address = new AddressModel();
            }
            if (this.customer.CustomerRoles.some((x) => x.Id === 18)) {
              this.customer.chatEnabled = true;
            }
          }
          this._spinner.hide();
        });
    }
  }
  getCustomerById() {
    this.commonDataService.getCustomerByRoleId(6).then((data) => {
      this.customerRole.push(data);
      this.getCustomer();
    });
  }

  onSubmit(form, callback?: () => void) {
    if (form.valid && !this.hideSaveButton) {
      this.hideSaveButton = true;
      this._spinner.show();

      // if(this.customer.chatEnabled) {
      //     this.customer.CustomerRoles.push(this.chatRole)
      // } else if (!this.customer.chatEnabled) {
      //     this.customer.CustomerRoles = [{
      //         Id: 6,
      //         Name: "Customer",
      //         Active: true,
      //         OrgGuid: null,
      //     }];
      // }
      const obj = this.customer;
      this.customer.Active = !this.customer.Disable;
      this.validateZipCode(this.customer.Address.ZipPostalCode);
      if (!this.zipValidate) {
        if (this.custId === 0) {
          const customerObj = new CustomerModel();
          obj.CustomerRoles = this.customerRole;
          if (this.customer.chatEnabled) obj.CustomerRoles.push(this.chatRole);
          obj.OrgId = this.sharedService.selectedOrganization.ID;
          this.customerService
            .saveCustomer(
              this.customer,
              this.sharedService.selectedOrganization.ID
            )
            .subscribe(
              (result) => {
                if (!!callback) {
                  this.hideSaveButton = false;
                  callback();
                  return;
                }

                this.updateEvent.emit(true);
                this.newCustomerInfo.emit(result);
                // this.result(result, "Added Successfully!!");
                this.toastrService.success("Added Successfully!!");
                this.myForm.form.markAsPristine();
              },
              (error) => {
                this.newCustomerInfo.emit(false);
                this.error(error);
              }
            );
        } else {
          const item = this.customer;
          this.customerService.updateCustomerV2(this.customer).subscribe(
            (result) => {
              let data = result;
              if (!!callback) {
                this.hideSaveButton = false;
                callback();
                return;
              }
              this.myForm.form.markAsPristine();
              this.updateEvent.emit(true);
              // this.result(result, "Updated Successfully!!");
              this.toastrService.success("Updated Successfully!!");
            },
            (error) => {
              this.error(error);
            }
          );
          // item.UserName = null;
        }
      } else {
        this._spinner.hide();
        this.notificationsService.add(environmentConstant.zipCodeError);
      }
    }
    this.hideSaveButton = false;
  }

  isValidEmail(Username, isValid) {
    var isFilled = false;
    if (Username && isValid && this.custId == 0 && Username.length != 0) {
      if (this.customer.UserName == undefined || this.customer.UserName == "") {
        this.customer.UserName = Username;
        isFilled = true;
      }

      if (isFilled) {
        this.customerService.isValidUserName(Username).subscribe(
          (data) => {},
          (error) => {
            if (error.status === 302) {
              this.isValidUserNameText = "This User Name already exists";
              this.isValidUserNameVal = true;
              this.isUsernameValid = false;
            } else {
              this.isValidUserNameText = "This is valid email";
              this.isValidUserNameVal = false;
              this.isUsernameValid = true;
            }
          }
        );
        isFilled = false;
      } else {
        this.isValidUserNameVal = undefined;
      }
    }
  }
  isValidUserName(userName, isValid) {
    // this.customer.Email = userName;
    if (
      !!userName &&
      isValid &&
      this.userNameToEdit != this.customer.UserName
    ) {
      this.customerService.isValidUserName(userName).subscribe(
        (data) => {},
        (error) => {
          if (error.status === 302) {
            this.isValidUserNameText = "This User Name already exists";
            this.isValidUserNameVal = true;
          } else {
            this.isValidUserNameText = "This is valid email";
            this.isValidUserNameVal = false;
          }
        }
      );
    } else {
      this.isValidUserNameVal = undefined;
    }
  }

  private error(error: any) {
    switch (error.status) {
      case 409:
        // this.notificationsService.add({
        //   severity: "warn",
        //   summary: "Customer",
        //   detail: "This User Name or Email already exists",
        // });
        this.toastrService.error("The user name is already in use");
        this._spinner.hide();
        break;

      case 500:
        this.toastrService.error(error.error.Message);
        this._spinner.hide();
        this.updateEvent.emit(false);
        break;

      default:
        this.toastrService.error(error.message);
        this._spinner.hide();
        this.updateEvent.emit(false);
        break;
    }
  }
  private result(res: any, message: string) {
    this.notificationsService.add({
      severity: "success",
      summary: "Customer",
      detail: message,
    });
    this._spinner.hide();
    this.onCancel();
  }
  onCancel() {
    if (
      this.router.url.includes("profile") ||
      (this.profileId && this.profileId > 0)
    ) {
      this.updateEvent.emit(false);
    } else {
      this.updateEvent.emit(false);
    }
    this.newCustomerInfo.emit(false);
  }

  /**
   * Method for validate Zip Code values
   * @param zipCodeValue
   */
  validateZipCode(zipCodeValue) {
    if (zipCodeValue != "") {
      environmentConstant.zipRegex.test(zipCodeValue)
        ? (this.zipValidate = false)
        : (this.zipValidate = true);
    } else {
      this.zipValidate = false;
    }
  }
}
