export class Settings {
  Id: number;
  SettingKey: string;
  DisplayName: string;
  SettingGroupId: number;
  GroupName: string;
  DataTypeId: number;
  OrganizationOrganizationSettingId: number;
  Value: string;
  OrganizationId: string;
  Visible: boolean;
  ReadOnly: boolean;
  DefaulValue: string;
  SettingValues: OrganizationSettingValue[];
  BooleanValue?: boolean;
  NumericValue?: number;
  DateValue?: Date;
}

export enum DataType {
  Numeric = 1,
  String = 2,
  Boolean = 3,
  Date = 4,
}

export interface OrganizationSettingValue {
  Id: number;
  Description: string;
  Value: number;
  DisplayOrder: number;
}

export interface OrganizationOrganizationSetting {
  Id: number;
  OrganizationId: string;
  SettingId: number;
  Value: string;
  CreatedOn: Date;
}

export interface AddRemoveOrganizationOrganizationSetting {
  AddSettings: OrganizationOrganizationSetting[];
  RemoveSettings: OrganizationOrganizationSetting[];
  UpdateSettings: OrganizationOrganizationSetting[];
}
