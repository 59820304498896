import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { JobPhaseService } from "app/common/services/jobPhase.service";
import { BaThemeSpinner } from "app/theme";
import { DialogService } from "ng2-bootstrap-modal";
import { takeWhile } from "rxjs/operators";
import { RequestForServiceService } from "../../common/services/request-for-service.service";
import { ConfirmComponent, ConfirmModel } from "../dialog/dialog.component";
import { JobPhasModel } from "../../model/jobPhaseModel";
import { JobStatusEnum } from "../../common/directives/commonEnum";
import {
  ScheduleErrorDialogComponent,
  SchedulErrorDTO,
} from "../schedule-error-dialog/schedule-error-dialog.component";

interface JobPhaseSchedulingData {
  StatusId: number;
  VendorAccessCode: string;
  CreatedOn: string;
  ScheduledDate: string;
  Id: number;
}

enum ScheduleRequestStatusEnum {
  WaitingReplyDateRange = 1,
  WaitingApproval = 2,
  DateConfirmed = 3,
  DateRejected = 4,
  WaitingOnDependentResponse = 6,
  WaitingReplyDateEntered = 7,
}
@Component({
  selector: "request-for-service",
  templateUrl: "./request-for-service.component.html",
  styleUrls: ["./request-for-service.component.scss"],
})
export class RequestForServiceComponent implements OnInit, OnDestroy {
  @Output() requestForServiceEmitter = new EventEmitter<void>();
  enableStart: boolean;
  enableComplete: boolean;
  enableViewRequest: boolean;
  enableRequestService: boolean;
  enableProposed: boolean;
  isCompleted: boolean;
  displayText: string;

  private scheduledDate: Date;
  private startedOn: Date | string;
  private accessCode: string;
  private requestedOn: Date | string;
  private proposedOn: Date | string;
  private phaseName: string;
  private alive: boolean = true;
  private requestId: number;
  private phaseId: number;
  private haveScheduledQueueRecord: boolean;
  private jobPhaseStatusId: number;
  private requestQueueStatusId: number;
  private get hasStarted(): boolean {
    return !!this.startedOn;
  }
  private get hasBeenScheduled(): boolean {
    return !!this.scheduledDate;
  }
  private get hasBeenRequested(): boolean {
    return [
      ScheduleRequestStatusEnum.WaitingReplyDateEntered,
      ScheduleRequestStatusEnum.WaitingReplyDateRange,
      ScheduleRequestStatusEnum.WaitingOnDependentResponse,
    ].includes(this.requestQueueStatusId);
  }

  constructor(
    private requestForService: RequestForServiceService,
    private datePipe: DatePipe,
    private dialogService: DialogService,
    private jobPhaseService: JobPhaseService,
    private spinner: BaThemeSpinner
  ) {}

  ngOnInit() {
    this.disableAll();
    this.requestForService.jobPhaseDetails
      .pipe(takeWhile(() => this.alive))
      .subscribe((data) => {
        if (data) {
          this.setJobPhaseData(data);
          this.getJobPhaseSchedulingData();
        }
      });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  onStart() {
    this.handleDialog(
      () => this.start(),
      () => this.buildStartCompleteDialogText(true)
    );
  }

  onComplete() {
    this.handleDialog(
      () => this.complete(),
      () => this.buildStartCompleteDialogText(false)
    );
  }

  onAccept() {
    this.handleDialog(
      () => this.accept(),
      () => this.buildAcceptRejectDialogText(true)
    );
  }

  onReject() {
    this.handleDialog(
      () => this.reject(),
      () => this.buildAcceptRejectDialogText(false)
    );
  }

  onViewRequest() {
    this.redirectToRequest();
  }

  onRequestService() {
    this.requestForServiceEmitter.emit();
  }

  private setState() {
    this.enableRequestService =
      this.requestQueueStatusId === ScheduleRequestStatusEnum.DateRejected ||
      (!this.haveScheduledQueueRecord && !this.hasBeenScheduled);
    this.enableViewRequest = this.hasBeenRequested && !this.hasStarted;
    this.enableProposed =
      this.requestQueueStatusId === ScheduleRequestStatusEnum.WaitingApproval;
    this.enableStart =
      (this.requestQueueStatusId === ScheduleRequestStatusEnum.DateConfirmed &&
        !this.hasStarted) ||
      (!this.haveScheduledQueueRecord && this.hasBeenScheduled);
    this.enableComplete =
      this.jobPhaseStatusId === JobStatusEnum.InProgress && this.hasStarted;
  }

  private setDisplayText() {
    let displayText = "";
    if (this.enableComplete)
      displayText = this.buildDisplayText(this.startedOn, "Started");
    if (this.enableViewRequest)
      displayText = this.buildDisplayText(this.requestedOn, "Requested", true);
    if (this.enableProposed)
      displayText = this.buildDisplayText(this.proposedOn, "Proposed");

    this.displayText = displayText;
  }

  private reject() {
    this.spinner.show();
    this.jobPhaseService
      .rejectProppsedDate(this.requestId)
      .subscribe((data) => {
        this.requestForService.updateAndReset();
      })
      .add(() => this.spinner.hide());
  }

  private accept() {
    this.spinner.show();
    this.jobPhaseService
      .acceptProppsedDate(this.requestId)
      .subscribe((data) => {
        this.requestForService.updateAndReset();
      })
      .add(() => this.spinner.hide());
  }

  private start() {
    this.spinner.show();
    this.jobPhaseService
      .startJobPhase(this.phaseId)
      .subscribe((data: boolean) => {
        this.requestForService.updateAndReset();
      })
      .add(() => this.spinner.hide());
  }

  private complete() {
    this.hasScheduledError()
      ? this.handleCompleteWithScheduleError()
      : this.completePhase();
  }

  private buildDisplayText(
    date: Date | string,
    verb: string,
    includeTime: boolean = false
  ): string {
    return `${verb} on ${this.datePipe.transform(
      date as string,
      includeTime ? "short" : "shortDate"
    )}`;
  }

  private buildAcceptRejectDialogText(forAccepted: boolean): ConfirmModel {
    const verb = forAccepted ? "Accept" : "Reject";
    return {
      title: `${verb} Schedule Response`,
      message: `Are you sure you want to ${verb.toLowerCase()} proposed date ${this.datePipe.transform(
        this.proposedOn,
        "shortDate"
      )} for ${this.phaseName}?`,
    };
  }

  private buildStartCompleteDialogText(forStart: boolean): ConfirmModel {
    const verb = forStart ? "Start" : "Complete";
    return {
      title: `${verb} Job Phase`,
      message: `Are you sure you want to ${verb.toLowerCase()} ${
        this.phaseName
      }?`,
    };
  }

  private redirectToRequest() {
    window.open(`./#/accessCode/${this.requestId}/${this.accessCode}`);
  }

  private setJobPhaseData(phase: JobPhasModel) {
    this.scheduledDate = phase.ScheduledDate;
    this.startedOn = phase.ActualStartDate;
    this.phaseName = phase.PhaseName;
    this.isCompleted = phase.IsComplete;
    this.phaseId = phase.ID;
    this.jobPhaseStatusId = phase.Status;
  }

  private getJobPhaseSchedulingData() {
    this.jobPhaseService
      .getJobPhaseSchedulingByJobPhaseId(this.phaseId)
      .subscribe((data: JobPhaseSchedulingData) => {
        this.setJobPhaseSchedulingData(data);
        this.setState();
        this.setDisplayText();
      });
  }

  private setJobPhaseSchedulingData(data: JobPhaseSchedulingData) {
    this.requestQueueStatusId = data ? data.StatusId : null;
    this.haveScheduledQueueRecord = !!data;
    if (this.haveScheduledQueueRecord) {
      this.accessCode = data.VendorAccessCode;
      this.requestedOn = new Date(data.CreatedOn + "Z");
    }
    this.proposedOn =
      !!data && data.ScheduledDate ? new Date(data.ScheduledDate) : null;
    this.requestId = data ? data.Id : null;
  }

  private completePhase(datum?: SchedulErrorDTO) {
    this.jobPhaseService
      .completeJobPhase(
        this.phaseId,
        datum ? datum.StartDate.toLocaleString() : null,
        datum ? datum.EndDate.toLocaleString() : null
      )
      .subscribe((data) => {
        this.requestForService.updateAndReset();
      });
  }

  private handleDialog(
    onConfirmed: () => void,
    dialogBuilder: () => ConfirmModel
  ) {
    let isOpened = true;
    this.dialogService
      .addDialog(ConfirmComponent, dialogBuilder())
      .pipe(takeWhile(() => isOpened))
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) onConfirmed();
        isOpened = false;
      });
  }

  private hasScheduledError(): boolean {
    return new Date(new Date().toDateString()) < this.startedOn;
  }

  private handleCompleteWithScheduleError() {
    let isOpened = true;
    const data = new SchedulErrorDTO();
    data.EndDate = new Date(new Date().toDateString());
    data.StartDate = this.startedOn as Date;
    this.dialogService
      .addDialog(ScheduleErrorDialogComponent, { Scm: data })
      .pipe(takeWhile(() => isOpened))
      .subscribe((data) => {
        if (data) {
          this.completePhase(data);
        }
        isOpened = false;
      });
  }

  private disableAll() {
    this.enableComplete = false;
    this.enableProposed = false;
    this.enableRequestService = false;
    this.enableStart = false;
    this.enableViewRequest = false;
  }
}
