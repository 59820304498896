import { Injectable } from "@angular/core";
import { SharedService } from "app/common/utility/SharedService";
import { UserModel } from "app/model/Core/UserModel";
import { JobPhasModel } from "app/model/jobPhaseModel";
import { TaskDto, ToDoListDto } from "app/model/ToDoListDto";
import { keyBy, mapValues } from "lodash";

const permissions = [
  "create",
  "assign",
  "start",
  "complete",
  "close",
  "delete",
  "editStatus",
  "editDescription",
  "editScheduledDate",
  "editCompletedOn",
  "editAssignedEmployee",
] as const;

export type TaskPermission =
  | "create"
  | "assign"
  | "start"
  | "complete"
  | "close"
  | "delete"
  | "editStatus"
  | "editDescription"
  | "editScheduledDate"
  | "editCompletedOn"
  | "editAssignedEmployee";

export type TaskPermissions = {
  [key in TaskPermission]: boolean;
};

export type ToDo = ToDoListDto | TaskDto | JobPhasModel;

export const FULL_PERMISSIONS = mapValues(
  keyBy(permissions),
  () => true
) as TaskPermissions;

@Injectable()
export class TodoPermissionsService {
  private loggedInUser: UserModel;
  private permissionMap: { [taskId: number]: TaskPermissions } = [];

  constructor(sharedService: SharedService) {
    this.loggedInUser = sharedService.user;
  }

  public setTaskPermissionsMap(tasks: ToDoListDto[]) {
    this.permissionMap = mapValues(
      keyBy(tasks, (t) => this.getTaskId(t)),
      (task) => this._getPermissionsForTask(task)
    );
  }

  public getPermissionsForTask(task: ToDoListDto): TaskPermissions {
    const id = this.getTaskId(task);
    if (!this.shouldUsePermissions || this.permissionMap[id] == null) {
      return FULL_PERMISSIONS;
    }
    return this.permissionMap[id];
  }

  public get shouldUsePermissions() {
    return Object.getOwnPropertyNames(this.permissionMap).length > 0;
  }

  private getTaskId(task: ToDo): number {
    if (task["ID"] !== undefined) {
      return (task as ToDoListDto).ID;
    } else {
      return (task as TaskDto).TaskId;
    }
  }

  private _getPermissionsForTask(task: ToDoListDto): TaskPermissions {
    const isOwner = task.OwnerId === this.loggedInUser.id;
    return {
      create: isOwner,
      assign: isOwner,
      start: true,
      complete: true,
      close: isOwner,
      delete: isOwner,
      editStatus: true,
      editDescription: true,
      editScheduledDate: true,
      editCompletedOn: true,
      editAssignedEmployee: isOwner,
    };
  }
}
