import { Calculator } from "./CalculatorErrorsData";
import { EstimateProductModel } from "./EstimateProductModel";
import { KitGroup } from "./KitModel";
import { ValueTypeValidationEnum } from "./Parameter";

export interface KitDetails {
  id: number;
  kitProductId: number;
  kitPartProductId: number;
  kitGroupDetailId?: number;
  qty: number;
  unitOfMeasureId: number;
  unitOfMeasureName: string;
  productTypeId?: number;
  type: string;
  itemName: string;
  description?: string;
  materialsCalculatorId?: number;
  isRequired: boolean;
  editableQty: boolean;
  showPricing: boolean;
  calculatorName?: string;
  orgGuid: string;
  displayOrder: number;
  kitGroupDetail?: KitGroupDetail;
  kitGroup?: KitGroup;
  sku: string;
  useCalculator: boolean;
  valueType: ValueTypeValidationEnum;
  customerSheetFeatureId?: number;
  worksheetFeatureId?: number;
  customerSheetFeature?: KitDetailOption;
  worksheetFeature?: KitDetailOption;
  calculator?: Calculator;
  kitPartProduct?: EstimateProductModel;
  kitGroupId?: number;
}
export interface KitDetailOption {
  id: number;
  description: string;
}
export interface ComplexKitDetails {
  id: number;
  groupName: string;
  displayOrder: number;
  details: KitDetails[];
}
export interface KitGroups {
  id?: number;
  groupName: string;
  orgGuid?: string;
  displayOrder: number;
  description?: string;
}

export enum KitDetailOptionEnum {
  DoNotShow = 1,
  ShowAsLineProduct = 2,
  ShowAsLineProductAndShowProductPricing = 3,
}

export interface KitDetailOption {
  id: number;
  description: string;
}

export interface Calculators {
  id: number;
  calculatorName: string;
  type: string;
}

export enum ProductTypeEnum {
  All = 0,
  Item = 1,
  SimpleKit = 2,
  ComplexKit = 3,
  Service = 4,
  Labor = 5,
}

export interface ProductTypes {
  type: number;
}

export interface KitDetailsObj {
  id: number;
  group: string;
  types: any[];
}
export interface KitGroupDetail {
  id: number;
  kitGroupId: number;
  kitProductId: number;
  orgGuid: string;
  kitGroup?: KitGroup;
  displayOrder: number;
}

/**
 * An update kit detail request DTO
 */
export interface UpdateKitDetailDTO {
  qty: number;
  materialsCalculatorId?: number;
  editableQty: boolean;
  customerSheetFeatureId?: number;
  worksheetFeatureId?: number;
  kitGroupId?: number;
  displayOrder: number;
  kitGroupDetailId?: number;
  kitGroupDisplayOrder?: number;
}
