<div
  class="widgets estimate-add-photo-container ht100"
  style="overflow-x: hidden"
>
  <div class="row ht100">
    <div class="col-md-12 ht100 container-photo-add px-3 py-2">
      <div class="estimate-add-photo-container-title">
        <h1>Add {{headertext}}</h1>
      </div>
      <div
        class="mt-4 d-flex layout-flex-column estimate-add-photo-container-form"
      >
        <form
          #FileForm="ngForm"
          (ngSubmit)="onSubmit(FileForm)"
          *ngIf="jobphoto!=undefined"
          class="form-horizontal mt-4"
        >
          <div>
            <div class="row px-2">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div
                  class="form-group input-pentair-std"
                  *ngIf="headertext=='Photo'; then thenPhotoBlock else elseFileBlock"
                ></div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <ng-template #thenPhotoBlock>
                  <div class="formfield input-pentair-std">
                    <label class="estimate-add-photo-file-title"
                      >File Type:</label
                    >
                    <kendo-textbox-container
                      [ngClass]="{'k-state-filled':true}"
                    >
                      <kendo-dropdownlist
                        kendoTextBox
                        name="JobFileTypeId"
                        [valuePrimitive]="true"
                        [disabled]="disabled"
                        [data]="lstFileType"
                        textField="FileTypeName"
                        #JobFileTypeId="ngModel"
                        [(ngModel)]="jobphoto.JobFileTypeId"
                        valueField="Id"
                        required
                      >
                      </kendo-dropdownlist>
                    </kendo-textbox-container>
                    <div class="project-allowed-type">
                      <small>
                        Allowed file types: {{allowedExtensionsText}}. Maximum
                        upload file size: {{ fileRestrictions.maxFileSize /
                        1000000 }} MB.</small
                      >
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
            <!-- <file-add [type]="'image'"></file-add> -->
            <div class="row px-2">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <ng-template #elseFileBlock>
                  <div class="formfield Mandat input-pentair-std">
                    <label class="estimate-add-photo-file-title"
                      >File Type:</label
                    >
                    <kendo-textbox-container
                      [ngClass]="{'k-state-filled':true}"
                    >
                      <kendo-dropdownlist
                        [defaultItem]="defaultItem"
                        kendoTextBox
                        name="JobFileTypeId"
                        [valuePrimitive]="true"
                        [data]="lstFileType"
                        id="JobFileTypeId"
                        textField="FileTypeName"
                        #JobFileTypeId="ngModel"
                        [ngClass]="{'has-error':JobFileTypeId.invalid && (JobFileTypeId.dirty || JobFileTypeId.touched)}"
                        [(ngModel)]="jobphoto.JobFileTypeId"
                        valueField="Id"
                        required
                      >
                      </kendo-dropdownlist>
                    </kendo-textbox-container>
                  </div>
                </ng-template>
              </div>
              <div
                class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                *ngIf="!isThisForPhase"
              >
                <div class="form-group">
                  <div
                    class="formfield input-pentair-std"
                    *ngIf="!isSalesFile && lstJobPhase.length > 0"
                  >
                    <label class="estimate-add-photo-file-title">Phase:</label>
                    <kendo-textbox-container
                      [ngClass]="{'k-state-filled':true}"
                    >
                      <kendo-dropdownlist
                        kendoTextBox
                        name="JobPhaseId"
                        [valuePrimitive]="true"
                        [data]="lstJobPhase"
                        id="JobPhaseId"
                        textField="PhaseName"
                        #JobPhaseId="ngModel"
                        [ngClass]="{'has-error':JobPhaseId.invalid && (JobPhaseId.dirty || JobPhaseId.touched)}"
                        [(ngModel)]="jobphoto.JobPhaseId"
                        valueField="ID"
                      >
                      </kendo-dropdownlist>
                    </kendo-textbox-container>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" id="file-drop-area">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div
                  class="form-group"
                  *ngIf="headertext== 'Photo'; then thenBlock else elseBlock"
                ></div>
              </div>
            </div>
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <ng-template #thenBlock>
                  <!-- <label for="text" class="form-control-label clabel">Select Image</label>
                    <ba-picture-uploader   (change)="fileChange($event)">
                      <div   class="input-group" style="margin-top:-10px;">
                        <input   class="form-control" readonly="" type="text">
                        <span   class="input-group-btn">
                          <button   class="btn btn-success" type="button">Browse</button>
                        </span>
                      </div>'jobs/' + jobId + '/filetype/' + filetype + '/phase/' + jobPhaseId + (phasephotoFlag ? ('?phasephotoFlag=' + phasephotoFlag) : '')
                    </ba-picture-uploader> -->
                  <file-add
                    #addFiles
                    [type]="'image'"
                    dropAreaId="file-drop-area"
                    (FileSelected)="FileSelected($event)"
                    (uploadCompleted)="uploadDone($event)"
                    [jobId]="jobId"
                    [filetype]="jobphoto.JobFileTypeId"
                    [jobPhaseId]="jobphoto.JobPhaseId"
                    [phasephotoFlag]="fileStage"
                    [allowedExtensions]="fileRestrictions.allowedExtensions"
                    [maxFileSize]="fileRestrictions.maxFileSize"
                    [adjustHeight]="false"
                    [allowMultiple]="allowMultiple"
                    [estimateId]="estimateId"
                    [changeOrderId]="changeOrderId"
                    [productId]="productId"
                  ></file-add>
                </ng-template>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <ng-template #elseBlock>
                  <!-- <label for="text" class="form-control-label clabel">Select File</label>
                    <ba-file-uploader (change)="fileChange($event)">
                      <div class="input-group" style="margin-top:-10px;">
                        <input class="form-control" readonly="" type="text">
                        <span class="input-group-btn">
                          <button class="btn btn-success" type="button">Browse</button>
                        </span>
                      </div>
                    </ba-file-uploader> -->
                  <file-add
                    #addFiles
                    [type]="'file'"
                    dropAreaId="file-drop-area"
                    (FileSelected)="FileSelected($event)"
                    (uploadCompleted)="uploadDone($event)"
                    [jobId]="jobId"
                    [filetype]="jobphoto.JobFileTypeId"
                    [jobPhaseId]="jobphoto.JobPhaseId"
                    [phasephotoFlag]="fileStage"
                    [adjustHeight]="false"
                    [productId]="productId"
                  ></file-add>
                </ng-template>
              </div>
            </div>
          </div>
          <div class="fixbtnwrap photos-add_buttons mb-2 mr-2 w-100">
            <div class="btnwrap__center">
              <button
                type="submit"
                class="btn darkbtn"
                [disabled]="!FileForm.valid || !isImageValid"
                *ngIf="FileForm.dirty || isImageValid"
              >
                Upload
              </button>
              <button type="button" class="cross btn bthpn2" (click)="cancel()">
                <!-- {{'common.cancel'|translate}} -->
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
