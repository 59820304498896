import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { AuthenticationService } from "../../../common/services/authentication.service";
import { GlobalState } from "../../../global.state";
import { SharedService } from "app/common/utility/SharedService";
import { ConfirmComponent } from "app/component/dialog/dialog.component";
import { DialogService } from "ng2-bootstrap-modal";
import { Router } from "@angular/router";
import { NotifyService } from "app/common/utility/notify.service";
import { ChatService } from "app/common/services/Chat.service";
import { OrganizationService } from "app/common/services/organization.service";
import { UserModel } from "app/model/Core/UserModel";
import { UserRoleEnum } from "app/model/Core/UserRoleEnum";
import { environment } from "environments/environment";
import { asapScheduler } from "rxjs";
import { environmentConstant } from "app/common/utility/environment";

@Component({
  selector: "ba-page-top",
  templateUrl: "./baPageTop.html",
  styleUrls: ["./baPageTop.scss"],
})
export class BaPageTop implements OnInit {
  isScrolled: boolean = false;
  chatNotifications: number = 0;
  isMenuCollapsed: boolean = false;
  selectedOrganization: any;
  logInUserId: number = this.sharedService.loggedInUser.Id;
  fullName: any =
    this.sharedService.loggedInUser.FirstName +
    " " +
    this.sharedService.loggedInUser.LastName;
  imperosnatedObj: any;
  showProfile: boolean = false;
  visibleSidebar: boolean = false;
  displayChat: boolean = false;
  displayWeatherRescheduler: boolean = false;
  showSlides: string = "";
  canChat: boolean = false;
  clickedOrg: any;
  initialOrg: any;
  childOrgs: any[] = [];
  originalChildOrgs: any[] = [];
  parentHasChildren: boolean = false;
  user: UserModel;
  isAdmin: boolean = false;
  defaultItem: any = { ID: 0, OrganizationName: "Select a child" };
  localStorageKeys: any = environmentConstant.localStorageKeys;

  @Output() SlideOut: EventEmitter<any> = new EventEmitter<any>();
  @Output() onHide: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private _state: GlobalState,
    private router: Router,
    private chatService: ChatService,
    private dialogService: DialogService,
    private authService: AuthenticationService,
    private organizationService: OrganizationService,
    public sharedService: SharedService,
    private notifyService: NotifyService
  ) {
    this._state.subscribe("menu.isCollapsed", (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
      this.selectedOrganization = this.sharedService.selectedOrganization;
    });
  }

  ngOnInit() {
    this.notifyService.notifyObservable$.subscribe((res) => {
      if (res.hasOwnProperty("option")) {
        switch (res.option) {
          case "toggleSideNav":
            this.toggleMenu();
            break;
          case "hideSideNav":
            this.toggleMenuCollapsed(true);
            break;
          case "showSideNav":
            this.toggleMenuCollapsed(false);
            break;
        }
      }
    });
    this.canChat = this.chatService.hasPermission && environment.chat;
    this.user = this.sharedService.user;
    let hasValidRoles = this.user.hasAnyRole(
      UserRoleEnum.Admin,
      UserRoleEnum.SuperAdmin,
      UserRoleEnum.MultiOrg
    );
    if (hasValidRoles)
      this.GetParentChildOrgs(this.sharedService.selectedOrganization.ID);
    this.StartImpersonation();

    this.chatService.unreadCount.subscribe((count: number) => {
      this.chatNotifications = count;
    });

    let WIPFilter: any = {};
    let SalesFilter: any = {};
    try {
      WIPFilter = JSON.parse(localStorage.getItem("WIP_Filter"));
    } catch (e) {}
    try {
      SalesFilter = JSON.parse(localStorage.getItem("Sales_Filter"));
    } catch (e) {}

    this.initAppcues();
    this.isMenuCollapsed =
      localStorage.getItem(this.localStorageKeys.MENU) == "true" ||
      (WIPFilter ? WIPFilter.OffAll : false) ||
      (SalesFilter ? SalesFilter.OffAll : false)
        ? true
        : false;
    this._state.notifyDataChanged("menu.isCollapsed", this.isMenuCollapsed);
  }
  private StartImpersonation() {
    this.imperosnatedObj = JSON.parse(localStorage.getItem("impersonateObj"));
    if (
      JSON.parse(localStorage.getItem("impersonateObj")) &&
      this.sharedService.selectedOrganization.ID != this.imperosnatedObj.orgId
    ) {
      this.sharedService.selectedOrganization.ID = this.imperosnatedObj.orgId;
      this.sharedService.isImpersonated = this.imperosnatedObj.isImpersonated;
      this.sharedService.selectedOrganization.OrganizationName =
        this.imperosnatedObj.OrganizationOrganizationName;
      this.sharedService.selectedOrganization.Email =
        this.imperosnatedObj.Email;
      this.sharedService.selectedOrganization.LogoUrl =
        this.imperosnatedObj.LogoUrl;
      this.sharedService.selectedOrganization.WorkingDays =
        this.imperosnatedObj.WorkingDays;
      this.sharedService.selectedOrganization.State =
        this.imperosnatedObj.State;
      this.sharedService.selectedOrganization.IsVendor =
        this.imperosnatedObj.IsVendor;
      this.sharedService.selectedOrganization.UseGoogleCalendar =
        this.imperosnatedObj.UseGoogleCalendar;
      this.sharedService.selectedOrganization.StatTime =
        this.imperosnatedObj.StatTime;
      this.sharedService.selectedOrganization.EndTime =
        this.imperosnatedObj.EndTime;
    }
  }

  toggleMenu() {
    this.isMenuCollapsed = !this.isMenuCollapsed;
    localStorage.setItem(
      this.localStorageKeys.MENU,
      !!this.isMenuCollapsed ? "true" : "false"
    );
    this._state.notifyDataChanged("menu.isCollapsed", this.isMenuCollapsed);
    return false;
  }
  toggleMenuCollapsed(isCollapsed: boolean) {
    this.isMenuCollapsed = isCollapsed;
    this._state.notifyDataChanged("menu.isCollapsed", this.isMenuCollapsed);
    return false;
  }
  signOut() {
    this.authService.logout();
  }

  scrolledChanged(isScrolled) {
    asapScheduler.schedule(() => {
      this.isScrolled = isScrolled;
    });
  }
  revertImpersonate() {
    const disposable = this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Switch Organization",
        // tslint:disable-next-line:prefer-template
        message:
          'Are you sure you want to Finish Session with "' +
          this.sharedService.selectedOrganization.OrganizationName +
          '"?',
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.sharedService.selectedOrganization = Object.assign(
            {},
            this.sharedService.loggedInUser.Org
          );
          this.sharedService.isImpersonated = false;
          let state = +this.sharedService.loggedInUser.Org.State;
          this.sharedService.selectedOrganization.State = state;
          localStorage.removeItem("impersonateObj");
          if (this.router.url.includes("pages/scheduler")) {
          } else {
            this.router.navigate(["pages", "scheduler"]);
            // this.router.navigate(["pages", "dashboard", "wipdashboard", "2"]);
          }
          window.location.reload();
        }
      });
  }
  onHideSliderHide(e) {
    this.visibleSidebar = false;
    this.showSlides = "";
    if (e == "weather") this.displayWeatherRescheduler = false;
    this.onHide.emit(e);
  }

  initAppcues() {
    window["Appcues"].page();

    let widget = window["AppcuesWidget"](window["Appcues"].user());

    widget.init("#appcues-widget", {
      header: "Tutorials",
      position: "bottom-right",
    });
  }

  GetParentChildOrgs(id: any) {
    this.organizationService.getParentChildOrgs(id).subscribe((data) => {
      if (data) {
        if (data.length > 1) this.parentHasChildren = true;
        this.initialOrg = this.sharedService.selectedOrganization.ID;
        this.originalChildOrgs = data;
        var parent = data.find(
          (x) => x.ID == this.sharedService.selectedOrganization.ID
        );
        if (parent) {
          this.clickedOrg = parent;
          this.childOrgs = data.filter((x) => x.ID != parent.ID);
        }
      }
    });
  }

  ChildOrgImpersonate(e) {
    if (this.sharedService.isImpersonated) {
      this.revertImpersonate();
    } else {
      this.impersonateOrganization(e);
    }
  }

  impersonateOrganization(org) {
    const disposable = this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Switch Organization",
        message:
          'Are you sure you want to switch the Organization to "' +
          org.OrganizationName +
          '"?',
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          var impersonateObj = {
            IsVendor: org.IsVendor,
            WorkingDays: org.WorkingDays,
            orgId: org.ID,
            isImpersonated: true,
            OrganizationOrganizationName: org.OrganizationName,
            Email: org.Email,
            LogoUrl: org.LogoUrl,
            State: +org.State,
            UseGoogleCalendar: org.UseGoogleCalendar,
            StatTime: org.StatTime,
            EndTime: org.EndTime,
          };
          this.sharedService.selectedOrganization.ID = org.ID;
          this.sharedService.isImpersonated = true;
          this.sharedService.selectedOrganization.OrganizationName =
            org.OrganizationName;
          this.sharedService.selectedOrganization.State = +org.State;
          this.sharedService.selectedOrganization.Email = org.Email;
          this.sharedService.selectedOrganization.LogoUrl = org.LogoUrl;
          this.sharedService.selectedOrganization.WorkingDays = org.WorkingDays;
          this.sharedService.selectedOrganization.IsVendor = org.IsVendor;
          this.sharedService.selectedOrganization.UseGoogleCalendar =
            org.UseGoogleCalendar;
          this.sharedService.selectedOrganization.StatTime = org.StatTime;
          this.sharedService.selectedOrganization.EndTime = org.EndTime;
          localStorage.setItem(
            "impersonateObj",
            JSON.stringify(impersonateObj)
          );

          window.location.reload();
        } else {
          this.GetParentChildOrgs(this.sharedService.selectedOrganization.ID);
        }
      });
  }

  loadCalendar() {
    this.router.navigate(["/", "pages", "sales", "salesCalendar"]);
  }
}
