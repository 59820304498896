<div class="widgets commonFixedSidebar">
  <div class="row">
    <div class="col-md-12 ht100">
      <div class="rightcontent">
        <div class="sideBarHeader" *ngIf="!phaseName || phaseName == ''">
          <h1 [ngClass]="IsWarranty ? 'hasWarranty' : ''">
            <span>
              {{ headerText }}
              <a
                style="margin-left: 5px"
                title="Print"
                (click)="PrintWorkOrder()"
              >
                <i class="icon icon-printer1"> </i> </a
            ></span>
          </h1>
        </div>
        <div class="sideBarHeader" *ngIf="phaseName && phaseName != ''">
          <h1 [ngClass]="IsWarranty ? 'hasWarranty' : ''">
            <span>{{ "EDIT " }}</span> <span>{{ phaseName }}</span
            ><span *ngIf="!isSales">{{ " " + getID() }} </span>
          </h1>
        </div>
        <a
          *ngIf="JobPhaseId > 0 && fromBigBoard"
          style="
            margin-left: 5px;
            position: absolute;
            top: 58px;
            right: 30px;
            z-index: 999;
          "
          title="Delete Phase"
          (click)="deleteJobPhase(JobPhaseId)"
        >
          <i class="fa fa-trash-o" style="color: red"> </i>
        </a>
        <div class="tabBlk">
          <form
            #myForm="ngForm"
            class="form-horizontal form-workOrder"
            (ngSubmit)="save(myForm, true)"
          >
            <kendo-tabstrip
              (tabSelect)="onTabSelected($event)"
              [keepTabContent]="true"
            >
              <kendo-tabstrip-tab
                [title]="'Details'"
                [selected]="selectedTab == 0"
              >
                <ng-template kendoTabContent>
                  <work-order-details
                    *ngIf="this.CallingComponent.toLowerCase() == 'work order'"
                    [WorkOrderID]="JobID"
                    (updateWorkorderID)="updateJobID($event)"
                    (updateWorkorderNumber)="updateJobNumber($event)"
                    (closeSlide)="onCancel($event)"
                    (onWarrantyChange)="onWarrantyChange($event)"
                    (jobSeleted)="onSaveEnable($event)"
                    [JobData]="JobData"
                  ></work-order-details>
                  <punchlist-details
                    [PunchlistID]="JobPhaseId"
                    *ngIf="this.CallingComponent.toLowerCase() == 'punchlist'"
                    (updateJobID)="updateJobID($event)"
                    (updatePunchlistID)="updatePhaseId($event)"
                    (closeSlide)="onCancel($event)"
                    (onWarrantyChange)="onWarrantyChange($event)"
                    (jobSeleted)="onSaveEnable($event)"
                    [JobData]="JobData"
                  ></punchlist-details>
                  <jobphase-details
                    [PhaseId]="JobPhaseId"
                    (updateJobID)="updateJobID($event)"
                    [JobId]="JobID"
                    [sideBarClass]="sideBarClass"
                    *ngIf="this.CallingComponent.toLowerCase() == 'job phase'"
                    (updatePhaseID)="updatePhaseId($event)"
                    (onPhaseChange)="onPhaseChange($event)"
                    (jobSeleted)="onSaveEnable($event)"
                    [JobData]="JobData"
                    [isSales]="isSales"
                    (incrementSale)="onIncrementSale($event)"
                    (worksheetId)="setParentWorksheetId($event)"
                    (closeSlide)="onCancel($event)"
                    (updateBuildPlan)="updateBuildPlan($event)"
                    [isSlideOut]="IsSlideOut"
                  >
                  </jobphase-details>
                </ng-template>
              </kendo-tabstrip-tab>

              <kendo-tabstrip-tab
                [title]="'Files'"
                [selected]="selectedTab == 1"
                [disabled]="!IsSaveEnable"
                *ngIf="selectedJob && canShowTab"
              >
                <ng-template kendoTabTitle>
                  <div *ngIf="fileCount > 0" class="tab-indicator">
                    {{ fileCount }}
                  </div>
                </ng-template>
                <ng-template kendoTabContent *loadOnDemand>
                  <app-jobfile-info-card
                    [JobId]="JobID"
                    [useOwnAddSidebar]="false"
                    (addFile)="onAddNewData(0, 'addFile')"
                    [isPhase]="IsPhase"
                    [jobPhaseId]="JobPhaseId"
                    [isPunchlist]="isPunchlist"
                    [showFullHeader]="false"
                    [gridStyle]="{ height: 'calc(100% - 7.813rem)' }"
                    (filesCount)="setFileNoteCount($event)"
                  >
                  </app-jobfile-info-card>
                </ng-template>
                <!-- <ng-template kendoTabContent>
                  <div class="row">
                    <div class="col">
                      <div class="addbtn mb5 checkboxContainerAlign">
                        <label
                          class="checkbox-inline custom-checkbox nowrap fl-l mr3"
                          *ngIf="fileCount != 0"
                        >
                          <input
                            type="checkbox"
                            (change)="selectAllFiles()"
                            name="selectAll"
                            [(ngModel)]="selectAll"
                          />
                          <span></span>
                        </label>
                        <a
                          [ngClass]="
                            checkIfDataTodelete() ? '' : 'disableanchor'
                          "
                          *ngIf="fileCount != 0"
                        >
                          <i
                            title="Delete"
                            (click)="deleteJobFiles()"
                            class="fa fa-trash-o pha de_photo"
                            aria-hidden="true"
                          ></i>
                        </a>
                        <a
                          (click)="onAddNewData(0, 'addFile')"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Add File.."
                        >
                          <i class="fa fa-plus-circle" aria-hidden="true"></i>
                          Add
                        </a>
                      </div>
                      <div
                        class="title_right hright datasearch files-delete"
                        *ngIf="fileCount != 0"
                      >
                        <div class="form-group pull-right top_search">
                          <div class="input-group">
                            <button
                              type="button"
                              (click)="deleteAll()"
                              class="btn darkbtn"
                            >
                              Delete All
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="x_panel">
                    <div class="x_content">
                      <div class="kendoheight kendoheight2 files-grid">
                        <kendo-grid
                          [data]="gridView"
                          [pageSize]="pageSize"
                          #grid="kendoGrid"
                          [skip]="skip"
                          [pageable]="true"
                          (pageChange)="pageChange($event)"
                          [sortable]="{
                            allowUnsort: allowUnsort,
                            mode: multiple ? 'multiple' : 'single'
                          }"
                          [sort]="sort"
                          (sortChange)="sortChange($event)"
                        >
                          <!-- <kendo-grid-column headerClass="gridHeading" title="{{'common.action'|translate}}" width="80">
                            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                              <div class="dropdown cud">
                                <i class="fa fa-ellipsis-v" id="menu1" data-toggle="dropdown">
                                </i>
                                <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                                  <li>
                                    <a (click)="deleteFile(dataItem.ID)" data-toggle="modal"
                                      data-target=".bs-example-modal-sm">
                                      <i class="fa fa-trash-o"></i> Delete
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column
                            headerClass="gridHeading"
                            class="gridRow"
                            width="60"
                            field="ID"
                            title=""
                          >
                            <ng-template kendoGridCellTemplate let-dataItem>
                              <label
                                class="checkbox-inline custom-checkbox nowrap ml10"
                              >
                                <input
                                  type="checkbox"
                                  name="selectedData{{ dataItem.ID }}"
                                  [checked]="dataItem.selected"
                                  (change)="selectFiles($event, dataItem)"
                                />
                                <span></span>
                              </label>
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column
                            headerClass="gridHeading"
                            class="gridRow"
                            field="FileName"
                            title="File Name"
                          >
                          </kendo-grid-column>
                          <kendo-grid-column
                            headerClass="gridHeading"
                            class="gridRow"
                            field="FileTypeName"
                            title="File Type"
                          >
                          </kendo-grid-column>
                          <kendo-grid-column
                            headerClass="gridHeading"
                            class="gridRow"
                            field="CreatedOn"
                            title="Created On"
                          >
                            <ng-template kendoGridCellTemplate let-dataItem>
                              <span *ngIf="dataItem.CreatedOn != null">
                                {{
                                  dataItem.CreatedOn.replace("Z", "") + "Z"
                                    | date: "medium"
                                }}
                              </span>
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column
                            headerClass="gridHeading"
                            class="gridRow"
                            field="BlobUrl"
                            title="Download"
                          >
                            <ng-template kendoGridCellTemplate let-dataItem>
                              <a
                                href="{{ dataItem.BlobUrl }}"
                                target="_blank"
                                title="Download"
                              >
                                <i
                                  class="fa fa-cloud-download faml20"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </ng-template>
                          </kendo-grid-column>
                        </kendo-grid>
                      </div>
                    </div>
                  </div>
                </ng-template> -->
              </kendo-tabstrip-tab>

              <kendo-tabstrip-tab
                [title]="'Notes'"
                [selected]="selectedTab == 2"
                [disabled]="!IsSaveEnable"
                *ngIf="selectedJob && canShowTab"
              >
                <ng-template kendoTabTitle>
                  <div *ngIf="noteCount > 0" class="tab-indicator">
                    {{ noteCount }}
                  </div>
                </ng-template>
                <ng-template kendoTabContent *loadOnDemand>
                  <app-job-notes-info-card
                    [JobId]="JobID"
                    [JobPhaseId]="JobPhaseId"
                    [IsHeaderEnable]="false"
                    [isSales]="isSales"
                    [isPhase]="IsPhase"
                    (notesRetrieved)="lstNotes = $event"
                    (updateNoteCount)="setNoteTabCount($event)"
                  >
                  </app-job-notes-info-card>
                </ng-template>
                <!-- <ng-template kendoTabContent>
                  <div class="row">
                    <div class="col">
                      <div class="addbtn mb10">
                        <a (click)="onAddNewData(0,'addNote')" data-toggle="tooltip" data-placement="top"
                          title="Add Note">
                          <i class="fa fa-plus-circle" aria-hidden="true"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="x_panel">
                    <div class="x_content">
                      <div class="kendoheight kendoheight2">
                        <kendo-grid [data]="gridView" [pageSize]="pageSize" #grid="kendoGrid" [skip]="skip"
                          [pageable]="true" (pageChange)="pageChange($event)" [sortable]="{
                            allowUnsort: allowUnsort,
                            mode: multiple ? 'multiple' : 'single'
                            }" [sort]="sort" (sortChange)="sortChange($event)">
                          <kendo-grid-column headerClass="gridHeading" title="{{''|translate}}" width="30">
                            <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                              <div class="dropdown cud">
                                <i class="fa fa-ellipsis-v" id="menu1" data-toggle="dropdown">
                                </i>
                                <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                                  <li>
                                    <a (click)="onAddNewData(dataItem.ID,'addNote')" data-toggle="modal"
                                      data-target=".bs-example-modal-sm">
                                      <i class="fa fa-pencil"></i> Edit
                                    </a>
                                  </li>
                                  <li>
                                    <a (click)="deleteNotes(dataItem.ID)" data-toggle="modal"
                                      data-target=".bs-example-modal-sm">
                                      <i class="fa fa-trash-o"></i> Delete
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column headerClass="gridHeading" width="120" class="gridRow" field="NoteTitle"
                            title="Note Title">
                          </kendo-grid-column>
                          <kendo-grid-column headerClass="gridHeading" class="gridRow" field="Note" title="Note">
                          </kendo-grid-column>
                          <kendo-grid-column headerClass="gridHeading" width="150" class="gridRow" field="CreatedOn"
                            title="Created On">
                            <ng-template kendoGridCellTemplate let-dataItem>
                              {{dataItem.CreatedOn | date: 'medium'}}
                            </ng-template>
                          </kendo-grid-column>
                        </kendo-grid>
                      </div>
                    </div>
                  </div>
                </ng-template> -->
              </kendo-tabstrip-tab>
              <kendo-tabstrip-tab
                [title]="'Photos'"
                [selected]="selectedTab == 3"
                [disabled]="!IsSaveEnable"
                *ngIf="selectedJob && canShowTab"
              >
                <ng-template kendoTabTitle>
                  <div *ngIf="photoCount > 0" class="tab-indicator">
                    {{ photoCount }}
                  </div>
                </ng-template>
                <ng-template kendoTabContent *loadOnDemand>
                  <job-photo-info-card
                    [sideBarClass]="sideBarClass"
                    [jobId]="JobID"
                    [JobPhaseId]="JobPhaseId"
                    [isSales]="isSales"
                    [isPhase]="IsPhase"
                    [isFirstSlide]="false"
                    (photosRetrieved)="lstPhotos = $event.items"
                    (updatePhotoCount)="setPhotoTabCount($event)"
                    [isWorkOrder]="isWorkOrder"
                  >
                  </job-photo-info-card>
                </ng-template>
              </kendo-tabstrip-tab>
              <kendo-tabstrip-tab
                [title]="'Checklists'"
                [selected]="selectedTab == 4"
                [disabled]="!IsSaveEnable"
                *ngIf="enableChecklists && selectedJob && canShowTab"
              >
                <ng-template kendoTabTitle>
                  <div *ngIf="checklistCount > 0" class="tab-indicator">
                    {{ checklistCount }}
                  </div>
                </ng-template>
                <ng-template kendoTabContent *loadOnDemand>
                  <div class="addpagewrap wnotes">
                    <div class="addbtn" id="addbutton">
                      <a
                        (click)="onAddNewData(0, 'addChecklist')"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Add Checklist"
                      >
                        <i
                          style="margin-right: 5px"
                          class="fa fa-plus-circle"
                          aria-hidden="true"
                        ></i
                        >Add
                      </a>
                    </div>
                    <div class="x_panel">
                      <div class="x_content">
                        <div
                          class="kendoheight kendoheight2 files-grid"
                          style="height: calc(100vh - 140px)"
                        >
                          <kendo-grid
                            [data]="gridView"
                            #grid="kendoGrid"
                            [pageSize]="pageSize"
                            [skip]="skip"
                            [pageable]="false"
                            [scrollable]="virtual"
                            (pageChange)="pageChange($event)"
                            [sortable]="{
                              allowUnsort: allowUnsort,
                              mode: multiple ? 'multiple' : 'single'
                            }"
                            [sort]="sort"
                            (sortChange)="sortChange($event)"
                          >
                            <kendo-grid-column
                              headerClass="gridHeading"
                              class="gridRow"
                              title="{{ '' | translate }}"
                              width="30"
                            >
                              <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="dropdown cud">
                                  <i
                                    class="fa fa-ellipsis-v"
                                    id="menu1"
                                    data-toggle="dropdown"
                                  >
                                  </i>
                                  <ul
                                    class="dropdown-menu"
                                    role="menu"
                                    aria-labelledby="menu1"
                                    style="bottom: auto !important"
                                  >
                                    <li
                                      (click)="
                                        editChecklist(dataItem, 'editChecklist')
                                      "
                                    >
                                      <a>
                                        <i class="fa fa-pencil"></i>
                                        {{ "common.edit" | translate }}
                                      </a>
                                    </li>
                                    <li (click)="PrintCheckList(dataItem)">
                                      <a>
                                        <i class="icon icon-printer1"> </i>
                                        Print
                                      </a>
                                    </li>
                                    <li
                                      (click)="deleteChecklist(dataItem)"
                                      data-toggle="modal"
                                      data-target=".bs-example-modal-sm"
                                    >
                                      <a>
                                        <i class="fa fa-trash-o"></i>
                                        {{ "common.delete" | translate }}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column
                              headerClass="gridHeading"
                              class="gridRow"
                              field="name"
                              title="Checklist"
                            >
                            </kendo-grid-column>
                            <kendo-grid-column
                              headerClass="gridHeading"
                              class="gridRow"
                              title="Date"
                            >
                              <ng-template kendoGridCellTemplate let-dataItem>
                                <div>
                                  <label style="margin: 0">
                                    {{
                                      dataItem.created | date : "short"
                                    }}</label
                                  >
                                </div>
                              </ng-template>
                            </kendo-grid-column>

                            <kendo-grid-column
                              headerClass="gridHeading"
                              class="gridRow"
                              field="assignee.fullName"
                              title="Assigned To"
                            >
                            </kendo-grid-column>
                            <kendo-grid-column
                              headerClass="gridHeading"
                              class="gridRow"
                              title="Status"
                              width="60"
                            >
                              <ng-template kendoGridCellTemplate let-dataItem>
                                <div style="text-align: center">
                                  <i
                                    *ngIf="dataItem.status == 0"
                                    class="fa fa-tasks iconOpen"
                                  ></i>
                                  <i
                                    *ngIf="dataItem.status == 1"
                                    class="fa fa-check-circle iconComplete"
                                  ></i>
                                </div>
                              </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-excel
                              fileName="JobChecklists.xlsx"
                              [fetchData]="allData"
                            ></kendo-grid-excel>
                          </kendo-grid>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </kendo-tabstrip-tab>
              <kendo-tabstrip-tab
                [title]="'BOM'"
                [selected]="selectedTab == 5"
                [disabled]="!IsSaveEnable"
                *ngIf="hasBillOfMaterials && canShowTab"
              >
                <ng-template kendoTabContent *loadOnDemand>
                  <bill-of-materials-grid
                    [worksheetId]="parentWorksheetId"
                    [phase]="JobPhase"
                  >
                  </bill-of-materials-grid>
                </ng-template>
              </kendo-tabstrip-tab>
            </kendo-tabstrip>
            <div class="fixbtnwrap">
              <div class="btnwrap__center">
                <button
                  type="button"
                  class="btn darkbtn"
                  *ngIf="
                    this.CallingComponent.toLowerCase() == 'work order' &&
                    JobID &&
                    !isNew
                  "
                  (click)="sendWorkOrderEmail()"
                >
                  Send Work Order Email
                </button>
                <button
                  type="submit"
                  class="btn darkbtn"
                  *ngIf="(myForm.dirty || isFormDirty) && !IsPhase"
                  [disabled]="!IsSaveEnable || !myForm.valid || !selectedJob"
                >
                  <i class="fa fa-floppy-o" aria-hidden="true"></i>
                  {{ buttonText }}
                </button>
                <button
                  type="submit"
                  class="btn darkbtn"
                  *ngIf="
                    (myForm.dirty || isFormDirty) && IsPhase && JobPhaseId == 0
                  "
                  [disabled]="!IsSaveEnable || !myForm.valid || !selectedJob"
                >
                  <i class="fa fa-floppy-o" aria-hidden="true"></i>
                  {{ buttonTextSaveAndExit }}
                </button>
                <button
                  type="button"
                  class="btn darkbtn left-10"
                  *ngIf="(myForm.dirty || isFormDirty) && IsPhase"
                  [disabled]="!IsSaveEnable || !myForm.valid || !selectedJob"
                  (click)="save(myForm, false)"
                >
                  <i class="fa fa-floppy-o" aria-hidden="true"></i>
                  {{ buttonText }}
                </button>
                <button
                  type="button"
                  class="cross btn bthpn2"
                  (click)="onCancel(false)"
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="{{ sideBarClass }}" [ngClass]="showSlides != '' ? 'open' : ''">
  <a class="cancelbtnHead ui-sidebar-close" (click)="showSlides = ''">
    <span class="pi pi-times"></span>
  </a>
  <photosAdd
    *ngIf="showSlides == 'addFile'"
    [workOrderId]="JobID"
    [phaseId]="JobPhaseId"
    [isPhase]="IsPhase"
    (newFiles)="onNewFiles($event)"
    (closeSlideout)="showSlides = ''"
    [type]="'File'"
  ></photosAdd>
  <checklistAdd
    *ngIf="showSlides == 'addChecklist'"
    [phasechecklist]="isThisForNewPhase ? false : IsPhase"
    [guid]="isThisForNewPhase ? JobID : JobPhase.Guid"
    (closeSlide)="closeChecklist()"
    (refreshPage)="showTab(4)"
    (newChecklist)="onNewChecklist($event)"
  ></checklistAdd>
  <checklistitems
    *ngIf="showSlides == 'editChecklist'"
    [checklist]="checklist"
    [jobPhaseGuid]="JobPhase.Guid"
    (close)="showTab(4)"
    (refreshPage)="showTab(4)"
  ></checklistitems>
  <app-general-reports
    *ngIf="showSlides == 'reports'"
    [reportData]="reportModel"
  ></app-general-reports>
</div>
