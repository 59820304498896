import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { ConfigService } from "app/common/services/configService";

@Injectable({ providedIn: "root" })
export class ConfigResolver implements Resolve<any> {
  constructor(private configService: ConfigService, private router: Router) {}

  resolve() {
    return this.configService.load();
  }
}
