<div class="modal-dialog modal-sm">
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close" (click)="close()">&times;</button>
      <h4 class="modal-title">{{ title || "Confirm" }}</h4>
    </div>
    <div class="modal-body">
      <p>{{ message }}</p>
      <div *ngIf="showJob">
        <input
          type="checkbox"
          id="job"
          ([ngModel])="(handleJob)"
          (click)="setJob($event.target.checked)"
          style="margin-right: 10px"
        />
        <label for="job">All open Jobs</label>
      </div>
      <div *ngIf="showPhase">
        <input
          type="checkbox"
          id="phase"
          ([ngModel])="(handleJobPhase)"
          (click)="setPhase($event.target.checked)"
          style="margin-right: 10px"
        />
        <label for="phase">All open Job Phases</label>
      </div>
      <div *ngIf="showSettingsPhase">
        <input
          type="checkbox"
          id="settingsPhase"
          ([ngModel])="(handleSettingsPhase)"
          (click)="setSettingsPhase($event.target.checked)"
          style="margin-right: 10px"
        />
        <label for="settingsPhase">All Phases</label>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn darkbtn dpbtn"
        (click)="editSave()"
        *ngIf="toShow"
      >
        Save & Edit
      </button>
      <button type="button" class="btn darkbtn" (click)="confirm()">OK</button>
      <button
        type="button"
        class="btn btn-default"
        *ngIf="!isNotification"
        (click)="close()"
      >
        Cancel
      </button>
    </div>
  </div>
</div>
