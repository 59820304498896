<ng-container [formGroup]="form">
  <!-- Review Mode -->
  <span *ngIf="mode === ProductSelectorMode.Review" class="break-words">{{
    product.qty
  }}</span>

  <!-- Add Mode -->
  <span *ngIf="mode === ProductSelectorMode.Add">
    <div class="child-complex-text align-right">
      <span *ngIf="showIcon">
        <i class="fa svg-icon__function"></i>
      </span>
      <span *ngIf="showLabel" class="break-words">
        {{ product.qty }}
      </span>
    </div>
    <input
      *ngIf="showInput"
      class="child-complex-input input-number"
      type="number"
      formControlName="productQty"
      decimalFormat
      [decimals]="product.product.unitOfMeasure.valueType"
      [negative]="
        parentProduct.product.productTypeId === ProductType.SimpleKit
          ? true
          : false
      "
      [maxSize]="6"
      [allowZero]="
        parentProduct.product.productTypeId === ProductType.ComplexKit
          ? true
          : false
      "
    />
  </span>

  <!-- Edit Mode -->
  <span *ngIf="mode === ProductSelectorMode.Edit">
    <div class="child-complex-text align-right">
      <span *ngIf="showLabel" class="break-words">
        {{ product.qty }}
      </span>
    </div>

    <input
      *ngIf="showInput"
      class="child-complex-input-edit input-number"
      type="number"
      formControlName="productQty"
      decimalFormat
      [decimals]="product.product.unitOfMeasure.valueType"
      [negative]="
        parentProduct.product.productTypeId === ProductType.SimpleKit
          ? true
          : false
      "
      [maxSize]="6"
      [allowZero]="
        parentProduct.product.productTypeId === ProductType.ComplexKit
          ? true
          : false
      "
    />
  </span>
</ng-container>
