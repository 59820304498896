<div class="editjobtabform new_work_order hoverflow wodetail-pg">
  <div class="mt5">
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
        <label class="checkbox-inline custom-checkbox nowrap">
          <input
            type="checkbox"
            name="Public"
            [(ngModel)]="workOrderV2.IsPublic"
          />
          <span>Public</span>
        </label>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <label class="checkbox-inline custom-checkbox nowrap">
          <input
            type="checkbox"
            name="Public"
            [(ngModel)]="workOrderV2.IsWarranty"
            (change)="onChangeWaranty()"
          />
          <span>Is this a warranty item?</span>
        </label>
      </div>
      <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="ccol1" id="billTo">
              <div class="Customer">
                <span class="Customertxt">
                  <span>Job</span>
                </span>
                <address class="address">
                  <span *ngIf="workOrderV2 && workOrderV2.Address">
                    <span>{{ workOrderV2.JobName }}</span>
                    <span>{{ workOrderV2.Address.Address1 }}</span>
                    <span
                      >{{ workOrderV2.Address.City }}
                      <span
                        *ngIf="
                          workOrderV2.Address.City && workOrderV2.Address.State
                        "
                        >,</span
                      >
                      {{ workOrderV2.Address.State
                      }}{{
                        workOrderV2.Address.State &&
                        workOrderV2.Address.StateProvinceAbbreviation
                          ? ","
                          : ""
                      }}{{ workOrderV2.Address.StateProvinceAbbreviation
                      }}{{
                        (workOrderV2.Address.State &&
                          workOrderV2.Address.ZipPostalCode) ||
                        (workOrderV2.Address.StateProvinceAbbreviation &&
                          workOrderV2.Address.ZipPostalCode)
                          ? ","
                          : ""
                      }}{{ workOrderV2.Address.ZipPostalCode }}
                    </span>
                    <span>{{ workOrderV2.Phone }}</span>
                  </span>
                </address>
                <button
                  type="button"
                  class="order cursor-pointer"
                  *ngIf="isFindJob"
                  (click)="showSlides = 'findJob'"
                >
                  <i class="fa fa-search"></i> Find Job
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-5 col-md-5 col-sm-6 col-xs-12 pt10">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb15">
            <div class="form-group mb5 Mandat">
              <span class="ui-float-label">
                <input
                  type="number"
                  [(ngModel)]="workOrderV2.WorkOrderNumber"
                  id="WorkOrderName"
                  name="WorkOrderName"
                  #WorkOrderName="ngModel"
                  maxlength="50"
                  class="form-control"
                  pInputText
                  required
                  [ngClass]="{
                    'has-error':
                      WorkOrderName.invalid &&
                      (WorkOrderName.dirty || WorkOrderName.touched)
                  }"
                />
                <label
                  for="text"
                  class="form-control-label text-overflow"
                  required
                  >Work Order Number
                </label>
              </span>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb5">
            <div class="form-group mb5 Mandat">
              <kendo-floatinglabel text="Status">
                <kendo-combobox
                  [data]="statusLst"
                  [valuePrimitive]="true"
                  [filterable]="true"
                  (valueChange)="onStatusChange()"
                  (filterChange)="filterStatus($event)"
                  [textField]="'Value'"
                  [(ngModel)]="workOrderV2.StatusId"
                  name="Status"
                  [valueField]="'key'"
                ></kendo-combobox>
              </kendo-floatinglabel>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 mt15">
        <div class="form-group">
          <span class="ui-float-label">
            <input
              [appFocus]="true"
              type="text"
              [(ngModel)]="workOrderV2.Subject"
              id="Subject"
              name="Subject"
              #Subject="ngModel"
              maxlength="150"
              class="form-control"
              pInputText
              required
              [ngClass]="{
                'has-error':
                  Subject.invalid && (Subject.dirty || Subject.touched)
              }"
            />
            <label for="text" class="form-control-label" required
              >Subject
              <span class="text-red">*</span>
            </label>
          </span>
        </div>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-12">
        <div class="form-group mb0">
          <kendo-floatinglabel text="Category">
            <kendo-combobox
              [data]="lstCategory"
              [valuePrimitive]="true"
              [filterable]="true"
              (filterChange)="filterCategory($event)"
              [textField]="'CatetoryName'"
              [(ngModel)]="workOrderV2.CategoryId"
              name="CategoryId"
              [valueField]="'Id'"
            ></kendo-combobox>
          </kendo-floatinglabel>
        </div>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-12">
        <div class="form-group">
          <kendo-floatinglabel text="Business Unit">
            <kendo-combobox
              [data]="lstBusinessUnit"
              [valuePrimitive]="true"
              [filterable]="true"
              (filterChange)="filterBussinessUnit($event)"
              [textField]="'BusinessUnit1'"
              [(ngModel)]="workOrderV2.BusinessUnitId"
              name="businessUnitId"
              [valueField]="'ID'"
            >
            </kendo-combobox>
          </kendo-floatinglabel>
        </div>
      </div>
      <div class="col-md-4 col-sm-4 col-xs-12">
        <div class="form-group mb0">
          <label for="" class="fu">Reminder</label>
          <span class="ui-float-label">
            <kendo-datepicker
              class="full-date"
              name="RemindMeOnDay"
              #RemindMeOnDay
              [(ngModel)]="workOrderV2.RemindMeOnDay"
              placeholder=""
            ></kendo-datepicker>
          </span>
        </div>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="form-group mb10">
          <span class="ui-float-label">
            <textarea
              class="form-control"
              name="Comments"
              maxlength="500"
              id="description"
              name="description"
              #description="ngModel"
              [(ngModel)]="workOrderV2.Description"
              rows="3"
              pInputText
            ></textarea>
            <label for="text" class="form-control-label">Description</label>
          </span>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt5">
        <div class="adwordr-date-wrap">
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div class="form-group">
                <label class="fu">Target Date</label>
                <span class="ui-float-label">
                  <kendo-datepicker
                    class="full-date"
                    name="TargetDate"
                    [(ngModel)]="workOrderV2.TargetDate"
                    #TargetDate="ngModel"
                  ></kendo-datepicker>
                </span>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div class="form-group mb0">
                <label for="" class="fu">Scheduled Date</label>
                <span class="ui-float-label">
                  <kendo-datepicker
                    class="full-date"
                    name="ScheduledDate"
                    #ScheduledDate
                    [(ngModel)]="workOrderV2.ScheduledDate"
                    placeholder=""
                  ></kendo-datepicker>
                </span>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div class="form-group mb0">
                <label for="" class="fu">Completed On</label>
                <span class="ui-float-label">
                  <kendo-datepicker
                    class="full-date"
                    name="CompleteDate"
                    #CompleteDate
                    (valueChange)="
                      changeCompleteDateStatus(workOrderV2.CompleteDate)
                    "
                    [(ngModel)]="workOrderV2.CompleteDate"
                    placeholder=""
                  ></kendo-datepicker>
                </span>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div class="form-group">
                <span class="ui-float-label p2-float-label">
                  <input
                    type="number"
                    class="form-control bg-white"
                    min="1"
                    pInputText
                    name="DaysToComplete"
                    [(ngModel)]="workOrderV2.DaysToComplete"
                    #DaysToComplete="ngModel"
                  />
                  <label for="number" class="form-control-label"
                    >Days To Complete</label
                  >
                </span>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="workOrderV2.StartDate">
            <div class="col-sm-6">
              <p class="startedon_para mt0">
                This work order was marked started on :
                <span
                  >{{ workOrderV2.StartDate | date : "shortDate" }}
                  <span class="calin-icons">
                    <i
                      *ngIf="!EnableStartDateEdit"
                      class="fa fa-pencil"
                      title="Edit"
                      (click)="editStartDate(true)"
                    ></i>
                    <i
                      class="fa fa-ban"
                      *ngIf="EnableStartDateEdit"
                      title="Cancel"
                      (click)="editStartDate(false)"
                    ></i></span
                ></span>
              </p>
            </div>
            <div
              *ngIf="EnableStartDateEdit"
              class="col-lg-6 col-md-6 col-sm-6 col-xs-12"
            >
              <div class="form-group mb5">
                <label class="fu">Started On</label>
                <span class="ui-float-label">
                  <kendo-datepicker
                    class="full-date"
                    name="StartDate"
                    [(ngModel)]="workOrderV2.StartDate"
                    #StartDate="ngModel"
                  ></kendo-datepicker>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="additem assignto-item clearfix">
              <div class="addbtn">
                <a
                  data-placement="top"
                  data-toggle="tooltip"
                  title="Assign To"
                  (click)="showSlides = 'showAssignTo'"
                >
                  <i aria-hidden="true" class="fa fa-plus-circle"></i> Assign To
                </a>
              </div>
            </div>
            <div class="assigned-items">
              <ul>
                <li *ngFor="let resource of workOrderV2.Resources">
                  <a
                    class="cancelbtnHead ui-sidebar-close"
                    (click)="deleteAssignedResource(resource.Id)"
                  >
                    <span class="pi pi-times"></span>
                  </a>
                  <span
                    class="short-name"
                    [ngClass]="resource.IsExternal ? 'bg-green' : 'bg-blue'"
                    >{{ getInitals(resource.Name) }}</span
                  ><span class="hover-content">{{ resource.Name }}</span>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div class="additem clearfix mb10">
              <div class="addbtn lineitem-btn">
                <a
                  (click)="showSlides = 'showAddLineItem'; LineItemId = 0"
                  data-placement="top"
                  data-toggle="tooltip"
                  title="Add Line Item"
                >
                  <i aria-hidden="true" class="fa fa-plus-circle"></i> Line Item
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="x_panel">
      <div class="x_content">
        <div class="kendoheight wotheight">
          <kendo-grid
            [data]="gridView"
            [pageSize]="pageSize"
            #grid="kendoGrid"
            [skip]="skip"
            [pageable]="true"
            (pageChange)="pageChange($event)"
            [sortable]="{
              allowUnsort: allowUnsort,
              mode: multiple ? 'multiple' : 'single'
            }"
            [sort]="sort"
            (sortChange)="sortChange($event)"
          >
            <kendo-grid-column
              headerClass="gridHeading"
              class="gridRow"
              title="#"
              width="60"
            >
              <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                <div class="dropdown cud">
                  <i class="fa fa-ellipsis-v" id="menu1" data-toggle="dropdown">
                  </i>
                  <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                    <li>
                      <a
                        (click)="
                          showSlides = 'showAddLineItem';
                          LineItemId = dataItem.ID
                        "
                      >
                        <i class="fa fa-pencil"></i>
                        {{ "common.edit" | translate }}
                      </a>
                    </li>
                    <li>
                      <a
                        (click)="deleteLineItem(dataItem.ID)"
                        data-toggle="modal"
                        data-target=".bs-example-modal-sm"
                      >
                        <i class="fa fa-trash-o"></i>
                        {{ "common.delete" | translate }}
                      </a>
                    </li>
                  </ul>
                </div>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column
              headerClass="gridHeading"
              class="gridRow"
              field="LineItemName"
              title="Line Item Name"
            >
            </kendo-grid-column>
            <kendo-grid-column
              headerClass="gridHeading"
              class="gridRow"
              field="ResourcesText"
              title="Assign To"
            >
            </kendo-grid-column>
            <kendo-grid-column
              headerClass="gridHeading"
              class="gridRow"
              field="Status"
              title="Status"
            >
            </kendo-grid-column>
          </kendo-grid>
        </div>
      </div>
    </div>
    <!-- <div class="mb35">
            <div class="">
                <button type="button" class="btn darkbtn" *ngIf="!this.isFindJob" (click)="sendWorkOrderEmail()"> Send Work Order Email</button>
            </div>
        </div> -->
  </div>
  <div [ngClass]="showSlides != '' ? 'sidebar2 open ' : 'sidebar2'">
    <a class="cancelbtnHead ui-sidebar-close" (click)="showSlides = ''">
      <span class="pi pi-times"></span>
    </a>

    <Add-LineItem-Component
      *ngIf="showSlides == 'showAddLineItem'"
      [isAddWorkOrder]="isaddWorkOrder"
      [WorkOrderID]="workOrderV2 ? workOrderV2.ID : null"
      [lineItemId]="LineItemId"
      (updateData)="updateData($event)"
    ></Add-LineItem-Component>
    <assign-job
      *ngIf="showSlides == 'findJob'"
      (sendData)="getWOData($event)"
    ></assign-job>
    <AssignResource-Component
      *ngIf="showSlides == 'showAssignTo'"
      [AssignedResourceList]="lstAssignedResource"
      [BusinessUnitID]="workOrderV2.BusinessUnitId"
      [workOrderId]="workOrderV2.ID"
      [LineItemId]="-1"
      [StartDate]="workOrderV2.ScheduledDate"
      (sendData)="updateAssignedResource($event)"
    >
    </AssignResource-Component>
  </div>
</div>
