import { EditSalesCardModel } from "app/model/editSalesCardModel";
import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";
import { environmentConstant } from "app/common/utility/environment";
import { Observable } from "rxjs";
import { saleJobSimpleModel } from "app/model/saleJobSimpleModel";
import { SalesDashboardModel } from "app/model/SalesDashboardModel";
import { AppointmentDTO } from "app/model/AppointmentModel";
import { SalesGoogleCalander } from "app/model/SalesGoogleCalander";
import { saleJobBasicModel } from "app/model/saleJobBasicModel";
import { salesListModel } from "app/model/salesListModel";
@Injectable()
// tslint:disable:prefer-template
export class SalesService {
  constructor(private httpService: HttpService) {}

  getAllSalesJobByOrganPhaseID(
    orgId: any,
    phaseId: any
  ): Observable<saleJobSimpleModel[]> {
    return this.httpService.getRequestService("sales/" + orgId + "/" + phaseId);
  }
  getAllSalesJob(jobID: any): any {
    return this.httpService.getRequestService("sales/" + jobID + "/salesjob");
  }
  saveSalesLead(
    salesLead: saleJobSimpleModel,
    followUpdate: any,
    categoryId: any
  ) {
    let param = "";

    if (followUpdate && categoryId) {
      param = "?FollowUpDate=" + followUpdate + "&catetoryId=" + categoryId;
    } else if (followUpdate) {
      param = "?FollowUpDate=" + followUpdate;
    } else if (categoryId) {
      param = "?catetoryId=" + categoryId;
    }

    return this.httpService.postRequestService("sales" + param, salesLead);
  }

  saveSalesLeadFunnel(
    salesLead: saleJobSimpleModel,
    followUpdate: any,
    categoryId: any
  ) {
    let param = "";

    if (followUpdate && categoryId) {
      param = "?FollowUpDate=" + followUpdate + "&catetoryId=" + categoryId;
    } else if (followUpdate) {
      param = "?FollowUpDate=" + followUpdate;
    } else if (categoryId) {
      param = "?catetoryId=" + categoryId;
    }

    return this.httpService.postRequestService(
      "v2/salesjob" + param,
      salesLead
    );
  }
  updateSalesLead(salesLead: saleJobSimpleModel) {
    return this.httpService.putRequestService("sales", salesLead);
  }
  getSalesJobByJobId(jobid: any): Observable<saleJobSimpleModel> {
    return this.httpService.getRequestService("sales/" + jobid);
  }
  changeSalePhase(phaseId: any, saleJob: saleJobSimpleModel) {
    return this.httpService.postRequestService(
      "sale/phasecomplete/" + phaseId,
      saleJob
    );
  }
  uploadDocument(jobId: any, filetype: any, jobPhaseId: any, formData: any) {
    return this.httpService.UploadRequestService(
      "jobs/" + jobId + "/filetype/" + filetype + "/phase/" + jobPhaseId,
      formData
    );
  }
  getSalesDashboardData(
    orgId: any,
    view: any
  ): Observable<SalesDashboardModel[]> {
    return this.httpService.getRequestService(
      "sales/" + orgId + "/dashboard/" + view
    );
  }
//  getSalesData(orgId: any): Observable<SalesDashboardModel[]> {
//    return this.httpService.getRequestService("sales/" + orgId + "/dead");
//  }
  deleteSales(saleId: any) {
    return this.httpService.deleteRequestService("sales?id=" + saleId);
  }
  createDefaultConstructionJob(phase: any) {
    return this.httpService.postRequestService("v2/sale/phasecomplete", phase);
  }
  completeSalesProcess(phase: any) {
    return this.httpService.postRequestService(
      "v2/sale/phasecomplete/finish",
      phase
    );
  }

  completeSalesProcessCore(guid: any) {
    return this.httpService.postRequestService(
      "v2/sale/phasecomplete/finish/v2/" + guid,
      null
    );
  }
  CreateConstructionJob(jobId: any, CreateConstructionJob: boolean) {
    return this.httpService.postRequestService(
      "jobs/" +
        jobId +
        "/complete?CreateConstructionJob=" +
        CreateConstructionJob,
      null
    );
  }
  getAllSalesOnBoardData(orgId: any, templateId: number) {
    return this.httpService.getRequestService(
      environmentConstant.sales +
        orgId +
        environmentConstant.dashboard +
        "?templateId=" +
        templateId
    );
  }
  getSalesPhaseByJobId(jobId: any) {
    return this.httpService.getRequestService(
      environmentConstant.salesphase + jobId
    );
  }
  getSalesPhaseById(phaseId: any) {
    return this.httpService.getRequestService(
      environmentConstant.salesphase + phaseId + "/phase"
    );
  }
  updateSalePhase(salesCard: EditSalesCardModel) {
    return this.httpService.putRequestService("salesphase", salesCard);
  }
  updateSales(jobId: any, salesModel: saleJobBasicModel) {
    if (salesModel.Customer) {
      delete salesModel.Customer["Jobs"];
    }
    return this.httpService.putRequestService(
      "sales/" + jobId + "/salesjob",
      salesModel
    );
  }
  //Gallery
  getGalleryByOrgId(orgId: any, type: any, currnetPage: any, pageSize: any) {
    return this.httpService.getRequestService(
      environmentConstant.sales +
        orgId +
        environmentConstant.gallery +
        "/" +
        type +
        "?page=" +
        currnetPage +
        "&pageSize=" +
        pageSize
    );
  }
  getGalleryByOrgIdCategoryId(
    orgId: any,
    type: any,
    currnetPage: any,
    pageSize: any,
    categoryId: any
  ) {
    return this.httpService.getRequestService(
      environmentConstant.sales +
        orgId +
        environmentConstant.gallery +
        "/" +
        type +
        "?page=" +
        currnetPage +
        "&pageSize=" +
        pageSize +
        "&categoryId=" +
        categoryId
    );
  }
  addGallery(galleryObj: any) {
    return this.httpService.postRequestService(
      "sales" + environmentConstant.gallery,
      galleryObj
    );
  }
  getGalleryById(gId: any) {
    return this.httpService.getRequestService(
      environmentConstant.getSales + "/" + gId
    );
  }
  updateGallery(galleryObj) {
    return this.httpService.putRequestService(
      environmentConstant.getSales,
      galleryObj
    );
  }
  uploadGallery(orgId: any, formData: any, id: any) {
    return this.httpService.UploadRequestService(
      environmentConstant.sales +
        orgId +
        environmentConstant.gallery +
        "/" +
        id,
      formData
    );
  }
  deleteGalleryItem(id: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.getSales + "/" + id
    );
  }

  getOrgSaleVolume(orgId: any, startDate: any, endDate: any) {
    return this.httpService.getRequestService(
      "/salesdashboard/" +
        orgId +
        "/salevolume?startDate=" +
        startDate +
        "&endDate=" +
        endDate
    );
  }
  getOrgSaleZip(orgId: any, startDate: any, endDate: any) {
    return this.httpService.getRequestService(
      "/salesdashboard/" +
        orgId +
        "/zipcode?startDate=" +
        startDate +
        "&endDate=" +
        endDate
    );
  }
  getOrgSaleLead(orgId: any, startDate: any, endDate: any) {
    return this.httpService.getRequestService(
      "/salesdashboard/" +
        orgId +
        "/leadsource?startDate=" +
        startDate +
        "&endDate=" +
        endDate
    );
  }
  getOrgSaleAgent(orgId: any, startDate: any, endDate: any) {
    return this.httpService.getRequestService(
      "/salesdashboard/" +
        orgId +
        "/salesleaders?startDate=" +
        startDate +
        "&endDate=" +
        endDate
    );
  }
  getAppointmentList(orgId: any, startDate, endDate, appType) {
    return this.httpService.getRequestService(
      "/appointments?orgId=" +
        orgId +
        "&start=" +
        startDate +
        "&end=" +
        endDate +
        "&appType=" +
        appType
    );
  }
  saveAppointment(appointmentmodel: AppointmentDTO, sendNotification: boolean) {
    return this.httpService.postRequestService(
      environmentConstant.saveAppointment + sendNotification,
      appointmentmodel
    );
  }
  getAppointmentByID(Id: any) {
    return this.httpService.getRequestService("/appointment/" + Id);
  }
  updateAppointment(
    SendNotfication: boolean,
    appointmentmodel: AppointmentDTO
  ) {
    return this.httpService.putRequestService(
      "appointment?SendNotfication=" + SendNotfication,
      appointmentmodel
    );
  }
  deleteAppointment(id) {
    return this.httpService.deleteRequestService("appointment/" + id);
  }

  getCategoryProductById(id) {
    return this.httpService.getRequestService("productcategory/product/" + id);
  }
  getCategoryAllProductByCategoryId(id) {
    return this.httpService.getRequestService("productcategory/" + id);
  }
  addCategoryProduct(product: any) {
    return this.httpService.postRequestService(
      "productcategory/product",
      product
    );
  }
  updateCategoryProduct(product: any) {
    return this.httpService.putRequestService(
      "productcategory/product",
      product
    );
  }
  uploadProductImage(orgId: any, formData: any, id: any) {
    return this.httpService.UploadRequestService(
      "productcategory/" + orgId + "/product/" + id,
      formData
    );
  }
  deleteCategoryProduct(id: any) {
    return this.httpService.deleteRequestService(
      "productcategory/product" + "/" + id
    );
  }
  getUsersSyncedCalendars(userId: any) {
    return this.httpService.getRequestService(
      "sales/calander/google/" + userId
    );
  }
  setUsersSyncedCalendars(salesGoogleCalander: SalesGoogleCalander) {
    return this.httpService.postRequestService(
      "sales/calander/google",
      salesGoogleCalander
    );
  }
  deleteUsersSyncedCalendars(id: any) {
    return this.httpService.deleteRequestService("sales/calander/google/" + id);
  }
  getAttendees(organizationId) {
    return this.httpService.getRequestService(
      "customer/" + organizationId + "/attendees"
    );
  }
  moveSalesThroughDragDrop(jobId, phaseId, orderId) {
    return this.httpService.postRequestService(
      "sale/" + jobId + "/phase/" + phaseId + "/move/" + orderId,
      null
    );
  }
  getBudgetList(organizationId) {
    return this.httpService.getRequestService(
      "sale/" + organizationId + "/budget"
    );
  }
  getPentairCategory() {
    return this.httpService.getRequestService("productcategories/pentair");
  }
  importPenteirEquipments(product: any) {
    return this.httpService.postRequestService("productcategory/link", product);
  }
  UnsubscribeCategoryProduct(ProductId: any, CategoryId: any) {
    return this.httpService.deleteRequestService(
      "productcategory/" + ProductId + "/" + CategoryId
    );
  }
  /**
   * Fetch Sales Job by Job ID
   * @param jobId Job ID
   * @returns Observable with a Sales job body
   */
  getSalesJobDtoByJobId(jobId: string): Observable<salesListModel> {
    return this.httpService.getRequestService(`sales/${jobId}/dto`);
  }
}
