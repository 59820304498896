export interface JobFileModel {
  id: number;
  guid: string;
  jobGuid: string;
  jobPhaseId: number;
  createdOn: Date;
  deletedOn: Date;
  isPublic: boolean;
  isPinned: boolean;
}

export interface CountModel {
  entityId: number | string;
  countType: UpdateCountType;
  count: number;
}

export enum UpdateCountType {
  Files = 1,
  Notes = 2,
  Photos = 3,
  Checklists = 4,
}

export interface JobCounters {
  FilesCount: number;
  NotesCount: number;
  CheckListsCount: number;
  PhotosCount: number;
}
