import {
  ReportDataSource,
  ReportServerEnvironment,
} from "app/common/directives/commonEnum";

export const environment = {
  production: false,
  useProdMode: false,
  chat: false,
  reportServer: ReportServerEnvironment.DEV,
  reportDataSource: ReportDataSource.DEV,
  imgBaseUrl: "https://api-dev.mobilepoolbuilder.com/",
  baseUrl: "https://api-dev.mobilepoolbuilder.com/api/",
  baseCoreUrl: "https://core-dev.mobilepoolbuilder.com/api/",
  baseEstimateUrl: "https://estimateapi-dev.azurewebsites.net/api/v1/",
  baseEstimateSignalR: "https://estimateapi-dev.azurewebsites.net/",
  baseChatUrl: "https://chat-staging.mobilepoolbuilder.com",
  baseEstimateODataUrl: "https://estimateapi-dev.azurewebsites.net/odata/",
  PentairOrgId: "72945bae-0227-4240-bc26-fe2926394f9a",
  PentairOrgId2: "f067fca2-98b6-4100-afcf-d39ba34eec5b",
};
