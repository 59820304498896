import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DialogModule, MessageService } from "primeng/primeng";
import { QuickBooksConnectorComponent } from "./QuickBooksConnector.component";
import { OrganizationService } from "app/common/services/Core/Organization.service";
import { QuickBooksOptionsComponent } from "./QuickBooksOptions.component";
import { FormsModule } from "@angular/forms";
import { QuickBooksImportComponent } from "./QuickBooksImport.component";
import { QuickBooksSearchProductsComponent } from "./QuickBooksSearchProducts.component";
import { QuickBooksSearchEmployeesComponent } from "./QuickBooksSearchEmployees.component";
import { QuickBooksSearchCustomersComponent } from "./QuickBooksSearchCustomers.component";
import { QuickBooksSearchVendorsComponent } from "./QuickBooksSearchVendors.component";
import { QuickBooksSearchComponent } from "./QuickBooksSearch.component";
import { InputTextModule } from "primeng/inputtext";
@NgModule({
  imports: [CommonModule, DialogModule, FormsModule, InputTextModule],
  declarations: [
    QuickBooksConnectorComponent,
    QuickBooksOptionsComponent,
    QuickBooksImportComponent,
    QuickBooksSearchComponent,
    QuickBooksSearchProductsComponent,
    QuickBooksSearchEmployeesComponent,
    QuickBooksSearchCustomersComponent,
    QuickBooksSearchVendorsComponent,
  ],
  exports: [
    QuickBooksConnectorComponent,
    QuickBooksOptionsComponent,
    QuickBooksImportComponent,
    QuickBooksSearchProductsComponent,
    QuickBooksSearchEmployeesComponent,
    QuickBooksSearchCustomersComponent,
    QuickBooksSearchVendorsComponent,
    InputTextModule,
  ],
  providers: [OrganizationService, MessageService],
})
export class QuickBooksModule {}
