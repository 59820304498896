<div class="widgets commonFixedSidebar addedit-lineitem-pg">
  <div class="row">
    <div class="col-md-12 ht100">
      <div class="rightcontent">
        <div class="sideBarHeader">
          <h1>{{ headerText }}</h1>
        </div>
        <div class="tabBlk">
          <form #myForm="ngForm" class="form-horizontal">
            <div class="fixed-layout hoverflow">
              <div class="row mt15">
                <div class="section-bg">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <h3 class="pt">Contact Details</h3>
                      </div>

                      <div class="col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group">
                          <span class="ui-float-label">
                            <input
                              type="text"
                              class="form-control"
                              maxlength="50"
                              name="FirstName"
                              pInputText
                              [(ngModel)]="contactInfo.contact.firstName"
                              #firstName="ngModel"
                              required
                              preventBackSlashAndDoubleQuote
                            />
                            <label for="text" class="form-control-label"
                              >First Name
                            </label>
                          </span>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group">
                          <span class="ui-float-label">
                            <input
                              type="text"
                              class="form-control"
                              maxlength="50"
                              name="LastName"
                              pInputText
                              [(ngModel)]="contactInfo.contact.lastName"
                              #lastName="ngModel"
                              required
                              preventBackSlashAndDoubleQuote
                            />
                            <label for="text" class="form-control-label"
                              >Last Name
                            </label>
                          </span>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group">
                          <span class="ui-float-label">
                            <p-inputMask
                              [(ngModel)]="contactInfo.contact.phoneNumber"
                              mask="999-999-9999"
                              slotChar=" "
                              autoClear="false"
                              styleClass="form-control"
                              name="phoneNumber"
                              #phoneNumber="ngModel"
                            ></p-inputMask>
                            <label for="text" class="form-control-label"
                              >Phone</label
                            >
                          </span>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12 col-xs-12">
                        <div class="form-group">
                          <span class="ui-float-label">
                            <input
                              type="email"
                              class="form-control"
                              name="Email"
                              [(ngModel)]="contactInfo.contact.email"
                              pInputText
                              #Email="ngModel"
                              required
                            />
                            <label for="text" class="form-control-label"
                              >Email
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <h3 class="pt">Login Info</h3>
                  <div class="form-group">
                    <span class="ui-float-label">
                      <input type="text" class="form-control">
                      <label for="text" class="form-control-label">User Name
                      </label>
                    </span>
                  </div>
                </div> -->

                <div class="col-md-12 col-sm-12 col-xs-12">
                  <div class="row">
                    <div class="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                      <h3 class="pt mb0">Mobile Options</h3>
                    </div>
                    <div class="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <span class="ui-float-label">
                          <div class="switchBox pl0 l0 mt5 d-ib" id="mpbAccess">
                            <label class="switch">
                              <input
                                type="checkbox"
                                [(ngModel)]="contactInfo.hasMpbAccess"
                                name="hasMpbAccess"
                              />
                              <span class="slider round"></span>
                            </label>
                            <p class="di">Allow MPB Access</p>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="section-bg">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12">
                          <h3 class="pt">Login Info</h3>
                        </div>
                        <div class="col-12">
                          <div class="form-group">
                            <span class="ui-float-label">
                              <input
                                type="text"
                                class="form-control"
                                maxlength="50"
                                name="userName"
                                id="userName"
                                pInputText
                                [(ngModel)]="contactInfo.contact.userName"
                                #userName="ngModel"
                                required
                                preventBackSlashAndDoubleQuote
                              />
                              <label for="userName" class="form-control-label"
                                >User Name
                              </label>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12">
                  <div class="section-bg">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <h3 class="pt">Notification Preference</h3>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div class="form-group">
                            <label
                              class="checkbox-inline custom-checkbox nowrap"
                            >
                              <input
                                type="checkbox"
                                name="notificationEmail"
                                [(ngModel)]="
                                  contactInfo.contact.userPreference
                                    .notificationEmail
                                "
                                #notificationEmail="ngModel"
                              />
                              <span>Email</span>
                            </label>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div class="form-group">
                            <label
                              class="checkbox-inline custom-checkbox nowrap"
                            >
                              <input
                                type="checkbox"
                                name="notificationSMS"
                                [(ngModel)]="
                                  contactInfo.contact.userPreference
                                    .notificationSMS
                                "
                                #notificationSMS="ngModel"
                              />
                              <span>Text Message</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="h50"></div>
            </div>
            <div class="fixbtnwrap">
              <div class="btnwrap__center">
                <button
                  type="button"
                  class="btn darkbtn"
                  *ngIf="myForm.dirty"
                  (click)="onSubmit(myForm)"
                  [disabled]="!myForm.valid"
                >
                  <i class="fa fa-floppy-o" aria-hidden="true"></i>
                  {{ buttonText }}
                </button>
                <button
                  type="button"
                  class="cross btn bthpn2"
                  (click)="onCancel()"
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
