<div class="control-section" [class.h-auto]="!adjustHeight">
  <div class="control_wrapper">
    <div class="imagepreview p-0">
      <div id="dropArea" class="drop-top-area">
        <span>{{dropText}}</span>

        <div class="drop-area-attachment-icon d-flex layout-horizontal-center">
          <span class="fa fa-paperclip" aria-hidden="true"></span>
        </div>

        <span class="drop-upload-text">Or browse your computer:</span>
      </div>
      <div id="dropArea" class="drop-bottom-area">
        <kendo-upload
          #uploader
          [saveUrl]="uploadSaveUrl"
          [removeUrl]="uploadRemoveUrl"
          [chunkable]="chunkSettings"
          [withCredentials]="false"
          [autoUpload]="false"
          [restrictions]="myRestrictions"
          [multiple]="allowMultiple"
          (select)="selectEventHandler($event)"
          (upload)="uploadEventHandler($event)"
          (complete)="completeEventHandler()"
        >
          <kendo-upload-messages
            dropFilesHere="Drop your files here"
            select="Browse"
          >
          </kendo-upload-messages>
        </kendo-upload>
      </div>
    </div>
  </div>
</div>
