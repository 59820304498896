<form #SchdeuleMyForm="ngForm">
  <div
    class="addpagewrap scheduler-pg"
    [ngClass]="{'second-sidebar': !isThirdSidebar}"
  >
    <div class="headingwrap clearfix" id="rfsheaderbar">
      <h1>Scheduler</h1>
    </div>
    <div
      class="editjobtabform m0 hoverflow"
      [ngClass]="{'overflow-auto': displayFromSidebar}"
    >
      <div class="lgray">
        <div class="row">
          <div class="col-md-6 col-sm-6 col-xs-12 mb-2">
            <p class="schtitle">
              <span>Job Phase</span>
            </p>
            <span class="schtitle">{{phase.JobPhaseName}}</span>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12 mb-2">
            <p class="schtitle">
              <span>Preferred Contractor</span>
            </p>
            <span class="schtitle">{{contractorNameOrg}}</span>
          </div>
        </div>
        <div class="row">
          <!-- <div class="col-md-6 col-sm-6 col-xs-12">
            <p class="schtitle">
              <span>Scheduled Date</span>
              <kendo-datepicker [ngClass]="{'has-error':schdeuleddate && schdeuleddate.invalid && (schdeuleddate.dirty || schdeuleddate.touched)}"
                class="full-date" name="schdeuleddate" #schdeuleddate="ngModel" id="schdeuleddate" [(ngModel)]="schdeuledDate"
                required></kendo-datepicker>
            </p>
          </div> -->
          <div class="col-md-6 col-sm-6 col-xs-12 mb-3">
            <p class="schtitle"><span>Job Name</span></p>
            <span class="schtitle">{{jobName}}</span>
          </div>
        </div>
      </div>
      <form
        #SchdeuleRequestMyForm="ngForm"
        (ngSubmit)="sendScheduelRequest()"
        [ngClass]="{'h-auto': displayFromSidebar}"
      >
        <div class="lgray mt20">
          <p>Request Service Date</p>
          <div class="d-flex mt-1" [ngClass]="{'flex-wrap': isThirdSidebar}">
            <span>
              <label class="radio-inline custom-radio nowrap">
                <input
                  type="radio"
                  name="dateRange"
                  id="dateRange"
                  [value]="true"
                  [(ngModel)]="dateRange"
                />
                <span>Recipient selects from Date Range</span>
              </label>
            </span>
            <span [ngClass]="{'lm30': !isThirdSidebar}">
              <label class="radio-inline custom-radio nowrap">
                <input
                  type="radio"
                  name="dateRange"
                  id="dateRange"
                  [value]="false"
                  [(ngModel)]="dateRange"
                />
                <span>Recipient Enters Date</span>
              </label>
            </span>
          </div>
          <div class="row mt-3">
            <div class="col-md-4 col-sm-4 col-xs-12">
              <p
                class="schtitle"
                [style.visibility]="dateRange ? 'visible' : 'hidden'"
              >
                <span>Start Date</span>
                <kendo-datepicker
                  [ngClass]="{'has-error':StartDate && StartDate.invalid && (StartDate.dirty || StartDate.touched)}"
                  class="full-date"
                  name="StartDate"
                  #StartDate="ngModel"
                  id="StartDate"
                  [(ngModel)]="startDate"
                  [required]="dateRange"
                ></kendo-datepicker>
              </p>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
              <p
                class="schtitle"
                [style.visibility]="dateRange ? 'visible' : 'hidden'"
              >
                <span>End Date</span>
                <kendo-datepicker
                  [ngClass]="{'has-error':EndDate && EndDate.invalid && (EndDate.dirty || EndDate.touched)}"
                  class="full-date"
                  [disabled]="!startDate"
                  [min]="startDate"
                  name="EndDate"
                  #EndDate="ngModel"
                  id="EndDate"
                  [(ngModel)]="endDate"
                  [required]="dateRange"
                ></kendo-datepicker>
              </p>
            </div>
            <div class="col-md-4 col-sm-4 col-xs-12">
              <button
                type="submit"
                class="btn sendreqbtn"
                [disabled]="!SchdeuleRequestMyForm.valid || SubContractorId==0"
                [ngClass]="{'pl-3px': isThirdSidebar}"
              >
                Send Request
              </button>
            </div>
            <div class="col-md-12 col-sm-12 col-xs-12 mt-3">
              <div class="form-group mb10">
                <span class="ui-float-label schtitle">
                  <textarea
                    class="form-control"
                    name="comments"
                    maxlength="500"
                    id="comments"
                    #comments="ngModel"
                    [(ngModel)]="Comments"
                    rows="3"
                    pInputText
                  ></textarea>
                  <label for="text" class="form-control-label"
                    >Additional Information</label
                  >
                </span>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </form>
      <div class="searchbox mt-3">
        <div class="jsearch shpn">
          <input
            class="form-control"
            name="searchFieeld"
            [(ngModel)]="filterQuery"
            placeholder="Search Contractor"
            type="text"
          />
          <i class="fa fa-search" aria-hidden="true"></i>
        </div>
      </div>
      <div
        class="accodn"
        *ngFor="let item of schdeuledContractorJobs| commonFilter:{ContractorName:filterQuery}:false"
      >
        <div class="accordhead">
          <label class="radio-inline custom-radio">
            <input
              type="radio"
              [checked]="item.IsSelected"
              name="IsSelected"
              (change)="contractorChange(item)"
            />
            <span></span>
          </label>
          <div class="name">
            <span class="titlehd">{{getInitals(item.ContractorName)}}</span
            >{{item.ContractorName}}
          </div>
          <!-- <div class="datenext">
          <span>Next Available</span>
          08/25/2018
        </div> -->
        </div>
        <!-- <div class="accordbody">
          <p class="title">Current Schedule</p>
          <div class="schedulebox" *ngFor="let jphase of item.ContractorPhases">
            <span class="circle" [ngClass]="{'gray':jphase.Status===1,'orenge':jphase.Status===2,'green':jphase.Status===3}"></span>
            <div class="schname">{{jphase.JobName}}</div>
            <div class="schphname">{{jphase.PhaseName}}</div>
            <div class="schdate">{{jphase.ActualStartDate|date:'shortDate'}} -
              {{jphase.ActualEndDate|date:'shortDate'}}</div>
            <div class="clearfix"></div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="fixbtnwrap" id="btmclosebtn">
      <div class="btnwrap">
        <!-- <button type="submit" class="btn darkbtn mtop0" [disabled]="!SchdeuleMyForm.valid" *ngIf="SchdeuleMyForm.dirty">
          <i class="fa fa-floppy-o" aria-hidden="true"></i> Schedule</button> -->
        <button type="button" class="btn btn-cancel" (click)="onCancel()">
          Close
        </button>
      </div>
    </div>
  </div>
</form>
