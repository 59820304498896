import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { TaxingAuthority } from "app/model/TaxingAuthority";

@Injectable({
  providedIn: "root",
})
export class TaxingAuthoritiesHelperService {
  constructor() {}

  /** Filters authorities by name based on a text */
  filterByName(
    filter: string,
    authorities: TaxingAuthority[]
  ): TaxingAuthority[] {
    return authorities.filter(
      (s) => s.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }
  /** Handles action to perform when an auhtority is selected in a form */
  handleFormSubscription(
    authorities: TaxingAuthority[],
    form: FormGroup,
    authId: number
  ): void {
    const ta = authorities.find((x) => x.id === authId);
    if (ta) {
      form.get("percentage").setValue(ta.percentage);
      form.get("taxRate").setValue(ta.taxRate);
    }
  }
}
