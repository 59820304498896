import { JobNoteModel } from "app/model/jobNoteModel";
import { JobFileModel } from "app/model/jobFile";
import { JobPhotoModel } from "app/model/jobPhotoModel";
import { Resource } from "./ResourceModel";
import { PhaseModel } from "./phaseModel";
// tslint:disable:variable-name

export class JobPhaseChildIds {
  JobPhotoIds: number[];
  JobFileIds: number[];
  JobNoteIds: number[];
  ChecklistGuids: string[];
}

export class JobPhasModel {
  Aging: number;
  JobPhotos: JobPhotoModel[];
  JobFiles1: JobFileModel[];
  ChildIds?: JobPhaseChildIds;
  ID: number;
  SubContractorId: number;
  OriginalPhaseId: number;
  IsComplete: boolean = false;
  Comments: string;
  IsReady: boolean = false;
  DateOverdue: Date;
  ScheduledDate: Date;
  EstStartDate: Date;
  EstEndDate: Date;
  ActualStartDate: Date;
  ActualEndDate: Date;
  DaysToComplete: number;
  DaysToCompleteLong: number;
  Order: number = 1;
  JobTypeId: number;
  OrgGUID: string;
  Status: any;
  StatusId: number;
  TradeName: string;
  PhaseName: string;
  TradeId: number;
  Amount: number;
  FollowUpDate: Date;
  Priority: number;
  PunchlistLocation: string;
  IsPublic: boolean = true;
  IsPunchListWarranty: boolean;
  SubContractorEmail: string;
  SubContractorCompany: string;
  Contractor: string;
  ContractorId: number;
  SubContractorPrimaryPhone: string;
  JobFiles: JobFileModel[];
  JobNotes: JobNoteModel[];
  CategoryId: number;
  PhaseDisplayName: string;
  Dependents: any;
  MPBCategoryCatetoryName: string;
  businessrule: string;
  calOrderByPhaseId: number;
  JobPhaseName: string;
  Estimated: any;
  Actual: any;
  SubContractorName: any;
  StatusName: any;
  NextStatusName: any;
  isDirty: boolean = false;
  disableEstSD: boolean = false;
  disableEstED: boolean = false;
  disableActulSD: boolean = false;
  disableActuleED: boolean = false;
  PhaseCalendarColor: string;
  DependentsVal: string;
  UseContractorOrg: boolean;
  WorkOrderName: string;
  Description: string;
  DependsOnMe: boolean;
  Resources: any;
  JobID: string;
  Job_Id?: string;
  BusinessUnitId: number;
  PhotoCount: number;
  NoteCount: number;
  CheckListCount: number;
  FileCount: number;
  DependentsList: JobPhasModel[];
  isReady?: boolean;
  Guid: string;
  PhaseOrder: number;
  AlertCount: number;
  LastPhotoUploaded?: string;
  JobCameraCount?: number;
  ContractorGUID?: string;
  lastEdited?: boolean;
  ResourcesDetails?: Resource[];
  PhaseReplacingId?: number;
  ProposedDate: Date;
  RequestedOn: Date;
  ViewedOn: Date;
  RequestId: number;
  RFSAccessCode: string;
  ShouldClose?: boolean;
  constructor() {}
}

export interface JobPhaseEditResponse {
  Guid: string;
  Aging: number;
  JobPhotos: any[];
  JobFiles: any[];
  JobNotes: null;
  CheckList: null;
  ChildIds: null;
  LastUploadedPhoto: null;
  TotalPhaseAlert: number;
  JobID: string;
  CategoryId: null;
  SubContractorEmail: null;
  SubContractorCompany: null;
  SubContractorPrimaryPhone: null;
  PhaseDisplayName: null;
  Dependents: Dependent[];
  PhaseAlert: null;
  MPBCategoryCatetoryName: null;
  ID: number;
  SubContractorId: null;
  OriginalPhaseId: number;
  IsComplete: boolean;
  Comments: null;
  IsReady: boolean;
  DateOverdue: Date;
  EstStartDate: Date;
  EstEndDate: Date;
  ActualStartDate: Date;
  ActualEndDate: Date;
  ScheduledDate: Date;
  DaysToComplete: number;
  DaysToCompleteLong: number;
  Order: number;
  JobTypeId: number;
  OrgGUID: string;
  Status: number;
  PhaseName: string;
  TradeId: number;
  TradeName: string;
  Amount: number;
  FollowUpDate: Date;
  Priority: number;
  PunchlistLocation: null;
  IsPublic: boolean;
  IsPunchListWarranty: boolean;
  PhaseCalendarColor: null;
  PhaseDescription: null;
  FileCount: number;
  NoteCount: number;
  PhotoCount: number;
  CheckListCount: number;
  UseContractorOrg: boolean;
  DependsOnMe: boolean;
  BusinessUnitId: number;
  ResourcesDetails: Resource[];
  isReady?: boolean;
  StatusId: number;
  ShouldClose?: boolean;
}

export interface Dependent {
  Guid: string;
  Aging: number;
  SubContractorCompany: null;
  DisplayName: null;
  CatetoryName: null;
  ID: number;
  SubContractorId: null;
  OriginalPhaseId: number;
  IsComplete: boolean;
  IsReady: boolean;
  DateOverdue: Date;
  EstStartDate: Date;
  EstEndDate: Date;
  ActualStartDate: Date;
  ActualEndDate: Date;
  DaysToComplete: number;
  PhaseOrder: number;
  OrgGUID: null;
  Status: number;
  PhaseName: string;
  TradeId: null;
  TradeName: null;
  Amount: number;
  FollowUpDate: Date;
  Priority: number;
  IsPublic: boolean;
  PhaseCalendarColor: null;
}

export interface EventStatus {
  key: number;
  Value: string;
  checked: boolean;
}

export interface BuildPlanFilters {
  query: string;
  status: EventStatus[];
}

export enum BuildPlanDragAndDropType {
  AddPhase = 1,
  RearrangePhases = 2,
}
