import { Injectable } from "@angular/core";
import { environmentConstant } from "app/common/utility/environment";
import { HttpService } from "app/common/utility/http.service";
import { SharedService } from "app/common/utility/SharedService";
import { JobNoteModel } from "app/model/jobNoteModel";
import { JobPhasModel } from "app/model/jobPhaseModel";
import { JobPhotoPaginationModel } from "app/model/jobPhotoModel";
import { ScheduleSimpleModel } from "app/model/scheduleSimpleModel";
import { SchedulingRequest } from "app/model/schedulingModel";
import { StatusModel } from "app/model/statusModel";
import {
  UpdatePhaseScheduleArrayRequest,
  UpdatePhaseScheduleRequestModel,
} from "app/model/UpdatePhaseScheduleRequestModel";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { PhotoEnum } from "../directives/commonEnum";
import { CalendarSalesAppointmentDTO } from "app/model/workOrderModel ";

@Injectable()
export class JobPhaseService {
  private subject = new Subject<boolean>();

  updateAssignedResource(isupdated: boolean) {
    this.subject.next(isupdated);
  }
  getUpdateAssignResource(): Observable<any> {
    return this.subject.asObservable();
  }
  setJobhaseDummy(jobPhase: JobPhasModel, jobId: string): any {
    return this.httpService.postRequestService(
      environmentConstant.saveJobPhase + "dummy/" + jobId,
      jobPhase
    );
  }

  constructor(
    private httpService: HttpService,
    private sharedService: SharedService
  ) {}
  getGanttPhasesByJobId(jobId: any) {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhaseById + jobId + "/jobphases"
    );
  }
  getAllPhasesByJobId(jobId: any) {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhaseById + jobId + "/lookup"
    );
  }
  getAllPhasesByOrgId(orgId: any, jobTypeId: number = 2) {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhaseById +
        orgId +
        "/lookupByOrg?jobTypeId=" +
        jobTypeId
    );
  }
  getBuildPlanByJobID(jobId: any): Observable<JobPhasModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getBuildPlanByJobID +
        jobId +
        "/phases?isLighterPayload=true"
    );
  }
  getJobPhaseNotes(jobPhaseId: any): Observable<JobNoteModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhaseNOtes + jobPhaseId + "/notes"
    );
  }
  getStatusId(statusId: number): Observable<StatusModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getStatus + "/" + statusId
    );
  }
  saveJobPhase(jobPhase: JobPhasModel, updateDependents: boolean, jobId: any) {
    return this.httpService.postRequestService(
      environmentConstant.saveJobPhase + jobId + "/" + updateDependents,
      jobPhase
    );
  }
  getJobPhaseById(id: any): Observable<JobPhasModel> {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhaseById + id + "?isLighterPayload=true"
    );
  }

  searchJobPhaseResources(orgId: any, payload: any) {
    return this.httpService.post(
      environmentConstant.getSearchJobPhaseResources(orgId),
      payload
    );
  }

  getJobPhaseLegacyByGuid(guid: any) {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhaseById +
        "guid/" +
        guid +
        "?isLighterPayload=true"
    );
  }
  getJobPhaseByIdBasic(id: any): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhaseById + id + "/basic"
    );
  }

  getToBeSchedulePhaseByOrgId(
    OrgId: any,
    howMany: any,
    clearToSchedule: any = false
  ): any {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhaseById +
        OrgId +
        "/tobeschedule?howMany=" +
        howMany +
        "&cleartoschedule=" +
        clearToSchedule
    );
  }
  getToBeSchedulePhaseByOrgIdByByScheulded(
    OrgId: any,
    howMany: any,
    IsScheduled: any
  ): any {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhaseById +
        OrgId +
        "/tobeschedule?howMany=" +
        howMany +
        "&cleartoschedule=" +
        IsScheduled
    );
  }
  getToBeSchedulePhaseByOrgIdByDays(OrgId: any, rangeFilter: any): any {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhaseById +
        "whatsscheduled/" +
        OrgId +
        "/filter/" +
        rangeFilter
    );
  }
  getPhasesScheduledNow(orgGuid: any): any {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhaseById + "schedulednow/" + orgGuid
    );
  }
  getJobPhaseByIdV2(id: any): Observable<JobPhasModel> {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhaseById + id + "/v2?isLighterPayload=true"
    );
  }
  getAllJobPhasesCost(jobId: any): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhaseById + jobId + "/jobcost"
    );
  }
  deleteJobphaseCostByPhaseId(id: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.deleteJobPhase + "jobphasecost/" + id
    );
  }
  deleteJobPhase(id: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.deleteJobPhase + id
    );
  }
  updateJobPhase(jobPhase: JobPhasModel, updateDependents: boolean) {
    return this.httpService.putRequestService(
      environmentConstant.updateJobPhase + updateDependents,
      jobPhase
    );
  }
  updateJobPhaseCost(jobPhase: any) {
    return this.httpService.putRequestService(
      environmentConstant.updateJobPhase + "jobphasecost",
      jobPhase
    );
  }
  updatejobPhaseSchedule(phase: any) {
    return this.httpService.putRequestService(
      environmentConstant.updateJobPhase + "schdeuleassistant",
      phase
    );
  }
  addDateOfServiceRequest(request: SchedulingRequest) {
    return this.httpService
      .postRequestService(
        environmentConstant.saveJobPhase + "dateofservice/request",
        request
      )
      .pipe(map((res) => res));
  }
  updateEstimatedDate(estimatedDate: UpdatePhaseScheduleRequestModel) {
    return this.httpService.putRequestService(
      environmentConstant.updatePhasesScheduleDates,
      estimatedDate
    );
  }
  updatePhasesScheduleDelay(request: UpdatePhaseScheduleArrayRequest) {
    return this.httpService.putRequestService(
      environmentConstant.updatePhasesScheduleDelay,
      request
    );
  }
  savePunchList(jobPhase: JobPhasModel, jobId: any) {
    return this.httpService.postRequestService(
      environmentConstant.savePunchList + jobId + "/item",
      jobPhase
    );
  }
  getPunchListPhaseById(id: any): Observable<JobPhasModel> {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhaseById + id + "/job"
    );
  }
  getPunchListFileByJobID(
    jobId: any,
    currnetPage: any,
    pageSize: any
  ): Observable<JobPhotoPaginationModel> {
    return this.httpService.getRequestService(
      environmentConstant.getPunchlistFiles +
        jobId +
        "/files?page=" +
        currnetPage +
        "&pageSize=" +
        pageSize
    );
  }
  getPunchListPhotosByJobID(
    jobId: any,
    currnetPage: any,
    pageSize: any
  ): Observable<JobPhotoPaginationModel> {
    return this.httpService.getRequestService(
      environmentConstant.getPunchListPhotos +
        jobId +
        "/photos?page=" +
        currnetPage +
        "&pageSize=" +
        pageSize
    );
  }
  getPunchListNotesByJobID(jobId: any): Observable<JobNoteModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getPunchListNotes + jobId + "/notes"
    );
  }
  savePunchListNotes(jobId: any, punchListNote: JobNoteModel) {
    return this.httpService.postRequestService(
      environmentConstant.savePunchListNotes + jobId + "/punchlistitemnote",
      punchListNote
    );
  }
  uploadDocument(
    jobId: any,
    filetype: number,
    jobPhaseId: number,
    formData: any
  ) {
    return this.httpService.UploadRequestService(
      "punchist/" + jobId + "/filetype/" + filetype + "/item/" + jobPhaseId,
      formData
    );
  }
  getJobPhaseEvents(
    orgId: any,
    jobId: any,
    startDate: any,
    endDate: any
  ): Observable<ScheduleSimpleModel[]> {
    // tslint:disable-next-line:prefer-template
    return this.httpService.getRequestService(
      "jobphase/" +
        orgId +
        "/job/" +
        jobId +
        "/" +
        startDate +
        "/start/" +
        endDate +
        "/end"
    );
  }
  getJobPhaseEventsV2(
    orgId: any,
    jobId: any,
    startDate: any,
    endDate: any,
    hasScheduledPhases: boolean,
    jobTypeId: number = 2
  ) {
    // tslint:disable-next-line:prefer-template
    return this.httpService.getRequestService(
      "jobphase/" +
        "v2/" +
        orgId +
        "/job/" +
        jobId +
        "/" +
        startDate +
        "/start/" +
        endDate +
        "/end/" +
        jobTypeId +
        "/jobTypeId?IsSchdeuled=" +
        hasScheduledPhases
    );
  }
  moveJobPhase(jobphaseId: any, jobID: any, newdisplayOrder: any) {
    return this.httpService.putRequestService(
      environmentConstant.updateJobPhase +
        "/" +
        jobphaseId +
        "/" +
        jobID +
        "/move/" +
        newdisplayOrder,
      null
    );
  }
  getChangeReasons() {
    return this.httpService.getRequestService(
      environmentConstant.getschedulereason +
        this.sharedService.selectedOrganization.ID
    );
  }
  getFilesByJobPhaseId(
    jobphaseId: any,
    currnetPage: any,
    pageSize: any,
    searchText: string = ""
  ) {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhaseById +
        jobphaseId +
        "/files?page=" +
        currnetPage +
        "&pageSize=" +
        pageSize +
        "&search=" +
        searchText
    );
  }
  getNotesByJobPhaseId(jobphaseId: any) {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhaseById + jobphaseId + "/notes"
    );
  }
  getPhotosByJobPhaseId(
    jobphaseId: any,
    currnetPage: any,
    pageSize: any,
    photoType: PhotoEnum,
    jobId: string = ""
  ) {
    let urlBase = `${environmentConstant.getJobPhaseById}${jobphaseId}/photos`;
    let queryParams = `?page=${currnetPage}&pageSize=${pageSize}&photoType=${photoType}`;
    let jobIdParam = jobId ? `&jobId=${jobId}` : "";
    let url = `${urlBase}${queryParams}${jobIdParam}`;
    return this.httpService.getRequestService(url);
  }
  getJobPhaseByJobId(jobId: any) {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhaseById + jobId + "/v2"
    );
  }
  getJobPhaseByBasic(jobId: any) {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhaseById + jobId + "/basic"
    );
  }
  getJobPhaseListByJobId(jobId: any) {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhaseById + jobId + "/basiclist"
    );
  }
  getTradeById(id: any) {
    return this.httpService.getRequestService(
      environmentConstant.getTradeById + id
    );
  }
  completeJobPhase(phaseId: any, startDate, endDate) {
    let parStartDate = startDate
      ? startDate
      : new Date(new Date().toDateString()).toLocaleDateString();
    let parEndDate = endDate
      ? endDate
      : new Date(new Date().toDateString()).toLocaleDateString();
    return this.httpService.putRequestService(
      environmentConstant.getJobPhaseById +
        phaseId +
        "/complete" +
        "?startDate=" +
        parStartDate +
        "&endDate=" +
        parEndDate,
      {}
    );
  }
  startJobPhase(phaseId: any) {
    let startDate = new Date(new Date().toDateString()).toLocaleDateString();
    return this.httpService.putRequestService(
      environmentConstant.getJobPhaseById +
        phaseId +
        "/start" +
        "?startDate=" +
        startDate,
      {}
    );
  }
  getVerifyContractorAccessCode(accessCode: any, requestId: any): any {
    return this.httpService.getNoTokenRequestService(
      "dateofservice/verifyAccessCode?accessCode=" +
        accessCode +
        "&requestId=" +
        requestId
    );
  }
  getScheduleRequestbyOrgid(orgID: any) {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhaseById +
        "dateofservice/schedulerequestbyorgid?orgGuid=" +
        orgID
    );
  }

  acceptProppsedDate(reqId: any) {
    return this.httpService.putRequestService(
      environmentConstant.getJobPhaseById +
        "dateofservice/dateaccepted?requestId=" +
        reqId +
        "&userId=" +
        this.sharedService.loggedInUser.Id,
      {}
    );
  }

  rejectProppsedDate(reqId: any) {
    return this.httpService
      .putRequestService(
        environmentConstant.getJobPhaseById +
          "dateofservice/daterejected?requestId=" +
          reqId +
          "&userId=" +
          this.sharedService.loggedInUser.Id,
        {}
      )
      .pipe(map((res) => res));
  }

  cancelProppsedDate(reqId: any) {
    return this.httpService.putRequestService(
      environmentConstant.getJobPhaseById +
        "dateofservice/cancel?requestId=" +
        reqId +
        "&userId=" +
        this.sharedService.loggedInUser.Id,
      {}
    );
  }

  scheduleMultiple(phasesIds: number[]) {
    return this.httpService.putRequestService(
      environmentConstant.updateJobPhase + "schedule/multiple",
      phasesIds
    );
  }

  reArrangePhases(
    jobId: string,
    phaseToSwitchId: number,
    phaseToSwitchWithId: number
  ): Observable<JobPhasModel[]> {
    return this.httpService.putRequestService(
      `${environmentConstant.updateJobPhase}switchOrder/${jobId}/${phaseToSwitchId}/${phaseToSwitchWithId}`,
      {}
    );
  }
  getJobPhaseSchedulingByJobPhaseId(jobPhaseId: number): Observable<any> {
    const url = `jobPhase/scheduling/${jobPhaseId}`;
    return this.httpService.getRequestService(url);
  }
  getJobPhaseEventsV2ByAppointmentDate(
    orgId: any,
    jobIds: any,
    startDate: any,
    endDate: any,
    hasScheduledPhases: boolean,
    jobTypeId: number = 2
  ): Observable<CalendarSalesAppointmentDTO[]> {
    return this.httpService.postRequestService(
      "jobphase/" +
        "v2/appointments/" +
        orgId +
        "/job/" +
        null +
        "/" +
        startDate +
        "/start/" +
        endDate +
        "/end?IsSchdeuled=" +
        hasScheduledPhases +
        "&jobTypeId=" +
        jobTypeId,
      jobIds
    );
  }
}
