import { Resource } from "./ResourceModel";
import { AddressModel } from "./addressModel";

export class PunchListModel {
  ID: string;
  PunchListName: string;
  OrgGuid: string;
  PunchlistTemplateId: number;
  PunchListTemplateName: string;
  Status: number;
  Notes: any[];
  Items: any[];
  constructor() {}
}

export class PunchListModelV2 {
  ID: number;
  LineItemName: string;
  OrgGuid: string;
  StatusId: number;
  Description: string;
  CategoryId: number;
  RemindMeOnDay: Date;
  IsPublic: boolean;
  IsWarranty: boolean;
  Status: string;
  Priority: number;
  UseContractorOrg: boolean;
  JobName: string;
  JobID: string;
  Address: AddressModel;
  Aging: number;
  Phone: string;
  ContactsJSON: string;
  Resources: Resource[];
  WorkOrderID: string;
  WorkOrderName: string;
  ResourcesText: string;
  BusinessUnitId: number;
  StartDate: Date;
  CompleteDate: Date;
  TargetDate: Date;
  ScheduledDate: Date;
  DaysToComplete: number;
}
