<p-toast class="general-toast" position="top-right"></p-toast>
<div class="widgets commonFixedSidebar">
  <div class="row">
    <div class="col-md-12 ht100">
      <div class="rightcontent">
        <div class="sideBarHeader">
          <h1>{{headerText}} Vendor</h1>
        </div>
        <a
          class="d-flex justify-content-center link-anchor"
          href="/#/pages/estimate/vendor/pricebooksummary/{{ vendorId }}"
          target="_self"
          *ngIf="vendorId !== 0 && isFinancial"
        >
          Price Books&nbsp;
          <i class="svg-icon svg-icon__baseline-open_in_new"></i>
        </a>
        <div class="tabBlk">
          <form class="form-horizontal" novalidate #myForm="ngForm">
            <kendo-tabstrip [keepTabContent]="true" ngClass="estimate-panel">
              <kendo-tabstrip-tab title="Details" [selected]="true">
                <ng-template kendoTabContent>
                  <div class="mb22">
                    <!-----------------------ADD CONTRACTOR-------------------------------->
                    <div *ngIf="vendorId === 0 && !isExisting">
                      <div class="row mt15 vendor-contractor-form-container">
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-3">
                          <div class="form-group mb0">
                            <span class="ui-float-label">
                              <input
                                type="text"
                                class="form-control"
                                id="company"
                                name="company"
                                pInputText
                                [(ngModel)]="contractor.Organization.OrganizationName"
                                [ngClass]="{'has-error':company.invalid && (company.dirty || company.touched)}"
                                maxlength="200"
                                #company="ngModel"
                                required
                              />
                              <label for="text" class="form-control-label">
                                Company Name
                                <span class="text-red">*</span>
                              </label>
                            </span>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-3">
                          <div class="form-group mb0">
                            <span class="ui-float-label">
                              <input
                                [(ngModel)]="organizationPhone"
                                [disabled]="!contractor || !contractor.Organization"
                                type="tel"
                                class="form-control"
                                name="Phone"
                                pInputText
                                maxlength="50"
                                #Phone="ngModel"
                              />
                              <!-- <p-inputMask
                                [(ngModel)]="contractor.Organization.Phone"
                                mask="999-999-9999"
                                slotChar=" "
                                autoClear="false"
                                styleClass="form-control"
                                name="Phone"
                                #Phone="ngModel"
                              ></p-inputMask> -->
                              <label for="text" class="form-control-label"
                                >Phone
                              </label>
                            </span>
                          </div>
                        </div>

                        <div class="col-md-12 col-sm-12 col-xs-12 mb-3">
                          <div class="form-group mb0">
                            <span class="ui-float-label">
                              <input
                                type="email"
                                class="form-control"
                                id="email"
                                name="email"
                                [(ngModel)]="contractor.Organization.Email"
                                pInputText
                                email="true"
                                trim="blur"
                                #email="ngModel"
                                [ngClass]="{'has-error':email.invalid && (email.dirty || email.touched)}"
                                maxlength="200"
                                [pattern]="emailPattern"
                                required
                              />
                              <label for="Email" class="form-control-label"
                                >{{'common.email'|translate}}
                                <span class="text-red">*</span>
                              </label>
                            </span>
                          </div>
                        </div>

                        <div class="col-md-12 col-sm-12 col-xs-12 mb-3">
                          <div class="form-group mb0">
                            <span class="ui-float-label">
                              <input
                                type="text"
                                class="form-control"
                                id="Address"
                                name="Address"
                                pInputText
                                [(ngModel)]="contractor.Organization.Address"
                                maxlength="250"
                                #Address="ngModel"
                              />
                              <label for="text" class="form-control-label"
                                >Address
                              </label>
                            </span>
                          </div>
                        </div>

                        <div class="col-md-6 col-sm-6 col-xs-12 mb-3">
                          <div class="form-group mb0">
                            <span class="ui-float-label">
                              <input
                                type="text"
                                class="form-control"
                                id="City"
                                name="City"
                                pInputText
                                [(ngModel)]="contractor.Organization.City"
                                maxlength="200"
                                #City="ngModel"
                              />
                              <label for="text" class="form-control-label"
                                >City
                              </label>
                            </span>
                          </div>
                        </div>

                        <div class="col-md-6 col-sm-6 col-xs-12 mb-3">
                          <div
                            class="form-group mb0 vendor-contractor-form-container__state"
                          >
                            <kendo-floatinglabel
                              text="State"
                              class="fontWeightLighterSliderDropdowns"
                            >
                              <kendo-combobox
                                [data]="stateList"
                                [valuePrimitive]="true"
                                [filterable]="true"
                                (filterChange)="filterState($event)"
                                [textField]="'Abbreviation'"
                                [(ngModel)]="contractor.Organization.State"
                                name="State"
                                [valueField]="'Id'"
                              >
                              </kendo-combobox>
                            </kendo-floatinglabel>
                          </div>
                        </div>

                        <div class="col-md-6 col-sm-6 col-xs-12 mb-3">
                          <div class="form-group mb0">
                            <span class="ui-float-label">
                              <input
                                #inputZip
                                type="text"
                                class="form-control"
                                id="Zip"
                                name="Zip"
                                pInputText
                                oninput="this.value = this.value.toUpperCase()"
                                [(ngModel)]="contractor.Organization.Zip"
                                [ngClass]="{
                                'has-error': zipValidate
                              }"
                              />
                              <label for="text" class="form-control-label"
                                >Zip
                              </label>
                              <i
                                *ngIf="zipValidate"
                                tooltipZIndex="12000"
                                [pTooltip]="zipCodeMessage"
                                class="fa fa-info-circle color-red zip-alert"
                              >
                              </i>
                            </span>
                          </div>
                        </div>

                        <div
                          class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3"
                        >
                          <div class="section-bg">
                            <div class="form-group">
                              <div class="row">
                                <div
                                  class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                >
                                  <h3 class="pt">Tax options</h3>
                                </div>
                                <div class="col-md-12 col-sm-12 col-xs-12">
                                  <div class="form-group">
                                    <kendo-floatinglabel text="Trades">
                                      <kendo-multiselect
                                        [data]="lstTrade"
                                        [filterable]="true"
                                        (filterChange)="filterTrades($event)"
                                        [textField]="'TradeName'"
                                        [ngClass]="{'has-error':Trades.invalid && (Trades.dirty || Trades.touched)}"
                                        [valueField]="'ID'"
                                        #Trades="ngModel"
                                        name="Trades"
                                        [(ngModel)]="contractor.Trades"
                                      >
                                      </kendo-multiselect>
                                    </kendo-floatinglabel>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3"
                        >
                          <div class="section-bg">
                            <div class="form-group">
                              <div class="row">
                                <div
                                  class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                >
                                  <h3 class="pt">Primary Scheduling Contact</h3>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-6 mb-3">
                                  <div class="form-group mb0">
                                    <span class="ui-float-label">
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="SchedulerContactName"
                                        name="SchedulerContactFirstName"
                                        [(ngModel)]="contractor.SchedulerContactFirstName"
                                        pInputText
                                        trim="blur"
                                        #SchedulerContactFirstName="ngModel"
                                        [ngClass]="{'has-error':SchedulerContactFirstName.invalid && (SchedulerContactFirstName.dirty || SchedulerContactFirstName.touched)}"
                                        maxlength="150"
                                        preventBackSlashAndDoubleQuote
                                      />
                                      <label
                                        for="SchedulerContactFirstName"
                                        class="form-control-label"
                                        >First Name
                                      </label>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-6 mb-3">
                                  <div class="form-group mb0">
                                    <span class="ui-float-label">
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="SchedulerContactName"
                                        name="SchedulerContactLastName"
                                        [(ngModel)]="contractor.SchedulerContactLastName"
                                        pInputText
                                        trim="blur"
                                        #SchedulerContactLastName="ngModel"
                                        [ngClass]="{'has-error':SchedulerContactLastName.invalid && (SchedulerContactLastName.dirty || SchedulerContactLastName.touched)}"
                                        maxlength="150"
                                        preventBackSlashAndDoubleQuote
                                      />
                                      <label
                                        for="SchedulerContactLastName"
                                        class="form-control-label"
                                        >Last Name
                                      </label>
                                    </span>
                                  </div>
                                  <!-- <div class="input-pentair-std">


                                  </div> -->
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-12 mb-3">
                                  <div class="form-group mb0">
                                    <span class="ui-float-label">
                                      <input
                                        [(ngModel)]="contractorPhone"
                                        [disabled]="!contractor"
                                        type="tel"
                                        class="form-control"
                                        name="Phone"
                                        pInputText
                                        maxlength="50"
                                        #SchedulerContactPhone="ngModel"
                                      />
                                      <!-- <p-inputMask
                                        [(ngModel)]="contractor.SchedulerContactPhone"
                                        mask="999-999-9999"
                                        slotChar=" "
                                        autoClear="false"
                                        styleClass="form-control"
                                        name="SchedulerContactPhone"
                                        #SchedulerContactPhone="ngModel"
                                      ></p-inputMask> -->
                                      <label
                                        for="text"
                                        class="form-control-label"
                                        >Phone</label
                                      >
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <h6>Primary Scheduling Contact</h6> -->
                        </div>

                        <div class="col-sm-12">
                          <div class="section-bg">
                            <div class="form-group">
                              <div class="row">
                                <div
                                  class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                >
                                  <h3 class="pt">Notification Preference</h3>
                                </div>

                                <div class="col-md-12 col-sm-12 col-xs-12 mb-3">
                                  <div
                                    class="d-flex vendor-contractor-form-container__notification-preference"
                                  >
                                    <div class="checkbox">
                                      <label
                                        for="ReceivesEmailNotifications"
                                        class="custom-checkbox h16"
                                      >
                                        <input
                                          type="checkbox"
                                          id="ReceivesEmailNotifications"
                                          name="ReceivesEmailNotifications"
                                          [(ngModel)]="contractor.ReceivesEmailNotifications"
                                        />
                                        <span></span>
                                      </label>
                                    </div>
                                    <div class="form-group">
                                      <span class="ui-float-label">
                                        <input
                                          type="email"
                                          class="form-control"
                                          [disabled]="!contractor.ReceivesEmailNotifications"
                                          id="email"
                                          name="SchedulerEmail"
                                          [(ngModel)]="contractor.SchedulerEmail"
                                          pInputText
                                          email="true"
                                          trim="blur"
                                          #SchedulerEmail="ngModel"
                                          [ngClass]="{'has-error':SchedulerEmail.invalid && (SchedulerEmail.dirty || SchedulerEmail.touched)}"
                                          maxlength="150"
                                          [pattern]="emailPattern"
                                        />
                                        <label
                                          for="Email"
                                          class="form-control-label"
                                          >Email</label
                                        >
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="col-md-12 col-sm-12 col-xs-12 form-container__row--items mb-3"
                                >
                                  <div
                                    class="d-flex vendor-contractor-form-container__notification-preference"
                                  >
                                    <div class="checkbox">
                                      <label
                                        for="ReceivesSMSNotifications"
                                        class="custom-checkbox h16"
                                      >
                                        <input
                                          type="checkbox"
                                          id="ReceivesSMSNotifications"
                                          name="ReceivesSMSNotifications"
                                          [(ngModel)]="contractor.ReceivesSMSNotifications"
                                        />
                                        <span></span>
                                      </label>
                                    </div>
                                    <div class="form-group">
                                      <span class="ui-float-label">
                                        <p-inputMask
                                          [(ngModel)]="contractor.SchedulerSMSPhone"
                                          [disabled]="!contractor.ReceivesSMSNotifications"
                                          mask="999-999-9999"
                                          slotChar=" "
                                          autoClear="false"
                                          styleClass="form-control"
                                          name="SchedulerSMSPhone"
                                          #SchedulerSMSPhone="ngModel"
                                        ></p-inputMask>
                                        <label
                                          for="text"
                                          class="form-control-label"
                                          >SMS Text</label
                                        >
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-------------------------EDIT CONTRACTOR------------------------------>

                    <div *ngIf="vendorId !== 0">
                      <div class="row mt15 vendor-contractor-form-container">
                        <div
                          class="col-md-6 col-sm-6 col-xs-12 mb-3"
                          *ngIf="!contractor.IsEditable"
                        >
                          <div class="form-group mb0">
                            <span class="ui-float-label">
                              <input
                                type="text"
                                class="form-control"
                                id="VendorName"
                                disabled
                                name="VendorName"
                                pInputText
                                [(ngModel)]="contractor.VendorName"
                                #VendorName="ngModel"
                              />
                              <label for="text" class="form-control-label">
                                Contractor Name
                                <span class="text-red">*</span>
                              </label>
                            </span>
                          </div>
                        </div>
                        <div
                          class="col-md-6 col-sm-6 col-xs-12 mb-3"
                          *ngIf="contractor.IsEditable"
                        >
                          <div class="form-group mb0">
                            <span class="ui-float-label">
                              <input
                                type="text"
                                class="form-control"
                                id="company"
                                name="company"
                                pInputText
                                [(ngModel)]="contractor.Organization.OrganizationName"
                                [ngClass]="{'has-error':company.invalid && (company.dirty || company.touched)}"
                                maxlength="200"
                                #company="ngModel"
                                required
                              />
                              <label for="text" class="form-control-label">
                                Company Name
                                <span class="text-red">*</span>
                              </label>
                            </span>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-3">
                          <div class="form-group mb0">
                            <span class="ui-float-label">
                              <input
                                [(ngModel)]="organizationPhone"
                                [disabled]="!contractor || !contractor.Organization"
                                type="tel"
                                class="form-control"
                                name="Phone"
                                pInputText
                                maxlength="50"
                                #Phone="ngModel"
                              />
                              <!-- <p-inputMask
                                [(ngModel)]="contractor.Organization.Phone"
                                [disabled]="!contractor.IsEditable"
                                mask="999-999-9999"
                                slotChar=" "
                                autoClear="false"
                                styleClass="form-control inputmask"
                                name="Phone"
                                #Phone="ngModel"
                              ></p-inputMask> -->
                              <label for="text" class="form-control-label"
                                >Phone</label
                              >
                            </span>
                          </div>
                        </div>

                        <div class="col-md-12 col-sm-12 col-xs-12 mb-3">
                          <div class="form-group mb0">
                            <span class="ui-float-label">
                              <input
                                type="email"
                                class="form-control"
                                id="email"
                                name="email"
                                [disabled]="!contractor.IsEditable"
                                [(ngModel)]="contractor.Organization.Email"
                                pInputText
                                email="true"
                                trim="blur"
                                #email="ngModel"
                                [ngClass]="{'has-error':email.invalid && (email.dirty || email.touched)}"
                                maxlength="200"
                                [pattern]="emailPattern"
                                required
                              />
                              <label for="Email" class="form-control-label">
                                {{'common.email'|translate}}
                                <span class="text-red">*</span>
                              </label>
                            </span>
                          </div>
                        </div>

                        <div class="col-md-12 col-sm-12 col-xs-12 mb-3">
                          <div class="form-group mb0">
                            <span class="ui-float-label">
                              <input
                                type="text"
                                class="form-control"
                                id="Address"
                                name="Address"
                                pInputText
                                [disabled]="!contractor.IsEditable"
                                [(ngModel)]="contractor.Organization.Address"
                                maxlength="250"
                                #Address="ngModel"
                              />
                              <label for="text" class="form-control-label">
                                Address
                              </label>
                            </span>
                          </div>
                        </div>

                        <div class="col-md-6 col-sm-6 col-xs-12 mb-3">
                          <div class="form-group mb0">
                            <span class="ui-float-label">
                              <input
                                type="text"
                                class="form-control"
                                id="City"
                                name="City"
                                pInputText
                                [disabled]="!contractor.IsEditable"
                                [(ngModel)]="contractor.Organization.City"
                                maxlength="200"
                                #City="ngModel"
                              />
                              <label for="text" class="form-control-label">
                                City
                              </label>
                            </span>
                          </div>
                        </div>

                        <div class="col-md-6 col-sm-6 col-xs-12 mb-3">
                          <div
                            class="form-group mb0 vendor-contractor-form-container__state"
                          >
                            <kendo-floatinglabel text="State">
                              <kendo-combobox
                                [data]="stateList"
                                [valuePrimitive]="true"
                                [filterable]="true"
                                (filterChange)="filterState($event)"
                                [textField]="'Abbreviation'"
                                [disabled]="!contractor.IsEditable"
                                [(ngModel)]="contractor.Organization.State"
                                name="State"
                                [valueField]="'Id'"
                              >
                              </kendo-combobox>
                            </kendo-floatinglabel>
                          </div>
                        </div>
                        <div class="col-md-6 col-sm-6 col-xs-12 mb-3">
                          <div class="form-group mb0">
                            <span class="ui-float-label">
                              <input
                                #inputZip
                                type="text"
                                class="form-control"
                                id="Zip"
                                name="Zip"
                                pInputText
                                oninput="this.value = this.value.toUpperCase()"
                                [disabled]="!contractor.IsEditable"
                                [(ngModel)]="contractor.Organization.Zip"
                                [ngClass]="{
                                'has-error': zipValidate
                              }"
                              />
                              <label for="text" class="form-control-label"
                                >Zip
                              </label>

                              <i
                                *ngIf="zipValidate"
                                tooltipZIndex="12000"
                                [pTooltip]="zipCodeMessage"
                                class="fa fa-info-circle color-red zip-alert"
                              >
                              </i>
                            </span>
                          </div>
                        </div>

                        <div class="col-sm-12">
                          <div class="section-bg">
                            <div class="form-group">
                              <div class="row">
                                <div
                                  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3"
                                >
                                  <h3 class="pt">Tax Options</h3>
                                </div>
                                <div class="col-md-12 col-sm-12 col-xs-12">
                                  <div class="form-group">
                                    <kendo-floatinglabel text="Trades">
                                      <kendo-multiselect
                                        [data]="lstTrade"
                                        [filterable]="true"
                                        (filterChange)="filterTrades($event)"
                                        [textField]="'TradeName'"
                                        [ngClass]="{'has-error':Trades.invalid && (Trades.dirty || Trades.touched)}"
                                        [valueField]="'ID'"
                                        #Trades="ngModel"
                                        name="Trades"
                                        [(ngModel)]="contractor.Trades"
                                      >
                                      </kendo-multiselect>
                                    </kendo-floatinglabel>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3"
                        >
                          <div class="section-bg">
                            <div class="form-group">
                              <div class="row">
                                <div
                                  class="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                                >
                                  <h3 class="pt">Primary Scheduling Contact</h3>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-6 mb-3">
                                  <div class="form-group mb0">
                                    <span class="ui-float-label">
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="SchedulerContactName"
                                        name="SchedulerContactFirstName"
                                        [(ngModel)]="contractor.SchedulerContactFirstName"
                                        pInputText
                                        trim="blur"
                                        #SchedulerContactFirstName="ngModel"
                                        [ngClass]="{'has-error':SchedulerContactFirstName.invalid && (SchedulerContactFirstName.dirty || SchedulerContactFirstName.touched)}"
                                        maxlength="150"
                                        preventBackSlashAndDoubleQuote
                                      />
                                      <label
                                        for="SchedulerContactFirstName"
                                        class="form-control-label"
                                        >First Name</label
                                      >
                                    </span>
                                  </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-6 mb-3">
                                  <div class="form-group mb0">
                                    <span class="ui-float-label">
                                      <input
                                        type="text"
                                        class="form-control"
                                        id="SchedulerContactName"
                                        name="SchedulerContactLastName"
                                        [(ngModel)]="contractor.SchedulerContactLastName"
                                        pInputText
                                        trim="blur"
                                        #SchedulerContactLastName="ngModel"
                                        [ngClass]="{'has-error':SchedulerContactLastName.invalid && (SchedulerContactLastName.dirty || SchedulerContactLastName.touched)}"
                                        maxlength="150"
                                        preventBackSlashAndDoubleQuote
                                      />
                                      <label
                                        for="SchedulerContactLastName"
                                        class="form-control-label"
                                        >Last Name</label
                                      >
                                    </span>
                                  </div>
                                </div>
                                <div class="col-md-6 col-sm-6 col-xs-12 mb-3">
                                  <div class="form-group mb0">
                                    <span class="ui-float-label">
                                      <input
                                        [(ngModel)]="contractorPhone"
                                        [disabled]="!contractor"
                                        type="tel"
                                        class="form-control"
                                        name="Phone"
                                        pInputText
                                        maxlength="50"
                                        #SchedulerContactPhone="ngModel"
                                      />
                                      <!--<p-inputMask
                                         [(ngModel)]="contractor.SchedulerContactPhone"
                                        mask="999-999-9999"
                                        slotChar=" "
                                        autoClear="false"
                                        styleClass="form-control"
                                        name="SchedulerContactPhone"
                                        #SchedulerContactPhone="ngModel"
                                      ></p-inputMask> -->
                                      <label
                                        for="text"
                                        class="form-control-label"
                                        >Phone</label
                                      >
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-sm-12">
                          <div class="section-bg">
                            <div class="form-group">
                              <div class="row">
                                <div
                                  class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-3"
                                >
                                  <h3 class="pt">Notification Preference</h3>
                                </div>

                                <div class="col-md-12 col-sm-12 col-xs-12 mb-3">
                                  <div
                                    class="d-flex vendor-contractor-form-container__notification-preference"
                                  >
                                    <div class="checkbox">
                                      <label
                                        for="ReceivesEmailNotifications"
                                        class="custom-checkbox h16"
                                      >
                                        <input
                                          type="checkbox"
                                          id="ReceivesEmailNotifications"
                                          name="ReceivesEmailNotifications"
                                          [(ngModel)]="contractor.ReceivesEmailNotifications"
                                        />
                                        <span></span>
                                      </label>
                                    </div>
                                    <div class="form-group">
                                      <span class="ui-float-label">
                                        <input
                                          type="email"
                                          class="form-control"
                                          [disabled]="!contractor.ReceivesEmailNotifications"
                                          id="email"
                                          name="SchedulerEmail"
                                          [(ngModel)]="contractor.SchedulerEmail"
                                          pInputText
                                          email="true"
                                          trim="blur"
                                          #SchedulerEmail="ngModel"
                                          [ngClass]="{'has-error':SchedulerEmail.invalid && (SchedulerEmail.dirty || SchedulerEmail.touched)}"
                                          maxlength="150"
                                          [pattern]="emailPattern"
                                        />
                                        <label
                                          for="Email"
                                          class="form-control-label"
                                          >Email</label
                                        >
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-12 col-sm-12 col-xs-12 mb-3">
                                  <div
                                    class="d-flex vendor-contractor-form-container__notification-preference"
                                  >
                                    <div class="checkbox">
                                      <label
                                        for="ReceivesSMSNotifications"
                                        class="custom-checkbox h16"
                                      >
                                        <input
                                          type="checkbox"
                                          id="ReceivesSMSNotifications"
                                          name="ReceivesSMSNotifications"
                                          [(ngModel)]="contractor.ReceivesSMSNotifications"
                                        />
                                        <span></span>
                                      </label>
                                    </div>
                                    <div class="form-group">
                                      <span class="ui-float-label">
                                        <p-inputMask
                                          [(ngModel)]="contractor.SchedulerSMSPhone"
                                          [disabled]="!contractor.ReceivesSMSNotifications"
                                          mask="999-999-9999"
                                          slotChar=" "
                                          autoClear="false"
                                          styleClass="form-control"
                                          name="SchedulerSMSPhone"
                                          #SchedulerSMSPhone="ngModel"
                                        ></p-inputMask>
                                        <label
                                          for="text"
                                          class="form-control-label"
                                          >SMS Text</label
                                        >
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!----------- MPB Access Component ------------>
                    <div
                      class="row layout-vertical-center vendor-contractor-form-container__acess-component padding-right-1 padding-left-1"
                      id="mobileOptions"
                      *ngIf="!isQuickAdd"
                    >
                      <h3 class="pt mb-2 mt-2">Mobile Options</h3>
                      <div class="d-flex mb15">
                        <div class="form-group mb-0">
                          <div class="checkbox m-0">
                            <label class="k-form-field">
                              <input
                                type="checkbox"
                                id="HasMpbAccess"
                                [(ngModel)]="contractor.HasMpbAccess"
                                name=" HasMpbAccess"
                                class="k-checkbox"
                              />
                              <span class="k-checkbox-label" for="HasMpbAccess"
                                >Pentair Pool Builder Access</span
                              >
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row" [hidden]="!isSuperAdmin">
                      <div class="col-sm-12 pl-2 pr-2 mt-3">
                        <quickbooks-options
                          [identifier]="quickBooksSearch.identifier"
                          [form]="myForm"
                          [model]="contractor"
                        ></quickbooks-options>
                        <quickbooks-search-vendors
                          #quickBooksSearch
                        ></quickbooks-search-vendors>
                      </div>
                    </div>

                    <div class="row" *ngIf="!isQuickAdd">
                      <div
                        class="col-md-4 col-sm-4 col-xs-4"
                        *ngIf="contractor.HasMpbAccess"
                      >
                        <div class="checkbox">
                          <label
                            for="ShowLiveViews"
                            class="custom-checkbox h16"
                          >
                            <input
                              type="checkbox"
                              id="ShowLiveViews"
                              name="ShowLiveViews"
                              [(ngModel)]="contractor.ShowLiveViews"
                            />
                            <span>Live View</span>
                          </label>
                        </div>
                      </div>
                      <div
                        class="col-md-4 col-sm-4 col-xs-4"
                        *ngIf="contractor.HasMpbAccess"
                      >
                        <div class="checkbox">
                          <label
                            for="ShowCustomerInfo"
                            class="custom-checkbox h16"
                          >
                            <input
                              type="checkbox"
                              id="ShowCustomerInfo"
                              name="ShowCustomerInfo"
                              [(ngModel)]="contractor.ShowCustomerInfo"
                            />
                            <span>View Customer Info</span>
                          </label>
                        </div>
                      </div>
                      <div
                        class="col-md-4 col-sm-4 col-xs-4"
                        *ngIf="contractor.HasMpbAccess"
                      >
                        <div class="checkbox">
                          <label
                            for="ShowPunchlist"
                            class="custom-checkbox h16"
                          >
                            <input
                              type="checkbox"
                              id="ShowPunchlist"
                              name="ShowPunchlist"
                              [(ngModel)]="contractor.ShowPunchlist"
                            />
                            <span>View Punch List</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="h50"></div>
                    <div class="fixbtnwrap">
                      <div class="btnwrap__center">
                        <button
                          type="button"
                          (click)="onSubmit(myForm)"
                          class="btn darkbtn"
                          [disabled]="!myForm.valid || hideSaveButton "
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          class="cross btn bthpn2"
                          (click)="onCancel()"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </kendo-tabstrip-tab>

              <kendo-tabstrip-tab title="Contacts" *ngIf="vendorId !== 0">
                <ng-template kendoTabContent>
                  <div class="mb22">
                    <div *ngIf="isExisting">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="addbtn rightbtn">
                            <button
                              class="btn btn-info d-flex align-items-center"
                            >
                              <span class="addtxt"> Contacts </span>
                              <i
                                aria-hidden="true"
                                class="fa fa-plus-circle"
                                title="Add Contact"
                                (click)="addContact()"
                              ></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-sm-12">
                          <kendo-grid
                            [data]="contractorContacts"
                            #grid="kendoGrid"
                            id="contactgrid"
                          >
                            <kendo-grid-column
                              headerClass="gridHeading"
                              class="gridRow"
                              title="{{''|translate}}"
                              width="30"
                            >
                              <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="dropdown cud">
                                  <i
                                    class="fa fa-ellipsis-v"
                                    id="menu1"
                                    data-toggle="dropdown"
                                  ></i>
                                  <ul
                                    class="dropdown-menu"
                                    aria-labelledby="menu1"
                                  >
                                    <li>
                                      <a (click)="editContact(dataItem)">
                                        <i class="fa fa-pencil"></i>
                                        {{'common.edit'|translate}}
                                      </a>
                                    </li>
                                    <li>
                                      <a (click)="deleteContact(dataItem)">
                                        <i class="fa fa-trash-o"></i>
                                        {{'common.delete'|translate}}
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        (click)="resetPassword(dataItem.contact.userName)"
                                      >
                                        <i
                                          class="fa fa-unlock-alt"
                                          aria-hidden="true"
                                        ></i>
                                        {{'common.resetpassword'|translate}}
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column
                              headerClass="gridHeading"
                              class="gridRow"
                              title="Name"
                              field="contact.fullName"
                            >
                            </kendo-grid-column>
                            <kendo-grid-column
                              headerClass="gridHeading"
                              class="gridRow"
                              title="Phone"
                              field="contact.phoneNumber"
                              width="100"
                            >
                            </kendo-grid-column>
                            <kendo-grid-column
                              headerClass="gridHeading"
                              class="gridrowalignr"
                              title="Email"
                              field="contact.email"
                            >
                            </kendo-grid-column>
                          </kendo-grid>
                        </div>
                      </div>
                    </div>
                    <div class="h50"></div>
                    <div class="fixbtnwrap">
                      <div class="btnwrap__center">
                        <button
                          type="button"
                          (click)="onSubmit(myForm)"
                          class="btn darkbtn"
                          [disabled]="!myForm.valid"
                          *ngIf="myForm.dirty"
                        >
                          <i class="fa fa-floppy-o"></i> {{btnText}}
                        </button>
                        <button
                          type="button"
                          class="cross btn bthpn2"
                          (click)="onCancel()"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </kendo-tabstrip-tab>

              <kendo-tabstrip-tab
                title="License & Bonding"
                *ngIf="vendorId !== 0"
              >
                <ng-template kendoTabContent>
                  <div class="mb22">
                    <div class="form-group border-botom-line">
                      <label for="text" class="form-control-label clabel"
                        >License & Bonding Notes</label
                      >
                      <textarea
                        rows="6"
                        class="form-control"
                        [(ngModel)]="contractor.LicenseBonding"
                        name="LicenseBonding"
                        #LicenseBonding="ngModel"
                      ></textarea>
                    </div>
                    <app-jobfile-info-card
                      #filesListCard
                      [isVendor]="true"
                      [vendorId]="vendorId"
                      [useOwnAddSidebar]="true"
                      [showMiddleBar]="true"
                      [showFullHeader]="true"
                    >
                    </app-jobfile-info-card>
                    <div class="h50"></div>
                    <div class="fixbtnwrap">
                      <div class="btnwrap__center">
                        <button
                          type="button"
                          (click)="onSubmit(myForm)"
                          class="btn darkbtn"
                          [disabled]="!myForm.valid"
                          *ngIf="myForm.dirty"
                        >
                          <i class="fa fa-floppy-o"></i> {{btnText}}
                        </button>
                        <button
                          type="button"
                          class="cross btn bthpn2"
                          (click)="onCancel()"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </kendo-tabstrip-tab>
            </kendo-tabstrip>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  [ngClass]="showSlides != '' ? 'add-contact-sidebar sidebar2 open ' : 'add-contact-sidebar sidebar2'"
>
  <a class="cancelbtnHead ui-sidebar-close" (click)="showSlides = ''">
    <span class="pi pi-times"></span>
  </a>
  <ContractorContact-Component
    *ngIf="showSlides == 'showAddContractorContact'"
    [contactdata]="contactData"
    [contactGuid]="contactGuid"
    [OrgVendorId]="contractorGuid"
    (updateData)="updateData($event)"
  ></ContractorContact-Component>
  <app-setup-password
    *ngIf="showSlides == 'resetpassword'"
    [userName]="passwordResetUserName"
    (updateEvent)="updateEvent.emit($event)"
  ></app-setup-password>
</div>
