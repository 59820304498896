import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SettingService } from "../services/settings.service";
import { SharedService } from "./SharedService";
import { RedirectDashboardUrl } from "./Redirect.function";

@Component({
  template: "",
})
export class RedirectComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private settingService: SettingService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    RedirectDashboardUrl(this.settingService, this.sharedService, this.router);
  }
}
