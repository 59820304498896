import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UpdateJobFilesService {
  private _changeHasOcurred = new BehaviorSubject<boolean>(false);
  get changeHasOcurred(): Observable<boolean> {
    return this._changeHasOcurred.asObservable();
  }
  constructor() {}

  notifyChangeHasOcurred(trigger: boolean) {
    this._changeHasOcurred.next(trigger);
  }
}
