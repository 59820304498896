import { Injectable } from "@angular/core";
import { environmentConstant } from "../utility/environment";

// TODO: move all calls to LocalStorage here.
// in doing so, eliminate all usages of magic
// strings like userName and lUserName throughout the app.

export const tokens = {
  SALES_FUNNEL_COLLAPSED: "sales_funnel_collapsed",
  SALES_FUNNEL_ALL_CARDS_ARE_COLLAPSED: "sales_funnel_all_cards_are_collapsed",
  BIGBOARD_PINNED_IDS: "bigboard_pinned_ids",
};

@Injectable()
export class LocalStorageService {
  // clear() should clear everything in localStorage except for these keys
  private static readonly PERSISTED_KEYS_ON_CLEAR = [
    "userName",
    "lUserName",
    "WIP_Filter",
    "Sales_Filter",
    tokens.SALES_FUNNEL_COLLAPSED,
    tokens.SALES_FUNNEL_ALL_CARDS_ARE_COLLAPSED,
    environmentConstant.localStorageKeys.MENU,
    "catalogSortBy",
    "catalogSortDirection",
  ];

  clear() {
    let persistedMap = {};
    const persistedKeys = LocalStorageService.PERSISTED_KEYS_ON_CLEAR;

    // save the local storage items to be kept after clear
    persistedKeys.forEach((key) => {
      persistedMap[key] = localStorage.getItem(key);
    });

    localStorage.clear();

    // restore local storage items that were to be kept after clear
    persistedKeys.forEach((key) => {
      const value = persistedMap[key];
      if (value) {
        localStorage.setItem(key, value);
      }
    });
  }

  persistObject<T>(key: string, object: T) {
    localStorage.setItem(key, JSON.stringify(object));
  }

  serializeObject<T>(key: string): T {
    if (!localStorage.getItem(key)) {
      return null;
    }
    return JSON.parse(localStorage.getItem(key));
  }
}
