import { SelectItem } from "primeng/primeng";
import { Calculator } from "./CalculatorErrorsData";
import { EstimateCategoryModel } from "./EstimateCategoryModel";
import { EstimateProductTypeModel } from "./EstimateProductTypeModel";
import { EstimateViewGroup } from "./EstimateViewGroup";
import { KitDetails, ProductTypeEnum } from "./KitDetails";
import { MaterialCalculator } from "./MaterialsCalculator";
import { PricebookProductFromDB, ProductPriceChange } from "./PriceBookDetails";
import { ProductEstimateView } from "./ProductEstimateView";
import { ProductOption } from "./ProductOptionModel";
import { UnitOfMeasure } from "./UnitOfMeasure";

class EstimateManufacturerModel {
  id: number;
  name: string;
  description: string;
}

class EstimateTaxModel {
  id: number;
  name: string;
  displayOrder: number;
}

export class EstimateProductModel {
  id: number;
  name: string;
  shortDescription: string;
  fullDescription: string;
  sku: string;
  price: number;
  published: boolean;
  userAgreementText: string;
  userWarrantyText: string;
  userDisclaimerText: string;
  hasUserAgreement: boolean;
  hasWarranty: boolean;
  hasDisclaimer: boolean;
  isAvailableInBudgetView: boolean;
  isFeaturedProduct: boolean;
  isTaxExempt: boolean;
  categories: EstimateCategoryModel[];
  estimateViewGroups: EstimateViewGroup[];
  productEstimateExt?: ProductEstimateExt;
  productOptionId?: number;
  productOptions: ProductOption[];
  manufacturer: EstimateManufacturerModel;
  manufacturerPartNumber: string;
  sync: boolean;
  productTypeId: number;
  productType: EstimateProductTypeModel;
  quickBooksIdentifier: number;
  taxCategory: EstimateTaxModel;
  useKitPricing?: boolean;
  usePriceBookPricing: boolean;
  requiresUserSelection?: boolean;
  accountObject: string;
  accountSubsidiary: string;
  unitOfMeasure: UnitOfMeasure;
  laborServiceNumber: string;
  orgGuid: string;
  createdBy: string;
  manufacturerId: number;
  group?: number;
  groupName?: string;
  productPriceChange?: ProductPriceChange;
  onlyUseWithKits?: boolean;
  taxCategoryId?: number;
  productOptionsMap?: SelectItem[];
  originalPrice?: number;
  productWorksheetViewGroup?: ProductEstimateView[];
  priceBookDetails?: PricebookProductFromDB[];
  productTypeName?: string;
  priceBookDetailId: number;
  priceBookPrice?: number;
  deleted?: boolean;
  showInEquipmentProfile: boolean;
  // Active price depending on pricebook, kit, and options
  activePrice: number;
  priceToShow: number;
  calculator?: Calculator;
  kitDetailKitProducts?: KitDetails[];
  listCategories?: string;
  listGroups?: string;
  notes: string;
  showNotes: boolean;
  excludePriceAdjustment: boolean;

  constructor() {}

  get isKit(): boolean {
    return (
      this.productTypeId === ProductTypeEnum.SimpleKit ||
      this.productTypeId === ProductTypeEnum.ComplexKit
    );
  }
  guid?: string;
  showOnProposal: boolean;
}

export interface ProductEstimateExt {
  productId: number;
  preferredVendorId?: number;
  budgetVendorId?: number;
  visibleOnEstimate?: boolean;
  useMostExpensiveVendor?: boolean;
  requiresUserSelection?: boolean;
  onlyUseWithKits?: boolean;
  constructionPhaseId?: boolean;
  lastUpdatedOn: string;
  createdOn: string;
  materialsCalculatorId?: number;
  materialsCalculator: MaterialCalculator;
  useCalculator: boolean;
  calculator?: Calculator;
  canEditCost?: boolean;
  canEditProductName?: boolean;
}
