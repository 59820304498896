<div class="modal-dialog modal-md pentair-dialog">
  <div class="modal-content pentair-dialog-content">
    <div class="modal-header pentair-dialog-header">
      <button type="button" class="close" (click)="close()">&times;</button>
      <h4 class="modal-title pentair-dialog-title pb-3">{{title || 'Confirm'}}</h4>
    </div>
    <div class="modal-body pentair-dialog-body">
      <p class="mb-2">{{message || 'Are you sure?'}}</p>
      <p *ngIf="message2 != ''">{{message2}}</p>
    </div>
    <div class="modal-footer mt-3 d-flex layou-flex-end">
      <button
        type="button"
        class="btn darkbtn dpbtn"
        (click)="editSave()"
        *ngIf="toShow"
      >
        Save & Edit
      </button>
      <button
        type="button"
        class="button button--secondary"
        *ngIf="!isNotification"
        (click)="close()"
      >
        Cancel
      </button>
      <button type="button" class="button button--primary" (click)="confirm()">
        Confirm
      </button>
    </div>
  </div>
</div>
