import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ToDoListDto } from "app/model/ToDoListDto";
import { SharedService } from "app/common/utility/SharedService";
import * as _ from "lodash";
import { UserModel } from "app/model/Core/UserModel";
import { TodoPermissionsService } from "../todo-permissions.service";

@Component({
  selector: "todo-items",
  templateUrl: "./todo-items.component.html",
  styleUrls: ["./todo-items.component.scss"],
})
export class TodoItemsComponent implements OnInit {
  constructor(
    private sharedService: SharedService,
    private todoPermissionsService: TodoPermissionsService
  ) {}

  loggedInUser: UserModel;

  _items: ToDoListDto[] = [];

  @Input()
  set items(value: ToDoListDto[]) {
    this._items = value;
    this.todoPermissionsService.setTaskPermissionsMap(value);
  }
  get items(): ToDoListDto[] {
    return this._items;
  }

  @Output() onEditPhase = new EventEmitter<ToDoListDto>();
  @Output() onChangeStatus = new EventEmitter<ToDoListDto>();
  @Output() onDelete = new EventEmitter<ToDoListDto>();

  ngOnInit() {
    this.loggedInUser = this.sharedService.user;
  }

  getTaskPermissions(task: ToDoListDto) {
    return this.todoPermissionsService.getPermissionsForTask(task);
  }

  hasPermissionToUseAction(task: ToDoListDto) {
    const permissions = this.getTaskPermissions(task);
    return task.groupingStatus == "Not Started"
      ? permissions.start
      : task.groupingStatus != "Completed"
      ? permissions.complete
      : permissions.close;
  }

  deleteTask(task: ToDoListDto) {
    this.onDelete.emit(task);
  }

  getStatusClass(phase: ToDoListDto) {
    var q = new Date();
    var m = q.getMonth();
    var d = q.getDate();
    var y = q.getFullYear();
    var date = new Date(y, m, d);
    var endDate: any;
    if (phase.EstEndDate) {
      endDate = new Date(
        new Date(phase.EstEndDate).getFullYear(),
        new Date(phase.EstEndDate).getMonth(),
        new Date(phase.EstEndDate).getDate()
      );
    }
    return phase.Status == 3
      ? "completed"
      : phase.Status == 2
      ? endDate < date
        ? phase.JobTypeId != 7
          ? "pastDue"
          : "inProgress"
        : "inProgress"
      : "notStarted";
  }

  shouldShowTaskOwner(phase: ToDoListDto) {
    return phase.OwnerId != null && phase.OwnerId !== this.loggedInUser.id;
  }

  shouldShowTaskAssigned(phase: ToDoListDto) {
    return phase.AssignedToId && phase.AssignedToId !== this.loggedInUser.id;
  }

  getIfCompletedToday(phase: ToDoListDto) {
    if (phase.EstEndDate && phase.Status == 3) {
      var endDate = new Date(
        new Date(phase.EstEndDate).getFullYear(),
        new Date(phase.EstEndDate).getMonth(),
        new Date(phase.EstEndDate).getDate()
      );
      var currentdate = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      );
      if (endDate.toLocaleDateString() == currentdate.toLocaleDateString()) {
        return "strikethrough";
      }
    }
    return "";
  }

  getInitials(str) {
    if (!str) return "";
    var result = str
      .split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), "");
    if (result && result.length > 2) {
      return result.slice(0, 2);
    }
    return result;
  }
}
