import { Component, ChangeDetectorRef } from "@angular/core";
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
export interface ConfirmModel {
  title: string;
  message: string;
  message2?: string;
  toShow?: boolean;
  isNotification?: boolean;
}
@Component({
  selector: "confirm",
  templateUrl: "./dialog.html",
  styleUrls: ["./dialog.component.scss"],
})
export class ConfirmComponent
  extends DialogComponent<ConfirmModel, boolean>
  implements ConfirmModel
{
  title: string;
  message: string;
  message2: string;
  toShow?: boolean = false;
  isNotification?: boolean = false;
  changeDetectorRef: ChangeDetectorRef;
  constructor(
    dialogService: DialogService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(dialogService);
    this.changeDetectorRef = changeDetectorRef;
    const that = this;
    setTimeout(function () {
      that.changeDetectorRef.detectChanges();
    }, 300);
  }
  confirm() {
    // we set dialog result as true on click on confirm button,
    // then we can get dialog result from caller code
    this.result = true;
    this.close();
  }
  editSave() {
    this.result = false;
    this.close();
  }
}
