import { CustomerModel } from "./customerModel";
import { FormGroup } from "@angular/forms";

export class JobFileModel {
  ID: number;
  Guid: string;
  FileName: string;
  Extension: string;
  JobFileTypeId: number;
  JobGUID: string;
  JobPhaseId: number;
  BlobId: number;
  BlobFileName: string;
  BlobUrl: string;
  Comments: boolean;
  Tags: boolean;
  ThumbnailFileName?: string;
  ThumbnailUrl?: string;
  JobFileTags?: JobFileTag[];
  JobFileNotes?: JobFileNote[];
  BlobBlobContainer: string;
  CreatedOn: Date;
  FileTypeName: string;
  Title: string;
  Description: string;
  UploadedBy?: CustomerModel;
  IsJobThumbnail: boolean;
  IsPinned: boolean = false;
  selected: boolean = false;
  JobPhaseName: string;
  BlobGuid?: string;
  ContractorCanView?: boolean;
  CustomerCanView?: boolean;

  JobChangeOrderId?: number;

  form?: FormGroup;
  isEditing?: boolean;
  constructor() {}
}

export class JobFileTag {
  Id: number;
  CreatedOn: Date;
  FileTag: string;
  OrgGuid: string;
  IsSystem: boolean;
  SystemName: string;
  DeletedOn: Date;
}

export class JobFileNote {
  Guid: string;
  Title: string;
  Note: string;
  CreatedBy: CustomerModel;
  DeletedBy: CustomerModel;
  JobFileGuid: string;
  Created: Date;
}

export class JobFileNoteUI {
  Guid: string;
  Title: string;
  Note: string;
  CreatedBy: CustomerModel;
  DeletedBy: CustomerModel;
  JobFileGuid: string;
  Created: Date;
  DateString: string;
  CustomerInitials: string;
  canEdit: boolean;
}

export class UpdateJobFile {
  JobFileTags: JobFileTag[];
  DisplayCreatedOn: string;
  Title: string;
  Description: string;
  Public: boolean;
  Pinned: boolean;
  FileGuid: string;
  ContractorCanView: boolean;
  CustomerCanView: boolean;
}
