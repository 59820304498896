export class SchedulingRequest {
  StartDate: Date;
  EndDate: Date;
  JobPhaseId: number;
  JobId: string;
  RequestedById: number;
  PhaseOwnerId: number;
  Comments: string;
  DateRange: boolean;
}
export class SchedulingResponse {
  Id: number;
  ProposedDate: Date;
  JobPhaseId: number;
  JobId: string;
  RespondedBy: string;
}
export class JobPhaseSchedulingQueModel {
  JobName: string;
  JobPhase: string;
  StatusId: number;
  RequestStartDate: Date;
  RequestEndDate: Date;
  ScheduledDate: any;
  DateConfirmed: any;
  ConfirmedById: any;
  DateViewedByPhaseOwner: any;
  DateResponded: any;
  Comments: any;
  RequestedBy: number;
  DateRejected: any;
  InitialDateRequested: Date;
  PhaseOwnerId: number;
  PhaseOwner: any;
  Id: number;
  OrgGUID: string;
  JobId: string;
  RequestorName: string;
  JobPhaseId: number;
  UseContractorOrg: boolean;
}
