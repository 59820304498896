import { filter } from "lodash";
import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { BaThemeSpinner } from "../../../../theme/services";
import { CommonService } from "../../../../common/services/common.service";
import { SharedService } from "../../../../common/utility/SharedService";
import { ToDoListDto, QuickToDoDto } from "../../../../model/ToDoListDto";
import { DialogService } from "ng2-bootstrap-modal";
import { ConfirmComponent } from "app/component/dialog/dialog.component";
import {
  SchedulErrorDTO,
  ScheduleErrorDialogComponent,
} from "app/component/schedule-error-dialog/schedule-error-dialog.component";
import { MessageService } from "primeng/primeng";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: "app-todo-list",
  templateUrl: "./todo-list.component.html",
  styleUrls: ["./todo-list.component.scss"],
})
export class TodoListComponent implements OnInit {
  constructor(
    private _spinner: BaThemeSpinner,
    private notificationsService: MessageService,
    private dialogService: DialogService,
    private commonService: CommonService,
    private sharedService: SharedService
  ) {}
  @Input() JobId: any;
  @Output() updateEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() addEvent: EventEmitter<any> = new EventEmitter<any>();

  lstToDo: ToDoListDto[] = [];
  lstPhases: ToDoListDto[] = [];
  lstToDoOther: ToDoListDto[] = [];
  scheduleObj: SchedulErrorDTO;
  taskName: string;
  ngOnInit() {
    this.getToDoList();
  }

  getToDoList() {
    this._getToDoList().subscribe();
  }

  private _getToDoList() {
    this._spinner.show();
    if (!this.JobId) {
      const $myToDoList = this.commonService.getMyToDoList(
        this.sharedService.selectedOrganization.ID
      );
      const $othersToDoList = this.commonService.getOthersToDoList(
        this.sharedService.selectedOrganization.ID
      );
      return forkJoin([$myToDoList, $othersToDoList]).pipe(
        map(([mine, others]) => {
          this.lstPhases = mine.filter((x) => x.JobTypeId != 7);
          this.lstToDo = mine.filter((x) => x.JobTypeId == 7);
          this.lstToDoOther = others;
          [...mine, ...others].forEach((obj) => {
            obj.groupingStatus = this.getStatus(obj);
          });
          this._spinner.hide();
        })
      );
    } else {
      return this.commonService.getJobToDoList(this.JobId).pipe(
        map((data) => {
          this.lstToDo = [];
          this.lstToDo = data;
          var that = this;
          this.lstToDo.forEach((obj) => {
            obj.groupingStatus = that.getStatus(obj);
          });
          this._spinner.hide();
        })
      );
    }
  }
  getStatus(phase: ToDoListDto) {
    var q = new Date();
    var m = q.getMonth();
    var d = q.getDate();
    var y = q.getFullYear();
    var date = new Date(y, m, d);
    var endDate: any;
    if (phase.EstEndDate) {
      endDate = new Date(
        new Date(phase.EstEndDate).getFullYear(),
        new Date(phase.EstEndDate).getMonth(),
        new Date(phase.EstEndDate).getDate()
      );
    }
    return phase.Status == 3
      ? "Completed"
      : phase.Status == 2
      ? endDate < date
        ? phase.JobTypeId != 7
          ? "Past Due"
          : "In Progress"
        : "In Progress"
      : "Not Started";
  }

  AddTodoList() {
    this.addEvent.emit(true);
  }

  EditPhase(phase: ToDoListDto) {
    if (phase.JobTypeId != 7) this.updateEvent.emit(phase);
    else this.addEvent.emit(phase);
  }
  getPhaseType(JobTypeId) {
    var type = "";
    switch (JobTypeId) {
      case 2:
        type = "JP";

        break;
      case 3:
        type = "PL";

        break;
      case 4:
        type = "WO";
        break;
    }
    return type;
  }
  getPhaseTypeFull(JobTypeId) {
    var type = "";
    switch (JobTypeId) {
      case 2:
        type = "Job Phase";

        break;
      case 3:
        type = "Punch List";

        break;
      case 4:
        type = "Work Order";
        break;
    }
    return type;
  }
  ChangeStatus(phase: ToDoListDto) {
    var status = phase.Status == 2 ? "complete" : "start";
    this.dialogService
      .addDialog(ConfirmComponent, {
        title:
          (phase.Status == 2 ? "Complete " : "Start ") +
          this.getPhaseTypeFull(phase.JobTypeId),
        message: "Are you sure you want to " + status + " " + phase.PhaseName,
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          if (phase.Status == 2) {
            this.checkScheduleError(phase);
          } else {
            this.processRequest(phase);
          }
        }
      });
  }

  deletePhase(phase: ToDoListDto) {
    this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Delete",
        message: "Are you sure you want to delete " + phase.PhaseName,
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.commonService.deleteToDoItem(phase.ID).subscribe((result) => {
            if (result) {
              this._getToDoList().subscribe(() => {
                this.result(result, "Deleted Successfully!", "To Do Task");
              });
            }
          });
        }
      });
  }

  checkScheduleError(phase: ToDoListDto) {
    var date = new Date();
    date.setHours(0, 0, 0, 0);
    var startDate: any;
    if (phase.StartDate) {
      startDate = new Date(
        new Date(phase.StartDate).getFullYear(),
        new Date(phase.StartDate).getMonth(),
        new Date(phase.StartDate).getDate()
      );
    }
    if (date < startDate) {
      var data = new SchedulErrorDTO();
      data.EndDate = date;
      data.StartDate = startDate;
      this.dialogService
        .addDialog(ScheduleErrorDialogComponent, { Scm: data })
        .subscribe((datum) => {
          if (datum) {
            this.scheduleObj = datum;
            this.processRequest(phase);
          }
        });
    } else {
      this.processRequest(phase);
    }
  }
  processRequest(phase: ToDoListDto) {
    this._spinner.show();
    var status = phase.Status == 2 ? "complete" : "start";
    this.commonService
      .ChangePhaseStatus(
        phase.ID,
        status +
          (this.scheduleObj
            ? "?startDate=" +
              this.scheduleObj.StartDate.toLocaleDateString() +
              "&endDate=" +
              this.scheduleObj.EndDate.toLocaleDateString()
            : "")
      )
      .subscribe((data) => {
        this.getToDoList();
      });
  }

  quickToDo(form) {
    this._spinner.show();
    var model = new QuickToDoDto();
    model.OrgGUID = this.sharedService.selectedOrganization.ID;
    model.TaskName = this.taskName;
    model.JobId = this.JobId;
    var curretnDate = new Date();
    curretnDate = new Date(
      Date.UTC(
        curretnDate.getFullYear(),
        curretnDate.getMonth(),
        curretnDate.getDate()
      )
    );
    model.TaskDate = curretnDate;
    this.commonService.saveQuickTodoList(model).subscribe((result) => {
      this._getToDoList().subscribe(() => {
        this.result(result, "Added Successfully!!", "To Do Task");
      });
      form.reset();
    });
  }
  private result(res: any, message, title) {
    if (res !== null) {
      this.notificationsService.add({
        severity: "success",
        summary: title,
        detail: message,
      });
      this._spinner.hide();
    }
  }
  getInitals(str) {
    if (!str) return "";
    var result = str
      .split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), "");
    if (result && result.length > 2) {
      return result.slice(0, 2);
    }
    return result;
  }
}
