import {
  Component,
  Input,
  Output,
  OnInit,
  OnDestroy,
  Pipe,
  PipeTransform,
  EventEmitter,
} from "@angular/core";
import { ChatService } from "app/common/services/Chat.service";
import { GroupModel } from "app/model/Chat/GroupModel";
import { UserModel } from "app/model/Core/UserModel";
import { Subscription } from "rxjs";
import {
  ChatPanelContextModel,
  ChatPanelContextEnum,
} from "app/model/Chat/ChatPanelContextModel";
import { MatExpansionPanel } from "@angular/material/expansion";
import { ChatUserModel } from "app/model/Chat/ChatUserModel";

@Component({
  selector: "chat-manager",
  templateUrl: "./ChatManager.component.html",
  styles: [
    `
      :host {
        width: 100%;
        height: 100%;
        background: #2c3e50;
        color: #ffffff;
        overflow: hidden;
        position: relative;
      }

      :host p {
        font-weight: 400 !important;
      }

      .chat-top-bar {
        border-top: 1px solid #32465a;
        border-bottom: 1px solid #32465a;
        font-weight: 300;
        height: 50px;
        background: #2c3e50;
        margin: 0;
      }

      button.chat-add {
        color: #4ea755;
        background: #ffffff;
        border: none;
        border-radius: 10px;
        font-size: 16px;
        width: 20px;
        height: 20px;
        cursor: pointer;
        text-align: center;
        line-height: 20px;
        padding-left: 4px;
        position: absolute;
        right: 5px;
      }

      .chat-group-selector {
        height: 100%;
        width: 90%;
        position: absolute;
        z-index: 99;
      }

      .chat-top-bar label {
        position: absolute;
        margin: 5px 0 0 15px;
        color: #32465a;
      }
      .chat-top-bar input {
        padding: 0 0 0 35px;
        border: none;
        background: transparent;
        color: #32465a;
        height: 100%;
        width: 100%;
        background-color: white;
        border-radius: 5px;
      }

      .chat-top-bar .chat-search {
        margin: 0;
        padding: 10px;
        height: 50px;
        top: 0;
        width: 100%;
      }

      .chat-contacts {
        background: #2c3e50;
        overflow-y: scroll;
        overflow-x: hidden;
        margin: 0;
        height: calc(100% - 40px);
      }

      .chat-loading {
        width: 100%;
        text-align: center;
        color: #ffffff;
        margin-top: 20px;
        font-size: 16px;
      }

      .chat-contacts::-webkit-scrollbar {
        width: 8px;
        background: #2c3e50;
      }
      .chat-contacts::-webkit-scrollbar-thumb {
        background-color: #243140;
      }

      .chat-contacts .chat-contact {
        position: relative;
        padding: 0;
        font-size: 0.9em;
        cursor: pointer;
        height: 40px;
        margin: 0;
      }

      .chat-edit {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        margin: 10px 8px 0 0;
        width: 20px;
        height: 20px;
        color: #ffffff;
        background-color: #32465a;
        text-align: center;
      }

      .chat-contact:hover .chat-edit {
        display: block;
      }

      .chat-contacts .chat-contact:hover {
        background: #32465a;
      }

      .chat-selected {
        background: #32465a;
      }

      .chat-contacts .chat-contact {
        position: relative;
      }

      .chat-contacts .chat-contact span {
        position: absolute;
        left: 0;
        margin: -2px 0 0 -2px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        border: 2px solid #2c3e50;
        background: #95a5a6;
      }

      .chat-name p,
      .chat-preview {
        color: #ffffff;
      }

      .chat-name {
        padding-left: 0;
      }

      .chat-contacts .chat-name p {
        margin: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        line-height: 40px;
      }

      .chat-contacts .chat-preview {
        margin: 10px 0 0 0;
        padding: 10px 0 0 0;
        font-weight: 400 !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -moz-transition: 1s all ease;
        -o-transition: 1s all ease;
        -webkit-transition: 1s all ease;
        transition: 1s all ease;
      }
      .chat-contacts .chat-preview span {
        position: initial;
        border-radius: initial;
        background: none;
        border: none;
        padding: 0 2px 0 0;
        margin: 0 0 0 1px;
        opacity: 0.5;
      }
      .chat-bottom-bar {
        width: 100%;
        height: 40px;
        margin: 0;
      }
      .chat-bottom-bar button {
        float: left;
        border: none;
        width: 50%;
        padding: 10px 0;
        background: #32465a;
        color: #f5f5f5;
        cursor: pointer;
        font-size: 0.85em;
      }

      .chat-bottom-bar button:focus {
        outline: none;
      }
      .chat-bottom-bar button:nth-child(1) {
        border-right: 1px solid #2c3e50;
      }

      .chat-bottom-bar button:hover {
        background: #435f7a;
      }

      .chat-bottom-bar button i {
        margin-right: 3px;
        font-size: 1em;
      }

      .chat-user {
        padding: 5px 0 0 10px;
      }

      .chat-user p {
        color: #ffffff;
        text-align: center;
        line-height: 30px;
        margin: 0;
        height: 30px;
        width: 30px;
        background-color: transparent;
        border-radius: 17px;
        text-align: center;
      }

      .chat-user p.active {
        background-color: #4ea755;
      }

      .chat-user p.inactive {
        background-color: #ffffff;
        color: #4ea755;
      }

      .chat-user i {
        font-size: 1.3vw;
      }

      .chat-contacts .chat-notification {
        position: absolute;
        right: 0;
        top: 0;
        margin: 10px 8px 0 0;
        width: 20px;
        height: 20px;
        color: #ffffff;
        background: red;
        text-align: center;
      }

      .mat-accordion {
        width: 100%;
      }

      ::ng-deep .mat-expansion-panel-body {
        padding: 0 !important;
      }

      ::ng-deep .mat-content h3 {
        font-weight: 600;
      }

      ::ng-deep .mat-expansion-panel {
        position: relative;
      }

      ::ng-deep .mat-expansion-panel-header {
        display: inline-flex !important;
        padding: 0 0 0 24px !important;
        width: 90% !important;
        height: 45px !important;
      }

      ::ng-deep .mat-content .row {
        width: 100%;
      }
    `,
  ],
})
export class ChatManagerComponent implements OnInit, OnDestroy {
  @Output() panelContextChanged: EventEmitter<
    ChatPanelContextModel<GroupModel>
  > = new EventEmitter<ChatPanelContextModel<GroupModel>>();
  @Input() user: ChatUserModel;

  public search: string;
  public items: GroupModel[];
  public selectedIndex: number;
  public isLoading: boolean = true;
  public hasGroup: boolean = false;
  private subscriptions: Subscription = new Subscription();

  constructor(private chatService: ChatService) {}

  public ngOnInit(): void {
    this.items = this.chatService.groups;
    this.setSubscriptions();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setSubscriptions(): void {
    let subscription = this.chatService.loadedGroups.subscribe((didLoad) => {
      if (didLoad) {
        this.isLoading = false;
        if (subscription) subscription.unsubscribe();
      }
    });
  }

  public onGroupSelected(group: GroupModel): void {
    this.chatService.selectGroup(group);
    this.hasGroup = true;
  }

  public onEditGroup(group: GroupModel): void {
    this.panelContextChanged.emit(<ChatPanelContextModel<GroupModel>>{
      object: group,
      context: ChatPanelContextEnum.EditGroup,
    });
  }

  public expandPanel(matExpansionPanel: MatExpansionPanel, event: Event): void {
    event.stopPropagation();
    let element = event.target as Element;
    let isButton =
      element.classList &&
      (element.classList.contains("fa") ||
        element.classList.contains("chat-add"));
    if (isButton) {
      if (!matExpansionPanel.expanded) {
        matExpansionPanel.open();
        return;
      }
      matExpansionPanel.close();
    }
  }

  public onAddGroup(system: boolean): void {
    this.panelContextChanged.emit(<ChatPanelContextModel<GroupModel>>{
      object: <GroupModel>{
        system: system,
      },
      context: ChatPanelContextEnum.AddGroup,
    });
  }
}

@Pipe({ name: "filterGroups" })
export class FilterGroupsPipe implements PipeTransform {
  transform(items: GroupModel[], filter: string): GroupModel[] {
    if (!items) return null;
    if (!filter) return items;

    return items.filter((x) =>
      x.system
        ? x.name.toLowerCase().indexOf(filter) >= 0
        : x.users
            .map((c) => c.fullName)
            .join("")
            .toLowerCase()
            .indexOf(filter) >= 0
    );
  }
}

@Pipe({
  name: "groupName",
  pure: false,
})
export class GroupNamePipe implements PipeTransform {
  transform(group: GroupModel, user: UserModel): string {
    if (!group || !user) return;
    if (group.system) {
      return group.name;
    }

    if (group.users.length > 1) {
      return group.users
        .filter((x) => x.guid !== user.guid)
        .map((x) => x.fullName)
        .join(", ");
    }

    return "Just me";
  }
}

@Pipe({
  name: "groupIcon",
  pure: true,
})
export class GroupIconPipe implements PipeTransform {
  transform(users: UserModel[], user: UserModel): string {
    let initials = "N/A";
    if (!users) return initials;

    let participant: UserModel;

    if (users.length === 1) {
      participant = users[0];
    } else if (users.length === 2) {
      participant = users.filter((x) => x.guid !== user.guid)[0];
    }

    if (participant) {
      initials = "";
      if (participant.firstName && participant.firstName.length > 0) {
        initials += participant.firstName.charAt(0);
      }

      if (participant.lastName && participant.lastName.length > 0) {
        initials += participant.lastName.charAt(0);
      }
    }

    return initials.toUpperCase();
  }
}

@Pipe({
  name: "userActivity",
  pure: false,
})
export class UserActivityPipe implements PipeTransform {
  transform(group: GroupModel, user: UserModel): boolean {
    if (!group || !user || group.system) return false;
    return (
      group.users.findIndex((x) => x.guid !== user.guid && x.online === true) >
      -1
    );
  }
}
