import { Directive, ElementRef, Input, OnInit } from "@angular/core";

@Directive({
  selector: "[appFocus]",
})
export class DefaultFocusDirective implements OnInit {
  @Input("appFocus") isFocused: boolean;

  constructor(private hostElement: ElementRef) {}

  ngOnInit() {
    if (this.isFocused) {
      this.hostElement.nativeElement.focus();
    }
  }
}
