export class To {
  name: string;
  email: string;
}

export class Bcc {
  name: string;
  email: string;
}

export class Cc {
  name: string;
  email: string;
}

export class EmailMessageModel {
  Tos: To[];
  Bccs: Bcc[];
  Ccs: Cc[];
  Subject: string;
  PlainTextContent: string;
  HtmlContent: string;
  constructor() {}
}

export class EmailPayload {
  SendTo: number;
  EmailType: EnumEmailType;
  JobId: string;
  constructor() {}
}
export enum EnumEmailType {
  None = 0,
  //Welcome Message
  NewEmployee,
  EmployeeDeleted,

  NewCustomer,
  CustomerDeleted,

  NewOrganization,
  OrganizationDeleted,

  NewVender,
  VendorDeleted,

  JobAdded,
  JobUpdated,
  JobDeleted,

  //JobPhase
  PhaseAdded,
  PhaseUpdate,
  PhaseDeleted,
  PhaseCompleted,
  PhaseReminder,
  PhaseScheduleUpdate,
  PhaseReadyToBeWorked,
  PhaseScheduled,

  //PunchList
  PunchlistItemAdded,
  PunchlistItemUpdate,
  PunchlistItemDeleted,
  PunchlistItemCompleted,

  //WorkOrder
  WorkOrderAdded,
  WorkOrderUpdated,
  WorkOrderDeleted,
  WorkOrderCompleted,
  WorkOrderSendToContractor,

  //WorkOrder Items
  WorkOrderItemAdded,
  WorkOrderItemUpdated,
  WorkOrderItemDeleted,
  WorkOrderItemCompleted,

  ChangeOrderAdded,
  ChangeOrderApproved,
  ChangeOrderRejected,

  PaymentAdded,
  PaymentDue,
  PaymentReceived,

  //request for service messages
  JobPhaseStatusRequest,
  JobPhaseScheduleCancel,
  JobPhaseDateofServiceRequestDate,
  JobPhaseDateofServiceProposedDate,
  JobPhaseDateofServiceConfirmDate,
  JobPhaseDateofServiceRejectDate,
  JobPhaseDateofServiceCancel,

  //resources messages
  ResourceAssignedToTask,
  ResourceRemovedFromTask,
  ResourceAssignedToJob,

  NewOppertunityCustomer,
}
