<div class="tabphotoblk">
  <div class="row">
    <div class="col mb5">
      <div class="addbtn">
        <label
          *ngIf="totalRecords != 0"
          class="checkbox-inline custom-checkbox nowrap fl-l mr3"
          style="width: 6%"
        >
          <input
            type="checkbox"
            name="selectAll"
            [(ngModel)]="selectAll"
            (change)="selectDeselectAllPhotos(true)"
          />
          <span></span>
        </label>
        <a
          [ngClass]="{
            'margin-top-btn': !isWorkOrder,
            'margin-notop-btn': isFromPhotos
          }"
          (click)="onAddNewData(0, 'addPhoto')"
          data-toggle="tooltip"
          data-placement="top"
          title="Add File"
        >
          <i class="fa fa-plus-circle" aria-hidden="true"></i> Add
        </a>
        <a
          [ngClass]="{
            'margin-top-btn': !isWorkOrder,
            'margin-notop-btn': isFromPhotos,
            disableanchor: !enableDelete
          }"
          *ngIf="totalRecords != 0"
        >
          <i
            title="Delete"
            (click)="deleteJobPhotos()"
            class="fa fa-trash-o pha de_photo"
            aria-hidden="true"
          ></i>
        </a>
        <button
          [ngClass]="{ 'from-photos-btn': isFromPhotos }"
          *ngIf="lstPhotos.length != 0"
          class="download"
          type="button"
          (click)="downloadPhotos()"
          style="height: 25px; margin-left: 25px"
        >
          <i class="fa fa-download" style="margin-right: 1px; font-size: 11px">
          </i>
          Download
        </button>
      </div>

      <div
        *ngIf="totalRecords != 0"
        class="title_right hright datasearch align delbtn"
      >
        <button
          class="btn darkbtn"
          (click)="deleteAllJobPhotos()"
          [disabled]="totalRecords == 0"
          type="button"
        >
          Delete All
        </button>
      </div>
    </div>
  </div>
  <div class="kendoheight">
    <!--customtable-->
    <app-photo-grid
      [photos]="lstPhotos"
      [pageSize]="pageSize"
      [lazy]="true"
      (refresh)="getPhotos()"
      (onLazyLoad)="loadData($event)"
      (onSelectedChanged)="selectDeselectAllPhotos(false)"
      (onDelete)="deleteJobPhoto($event)"
      [totalRecords]="totalRecords"
      [selectAll]="selectAll"
      [isWorkOrder]="isWorkOrder"
      [jobId]="jobId"
    ></app-photo-grid>
  </div>
</div>
<div
  *ngIf="!isFirstSlide"
  class="{{ sideBarClass }}"
  [ngClass]="showSlides != '' ? 'open' : ''"
>
  <a class="cancelbtnHead ui-sidebar-close" (click)="showSlides = ''">
    <span class="pi pi-times"></span>
  </a>
  <photosAdd
    *ngIf="showSlides == 'addPhoto'"
    [workOrderId]="jobId"
    [phaseId]="JobPhaseId"
    [isPhase]="isPhase"
    (newPhotos)="onNewPhotos($event)"
    [type]="'Photo'"
    (closeSlideout)="showSlides = ''"
  ></photosAdd>
</div>
<p-sidebar
  *ngIf="isFirstSlide"
  class="addeditclient"
  [(visible)]="visibleSidebar"
  position="right"
  autoZIndex="true"
  appendTo="body"
>
  <photosAdd
    *ngIf="showSlides == 'addPhoto'"
    [workOrderId]="jobId"
    [phaseId]="JobPhaseId"
    [isPhase]="isPhase"
    [saleJobId]="jobId"
    (newPhotos)="onNewPhotos($event)"
    [type]="'Photo'"
    (closeSlideout)="onHide($event)"
  ></photosAdd>
</p-sidebar>
