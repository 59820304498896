import { Directive, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[preventBackSlashAndDoubleQuote]",
})
export class PreventBackSlashAndDoubleQuote {
  constructor() {}

  @Input() OnlyNumber: boolean;

  @HostListener("keydown", ["$event"])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === '"' || event.key === "\\") {
      event.preventDefault();
    }
  }

  @HostListener("paste", ["$event"])
  onPaste(event: ClipboardEvent) {
    const data = event.clipboardData || window["clipboardData"];
    const text: string = data.getData("text");
    if (text.includes('"') || text.includes("\\")) {
      event.preventDefault();
    }
  }

  @HostListener("input", ["$event"])
  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const newInput = input.value.replace(/["\\]/, "");
    if (input.value !== newInput) {
      input.value = newInput;
      input.dispatchEvent(new Event("input"));
    }
  }
}
