import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { ProductQuantity } from "app/model/KitModel";
export class ValidateHasGreaterThanZero {
  /**
   * Validates if at least one of the formControl has a value greater than zero.
   * @param quantityValues
   * @returns Validator Function
   */
  static checkIfHasGreaterThanZero(
    quantityValues: ProductQuantity[],
    formGroup: FormArray,
    fromEstimate: boolean = false
  ) {
    const fn = (control: FormControl) => {
      let hasGreaterThanZero: boolean = false;

      if (quantityValues.length === 0) return null;

      quantityValues.forEach((x) => {
        if (x.qty > 0) {
          hasGreaterThanZero = true;
        }
      });

      if (!hasGreaterThanZero) {
        return { invalidValue: true };
      }

      if (formGroup) {
        const validate = new ValidateHasGreaterThanZero();
        fromEstimate
          ? validate.markFormAsInvalidFromEstimate(formGroup)
          : validate.markFormAsValid(formGroup);
      }

      return null;
    };

    return fn.bind(this);
  }

  /**
   * Method to mark the form as invalid when request comes from package builder
   * @param formArray formArray with the data to be marked as valid
   */
  markFormAsValid(formArray: FormArray): void {
    let form = formArray.controls as FormGroup[];
    form.forEach((control: FormGroup) => {
      let formSimpleKit = control.controls.simpleKitFormRows as FormArray;
      let formProduct = control.controls.productFormRows as FormArray;
      let formSimpleKitItems = formSimpleKit.controls as FormGroup[];
      let formProductItems = formProduct.controls as FormGroup[];

      formSimpleKitItems.forEach((innerControl) => {
        innerControl.markAsTouched();
        innerControl.markAsDirty();
        innerControl.setErrors(null);
        innerControl.controls.qtyControl.markAsTouched();
        innerControl.controls.qtyControl.markAsDirty();
        innerControl.controls.qtyControl.setErrors(null);
      });

      formProductItems.forEach((innerControl) => {
        innerControl.markAsTouched();
        innerControl.markAsDirty();
        innerControl.setErrors(null);
        innerControl.controls.qtyControl.markAsTouched();
        innerControl.controls.qtyControl.markAsDirty();
        innerControl.controls.qtyControl.setErrors(null);
      });
    });
  }

  /**
   * Method to mark the form as invalid when request comes from estimate worksheet
   * @param formArray formArray with the data to be marked as valid
   */
  markFormAsInvalidFromEstimate(formArray: FormArray): void {
    const formProduct = formArray.controls;
    formProduct.forEach((control: FormGroup) => {
      control.markAsTouched();
      control.markAsDirty();
      control.setErrors(null);
      control.controls.productQty.markAllAsTouched();
      control.controls.productQty.markAsDirty();
      control.controls.productQty.setErrors(null);
    });
  }
}
