import { Injectable } from "@angular/core";
import { HttpService } from "../../../app/common/utility/http.service";
import { environmentConstant } from "../../../app/common/utility/environment";
import { Observable, BehaviorSubject } from "rxjs";
import { SharedService } from "../utility/SharedService";
import { JobInfoViewDto } from "../../model/JobInfoView";

@Injectable()
// tslint:disable:prefer-template
export class DashboardService {
  private data = new BehaviorSubject("");
  totalJobsCount = this.data.asObservable();
  constructor(
    private httpService: HttpService,
    private sharedService: SharedService
  ) {}
  sendTotalNoOfJobs(totalJobs: any) {
    this.data.next(totalJobs);
  }
  getFinanceDashboardData(): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getFinanceDashboard +
        this.sharedService.selectedOrganization.ID
    );
  }
  getDashboardTemplate(year: number): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getTemplateDashboard +
        this.sharedService.selectedOrganization.ID +
        "/year/" +
        year
    );
  }
  getDashboardRevenueData(year: number): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getRevenueDashboard +
        this.sharedService.selectedOrganization.ID +
        "/year/" +
        year
    );
  }
  getDashboardSales(year: number): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getSalesDashboard +
        this.sharedService.selectedOrganization.ID +
        "/year/" +
        year
    );
  }
  getJobsWhiteBoard(jobType): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getJobsWhiteboard +
        this.sharedService.selectedOrganization.ID +
        "?jobType=" +
        jobType
    );
  }
  getWhiteBoardByJobId(jobId): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getWhiteBoardByJobId + jobId
    );
  }
  getJobPhaseStatus(): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.jobPhaseStatus +
        this.sharedService.selectedOrganization.ID
    );
  }
  getSalesJobStatus(): Observable<any> {
    return this.httpService.getRequestService(
      "dasbboard/salesjobphasesstatus/" +
        this.sharedService.selectedOrganization.ID
    );
  }
  getJobInfo(jobId: any): Observable<JobInfoViewDto> {
    return this.httpService.getRequestService(
      environmentConstant.jobInfo[0] + jobId + environmentConstant.jobInfo[1]
    );
  }
  // getPinnedFiles(jobId: any): Observable<any> {
  //   return this.httpService.getRequestService(environmentConstant.pinnedFiles + jobId + '/files/pinned');
  // }
  UpdateFilePinnedState(filed: number, pinned: boolean) {
    return this.httpService.postRequestService(
      environmentConstant.updatefilePinnedState + filed + "/pinned/" + pinned,
      null
    );
  }
}
