import { Component, OnInit, ChangeDetectorRef, ViewChild } from "@angular/core";
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { timezoneData } from "@syncfusion/ej2-schedule";

export interface CaledarPopupModel {
  title: string;
  Calendar: CalendarDto;
  BtnText: string;
}
export class CalendarDto {
  Summary: string;
  Description: string;
  Timezone: string;
  backgroundColor: any;
}
@Component({
  selector: "app-add-calendar-popup",
  templateUrl: "./add-calendar-popup.component.html",
  styleUrls: ["./add-calendar-popup.component.scss"],
})
export class AddCalendarPopupComponent
  extends DialogComponent<CaledarPopupModel, any>
  implements CaledarPopupModel {
  BtnText: string;
  Calendar: CalendarDto;
  title: string;
  changeDetectorRef: ChangeDetectorRef;
  timezones: any = timezoneData;
  constructor(
    dialogService: DialogService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(dialogService);
    this.changeDetectorRef = changeDetectorRef;
  }

  Confirm() {
    this.result = this.Calendar;
    this.close();
  }
}
