<div class="row">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <h3 class="pt">Organization Settings</h3>
  </div>
</div>
<div class="row bottom-grid">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <form novalidate #myForm="ngForm">
      <kendo-grid
        [data]="organizationSettings"
        (edit)="editHandler($event)"
        (cancel)="cancelHandler($event)"
        (save)="saveHandler($event)"
      >
        <kendo-grid-command-column title="" width="15%">
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            <div
              class="acButton"
              *ngIf="isSuperAdmin || (!dataItem.ReadOnly && isAdmin)"
            >
              <button kendoGridSaveCommand [disabled]="myForm.invalid">
                <span title="{{ 'common.save' | translate }}">
                  <i class="fa fa-floppy-o" aria-hidden="true"></i>
                </span>
              </button>
              <button kendoGridCancelCommand>
                <span title="{{ 'common.cancel' | translate }}">
                  <i class="fa fa-ban" aria-hidden="true"></i>
                </span>
              </button>
            </div>
            <div
              class="dropdown cud"
              *ngIf="isSuperAdmin || (!dataItem.ReadOnly && isAdmin)"
            >
              <i class="fa fa-ellipsis-v" id="menu1" data-toggle="dropdown">
              </i>
              <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
                <li kendoGridEditCommand>
                  <a class="edit" title="Edit">
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                    {{ "common.edit" | translate }}
                  </a>
                </li>
              </ul>
            </div>
          </ng-template>
        </kendo-grid-command-column>
        <kendo-grid-column
          field="DisplayName"
          title="Name"
          width="35%"
        ></kendo-grid-column>
        <kendo-grid-column
          field="Value"
          editor="numeric"
          title="Value"
          width="30%"
          [style]="{ 'text-align': 'left' }"
          [format]="'n2'"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container
              *ngIf="
                dataItem.Value === null || dataItem.Value === 0;
                else valueTemplate
              "
            >
              Not Set
            </ng-container>
            <ng-template #valueTemplate>
              <p
                *ngIf="
                  dataItem.DataTypeId != dataTypeEnum.Boolean;
                  else booleanValueTemplate
                "
              >
                {{ dataItem.Value }}
              </p>
              <ng-template #booleanValueTemplate>
                <p>{{ dataItem.Value == "0" ? "False" : "True" }}</p>
              </ng-template>
            </ng-template>
          </ng-template>
          <ng-template kendoGridEditTemplate let-dataItem="dataItem">
            <input
              [(ngModel)]="dataItem.NumericValue"
              (keydown)="validateNumber($event)"
              name="ValueField"
              class="k-textbox"
              type="text"
              maxlength="10"
              required
              *ngIf="
                dataItem.SettingValues.length == 0 &&
                dataItem.DataTypeId == dataTypeEnum.Numeric
              "
            />
            <input
              [(ngModel)]="dataItem.Value"
              name="ValueField"
              class="k-textbox"
              type="text"
              maxlength="150"
              required
              *ngIf="dataItem.DataTypeId == dataTypeEnum.String"
            />
            <input
              [(ngModel)]="dataItem.BooleanValue"
              type="checkbox"
              name="ValueField"
              formnovalidate
              *ngIf="dataItem.DataTypeId == dataTypeEnum.Boolean"
            />
            <p *ngIf="dataItem.DataTypeId == dataTypeEnum.Date">
              {{ dataItem.DateValue | date : "MM/dd/yyyy" }}
            </p>
            <kendo-datepicker
              class="full-date"
              name="ValueField"
              [(ngModel)]="dataItem.DateValue"
              *ngIf="dataItem.DataTypeId == dataTypeEnum.Date"
            ></kendo-datepicker>

            <kendo-dropdownlist
              *ngIf="
                dataItem.SettingValues.length > 0 &&
                dataItem.DataTypeId == dataTypeEnum.Numeric
              "
              kendoTextBox
              name="ValueField"
              [valuePrimitive]="true"
              [(ngModel)]="dataItem.NumericValue"
              [data]="dataItem.SettingValues"
              textField="Description"
              valueField="Value"
              (selectionChange)="onSelectionChange($event, dataItem)"
            >
            </kendo-dropdownlist>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="DefaultValue"
          editor="boolean"
          title="Default Value"
          width="20%"
        >
          <ng-template kendoGridCellTemplate let-dataItem="dataItem">
            <p
              *ngIf="
                dataItem.DataTypeId != dataTypeEnum.Boolean;
                else booleanDefaultValueTemplate
              "
            >
              {{ dataItem.DefaultValue || "Not Set" }}
            </p>
            <ng-template #booleanDefaultValueTemplate>
              <p>
                {{
                  dataItem.DefaultValue === null
                    ? "Not Set"
                    : dataItem.DefaultValue == "0"
                    ? "False"
                    : "True"
                }}
              </p>
            </ng-template>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </form>
  </div>
</div>
