import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";
import { environmentConstant } from "app/common/utility/environment";
import { Observable } from "rxjs";
import { JobNoteModel } from "app/model/jobNoteModel";

@Injectable()
export class JobNoteService {
  constructor(private httpService: HttpService) {}

  getJobNotesByJobID(jobId: any): Observable<JobNoteModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getJobNotesByJobID + jobId + "/notes"
    );
  }
  saveJobNote(jobNote: JobNoteModel) {
    return this.httpService.postRequestService(
      environmentConstant.saveJobNote,
      jobNote
    );
  }
  getJobNoteById(id: any): Observable<JobNoteModel> {
    return this.httpService.getRequestService(
      environmentConstant.getJobNoteById + id + "/job"
    );
  }
  deleteJobNote(id: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.deleteJobNote + id
    );
  }
  updateJobNote(jobNote: JobNoteModel) {
    return this.httpService.putRequestService(
      environmentConstant.updateJobNote,
      jobNote
    );
  }
}
