<div class="widgets commonFixedSidebar">
  <div class="row">
    <div class="col-md-12 ht100">
      <div class="rightcontent">
        <div class="sideBarHeader">
          <h1>{{ title }}</h1>
        </div>
        <div class="tabBlk">
          <div class="fixed-layout pb-0 overflowy-auto">
            <tr-viewer
              [containerStyle]="viewerContainerStyle"
              [reportServer]="reportData.reportServer"
              [reportSource]="reportData.reportSource"
              [viewMode]="reportData.viewMode"
              [parametersAreaVisible]="true"
              [scaleMode]="reportData.scaleMode"
              [scale]="reportData.scale"
              [pageReady]="pageReady"
            >
            </tr-viewer>
            <!-- [sendEmail]="{enabled:true }" -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
