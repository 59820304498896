import { Injectable } from "@angular/core";
import { ExpiredWorksheetsHistoryDTO } from "app/model/ExpiredWorksheetsHistoryModel";
import { environment } from "environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class ExpiredWorksheetsHistoryService {
  private baseUrl = environment.baseEstimateUrl + "ExpiredWorksheetsHistory/";

  constructor(private httpService: HttpClient) {}

  add(entity: ExpiredWorksheetsHistoryDTO) {
    return this.httpService.post(`${this.baseUrl}add`, entity);
  }
}
