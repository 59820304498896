<div class="row">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <h3 class="pt">Gateway Accounts</h3>
  </div>
</div>
<div class="job-contacts">
  <div class="row">
    <div class="grid-row add-row col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <a (click)="addGatewayAccount()"><span class="pi pi-plus"></span>Add</a>
    </div>
  </div>

  <div class="header-row row">
    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">Account Name</div>
    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">Account #</div>
    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">Merchant Id</div>
    <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12">Default</div>
    <div class="col-lg-1 col-md-1 col-sm-12 col-xs-12"></div>
  </div>

  <div class="grid-row row" *ngIf="!!addingValue">
    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <div class="form-group">
        <kendo-textbox-container class="k-state-filled">
          <input
            kendoTextBox
            [(ngModel)]="addingValue.AccountName"
            (valueChange)="changeAddAccountName($event)"
            (filterChange)="filterAccountName($event)"
          />
        </kendo-textbox-container>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-10 col-xs-10">
      <div class="form-group">
        <kendo-textbox-container class="k-state-filled">
          <input
            kendoTextBox
            [(ngModel)]="addingValue.AccountNumber"
            (valueChange)="changeAddAccountName($event)"
          />
        </kendo-textbox-container>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-10 col-xs-10">
      <div class="form-group">
        <kendo-textbox-container class="k-state-filled">
          <input
            kendoTextBox
            [(ngModel)]="addingValue.MerchantId"
            (valueChange)="changeAddMerchantId($event)"
          />
        </kendo-textbox-container>
      </div>
    </div>
    <div class="col-lg-1 col-md-1 col-sm-10 col-xs-10">
      <div class="form-group">
        <kendo-textbox-container class="k-state-filled">
          <input
            type="checkbox"
            [(ngModel)]="addingValue.Default"
            (valueChange)="changeAddDefault($event)"
          />
        </kendo-textbox-container>
      </div>
    </div>

    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
      <a
        class="edit-icon"
        title="Save Gateway Account"
        (click)="saveAddGatewayAccount()"
      >
        <span class="pi pi-check"></span>
      </a>
    </div>
    <div class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
      <a class="edit-icon" title="Cancel Add" (click)="cancelAdd()">
        <span class="pi pi-ban"></span>
      </a>
    </div>
  </div>

  <div
    class="grid-row row"
    *ngFor="let account of gatewayAccounts; let i = index"
  >
    <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
      <div class="form-group">
        <div *ngIf="editingIndex !== i">{{ getAccountName(i) }}</div>
        <kendo-textbox-container
          *ngIf="editingIndex === i"
          class="k-state-filled"
        >
          <input
            kendoTextBox
            [(ngModel)]="editingValue.AccountName"
            (valueChange)="changeAccountName($event, i)"
            (filterChange)="filterAccountName($event)"
          />
        </kendo-textbox-container>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-10 col-xs-10">
      <div class="form-group">
        <div *ngIf="editingIndex !== i">{{ getAccountNumber(i) }}</div>
        <kendo-textbox-container
          *ngIf="editingIndex === i"
          class="k-state-filled"
        >
          <input
            kendoTextBox
            [(ngModel)]="editingValue.AccountNumber"
            (valueChange)="changeAccountNumber($event, i)"
          />
        </kendo-textbox-container>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-10 col-xs-10">
      <div class="form-group">
        <div *ngIf="editingIndex !== i">{{ getMerchantId(i) }}</div>
        <kendo-textbox-container
          *ngIf="editingIndex === i"
          class="k-state-filled"
        >
          <input
            kendoTextBox
            [(ngModel)]="editingValue.MerchantId"
            (valueChange)="changeMerchantId($event, i)"
          />
        </kendo-textbox-container>
      </div>
    </div>
    <div class="col-lg-1 col-md-1 col-sm-10 col-xs-10">
      <div class="form-group">
        <div *ngIf="editingIndex !== i">{{ getDefault(i) }}</div>
        <kendo-textbox-container
          *ngIf="editingIndex === i"
          class="k-state-filled"
        >
          <input
            type="checkbox"
            [(ngModel)]="editingValue.Default"
            (valueChange)="changeDefault($event, i)"
          />
        </kendo-textbox-container>
      </div>
    </div>

    <div *ngIf="editingIndex !== i" class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
      <a
        class="read-icon"
        title="Edit Gateway Account"
        (click)="editGatewayAccount(i)"
      >
        <span class="pi pi-pencil"></span>
      </a>
    </div>
    <div *ngIf="editingIndex !== i" class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
      <a
        class="read-icon"
        title="Delete Gateway Account"
        (click)="deleteGatewayAccount(i)"
      >
        <span class="pi pi-trash"></span>
      </a>
    </div>
    <div *ngIf="editingIndex === i" class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
      <a
        class="edit-icon"
        title="Save Gateway Account"
        (click)="saveGatewayAccount(i)"
      >
        <span class="pi pi-check"></span>
      </a>
    </div>
    <div *ngIf="editingIndex === i" class="col-lg-1 col-md-1 col-sm-1 col-xs-1">
      <a class="edit-icon" title="Cancel Edit" (click)="cancelEdit()">
        <span class="pi pi-ban"></span>
      </a>
    </div>
  </div>

  <div
    class="grid-row no-results row"
    *ngIf="gatewayAccounts.length === 0 && !addingValue"
  >
    No Accounts added
  </div>
</div>
