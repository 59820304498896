<div class="estimate-theme mat-dialog mat-dialog--l">
  <mat-dialog-title>
    <h3>{{ title }}</h3>
  </mat-dialog-title>

  <mat-dialog-content>
    <span *ngIf="isLoading; else files">{{ message }}</span>

    <ng-template #files>
      <app-export-file-list [fileList]="fileList"></app-export-file-list>
    </ng-template>
  </mat-dialog-content>

  <mat-dialog-actions
    class="mat-dialog__actions mat-dialog__actions--align-right"
  >
    <button class="button button--secondary button--md" matDialogClose>
      Close
    </button>
  </mat-dialog-actions>
</div>
