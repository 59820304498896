import { Injectable } from "@angular/core";
import { environmentConstant } from "app/common/utility/environment";
import { Observable, ReplaySubject } from "rxjs";
import { EstimateProductModel } from "app/model/EstimateProductModel";
import { EstimateProductResponseModel } from "app/model/estimateProductResponseModel";
import { environment } from "environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { PagedProductSearchParams } from "app/model/PagedProductSearchParams";
import { ProductOption } from "app/model/ProductOptionModel";
import { Kit } from "app/model/KitModel";
import { ProductWarrantyDisclaimer } from "app/model/ProductWarrantyDisclaimer";
import { SharedService } from "../utility/SharedService";
import { AuthGuard } from "../authGuard/auth.guard";
import { ProductEstimateViewResponse } from "app/model/ProductEstimateViewResponse";
import { ValidSkuResponse } from "app/model/ValidSkuResponse";
import { ProductRealPrice } from "app/model/ProductRealPrice.Model";
import { ExistsProductOnEstimate } from "app/model/ExistsProductOnEstimate";
import { PreDeleteOpResponse } from "app/model/PreDeleteOpResponse";
import { CheckIfAffectsWorksheets } from "app/model/CheckIfAffectsWorksheet";

@Injectable({
  providedIn: "root",
})
export class EstimateProductService {
  private baseUrl = environment.baseEstimateUrl;
  private baseUrlBlob = environment.baseCoreUrl;

  constructor(
    private httpService: HttpClient,
    private sharedService: SharedService,
    private authGuard: AuthGuard
  ) {}

  getAllEstimateProducts() {
    return this.httpService.get<EstimateProductModel[]>(
      `${this.baseUrl}productslist`
    );
  }

  getProductRealPrice(Productid: number) {
    return this.httpService.get<ProductRealPrice>(
      `${this.baseUrl}productrealprice/${Productid}`
    );
  }

  getProductsByCategoryId(categoryId: number) {
    return this.httpService.get<EstimateProductModel[]>(
      `${this.baseUrl}productsbycategory/${categoryId}`
    );
  }

  pagedProductsSearch(orgGuid: string, searchParams: PagedProductSearchParams) {
    return this.httpService.post<EstimateProductResponseModel>(
      `${this.baseUrl}products/${orgGuid}/search`,
      searchParams
    );
  }

  deleteProduct(productId: number) {
    return this.httpService.delete(`${this.baseUrl}products/${productId}`);
  }

  editProduct(product: EstimateProductModel) {
    return this.httpService.post(`${this.baseUrl}products/edit`, product);
  }

  getProduct(guid: string): Observable<EstimateProductModel> {
    return this.httpService.get<EstimateProductModel>(
      `${this.baseUrl}products/${guid}`
    );
  }

  editPublishedProduct(id: number, checked: { checked: boolean }) {
    return this.httpService.post(
      `${this.baseUrl}products/${id}/published`,
      checked
    );
  }

  createNewProduct(product: EstimateProductModel) {
    return this.httpService.post(`${this.baseUrl}products/new`, product);
  }

  getProductOptions(productId: number, orgGuid: string) {
    return this.httpService.get<ProductOption[]>(
      `${this.baseUrl}products/${productId}/options/${orgGuid}`
    );
  }

  getProductWarrantyDisclaimer(id: number) {
    return this.httpService.get<ProductWarrantyDisclaimer>(
      `${this.baseUrl}products/${id}/warranty-disclaimer`
    );
  }

  uploadProductWarranty(file) {
    const formData = new FormData();

    formData.append("file", file);

    return this.httpService.post("http://localhost:4300/api/Blobs", formData);
  }

  uploadFile(file, fileName) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      AppName: "MPB-Web",
    });

    if (this.sharedService.selectedOrganization)
      headers = headers.append(
        "Org-Guid",
        this.sharedService.selectedOrganization.ID
      );

    let token = this.authGuard.getToken(true, false);
    if (token) headers = headers.append("Authorization", token);

    let param = {
      data: file,
      fileName: fileName,
    };
    return this.httpService.post<any>(`${this.baseUrlBlob}Blobs`, param, {
      headers,
    });
  }

  getKit(kitId: number, orgGuid: string) {
    return this.httpService.get<Kit>(
      `${this.baseUrl}products/${kitId}/kit/${orgGuid}`
    );
  }

  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(reader.result.toString()));
    return result;
  }

  getProductEstimateViews(productId: number, orgGuid: string) {
    return this.httpService.get(
      `${this.baseUrl}products/${orgGuid}/${productId}/estimateviews`
    );
  }

  editProductEstimateViews(estimateView: ProductEstimateViewResponse) {
    return this.httpService.post(
      `${this.baseUrl}products/estimate/ext/edit/${estimateView.productId}`,
      estimateView
    );
  }

  addProductEstimateExt(estimateView: ProductEstimateViewResponse) {
    return this.httpService.post(
      `${this.baseUrl}products/estimate/ext/new`,
      estimateView
    );
  }

  validateSku(sku: string, productId: number): Observable<ValidSkuResponse> {
    return this.httpService.get<ValidSkuResponse>(
      `${this.baseUrl}products/validatesku/${sku}/${productId}`
    );
  }

  existsProductOnEstimate(
    productId: number,
    priceBookDetailId: number
  ): Observable<ExistsProductOnEstimate> {
    return this.httpService.get<ExistsProductOnEstimate>(
      `${this.baseUrl}products/estimateexists/${productId}/${priceBookDetailId}`
    );
  }

  /**
   * Checks if the deletion of a product affects worksheets
   * @param productId
   * @returns
   */
  checkPreDeleteOperation(productId: number): Observable<PreDeleteOpResponse> {
    return this.httpService.get<PreDeleteOpResponse>(
      `${this.baseUrl}products/preDeleteOperation/${productId}`
    );
  }

  /**
   * Checks if updating the kit would affect the woksheet
   * @param productId
   * @returns
   */
  checkIfAffectsWorksheets(
    productId: number
  ): Observable<CheckIfAffectsWorksheets> {
    return this.httpService.get<CheckIfAffectsWorksheets>(
      `${this.baseUrl}products/checkIfAffectsWorksheets/${productId}`
    );
  }
}
