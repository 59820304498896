import { Injectable } from "@angular/core";
import { SortDescriptor } from "@progress/kendo-data-query";
import { UserRoleEnum } from "app/model/Core/UserRoleEnum";
import parsePhone from "libphonenumber-js";
import { SelectItem } from "primeng/primeng";
import { Observable, ReplaySubject } from "rxjs";
import { environmentConstant } from "./environment";

@Injectable()
export class Helper {
  static ConvertToString(value: any): string {
    if (value) {
      return value.toString().trim();
    } else {
      return "";
    }
  }
  static ConvertToLower(value: any): string {
    if (value) {
      return value.toString().toLowerCase().trim();
    } else {
      return "";
    }
  }
  static uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
  static formatPhone(input: string) {
    const phone = parsePhone(input, "US");
    const formatted = phone.formatNational();
    return formatted;
  }
  static getInitials(input: string) {
    if (!input) return "";
    var result = input
      .split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), "");
    if (result && result.length > 2) {
      return result.slice(0, 2);
    }
    return result;
  }

  /**
   * Convert options for dropdowns in dropdown items.
   */
  static convertToNgDropdown(
    arr: any[],
    label: string,
    value?: string
  ): SelectItem[] {
    return arr.map((el) => {
      const val = value || el;
      return { label: el[label], value: val };
    });
  }

  /**
   * Extracts numbers from a given string
   * @param str The string to extract numbers from
   * @returns An array of the numbers from the string
   */
  static getNumbersFromString(str: string): number[] {
    const regex = /\d+(?:\.\d+)?/g;
    var match;
    let result = [];
    while ((match = regex.exec(str))) {
      result.push(+match[0]);
    }
    return result;
  }
  /**
   * Returns an array of substring from a given string that matches sentences in between a delimitator
   * @param str full string
   * @param delimitator character that represents the delimitator of sentences
   * @returns Array of string
   */
  static stringToLettersArray(str: string, delimitator: string): string[] {
    let array = [];
    let name = "";
    let index = 0;
    let isStart = false;

    while (index < str.length) {
      if (str[index] == delimitator) {
        isStart = !isStart;
        if (!isStart) {
          array.push(name);
          name = "";
        }
      } else {
        if (isStart) {
          name = name + str[index];
        }
      }
      index++;
    }
    return array;
  }

  /**
   * Currency formatter for Total Price
   * @param amount
   */
  static formatCurrency(amount: number, digits: number) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: digits,
    }).format(amount);
  }

  /**
   * Format numbers
   */
  static formatNumbersWithThreeCommas(x: string): string {
    if (x.includes(".")) {
      let parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    } else {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  /**
   * Truncates a value to the given number of decimals
   * @param targetNumber
   * @param numberOfDecimals
   * @returns
   */
  static truncateNumber(targetNumber: number, numberOfDecimals: number) {
    const multiplier = 10 ** numberOfDecimals;
    return Math.trunc(targetNumber * multiplier) / multiplier;
  }

  /**
   * Truncates a value to the given number of decimals
   * @param targetNumber
   * @param numberOfDecimals
   * @returns
   */
  static roundNumber(targetNumber: number, numberOfDecimals: number) {
    const multiplier = 10 ** numberOfDecimals;
    return Math.round(targetNumber * multiplier) / multiplier;
  }

  /**
   * converts a class to querystring format
   *  - null values will be excluded
   *  - limited to certain class properties (string, number, bool)
   */
  static convertToQueryString(paramsObject: any): string {
    var queryString = "";

    if (paramsObject === null) {
      return "";
    }

    var keys = Object.keys(paramsObject);
    for (var i = 0; i < keys.length; i++) {
      var value = paramsObject[keys[i]];
      if (value === null || value === undefined) {
        continue;
      }

      queryString += `&${keys[i]}=${value}`;
    }

    return queryString.replace("&", "?");
  }

  static convertFileToBase64(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(reader.result.toString()));
    return result;
  }

  /** capitalize all the words from a string */
  static capitalizeString(str: string): string {
    let res = "";
    str.split(" ").forEach((s) => {
      res += s[0].toUpperCase() + s.slice(1).toLowerCase() + " ";
    });
    return res.trim();
  }

  /** Handles a blob for the browser to download */
  static downloadBlob(blob: Blob, fileName: string) {
    const dataType = blob.type;
    const binaryData = [];
    binaryData.push(blob);
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(
      new Blob(binaryData, { type: dataType })
    );
    downloadLink.setAttribute("download", `${fileName.split(".")[0]}.zip`);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  /** Gets the file extension. Source: https://stackoverflow.com/questions/190852/how-can-i-get-file-extensions-with-javascript/12900504#12900504 */
  static getFileExtension(fileName: string): string {
    return fileName.slice(
      (Math.max(0, fileName.lastIndexOf(".")) || Infinity) + 1
    );
  }

  /** Moves element inside array */
  static moveElement<T>(arr: T[], from: number, to: number): T[] {
    return [...arr.splice(to, 0, arr.splice(from, 1)[0])];
  }

  static convertAdvancedSortToPageSort(sort: any[]) {
    return sort
      .filter((item: SortDescriptor) => item.dir)
      .map((item: SortDescriptor) => ({
        name: item.field,
        direction: item.dir,
      }));
  }

  static formatLeadSearch(data: any[]) {
    data.forEach((item: any) => {
      var status = environmentConstant.status.find(
        (x) => x.key == item.jobStatusId
      );
      if (status != undefined) {
        item.jobStatusId = status.Value;
      }

      if (item.address) {
        const addressComps = [
          item.address1,
          item.city,
          item.stateProvinceAbbreviation,
          item.zipPostalCode,
        ];
        item.address = addressComps.filter((part: any) => part).join(", ");
      }
    });
    return data;
  }

  static formatAddress(address: any) {
    const empty = {
      long_name: "",
      short_name: "",
    };

    const components: any[] = address.address_components;

    const streetNumber =
      components.find((item: any) => item.types.includes("street_number")) ||
      empty;

    const route =
      components.find((item: any) => item.types.includes("route")) || empty;

    const subLocality =
      components.find((item: any) =>
        item.types.includes("sublocality_level_1")
      ) || empty;

    const city =
      components.find((item: any) => item.types.includes("locality")) ||
      components.find((item: any) =>
        item.types.includes("administrative_area_level_3")
      ) ||
      empty;

    let state = components.find((item: any) =>
      item.types.includes("administrative_area_level_1")
    );

    if (!state) {
      state =
        components.find((item: any) =>
          item.types.includes("administrative_area_level_2")
        ) || empty;
    }

    const country =
      components.find((item: any) => item.types.includes("country")) || empty;

    const postalCode =
      components.find((item: any) => item.types.includes("postal_code")) ||
      empty;

    const neighborhood =
      components.find((item: any) => item.types.includes("neighborhood")) ||
      empty;

    const addressComponents = [streetNumber.long_name, route.long_name];

    let fullAddress = addressComponents
      .map((e) => e.trim())
      .filter((e) => e)
      .join(" ");

    return {
      city,
      state,
      route,
      country,
      subLocality,
      postalCode,
      streetNumber,
      neighborhood,
      address: fullAddress,
    };
  }

  static formatHolidays(holidays: any[]) {
    return holidays.map((holiday: any) => {
      const newEvent: any = {};
      newEvent.allDay = true;
      newEvent.StatusId = 1;
      newEvent.daysToComplete = 1;
      newEvent.title = `${holiday.Subject} / Holiday`;
      newEvent.start = holiday.HolidayDate;
      newEvent.textColor = "white";
      newEvent.borderColor = "rgba(161,179,146)";
      newEvent.backgroundColor = "green";
      newEvent.editable = false;
      newEvent.className = "event-holiday";
      return newEvent;
    });
  }

  static formatHolidaysEjs(holidays: any[]) {
    return holidays.map((holiday: any) => {
      const newEvent: any = {};
      newEvent.IsAllDay = true;
      newEvent.StatusId = 1;
      newEvent.Subject = `${holiday.Subject} / Holiday`;
      newEvent.title = newEvent.Subject;
      newEvent.StartTime = holiday.HolidayDate;
      newEvent.EndTime = holiday.HolidayDate;
      newEvent.textColor = "white";
      newEvent.borderColor = "rgba(161,179,146)";
      newEvent.backgroundColor = "rgba(39, 144, 176, 0.1)";
      newEvent.IsReadonly = true;
      newEvent.className = "event-holiday";
      return newEvent;
    });
  }

  static convertJobStatusTextToEnum(state) {
    if (state.filters.length) {
      state.filters.forEach((item, index) => {
        if (Array.isArray(item.filters)) {
          item = this.convertJobStatusTextToEnum(item);
        } else if (item.field === "jobStatusId") {
          if (!parseInt(item.value)) {
            const apprStatus = environmentConstant.status.find((s) =>
              s.Value.toLowerCase().includes(item.value.toLowerCase())
            );
            if (apprStatus) {
              item.value = apprStatus.key;
            }
            //  else {
            // delete in appropriate job status
            // state.filters.splice(index, 1);
            // return convertJobStatusTextToEnum(state)
            // }
          }
        }
      });

      return state;
    } else {
      return state;
    }
  }
}
