import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { BaThemeSpinner } from "app/theme/services";
import { SharedService } from "app/common/utility/SharedService";
import { JobListModelV2 } from "app/model/jobListModelV2";
import { MessageService } from "primeng/components/common/messageservice";
import { DialogService } from "ng2-bootstrap-modal";
import { ConfirmComponent } from "app/component/dialog/dialog.component";
import { AddressModel } from "app/model/addressModel";
import { Resource } from "app/model/ResourceModel";
import { NgForm, ControlContainer } from "@angular/forms";
import { PunchListModelV2 } from "app/model/punchListModel";
import { PunchListService } from "app/common/services/punchList.service";
import { WorkOrderService } from "app/common/services/workOrder.service";
import { environmentConstant } from "app/common/utility/environment";
import { CommonService } from "app/common/services/common.service";
import { BusinessUnitModel } from "app/model/organizationModel";
import { JobResourcesRequestPayload } from "app/model/JobAssignResourceModel";
import { UpdateCountService } from "app/common/utility/updateCount.service";
@Component({
  selector: "punchlist-details",
  templateUrl: "./punchlistdetails.component.html",
  styleUrls: ["./punchlistdetails.component.css"],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class PunchlistDetailsComponent implements OnInit {
  punchlistV2: PunchListModelV2;
  showAddLineItem: boolean = false;
  showSlides: string = "";
  isFindJob: boolean = true;
  statusLst: any[] = [];
  originalStatusList: any[] = [];
  LineItemId: number;
  isadd: boolean = false;
  dataIdToEdit: number;
  visibleSidebar: boolean = false;
  selectedTab: number = 0;
  jobListV2: JobListModelV2;
  showCarousel: boolean = false;
  currentIndex: number;
  selectAll: boolean = false;
  buttonText: string = "";
  isChecked: boolean = false;
  lstAssignedResource: any;
  isAssignedUpdated: boolean = false;
  lstCategory: any[] = [];
  originalCategoryList: any[] = [];
  priorities: any[];
  isNewAssigned: boolean = false;
  originalPriorities: any[];
  lstBusinessUnit: BusinessUnitModel[] = [];
  lstOrignalBusinessUnit: BusinessUnitModel[] = [];
  EnableStartDateEdit: boolean = false;
  previousStartDate: Date;
  @Input() JobData: any;
  @Input() PunchlistID: number;
  @Output() closeSlide: EventEmitter<any> = new EventEmitter<any>();
  @Output() onWarrantyChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() jobSeleted: EventEmitter<any> = new EventEmitter<any>();
  @Output() updatePunchlistID: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateJobID: EventEmitter<any> = new EventEmitter<any>();
  assignResourceModel: any;
  previousResources: any = [];
  constructor(
    private workOrderService: WorkOrderService,
    private punchlistService: PunchListService,
    private _spinner: BaThemeSpinner,
    private sharedService: SharedService,
    private commonService: CommonService,
    private notificationsService: MessageService,
    private dialogService: DialogService,
    private updateCountsService: UpdateCountService
  ) {
    this.punchlistV2 = new PunchListModelV2();
    this.punchlistV2.OrgGuid = this.sharedService.selectedOrganization.ID;
  }
  getPriority() {
    this.priorities = environmentConstant.priority;
    this.originalPriorities = environmentConstant.priority;
  }
  ngOnInit() {
    this._spinner.show();
    this.getPriority();
    if (!this.PunchlistID) {
      this.isadd = true;
      this.buttonText = "Save";
      this.setDummyWorkOrder();
    } else {
      this.isFindJob = false;
      this.buttonText = "Update";
    }
    this.getStatus();
    this.getCategory();
    this.getPunchlistById();
    this.getBusinessUnit();
  }
  changeCompleteDateStatus(completeDate) {
    if (!completeDate) return;

    this.punchlistV2.StatusId = 16;
    if (!this.punchlistV2.StartDate) {
      this.punchlistV2.StartDate = new Date();
    }
  }
  getBusinessUnit() {
    this.commonService
      .getBusinessUnit(this.sharedService.selectedOrganization.ID)
      .subscribe((data) => {
        this.lstBusinessUnit = data;
        this.lstOrignalBusinessUnit = data;
      });
  }
  public filterBussinessUnit(filter: any): void {
    this.lstBusinessUnit = this.lstOrignalBusinessUnit.filter(
      (s) => s.BusinessUnit1.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }
  getCategory() {
    this.workOrderService
      .getCategoriesByEntityId(this.sharedService.selectedOrganization.ID, 2)
      .subscribe((data) => {
        this.lstCategory = data;
        this.originalCategoryList = data;
        this._spinner.hide();
      });
  }

  public filterCategory(filter: any): void {
    this.lstCategory = this.originalCategoryList.filter(
      (s) => s.CatetoryName.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }
  public filterPriority(filter: any): void {
    this.priorities = this.originalPriorities.filter(
      (s) => s.key.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }

  setDummyWorkOrder() {
    if (this.isadd) {
      this.punchlistService
        .setPunchlistDummy(this.punchlistV2)
        .subscribe((data) => {
          this.punchlistV2 = data;
          this.punchlistV2.Resources = new Array<Resource>();
          if (this.JobData && this.sharedService.selectedJob) {
            let isCoreAddressModel =
              this.sharedService.selectedJob.Address.address1 != null
                ? true
                : false;
            this.setAddressModel(
              isCoreAddressModel,
              this.sharedService.selectedJob
            );
            this.punchlistV2.JobName = this.sharedService.selectedJob.JobName;
            this.punchlistV2.Phone = this.sharedService.selectedJob.Phone;
            this.isFindJob = false;
            this.jobSeleted.emit(true);
            this.punchlistV2.JobID = this.sharedService.selectedJob.JobID;
            this.updateJobID.emit(this.punchlistV2.JobID);
          }
          this.updatePunchlistID.emit(this.punchlistV2.ID);
        });
    }
  }

  setAddressModel(isCoreAddressModel: boolean, job) {
    this.punchlistV2.Address = new AddressModel();
    if (isCoreAddressModel) {
      this.createCoreAddressModel(job);
    } else {
      this.createLegacyAddressModel(job);
    }
  }

  createCoreAddressModel(job) {
    this.punchlistV2.Address.Address1 = job.Address.address1;
    this.punchlistV2.Address.City = job.Address.city;
    this.punchlistV2.Address.StateProvinceAbbreviation =
      job.Address.stateProvinceAbbreviation;
    this.punchlistV2.Address.ZipPostalCode = job.Address.zipPostalCode;
  }

  createLegacyAddressModel(job) {
    this.punchlistV2.Address.Address1 = job.Address.Address1;
    this.punchlistV2.Address.City = job.Address.City;
    this.punchlistV2.Address.StateProvinceAbbreviation =
      job.Address.StateProvinceAbbreviation;
    this.punchlistV2.Address.ZipPostalCode = job.Address.ZipPostalCode;
  }

  getPunchlistById() {
    if (!this.isadd) {
      this.punchlistService
        .GetPunchlistItemById(this.PunchlistID)
        .subscribe((data) => {
          this.punchlistV2 = data;
          this.punchlistV2.RemindMeOnDay = this.punchlistV2.RemindMeOnDay
            ? new Date(this.punchlistV2.RemindMeOnDay)
            : null;
          this.punchlistV2.ScheduledDate = this.punchlistV2.ScheduledDate
            ? new Date(this.punchlistV2.ScheduledDate)
            : null;
          this.punchlistV2.StartDate = this.punchlistV2.StartDate
            ? new Date(this.punchlistV2.StartDate)
            : null;
          this.punchlistV2.CompleteDate = this.punchlistV2.CompleteDate
            ? new Date(this.punchlistV2.CompleteDate)
            : null;
          this.punchlistV2.TargetDate = this.punchlistV2.TargetDate
            ? new Date(this.punchlistV2.TargetDate)
            : null;

          if (this.punchlistV2.Resources.length > 0) {
            this.lstAssignedResource = JSON.parse(
              JSON.stringify(this.punchlistV2.Resources)
            );
          }
          this.previousResources = JSON.parse(
            JSON.stringify(this.punchlistV2.Resources)
          );
          this.onChangeWaranty();
          if (this.punchlistV2.JobID) {
            this.jobSeleted.emit(true);
            this.updateJobID.emit(this.punchlistV2.JobID);
          }
        });
    }
  }

  onChangeWaranty() {
    this.onWarrantyChange.emit(this.punchlistV2.IsWarranty);
  }
  public filterStatus(filter: any): void {
    this.statusLst = this.originalStatusList.filter(
      (s) => s.Status.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }
  onStatusChange() {
    if (this.punchlistV2.StatusId === 15) {
      if (!this.punchlistV2.StartDate) {
        this.punchlistV2.CompleteDate = null;
        this.punchlistV2.StartDate = new Date();
      } else {
        this.punchlistV2.CompleteDate = null;
      }
    } else if (
      this.punchlistV2.StatusId === 16 &&
      !this.punchlistV2.CompleteDate
    ) {
      this.punchlistV2.StartDate = new Date();
      this.punchlistV2.CompleteDate = new Date();
    } else if (this.punchlistV2.StatusId === 14) {
      this.punchlistV2.StartDate = null;
      this.punchlistV2.CompleteDate = null;
    }
  }

  getStatus() {
    this.workOrderService.getStatusId(3).subscribe((data) => {
      this.statusLst = data;
      this.originalStatusList = data;
    });
  }

  private error(error: any, message) {
    this.notificationsService.add({
      severity: "warn",
      summary: "Punchlist",
      detail: message,
    });
    this._spinner.hide();
  }

  private result(res: any, message, title) {
    if (res !== null) {
      this.notificationsService.add({
        severity: "success",
        summary: title,
        detail: message,
      });
      this._spinner.hide();
    }
  }

  updateData(e) {
    this.showSlides = "";
  }

  updateAssignedResource(event) {
    if (event == "cancel") {
      this.showSlides = "";
    } else {
      if (event.length > 0) {
        event.forEach((element) => {
          this.punchlistV2.Resources.push(element);
          this.isAssignedUpdated = true;
        });
        this.lstAssignedResource = JSON.parse(
          JSON.stringify(this.punchlistV2.Resources)
        );
      }
      this.showSlides = "";
    }
  }

  getPunchlistData(event) {
    if (event == "cancel") {
      this.showSlides = "";
    } else {
      this.isFindJob = false;
      this.punchlistV2.JobName = event.JobName;
      this.punchlistV2.Phone = event.PrimaryPhone;
      this.punchlistV2.Address = new AddressModel();
      this.punchlistV2.Address.Address1 = event.Address;
      this.punchlistV2.Address.City = event.City;
      this.punchlistV2.Address.StateProvinceAbbreviation = event.State;
      this.punchlistV2.Address.ZipPostalCode = event.Zip;
      this.jobListV2 = event;
      this.punchlistV2.JobID = event.JobId;
      this.showSlides = "";
      this.jobSeleted.emit(true);
      this.updateJobID.emit(this.punchlistV2.JobID);
    }
  }

  savePunchlist(form) {
    this._spinner.show();
    if (form.valid) {
      this.isNewAssigned = false;
      if (this.punchlistV2.RemindMeOnDay) {
        this.punchlistV2.RemindMeOnDay = new Date(
          this.punchlistV2.RemindMeOnDay
        );
        this.punchlistV2.RemindMeOnDay = new Date(
          this.punchlistV2.RemindMeOnDay.getFullYear(),
          this.punchlistV2.RemindMeOnDay.getMonth(),
          this.punchlistV2.RemindMeOnDay.getDate()
        );
      }
      if (this.punchlistV2.ScheduledDate) {
        this.punchlistV2.ScheduledDate = new Date(
          this.punchlistV2.ScheduledDate
        );
        this.punchlistV2.ScheduledDate = new Date(
          this.punchlistV2.ScheduledDate.getFullYear(),
          this.punchlistV2.ScheduledDate.getMonth(),
          this.punchlistV2.ScheduledDate.getDate()
        );
      }
      if (this.punchlistV2.TargetDate) {
        this.punchlistV2.TargetDate = new Date(this.punchlistV2.TargetDate);
        this.punchlistV2.TargetDate = new Date(
          this.punchlistV2.TargetDate.getFullYear(),
          this.punchlistV2.TargetDate.getMonth(),
          this.punchlistV2.TargetDate.getDate()
        );
      }
      if (this.punchlistV2.StartDate) {
        this.punchlistV2.StartDate = new Date(this.punchlistV2.StartDate);
        this.punchlistV2.StartDate = new Date(
          this.punchlistV2.StartDate.getFullYear(),
          this.punchlistV2.StartDate.getMonth(),
          this.punchlistV2.StartDate.getDate()
        );
      }
      if (this.punchlistV2.CompleteDate) {
        this.punchlistV2.CompleteDate = new Date(this.punchlistV2.CompleteDate);
        this.punchlistV2.CompleteDate = new Date(
          this.punchlistV2.CompleteDate.getFullYear(),
          this.punchlistV2.CompleteDate.getMonth(),
          this.punchlistV2.CompleteDate.getDate()
        );
      }

      this.punchlistService
        .updatePunchlistV2(this.isadd, this.punchlistV2)
        .subscribe(
          (result) => {
            this.updateCountsService.update();
            let yFilter = this.previousResources.map((itemY) => {
              return itemY.Id;
            });
            let filteredAssignTo = this.punchlistV2.Resources.filter(
              (itemX) => !yFilter.includes(itemX.Id)
            );
            if (filteredAssignTo.length > 0) {
              this.assignResourceModel = new JobResourcesRequestPayload();
              this.assignResourceModel.Resources = filteredAssignTo;
              this.assignResourceModel.JobPhaseId = this.punchlistV2.ID;
              this.assignResourceModel.JobID = this.punchlistV2.JobID;
              this.workOrderService
                .saveAssignedResource(this.assignResourceModel)
                .subscribe((result) => {
                  this.result(
                    result,
                    this.isadd
                      ? "Added Successfully!!"
                      : "Updated Successfully!!",
                    "Punchlist"
                  );
                  this.closeSlide.emit(true);
                });
            } else {
              this.result(
                result,
                this.isadd ? "Added Successfully!!" : "Updated Successfully!!",
                "Punchlist"
              );
              this.closeSlide.emit(true);
            }
            this._spinner.hide();
          },
          (error) => this.error(error, "Unable to save Punchlist")
        );
    } else {
      this._spinner.hide();
    }
  }
  changeStatus() {
    if (this.punchlistV2.StatusId === 15) {
      if (!this.punchlistV2.StartDate) {
        this.punchlistV2.CompleteDate = null;
        this.punchlistV2.StartDate = new Date();
      } else {
        this.punchlistV2.CompleteDate = null;
      }
    } else if (
      this.punchlistV2.StatusId === 16 &&
      !this.punchlistV2.CompleteDate
    ) {
      this.punchlistV2.StartDate = new Date();
      this.punchlistV2.CompleteDate = new Date();
    } else {
      this.punchlistV2.StartDate = null;
      this.punchlistV2.CompleteDate = null;
    }
  }
  deleteAssignedResource(assignedId) {
    var assignedTo = this.punchlistV2.Resources.find(
      (obj) => obj.Id == assignedId
    );
    var index = this.punchlistV2.Resources.findIndex(
      (obj) => obj.Id == assignedId
    );
    const disposable = this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Delete Assigned Resource",
        // tslint:disable-next-line:prefer-template
        message:
          "Are you sure you want to delete assigned resource" +
          " " +
          assignedTo.Name +
          "?",
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this._spinner.show();
          this.workOrderService
            .deleteAssignedResources(
              assignedId,
              this.punchlistV2.JobID,
              this.punchlistV2.ID,
              assignedTo.IsExternal
            )
            .subscribe(
              (result) => {
                this.result(
                  result,
                  "Deleted Successfully!!",
                  "Assign Resource"
                );
                this.punchlistV2.Resources.splice(index, 1);
                this.lstAssignedResource = JSON.parse(
                  JSON.stringify(this.punchlistV2.Resources)
                );
                this._spinner.hide();
              },
              (error) => this.error(error, "Unable to Delete assigned resource")
            );
          this._spinner.hide();
        }
      });
  }

  getInitals(str) {
    if (!str) return "";
    var result = str
      .split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), "");
    if (result && result.length > 2) {
      return result.slice(0, 2);
    }
    return result;
  }
  editStartDate(IsEdit) {
    if (IsEdit) {
      this.EnableStartDateEdit = true;
      this.previousStartDate = this.punchlistV2.StartDate;
    } else {
      this.EnableStartDateEdit = false;
      this.punchlistV2.StartDate = this.previousStartDate;
    }
  }
  ngOnDestroy() {
    if (this.isNewAssigned) {
      this.closeSlide.emit(true);
    }
  }
}
