import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Router, NavigationEnd, Routes } from "@angular/router";
import { Subscription } from "rxjs";

import { BaMenuService } from "../../services";
import { GlobalState } from "../../../global.state";
import { SharedService } from "app/common/utility/SharedService";
import { UserRoleEnum } from "app/model/Core/UserRoleEnum";
import { SignNowService } from "app/common/services/signNow.service";
import { ToastrService } from "ngx-toastr";
import { PAGES_MENU } from "app/pages/pages.menu";
import { SettingService } from "app/common/services/settings.service";

@Component({
  selector: "ba-menu",
  templateUrl: "./baMenu.html",
  styleUrls: ["./baMenu.scss"],
})
export class BaMenu {
  @Input() sidebarCollapsed: boolean = false;
  @Input() menuHeight: number;

  @Output() expandMenu = new EventEmitter<any>();
  @Output() SlideOutEvent = new EventEmitter<any>();
  public menuItems: any[];
  protected _menuItemsSub: Subscription;
  public showHoverElem: boolean;
  public hoverElemHeight: number;
  public hoverElemTop: number;
  protected _onRouteChange: Subscription;
  public outOfArea: number = -200;
  public showSlideOut: boolean = false;
  private readonly _orgGuid = this.sharedService.selectedOrganization.ID;
  connected: boolean = true;
  finishedLoading: boolean = false;
  private subscriptionName: Subscription;
  private childrenAdminMenu: any[];
  private hideWipAreas: boolean = false;
  private hideSalesAreas: boolean = false;
  constructor(
    private _router: Router,
    private _service: BaMenuService,
    private _state: GlobalState,
    private sharedService: SharedService,
    private signNowService: SignNowService,
    private toastrService: ToastrService,
    private settingService: SettingService
  ) {}

  public updateMenu(newMenuItems) {
    this.menuItems = newMenuItems;
    this.selectMenuAndNotify();
  }

  public selectMenuAndNotify(): void {
    if (this.menuItems) {
      this.menuItems = this._service.selectMenuItem(this.menuItems);
      let configObj = this.sharedService.loggedInUser.CustomerRoles;
      let hasDashBoardRole = configObj.find((x) => x.Id === 0);
      // 0 = Executive, 1 = System Admin, 2 = Admin, 3 = Chat, 4 = Sales Manager, 5 = Sales
      let hasAdminRole = configObj.some(
        (x) =>
          x.Id === UserRoleEnum.Admin ||
          x.Id === UserRoleEnum.SuperAdmin ||
          x.Id === UserRoleEnum.Executive
      );
      let isSales = configObj.find(
        (x) =>
          x.Id == UserRoleEnum.SalesManager ||
          x.Id == UserRoleEnum.SalesRep ||
          x.Id == UserRoleEnum.SalesContact
      );

      // let hasSalesRole = configObj.find(x => x.Id <= 5) !== undefined;
      let useGoogleCalendar =
        this.sharedService.loggedInUser.Org.UseGoogleCalendar;

      if (!this.connected) {
        this.menuItems.forEach((x) => {
          if (x.title === "Administration") {
            this.childrenAdminMenu = JSON.parse(JSON.stringify(x.children));
            let index = x.children.findIndex((x) => x.title === "E-Templates");
            if (index > -1) x.children.splice(index, 1);

            index = x.children.findIndex((x) => x.title === "Document Fields");
            if (index > -1) x.children.splice(index, 1);
          }
        });
      }

      if (
        this.hideWipAreas &&
        !this.hideSalesAreas &&
        !hasAdminRole &&
        isSales
      ) {
        this.menuItems = this.menuItems.filter((x) => x.title !== "Jobs (WIP)");
        this.menuItems = this.menuItems.filter(
          (x) => x.title !== "general.menu.LiveCamera"
        );
      }

      if (this.hideSalesAreas && !this.hideWipAreas && !hasAdminRole) {
        this.menuItems = this.menuItems.filter((x) => x.title !== "Sales");
        this.menuItems = this.menuItems.filter(
          (x) => x.title !== "general.menu.Estimate"
        );
      }

      if (hasDashBoardRole && configObj.length == 1) {
        this.menuItems = this.menuItems.filter((x) => x.title === "Dashboard");
      } else {
        var hasRole = configObj.find((x) => x.Id === 1);
        if (!hasRole) {
          this.menuItems = this.menuItems.filter(
            (x) => x.title !== "Configuration"
          );
        }

        if (!hasDashBoardRole) {
          this.menuItems = this.menuItems.filter(
            (x) => x.title !== "Dashboard"
          );
        }

        if (!hasAdminRole) {
          this.menuItems = this.menuItems.filter(
            (x) => x.title !== "Administration"
          );
        }

        // Access only for Admin, SuperAdmin and Executive User at Menu "Job Setup"
        this.ExcludeFatherItemMenuToAdminUsers(
          hasAdminRole,
          "general.menu.JobSetup"
        );

        // Access Only for SuperAdmin
        if (!this.sharedService.user.isSuperAdmin) {
          let menuItems = [...this.menuItems];
          let items = menuItems.find((x) => x.title == "Administration");
          if (items) {
            let children = items.children;
            children = children.filter(
              (x) => x.title !== "general.menu.userRole"
            );
            this.menuItems.forEach((x, i) => {
              if (x.title == "Administration") {
                x.children = children;
              }
            });
          }
        }

        // Access only for Admin and Accounting Role
        let isAccounting = configObj.find(
          (x) => x.Id == UserRoleEnum.Accounting
        );
        let isAccountRole =
          hasAdminRole || isAccounting != undefined ? true : false;
        if (!isAccountRole) {
          this.menuItems = this.menuItems.filter(
            (x) => x.title !== "Accounting"
          );
        }

        // Access only for Admin and Sales Role
        let isSalesRole = hasAdminRole || isSales != undefined ? true : false;
        if (isSalesRole) {
          if (!useGoogleCalendar) {
            this.menuItems.forEach((x) => {
              if (x.title === "Sales") {
                x["children"] = x["children"].filter(
                  (y) => y.title !== "Calendar"
                );
              }
            });
          }
        } else {
          var index = this.menuItems.findIndex((x) => x.title == "Sales");
          if (index > -1) this.menuItems.splice(index, 1);
        }

        // this.ExcludeChildItemMenuToAdminUsers(hasAdminRole, "general.menu.Estimate", "Worksheets");
        // this.ExcludeChildItemMenuToAdminUsers(hasAdminRole, "general.menu.Estimate", "Contracts");
      }
    }

    this._state.notifyDataChanged(
      "menu.activeLink",
      this._service.getCurrentItem()
    );
  }

  public async ngOnInit(): Promise<any> {
    this.setOrganizationSettings();
    try {
      // await this.verifyOrg();
      this.initData();

      this.subscriptionName = this.signNowService
        .statusFromSignNow()
        .subscribe((isConnected) => {
          this.connected = isConnected;
          this._service.updateMenuByRoutes(<Routes>PAGES_MENU);
          this.selectMenuAndNotify();
        });
    } catch (error) {
      // console.log(error);
      this.initData();
      // this.retryCall();
    }
  }

  initData() {
    this._onRouteChange = this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.menuItems) {
          this.selectMenuAndNotify();
        } else {
          // on page load we have to wait as event is fired before menu elements are prepared
          setTimeout(() => this.selectMenuAndNotify());
        }
      }
    });

    this._menuItemsSub = this._service.menuItems.subscribe(
      this.updateMenu.bind(this)
    );
  }

  retryCall() {
    setTimeout(async () => {
      try {
        await this.verifyOrg();
        this.menuItems.forEach((x) => {
          if (x.title === "Administration") {
            x.children = this.childrenAdminMenu;
          }
        });
      } catch (error) {
        this.retryCall();
      }
    }, 5000);
  }

  public ngOnDestroy(): void {
    this._onRouteChange.unsubscribe();
    this._menuItemsSub.unsubscribe();
    this.subscriptionName.unsubscribe();
  }

  public hoverItem($event): void {
    this.showHoverElem = true;
    this.hoverElemHeight = $event.currentTarget.clientHeight;
    // TODO: get rid of magic 66 constant
    this.hoverElemTop = $event.currentTarget.getBoundingClientRect().top - 66;
  }
  public slideOut($event): void {
    this.showSlideOut = true;
    this.SlideOutEvent.emit($event);
  }

  public toggleSubMenu($event): boolean {
    let submenu = jQuery($event.currentTarget).next();

    if (this.sidebarCollapsed) {
      this.expandMenu.emit(null);
      if (!$event.item.expanded) {
        $event.item.expanded = true;
      }
    } else {
      $event.item.expanded = !$event.item.expanded;
      submenu.slideToggle();
    }

    return false;
  }

  private ExcludeFatherItemMenuToAdminUsers(
    hasAdminRole: boolean,
    fatherItemMenu: string
  ) {
    if (!hasAdminRole) {
      this.menuItems = this.menuItems.filter((x) => x.title !== fatherItemMenu);
    }
  }

  private ExcludeChildItemMenuToAdminUsers(
    hasAdminRole: boolean,
    fatherItemMenu: string,
    childItemMenu: string
  ) {
    if (!hasAdminRole) {
      let menuItems = [...this.menuItems];
      let items = menuItems.find((x) => x.title == fatherItemMenu);
      if (items) {
        let children = items.children;
        children = children.filter((x) => x.title !== childItemMenu);
        this.menuItems.forEach((x, i) => {
          if (x.title == fatherItemMenu) {
            x.children = children;
          }
        });
      }
    }
  }

  /** Verifies if the organization is registered in the db */
  private async verifyOrg() {
    this.connected = await this.signNowService
      .verifyOrg(this._orgGuid)
      .toPromise();
    this.finishedLoading = true;
  }

  /**
   * Sets the organization settings
   */
  private setOrganizationSettings() {
    const hideWipAreas = "HIDE_WIP_AREAS";
    const hideSalesAreas = "HIDE_SALES_AREAS";
    this.hideWipAreas = this.settingService.getBooleanValue(
      this.sharedService.organizationSettings,
      hideWipAreas,
      false
    );
    this.hideSalesAreas = this.settingService.getBooleanValue(
      this.sharedService.organizationSettings,
      hideSalesAreas,
      false
    );
  }
}
