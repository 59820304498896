<p-toast
  [styleClass]="isSuccess ? 'custom-toast-success' : 'custom-toast-error'"
  position="top-right"
></p-toast>
<!-- <p-toast *ngIf="isError" styleClass="custom-toast-error"  position="top-right"></p-toast> -->
<div class="widgets commonFixedSidebar">
  <div class="row">
    <div class="col-md-12 ht100">
      <div class="rightcontent">
        <div class="sideBarHeader">
          <h1>Checklist Items</h1>
        </div>

        <div class="tabBlk">
          <div style="padding: 60px 10px 10px 10px !important">
            <div class="row">
              <div
                class="col-lg-12 col-md-12 col-sm-12"
                style="height: calc(100vh - 120px)"
              >
                <button
                  *ngIf="canEdit"
                  ngDefaultControl
                  name="complete"
                  class="btn darkbtn"
                  style="float: right; margin-top: 15px"
                  (click)="clickStatus()"
                  [disabled]="countRequired > 0 || isDirty"
                  [(ngModel)]="status"
                >
                  {{ statusBtnTxt }}
                </button>
                <div style="width: 75%; margin-bottom: -10px">
                  <div class="col-12" style="margin-bottom: 15px">
                    <label>Checklist is assigned to: </label><br />

                    <kendo-dropdownlist
                      class="col-7"
                      name="employees"
                      kendoTextBox
                      [data]="employees"
                      textField="Name"
                      valueField="CustomerGuid"
                      [(ngModel)]="assignTo"
                      (selectionChange)="assignToChanged($event)"
                      [disabled]="!canEdit"
                    >
                    </kendo-dropdownlist>
                    <a
                      class="col-1 actionLbl"
                      *ngIf="assignToHasChanged && canEdit"
                      (click)="assignToCancelled()"
                      >Cancel</a
                    >
                    <a
                      class="col-1 actionLbl"
                      *ngIf="
                        assignToCanRemove &&
                        !assignToHasChanged &&
                        canEdit &&
                        checklist.status == 0
                      "
                      (click)="assignToCleared()"
                      >Remove</a
                    >
                    <a
                      class="col-1 actionLbl"
                      *ngIf="assignToHasChanged && canEdit"
                      (click)="assignToConfirmed()"
                      >Update</a
                    >
                    <a class="col-1" *ngIf="showPrint" (click)="printParent()">
                      <i class="icon icon-printer1"> </i>
                    </a>
                  </div>
                </div>

                <div
                  *ngIf="
                    checklist.status == 1 &&
                    checklist.completed.toString().indexOf('Z') == -1
                  "
                >
                  <label
                    >Checklist was marked completed on
                    {{ checklist.completed + "Z" | date : "medium" }}</label
                  >
                </div>
                <div
                  *ngIf="
                    checklist.status == 1 &&
                    checklist.completed.toString().indexOf('Z') != -1
                  "
                >
                  <label
                    >Checklist was marked completed on
                    {{ checklist.completed | date : "medium" }}</label
                  >
                </div>

                <div class="x_panel">
                  <div class="x_content">
                    <div class="kendoheight2">
                      <div
                        [ngClass]="{ customDisabled: !canEdit }"
                        style="margin: 5px 0 0 10px"
                      >
                        <kendo-grid
                          [data]="checklistItems"
                          [style.height.%]="100"
                          [pageable]="false"
                          [pageSize]="10"
                          #grid="kendoGrid"
                          scrollable="virtual"
                        >
                          <kendo-grid-column
                            title="Custom Field"
                            class="text-left"
                          >
                            <ng-template
                              kendoGridCellTemplate
                              let-dataItem="dataItem"
                            >
                              {{ dataItem.name }}
                              <label
                                style="color: red"
                                *ngIf="dataItem.required"
                              >
                                *</label
                              >
                            </ng-template>
                          </kendo-grid-column>
                          <kendo-grid-column
                            width="200"
                            title="Value"
                            class="text-center"
                          >
                            <ng-template
                              kendoGridCellTemplate
                              let-dataItem="dataItem"
                            >
                              <textarea
                                *ngIf="dataItem.type == 1"
                                kendoTextArea
                                style="width: 98%; height: 45px !important"
                                type="text"
                                name="dataItem.result"
                                [(ngModel)]="dataItem.result"
                                (keyup)="this.isDirty = true"
                                (onblur)="CheckForRequiredFields()"
                                [disabled]="!canEdit"
                              >
                              </textarea>
                              <!-- <input
                        kendoTextBox
                        style="width: 98%; "
                        *ngIf="dataItem.type == 1"
                        type="text"
                        name="dataItem.result"
                        [(ngModel)]="dataItem.result"
                        (keyup)="this.isDirty = true"
                      /> -->

                              <kendo-dropdownlist
                                [disabled]="!canEdit"
                                kendoTextBox
                                *ngIf="dataItem.type == 2"
                                name="combobox"
                                [data]="dataItem.selectionItems"
                                textField="value"
                                valueField="id"
                                [defaultItem]="defaultItem"
                                [(ngModel)]="dataItem.selectedResult"
                                (selectionChange)="
                                  SelectionChanged($event, dataItem)
                                "
                              >
                              </kendo-dropdownlist>
                              <kendo-dropdownlist
                                kendoTextBox
                                *ngIf="dataItem.type == 3"
                                name="checkbox"
                                [data]="dataItem.selectionItems"
                                textField="value"
                                valueField="id"
                                [defaultItem]="defaultItem"
                                [(ngModel)]="dataItem.selectedResult"
                                [disabled]="!canEdit"
                                (selectionChange)="
                                  SelectionChanged($event, dataItem)
                                "
                              >
                              </kendo-dropdownlist>
                            </ng-template>
                          </kendo-grid-column>

                          <!-- <kendo-grid-column field="requiredText" title="Required" class="text-center"
              width="70">
            </kendo-grid-column>         -->
                        </kendo-grid>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="requirednotify" *ngIf="Notify">
                  *One or more of the checklist items is required*
                </div>
              </div>
            </div>

            <div class="fixbtnwrap">
              <div class="btnwrap">
                <button
                  type="button"
                  class="btn darkbtn"
                  [hidden]="!isDirty"
                  (click)="onSave()"
                >
                  <i class="fa fa-floppy-o" aria-hidden="true"></i> Save
                </button>
                <button
                  type="button"
                  class="btn btn-cancel"
                  (click)="onCancel()"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-sidebar
  [(visible)]="visibleSidebar"
  class="addeditclient"
  position="left"
  (onHide)="onHideSliderHide()"
  autoZIndex="true"
  [baseZIndex]="10000"
  appendTo="body"
>
  <app-general-reports
    *ngIf="slideBar == 'reports'"
    [reportData]="reportModel"
  ></app-general-reports>
</p-sidebar>
