import {
  NgModule,
  ApplicationRef,
  LOCALE_ID,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MessageService } from "primeng/components/common/messageservice";
import { GrowlModule, CalendarModule } from "primeng/primeng";
import {
  MultiSelectModule,
  EditorModule,
  InputTextModule,
  CardModule,
} from "primeng/primeng";
import { BootstrapModalModule } from "ng2-bootstrap-modal";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ConfirmComponent } from "./component/dialog/dialog.component";
import { PipesModule } from "app/shared/pipes.module";

/*
 * Platform and Environment providers/directives/pipes
 */
import { routing } from "./app.routing";

// App is our top level component
import { App } from "./app.component";
import { AppState, InternalStateType } from "./app.service";
import { GlobalState } from "./global.state";
import { NgaModule } from "./theme/nga.module";
import { PagesModule } from "./pages/pages.module";
import { SharedService } from "app/common/utility/SharedService";
import { ConfigService } from "app/common/services/configService";
import { DragulaModule } from "ng2-dragula";
import { NotifyService } from "app/common/utility/notify.service";
import { NotificationsModule } from "./component/notifications/notifications.module";
import { ChatModule } from "./component/Chat/Chat.module";
import { ScheduleErrorDialogComponent } from "./component/schedule-error-dialog/schedule-error-dialog.component";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { CalendarMoreOptionComponent } from "./component/calendar-more-option/calendar-more-option.component";
import { SalesCalendarShareComponent } from "./pages/sales/salesCalendar/sales-calendar-share/sales-calendar-share.component";
import { AddCalendarPopupComponent } from "./component/add-calendar-popup/add-calendar-popup.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MenuModule } from "@progress/kendo-angular-menu";
import { LoaderService } from "./common/utility/loader.service";
import { ToastrService, ToastrModule } from "ngx-toastr";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PaymentService } from "./common/services/payment.service";
import { WeatherService } from "./common/services/weather.service";
import { DatePipe } from "@angular/common";
import { ToastModule } from "primeng/toast";
import { KendoGridReorderService } from "./common/kendo/kendo-grid-reorder.service";
import { LocalStorageService } from "./common/services/localStorage.service";
import { ChecklistDialogComponent } from "./component/Checklist/checklist-dialog/checklist-dialog.component";
import { DialogMovePhaseComponent } from "./component/dialog-move-phase/dialog-move-phase.component";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { JwtAuthInterceptor } from "./common/interceptors/jwt-auth.interceptor";
import { ConfirmDeleteFileTypeComponet } from "./component/dialog-delete-file-type/dialog-delete-file-type.component";
import { LabelModule } from "@progress/kendo-angular-label";
import { RedirectModule } from "./shared/redirect.module";
import { DocumentService } from "./common/services/document.service";

// Application wide providers
const APP_PROVIDERS = [AppState, GlobalState];

export type StoreType = {
  state: InternalStateType;
  restoreInputValues: () => void;
  disposeOldHosts: () => void;
};
export function configFactory(config: ConfigService) {
  return () => config.load();
}

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [App],
  declarations: [
    App,
    ConfirmComponent,
    ScheduleErrorDialogComponent,
    DialogMovePhaseComponent,
    ConfirmDeleteFileTypeComponet,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    // import Angular's modules
    BrowserModule,
    PipesModule,
    DragulaModule.forRoot(),
    ChatModule.forRoot(),
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgaModule.forRoot(),
    NgbModule.forRoot(),
    PagesModule,
    routing,
    GrowlModule,
    MultiSelectModule,
    CardModule,
    EditorModule,
    BootstrapModalModule,
    BrowserAnimationsModule,
    InputTextModule,
    CalendarModule,
    NotificationsModule,
    DateInputsModule,
    MenuModule,
    DateInputsModule,
    ToastrModule.forRoot(),
    ToastModule,
    PerfectScrollbarModule,
    DropDownsModule,
    LabelModule,
    RedirectModule,
  ],
  providers: [
    // expose our Services and Providers into Angular's dependency injection
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configFactory,
      deps: [ConfigService],
      multi: true,
    },
    APP_PROVIDERS,
    { provide: LOCALE_ID, useValue: "en-US" },
    { provide: HTTP_INTERCEPTORS, useClass: JwtAuthInterceptor, multi: true },
    MessageService,
    SharedService,
    NotifyService,
    LoaderService,
    ToastrService,
    PaymentService,
    WeatherService,
    DatePipe,
    KendoGridReorderService,
    LocalStorageService,
    DocumentService,
  ],
  entryComponents: [
    ConfirmComponent,
    ChecklistDialogComponent,
    ScheduleErrorDialogComponent,
    CalendarMoreOptionComponent,
    SalesCalendarShareComponent,
    AddCalendarPopupComponent,
    DialogMovePhaseComponent,
    ConfirmDeleteFileTypeComponet,
  ],
})
export class AppModule {
  constructor(public appState: AppState, applicationRef: ApplicationRef) {
    // for ng2-bootstrap-modal in angualar 6
    Object.defineProperty(applicationRef, "_rootComponents", {
      get: () => applicationRef["components"],
    });
  }
}
