import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { map, mergeMap, toArray } from "rxjs/operators";
import { from } from "rxjs";
import { CommonDataService } from "app/common/services/commonData.service";
import { JobService } from "app/common/services/job.service";
import { JobListModel } from "app/model/JobListModel";
import { CustomerModel } from "app/model/customerModel";

@Component({
  selector: "job-grid",
  templateUrl: "./JobGrid.component.html",
  styleUrls: ["./JobGrid.component.scss"],
})
export class JobGridComponent implements OnInit {
  @Input() customer: CustomerModel;
  @Output() closed = new EventEmitter<boolean>();

  jobList: JobListModel[];

  constructor(
    private commonDataService: CommonDataService,
    private jobService: JobService
  ) {}

  ngOnInit() {
    this.getJobs();
  }

  getJobs() {
    this.jobService
      .getAllJobsByCustomerId(this.customer.Id)
      .pipe(
        mergeMap((response) => {
          return from(response).pipe(
            map((x) => {
              x.CreatedOn = new Date(x.CreatedOn);
              this.commonDataService.getStatusById(x.JobStatusId).then((y) => {
                x.JobStatus = y;
              });
              return x;
            })
          );
        }),
        toArray(),
        map((z) => (this.jobList = z))
      )
      .subscribe();
  }

  getJobtypeDisplayName(jobTypeId) {
    let jobTypeDisplayName = "";

    switch (jobTypeId) {
      case 0:
        jobTypeDisplayName = "All";
        break;
      case 1:
        jobTypeDisplayName = "SalesCycle";
        break;
      case 2:
        jobTypeDisplayName = "Construction";
        break;
      case 3:
        jobTypeDisplayName = "Punchlist";
        break;
      case 4:
        jobTypeDisplayName = "Work Order";
        break;
    }

    return jobTypeDisplayName;
  }

  // ***************************************************************
  // Events
  // ***************************************************************
  reloadGrid(e) {
    e.preventDefault();
    this.getJobs();
  }

  viewDetail(itemGuid) {
    window.open("./#/pages/jobs/jobEdit/" + itemGuid);
  }

  onCancel() {
    this.closed.emit(true);
  }
}
