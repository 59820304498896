import { JobFileModel } from "./jobFile";

export class JobPhotoModel extends JobFileModel {
  DisplayCreatedOn: string;
  UploadedOn?: Date;
}
export class JobPhotoPaginationModel {
  items: JobPhotoModel[];
  page: number;
  pageSize: number;
  totalItemCount: number;
  constructor() {}
}
