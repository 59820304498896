import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EnumJobFileTypes } from "app/common/directives/commonEnum";
import { EstimateWorksheetSharedDataService } from "app/common/services/estimate-worksheet-sharedData.service";
import { JobFileService } from "app/common/services/jobFile.service";
import { SharedService } from "app/common/utility/SharedService";
import { ConfirmComponent } from "app/component/dialog/dialog.component";
import { ShareJobfileDialogComponent } from "app/component/share-jobfile-dialog/share-jobfile-dialog.component";
import { UserModel } from "app/model/Core/UserModel";
import { CustomerModel } from "app/model/customerModel";
import {
  JobFile,
  JobFileNotes,
  JobFileUpdate,
  WorksheetFileData,
} from "app/model/WorksheetFile";
import { BaThemeSpinner } from "app/theme";
import { DialogService } from "ng2-bootstrap-modal";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";

type JobFileOrEmpty = JobFile | "";

@Component({
  selector: "job-file-crud",
  templateUrl: "./job-file-crud.component.html",
  styleUrls: ["./job-file-crud.scss"],
})
export class JobFileCrudComponent implements OnInit, OnDestroy {
  readonly enumJobFileTypes = EnumJobFileTypes;

  @Input()
  listJobFile: JobFileOrEmpty[] = [];
  @Input()
  orgGuid: string = "";
  @Input()
  jobGuid: string = "";
  @Input()
  generalTypeFile: EnumJobFileTypes = null;
  @Input()
  hasActivity: boolean = false;
  @Input() estimateId: number = 0;

  @Output()
  updateImage: EventEmitter<any> = new EventEmitter();
  @Output()
  deleteImage: EventEmitter<any> = new EventEmitter();
  @Output()
  newPhotos: EventEmitter<any> = new EventEmitter();
  @Output()
  newFiles: EventEmitter<any> = new EventEmitter();

  listTags: any[] = [];
  title: string = "";
  typeFile: string = "";
  fileForm: JobFile = null;
  versions: any[] = [];
  comments: JobFileNotes[] = [];

  alive = true;
  itemSelected = null;
  listItems: any[] = [
    { id: 1, value: "Tiles" },
    { id: 2, value: "List" },
  ];
  dialogAddPhoto: boolean = false;
  dialogEdit: boolean = false;
  dialogCarousel: boolean = false;
  imageSelectedCarousel: number;
  jobFileForm: FormGroup;
  jobFileNoteForm: FormGroup;
  user: UserModel = null;
  submittedJobFile: boolean = false;
  submittedJobFileNote: boolean = false;
  signatureName: CustomerModel;
  isLocked: boolean;
  allowUploadPhoto: boolean = true;
  uploadPlaceholderList: string[] = [];
  maxImagesUpload: number = 2;
  worksheetFileData: WorksheetFileData;
  titleSizeMessage: string = "Recommended Size: 720 x 300";

  constructor(
    private fb: FormBuilder,
    private jobFileService: JobFileService,
    private _spinner: BaThemeSpinner,
    private toastrService: ToastrService,
    private sharedService: SharedService,
    private dialogService: DialogService,
    private worksheetSharedData: EstimateWorksheetSharedDataService
  ) {}

  ngOnInit() {
    this.setTypeView();
    this.setAvailableTags();
    this.setCurrentUser();
    this.setTitle();
    this.setTypeFile();
    this.setAllowUploadPhoto();
    this.isLocked = this.worksheetSharedData.isLocked;
  }

  ngOnDestroy() {
    this.alive = false;
  }

  // #region set and get fields

  setCurrentUser(): void {
    this.user = this.sharedService.user;
  }

  setTypeView(): void {
    this.itemSelected =
      this.generalTypeFile === EnumJobFileTypes.Other
        ? { id: 2, value: "List" }
        : this.generalTypeFile === EnumJobFileTypes.UserPhotos && {
            id: 1,
            value: "Tiles",
          };
  }

  setAvailableTags(): void {
    this.jobFileService
      .getAllJobFileTagByOrgGuid(this.orgGuid)
      .subscribe(
        (x) =>
          (this.listTags = x.map((y) => ({ label: y.FileTag, value: y.Id })))
      );
  }

  setTitle(): void {
    this.title =
      this.generalTypeFile === EnumJobFileTypes.Other
        ? "Files"
        : this.generalTypeFile === EnumJobFileTypes.UserPhotos &&
          "Proposal Photo";
  }

  setAllowUploadPhoto() {
    if (
      this.generalTypeFile === EnumJobFileTypes.UserPhotos &&
      this.listJobFile.length >= 2
    )
      this.allowUploadPhoto = false;

    this.setJobFileList();
  }

  setTypeFile(): void {
    this.typeFile =
      this.generalTypeFile === EnumJobFileTypes.Other
        ? "File"
        : this.generalTypeFile === EnumJobFileTypes.UserPhotos && "Photo";
  }

  get f() {
    return this.jobFileForm.controls;
  }

  get fn() {
    return this.jobFileNoteForm.controls;
  }

  // #endregion

  // #region methods

  addFile(order): void {
    if (this.isLocked || !this.allowUploadPhoto) {
      return;
    }
    this.dialogAddPhoto = true;
    this.worksheetFileData = {
      estimateId: this.estimateId,
      order: order,
    };
  }

  deleteFile(file: JobFile): void {
    const isPhoto: boolean =
      this.generalTypeFile === this.enumJobFileTypes.UserPhotos;
    const photoName = this.getNameFile(file);
    this.dialogService
      .addDialog(ConfirmComponent, {
        title: isPhoto ? `Delete Photo` : `Delete File`,
        message: isPhoto
          ? `Once the photo is deleted the action cannot be undone`
          : `Once the file is deleted the action cannot be undone`,
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this._spinner.show();
          this.jobFileService
            .deletefilePhoto(file.id)
            .subscribe(
              () => this.deleteImage.emit(file.id),
              (error) => this.error(error, "Error deleting file.")
            )
            .add(() => this._spinner.hide());
        }
      });
  }

  downloadFile(file: JobFile): void {
    this._spinner.show();
    fetch(file.blob.url, {
      headers: new Headers({
        Origin: location.origin,
      }),
      mode: "cors",
    })
      .then((response) => response.blob())
      .then((blob) => {
        const a = document.createElement("a");
        a.download = this.getNameFile(file);
        a.href = window.URL.createObjectURL(blob);
        document.body.appendChild(a);
        a.click();
        a.remove();
        this._spinner.hide();
      })
      .catch((error) => {
        this._spinner.hide();
        this.error(error, "Error download file");
        console.error(error);
      });
  }

  clickImage(index: number): void {
    this.imageSelectedCarousel = index;
    this.dialogCarousel = true;
  }

  editFile(file: JobFile): void {
    this.fileForm = file;
    this.getAllCommentsByJobFileId(file.id);
    this.configForm(file);
    this.comments = [...file.jobFileNotes.reverse()];
    this.signatureName = this.fileForm.createdBy;
    this.dialogEdit = true;
  }

  shareFile(file: JobFile): void {
    this.dialogService.addDialog(ShareJobfileDialogComponent, {
      jobFileId: file.id,
      jobId: file.jobGuid,
    });
  }

  saveComment(): void {
    this.submittedJobFileNote = true;
    if (this.jobFileNoteForm.invalid) {
      return;
    }
    const form = this.jobFileNoteForm.value;
    const model = {
      JobFileId: this.fileForm.id,
      Created: new Date(Date.now()),
      // Title: form.title,
      Note: form.note,
      JobFileGuid: this.fileForm.guid,
    };
    this._spinner.show();
    this.jobFileService
      .postJobFileNoteByGuid(model)
      .subscribe(
        (x) => {
          this.jobFileNoteForm.patchValue({ title: null, note: null });
          this.submittedJobFileNote = false;
          this.comments.unshift({
            id: null,
            // title: model.Title,
            note: model.Note,
            created: model.Created,
          } as JobFileNotes);
        },
        (error) => this.error(error, "Error saving Job File Note information")
      )
      .add(() => this._spinner.hide());
  }

  save(): void {
    this.submittedJobFile = true;
    if (this.jobFileForm.invalid) {
      return;
    }

    const form = this.jobFileForm.value as JobFile;
    const model: JobFileUpdate = {
      Description: form.description,
      DisplayCreatedOn: new Date(Date.now()),
      FileGuid: form.guid,
      JobFileTags: form.jobFileJobFileTags.map((x) => ({ Id: x })),
      Pinned: form.isPinned,
      Public: form.isPublic,
      Title: form.title,
    };

    this._spinner.show();
    this.jobFileService
      .putJobFileByGuid(model)
      .subscribe(
        (x) => {
          this.submittedJobFile = false;
          this.updateImage.emit(this.fileForm);
          this.dialogEdit = false;
        },
        (error) => this.error(error, "Error updating Job File information")
      )
      .add(() => this._spinner.hide());
  }

  closeForm(): void {
    this.dialogEdit = false;
  }

  photoAdded(list: any): void {
    console.info(list);
  }

  emitEventNewPhotos(model: string[]): void {
    this.newPhotos.emit(model);
  }
  emitEventNewFiles(model: string[]): void {
    this.newFiles.emit(model);
  }

  // #endregion

  // #region private methods

  private extractExtension(fileName: string): string {
    const re = /(?:\.([^.]+))?$/;
    return re.exec(fileName)[1];
  }

  private getNameFile(file: JobFile): string {
    return file.title && file.title.length > 0
      ? `${file.title}.${this.extractExtension(file.blob.fileName)}`
      : this.extractExtension(file.fileName)
      ? file.fileName
      : file.fileName + this.extractExtension(file.blob.fileName);
  }

  // Debo quitar el ForkJoin si no lo necesito al fin
  private getAllCommentsByJobFileId(id: number) {
    this._spinner.show();
    forkJoin([this.jobFileService.getAllVersions(id)]).subscribe(
      ([versions]) => {
        this.versions = versions;
        this._spinner.hide();
      }
    );
  }

  private configForm(model: JobFile) {
    this.jobFileForm = this.fb.group({
      id: [model.id, []],
      fileName: [model.fileName, [Validators.required]],
      jobFileTypeId: [model.jobFileTypeId, [Validators.required]],
      jobGuid: [model.jobGuid, [Validators.required]],
      jobPhaseId: [model.jobPhaseId, []],
      blobId: [model.blobId, [Validators.required]],
      isPublic: [model.isPublic, [Validators.required]],
      isPinned: [model.isPinned, [Validators.required]],
      guid: [model.guid, []],
      description: [model.description, [Validators.maxLength(1000)]],
      title: [model.title, [Validators.required, Validators.maxLength(100)]],
      primary: [model.primary, []],
      sourceId: [model.sourceId, []],
      thumbnailUrl: [model.blob.thumbnailUrl, []],
      jobFileJobFileTags: [
        model.jobFileJobFileTags.map((x) => x.jobFileTagId),
        [],
      ],
    });
    this.jobFileNoteForm = this.fb.group({
      // title: [null, [Validators.required, Validators.maxLength(100)]],
      note: [null, [Validators.required, Validators.maxLength(1000)]],
    });
  }

  private error(error: any, message: string) {
    console.error(error);
    this.toastrService.error(message, "Estimate");
  }

  setJobFileList(): void {
    let viewListJobFile: JobFileOrEmpty[] = [];
    for (let i = 0; i < this.maxImagesUpload; i++) {
      const order = i + 1;
      const jobFileOrEmpty = this.listJobFile.find(
        (x) => this.isJobFile(x) && x.order === order
      );

      if (jobFileOrEmpty) viewListJobFile.push(jobFileOrEmpty);
      else viewListJobFile.push("");
    }

    this.listJobFile = viewListJobFile;
  }

  isJobFile(item: JobFileOrEmpty): item is JobFile {
    return (item as JobFile).order !== undefined;
  }

  // #endregion
}
