import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DialogService } from "ng2-bootstrap-modal";

@Injectable({
  providedIn: "root",
})
export class DocumentService {
  constructor(private dialogService: DialogService, private http: HttpClient) {}

  openFile(fileUrl: string) {
    window.open(fileUrl, "blank_");
  }
}
