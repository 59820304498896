import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";
import { environmentConstant } from "app/common/utility/environment";
import { Observable } from "rxjs";
import { CategoryModel, SystemCategoryModel } from "app/model/categoryModel";
@Injectable()
export class CategoryService {
  constructor(private httpService: HttpService) {}

  getAllCategoriesByOrgId(id: any): Observable<CategoryModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getCategoryByOrgId + id
    );
  }
  getAllSystemCategories() {
    return this.httpService.getRequestService(
      environmentConstant.getSytemCategories
    );
  }
  getCategoriesByEntityId(
    orgId: any,
    entityId: any
  ): Observable<CategoryModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getJobCategoryByOrgId + orgId + "/entity/" + entityId
    );
  }
  getSystemCategoriesByEntityId(entityId: any): Observable<CategoryModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getJobCategoryByOrgId + "/entity/" + entityId
    );
  }
  getCategoryById(id: any): Observable<CategoryModel> {
    return this.httpService.getRequestService(
      environmentConstant.getCategoryById + id
    );
  }
  updateCategory(category: CategoryModel) {
    return this.httpService.putRequestService(
      environmentConstant.updateCategory,
      category
    );
  }
  saveCategory(category: CategoryModel) {
    return this.httpService.postRequestService(
      environmentConstant.saveCategory,
      category
    );
  }
  deleteCategory(id: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.deleteCategory + id
    );
  }

  updateSystemCategory(category: SystemCategoryModel) {
    return this.httpService.putRequestService(
      environmentConstant.updateSystemCategory,
      category
    );
  }
  saveSystemCategory(category: SystemCategoryModel) {
    return this.httpService.postRequestService(
      environmentConstant.saveSystemCategory,
      category
    );
  }
  deleteSystemCategory(id: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.deleteSystemCategory + id
    );
  }
}
