<div>
  <div class="row">
    <form #ChecklistForm="ngForm" class="form-horizontal">
      <div class="col-sm-12">
        <div class="rightcontent card">
          <div class="sideBarHeader">
            <h1>Add Checklists</h1>
          </div>
          <div class="x_panel">
            <div class="x_content">
              <div
                class="kendoheight2 customtable"
                id="checklistgrid"
                style="height: calc(100vh - 120px); padding: 5px"
              >
                <kendo-grid
                  [data]="gridView"
                  [style.height.%]="100"
                  #grid="kendoGrid"
                  [pageable]="false"
                  scrollable="virtual"
                >
                  <kendo-grid-column
                    headerClass="gridHeading"
                    class="gridRow"
                    width="60"
                    field="guid"
                    title=""
                  >
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                      <label class="nowrap ml10">
                        <input
                          type="checkbox"
                          name="selectedchecklist"
                          (change)="selectChecklist($event,dataItem)"
                        />
                        <span></span>
                      </label>
                    </ng-template>
                  </kendo-grid-column>
                  <kendo-grid-column
                    headerClass="gridHeading"
                    class="gridRow"
                    field="name"
                    title="Checklist"
                  >
                  </kendo-grid-column>
                  <!-- <kendo-grid-column
                    headerClass="gridHeading"
                    class="gridRow"
                    field="assignee"
                    title="Assigned To"
                  >
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                      <kendo-dropdownlist
                        name="gridView[index]"
                        kendoTextBox
                        [data]="employees"
                        textField="Name"
                        valueField="CustomerGuid"
                        (selectionChange)="assignToChanged($event,dataItem)"
                      >
                      </kendo-dropdownlist>
                    </ng-template>
                  </kendo-grid-column> -->
                  <kendo-grid-excel fileName="Checklists.xlsx">
                  </kendo-grid-excel>
                </kendo-grid>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="fixbtnwrap">
        <div class="btnwrap__center">
          <button
            type="button"
            class="btn darkbtn"
            [disabled]="!dirty || isSubmitting"
            (click)="onSubmit()"
          >
            <i class="fa fa-floppy-o"></i>
            Save
          </button>
          <button
            type="button"
            class="cross btn bthpn2"
            (click)="closeslideout()"
          >
            {{'common.cancel'|translate}}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
