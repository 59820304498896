import { Component, ChangeDetectorRef } from "@angular/core";
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
export interface ConfirmModel {
  title: string;
  message: string;
  message2?: string;
  isSystem?: boolean;
  isNotification?: boolean;
  fileTypes: any[];
  allFileTypes: any[];
  isUsed: boolean;
}
@Component({
  selector: "confirm",
  templateUrl: "./dialog-delete-file-type.html",
  styleUrls: ["./dialog-delete-file-type.component.scss"],
})
export class ConfirmDeleteFileTypeComponet
  extends DialogComponent<ConfirmModel, any>
  implements ConfirmModel {
  title: string;
  message: string;
  message2: string;
  isSystem?: boolean = false;
  isNotification?: boolean = false;
  changeDetectorRef: ChangeDetectorRef;
  fileTypes: any[] = [];
  allFileTypes: any[] = [];
  selectedFileType: string = "";
  isUsed: boolean = false;

  constructor(
    dialogService: DialogService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(dialogService);
    this.changeDetectorRef = changeDetectorRef;
    const that = this;
    setTimeout(function () {
      that.changeDetectorRef.detectChanges();
    }, 300);
  }

  confirm() {
    // we set dialog result as true on click on confirm button,
    // then we can get dialog result from caller code
    this.result = {
      confirmed: true,
      selectedFileType: this.selectedFileType,
    };
    this.close();
  }

  onSelectFileType(Id: any) {
    this.selectedFileType = Id;
  }

  filterFileTypes(fileType: string) {
    this.fileTypes = this.allFileTypes.filter((item: any) =>
      item.FileTypeName.toLowerCase().includes(fileType.toLowerCase())
    );
  }
}
