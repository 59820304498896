import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { TodoListComponent } from "app/pages/account/todo/todo-list/todo-list.component";
import { TodoItemsComponent } from "app/pages/account/todo/todo-items/todo-items.component";
import { TodoAdvancedComponent } from "app/pages/account/todo/todo-advanced/todo-advanced.component";
import { AssignResourceToDoComponent } from "app/pages/account/todo/AssignResourceToDo/AssignResourceToDo";
import { SharedModule } from "app/shared/shared.module";
import { TodoPermissionsService } from "./todo-permissions.service";

@NgModule({
  declarations: [
    AssignResourceToDoComponent,
    TodoListComponent,
    TodoAdvancedComponent,
    TodoItemsComponent,
  ],
  imports: [SharedModule],
  exports: [
    AssignResourceToDoComponent,
    TodoListComponent,
    TodoAdvancedComponent,
    AssignResourceToDoComponent,
  ],
  providers: [TodoPermissionsService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TodoModule {}
