<div class="widgets commonFixedSidebar" style="padding: 15px">
  <div class="row dropdownRow col-md-12 col-sm-12 col-xs-12">
    <div class="col-md-4 col-sm-4 col-xs-4" style="height: 40px">
      <kendo-dropdownlist
        disabled
        class="align-dropdown icon-background icon-silver"
        [data]="jobTypeData"
        [(ngModel)]="jobType"
        [textField]="'name'"
        [valueField]="'name'"
      >
      </kendo-dropdownlist>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-4" style="height: 40px">
      <kendo-dropdownlist
        class="align-dropdown icon-background icon-silver"
        [data]="calendarData"
        [(ngModel)]="calendarDay"
        [textField]="'name'"
        [valueField]="'id'"
        (selectionChange)="filterByDateSelection($event)"
      >
      </kendo-dropdownlist>
    </div>
    <div class="col-md-4 col-sm-4 col-xs-4 form-group pull-right top_search">
      <div class="input-group">
        <input
          type="text"
          class="inputS"
          [(ngModel)]="searchCriteria"
          (keyup)="filterSearchCriteria()"
          placeholder="{{ 'common.search_for' | translate }}"
        />
        <i class="fa fa-search"></i>
      </div>
    </div>
  </div>
  <div class="customWrapper">
    <a
      >Select:&nbsp;
      <span class="linkColor" (click)="toggleSelectedPhases(true)">All</span>
      <span>&nbsp;/&nbsp;</span>
      <span class="linkColor" (click)="toggleSelectedPhases(false)">None</span>
    </a>
    <div *ngFor="let data of filteredPhases; index as i">
      <div class="phaseStatus" [ngClass]="getStatusClass(data)">&nbsp;</div>
      <div class="card phaseCard">
        <div class="container" style="padding: 5px">
          <div class="row">
            <div class="col-sm" style="max-width: 30px">
              <div>
                <label class="checkbox-inline custom-checkbox nowrap">
                  <input
                    type="checkbox"
                    [checked]="data.phaseSelected"
                    (change)="phaseClicked($event.target.checked, data)"
                  />
                  <span></span>
                </label>
              </div>
            </div>
            <div class="col">
              <div class="row" style="height: 35px">
                <div class="col-9">
                  <label class="titleFont"> {{ data.JobPhaseName }}</label>
                  <br />
                  <label style="font-size: small"> {{ data.JobName }}</label>
                </div>
                <div class="col-3">
                  <div
                    class="card-calender"
                    style="min-height: 0px; margin-right: 75px"
                  >
                    <div class="calender-blk" style="width: 35px">
                      <span class="month">{{
                        getMonthText(data.Scheduled)
                      }}</span>
                      <h3 class="date">{{ getDateText(data.Scheduled) }}</h3>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <a
                    class="smallFont"
                    routerLink="/pages/jobs/jobEdit/{{
                      data.JobId
                    }}/jobLocation/{{ getLocationString(data) }}"
                  >
                    {{ data.Address1 }}, {{ data.City }},
                    {{ data.Abbreviation }}, {{ data.ZipPostalCode
                    }}<i class="fa fa-map-marker" style="margin-left: 5px"></i>
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="assigned">
                  <span class="mt10">Assign To</span>
                </div>
              </div>
              <div class="card-assignee row" style="padding-left: 8px">
                <ul *ngFor="let r of data.Resources; let i = index">
                  <li>
                    <span
                      class="assignee-sname assignee"
                      [ngClass]="r.IsExternal ? 'bg-green' : 'bg-blue'"
                      >{{ getInitals(r.Name) }}</span
                    >
                    <span class="assignee-fname">{{ r.Name }}</span>
                  </li>
                </ul>
              </div>
              <div class="row" style="padding-top: 8px">
                <div
                  class="col-8 smallFont"
                  style="font-weight: bold; font-size: 10px"
                >
                  Scheduled for {{ data.DaysToComplete }} days and will finish
                  on {{ data.FinshDate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="fixbtnwrap">
    <div class="btnwrap" style="margin-top: 5px">
      <button
        type="button"
        class="btn darkbtn"
        (click)="StartUpdateRequest()"
        [disabled]="totalSelected.length == 0"
      >
        <i class="fa fa-floppy-o"></i> Update
      </button>
      <button type="button" class="btn btn-cancel" (click)="onCancel()">
        Close
      </button>
    </div>
  </div>
</div>
