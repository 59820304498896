import { AddressModel } from "app/model/addressModel";
import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";
import { environmentConstant } from "app/common/utility/environment";
import { Observable, BehaviorSubject } from "rxjs";
import { StateModel } from "app/model/statesModel";
import { CustomerModel } from "app/model/customerModel";
import { leadSourceModel } from "app/model/leadSourceModel";
import { scheduleReasonModel } from "app/model/scheduleReasonModel";
import { fileTypeModel } from "app/model/FileTypeModel";
import { SharedService } from "app/common/utility/SharedService";
import { CustomerRoleModel } from "../../model/customerRoleModel";
import { QuickToDoDto, TaskDto } from "app/model/ToDoListDto";
import { StatusModel } from "app/model/statusModel";
import { DeliveryTypeModel } from "app/model/DeliveryTypeModel";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";

@Injectable()
export class CommonService {
  constructor(
    private httpService: HttpService,
    private sharedService: SharedService,
    private httpClient: HttpClient
  ) {}
  getLicenseData() {
    return this.httpService.getNoTokenRequestService(
      environmentConstant.licensePrivacy
    );
  }
  getStates(): Observable<StateModel[]> {
    return new BehaviorSubject(environmentConstant.states);
  }
  getCurretnOrganizationStateId() {
    if (
      this.sharedService.loggedInUser &&
      this.sharedService.loggedInUser.Address
    )
      return +this.sharedService.selectedOrganization.State;
    else return null;
  }
  getStatusId(statusId: number): Observable<StatusModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getStatus + "/" + statusId
    );
  }
  getscheduleReason(): Observable<scheduleReasonModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getschedulereason +
        this.sharedService.selectedOrganization.ID
    );
  }
  saveScheduleReason(
    obj: scheduleReasonModel
  ): Observable<scheduleReasonModel[]> {
    return this.httpService.postRequestService(
      environmentConstant.getschedulereason +
        this.sharedService.selectedOrganization.ID,
      obj
    );
  }
  updatescheduleReason(obj: scheduleReasonModel) {
    return this.httpService.putRequestService(
      environmentConstant.getschedulereason +
        this.sharedService.selectedOrganization.ID,
      obj
    );
  }
  deletescheduleReason(id: number) {
    return this.httpService.deleteRequestService(
      environmentConstant.deletescheduleReason + id
    );
  }
  getleadSource(): Observable<leadSourceModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getleadSource +
        this.sharedService.selectedOrganization.ID
    );
  }
  saveLeadSource(obj: leadSourceModel) {
    return this.httpService.postRequestService(
      environmentConstant.getleadSource +
        this.sharedService.selectedOrganization.ID,
      obj
    );
  }
  updateLeadSource(obj: leadSourceModel) {
    return this.httpService.putRequestService(
      environmentConstant.getleadSource +
        this.sharedService.selectedOrganization.ID,
      obj
    );
  }
  deleteleadSourcebyId(id: number) {
    return this.httpService.deleteRequestService(
      environmentConstant.deleteleadSource + id
    );
  }
  getAddressById(id: any) {
    return this.httpService.getRequestService(environmentConstant.address + id);
  }
  updateAddress(address: AddressModel) {
    return this.httpService.putRequestService("address", address);
  }
  putShippingAddress(address: AddressModel) {
    return this.httpService.putRequestService("shippingAddress", address);
  }
  getCustomerInfoById(cId) {
    return this.httpService.getRequestService(
      environmentConstant.getCustomer + cId + "/info"
    );
  }
  updateCustomerInfo(customer: CustomerModel) {
    return this.httpService.putRequestService("customer/info", customer);
  }

  getFileType(): Observable<fileTypeModel[]> {
    return this.httpService.getRequestService(environmentConstant.fileType);
  }
  getFileTypeByOrgId(params: string = "?filterACL=false"): Observable<fileTypeModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.fileType +
        environmentConstant.organization +
        this.sharedService.selectedOrganization.ID +
        params
    );
  }
  saveFileType(obj: fileTypeModel): Observable<fileTypeModel[]> {
    return this.httpService.postRequestService(
      environmentConstant.fileType,
      obj
    );
  }
  updateFileType(obj: fileTypeModel, organizationId: any) {
    return this.httpService.putRequestService(
      environmentConstant.fileType +
        (organizationId != null ? "?organizationId=" + organizationId : ""),
      obj
    );
  }
  deleteFileType(id: number) {
    return this.httpService.deleteRequestService(
      environmentConstant.fileType + id
    );
  }

  deleteFileTypeWithOrganization(id: number, alternateFileType: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.fileType +
        id +
        "/" +
        this.sharedService.selectedOrganization.ID +
        "/" +
        alternateFileType
    );
  }

  //Delivery API
  getDeliveryTypes(): Observable<DeliveryTypeModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.deliveryTypes
    );
  }

  //Roles API
  getRole(): Observable<CustomerRoleModel[]> {
    return this.httpService.getRequestService(environmentConstant.roles);
  }
  getRoleById(id): Observable<CustomerRoleModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.roles + "/" + id
    );
  }
  getRoleByOrgId(): Observable<CustomerRoleModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.rolesByOrg +
        this.sharedService.selectedOrganization.ID
    );
  }
  saveUserRole(obj: CustomerRoleModel): Observable<CustomerRoleModel[]> {
    return this.httpService.postRequestService(environmentConstant.roles, obj);
  }
  updateUserRole(obj: CustomerRoleModel) {
    return this.httpService.putRequestService(environmentConstant.roles, obj);
  }
  deleteUserRole(id: number) {
    return this.httpService.deleteRequestService(
      environmentConstant.roles + "/" + id
    );
  }
  getBusinessUnit(orgID: any): Observable<any[]> {
    return this.httpService.getRequestService(
      "businessunit/" + orgID + "?includeDeleted=false"
    );
  }
  getToDoList(orgId: any) {
    return this.httpService.getRequestService("customer/todolist/" + orgId);
  }
  getMyToDoList(orgId: any) {
    return this.httpService.getRequestService("customer/mytodolist/" + orgId);
  }
  deleteToDoItem(taskId: number) {
    return this.httpService.deleteRequestService("customer/todotask/" + taskId);
  }
  getOthersToDoList(orgId: any) {
    return this.httpService.getRequestService(
      "customer/assignedbymetodolist/" + orgId
    );
  }
  getJobToDoList(jobId: any) {
    return this.httpService.getRequestService("jobs/todolist/" + jobId);
  }
  ChangePhaseStatus(jobphaseId: any, status: any) {
    return this.httpService.putRequestService(
      "jobphase/" + jobphaseId + "/" + status,
      null
    );
  }
  saveQuickTodoList(obj: QuickToDoDto): any {
    return this.httpService.postRequestService(
      environmentConstant.customer + "/todolist/quicktask",
      obj
    );
  }
  saveTodoListTask(obj: TaskDto): any {
    return this.httpService.postRequestService(
      environmentConstant.customer + "/todolist/task",
      obj
    );
  }
  UpdateTodoListTask(obj: TaskDto): any {
    return this.httpService.putRequestService(
      environmentConstant.customer + "/todolist/task",
      obj
    );
  }
  getTodoListTask(taskId: number) {
    return this.httpService.getRequestService(
      environmentConstant.customer + "/todolist/task/" + taskId
    );
  }

  checkIfFileTypeIsUsed(fileTypeId: string) {
    return this.httpService.getRequestService(
      `filetype/Jobs/${fileTypeId}/${this.sharedService.selectedOrganization.ID}`
    );
  }

  getHolidays(country: string, year: number) {
    return this.httpService.getRequestService(
      `holidayCalendar?location=${country}&year=${year}`
    );
  }
}
