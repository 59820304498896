import { Injectable } from "@angular/core";

import { SharedService } from "app/common/utility/SharedService";
import { environmentConstant } from "app/common/utility/environment";
import { environment } from "environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
// tslint:disable:prefer-template
@Injectable()
export class ConfigService {
  constructor(private http: HttpClient, private sharedService: SharedService) {}
  load(): Promise<any> {
    let impersonatedOrg = this.getImpersonatedOrganizationGuid();
    const url =
      environment.baseUrl + "Account/GetLoginUser/v2" + impersonatedOrg;
    let headers: HttpHeaders;

    let token: any;
    if (localStorage.getItem("token")) {
      token = "Bearer " + localStorage.getItem("token");
    }
    if (token) {
      headers = new HttpHeaders({
        Authorization: token,
        "Content-Type": "application/json",
        AppName: "MPB-Web",
      });
      return this.http
        .get(url, { headers: headers })
        .toPromise()
        .then((loggedInData: any) => {
          if (
            (loggedInData.CustomerRoles.length == 1 &&
              (loggedInData.CustomerRoles[0].Id == 6 ||
                loggedInData.CustomerRoles[0].Id == 3 ||
                loggedInData.CustomerRoles[0].Id == 13)) ||
            !loggedInData.Active ||
            loggedInData.Deleted
          ) {
            localStorage.removeItem("token");
            return Promise.resolve();
          } else {
            this.sharedService.loggedInUser = loggedInData;
            this.sharedService.selectedOrganization = Object.assign(
              {},
              loggedInData.Org
            );
            let state = +loggedInData.Org.State;
            this.sharedService.selectedOrganization.State = state;
            this.sharedService.organizationSettings =
              loggedInData.OrganizationSettings;
          }
        })
        .catch(() => {
          localStorage.removeItem("token");
          return Promise.resolve();
        });
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Get the impersonate organization guid
   * return impersonted organization guid
   */
  getImpersonatedOrganizationGuid(): string {
    const impersonateObj = JSON.parse(localStorage.getItem("impersonateObj"));

    if (!impersonateObj) {
      return "";
    }

    return "?organizationGuid=" + impersonateObj.orgId;
  }

  get startupData(): any {
    return this.sharedService.loggedInUser;
  }
}
