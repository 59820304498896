import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";
import { environmentConstant } from "app/common/utility/environment";
import { Observable } from "rxjs";
import {
  JobPhotoModel,
  JobPhotoPaginationModel,
} from "app/model/jobPhotoModel";
import { PhotoEnum } from "../directives/commonEnum";

// tslint:disable:prefer-template
@Injectable()
export class JobPhotoService {
  constructor(private httpService: HttpService) {}

  getJobId(): Observable<JobPhotoModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhotosUrl
    );
  }

  getJobPhotosByJobID(
    jobId: any,
    currnetPage: any,
    pageSize: any,
    photoType: PhotoEnum,
    rangeFilter: number = 0
  ): Observable<JobPhotoPaginationModel> {
    if (jobId == 0) {
      return this.httpService.getRequestService(
        environmentConstant.getJobPhotosByJobID +
          "00000000-0000-0000-0000-000000000000" +
          "/photos?page=" +
          currnetPage +
          "&pageSize=" +
          pageSize +
          "&photoType=" +
          photoType
      );
    } else {
      return this.httpService.getRequestService(
        environmentConstant.getJobPhotosByJobID +
          jobId +
          "/photos?page=" +
          currnetPage +
          "&pageSize=" +
          pageSize +
          "&photoType=" +
          photoType +
          "&rangeFilter=" +
          rangeFilter
      );
    }
  }

  getUserPhotosByOrg(orgId, rangeFilter, jobType) {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhotosByJobID +
        orgId +
        "/organization/userphotojobtile/" +
        rangeFilter +
        "/" +
        jobType
    );
  }

  uploadDocument(
    jobId: any,
    filetype: number,
    jobPhaseId: number,
    formData: any,
    phasephotoFlag: any
  ) {
    return this.httpService.UploadRequestService(
      "jobs/" +
        jobId +
        "/filetype/" +
        filetype +
        "/phase/" +
        jobPhaseId +
        (phasephotoFlag ? "?phasephotoFlag=" + phasephotoFlag : ""),
      formData
    );
  }
  // for deleting all photos
  deleteJobPhoto(jId: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.deleteJobPhoto + jId + "/photos"
    );
  }
  // for deleting specific photo

  deleteAllJobPhoto(id: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.deleteJobPhoto + "file/" + id
    );
  }
  deleteSelectedJobPhoto(ids: any) {
    var urlParam: any = "";
    for (var key in ids) {
      urlParam += "ids=" + ids[key] + "&";
    }
    return this.httpService.deleteRequestService(
      environmentConstant.deleteJobPhoto + "file?" + urlParam
    );
  }
  deletePhoto(id: number) {
    return this.httpService.deleteRequestService("jobs/file/" + id);
  }
}
