import { Component, ChangeDetectorRef, OnInit } from "@angular/core";
import { DialogService, DialogComponent } from "ng2-bootstrap-modal";
import { JobPhaseService } from "app/common/services/jobPhase.service";

export interface WeatherDialogModel {
  title: string;
  message: string;
}

export class WeatherScheduleChange {
  days: number;
  withDependents: boolean;
  ChangeReasonId: number;
  ChangeReason: string;
}

@Component({
  selector: "weather-reschedule-modal",
  templateUrl: "./weather-reschedule-modal.component.html",
  styleUrls: ["./weather-reschedule-modal.component.scss"],
})
export class WeatherRescheduleModalComponent
  extends DialogComponent<WeatherDialogModel, WeatherScheduleChange>
  implements WeatherDialogModel, OnInit {
  title: string;
  message: string;
  message2: string;
  changeDetectorRef: ChangeDetectorRef;
  jobPhaseService: JobPhaseService;
  enteredDays: number = 1;
  withDependents: boolean = false;
  weatherResult: WeatherScheduleChange = new WeatherScheduleChange();
  changeReasonList;
  originalChangeList;
  changeReasonId;
  changeReason = "";
  hasReasonSelected: boolean = false;

  constructor(
    dialogService: DialogService,
    changeDetectorRef: ChangeDetectorRef,
    jobPhaseService: JobPhaseService
  ) {
    super(dialogService);
    this.changeDetectorRef = changeDetectorRef;
    this.jobPhaseService = jobPhaseService;
    const that = this;
    setTimeout(function () {
      that.changeDetectorRef.detectChanges();
    }, 300);

    this.title = "Schedule Update";
    this.message = "Changing the scheduled date will impact dependent phases.";
    this.message2 = "What would you like to do?";
  }

  ngOnInit() {
    this.jobPhaseService.getChangeReasons().subscribe((value) => {
      this.changeReasonList = value;
      this.originalChangeList = value;
    });
  }

  Confirm() {
    this.weatherResult.days = this.enteredDays;
    this.weatherResult.withDependents = this.withDependents;
    this.weatherResult.ChangeReasonId = this.changeReasonId;
    this.weatherResult.ChangeReason = this.changeReason;
    this.result = this.weatherResult;
    this.close();
  }

  restrictMaxValue(evt) {
    if (evt.target.value > 365) return (this.enteredDays = 365);
  }

  updateDependents(evt) {
    this.withDependents = evt;
  }

  UpdateChangeReason(evt) {
    this.changeReasonId = evt.Id;
    this.changeReason = evt.Reason;
    if (evt.Id != 0) this.hasReasonSelected = true;
  }
}
