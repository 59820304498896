export enum PaymentStatus {
  Pending = 10,
  Authorized = 20,
  Paid = 30,
  PartiallyRefunded = 40,
  Refunded = 50,
  Voided = 60,
  Deleted = 70,
}
export enum InvoiceStatus {
  Pending = 10,
  Processing = 20,
  Completed = 30,
  Cancelled = 40,
}

export enum OrderType {
  None = 0,
  Web = 1,
  Mobile = 2,
}

export enum TransType {
  Purchase = 1,
  Refund = 2,
  Void = 3,
  Mobile = 4,
}
export enum ShippingStatus {
  ShippingNotRequired = 10,
  NotYetShipped = 20,
  PartiallyShipped = 25,
  Shipped = 30,
  Delivered = 40,
}
export enum ShippingMethods {
  PickUp = 0,
  Free = 1,
  Standard = 2,
  Express = 3,
}
export enum enumModuleType {
  None = 0,
  Accounting = 1,
  Order = 2,
  Service = 3,
}
export enum CreditCardType {
  Visa = 1,
  MasterCard = 2,
  AMEX = 3,
  Discover = 4,
  Other = 5,
}
export enum EnumMoveInvoiceTransactionTarget {
  InvoiceToInvoice = 0,
  CreditMemoToInvoice = 1,
  InvoiceToCreditMemo = 2,
}
// export enum TransType {
// 	Credit = 1,
// 	Debit = 2,
// 	Delete = 3,
// 	Adjust = 4
// };
export enum Catalog {
  Accounting = 1,
  Gallery = 2,
  Equipment = 3,
  OnlineStore = 8,
}
export enum enumAppointmentStatus {
  Booked = 1,
  Tentative = 2,
}
export enum enumAppointmentType {
  Sales = 1,
  Field = 2,
}
export enum enumAttendeeType {
  Host = 1,
  Employee = 2,
  Customer = 3,
}

export enum ChecklistItemTypeEnum {
  None = 0,
  Entry = 1,
  ComboBox = 2,
  Checkbox = 3,
  TextArea = 4,
  Date = 5,
}

export enum RecurringFrequencyEnum {
  None = 0,
  Hourly = 1,
  Daily = 2,
  Monthly = 3,
  Annually = 4,
}

export enum EnumRecordState {
  Unchanged = 0,
  Added = 1,
  Modified = 2,
  Deleted = 3,
}

export enum EnumPhotoRangFilter {
  None = 0,
  Today = 1,
  Tommorow = 2,
  ThisWeek = 3,
  NextTwoWeek = 4,
  Yesterday = 5,
  LastWeek = 6,
  ThisMonth = 7,
  LastMonth = 8,
}

export enum EnumChecklistStatus {
  None = 0,
  Complete = 1,
}

export enum JobTypeEnum {
  All = 0,
  Sales = 1,
  Construction = 2,
  PunchList = 3,
  WorkOrder = 4,
  ChangeOrder = 5,
  DrawSchedule = 6,
  System = 7,
}
export enum JobStatusEnum {
  NotStarted = 1,
  InProgress = 2,
  Complete = 3,
  Dead = 4,
  Stopped = 5,
  Active = 6,
  Any = 7,
}

export enum EstimateViewGroupEnum {
  NotStarted = 1,
  Done = 2,
}

export enum JobPhaseStatusEnum {
  None = 0,
  NotStarted = 1,
  InProgress = 2,
  Complete = 3,
  Dead = 4,
  Stopped = 5,
}

export enum AbstractedJobStatusEnum {
  None = 0,
  InProgress = 2,
  PastDue = 3,
  CompletedToday = 4,
  StartingToday = 5,
}

export enum TemperatureEnum {
  None = "None",
  Hot = "Hot",
  Warm = "Warm",
  Cold = "Cold",
}

export enum ReportServerEnvironment {
  PROD = "PROD",
  STAGING = "STAGING",
  QA = "QA",
  DEV = "DEV",
  SHASTA = "SHASTA",
  SHASTA_SANDBOX = "SHASTA_SANDBOX",
}

export enum ReportDataSource {
  MPB = "MPB",
  SHASTA = "SHASTA",
  DEV = "DEV",
  QA = "QA",
  WIP = "WIPReports",
  SALES = "SalesReports",
  ACCOUNTING = "AccountingReports",
}

export enum PhotoEnum {
  Camera = 1,
  User = 9,
}

export enum MPBStatusEntity {
  Sales = 1,
  Construction = 2,
  WorkOrder = 3,
  Punchlist = 4,
  ChangeOrder = 5,
  SalesPhase = 11,
  ConstructionPhase = 12,
  WorkOrderItem = 13,
  PunchListPhase = 14,
}

export enum MPBCategoryEntity {
  JobPhase = 1,
  PunchlistItem = 2,
  JobTemplate = 3,
  WorkOrderItem = 4,
  PhaseChangeReason = 5,
  DrawScheduleTemplate = 6,
  SalesLeadCategory = 7,
  WorkOrder = 8,
  BussinessUnit = 9,
  HomeBuilder = 10,
  Report = 11,
  SalesJobTemplate = 12,
  Checklist = 17,
  CustomFieldChecklist = 18,
}

export enum EnumJobFileTypes {
  CameraPhoto = 1,
  Invoice = 2,
  ConstructionPlan = 3,
  Contracts = 4,
  Workorders = 5,
  Permit = 6,
  Other = 7,
  Proposal = 8,
  UserPhotos = 9,
  Logs = 10,
  Layout = 11,
  AandA = 12,
  Survey = 13,
  Rendering3D = 14,
  SelectionSheet = 15,
  PlumbingStubOut = 16,
  Equipment = 17,
  Deal = 18,
  VendorInvoice = 19,
  CameraAndUser = 20,
}

export enum ChecklistTypes {
  Checklist = 1,
  CustomFieldChecklist = 2,
}

export enum CrudTypes {
  New = "NEW",
  Edit = "EDIT",
}

export enum MediaResourceType {
  None = 0,
  Blob = 1,
  Video = 2,
  RemoteImage = 3,
}

export enum EDocumentTemplateType {
  SigNowTemplate = 1,
  ProposalPlaceholder = 2,
  DrawSchedulePlaceHolder = 3,
}

export enum HomeBuilderUpdateStatusEnum {
  AllowedToUpdate = 1,
  NotAllowedToUpdate = 2,
  WarningToUpdate = 3,
}
