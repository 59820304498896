import { CustomerRoleModel } from "app/model/customerRoleModel";
import { AddressModel } from "app/model/addressModel";
import { OrganizationBasicModel } from "app/model/organisation_customer_MappingModel";
import { BusinessUnitModel } from "app/model/organizationModel";
import { QuickBooksModel } from "./Core/QuickBooksModel";
import { QuickBooksDataModel } from "./Core/QuickBooksDataModel";
import { Settings } from "./Settings";

export class CustomerModel implements QuickBooksModel {
  Id: number;
  CustomerGuid: string;
  Email: string;
  UserName: string;
  Active: boolean = false;
  Disable: boolean;
  Deleted: boolean;
  IsSystemAccount: boolean;
  FirstName: string;
  LastName: string;
  name: string;
  FullName: string;
  Company: string;
  DealerNumber: string;
  Address_Id: number;
  PrimaryPhone: string;
  SecondaryEmail: string;
  SecondaryPhone: string;
  SecondaryFirstName: string;
  SecondaryLastName: string;
  AddressId: number;
  Address: AddressModel = new AddressModel();
  CountryId: number;
  StateProvinceId: number;
  City: string;
  Address1: string;
  Address2: string;
  ZipPostalCode: string;
  StateProvinceAbbreviation: string;
  Name: string;
  CustomerAddress: string;
  Status: string;
  Roles: number[];
  CustomerRoles: CustomerRoleModel[];
  chatEnabled: boolean;
  Trades: String;
  Org: OrganizationBasicModel;
  OrgId: string;
  OrgName: string;
  checked: boolean = false;
  rolesList: string;
  BusinessUnits: BusinessUnitModel[];
  BusinessUnitsString: string;
  Preference: CustomerPrefrenceModel = new CustomerPrefrenceModel();
  CreatedOnUtc: Date;
  Phase: CustomerPhaseEnum;
  quickBooksData: QuickBooksDataModel;
  ReportsToId?: number;
  OrganizationSettings: Settings[];
  constructor() {}
}

export class CustomerPrefrenceModel {
  LiveView: boolean = false;
  NotificationSMS: boolean = false;
  NotificationEmail: boolean = false;
  SendToSecondary: boolean = false;

  constructor() {}
}

// Customer Phase will describe what services/options/actions should be available on a
// customer record.
export enum CustomerPhaseEnum {
  None = 0,
  Sales = 1,
  Job = 2,
  Maintenance = 4,
}

export class CustomerPhaseModel {
  Kind: number;
  Name: string;
}

export class CustomerSearchModel {
  totalRecords: number;
  customers: CustomerModel[];
}

export interface CustomerSearchFilter {
  organizationId: string;
  term: string | null;
  page: number;
  take: number;
  sort: string | null;
  direction: string;
  salesPerson: number | null;
  roleId: number | null;
}

export interface RolesFilter {
  id: number;
  name: string;
}
