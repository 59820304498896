import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { ControlContainer, NgForm, NgModel } from "@angular/forms";
import { ComboBoxComponent } from "@progress/kendo-angular-dropdowns";
import { JobTypeEnum } from "app/common/directives/commonEnum";
import { CommonService } from "app/common/services/common.service";
import { CustomerService } from "app/common/services/customer.service";
import { environmentConstant } from "app/common/utility/environment";
import { Helper } from "app/common/utility/helper";
import { fromEvent, merge } from "rxjs";
import { map, takeWhile } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { GoogleService } from "app/common/services/Core/Google.service";
import { DialogService } from "ng2-bootstrap-modal";
import { ContactsDialogComponent } from "./contacts-dialog/contacts-dialog.component";
import { BaThemeSpinner } from "app/theme/services";

@Component({
  selector: "contacts-tabbed-entry",
  templateUrl: "./contacts-tabbed-entry.component.html",
  styleUrls: ["./contacts-tabbed-entry.component.scss"],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  encapsulation: ViewEncapsulation.None,
})
export class ContactsTabbedEntryComponent implements OnInit, AfterViewInit {
  @Input() model: any;
  @Input() jobGuid: string;
  @Input() jobType: JobTypeEnum;
  @Input() type: string;
  @Input() duplicateErrorMessage: string = "Existing contact found.";
  @Input() salesBoardDisableSalesman: boolean = false;
  @Input() isValidPhoneEmail: boolean = true;
  @Input() isEdit: boolean = false;
  @Output() isValidPhoneEmailChange = new EventEmitter<boolean>();
  @ViewChild("inputZip", { static: true })
  inputZip: ElementRef;
  @ViewChild("statesInput", { static: true })
  statesInput: ComboBoxComponent;
  @ViewChild("customerPrimaryPhone", { static: true })
  customerPrimaryPhone: NgModel;
  @ViewChild("customerEmail", { static: true }) customerEmail: NgModel;
  @Output() filterExistingCustomer: EventEmitter<any> = new EventEmitter<any>();
  emailPattern = environmentConstant.emailPattern;

  isValidUserNameText: string = "";
  isValidUserNameVal: boolean = undefined;

  states: any;
  originalStates: any;

  zipCodeMessage = environmentConstant.zipCodeMessage;
  alive: boolean;
  zipValidate: boolean = false;

  isDuplicateCustomerEmail: boolean = false;
  isDuplicateCustomerPhone: boolean = false;
  private dialogOpen = false;
  originalEmail: string = "";
  originalPhone: string = "";

  options = {
    componentRestrictions: {
      country: ["US", "CA"],
    },
    fields: ["address_components"],
    types: ["address"],
  };
  private CONS_PHONE: string = "PHONE";
  private CONS_EMAIL: string = "EMAIL";
  private CONS_MAX_CUSTOMER_DUPLICATES: number = 1;
  phoneEmailQty: { phoneQty: number; emailQty: number } = {
    phoneQty: 0,
    emailQty: 0,
  };

  constructor(
    private customerService: CustomerService,
    private commonService: CommonService,
    private renderer: Renderer2,
    private toastrService: ToastrService,
    private googleService: GoogleService,
    private dialogService: DialogService,
    private _spinner: BaThemeSpinner
  ) {}

  ngOnInit() {
    this.getStates();
    this.alive = true;
    this.setZipCodes();
    this.initializeModel();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.model &&
      changes.model.currentValue &&
      changes.model.currentValue.Id
    ) {
      this.originalEmail = changes.model.currentValue.Email;
      this.originalPhone = changes.model.currentValue.PrimaryPhone;
    }
  }

  initializeModel() {
    this.model.PrimaryPhone = "";
  }

  validateEmail(userName, isValid) {
    this._spinner.show();
    this.isValidEmail(userName, isValid)
      .then((data) => {
        this._spinner.hide();
      })
      .catch((error) => {
        console.error("Error validating email:", error);
      });
  }

  async isValidEmail(userName, isValid) {
    this.isValidUserName(userName, isValid);

    const validatePhone = !(
      this.isEdit && this.model.PrimaryPhone == this.originalPhone
    );

    const validateEmail = !(
      this.isEdit && this.model.Email == this.originalEmail
    );

    if (
      this.isEdit &&
      this.model.Email == this.originalEmail &&
      this.model.PrimaryPhone == this.originalPhone
    ) {
      this.isValidPhoneEmail = true;
      this.isValidPhoneEmailChange.emit(this.isValidPhoneEmail);
      return;
    }

    if (userName !== undefined && userName !== "") {
      if (validateEmail)
        this.phoneEmailQty.emailQty = await this.checkIfContactIsDuplicate(
          userName,
          this.CONS_EMAIL
        );

      if (validatePhone)
        this.phoneEmailQty.phoneQty = await this.checkIfContactIsDuplicate(
          this.model.PrimaryPhone,
          this.CONS_PHONE
        );

      this.setCustomerDuplicatesEmailPhoneByQty(
        this.phoneEmailQty.phoneQty,
        this.phoneEmailQty.emailQty,
        this.model.PrimaryPhone,
        this.model.Email
      );

      this.phoneEmailQty.phoneQty = 0;
      this.phoneEmailQty.emailQty = 0;
    } else {
      this.isDuplicateCustomerEmail = false;
    }
  }

  validatePhone(phone) {
    this._spinner.show();
    this.isValidPhone(phone)
      .then((data) => {
        this._spinner.hide();
      })
      .catch((error) => {
        console.error("Error validating phone:", error);
      });
  }

  async isValidPhone(phone) {
    const validatePhone = !(
      this.isEdit && this.model.PrimaryPhone == this.originalPhone
    );

    const validateEmail = !(
      this.isEdit && this.model.Email == this.originalEmail
    );

    if (
      this.isEdit &&
      this.model.PrimaryPhone == this.originalPhone &&
      this.model.Email == this.originalEmail
    ) {
      this.isValidPhoneEmail = true;
      this.isValidPhoneEmailChange.emit(this.isValidPhoneEmail);
      return;
    }
    if (phone !== undefined && phone !== "" && phone != "   -   -    ") {
      if (validatePhone)
        this.phoneEmailQty.phoneQty = await this.checkIfContactIsDuplicate(
          phone,
          this.CONS_PHONE
        );

      if (validateEmail)
        this.phoneEmailQty.emailQty = await this.checkIfContactIsDuplicate(
          this.model.Email,
          this.CONS_EMAIL
        );

      this.setCustomerDuplicatesEmailPhoneByQty(
        this.phoneEmailQty.phoneQty,
        this.phoneEmailQty.emailQty,
        this.model.PrimaryPhone,
        this.model.Email
      );

      this.phoneEmailQty.phoneQty = 0;
      this.phoneEmailQty.emailQty = 0;
    } else {
      this.isDuplicateCustomerPhone = false;

      if (!this.model.Email) {
        this.filterExistingCustomer.emit(null);
      }
    }
  }

  ngAfterViewInit(): void {
    this.renderer.setAttribute(
      this.statesInput.searchbar.input.nativeElement,
      "autocomplete",
      "do-not-auto-fill"
    );
  }

  isValidUserName(userName, isValid) {
    this.model.Email = userName;
    if (
      userName !== undefined &&
      isValid &&
      this.jobGuid == undefined &&
      userName.length != 0
    ) {
      this.customerService.isValidUserName(userName).subscribe(
        (data) => {},
        (error) => {
          if (error.status === 302) {
            this.isValidUserNameText = "This User Name already exists";
            this.isValidUserNameVal = true;
          } else {
            this.isValidUserNameText = "This is valid email";
            this.isValidUserNameVal = false;
          }
        }
      );
    } else {
      this.isValidUserNameVal = undefined;
    }
  }

  getStates() {
    this.commonService.getStates().subscribe((states) => {
      this.states = states.sort((a, b) =>
        a.Abbreviation > b.Abbreviation ? 1 : -1
      );
      this.originalStates = states;

      this.model.Address.StateProvinceId =
        this.model.Address.StateProvinceId == null
          ? this.commonService.getCurretnOrganizationStateId()
          : this.model.Address.StateProvinceId;
    });
  }

  filterStates(filter: any): void {
    if (!filter || !isNaN(filter)) return;
    this.states = this.originalStates.filter(
      (s) => s.Abbreviation.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }

  setZipCodes() {
    const keyup = fromEvent(this.inputZip.nativeElement, "keyup");
    const paste = fromEvent(this.inputZip.nativeElement, "paste");
    const allEvents = merge(keyup, paste);
    allEvents
      .pipe(
        takeWhile(() => this.alive),
        map((event: Event) => {
          return (event.target as HTMLInputElement).value.trim();
        })
      )
      .subscribe((text: string) => {
        if (text != "") {
          environmentConstant.zipRegex.test(text)
            ? (this.zipValidate = false)
            : (this.zipValidate = true);
        } else {
          this.zipValidate = false;
        }
      });
  }

  /**
   * Checks if the contact is duplicate by type
   * @param value value to check
   * @param contactType type of contact to check
   * @returns quantity of duplicates
   */
  async checkIfContactTypeIsDuplicate(
    value: string,
    contactType: string
  ): Promise<number> {
    if (value) {
      if (
        value.toLowerCase().trim().endsWith(".invalid") &&
        contactType === this.CONS_EMAIL
      ) {
        this.isDuplicateCustomerEmail = false;
        return 0;
      } else {
        try {
          const qty = await this.customerService
            .getQtyCustomerDuplicates(value, contactType)
            .toPromise();

          this.validateQtyCustomerDuplicates(qty, contactType, value);
          return qty;
        } catch (error) {
          console.error("Error fetching customer duplicates:", error);
          throw error;
        }
      }
    }

    return 0;
  }

  /**
   * Checks if the contact is duplicate
   * @param value value to check
   * @param contactType type of contact to check
   * @returns quantity of duplicates
   */
  async checkIfContactIsDuplicate(
    value: string,
    contactType: string
  ): Promise<number> {
    switch (contactType) {
      case this.CONS_PHONE:
        let validFormat = this.validateIfValidPhoneFormat(value);
        if (validFormat.isValid) {
          return await this.checkIfContactTypeIsDuplicate(
            validFormat.value,
            this.CONS_PHONE
          );
        }
        break;
      case this.CONS_EMAIL:
        return await this.checkIfContactTypeIsDuplicate(value, this.CONS_EMAIL);
    }

    return 0;
  }

  validateQtyCustomerDuplicates(
    qty: number,
    contactType: string,
    value: string
  ) {
    switch (contactType) {
      case this.CONS_EMAIL:
        this.setCustomerDuplicatesEmailByQty(qty, value);
        break;
      case this.CONS_PHONE:
        this.setCustomerDuplicatesPhoneByQty(qty, value);
        break;
    }
  }

  /**
   * Sets the customer duplicates by quantity
   * @param phoneQty phone duplicate quantity returned from server
   * @param emailQty email duplicate quantity returned from server
   * @param phoneValue constant valiue for phone
   * @param emailValue constant value for email
   */
  setCustomerDuplicatesEmailPhoneByQty(
    phoneQty: number,
    emailQty: number,
    phoneValue: string,
    emailValue
  ) {
    if (
      phoneQty >= this.CONS_MAX_CUSTOMER_DUPLICATES ||
      emailQty >= this.CONS_MAX_CUSTOMER_DUPLICATES
    ) {
      if (this.salesBoardDisableSalesman) {
        this.setDialogDuplicateRecord();
        this.isValidPhoneEmail = false;
        this.isValidPhoneEmailChange.emit(this.isValidPhoneEmail);

        if (phoneQty >= this.CONS_MAX_CUSTOMER_DUPLICATES)
          this.filterExistingCustomer.emit(phoneValue);
        else this.filterExistingCustomer.emit(emailValue);
      } else {
        this.isDuplicateCustomerEmail = true;
        if (phoneQty >= this.CONS_MAX_CUSTOMER_DUPLICATES)
          this.filterExistingCustomer.emit(phoneValue);
        else this.filterExistingCustomer.emit(emailValue);
      }
    } else {
      this.isDuplicateCustomerEmail = false;
      this.isValidPhoneEmail = true;
      this.isValidPhoneEmailChange.emit(this.isValidPhoneEmail);
    }
  }

  setCustomerDuplicatesEmailByQty(qty: number, value: string) {
    if (qty >= this.CONS_MAX_CUSTOMER_DUPLICATES) {
      if (this.salesBoardDisableSalesman) {
        this.setDialogDuplicateRecord();
        this.isValidPhoneEmail = false;
        this.isValidPhoneEmailChange.emit(this.isValidPhoneEmail);
        this.filterExistingCustomer.emit(value);
      } else {
        this.isDuplicateCustomerEmail = true;
        this.filterExistingCustomer.emit(value);
      }
    } else {
      this.isDuplicateCustomerEmail = false;
      this.isValidPhoneEmail = true;
      this.isValidPhoneEmailChange.emit(this.isValidPhoneEmail);
    }
  }

  setCustomerDuplicatesPhoneByQty(qty: number, value: string) {
    if (qty >= this.CONS_MAX_CUSTOMER_DUPLICATES) {
      if (this.salesBoardDisableSalesman) {
        this.setDialogDuplicateRecord();
        this.isValidPhoneEmail = false;
        this.isValidPhoneEmailChange.emit(this.isValidPhoneEmail);
        this.filterExistingCustomer.emit(value);
      } else {
        this.isDuplicateCustomerPhone = true;
        this.filterExistingCustomer.emit(value);
      }
    } else {
      this.isDuplicateCustomerPhone = false;
      this.isValidPhoneEmail = true;
      this.isValidPhoneEmailChange.emit(this.isValidPhoneEmail);
    }
  }

  validateIfValidPhoneFormat(phone: string): any {
    let response: any = {
      isValid: false,
      value: "",
    };

    if (!phone) {
      return response.isValid;
    }

    phone = phone.trim();

    let phoneRemovedWhiteSpace = phone.replace(/\s/g, "");

    if (phoneRemovedWhiteSpace === "--") {
      return response.isValid;
    }

    response.isValid = true;
    response.value = phoneRemovedWhiteSpace;

    return response;
  }

  onAddressChange(address) {
    const place = Helper.formatAddress(address);

    const addressInput: HTMLInputElement = document.querySelector("#address1");

    if (!place.streetNumber.short_name) {
      place.address = `${addressInput.value.split(",")[0]}`;
    }

    addressInput.value = place.address;
    setImmediate(() => (this.model.Address.Address1 = place.address));

    this.model.Address.Address2 = place.neighborhood.short_name;
    this.model.Address.City = place.city.long_name;
    this.model.Address.StateProvinceId = environmentConstant.states.find(
      (state: any) => state.Abbreviation === place.state.short_name
    ).Id;
    this.model.Address.ZipPostalCode = place.postalCode.long_name;
  }

  /**
   * search city, state and country by zip code
   * @param zipCode zip code to search
   */
  searchByZIP(event): void {
    event.preventDefault();
    const zipCode = event.target.value;
    if (this.zipValidate) return;
    this.googleService.searchByZIP(zipCode).subscribe((response) => {
      const result = response.results[0];
      if (!result) {
        this.toastrService.error(
          "Postal code doesn't exist.",
          "Customer Address"
        );
        this.model.Address.City = "";
        this.model.Address.StateProvinceId = "";
        return;
      }
      const address = result.address_components;
      this.onPlaceSelect(address);
    });
  }

  /**
   * returns the value of the place component
   * @param components place components data
   */
  onPlaceSelect(components: any[]): void {
    const city = this.getComponentValue(components, "locality");
    const state = this.getComponentValue(
      components,
      "administrative_area_level_1",
      false
    );
    const country = this.getComponentValue(components, "country", false);
    if (country != "US" && country != "CA") {
      this.toastrService.error("Postal Code is not valid.", "Customer Address");
      this.model.Address.City = "";
      this.model.Address.StateProvinceId = "";
      return;
    }
    this.zipValidate = false;
    this.model.Address.City = city;
    this.model.Address.StateProvinceId = environmentConstant.states.find(
      (innerState: any) => innerState.Abbreviation === state
    ).Id;
  }

  /**
   * gets data from the place component by type
   * @param components place components data
   * @param type place component type
   * @param isLongName if return long name or short name
   * @returns either long name or short name of the place component
   */
  getComponentValue(
    components: any[],
    type: string,
    isLongName: boolean = true
  ): string {
    const component = components.find((comp) => comp.types.includes(type));

    if (isLongName) {
      return component ? component.long_name : "";
    }

    return component ? component.short_name : "";
  }

  /**
   * Sets the dialog for duplicate record
   */
  setDialogDuplicateRecord(): void {
    if (!this.dialogOpen) {
      const disposable = this.dialogService
        .addDialog(ContactsDialogComponent, {
          title: "Existing Customer Found",
          message:
            "We found a duplicate record matching the phone or email entered. Please Contact System Administrator",
        })
        .subscribe((data) => {
          this.dialogOpen = false;
        });
      this.dialogOpen = true;
    }
  }
}
