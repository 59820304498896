<div class="modal-dialog modal-md pentair-dialog">
  <div class="modal-content pentair-dialog-content">
    <div class="modal-header pentair-dialog-header">
      <button type="button" class="close" (click)="close()">&times;</button>
      <h4 class="modal-title pentair-dialog-title pb-3">
        {{title || 'Confirm'}}
      </h4>
    </div>
    <div class="modal-body pentair-dialog-body">
      <p class="mb-2">{{message || 'Are you sure?'}}</p>
      <p *ngIf="message2 != ''">{{message2}}</p>
      <div class="mb-1" *ngIf="isUsed">
        <p>
          Please select alternate file type. This will be replaced with deleted
          file type incase deleted file type is associated with existing files.
        </p>
      </div>
      <kendo-textbox-container
        floatingLabel="File Types"
        [ngClass]="{ 'k-state-filled': true }"
        *ngIf="isUsed"
      >
        <kendo-combobox
          [data]="fileTypes"
          (valueChange)="onSelectFileType($event)"
          [valuePrimitive]="true"
          [filterable]="true"
          name="fileTypes"
          (filterChange)="filterFileTypes($event)"
          [textField]="'FileTypeName'"
          [(ngModel)]="selectedFileType"
          [valueField]="'Id'"
        ></kendo-combobox>
      </kendo-textbox-container>
    </div>
    <div class="modal-footer mt-3 d-flex layou-flex-end">
      <button
        type="button"
        class="button button--secondary"
        *ngIf="!isNotification"
        (click)="close()"
      >
        Cancel
      </button>
      <button type="button" class="button button--primary" (click)="confirm()">
        Confirm
      </button>
    </div>
  </div>
</div>
