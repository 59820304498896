import {
  ProjectPackageTemplate,
  PagedDocumentsResponse,
  PagedDocumentsSearchParams,
  AuthorizationCodeUrlResponse,
  DocumentField,
  ChangeOrderSignNowTemplate,
  SignNowTemplateSigners,
  SignNowSignersRequest,
} from "../../model/SignNowModels";
import { Helper } from "app/common/utility/helper";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable, Subject } from "rxjs";
const base_url = environment.baseEstimateUrl;

/** SignNow Service */
@Injectable({
  providedIn: "root",
})
export class SignNowService {
  options = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("token"),
    },
  };

  private signNowSubject = new Subject<any>();
  constructor(private http: HttpClient) {}

  getAuthorizationCodeUrl(
    returnUrl: string
  ): Observable<AuthorizationCodeUrlResponse> {
    const url = `${base_url}signNow/authorizationCode?returnUrl=${returnUrl}`;
    return this.http.get<AuthorizationCodeUrlResponse>(url, this.options);
  }

  setAuthorizationCode(code: string) {
    const url = `${base_url}signNow/authorizationCode`;
    return this.http.post(
      url,
      {
        code: code,
      },
      this.options
    );
  }

  /** retrieves paged e-templates */
  getDocuments(searchParams: PagedDocumentsSearchParams) {
    const url = `${base_url}signNow/documents${Helper.convertToQueryString(
      searchParams
    )}`;
    return this.http.get<PagedDocumentsResponse>(url, this.options);
  }

  /** adds a new e-template */
  uploadDocument(formData: any) {
    let url = `${base_url}signNow/documents`;
    return this.http.post(url, formData, this.options);
  }

  deleteDocument(documentId: string): Observable<any> {
    let url = `${base_url}signNow/documents?id=${documentId}`;
    return this.http.delete(url, this.options);
  }

  downloadDocument(
    documentId: string,
    organizationGuid: string = ""
  ): Observable<Blob> {
    let url = `${base_url}signNow/documents/${documentId}/download/${organizationGuid}`;
    return this.http.get(url, {
      responseType: "blob",
      headers: { Authorization: "Bearer " + localStorage.getItem("token") },
    });
  }

  getProjectPackageTemplates(
    projectPackageId: number
  ): Observable<ProjectPackageTemplate[]> {
    let url = `${base_url}signNow/projectPackages/${projectPackageId}/templates`;
    return this.http.get<ProjectPackageTemplate[]>(url);
  }

  addProjectPackageTemplates(
    projectPackageId: number,
    eDocumentTemplateIds: number[]
  ): Observable<any> {
    let url = `${base_url}signNow/projectPackages/${projectPackageId}/templates`;
    return this.http.post(url, {
      eDocumentTemplateIds: eDocumentTemplateIds,
    });
  }

  reorderProjectPackageTemplates(
    projectPackageId: number,
    eDocumentTemplateIds: number[]
  ): Observable<any> {
    let url = `${base_url}signNow/projectPackages/${projectPackageId}/templates`;
    return this.http.put(url, {
      eDocumentTemplateIds: eDocumentTemplateIds,
    });
  }

  deleteProjectPackageTemplate(
    projectPackageId: number,
    projectPackageTemplateId: number
  ): Observable<any> {
    let url = `${base_url}signNow/projectPackages/${projectPackageId}/templates/${projectPackageTemplateId}`;
    return this.http.delete<boolean>(url);
  }

  getAuthCodeUrl(returnUrl: string) {
    return this.http.get(
      `${base_url}signNow/authorizationCode?returnUrl=${returnUrl}`
    );
  }

  setAuthCode(code: string, orgId: string) {
    return this.http.post(`${base_url}signNow/connected`, { code, orgId });
  }

  verifyOrg(orgGuid: string) {
    return this.http.get<boolean>(`${base_url}signNow/verify/${orgGuid}`);
  }

  disconnectOrganization(orgGuid: string) {
    return this.http.delete(`${base_url}signNow/disconnect/${orgGuid}`);
  }

  /**
   * triggers the event when is disconnected
   */
  disconnectMenuTrigger() {
    this.signNowSubject.next(false);
  }

  /**
   * triggers the event when is connected
   */
  connectMenuTrigger() {
    this.signNowSubject.next(true);
  }

  /**
   * gets the conection status from sign now
   */
  statusFromSignNow() {
    return this.signNowSubject.asObservable();
  }

  getDocumentFields(documentId: string, orgGuid: string) {
    return this.http.get<DocumentField[]>(
      `${base_url}signNow/documents/${documentId}/${orgGuid}/fields`,
      this.options
    );
  }

  getChangeOrderEDocs(
    changeOrderId: number
  ): Observable<ChangeOrderSignNowTemplate[]> {
    return this.http.get<ChangeOrderSignNowTemplate[]>(
      `${base_url}signNow/documents/changeorder/${changeOrderId}`
    );
  }

  updateEDocsList(
    changeOrderId: number,
    templates: ChangeOrderSignNowTemplate[]
  ): Observable<boolean> {
    templates.forEach((template) => {
      delete template.template;
      delete template.isNew;
    });
    return this.http.post<boolean>(
      `${base_url}signNow/documents/changeorder/${changeOrderId}`,
      templates
    );
  }

  getSignersByEstimateWorksheetGuid(estimateGuid: string) {
    return this.http.get<SignNowTemplateSigners[]>(
      `${base_url}signNow/signers/${estimateGuid}`
    );
  }

  postSignersByEstimateWorksheetGuid(signers: SignNowSignersRequest) {
    return this.http.post(`${base_url}signers`, signers);
  }
}
