<p-toast class="general-toast" position="top-right"></p-toast>
<div class="widgets commonFixedSidebar">
  <div class="row">
    <div class="col-md-12 ht100">
      <div class="rightcontent">
        <div class="sideBarHeader" *ngIf="!isSlideOut">
          <h1>{{headerText }} {{headerData}}</h1>
        </div>
        <div class="tabBlk">
          <form
            class="form-horizontal positionbtn"
            #myForm="ngForm"
            (ngSubmit)="onSubmit(myForm)"
            *ngIf="customer!=undefined"
          >
            <ng-container *ngIf="custId !=0; else userdetailform">
              <kendo-tabstrip [keepTabContent]="true">
                <kendo-tabstrip-tab [title]="'Details'" [selected]="true">
                  <ng-template kendoTabContent>
                    <ng-container
                      *ngTemplateOutlet="userdetailform"
                    ></ng-container>
                  </ng-template>
                </kendo-tabstrip-tab>

                <kendo-tabstrip-tab
                  [title]="'Jobs'"
                  [disabled]="customer.CustomerGuid === undefined"
                >
                  <ng-template kendoTabContent *loadOnDemand>
                    <job-grid [customer]="customer" (closed)="onCancel()">
                    </job-grid>
                  </ng-template>
                </kendo-tabstrip-tab>

                <kendo-tabstrip-tab
                  [title]="'Work Orders'"
                  [disabled]="customer.CustomerGuid === undefined"
                >
                  <ng-template kendoTabContent *loadOnDemand>
                    <workOrder-grid [customer]="customer" (closed)="onCancel()">
                    </workOrder-grid>
                  </ng-template>
                </kendo-tabstrip-tab>

                <kendo-tabstrip-tab
                  [title]="'Orders'"
                  [disabled]="customer.CustomerGuid === undefined"
                  *ngIf="isFinancial && false"
                >
                  <ng-template kendoTabContent *loadOnDemand>
                    <mpbui-order-grid
                      [customer]="customer"
                      (closed)="onCancel()"
                    >
                    </mpbui-order-grid>
                  </ng-template>
                </kendo-tabstrip-tab>
                <!--<kendo-tabstrip-tab [title]="'QuickBooks'">
                  <ng-template kendoTabContent>
                    <quickbooks-options
                      [identifier]="quickBooksSearch.identifier"
                      [form]="myForm"
                      [model]="customer"
                    ></quickbooks-options>
                    <quickbooks-search-customers
                      #quickBooksSearch
                    ></quickbooks-search-customers>
                  </ng-template>
                </kendo-tabstrip-tab>
                -->
              </kendo-tabstrip>
            </ng-container>

            <ng-template #userdetailform>
              <div [ngClass]="{'fixed-layout hoverflow': custId === 0}">
                <div class="row">
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="form-group">
                      <label class="k-form-field">
                        <input
                          type="checkbox"
                          id="Disable"
                          name="Disable"
                          class="k-checkbox"
                          [(ngModel)]="customer.Disable"
                        />
                        <span class="k-checkbox-label" for="Disable"
                          >Login disabled</span
                        >
                      </label>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div
                      class="text-right"
                      *ngIf="customer.Id && customer.Id>0"
                    >
                      ID : {{customer.Id}}
                    </div>
                  </div>
                </div>
                <div class="row" style="margin-bottom: 15px">
                  <div class="col-lg-7 col-md-7 col-sm-7">
                    <div class="form-group mb0">
                      <span class="ui-float-label">
                        <input
                          type="text"
                          id="Company"
                          name="Company"
                          pInputText
                          [(ngModel)]="customer.Company"
                          #Company="ngModel"
                          class="form-control"
                        />
                        <label for="text" class="form-control-label"
                          >Company Name</label
                        >
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-4" style="display: none">
                    <div class="form-group Mandat mb0">
                      <kendo-floatinglabel text="Phase">
                        <kendo-dropdownlist
                          [data]="customerPhaseList"
                          [valuePrimitive]="true"
                          [filterable]="false"
                          [textField]="'Name'"
                          [valueField]="'Kind'"
                          [ngClass]="{'has-error':(CustomerPhase.invalid && custId != 0) || (custId==0 && CustomerPhase.touched)}"
                          [(ngModel)]="customer.Phase"
                          name="CustomerPhase"
                          #CustomerPhase="ngModel"
                        >
                        </kendo-dropdownlist>
                      </kendo-floatinglabel>
                    </div>
                  </div>
                  <div class="col-lg-5 col-md-5 col-sm-5">
                    <div class="form-group mb0">
                      <span class="ui-float-label">
                        <input
                          type="text"
                          id="dealer"
                          name="dealer"
                          pInputText
                          [(ngModel)]="customer.DealerNumber"
                          #dealer="ngModel"
                          class="form-control"
                        />
                        <label
                          title="Custom Job Number"
                          for="text"
                          class="form-control-label"
                          >Custom Job Number</label
                        >
                      </span>
                    </div>
                  </div>
                </div>
                <contacts-tabbed-entry
                  [model]="customer"
                  [jobType]="2"
                ></contacts-tabbed-entry>

                <!-- <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <h3 class="pt">Customer Address</h3>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="form-group">
                      <span class="ui-float-label">
                        <input
                          type="text"
                          class="form-control"
                          id="address1"
                          name="address1"
                          pInputText
                          [(ngModel)]="customer.Address.Address1"
                          #Address1="ngModel"
                          maxlength="250"
                        />
                        <label for="text" class="form-control-label"
                          >Address</label
                        >
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="form-group">
                      <span class="ui-float-label">
                        <input
                          type="text"
                          class="form-control"
                          id="address2"
                          name="address2"
                          pInputText
                          [(ngModel)]="customer.Address.Address2"
                          maxlength="250"
                        />
                        <label for="text" class="form-control-label"
                          >Subdivision/Area</label
                        >
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                    <div class="form-group">
                      <span class="ui-float-label">
                        <input
                          type="text"
                          class="form-control"
                          id="city"
                          name="city"
                          pInputText
                          [(ngModel)]="customer.Address.City"
                          maxlength="250"
                          #city="ngModel"
                          [ngClass]="{'has-error':city.invalid && (city.dirty || city.touched)}"
                          pattern="([a-zA-Z0-9.]{1,100}\s*)+"
                        />
                        <label for="text" class="form-control-label"
                          >City</label
                        >
                      </span>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                    <div class="form-group">
                      <kendo-textbox-container
                        floatingLabel="State"
                        [ngClass]="{'k-state-filled':true}"
                      >
                        <kendo-combobox
                          [data]="stateList"
                          [valuePrimitive]="true"
                          [filterable]="true"
                          (filterChange)="filterState($event)"
                          [textField]="'Abbreviation'"
                          [(ngModel)]="customer.Address.StateProvinceId"
                          name="StateProvinceId"
                          [valueField]="'Id'"
                        >
                        </kendo-combobox>
                      </kendo-textbox-container>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                    <div class="form-group">
                      <span class="ui-float-label">
                        <p-inputMask
                          type="text"
                          id="zipPostalCode"
                          name="zipPostalCode"
                          trim="blur"
                          [(ngModel)]="customer.Address.ZipPostalCode"
                          mask="99999"
                          slotChar=" "
                          autoClear="false"
                          styleClass="form-control"
                        ></p-inputMask>
                        <label for="text" class="form-control-label">Zip</label>
                      </span>
                    </div>
                  </div>
                </div> -->
                <div class="slidebg-adp2 pl-2 pr-2" *ngIf="id!=0">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="d-flex">
                            <div class="mr-4">
                              <h3 class="pt">Login Info</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="form-group">
                        <span class="ui-float-label">
                          <input
                            type="text"
                            class="form-control"
                            (blur)="isValidUserName(customer.UserName,userName.valid)"
                            id="userName"
                            minlength="6"
                            name="userName"
                            pInputText
                            [(ngModel)]="customer.UserName"
                            #userName="ngModel"
                            [ngClass]="{'has-error':userName.invalid && (userName.dirty || userName.touched)}"
                            maxlength="250"
                            preventBackSlashAndDoubleQuote
                          />
                          <span
                            class="form-msg"
                            [ngClass]="isValidUserNameVal?'red':'green'"
                            *ngIf="isValidUserNameVal!==undefined"
                          >
                            <span *ngIf="isValidUserNameVal"
                              ><i class="fa fa-times" aria-hidden="true"></i
                            ></span>
                            <span *ngIf="!isValidUserNameVal">
                              <i class="fa fa-check" aria-hidden="true"></i
                            ></span>
                          </span>
                          <label for="text" class="form-control-label"
                            >User Name
                            <!-- <span class="text-red">*</span> --></label
                          >
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="pr-2 pl-2 mt-3 sectionBox"
                  style="padding: 5px !important"
                >
                  <h3 class="mb-2">Preferences</h3>
                  <div class="d-flex mb15">
                    <div
                      class="form-group mb-0"
                      style="background-color: transparent !important"
                    >
                      <div class="checkbox m-0 preferenceCells">
                        <label class="k-form-field">
                          <input
                            type="checkbox"
                            id="LiveView"
                            name="LiveView"
                            [(ngModel)]="customer.Preference.LiveView"
                            class="k-checkbox"
                          />
                          <span class="k-checkbox-label" for="LiveView"
                            >Live View</span
                          >
                        </label>
                      </div>
                    </div>
                    <div
                      class="form-group mb-0"
                      style="background-color: transparent !important"
                    >
                      <div class="checkbox m-0 preferenceCells">
                        <label class="k-form-field">
                          <input
                            type="checkbox"
                            id="NotificationEmail"
                            name="NotificationEmail"
                            [(ngModel)]="customer.Preference.NotificationEmail"
                            class="k-checkbox"
                          />
                          <span class="k-checkbox-label" for="emailnoti"
                            >Email Notification</span
                          >
                        </label>
                      </div>
                    </div>
                    <div
                      class="form-group mb-0"
                      style="background-color: transparent !important"
                    >
                      <div class="checkbox m-0 preferenceCells">
                        <label class="k-form-field">
                          <input
                            type="checkbox"
                            id="NotificationSMS"
                            name="NotificationSMS"
                            [(ngModel)]="customer.Preference.NotificationSMS"
                            class="k-checkbox"
                          />
                          <span class="k-checkbox-label" for="smsnoti"
                            >SMS Notification</span
                          >
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex mb15">
                    <div
                      class="form-group mb-0"
                      style="background-color: transparent !important"
                    >
                      <div class="checkbox m-0 preferenceCells">
                        <label class="k-form-field">
                          <input
                            type="checkbox"
                            id="SendToSecondary"
                            name="SendToSecondary"
                            [(ngModel)]="customer.Preference.SendToSecondary"
                            class="k-checkbox"
                          />
                          <span class="k-checkbox-label" for="SendToSecondary"
                            >Send to Secondary Contact</span
                          >
                        </label>
                      </div>
                    </div>
                    <div
                      class="form-group mb-0"
                      style="background-color: transparent !important"
                    >
                      <div class="checkbox m-0 preferenceCells">
                        <label class="k-form-field">
                          <input
                            type="checkbox"
                            id="Chat"
                            name="Chat"
                            [(ngModel)]="customer.chatEnabled"
                            class="k-checkbox"
                          />
                          <span class="k-checkbox-label" for="Chat">Chat</span>
                        </label>
                      </div>
                    </div>

                    <!-- using for group div for spacing -->
                    <div
                      class="form-group mb-0"
                      style="background-color: transparent !important"
                    ></div>
                  </div>
                </div>

                <div class="d-flex mb15" *ngIf="isSuperAdmin">
                  <div class="col-12 mt-3 pr-1 pl-1 sectionBox">
                    <quickbooks-options
                      [identifier]="quickBooksSearch.identifier"
                      [form]="myForm"
                      [model]="customer"
                    ></quickbooks-options>
                    <quickbooks-search-customers
                      #quickBooksSearch
                    ></quickbooks-search-customers>
                  </div>
                </div>

                <div *ngIf="customer.Id >0 && headerData=='Customer'">
                  <div class="dib sentitem" *ngIf="lastSendDate">
                    Sent: {{lastSendDate|date:'medium'}}
                  </div>
                </div>
                <div class="mb55"></div>
              </div>
              <div class="fixbtnwrap">
                <div class="btnwrap__center">
                  <button
                    type="submit"
                    class="btn darkbtn"
                    [disabled]="!myForm.valid || hideSaveButton"
                    *ngIf="myForm.dirty"
                  >
                    <i class="fa fa-floppy-o" aria-hidden="true"></i>
                    {{btnText}}
                  </button>
                  <button
                    *ngIf="!isSlideOut"
                    type="button"
                    class="cross btn bthpn2"
                    (click)="onCancel()"
                  >
                    Close
                  </button>
                </div>
              </div>
            </ng-template>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
