import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import { ChatService } from "app/common/services/Chat.service";
import { UserModel } from "app/model/Core/UserModel";
import { GroupModel } from "app/model/Chat/GroupModel";
import { Subscription, Observable } from "rxjs";
import { ChatPanelContextModel } from "app/model/Chat/ChatPanelContextModel";
import { ChatUserModel } from "app/model/Chat/ChatUserModel";

@Component({
  selector: "chat",
  templateUrl: "./Chat.component.html",
  styles: [
    `
      :host {
        background: #e6eaea;
      }

      :host ::ng-deep .k-window-content,
      :host ::ng-deep .k-prompt-container {
        padding: 0;
        overflow: hidden;
      }

      :host ::ng-deep .k-dialog-titlebar {
        background-color: #2f4356;
        height: 50px;
      }

      :host ::ng-deep .k-dialog-titlebar button {
        float: right;
      }

      :host ::ng-deep .k-dialog-titlebar ul {
        float: right;
        list-style: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: calc(100% - 90px);
      }
      :host ::ng-deep .k-dialog-titlebar ul li {
        display: inline-block;
      }

      :host ::ng-deep .k-dialog-titlebar span.chat-notification {
        position: absolute;
        left: 0;
        margin: -25px 0 0 10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: #ffffff;
        background: red;
        text-align: center;
      }

      :host .chat-window-buttons {
        float: right;
        display: inline-flex;
        clear: both;
        position: absolute;
        right: 0;
      }

      .chat-wrapper {
        position: absolute;
      }

      .chat-sidebar-panel {
        width: 100%;
        margin: 0;
        color: #ffffff;
        background-color: #e6eaea;
        padding: 0;
        height: 100%;
      }

      .chat-sidebar-panel-content {
        padding: 10px;
        height: calc(100% - 50px);
        overflow-y: scroll;
      }

      .chat-sidebar-panel i {
        cursor: pointer;
        margin: 15px 10px 0 0;
        right: 0;
        position: absolute;
        font-size: 20px;
      }

      .chat-sidebar-panel-header {
        background-color: #2c3e50;
        height: 50px;
        width: 100%;
      }
      .chat-sidebar-panel-header h3 {
        line-height: 50px;
        padding-left: 10px;
      }
      .chat-shadow-overlay {
        background-color: #000000;
        opacity: 0.6;
        position: absolute;
        height: 100%;
        top: 0;
      }

      .chat-sidebar-panel-container {
        position: absolute;
        height: 100%;
        top: 0;
      }
    `,
  ],
})
export class ChatComponent implements OnInit, OnDestroy {
  @Input() visible: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public panelContext: ChatPanelContextModel<any>;
  public height: number;
  public width: number;
  public top: number;
  public left: number;
  public minimized: boolean = false;
  private initialHeight: number = 544;
  private initialWidth: number = 995;
  private heightOffset: number = 55;
  public selectedGroup: Observable<GroupModel>;
  public user: ChatUserModel;
  public notifications: number = 0;
  private subscriptions: Subscription = new Subscription();

  constructor(private chatService: ChatService) {}

  public ngOnInit(): void {
    this.user = this.chatService.user;

    this.selectedGroup = this.chatService.selectedGroup;

    this.setDefault();
    this.setSubscriptions();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setSubscriptions(): void {
    this.subscriptions.add(
      this.chatService.unreadCount.subscribe((count: number) => {
        this.notifications = count;
      })
    );
  }

  private setDefault(): void {
    this.width = this.initialWidth;
    this.height = this.initialHeight;
    this.top = (window.innerHeight - this.height) / 2;
    this.left = (window.innerWidth - this.width) / 2;
  }

  public onStateChange(state: string): void {
    if (state == "minimized") {
      this.top = window.innerHeight - this.heightOffset;
      this.width = 300;
      this.left = window.innerWidth - this.width;
      this.minimized = true;
    } else if (state == "default") {
      this.notifications = 0;
      this.minimized = false;
      this.setDefault();
    }
    this.chatService.minimized = this.minimized;
  }

  public onPanelContextChanged(model: ChatPanelContextModel<any>) {
    this.panelContext = model;
  }

  public onPanelCompleted(didComplete: boolean): void {
    this.panelContext = null;
  }

  public onClose(): void {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }
}
