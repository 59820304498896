<h1 class="title-wizard">Estimate Wizard</h1>

<div class="" mat-dialog-content>
  <div class="spinner-content" *ngIf="loading">
    <div class="spinner" style="top: 35%; position: absolute">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <!-- STEP -->
  <mat-horizontal-stepper
    [linear]="true"
    #stepper
    labelPosition="bottom"
    [@.disabled]="true"
    *ngIf="!loading"
    [ngClass]="'last-edited-step-' + stepper.selectedIndex"
  >
    <!--ESTIMATE INFO-->
    <mat-step [stepControl]="estimateInfo" label="Estimate Info">
      <form [formGroup]="estimateInfo">
        <div
          style="margin-top: 10px"
          class="row mx-auto estimate-wizard__estimate-name"
        >
          <div class="col-6">
            <div class="input-pentair-std">
              <label>Estimate Name</label>
              <input
                type="text"
                formControlName="estimateName"
                maxlength="50"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="dropdown-pentair-std w-100">
              <label
                class="dropdown-pentair-std__title dropdown-pentair-std__title-kendo"
                style="font-weight: 700"
                >Taxing Authority</label
              >
              <kendo-combobox
                id="taxbox"
                [data]="taxingAuthorities"
                formControlName="orgTaxAuthorityId"
                [valuePrimitive]="true"
                [filterable]="true"
                (filterChange)="filterTaxAuthorities($event)"
                [textField]="'label'"
                [valueField]="'id'"
                class="catalog-picker__search-combobox"
                (selectionChange)="onTaxAuthorityChange($event)"
              >
              </kendo-combobox>
              <div class="small text-danger" *ngIf="submitted">
                <span
                  *ngIf="
                    estimateInfo.controls['orgTaxAuthorityId'].errors?.required
                  "
                >
                  Field is required
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          style="margin-top: 10px"
          class="row mx-auto estimate-wizard__estimate-name"
        >
          <div class="col-6">
            <div class="input-pentair-std">
              <label>Customer Name</label>
              <input
                type="text"
                [readonly]="true"
                formControlName="customerName"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="input-pentair-std">
              <label>Customer Email Address</label>
              <input type="text" formControlName="customerEmail" />
            </div>
            <div class="small text-danger" *ngIf="submitted">
              <span *ngIf="estimateInfo.get('customerEmail').hasError('email')">
                Invalid email
              </span>
              <span
                *ngIf="estimateInfo.get('customerEmail').hasError('required')"
              >
                Field is required
              </span>
            </div>
          </div>
        </div>
        <div
          style="
            margin-top: 10px;
            border: 1px solid #0a244f;
            padding-top: 20px;
            margin-bottom: 90px;
            padding-bottom: 10px;
          "
          class="row mx-auto estimate-wizard__estimate-name"
        >
          <div class="col">
            <div class="row">
              <div class="col-5">
                <div class="dropdown-pentair-std w-100">
                  <label
                    class="dropdown-pentair-std__title dropdown-pentair-std__title-kendo"
                    style="font-weight: 700"
                  >
                    Draw Schedule
                  </label>
                  <kendo-combobox
                    [data]="drawSchedules"
                    formControlName="drawScheduleId"
                    [valuePrimitive]="true"
                    [filterable]="true"
                    (filterChange)="filterDrawScheduleList($event)"
                    [textField]="'ScheduleName'"
                    [valueField]="'ID'"
                    class="catalog-picker__search-combobox"
                  >
                  </kendo-combobox>
                </div>
              </div>
              <div class="col-3 wizard-check">
                <mat-checkbox
                  class="example-margin"
                  formControlName="includeOnProposal"
                  >Include on Proposal</mat-checkbox
                >
              </div>
              <div class="col-3 wizard-check">
                <mat-checkbox
                  class="example-margin"
                  formControlName="includeOnContract"
                  >Include on Contract</mat-checkbox
                >
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="wizard__steps k-form-buttons k-buttons-end">
        <div class="wizard__step wizard__step--cancel">
          <button
            class="estimate-button estimate-button--secondary"
            (click)="close()"
          >
            Cancel
          </button>
        </div>
        <button
          class="estimate-button estimate-button--ghost"
          (click)="nextStepProjectDetails()"
        >
          Next
        </button>
      </div>
    </mat-step>
    <!-- PROJECT DETAILS -->
    <mat-step [stepControl]="projectDetailForm" label="Project Details">
      <div class="containter-step">
        <form [formGroup]="projectTypeForm" class="width-100">
          <div class="row width-100 margin-top-10">
            <div class="col-6">
              <div class="dropdown-pentair-std w-100">
                <label
                  class="dropdown-pentair-std__title dropdown-pentair-std__title-kendo"
                  style="font-weight: 700"
                  >Project Type</label
                >
                <kendo-combobox
                  id="projectType"
                  [data]="projects"
                  formControlName="projectType"
                  [valuePrimitive]="true"
                  [filterable]="true"
                  (filterChange)="filterProjects($event)"
                  [textField]="'projectName'"
                  [valueField]="'id'"
                  class="catalog-picker__search-combobox"
                  (selectionChange)="changeProject($event)"
                >
                </kendo-combobox>
                <div class="small text-danger" *ngIf="submitted">
                  <span
                    *ngIf="
                      projectTypeForm.controls['projectType'].errors?.required
                    "
                  >
                    Field is required
                  </span>
                </div>
              </div>
            </div>
            <div class="col-6"></div>
          </div>
        </form>
        <form [formGroup]="ProjectSubtypeForm" class="width-100">
          <div class="row width-100 margin-top-10">
            <div class="col-6">
              <div class="dropdown-pentair-std w-100">
                <label
                  class="dropdown-pentair-std__title dropdown-pentair-std__title-kendo"
                  style="font-weight: 700"
                  >Project Subtype</label
                >
                <kendo-combobox
                  id="projectSubType"
                  [data]="projectsSubType"
                  formControlName="projectSubType"
                  [valuePrimitive]="true"
                  [filterable]="true"
                  (filterChange)="filterSubProjects($event)"
                  [textField]="'categoryName'"
                  [valueField]="'id'"
                  class="catalog-picker__search-combobox"
                  (selectionChange)="changeProjectSubType($event)"
                >
                </kendo-combobox>
                <div class="small text-danger" *ngIf="submitted">
                  <span
                    *ngIf="
                      ProjectSubtypeForm.controls['projectSubType'].errors
                        ?.required
                    "
                  >
                    Field is required
                  </span>
                </div>
              </div>
            </div>
            <div class="col-6"></div>
          </div>
        </form>
        <form [formGroup]="pricingTempleteForm" class="width-100">
          <div class="row width-100 margin-top-10">
            <div class="col-6">
              <div class="dropdown-pentair-std w-100">
                <label
                  class="dropdown-pentair-std__title dropdown-pentair-std__title-kendo"
                  style="font-weight: 700"
                  >Pricing Template</label
                >
                <kendo-combobox
                  id="pricingTemplate"
                  [data]="princingTemplate"
                  formControlName="pricingTemplete"
                  [valuePrimitive]="true"
                  [filterable]="true"
                  (filterChange)="filterPricingTemplates($event)"
                  [textField]="'packageName'"
                  [valueField]="'id'"
                  class="catalog-picker__search-combobox"
                  (selectionChange)="changePricingTemplate($event)"
                >
                </kendo-combobox>
                <div class="small text-danger" *ngIf="submitted">
                  <span
                    *ngIf="
                      pricingTempleteForm.controls['pricingTemplete'].errors
                        ?.required
                    "
                  >
                    Field is required
                  </span>
                </div>
              </div>
            </div>
            <div class="col-6"></div>
          </div>
        </form>
        <form [formGroup]="workareasForm" class="width-100">
          <div class="row width-100 margin-top-10">
            <div class="col-6">
              <div class="dropdown-pentair-std w-100">
                <label
                  class="dropdown-pentair-std__title dropdown-pentair-std__title-kendo"
                  style="font-weight: 700"
                  >Work Areas</label
                >
                <kendo-multiselect
                  style="
                    height: 2.7em;
                    border-radius: 0;
                    border: 1px solid #0a244f;
                  "
                  [data]="workAreaSpecs"
                  [filterable]="true"
                  (filterChange)="filterWorkAreaSpecs($event)"
                  [textField]="'workAreaName'"
                  [valueField]="'id'"
                  name="workAreas"
                  formControlName="workareas"
                  (valueChange)="changeWorkAreaSpecs($event)"
                >
                </kendo-multiselect>
              </div>
            </div>
            <div class="col-6"></div>
          </div>
        </form>
      </div>

      <div class="wizard__steps k-form-buttons k-buttons-end">
        <div class="wizard__step wizard__step--cancel">
          <button
            class="estimate-button estimate-button--secondary"
            (click)="close()"
          >
            Cancel
          </button>
        </div>
        <div class="wizard__step">
          <button
            class="estimate-button estimate-button--ghost"
            matStepperPrevious
          >
            Back
          </button>
          <button
            class="estimate-button estimate-button--ghost"
            (click)="goToWorkAreaSpecs()"
          >
            Next
          </button>
        </div>
      </div>
    </mat-step>

    <!-- WORK AREA SPECS -->
    <mat-step [stepControl]="worksheetWorkAreasForm" label="Work Area Specs">
      <button
        class="estimate-button estimate-button--primary generate-position"
        matStepperNext
        (click)="generateWorksheet()"
        [disabled]="
          !isValidFormula ||
          (!worksheetWorkAreasForm?.valid &&
            (parametersFormSubmitted || submitted)) ||
          !worksheetWorkAreasForm?.valid
        "
      >
        Create Estimate
      </button>
      <div *ngIf="selectedWorkAreaSpecs.length == 0" class="noWorkAreas">
        No work areas selected.
      </div>
      <div class="row mx-auto workarea-row estimate-wizard__work-area">
        <div *ngIf="!chargingInfo" class="w-100">
          <form [formGroup]="worksheetWorkAreasForm">
            <p-tabView
              class="work-area-tab-view"
              formArrayName="worksheetWorkAreas"
            >
              <p-tabPanel
                [header]="selectedWorkAreaSpecs[i].workAreaName"
                *ngFor="
                  let worksheetWorkAreasItem of getWorksheetWorkAreasFormArray(
                    worksheetWorkAreasForm
                  );
                  let i = index
                "
                [formGroupName]="i"
              >
                <p-tabView
                  class="group-tab-view"
                  formArrayName="parameterGroups"
                  orientation="left"
                  [hidden]="!selectedWorkAreaSpecs[i].parameterGroups.length"
                >
                  <p-tabPanel
                    [header]="
                      selectedWorkAreaSpecs[i].parameterGroups[k].description
                    "
                    *ngFor="
                      let workAreaParametersGroupItem of getWorksheetWorkAreaParametersGroup(
                        worksheetWorkAreasItem
                      );
                      let k = index
                    "
                    [formGroupName]="k"
                  >
                    <span
                      style="
                        font-weight: bold;
                        margin-bottom: 10px;
                        color: #0a244f;
                      "
                    >
                      {{
                        selectedWorkAreaSpecs[i].parameterGroups[k].description
                      }}
                      Parameters
                    </span>
                    <div
                      class="row workarea-parameters"
                      style="overflow-y: auto"
                      formArrayName="worksheetWorkAreaParameters"
                    >
                      <div
                        [formGroupName]="j"
                        class="worksheet-input-container col-12 col-sm-6 col-xl-2_4"
                        *ngFor="
                          let worksheetWorkAreaParametersItem of getWorksheetWorkAreaParametersFormArray(
                            workAreaParametersGroupItem
                          );
                          let j = index
                        "
                        [hidden]="
                          !selectedWorkAreaSpecs[i].parameterGroups[k]
                            .parameters[j].canShow
                        "
                      >
                        <div class="workarea-textfield">
                          <input
                            *ngIf="
                              selectedWorkAreaSpecs[i].parameterGroups[k]
                                .parameters[j].valueType ==
                              parameterValueTypes.Text
                            "
                            type="text"
                            formControlName="textValue"
                            class="mt-3 p-2 w-100"
                          />
                          <select
                            *ngIf="
                              selectedWorkAreaSpecs[i].parameterGroups[k]
                                .parameters[j].valueType ==
                                parameterValueTypes.YesNo ||
                              selectedWorkAreaSpecs[i].parameterGroups[k]
                                .parameters[j].valueType ==
                                parameterValueTypes.DropDownNumeric ||
                              selectedWorkAreaSpecs[i].parameterGroups[k]
                                .parameters[j].valueType ==
                                parameterValueTypes.DropDownText
                            "
                            [formControlName]="
                              getFormControlName(
                                selectedWorkAreaSpecs[i].parameterGroups[k]
                                  .parameters[j].valueType
                              )
                            "
                            class="mt-3 p-2 w-100"
                          >
                            <option
                              *ngFor="
                                let option of getOptions(
                                  selectedWorkAreaSpecs[i].parameterGroups[k]
                                    .parameters[j].valueType,
                                  selectedWorkAreaSpecs[i].parameterGroups[k]
                                    .parameters[j].customValue
                                )
                              "
                              [ngValue]="option.value"
                            >
                              {{ option.label }}
                            </option>
                          </select>
                          <input
                            *ngIf="
                              selectedWorkAreaSpecs[i].parameterGroups[k]
                                .parameters[j].valueType ==
                              parameterValueTypes.Number
                            "
                            type="number"
                            formControlName="value"
                            class="mt-3 p-2 w-100"
                            decimalFormat
                            [negative]="true"
                            [decimals]="
                              selectedWorkAreaSpecs[i].parameterGroups[k]
                                .parameters[j].numberType
                            "
                          />
                          <label class="workarea-textfield-label ml-2">
                            {{
                              selectedWorkAreaSpecs[i].parameterGroups[k]
                                .parameters[j].parameterName
                            }}&nbsp; ({{
                              selectedWorkAreaSpecs[i].parameterGroups[k]
                                .parameters[j].unitOfMeasureName
                            }})
                          </label>
                        </div>
                        <div
                          *ngIf="worksheetWorkAreaParametersItem.controls.value"
                          class="d-flex justify-content-end small text-danger mt-1"
                          [ngStyle]="{
                            visibility:
                              submitted ||
                              parametersFormSubmitted ||
                              !worksheetWorkAreaParametersItem.errors
                                ?.invalid ||
                              !isValidFormula
                                ? 'unset'
                                : 'hidden'
                          }"
                        >
                          <span
                            [ngStyle]="{
                              visibility:
                                worksheetWorkAreaParametersItem.controls.value
                                  .errors?.incorrect ||
                                worksheetWorkAreaParametersItem.controls
                                  .textValue.errors?.required ||
                                worksheetWorkAreaParametersItem.controls
                                  .booleanValue.errors?.required ||
                                worksheetWorkAreaParametersItem.controls.value
                                  .errors
                                  ? 'unset'
                                  : 'hidden'
                            }"
                          >
                            <span>Error &nbsp;</span>
                            <span>
                              <i
                                [pTooltip]="
                                  worksheetWorkAreaParametersItem.controls
                                    .errorMsg.value
                                "
                                tooltipPosition="right"
                                tooltipZIndex="12000"
                                [escape]="false"
                                class="fa fa-info-circle text-danger"
                                aria-hidden="true"
                              >
                              </i>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </p-tabPanel>
                </p-tabView>
              </p-tabPanel>
            </p-tabView>
          </form>
        </div>
      </div>
      <div class="wizard__steps k-form-buttons k-buttons-end">
        <div class="wizard__step wizard__step--cancel">
          <button
            class="estimate-button estimate-button--secondary"
            (click)="close()"
          >
            Cancel
          </button>
        </div>
        <div class="wizard__step">
          <button class="btn-ghost" matStepperPrevious>Back</button>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
