import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { EditorComponent } from "@tinymce/tinymce-angular";
import { ImageViewerService } from "app/common/services/image-viewer.service";
import { JobFileService } from "app/common/services/jobFile.service";
import { SharedService } from "app/common/utility/SharedService";
import { UserModel } from "app/model/Core/UserModel";
import {
  JobFileModel,
  JobFileNote,
  JobFileNoteUI,
  JobFileTag,
  UpdateJobFile,
} from "app/model/jobFile";
import { JobPhotoModel } from "app/model/jobPhotoModel";
import { UserPhotoModel } from "app/model/userPhotoModel";
import { TextEditorConfigService } from "../../../../common/services/textEditorConfig.service";

@Component({
  selector: "job-photo-roll-detail",
  templateUrl: "./job-photo-roll-detail.component.html",
  styleUrls: ["./job-photo-roll-detail.component.scss"],
})
export class JobPhotoRollDetailComponent implements OnInit {
  @Output() close = new EventEmitter();
  @Output() emitIcons = new EventEmitter<UserPhotoModel>();
  @Output() updated = new EventEmitter<UserPhotoModel>();
  @Input() imageData: UserPhotoModel;

  image: UserPhotoModel;
  comment: string = "";
  position;
  addTag: boolean = false;
  editComment: boolean = false;
  isDirty: boolean = false;
  tagEntry: string = "";
  jobFile: JobFileModel = new JobFileModel();
  jobFileNotes;
  tag: JobFileTag;
  inputComment: string = "";
  user;
  selectedTags: JobFileTag[] = [];
  tags: JobFileTag[] = [];
  lstComments = [];
  allVersions: JobPhotoModel[];
  displayPreview: boolean = false;
  editorConfig: EditorComponent["init"];

  constructor(
    private datePipe: DatePipe,
    private jobFileService: JobFileService,
    private sharedService: SharedService,
    private imageViewerService: ImageViewerService,
    private textEditorConfig: TextEditorConfigService
  ) {}

  ngOnInit() {
    this.editorConfig = this.textEditorConfig.editorConfig;
    this.image = Object.assign({}, this.imageData);
    this.user = this.sharedService.loggedInUser;
    this.GetAllTags();
    this.GetJobFile();
    this.getAllVersions();
    this.imageViewerService.setViewerImages([this.image.PhotoPath]);
  }

  GetJobFile() {
    this.jobFileService
      .getJobFileByGuid(this.image.JobFileGuid)
      .subscribe((response) => {
        this.jobFile = response;
        this.SortComments(response.JobFileNotes);
        response.JobFileNotes.forEach((note) => {
          if (this.user.CustomerGuid == note.CreatedBy.CustomerGuid)
            note.canEdit = true; //customer can only edit their own comments
          note.CustomerInitials = note.CreatedBy.CustomerInitials;
          note.DateString = this.datePipe.transform(
            note.Created,
            "EE M-dd-yy * h:mm:ss a"
          );
        });

        this.lstComments = response.JobFileNotes;
        this.selectedTags = [];
        response.JobFileTags.forEach((tag) => {
          var item = this.tags.find((x) => x.Id == tag.Id);
          this.selectedTags.push(item);
        });

        if (this.selectedTags == null) this.selectedTags = [];
        if (this.lstComments == null) this.lstComments = [];
      });
  }

  thumbnailUpdated() {
    this.updated.emit();
    this.getAllVersions();
  }

  getAllVersions() {
    this.jobFileService
      .getAllVersions(this.image.JobFileId)
      .subscribe(
        (allVersions: JobPhotoModel[]) => (this.allVersions = allVersions)
      );
  }

  AddTag() {
    this.addTag = true;
  }

  SaveTag() {
    if (this.tagEntry != "") {
      var jobFileTag: JobFileTag = {
        Id: 0,
        FileTag: this.tagEntry,
        OrgGuid: this.sharedService.selectedOrganization.ID,
        CreatedOn: new Date(),
        IsSystem: false,
        SystemName: "",
        DeletedOn: null,
      };

      var duplicate = this.tags.find((x) => x.FileTag == jobFileTag.FileTag);
      if (duplicate == null) {
        this.jobFileService
          .postJobFileTagByGuid(jobFileTag)
          .subscribe((response) => {
            var jobFileTag: JobFileTag = {
              Id: response.Id,
              FileTag: response.FileTag,
              OrgGuid: response.OrgGuid,
              CreatedOn: response.CreatedOn,
              IsSystem: false,
              SystemName: "",
              DeletedOn: null,
            };
            this.selectedTags.push(jobFileTag);
            this.GetAllTags();

            this.tagEntry = "";
            this.addTag = false;
            this.imageData.Tags = true;
            this.imageData.Comments =
              this.jobFile.JobFileNotes.length > 0 ? true : false;
            this.emitIcons.emit(this.imageData);
          });
      }
    }
  }

  CancelTag() {
    this.tagEntry = "";
    this.addTag = false;
  }

  GetAllTags() {
    this.jobFileService
      .getAllJobFileTagByOrgGuid(this.sharedService.selectedOrganization.ID)
      .subscribe((response) => {
        var list = [];
        response.forEach((tag) => {
          var jobFileTag: JobFileTag = {
            Id: tag.Id,
            FileTag: tag.FileTag,
            OrgGuid: tag.OrgGuid,
            CreatedOn: tag.CreatedOn,
            IsSystem: false,
            SystemName: "",
            DeletedOn: null,
          };
          list.push(jobFileTag);
        });

        this.tags = list;
        this.SortTags(this.tags);
        this.SortTags(this.selectedTags);
      });
  }

  AddComment() {
    let image = this.image;
    if (this.comment.trim().length > 0) {
      let comment: JobFileNote = {
        Guid: Guid.newGuid(),
        Title: "",
        Note: this.comment,
        CreatedBy: this.user,
        Created: new Date(),
        JobFileGuid: image.JobFileGuid,
        DeletedBy: null,
      };

      this.jobFileService.postJobFileNoteByGuid(comment).subscribe(
        (response) => {
          let addComment: JobFileNoteUI = {
            Guid: comment.Guid,
            Title: "",
            Note: this.comment,
            CreatedBy: this.user,
            Created: new Date(),
            JobFileGuid: image.JobFileGuid,
            DeletedBy: null,
            CustomerInitials: this.user.CustomerInitials,
            canEdit: true,
            DateString: this.datePipe.transform(
              comment.Created,
              "EE M-dd-yy * h:mm:ss a"
            ),
          };

          this.lstComments.unshift(addComment);
          this.comment = "";
          this.imageData.Tags =
            this.jobFile.JobFileTags.length > 0 ? true : false;
          this.imageData.Comments = true;
          this.emitIcons.emit(this.imageData);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  EditComment(index) {
    this.editComment = true;
    this.position = index;
  }

  UpdateComment(comment: JobFileNoteUI) {
    if (comment.Note != "") {
      let commentEdit: JobFileNote = {
        Guid: comment.Guid,
        Title: comment.Title,
        Note: comment.Note,
        CreatedBy: comment.CreatedBy,
        Created: new Date(),
        JobFileGuid: comment.JobFileGuid,
        DeletedBy: null,
      };

      comment.Created = new Date();

      this.jobFileService.putJobFileNoteByGuid(commentEdit).subscribe(
        (response) => {
          comment.DateString = this.datePipe.transform(
            new Date(),
            "EE M-dd-yy * h:mm:ss a"
          );
          this.position = -1;
          this.editComment = false;
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  DeleteComment(index, comment: JobFileNoteUI) {
    let commentEdit: JobFileNote = {
      Guid: comment.Guid,
      Title: comment.Title,
      Note: comment.Note,
      CreatedBy: comment.CreatedBy,
      Created: comment.Created,
      JobFileGuid: comment.JobFileGuid,
      DeletedBy: this.user,
    };

    this.jobFileService.putJobFileNoteByGuid(commentEdit).subscribe(
      (response) => {
        this.editComment = false;
        this.position = -1;
        this.lstComments.splice(index, 1);
        this.imageData.Tags =
          this.jobFile.JobFileTags.length > 0 ? true : false;
        this.imageData.Comments =
          this.jobFile.JobFileNotes.length - 1 > 0 ? true : false;
        this.emitIcons.emit(this.imageData);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  SortTags(list) {
    list.sort(function (a, b) {
      if (a.FileTag < b.FileTag) {
        return -1;
      }
      if (a.FileTag > b.FileTag) {
        return 1;
      }
      return 0;
    });
  }

  SortComments(list) {
    list.sort(function (a, b) {
      if (a.Created > b.Created) {
        return -1;
      }
      if (a.Created < b.Created) {
        return 1;
      }
      return 0;
    });
  }

  Save() {
    var file: UpdateJobFile = {
      Title: this.image.Title,
      Description: this.image.Description,
      Public: false,
      Pinned: false,
      FileGuid: this.image.JobFileGuid,
      DisplayCreatedOn: this.image.DisplayDateTime,
      JobFileTags: this.selectedTags,
      CustomerCanView: this.jobFile.CustomerCanView,
      ContractorCanView: this.jobFile.ContractorCanView,
    };
    this.jobFile.Title = this.image.Title;
    this.jobFile.Description = this.image.Description;
    this.jobFile.JobFileTags = this.selectedTags;
    this.jobFileService.putJobFileByGuid(file).subscribe((response) => {
      let retrieved = response;
      this.imageData.Title = this.image.Title;
      this.imageData.Description = this.image.Description;
      this.imageData.Tags = this.jobFile.JobFileTags.length > 0 ? true : false;
      this.imageData.Comments =
        this.jobFile.JobFileNotes.length > 0 ? true : false;
      this.updated.emit(this.imageData);
      this.emitIcons.emit(this.imageData);
      this.close.emit();
    });
  }

  Cancel() {
    this.close.emit();
  }

  getInitals(user: UserModel) {
    let initials = "N/A";
    if (user.firstName && user.firstName.length > 0) {
      initials += user.firstName.charAt(0);
    }

    if (user.lastName && user.lastName.length > 0) {
      initials += user.lastName.charAt(0);
    }

    return initials;
  }

  /** Opens the image viewer to preview */
  openPreview(index: number) {
    this.displayPreview = true;
  }

  /** Closes the image viewer */
  closePreview() {
    this.displayPreview = false;
  }
}

class Guid {
  static newGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
}
