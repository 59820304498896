import { Injectable } from "@angular/core";
import { ImageInfo } from "app/model/ImageInfo";
import { BehaviorSubject, Subject } from "rxjs";

export interface ShowInterface {
  show: boolean;
  imageUrls?: string[] | ImageInfo[];
  currentIndex?: number;
}

@Injectable({
  providedIn: "root",
})
export class PhotoCarouselService {
  private showCarousel$ = new BehaviorSubject<ShowInterface>({ show: false });
  public showCarousel = this.showCarousel$.asObservable();

  constructor() {}

  public show(payload: Omit<ShowInterface, "show">) {
    this.showCarousel$.next({
      show: true,
      ...payload,
    });
  }

  public hide() {
    this.showCarousel$.next({
      show: false,
    });
  }
}
