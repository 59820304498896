<div class="modal-dialog modal-md estimate-theme share-modal-container">
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close" (click)="close()">&times;</button>
      <h4 class="modal-title">Share File</h4>
    </div>
    <div class="modal-body">
      <form>
        <div class="container-fluid">
          <!-- <div class="row">
            <div class="col">
              <div class="multiselect-pentair-std mb-3">
                <label for="text" class="label-title"> Share With </label>
                <kendo-multiselect
                  [data]="filteredRoles"
                  [textField]="'Name'"
                  [valueField]="'Id'"
                  name="CustomerRoles"
                  id="CustomerRoles"
                  [filterable]="true"
                  [(ngModel)]="selectedRoles"
                  #CustomerRoles="ngModel"
                  [ngClass]="{
                    'has-error':
                      CustomerRoles.invalid &&
                      (CustomerRoles.dirty || CustomerRoles.touched)
                  }"
                  (filterChange)="filterChange($event)"
                ></kendo-multiselect>
              </div>
            </div>
          </div> -->
          <div class="row form-container__row--items mb-3">
            <!-- <p>
              <button
                class="btn btn-default"
                type="button"
                data-toggle="collapse"
                data-target="#collapseExample"
              >
                More options
              </button>
            </p> -->
            <!-- <div class="collapse" id="collapseExample"> -->
            <!-- <div>  -->
            <!-- <p class="smaller mb-4 mt-3">
                Additionally, you can send the file to the email addresses or
                phone numbers you specify.
              </p> -->
            <div class="additional-options mb-3 width-100">
              <div class="row">
                <div class="col">
                  <div class="multiselect-pentair-std mb-3">
                    <label for="text" class="label-title">
                      Email Addresses
                    </label>
                    <kendo-multiselect
                      [value]="emailAddresses"
                      [allowCustom]="true"
                      [valuePrimitive]="true"
                      (valueChange)="emailAddresses = $event"
                      [valueNormalizer]="emailNormalizer"
                    >
                      <ng-template kendoMultiSelectNoDataTemplate>
                        Begin Typing
                      </ng-template>
                    </kendo-multiselect>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="multiselect-pentair-std mb-3">
                    <label for="text" class="label-title">
                      Phone Numbers
                    </label>
                    <kendo-multiselect
                      [value]="phoneNumbers"
                      [allowCustom]="true"
                      [valuePrimitive]="true"
                      [valueNormalizer]="phoneNumberNormalizer"
                      (valueChange)="phoneNumbers = $event"
                    >
                      <ng-template kendoMultiSelectNoDataTemplate>
                        Begin Typing
                      </ng-template>
                    </kendo-multiselect>
                  </div>
                </div>
              </div>
            </div>
            <!-- </div> -->
          </div>
        </div>
        <div class="modal-footer d-flex layout-flex-end">
          <button
            type="button"
            class="button button--secondary mr-2"
            (click)="close()"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="button button--primary"
            (click)="share()"
          >
            Share
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
