import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";
import { environmentConstant } from "app/common/utility/environment";
import { Observable } from "rxjs";
import { JobFileModel, JobFileTag } from "app/model/jobFile";
import { JobPhotoPaginationModel } from "app/model/jobPhotoModel";
import { from } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { v4 as uuidv4 } from "uuid";
import { ShareJobFileModel } from "app/model/ShareJobFileModel";

@Injectable()
export class JobFileService {
  constructor(private httpService: HttpService) {}
  getfileId(): Observable<JobFileModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getJobPhotosUrl
    );
  }

  getJobfilesByJobID(
    jobId: any,
    currnetPage: any,
    pageSize: any,
    searchText: string = ""
  ): Observable<JobPhotoPaginationModel> {
    return this.httpService.getRequestService(
      environmentConstant.getJobFilesByJobID +
        jobId +
        "/files?page=" +
        currnetPage +
        "&pageSize=" +
        pageSize +
        "&search=" +
        searchText
    );
  }

  getJobFileByGuid(guid) {
    return this.httpService.getRequestService(
      environmentConstant.jobFile + guid
    );
  }
  putJobFileByGuid(model) {
    return this.httpService.putRequestService(
      environmentConstant.jobFile,
      model
    );
  }
  postNewVersion(fileId: number, newDataUrl: string, originalFileName: string) {
    var promise = fetch(newDataUrl).then((res) => res.blob());
    return from(promise).pipe(
      mergeMap((blob) => {
        var formData = new FormData();
        formData.append(
          "uploadFile",
          blob,
          `annotation${uuidv4()}__${originalFileName}`
        );
        return this.httpService.UploadRequestService(
          environmentConstant.jobFileNewVersion(fileId),
          formData
        );
      })
    );
  }
  getAllVersions(primaryFileId: number) {
    return this.httpService.getRequestService(
      environmentConstant.jobFileAllVersions(primaryFileId)
    );
  }
  shareJobFile(shareJobFile: ShareJobFileModel) {
    return this.httpService.postRequestService(
      environmentConstant.shareJobFile,
      shareJobFile
    );
  }
  deletefilePhoto(id: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.deleteFilePhoto + "file/" + id
    );
  }
  deleteAllJobFiles(jId: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.deleteJobPhoto + jId + "/files"
    );
  }
  deleteAllJobPhaseFiles(phaseId: number) {
    return this.httpService.deleteRequestService(
      environmentConstant.deleteJobPhase + phaseId + "/files"
    );
  }
  getBlob(id: any): any {
    return this.httpService.getBlobRequestService(
      environmentConstant.getBlobModelUrl + id
    );
  }

  //Job File Notes
  getJobFileNoteByGuid(guid) {
    return this.httpService.getRequestService(
      environmentConstant.jobFileNote + guid
    );
  }

  postJobFileNoteByGuid(model) {
    return this.httpService.postRequestService(
      environmentConstant.jobFileNote,
      model
    );
  }

  putJobFileNoteByGuid(model) {
    return this.httpService.putRequestService(
      environmentConstant.jobFileNote,
      model
    );
  }

  deleteJobFileNoteByGuid(guid) {
    return this.httpService.deleteRequestService(
      environmentConstant.jobFileNote + guid
    );
  }

  //Job File Tags
  getAllJobFileTagByOrgGuid(jobFileTag) {
    return this.httpService.getRequestService(
      environmentConstant.jobFileTag + jobFileTag
    );
  }

  // getJobFileTagByGuid(guid, model) {
  //   return this.httpService.postRequestService(
  //     environmentConstant.jobFileTag + guid,
  //     model
  //   );
  // }
  postJobFileTagByGuid(model): Observable<JobFileTag> {
    return this.httpService.postRequestService(
      environmentConstant.jobFileTag,
      model
    );
  }

  putJobFileTagByGuid(model) {
    return this.httpService.putRequestService(
      environmentConstant.jobFileTag,
      model
    );
  }
  deleteJobFileTagByGuid(guid) {
    return this.httpService.deleteRequestService(
      environmentConstant.jobFileTag + guid
    );
  }

  patchJobFileName(
    jobFileId: number,
    jobFileName: string,
    fileTypeId: number
  ): Observable<JobFileModel> {
    return this.httpService.putRequestService(`jobs/fileName`, {
      jobFileId,
      jobFileName,
      fileTypeId,
    });
  }

  patchChangeOrderFileName(changeOrderFileId: number, fileName: string) {
    return this.httpService.putRequestService(`jobs/changerorder/fileName`, {
      changeOrderFileId,
      fileName,
    });
  }
}
