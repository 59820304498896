import { AddressModel } from "app/model/addressModel";
import { Resource } from "app/model/ResourceModel";
import { IMPBCardDataProvider } from "app/modules/MPBGenericUI/CardUI/mpbcard-status/mpbcard-status.component";

export class WorkOrderModelV2 implements IMPBCardDataProvider {
  ID: string;
  WorkOrderName: string;
  WorkOrderNumber: string;
  OrgGuid: string;
  Description: string;
  ParentJobName: string;
  StatusId: number;
  JobAddresId: number;
  Address: AddressModel;
  CustomerId: number;
  IsWarranty: boolean;
  IsPublic: boolean;
  ParentJobId: any;
  JobName: string;
  Phone: number;
  ScheduledDate: Date;
  CompleteDate: Date;
  RemindMeOnDay: Date;
  StartDate: Date;
  Resources: Resource[];
  ResourcesText: string;
  Status: string;
  StatusColor: string;
  // Currently a Status description is not returned by the webservice, but
  // this can be populated by commonDataService display in grid views.
  StatusData: any;

  Subject: string;
  CategoryId: number;
  BusinessUnitId: number;
  TargetDate: Date;
  DaysToComplete: number;

  // CardProviderInterface
  get title() {
    return this.Subject;
  }

  get subtitle() {
    return this.JobName;
  }

  get address() {
    return this.Address;
  }

  constructor() {}
}
