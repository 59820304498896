import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  SimpleChanges,
} from "@angular/core";
import { BaThemeSpinner } from "app/theme/services";
import { MessageService } from "primeng/components/common/messageservice";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { GridDataResult } from "@progress/kendo-angular-grid/dist/es2015";
import { DialogService } from "ng2-bootstrap-modal";
import { ConfirmComponent } from "app/component/dialog/dialog.component";
import { JobFileService } from "app/common/services/jobFile.service";
import { JobFileModel } from "app/model/jobFile";
import { JobNoteService } from "app/common/services/jobNotes.service";
import { JobNoteModel } from "app/model/jobNoteModel";
import { JobPhotoModel } from "app/model/jobPhotoModel";
import { JobPhotoService } from "app/common/services/jobphoto.service";
import { WorkOrderDetailsComponent } from "../WorkOrderDetails/workorderdetails.component";
import { JobPhaseService } from "app/common/services/jobPhase.service";
import { PunchlistDetailsComponent } from "../PunchlistDetails/punchlistdetails.component";
import { JobPhaseDetailsComponent } from "../JobPhaseDetails/jobphasedetails.component";
import { Subscription } from "rxjs";
import {
  CommonSubscription,
  SharedService,
} from "app/common/utility/SharedService";
import { EmailPayload, EnumEmailType } from "app/model/emailMessageModel";
import { EmailMessageService } from "app/common/services/emailmessage.service";
import { JobPhaseChildIds, JobPhasModel } from "app/model/jobPhaseModel";
import { ChecklistService } from "app/common/services/Core/Checklist.service";
import { Checklist } from "app/component/Checklist/Models/Checklist";
import {
  GuidMatcherFactoryService,
  GuidMatcherService,
} from "app/common/utility/guid-matcher.service";
import { JobPhotoCardComponent } from "app/dashboard/job-photo-info-card/job-photo-info.component";
import { JobNotesInfoCardComponent } from "app/dashboard/job-notes-info-card/job-notes-info-card.component";
import { EntityCache } from "app/common/utility/EntityCache";
import { environment } from "environments/environment";
import {
  GeneralReportModel,
  ReportSourceModel,
} from "app/model/GeneralReportModel";
import { MatDialog } from "@angular/material";
import { ReportComponent } from "app/component/report/report.component";
import { ReportsEnum } from "app/model/ReportsEnum";
import { JobfileInfoCardComponent } from "app/dashboard/jobfile-info-card/jobfile-info-card.component";
import { UpdateCountService } from "app/common/utility/updateCount.service";
import { UpdateCountType } from "app/model/Core/JobFileModel";
import { PhaseService } from "app/common/services/phase.service";
import { ToastrService } from "ngx-toastr";
import { NgForm } from "@angular/forms";

@Component({
  selector: "common-container",
  templateUrl: "./commoncontainer.component.html",
  styleUrls: ["./commoncontainer.component.css"],
  providers: [GuidMatcherFactoryService],
})
export class CommonContainerComponent implements OnInit {
  headerText: string = "";
  showSlides: string = "";
  guid: any;
  sort: SortDescriptor[] = [];
  skip: number = 0;
  gridView: GridDataResult;
  pageSize: number = 50;

  photoCount: number = 0;
  noteCount: number = 0;
  fileCount: number = 0;
  checklistCount: number = 0;

  dataIdToEdit: number;
  currentpage: number = 1;
  totalRecords: number = null;
  visibleSidebar: boolean = false;
  lstNotes: JobNoteModel[] = [];
  selectedTab: number = 0;
  lstPhotos: JobPhotoModel[] = [];
  selectAll: boolean = false;
  buttonText: string = "";
  isChecked: boolean = false;
  IsWarranty: boolean = false;
  IsSaveEnable: boolean = false;
  isFormDirty: boolean = false;
  subscription: Subscription;
  selectedFiles: JobFileModel[] = [];
  enableDelete: boolean = false;
  isNew: boolean = true;
  checklist: Checklist;
  items: boolean = false;
  eventsAdded: boolean = false;
  addeditchecklistslide: boolean = false;
  phase: boolean = true;
  reportModel: GeneralReportModel;
  hasBillOfMaterials: boolean = false;
  isPunchlist: boolean = false;
  selectedJob: boolean = false;
  isWorkOrder: boolean = false;
  buttonTextSaveAndExit: string = "";

  @Input() jobGuid: any;
  @Input() JobPhase: any;
  @Input() JobData: any;
  @Input() sideBarClass: any;
  @Input() JobID: any = 0;
  @Input() JobPhaseId: number = 0;
  @Input() IsPhase: boolean = false;
  @Input() CallingComponent: string;
  @Input() phaseName: string;
  @Input() isSales: boolean;
  @Input() IsSlideOut: boolean = false;
  @Input() fromBigBoard: boolean = false;
  @Output() updateEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() incrementSale: EventEmitter<any> = new EventEmitter();
  @Output() updateBPlan: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateFilesCount: EventEmitter<number> = new EventEmitter();
  @ViewChild(WorkOrderDetailsComponent, { static: false })
  childWorkOrder: WorkOrderDetailsComponent;
  @ViewChild(PunchlistDetailsComponent, { static: false })
  childPunchlist: PunchlistDetailsComponent;
  @ViewChild(JobPhaseDetailsComponent, { static: false })
  childJobPhase: JobPhaseDetailsComponent;
  @ViewChild(JobPhotoCardComponent, { static: false })
  childPhotos: JobPhotoCardComponent;
  @ViewChild(JobNotesInfoCardComponent, { static: false })
  childNotes: JobNotesInfoCardComponent;
  @ViewChild(JobfileInfoCardComponent, { static: false })
  childFiles: JobfileInfoCardComponent;
  @ViewChild("myForm", { static: false }) myForm!: NgForm;

  guidMatcherForFiles: GuidMatcherService<JobPhotoModel>;
  guidMatcherForChecklists: GuidMatcherService<Checklist>;
  filesCache = new EntityCache<JobPhotoModel>();
  checklistCache = new EntityCache<Checklist>((c) => c.guid);
  parentWorksheetId: number = 0;
  originalPhaseIdForPhaseChecklists: number = 0;
  phaseChecklists: any[] = [];

  @Output() triggerCallToJumpWhiteboardComponentNotesEvent =
    new EventEmitter<string>();

  get enableChecklists() {
    return !["Work Order", "Punchlist"].includes(this.CallingComponent);
  }

  get lstFiles() {
    return this.filesCache.items();
  }

  get lstChecklists() {
    return this.checklistCache.items();
  }

  get canShowTab() {
    if (this.IsPhase && this.JobPhaseId == 0) return false;

    return true;
  }

  constructor(
    private _spinner: BaThemeSpinner,
    private sharedService: SharedService,
    private notificationsService: MessageService,
    private dialogService: DialogService,
    private jobFileService: JobFileService,
    private jobNoteService: JobNoteService,
    private jobPhotoService: JobPhotoService,
    private jobPhaseService: JobPhaseService,
    private _emailService: EmailMessageService,
    private checklistService: ChecklistService,
    private commonsubs: CommonSubscription,
    private guidMatcherFactory: GuidMatcherFactoryService,
    private matDialogService: MatDialog,
    private countService: UpdateCountService,
    private phaseService: PhaseService,
    private toastrService: ToastrService
  ) {
    this.subscription = this.commonsubs
      .getUpdateAssignResource()
      .subscribe((data) => {
        this.isFormDirty = data;
      });

    this.guidMatcherForFiles = this.guidMatcherFactory.create();
    this.guidMatcherForChecklists = this.guidMatcherFactory.create();
  }
  onWarrantyChange(e: boolean) {
    this.IsWarranty = e;
  }
  onSaveEnable(e: boolean) {
    this.IsSaveEnable = e;
    this.selectedJob = true;
  }

  GetPhaseById() {
    this.jobPhaseService.getJobPhaseById(this.JobPhaseId).subscribe((data) => {
      this.JobPhase = data;
      this.setTabCounts(data);
    });
  }

  setTabCounts(jobPhaseData: JobPhasModel) {
    this.photoCount = jobPhaseData.PhotoCount;
    this.noteCount = jobPhaseData.NoteCount;
    this.fileCount = jobPhaseData.FileCount;
    this.checklistCount = jobPhaseData.CheckListCount;
  }

  setChecklistTabCount(value) {
    this.checklistCount = value;
    this.countService.updateCount({
      entityId: this.JobPhaseId,
      countType: UpdateCountType.Checklists,
      count: value,
    });
  }

  setPhotoTabCount(value: number) {
    this.photoCount = value;
    this.countService.updateCount({
      entityId: this.JobPhaseId,
      countType: UpdateCountType.Photos,
      count: value,
    });
  }

  setNoteTabCount(value: number) {
    this.noteCount = value;
    if (this.IsPhase) {
      this.countService.updateCount({
        entityId: this.JobPhaseId,
        countType: UpdateCountType.Notes,
        count: value,
      });
    }
  }

  setFileNoteCount(value: number) {
    this.fileCount = value;
    // if (this.IsPhase) {
    //   this.countService.updateCount({entityId: this.JobPhaseId, countType: UpdateCountType.Files,  filesCount: value});
    // }
    //this.updateFilesCount.emit(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.JobPhaseId &&
      changes.JobPhaseId.currentValue &&
      !changes.JobPhaseId.firstChange
    ) {
      this.setButtonText();
      this.loadComponentData();
      this.childJobPhase.PhaseId = this.JobPhaseId;
      this.childJobPhase.loadJobPhaseDetailsData();
    }
  }

  ngOnDestroy(): void {
    this.childJobPhase.PhaseId = 0;
    this.JobPhaseId = 0;
  }

  ngOnInit() {
    if (this.childJobPhase) this.childJobPhase.PhaseId = this.JobPhaseId;

    this.loadComponentData();
  }

  get isThisForNewPhase() {
    return this.IsPhase && !this.JobPhaseId;
  }

  firstPass: boolean = true;
  onTabSelected(event) {
    let index;
    if (event && event.index) {
      index = event.index;
    } else {
      index = event;
    }
    switch (index) {
      case 4: {
        this.getChecklists();
        break;
      }
    }
  }

  async getPhotos() {
    this.lstPhotos = await this.childPhotos.getPhotos();
    this.setPhotoTabCount(this.lstPhotos.length);
  }

  onIncrementSale(jobPhase: JobPhasModel) {
    this.incrementSale.emit(jobPhase);
  }

  onAddNewData(id, text) {
    this.dataIdToEdit = id;
    this.showSlides = text;
  }

  async getNotesbyId() {
    this.lstNotes = await this.childNotes.getNotes();
    this.setNoteTabCount(this.lstNotes.length);
    this.loadItems(null, this.lstNotes);
  }

  selectDeselectAllPhotos(isAll) {
    if (isAll) {
      this.lstPhotos.forEach((photo) => {
        photo.selected = this.selectAll;
      });
    } else {
      var res = this.lstPhotos.find((photo) => photo.selected == false);
      if (res) {
        this.selectAll = false;
      } else {
        this.selectAll = true;
      }
    }
    var seleted = this.lstPhotos.filter((x) => x.selected == true);
    if (seleted.length > 0) {
      this.enableDelete = true;
    } else {
      this.enableDelete = false;
    }
  }

  deleteFile(fileID) {
    var fileList = this.lstFiles.find((obj) => obj.ID == fileID);
    const disposable = this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Delete File",
        message:
          "Are you sure you want to delete " + " " + fileList.FileName + "?",
        toShow: false,
        isNotification: false,
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this._spinner.show();
          this.jobFileService.deletefilePhoto(fileID).subscribe(
            (result) => {
              this.result(result, "Deleted Successfully!!", "File");
              this.onTabSelected(1);
              this.fileCount = this.lstFiles.length;
            },
            (error) => this.error(error, "Unable to Delete File")
          );
        }
      });
  }

  deleteNotes(notesID) {
    var notesList = this.lstNotes.find((obj) => obj.ID == notesID);
    const disposable = this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Delete Note",
        message:
          "Are you sure you want to delete " + " " + notesList.NoteTitle + "?",
        toShow: false,
        isNotification: false,
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this._spinner.show();
          this.jobNoteService.deleteJobNote(notesID).subscribe(
            (result) => {
              this.result(result, "Deleted Successfully!!", "Note");
              this.onTabSelected(2);
            },
            (error) => this.error(error, "Unable to Delete Note")
          );
        }
      });
  }

  private error(error: any, message) {
    this.notificationsService.add({
      severity: "warn",
      summary: this.CallingComponent,
      detail: message,
    });
    this._spinner.hide();
  }

  private result(res: any, message, title) {
    if (res !== null) {
      this.notificationsService.add({
        severity: "success",
        summary: title,
        detail: message,
      });
      this._spinner.hide();
    }
  }

  onCancel(event) {
    this.updateEvent.emit(event);
    this.updateBPlan.emit();
    this.triggerCallToJumpWhiteboardComponentNotesEvent.emit(this.JobID);
  }

  private loadItems(items, list): void {
    let lineItem = items || list;
    lineItem = orderBy(lineItem, this.sort);
    if (this.totalRecords) {
      this.gridView = {
        data: list,
        total: this.totalRecords,
      };
    } else {
      this.gridView = {
        data: lineItem.slice(this.skip, this.skip + this.pageSize),
        total: lineItem.length,
      };
    }
    this._spinner.hide();
  }

  getFilesbyJobId() {
    return new Promise((resolve) => {
      if (this.JobPhaseId != 0) {
        this.jobPhaseService
          .getFilesByJobPhaseId(
            this.JobPhaseId,
            this.currentpage,
            this.pageSize
          )
          .subscribe((data) => {
            this.filesCache.clear();
            if (data.items == null) {
              this.loadItems(null, this.lstFiles);
              return;
            }
            this.filesCache.addItems(data.items);
            this.setFileNoteCount(this.lstFiles.length);
            this.totalRecords = data.totalItemCount;

            this.lstFiles.forEach((obj) => {
              obj.Extension = obj.FileName.split(".")[1];
              obj.selected = false;
            });
            this.selectedFiles = [];
            this.loadItems(null, this.lstFiles);
            resolve(this.lstFiles);
            this._spinner.hide();
          });
      } else if (this.JobID && !this.JobPhaseId) {
        this._spinner.show();
        this.jobFileService
          .getJobfilesByJobID(this.JobID, this.currentpage, this.pageSize)
          .subscribe((data) => {
            let items = data.items;
            if (data.items == null) {
              this.loadItems(null, this.lstFiles);
              return;
            }
            if (this.isThisForNewPhase) {
              items = this.guidMatcherForFiles.matchGuids(items);
            } else {
              this.filesCache.clear();
            }

            this.filesCache.addItems(items);
            this.setFileNoteCount(this.lstFiles.length);
            this.totalRecords = this.lstFiles.length;

            this.lstFiles.forEach((obj) => {
              obj.Extension = obj.FileName.split(".")[1];
              obj.selected = false;
            });
            this.selectedFiles = [];
            this.loadItems(null, this.lstFiles);
            resolve(this.lstFiles);
            this._spinner.hide();
          });
      }
    });
  }

  async onNewFiles(guids: string[]) {
    this.showSlides = "";
    this.selectedTab = 1;
    this.guidMatcherForFiles.addGuid(guids);

    //await this.getFilesbyJobId();
    // if (this.IsPhase) {
    //   this.childFiles.getFilesListByPhase();
    // } else {
    //   this.childFiles.getFileslistByJobID();
    // }
    this.childFiles.handleRequest();
    if (this.isThisForNewPhase) {
      const fileIds = this.filesCache.getItems(guids).map((f) => f.ID);
      if (Array.isArray(fileIds) && fileIds.length)
        this.jobPhotoService
          .deleteSelectedJobPhoto(fileIds)
          .subscribe(() => {});
    }
  }

  async onNewChecklist(guid: string) {
    this.guidMatcherForChecklists.addGuid(guid);
    await this.getChecklists();
    if (this.isThisForNewPhase) {
      const checklist = this.checklistCache.get(guid);
      this.checklistService
        .deleteJobChecklist(this.JobID, guid)
        .subscribe(() => {});
    }
  }

  save(form, shouldClose) {
    switch (this.CallingComponent.toLowerCase()) {
      case "work order":
        this._spinner.show();
        this.childWorkOrder.saveWorkOrder(form);
        break;
      case "punchlist":
        this._spinner.show();
        this.childPunchlist.savePunchlist(form);
        break;
      case "job phase":
        const isNewPhase = this.IsPhase && !this.JobPhaseId;
        let childIds: JobPhaseChildIds;
        if (isNewPhase) {
          childIds = {
            ChecklistGuids: this.lstChecklists.map((c) => c.guid),
            JobFileIds: this.lstFiles.map((f) => f.ID),
            JobPhotoIds: this.lstPhotos.map((p) => p.ID),
            JobNoteIds: this.lstNotes.map((n) => n.ID),
          };
        }
        this.childJobPhase.onSubmit(form, childIds, shouldClose);
        this.myForm.form.markAsPristine();
        this.myForm.form.markAsUntouched();
        this.isFormDirty = false;
        break;
      default:
        break;
    }
  }

  getID() {
    let number: string = "";
    switch (this.CallingComponent.toLowerCase()) {
      case "work order":
        //number = ' - WO' ;
        break;
      case "punchlist":
        number = " - PL" + this.JobPhaseId;
        break;
      case "job phase":
        number = " - JP" + this.JobPhaseId;
        break;
      default:
        break;
    }
    return number;
  }
  updateJobID(e) {
    this.JobID = e;
  }
  updateJobNumber(e) {
    this.headerText = "EDIT " + this.CallingComponent + " - WO" + e;
  }

  updatePhaseId(e) {
    this.JobPhaseId = e;
  }

  onPhaseChange(e) {
    if (this.isThisForNewPhase) {
      this.originalPhaseIdForPhaseChecklists = e;
      if (this.originalPhaseIdForPhaseChecklists) {
        this.phaseService
          .getPhaseChecklists(this.originalPhaseIdForPhaseChecklists)
          .subscribe((res) => {
            const checklists = res.object;

            checklists.forEach(async (item: Checklist) => {
              try {
                const res = await this.checklistService
                  .postJobChecklists(this.JobID, item)
                  .toPromise();
              } catch (error) {
                console.log("updatePhaseId", error);
              }
            });
            this.getChecklists();
          });
      } else {
        this.getChecklists();
      }
    }
  }

  updateBuildPlan(event) {
    this.updateBPlan.emit(event);
  }

  selectFiles(event, file: JobFileModel) {
    if (event.target.checked) {
      this.selectedFiles.push(file);
      file.selected = true;
    } else {
      var index = this.selectedFiles.findIndex((item) => item.ID == file.ID);
      this.selectedFiles.splice(index, 1);
      file.selected = false;
    }
  }
  selectAllFiles() {
    var that = this;
    this.lstFiles.forEach((file) => {
      file.selected = that.selectAll;
      if (file.selected) {
        that.selectedFiles.push(file);
      } else {
        var index = that.selectedFiles.findIndex((item) => item.ID == file.ID);
        that.selectedFiles.splice(index, 1);
      }
    });
    if (!this.selectAll) this.selectedFiles = [];

    this.loadItems(null, this.lstFiles);
  }
  checkIfDataTodelete() {
    if (this.selectedFiles && this.selectedFiles.length > 0) {
      return true;
    }
    return false;
  }
  deleteJobFiles() {
    if (this.selectedFiles && this.selectedFiles.length > 0) {
      const disposable = this.dialogService
        .addDialog(ConfirmComponent, {
          title: "Delete Files",
          message:
            "Are you sure you want to delete " +
            this.selectedFiles.length +
            (this.selectedFiles.length == 1 ? " File?" : " Files?"),
          toShow: false,
          isNotification: false,
        })
        .subscribe((isConfirmed) => {
          if (isConfirmed) {
            this._spinner.show();
            let intArr = [];
            this.selectedFiles.filter((item) => {
              intArr.push(item.ID);
            });
            this.jobPhotoService
              .deleteSelectedJobPhoto(intArr)
              .subscribe((res) => {
                this.result(res, "Deleted Successfully!!", "Files");
                this.filesCache.deleteItems(
                  this.selectedFiles.map((f) => f.Guid)
                );
                this.getFilesbyJobId();
                this.fileCount = this.lstFiles.length;
                this._spinner.hide();
              });
          }
        });
    } else {
      this.notificationsService.add({
        severity: "warn",
        summary: "Photos",
        detail: "Please select photo to delete.",
      });
    }
  }
  deleteAll() {
    const disposable = this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Delete All Files",
        message: this.IsPhase
          ? "Are you sure you want to delete all files of this phase?"
          : "Are you sure you want to delete all files of this job?",
        toShow: false,
        isNotification: false,
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this._spinner.show();
          if (this.IsPhase) {
            this.jobFileService
              .deleteAllJobPhaseFiles(this.JobPhaseId)
              .subscribe((res) => {
                this.result(res, "Deleted Successfully!!", "Files");
                this.filesCache.clear();
                this.getFilesbyJobId();
                this.setFileNoteCount(0);
                this.totalRecords = 0;
                this.fileCount = 0;
                this._spinner.hide();
              });
          } else {
            this.jobFileService
              .deleteAllJobFiles(this.JobID)
              .subscribe((res) => {
                this.result(res, "Deleted Successfully!!", "Files");
                this.filesCache.clear();
                this.getFilesbyJobId();
                this.fileCount = 0;
                this._spinner.hide();
              });
          }
        }
      });
  }
  sendWorkOrderEmail() {
    if (this.childWorkOrder.lstAssignedResource.length > 0) {
      const disposable = this.dialogService
        .addDialog(ConfirmComponent, {
          title: "Send Email",
          message: "Are you sure you want to send Work Order Email?",
          toShow: false,
          isNotification: false,
        })
        .subscribe((isConfirmed) => {
          if (isConfirmed) {
            this._spinner.show();
            var payload = new EmailPayload();
            payload.EmailType = EnumEmailType.WorkOrderSendToContractor;
            payload.JobId = this.JobID;
            this._emailService.sendEmailMessage(payload).subscribe(
              (data) => {
                this.result(data, "Sent Successfully!!", "Email");
                this._spinner.hide();
              },
              (error) => this.error(error, "Unable to Send Email")
            );
          }
        });
    } else {
      this.toastrService.error("Work order is not assigned !", "Work Order");
    }
  }

  getChecklists() {
    return new Promise((resolve) => {
      if (this.JobID && !this.JobPhaseId) {
        this.checklistService.getJobChecklists(this.JobID).subscribe((data) => {
          let list = data.object;
          if (this.isThisForNewPhase) {
            list = this.guidMatcherForChecklists.matchGuids(
              list,
              (c) => c.guid
            );
          } else {
            this.checklistCache.clear();
          }
          this.checklistCache.addItems(list);
          this.setChecklistTabCount(data.object.length);

          this.gridView = {
            data: this.lstChecklists,
            total: this.lstChecklists.length,
          };
          resolve(this.lstChecklists);
        });
      } else if (this.JobPhaseId) {
        if (this.JobPhaseId != 0) {
          this.checklistService
            .getJobPhaseChecklists(this.JobPhase.Guid)
            .subscribe((data) => {
              this.checklistCache.clear();
              this.checklistCache.addItems(data.object);
              this.setChecklistTabCount(data.object.length);

              this.gridView = {
                data: data.object,
                total: data.object.length,
              };
            });
          resolve(this.lstChecklists);
        }
      }
    });
  }

  editChecklist(dataItem, text) {
    this.checklist = dataItem;
    this.showSlides = text;
    this.visibleSidebar = true;
    this.items = true;
  }

  deleteChecklist(dataItem) {
    const disposable = this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Delete Checklist",
        message: "Are you sure you want to delete this checklist?",
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          if (this.JobPhase) {
            this.checklistService
              .deleteJobPhaseChecklist(this.JobPhase.Guid, dataItem.guid)
              .subscribe(
                (result) => {
                  this.result(
                    result,
                    "Checklist deleted successfully",
                    "Checklist"
                  );
                  this.checklistCache.delete(dataItem.guid);
                  this.getChecklists();
                },
                (error) => {
                  this.error(error, "Unable to delete checklist");
                }
              );
          } else {
            this.checklistService
              .deleteJobChecklist(this.JobID, dataItem.guid)
              .subscribe(
                (result) => {
                  this.result(
                    result,
                    "Checklist deleted successfully",
                    "Checklist"
                  );
                  this.checklistCache.delete(dataItem.guid);
                  this.getChecklists();
                },
                (error) => {
                  this.error(error, "Unable to delete checklist");
                }
              );
          }
        }
      });
  }

  showTab(index) {
    this.showSlides = "";
    this.selectedTab = index;
    this.onTabSelected(index);
  }

  onHideSliderHide(e) {
    this.JobPhaseId = 0;
    this.eventsAdded = false;
    this.visibleSidebar = false;
    //this.sharedService.selectedJob = undefined;
  }

  closeChecklist() {
    this.visibleSidebar = false;
    this.addeditchecklistslide = false;
    this.showSlides = "";
  }
  refreshPage() {
    this.visibleSidebar = false;
    this.addeditchecklistslide = false;
    this.ngOnInit();
  }

  PrintCheckList(item) {
    let reportName = item.jobPhase
      ? ReportsEnum.JobPhaseChecklistReport
      : ReportsEnum.ChecklistReport;
    this.reportModel = new GeneralReportModel();
    let reportSource = new ReportSourceModel();
    reportSource.report = `NAME/${environment.reportDataSource}/${reportName}`;
    reportSource.parameters = {
      env: environment.reportServer,
      CheckListGuid: item.guid,
    };
    this.Print(reportSource);
  }

  PrintWorkOrder() {
    let isWorkOrder: boolean = true;
    let reportSource = new ReportSourceModel();
    if (this.CallingComponent.toLowerCase() === "job phase")
      reportSource.report = `NAME/${environment.reportDataSource}/${ReportsEnum.JobPhaseWorkOrder}`;
    else
      reportSource.report = `NAME/${environment.reportDataSource}/${ReportsEnum.WokOrder}`;

    if (this.CallingComponent.toLowerCase() === "punchlist") {
      isWorkOrder = false;
    }

    reportSource.parameters = {
      env: environment.reportServer,
      JobId: this.JobID,
      JobPhaseId: this.JobPhaseId,
      OrgGuid: this.sharedService.selectedOrganization.ID,
      IsWorkOrder: isWorkOrder,
    };
    this.Print(reportSource);
  }

  Print(reportSource: ReportSourceModel) {
    this.reportModel = new GeneralReportModel();
    this.reportModel.reportSource = reportSource;
    this.matDialogService.open(ReportComponent, {
      width: "800px",
      height: "500px",
      panelClass: "dialog-report",
      data: this.reportModel,
    });
  }

  setParentWorksheetId(id: number) {
    this.parentWorksheetId = id;
    this.hasBillOfMaterials = !this.isSales; // && !!id;
  }

  /**
   * Deletes job phase
   * @param jobPhaseId job phase id
   */
  deleteJobPhase(jobPhaseId): void {
    const disposable = this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Delete Phase",
        message:
          "Are you sure you want to delete " + this.JobPhase.PhaseName + "?",
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this._spinner.show();
          this.jobPhaseService.deleteJobPhase(jobPhaseId).subscribe((x) => {
            this.updateEvent.emit(true);
            this.updateBPlan.emit();
            this.triggerCallToJumpWhiteboardComponentNotesEvent.emit(
              this.JobID
            );
          });
        }
      });
  }

  setButtonText() {
    if (this.JobPhaseId == 0) {
      this.headerText = "ADD " + this.CallingComponent;
      this.buttonText = "Save";
      this.buttonTextSaveAndExit = "Save & Close";
      if (this.JobID != 0) this.selectedJob = true;
    } else {
      this.headerText = "EDIT " + this.CallingComponent + this.getID();
      this.buttonText = "Update";
      this.buttonTextSaveAndExit = "Update & Close";
      this.selectedJob = true;
      this.isNew = false;
    }
  }

  loadComponentData() {
    if (this.CallingComponent.toLowerCase() == "work order")
      this.isWorkOrder = true;
    this.isPunchlist = this.CallingComponent.toLowerCase() === "punchlist";
    if (this.JobPhaseId != null && this.JobPhaseId != 0) this.GetPhaseById();
    this.guid = this.JobData
      ? this.JobData.Guid
      : this.JobPhase
      ? this.JobPhase.Guid
      : this.JobID;
    this._spinner.show();
    if (
      ["job phase", "punchlist"].includes(
        this.CallingComponent.toLocaleLowerCase()
      )
    ) {
      this.IsPhase = true; //determine if a job phase or (punchlist/ workorder) job
      this.IsSaveEnable = true;
      this.setButtonText();
    } else if (this.JobID == 0 && this.JobPhaseId == 0) {
      this.IsPhase = false;
      this.headerText = "ADD " + this.CallingComponent;
      this.buttonText = "Save";
    } else {
      this.IsPhase = false;
      this.headerText = "EDIT " + this.CallingComponent + this.getID();
      this.buttonText = "Update";
      this.selectedJob = true;
      this.isNew = false;
    }
    if (!this.sideBarClass) {
      this.sideBarClass = "sidebar2";
    }
    if (this.isSales == null) this.isSales = false;
  }
}
