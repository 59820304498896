import { Component, Inject, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material";
import { DropdownService } from "app/common/services/dropdown.service";
import { SignNowService } from "app/common/services/signNow.service";
import { EstimateCustomerModel } from "app/model/EstimateCustomerModel";
import {
  SignNowSignersRequest,
  SignNowTemplateSigners,
} from "app/model/SignNowModels";
import { BaThemeSpinner } from "app/theme";
import { EstimateWorksheetService } from "app/common/services/estimate-worksheet.service";
import { SettingService } from "app/common/services/settings.service";
import { SharedService } from "app/common/utility/SharedService";

@Component({
  selector: "signers-modal",
  templateUrl: "signers-modal.component.html",
  styleUrls: ["signers-modal.component.scss"],
})
export class SignersModalComponent implements OnInit {
  form: FormGroup;
  shouldReturnSalesPersons: boolean = true;
  estimateWorksheetGuid: string;
  allSignersList: EstimateCustomerModel[] = [];
  signersList: EstimateCustomerModel[] = [];
  signersFromTemplate: SignNowTemplateSigners[] = [];
  submitted: boolean = false;
  selectedSignersMap: { [key: number]: any } = {};
  signNowSignersPayload: SignNowSignersRequest;
  salesPersonDisableKey = "SALES_BOARD_DISABLE_SALESMAN_FILTER";
  disableSalespersonFilter: boolean = false;
  signersLabelArray: string[] = [];

  constructor(
    private signNowService: SignNowService,
    private _spinner: BaThemeSpinner,
    private dropdownService: DropdownService,
    private matDialogService: MatDialog,
    private dialogRef: MatDialogRef<SignersModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      guid: string;
    },
    private fb: FormBuilder,
    private estimateWorksheetService: EstimateWorksheetService,
    private _sharedService: SharedService,
    private settingService: SettingService
  ) {
    this.estimateWorksheetGuid = data.guid;
    this.form = this.fb.group({
      signers: this.fb.array([]),
    });
  }

  get signersArray(): FormArray {
    return this.form.get("signers") as FormArray;
  }

  ngOnInit() {
    this.getSigners();
  }

  getSigners() {
    this.disableSalespersonFilter = this.getBooleanSetting(
      this.salesPersonDisableKey,
      false
    );
    this.shouldReturnSalesPersons = !this.disableSalespersonFilter;
    this._spinner.show();
    this.signNowService
      .getSignersByEstimateWorksheetGuid(this.estimateWorksheetGuid)
      .subscribe((response) => {
        if (response.length > 0) {
          this.signersFromTemplate = response;
          response[0].signers.forEach((signer) => {
            const signerControl = this.fb.control(null, Validators.required);
            this.signersLabelArray.push(signer.name);
            this.signersArray.push(signerControl);
          });
          this.getDropDownData();
          return;
        } else this._spinner.hide();
      });
  }

  getDropDownData() {
    this.dropdownService
      .getCustomersSignersDropdown(
        this.estimateWorksheetGuid,
        this.shouldReturnSalesPersons
      )
      .subscribe((response) => {
        this.sortSignersData(response);

        response.forEach((emp, index) => {
          emp.fullName = `${emp.firstName} ${emp.lastName}`;
          if (this.signersArray.length === 1 && emp.isCustomer)
            this.signersArray.controls[0].setValue(emp.id);

          emp.index = index;
        });
        this.allSignersList = response;
        this.signersList = response;
        this._spinner.hide();
      });
  }

  filterDropDownSigners(text: string) {
    this.signersList = this.allSignersList.filter((signer) => {
      return signer.fullName.toLowerCase().includes(text.toLowerCase());
    });
  }

  dropDownSignerChange(event: any, index: number) {
    const currentSelectedSigner = this.selectedSignersMap[index];

    if (
      currentSelectedSigner &&
      event &&
      currentSelectedSigner.id !== event.id
    ) {
      const oldSigner = this.allSignersList.find(
        (item) => item.id === currentSelectedSigner.id
      );

      if (oldSigner) {
        this.signersList.push(oldSigner);
      }

      this.signersList = this.signersList.filter(
        (item) => item.id !== event.id
      );
      this.selectedSignersMap[index] = event;
    } else if (event) {
      const selectedSigner = this.signersList.find(
        (item) => item.id === event.id
      );
      if (selectedSigner) {
        this.selectedSignersMap[index] = selectedSigner;
        this.signersList = this.signersList.filter(
          (item) => item.id !== event.id
        );
      }
    } else {
      if (currentSelectedSigner) {
        const signerToReadd = this.allSignersList.find(
          (item) => item.id === currentSelectedSigner.id
        );

        if (signerToReadd) {
          this.signersList.push(signerToReadd);
        }

        delete this.selectedSignersMap[index];
      }
    }

    this.sortSignersData(this.signersList);
  }

  submitForm() {
    if (this.form.invalid) return;
    this.signNowSignersPayload = {
      worksheetId: "",
      signers: [],
    };
    this.signNowSignersPayload.worksheetId = this.estimateWorksheetGuid;
    const signers = this.form.controls.signers as FormArray;
    signers.controls.forEach((control, index) => {
      const signer = this.allSignersList.find(
        (item) => item.id === control.value
      );
      if (signer) {
        this.signNowSignersPayload.signers.push({
          signingOrder: index + 1,
          displayName: signer.fullName,
          emailAddress: signer.email,
        });
      }
    });
    this._spinner.show();
    this.estimateWorksheetService
      .PostSignNowSigners(this.signNowSignersPayload)
      .subscribe((response) => {
        this._spinner.hide();
        this.closeModal(true);
      });
  }

  closeModal(isCompleted: boolean) {
    this.dialogRef.close(isCompleted);
  }

  sortSignersData(signersList: EstimateCustomerModel[]) {
    signersList.sort((a, b) => {
      const priorityA = (a.isCustomer ? 2 : 0) + (a.isOwner ? 1 : 0);
      const priorityB = (b.isCustomer ? 2 : 0) + (b.isOwner ? 1 : 0);

      return priorityB - priorityA;
    });
  }

  /**
   * get boolean value for the given key from organization settings
   * @param key the key to get the value for
   * @param defaultValue default velue to return if the key is not found
   * @returns boolean value for the given key
   */
  getBooleanSetting(key: string, defaultValue: boolean): boolean {
    return this.settingService.getBooleanValue(
      this._sharedService.organizationSettings,
      key,
      defaultValue
    );
  }
}
