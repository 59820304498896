<div class="todo-page">
  <div class="todolisting-blk">
    <div class="project-header" *ngIf="!JobId">
      <h3>To Do List</h3>
    </div>
    <ng-template #myTodos>
      <div class="addtodolist">
        <h3 (click)="AddTodoList()">
          <i class="fa fa-plus-circle"></i> Add advanced
        </h3>
      </div>
      <form #myForm="ngForm">
        <div class="addtask-searchblk">
          <input
            type="text"
            name="TaskName"
            [(ngModel)]="taskName"
            #TaskName="ngModel"
            [ngClass]="{
              'has-error':
                TaskName.invalid && (TaskName.dirty || TaskName.touched)
            }"
            maxlength="150"
            placeholder="Add new task"
            required
          /><button
            type="button"
            (click)="quickToDo(myForm)"
            [disabled]="!myForm.valid"
          >
            + Add Task
          </button>
        </div>
      </form>
      <todo-items
        [items]="lstToDo"
        (onEditPhase)="EditPhase($event)"
        (onChangeStatus)="ChangeStatus($event)"
        (onDelete)="deletePhase($event)"
      ></todo-items>
    </ng-template>
    <ng-template #phases>
      <todo-items
        [items]="lstPhases"
        (onEditPhase)="EditPhase($event)"
        (onChangeStatus)="ChangeStatus($event)"
        (onDelete)="deletePhase($event)"
      ></todo-items>
    </ng-template>
    <ng-container *ngIf="JobId">
      <ng-container *ngTemplateOutlet="myTodos"></ng-container>
    </ng-container>
    <kendo-tabstrip *ngIf="!JobId">
      <kendo-tabstrip-tab title="My To Do's" [selected]="true">
        <ng-template kendoTabContent>
          <ng-container *ngTemplateOutlet="myTodos"></ng-container>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="My Phases">
        <ng-template kendoTabContent>
          <ng-container *ngTemplateOutlet="phases"></ng-container>
        </ng-template>
      </kendo-tabstrip-tab>
      <kendo-tabstrip-tab title="Others" *ngIf="lstToDoOther.length > 0">
        <ng-template kendoTabContent>
          <todo-items
            [items]="lstToDoOther"
            (onEditPhase)="EditPhase($event)"
            (onChangeStatus)="ChangeStatus($event)"
            (onDelete)="deletePhase($event)"
          ></todo-items>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
</div>
