export enum UserRoleEnum {
  Executive = 0,
  SuperAdmin = 1,
  Admin = 2,
  SubContractor = 3,
  SalesManager = 4,
  SalesRep = 5,
  Customer = 6,
  Scheduler = 7,
  ProjectManager = 8,
  Superintendent = 9,
  Camera = 10,
  Contacts = 11,
  SalesTeamWithAdmin = 12,
  Opportunity = 13,
  CustomerwithOpportunity = 14,
  PhaseOwner = 15,
  CameraOfflineNotification = 16,
  Accounting = 17,
  Chat = 18,
  SerivceManager = 73,
  ServiceTech = 99,
  SalesContact = 110,
  SalesTeamOnly = 120,
  CustomerService = 123,
  MultiOrg = 124,
  ShareEstimates = 130,
  ViewFinancialData = 131,
  SpectrumExport = 132,
}
