import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { TextBoxModule } from "@progress/kendo-angular-inputs";
import { CommonModule } from "@angular/common";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { CurrencyMaskModule } from "ng2-currency-mask";
import {
  DataGridModule,
  EditorModule,
  InputTextModule,
  SidebarModule,
  PanelModule,
  InputMaskModule,
} from "primeng/primeng";
import { AppTranslationModule } from "app/app.translation.module";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { GridModule, ExcelModule } from "@progress/kendo-angular-grid";
import { DragulaModule } from "ng2-dragula";
import { Routes, RouterModule } from "@angular/router";
import { JobFileService } from "app/common/services/jobFile.service";
import { JobPhotoService } from "app/common/services/jobphoto.service";
import { JobNoteService } from "app/common/services/jobNotes.service";
import { ArchwizardModule } from "angular-archwizard";
import { ColorPickerModule } from "ngx-color-picker";
import { CommonService } from "app/common/services/common.service";
import { CommonDataService } from "app/common/services/commonData.service";
import { CommonComponentModule } from "../commoncomponent.module";
import { PhotoCarouselModule } from "../photo-carousel/photo-carousel.module";
import { WorkOrderDetailsComponent } from "./WorkOrderDetails/workorderdetails.component";
import { CommonContainerComponent } from "./commonContainer/commoncontainer.component";
import { AddLineItemComponent } from "./WorkOrderDetails/AddLineItem/AddLineItem.component";
import { PunchlistDetailsComponent } from "./PunchlistDetails/punchlistdetails.component";
import { AssignResourceModule } from "../AssignResource/AssignResource.module";
import { AddEditBuildPlanModule } from "../buildPlan/addEditBuildPlan/addEditbuildPlan.module";
import { JobPhaseDetailsComponent } from "./JobPhaseDetails/jobphasedetails.component";
import { BuilPlanDialogModule } from "../buildPlan-dialog/buildPlan-dialog.module";
import { JobTemplateDialogModule } from "../jobTemplate-dialog/jobTemplate-dialog.module";
import { CreateWorkOrderComponent } from "./WorkOrderDetails/CreateWorkOrder/CreateWorkOrder.component";
import { WorkOrderLineItemComponent } from "./WorkOrderDetails/workOrderLineItem/workOrderLineItem.component";
import { CommonDirectiveModule } from "app/common/directives/commondirective.module";
import { TodoModule } from "app/pages/account/todo/todo.module";
import { BillOfMaterialsGridComponent } from "./bill-of-materials-grid/bill-of-materials-grid.component";
import { RequestForServiceComponent } from "../request-for-service/request-for-service.component";
import { JobPhaseSchdeuleingComponent } from "../jobPhaseSchduleing/jobPhaseScheduleing";
import { LabelModule } from "@progress/kendo-angular-label";
import { RequiredLabelDirective } from "app/common/directives/required-label.directive";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DropDownsModule,
    TextBoxModule,
    DateInputsModule,
    CurrencyMaskModule,
    DataGridModule,
    AppTranslationModule,
    LayoutModule,
    GridModule,
    ExcelModule,
    InputTextModule,
    CommonComponentModule,
    DragulaModule,
    SidebarModule,
    RouterModule,
    PanelModule,
    PhotoCarouselModule,
    InputMaskModule,
    ArchwizardModule,
    ColorPickerModule,
    EditorModule,
    AssignResourceModule,
    AddEditBuildPlanModule,
    BuilPlanDialogModule,
    JobTemplateDialogModule,
    CommonDirectiveModule,
    TodoModule,
    LabelModule,
  ],
  declarations: [
    WorkOrderDetailsComponent,
    CommonContainerComponent,
    AddLineItemComponent,
    PunchlistDetailsComponent,
    JobPhaseDetailsComponent,
    CreateWorkOrderComponent,
    WorkOrderLineItemComponent,
    BillOfMaterialsGridComponent,
    RequestForServiceComponent,
    JobPhaseSchdeuleingComponent,
    RequiredLabelDirective,
  ],
  exports: [
    CommonContainerComponent,
    AddLineItemComponent,
    CreateWorkOrderComponent,
    WorkOrderLineItemComponent,
    JobPhaseDetailsComponent,
    JobPhaseSchdeuleingComponent,
  ],
  providers: [
    JobFileService,
    JobPhotoService,
    JobNoteService,
    CommonService,
    CommonDataService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ContainerComponentModule {}
