export interface ChatPanelContextModel<TObject> {
  object: TObject;
  context: ChatPanelContextEnum;
}

export enum ChatPanelContextEnum {
  None = 0,
  AddGroup = 1,
  EditGroup = 2,
}
