<!-- Full header -->
<section
  *ngIf="showFullHeader"
  class="headingwrap"
  [ngClass]="{
    'p-3 header--view__slideout': !dataFromUrl,
    'header--view__fullscreen': dataFromUrl
  }"
>
  <div class="editjobtopbtn">
    <h1 style="margin-left: 10px">FILES</h1>
  </div>
</section>

<!-- Tools -->
<div [ngClass]="dataFromUrl ? 'd-flex' : null" #divOne>
  <section
    class="wip-files-tab row section-small"
    [ngClass]="{
      'p-3': showFullHeader && !dataFromUrl,
      'tool--view__fullscreen': dataFromUrl
    }"
    *ngIf="!dataFromUrl"
  >
    <div class="row w-100">
      <div
        class="col-12 d-flex clear-padding-right clear-padding-left clear-margin-top-5"
      >
        <div class="jsearch shpn search-format">
          <input
            #input
            class="form-control"
            placeholder="Search"
            type="text"
            [(ngModel)]="searchText"
            (ngModelChange)="searchTextChange()"
            autofocus
          />
          <i aria-hidden="true" class="fa fa-search"></i>
        </div>
      </div>
    </div>
    <div class="row w-100 div-row-small">
      <div class="col-1 d-flex" *ngIf="files.length">
        <label
          class="checkbox-inline nowrap fl-l mr3 align-items-end chk-small"
        >
          <input
            type="checkbox"
            (change)="selectDeselectAllPhotos(true)"
            name="selectAll"
            [(ngModel)]="selectAll"
          />
        </label>
      </div>
      <div
        *ngIf="isVendor && files.length"
        class="addbtn flnone col-1 mt-0 d-flex align-items-end"
        [ngClass]="{ 'opacity-delete': opacityIcon }"
        (click)="deleteVendorFile()"
        title="Delete"
      >
        <a><i class="fa fa-trash-o"></i></a>
      </div>
      <div
        class="addbtn flnone col-2 mt-0 clear-padding-right"
        (click)="handleSidebar()"
        title="Upload Files"
      >
        <a
          class="btn-margin-20 btn-add-small"
          [ngClass]="files.length ? 'padding-0' : 'padding-2'"
        >
          <i class="fa fa-plus-circle"></i> Add</a
        >
      </div>
      <div
        class="download-all-btn d-flex justify-content-end delete-button-padding container-btn-small"
        [ngClass]="files.length ? 'col-9' : 'col-10'"
      >
        <button
          class="download"
          type="button"
          (click)="downloadJobFiles()"
          style="height: 25px"
          *ngIf="!isVendor && files.length"
        >
          <i class="fa fa-download" style="margin-right: 1px; font-size: 11px">
          </i>
          Download
        </button>
        <button
          class="delete btn-delete-small"
          type="button"
          (click)="deleteJobFiles()"
          *ngIf="!isVendor && files.length"
        >
          Delete Selected Files
        </button>
        <button
          class="darkbtn clear-all-btn clear-margin-top-5 btn-clear-small"
          type="button"
          (click)="clearFilter()"
        >
          <i
            aria-hidden="true"
            class="fa fa-filter"
            style="margin-right: 1px"
          ></i>
          Clear
        </button>
      </div>

      <div
        class="del-all-btn col-6 d-flex justify-content-end"
        *ngIf="isVendor && files.length"
      >
        <button type="button" (click)="deleteAllVendorFile()">
          Delete All
        </button>
      </div>
    </div>
  </section>
  <section
    class="wip-files-tab section-full"
    [ngClass]="{
      'p-3': showFullHeader && !dataFromUrl,
      'tool--view__fullscreen': dataFromUrl
    }"
    *ngIf="dataFromUrl"
  >
    <span class="addtxt col-1" *ngIf="isVendor">
      <b>FILES</b>
    </span>
    <div class="row w-100" style="flex: 1">
      <div class="col-5 d-flex">
        <label
          class="checkbox-inline nowrap fl-l mr3 d-flex align-items-end chk-full"
          *ngIf="files.length"
        >
          <input
            type="checkbox"
            (change)="selectDeselectAllPhotos(true)"
            name="selectAll"
            [(ngModel)]="selectAll"
          />
        </label>
        <div
          *ngIf="isVendor && files.length"
          class="addbtn flnone mt-0 d-flex align-items-end"
          [ngClass]="{ 'opacity-delete': opacityIcon }"
          (click)="deleteVendorFile()"
          title="Delete"
        >
          <a><i class="fa fa-trash-o"></i></a>
        </div>
        <div
          class="addbtn flnone mt-0 clear-padding-right d-flex"
          (click)="handleSidebar()"
          title="Upload Files"
          style="flex: 0 0 60px"
        >
          <a class="btn-margin-26" style="font-size: 13px"
            ><i class="fa fa-plus-circle"></i> Add</a
          >
        </div>
        <button
          class="download btn-download-full"
          type="button"
          (click)="downloadJobFiles()"
          *ngIf="!isVendor && files.length"
        >
          <i class="fa fa-download" style="margin-right: 1px; margin-top: 3px">
          </i>
          Download
        </button>
        <button
          class="delete btn-delete-full"
          type="button"
          (click)="deleteJobFiles()"
          *ngIf="!isVendor && files.length"
          style="padding-top: 3px"
        >
          Delete Selected Files
        </button>
        <button
          type="button"
          (click)="deleteAllVendorFile()"
          *ngIf="isVendor && files.length"
        >
          Delete All
        </button>
        <div class="div-clear-full">
          <button
            class="darkbtn clear-all-btn clear-margin-top-5 d-flex btn-clear-full"
            type="button"
            (click)="clearFilter()"
          >
            <i
              aria-hidden="true"
              class="fa fa-filter"
              style="margin-right: 3px; margin-bottom: 2px"
            ></i>
            Clear
          </button>
        </div>
      </div>
      <div class="col-7 d-flex div-search-full">
        <div
          *ngIf="!isVendor && JobId"
          class="d-flex clear-padding-right clear-padding-left clear-margin-top-5 div-ending div-innersearch-full"
        >
          <div class="jsearch shpn search-format w-50 container-search-full">
            <input
              #input
              class="form-control"
              placeholder="Search"
              type="text"
              [(ngModel)]="searchText"
              (ngModelChange)="searchTextChange()"
              autofocus
            />
            <i aria-hidden="true" class="fa fa-search"></i>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- Grid -->
<kendo-grid
  [data]="gridData"
  [sortable]="true"
  [filterable]="isVendor ? 'false' : 'menu'"
  #grid="kendoGrid"
  [pageable]="includePagination"
  [pageSize]="state.take"
  [skip]="state.skip"
  [hideHeader]="hideGridHeader"
  [ngStyle]="gridStyle"
  (edit)="handleEdit($event)"
  (cancel)="cancelHandler($event)"
  (save)="saveHandler($event)"
  (remove)="deleteJobFile($event)"
  [filter]="state.filter"
  [sort]="state.sort"
  (dataStateChange)="handleFilter($event)"
>
  <kendo-grid-column
    *ngIf="!isVendor"
    [style]="{
      'background-color': 'inherit',
      color: '#666666',
      padding: '0px'
    }"
    [headerStyle]="{
      'font-size': '13px',
      padding: '8px 6px !important',
      color: '#666666',
      'background-color': '#ffffff'
    }"
    [width]="isediting ? 75 : 30"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="acButton">
        <button
          kendoGridSaveCommand
          [disabled]="formGroup?.invalid"
          class="files--action-button__transparent"
        >
          <span class="updatebtn">
            <span title="Save">
              <i class="fa fa-floppy-o" aria-hidden="true"></i>
            </span>
          </span>
        </button>
        <button
          kendoGridCancelCommand
          class="files--action-button__transparent"
        >
          <span class="cancelbtn">
            <span title="Cancel">
              <i class="fa fa-ban" aria-hidden="true"></i>
            </span>
          </span>
        </button>
      </div>
      <div class="dropdown cud">
        <i class="fa fa-ellipsis-v" id="menu1" data-toggle="dropdown"> </i>
        <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
          <li class="bg-white border-0" (click)="view(dataItem)">
            <a style="text-decoration: none !important">
              <i class="fa fa-download"> </i>View/Download</a
            >
          </li>
          <li class="bg-white border-0" kendoGridEditCommand>
            <a style="text-decoration: none !important"
              ><i class="fa fa-pencil"></i>Edit</a
            >
          </li>
          <li
            class="bg-white border-0"
            kendoGridRemoveCommand
            data-toggle="modal"
          >
            <a style="text-decoration: none !important">
              <i class="fa fa-trash-o"></i>
              Delete
            </a>
          </li>
        </ul>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [style]="{
      'background-color': 'inherit',
      color: '#666666',
      padding: '5px 3px 3px 10px'
    }"
    [headerStyle]="{
      'font-size': '13px',
      padding: '8px 6px !important',
      color: '#666666',
      'background-color': '#ffffff'
    }"
    class="gridRow"
    width="34"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <input
        type="checkbox"
        (change)="selectDeselectAllPhotos(false)"
        name="{{ dataItem.ID }}"
        [(ngModel)]="dataItem.selected"
      />
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    class="gridrow"
    field="FileName"
    title="Name"
    [style]="{ 'background-color': 'inherit', color: '#666666' }"
    [headerStyle]="{
      'font-size': '13px',
      padding: '8px 6px !important',
      color: '#666666',
      'background-color': '#ffffff'
    }"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="pointer" (click)="view(dataItem)">
        {{ dataItem.FileName }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    *ngIf="showFileTypeColumn"
    field="FileTypeName"
    title="File Type"
    [style]="{ 'background-color': 'inherit', color: '#666666' }"
    [headerStyle]="{
      'font-size': '13px',
      padding: '8px 6px !important',
      color: '#666666',
      'background-color': '#ffffff'
    }"
  >
    <ng-template
      kendoGridEditTemplate
      let-dataItem="dataItem"
      let-formGroup="formGroup"
    >
      <kendo-dropdownlist
        [data]="fileTypes"
        textField="FileTypeName"
        valueField="Id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('FileTypeName')"
      >
      </kendo-dropdownlist>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.FileTypeName }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    *ngIf="showPhaseColumn"
    field="JobPhaseName"
    title="Phase"
    [style]="{ 'background-color': 'inherit', color: '#666666' }"
    [headerStyle]="{
      'font-size': '13px',
      padding: '8px 6px !important',
      color: '#666666',
      'background-color': '#ffffff'
    }"
  >
  </kendo-grid-column>
  <kendo-grid-column
    *ngIf="showCreatedOnColumn"
    field="CreatedOn"
    title="Created On"
    filter="date"
    format="{0: yyyy-MM-dd HH:mm:ss}"
    [style]="{ 'background-color': 'inherit', color: '#666666' }"
    [headerStyle]="{
      'font-size': '13px',
      color: '#666666',
      'background-color': '#ffffff'
    }"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.CreatedOn | date : "short" }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [style]="{ 'background-color': 'inherit', color: '#666666' }"
    [headerStyle]="{
      'font-size': '13px',
      padding: '8px 6px !important',
      color: '#666666',
      'background-color': '#ffffff'
    }"
    *ngIf="showPinColumn"
    width="30"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <span
        class="pinned-icon"
        title="{{ dataItem.IsPinned ? 'Unpin' : 'Pin' }}"
        (click)="onFilePin(dataItem)"
        ><i class="fa fa-thumb-tack"></i
        ><i *ngIf="dataItem.IsPinned" class="fa fa-ban"></i
      ></span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="download"
    title="Download"
    [style]="{ 'background-color': 'inherit', color: '#666666' }"
    [headerStyle]="{
      'font-size': '13px',
      padding: '8px 6px !important',
      color: '#666666',
      'background-color': '#ffffff'
    }"
    *ngIf="isVendor"
  >
    <ng-template kendoGridCellTemplate let-dataItem class="downloadCenter">
      <span class="download-center" title="Download" (click)="view(dataItem)"
        ><i class="fa fa-download"></i
      ></span>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

<!-- Sidebars -->
<div class="widgets" *ngIf="visibleSidebar">
  <p-sidebar
    [(visible)]="visibleSidebar"
    class="addeditclient"
    position="right"
    (onHide)="onHideSliderHide($event)"
    [baseZIndex]="900000"
    appendTo="body"
  >
    <photosAdd
      [saleJobId]="JobId"
      (fileAdded)="handleRequest(true)"
      [phaseId]="jobPhaseId"
      [isVendor]="isVendor"
      [vendorId]="vendorId"
    ></photosAdd>
  </p-sidebar>
</div>

<div *ngIf="displayMiddleBar" class="widgets sidebar2 open">
  <photosAdd
    [saleJobId]="JobId"
    (fileAdded)="handleRequest(true)"
    [phaseId]="jobPhaseId"
    [isVendor]="isVendor"
    [vendorId]="vendorId"
  ></photosAdd>
</div>
