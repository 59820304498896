import { Injectable } from "@angular/core";
import {
  ActivatedRoute,
  Router,
  CanActivate,
  RouterStateSnapshot,
} from "@angular/router";
import { SharedService } from "../common/utility/SharedService";
import { NavigationEnd } from "@angular/router";
import { LocalStorageService } from "app/common/services/localStorage.service";
import {
  RedirectDashboardUrl,
  RedirectDashboardUrlEnum,
} from "app/common/utility/Redirect.function";
import { SettingService } from "app/common/services/settings.service";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private settingService: SettingService
  ) {}
  curPath: string;
  conf = "configuration";
  canActivate() {
    if (localStorage.getItem("token")) {
      // logged in so return true
      this.router.events.subscribe((nav: NavigationEnd) => {
        this.curPath = nav.url;
        if (this.curPath) {
          const configObj = this.sharedService.loggedInUser.CustomerRoles;
          if (this.curPath.includes("sales/salesCalendar")) {
            if (this.sharedService.loggedInUser.Org.UseGoogleCalendar) {
              return true;
            } else {
              this.logOut();
              return false;
            }
          }
          const hasDashBoardRole = configObj.find((x) => x.Id === 0);
          if (
            hasDashBoardRole &&
            this.curPath.includes("configuration/dashboard")
          ) {
            return true;
          } else if (hasDashBoardRole && configObj.length == 1) {
            this.logOut();
            return false;
          }
          if (
            this.curPath &&
            this.curPath.includes(this.conf) &&
            !this.curPath.includes("organizationsAddEdit")
          ) {
            // 0 = Executive, 1 = System Admin, 2 = Admin
            const hasRole = configObj.find((x) => x.Id <= 2);
            if (hasRole) {
              return true;
            } else {
              this.logOut();
              return false;
            }
          }
          if (this.curPath.includes("wipdashboard/1")) {
            const dashboardAllowed = this.callRedirect();
            if (dashboardAllowed == RedirectDashboardUrlEnum.Wip) {
              window.location.href = "/#/pages/dashboards/wipdashboard/2";
              return false;
            }

            if (dashboardAllowed == RedirectDashboardUrlEnum.All) return true;
          }
          if (this.curPath.includes("wipdashboard/2")) {
            const dashboardAllowed = this.callRedirect();
            if (dashboardAllowed == RedirectDashboardUrlEnum.Sales) {
              window.location.href = "/#/pages/dashboards/wipdashboard/1";
              return false;
            }

            if (dashboardAllowed == RedirectDashboardUrlEnum.All) return true;
          }
        }
      });
      return true;
    }
    // not logged in so redirect to login page
    this.logOut();
    return false;
  }
  logOut() {
    this.localStorageService.clear();
    this.sharedService.dispose();
    this.router.navigate(["/login"]);
  }

  /**
   * Methos to call the redirect dashboard url function
   * @returns dashboard allowed option
   */
  callRedirect() {
    return RedirectDashboardUrl(
      this.settingService,
      this.sharedService,
      this.router,
      true
    );
  }
}

@Injectable()
export class Loggedout implements CanActivate {
  constructor(private router: Router) {}

  canActivate() {
    if (localStorage.getItem("token")) {
      // logged in so return true
      this.router.navigate(["/pages"]);
      return false;
    }
    // not logged in so redirect to login page
    // this.router.navigate(['/login']);
    return true;
  }
}
