import { Component, Inject, OnInit } from "@angular/core";
import {
  MatCheckboxChange,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { Router } from "@angular/router";
import { EstimateWorksheetService } from "app/common/services/estimate-worksheet.service";
import { WorksheetEstimateViewGroupChecklistItemValues } from "app/model/WorksheetEstimateViewGroupChecklistItemValues";
import { BaThemeSpinner } from "app/theme/services/baThemeSpinner";

@Component({
  selector: "app-estimate-dashboard-checklist-item-modal",
  templateUrl: "./estimate-dashboard-checklist-item-modal.component.html",
})
export class EstimateDashboardChecklistItemModalComponent implements OnInit {
  checklistItems: WorksheetEstimateViewGroupChecklistItemValues[];
  groupName: string;
  disableMarkAsDone: boolean;

  constructor(
    private router: Router,
    private spinner: BaThemeSpinner,
    private estimateWorksheetService: EstimateWorksheetService,
    private dialogRef: MatDialogRef<
      EstimateDashboardChecklistItemModalComponent
    >,
    @Inject(MAT_DIALOG_DATA)
    data: {
      groupName: string;
      checklistItems: WorksheetEstimateViewGroupChecklistItemValues[];
    }
  ) {
    this.groupName = data.groupName;
    this.checklistItems = data.checklistItems;
  }

  ngOnInit() {
    this.updateMarkAsDoneEnable();
  }

  close() {
    this.dialogRef.close(false);
  }

  updateMarkAsDoneEnable() {
    this.disableMarkAsDone = false;
    this.checklistItems.forEach((item) => {
      if (!item.status) {
        this.disableMarkAsDone = true;
      }
    });
  }

  markItemAsDone(
    event: MatCheckboxChange,
    item: WorksheetEstimateViewGroupChecklistItemValues
  ) {
    item.status = event.checked;

    this.spinner.show();
    this.estimateWorksheetService
      .markAsDoneWorksheetEstimateViewGroupChecklistItem(item)
      .subscribe(() => {
        this.updateMarkAsDoneEnable();
      })
      .add(() => this.spinner.hide());
  }

  masrkAsDone() {
    this.dialogRef.close(true);
  }
}
