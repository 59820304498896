import { NgModule } from "@angular/core";
import {
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatSelectModule,
  MatOptionModule,
  MatButtonModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatDialogModule,
  MatExpansionModule,
  MatDividerModule,
  MatTabsModule,
  MatProgressSpinnerModule,
  MatFormFieldModule,
  MatSlideToggleModule,
  MatProgressBarModule,
  MatRadioModule,
  MatCheckboxModule,
  MatStepperModule,
  MatIconRegistry,
  MatSliderModule,
  MatCardModule,
  MatButtonToggleModule,
} from "@angular/material";
import { DomSanitizer } from "@angular/platform-browser";

@NgModule({
  imports: [
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatDialogModule,
    MatExpansionModule,
    MatDividerModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatRadioModule,
    MatCheckboxModule,
    MatStepperModule,
    MatCardModule,
    MatSliderModule,
  ],
  exports: [
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatDialogModule,
    MatExpansionModule,
    MatDividerModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatRadioModule,
    MatCheckboxModule,
    MatStepperModule,
    MatCardModule,
    MatSliderModule,
  ],
})
export class MaterialUIComponentsModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl("./assets/mdi.svg")
    );
  }
}
