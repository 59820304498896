import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";
import {
  MediaResourceModel,
  MediaResourceObjectModel,
} from "app/model/MediaResource/MediaResourceModel";
import { BlobDataModel } from "app/model/MediaResource/BlobDataModel";
import { environmentConstant } from "app/common/utility/environment";
import { VideoDataModel } from "app/model/MediaResource/VideoDataModel";
import { ApiResponseModel } from "app/model/ApiResponseModel";
import { Observable } from "rxjs";

@Injectable()
export class MediaResourceService {
  constructor(private httpService: HttpService) {}

  private callback: (baseUrl: string) => Observable<any>;

  public commit = (baseUrl: string = undefined): Observable<any> =>
    this.callback(baseUrl);
  public setCallback = (callback: (baseUrl: string) => Observable<any>) =>
    (this.callback = callback);

  get(
    url: string
  ): Observable<ApiResponseModel<MediaResourceObjectModel<any>[]>> {
    return this.httpService.get<MediaResourceObjectModel<any>[]>(
      environmentConstant.coreApi.getMediaResources(url)
    );
  }

  delete(
    url: string,
    mediaResourceGuid: string
  ): Observable<ApiResponseModel<MediaResourceModel>> {
    return this.httpService.delete<MediaResourceModel>(
      environmentConstant.coreApi.deleteMediaResource(url, mediaResourceGuid)
    );
  }

  update(
    url: string,
    mediaResourceGuid: string,
    model: MediaResourceModel
  ): Observable<ApiResponseModel<MediaResourceModel>> {
    return this.httpService.put<MediaResourceModel>(
      environmentConstant.coreApi.putMediaResource(url, mediaResourceGuid),
      model
    );
  }

  addImage(
    url: string,
    model: MediaResourceObjectModel<BlobDataModel>
  ): Observable<ApiResponseModel<MediaResourceObjectModel<BlobDataModel>>> {
    return this.httpService.post<MediaResourceObjectModel<BlobDataModel>>(
      environmentConstant.coreApi.postMediaResourceImage(url),
      model
    );
  }

  addVideo(
    url: string,
    model: MediaResourceObjectModel<VideoDataModel>
  ): Observable<ApiResponseModel<MediaResourceObjectModel<VideoDataModel>>> {
    return this.httpService.post<MediaResourceObjectModel<VideoDataModel>>(
      environmentConstant.coreApi.postMediaResourceVideo(url),
      model
    );
  }
}
