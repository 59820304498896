import { JobService } from "app/common/services/job.service";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { BaThemeSpinner } from "app/theme/services";
import { SharedService } from "app/common/utility/SharedService";
import { JobListModelV2 } from "app/model/jobListModelV2";
import { JObChangeOrderModel } from "app/model/jobChangeOrderModel";
import { Helper } from "app/common/utility/helper";
import * as _ from "lodash";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import {
  GridDataResult,
  PageChangeEvent,
} from "@progress/kendo-angular-grid/dist/es2015";
import { InvoiceService } from "app/common/services/invoice.service";
import { enumModuleType } from "app/common/directives/commonEnum";
import { AddInvoiceModel } from "app/model/AddInvoiceModel";
import { MessageService } from "primeng/components/common/messageservice";
import { OrderItemModel } from "app/model/OrderItem";
import { ConfirmComponent } from "../dialog/dialog.component";
import { DialogService } from "ng2-bootstrap-modal";
import { watchFile } from "fs";
import { delay } from "rxjs/operators";
import { OrderModel } from "app/model/OrderModel";
import { environmentConstant } from "app/common/utility/environment";

@Component({
  selector: "assign-job",
  templateUrl: "./AssignJob.component.html",
  styleUrls: ["./AssignJob.css"],
})
export class AssignJobComponent implements OnInit {
  headerText: string = "";
  jobId: any;
  jobsList: JobListModelV2[] = [];
  originalJobsList = [];
  parentJobChangeOrder: JObChangeOrderModel = new JObChangeOrderModel();
  lstAssignResource: any[] = [];
  filterQuery = "";
  isFilled: boolean;
  pageSize: number = 50;
  sort: SortDescriptor[] = [];
  gridView: GridDataResult;
  skip: number = 0;
  selectedData: any = "";
  searchText: string = "";
  addInvoiceModel: AddInvoiceModel;
  drawSchedule: any;
  hasDrawSchedule: boolean = false;
  jobData: any;
  DrawScheduleSystemDefaultProductId: number;
  @Input() callingComponent: string;
  @Input() ModuleId: number;
  @Output() sendData: EventEmitter<any> = new EventEmitter<any>();
  fieldName: string;
  constructor(
    private _spinner: BaThemeSpinner,
    private sharedService: SharedService,
    private jobService: JobService,
    private invoiceService: InvoiceService,
    private dialogService: DialogService,
    private notificationsService: MessageService
  ) {
    this.addInvoiceModel = new AddInvoiceModel();
  }

  multiple: boolean = false;
  allowUnsort: boolean = true;
  ngOnInit() {
    this.fieldName = "JobName";
    this.getJobList();
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadJobs(null);
  }
  pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.filterGridData();
  }

  filterGrid() {
    this.isFilled = true;
    this.filterGridData();
    if (this.filterQuery.length == 0) {
      this.isFilled = false;
    }
    this.pageChange({ skip: 0, take: this.pageSize });
  }
  filterGridData() {
    this.loadJobs(
      _.filter(
        this.lstAssignResource,
        (row) =>
          Helper.ConvertToLower(row.JobName).indexOf(
            this.filterQuery.trim().toLowerCase()
          ) > -1
      )
    );
  }
  private loadJobs(job): void {
    let jobs = job || this.lstAssignResource;
    jobs = orderBy(jobs, this.sort);

    this.gridView = {
      data: jobs.slice(this.skip, this.skip + this.pageSize),
      total: jobs.length,
    };
    this._spinner.hide();
  }
  getJobList() {
    this._spinner.show();
    this.headerText = "Find Job";
    this.searchText = "Search By Job Name";
    this.jobService
      .getAllJobsByOrgIdv2(this.sharedService.selectedOrganization.ID, 7) // all jobs
      .subscribe((jobs) => {
        this.lstAssignResource = jobs;
        this.fieldName = "JobName";
        this.loadJobs(null);
        this._spinner.hide();
      });
  }

  GetSystemDefaultDrawScheduleProduct() {
    this.invoiceService
      .getSystemDefaultDrawScheduleProductDetails(environmentConstant.systemOrg)
      .subscribe((data) => {
        this.DrawScheduleSystemDefaultProductId = data.Id;
      });
  }

  SaveInvoice() {
    this.CreateInvoice();
  }

  CreateInvoice() {
    this.jobData;
    if (this.callingComponent == "Invoice") {
      this.addInvoiceModel.jobId = this.selectedData.JobId;
      this.addInvoiceModel.customerId = null;
      this.addInvoiceModel.orgGuid = this.sharedService.selectedOrganization.ID;
      this._spinner.show();

      this.invoiceService
        .saveInvoiceCustomer(this.addInvoiceModel, this.ModuleId)
        .subscribe(
          (result) => {
            setTimeout(() => {
              this.result(result, "Added sucessfully");
              this.jobData = {
                selectedCustomer: this.selectedData,
                orderId: result,
                customerId: result.CustomerId,
              };
              this.sendData.emit(this.jobData);
              this._spinner.hide();
            }, 1000);
          },
          (error) => {
            this.error(error);
          }
        );
    } else {
      this.sendData.emit(this.selectedData);
    }
  }

  // CreateMultipleInvoicesModal() {
  //      const disposable = this.dialogService.addDialog(ConfirmComponent, {
  //               title: 'Create Separate Invoices',
  //               message: "Do you want to split the draw schedule records into separate invoices?",
  //               toShow: false,
  //               isNotification: false
  //             })
  //               .subscribe((isConfirmed) => {
  //                 if (isConfirmed) {
  //                     this.CreateInvoice()
  //                 }
  //                 else {
  //                     this.CreateInvoice();
  //                 }
  //               });
  // }

  // AddDrawScheduleToInvoice(drawSchedule: any) {
  //     const disposable = this.dialogService.addDialog(ConfirmComponent, {
  //       title: 'Draw Schedule Found',
  //       message: "Do you want to add the draw schedule\n to the invoice?",
  //       toShow: false,
  //       isNotification: false
  //     })
  //       .subscribe((isConfirmed) => {
  //         if (isConfirmed) {
  //             if(drawSchedule !== null){
  //                 let existingOrderList = drawSchedule.filter(x => x.OrderId !== null && x.Deleted == false);
  //                 if(existingOrderList.length > 0){
  //                     let order = existingOrderList[0].OrderId;
  //                     if(order !== 0)
  //                         this.OrderIdAssigned(order);

  //                 } else {
  //                     this.CreateMultipleInvoicesModal();
  //                 }
  //             }
  //         }
  //         else {
  //             this.hasDrawSchedule = false;
  //             this.CreateInvoice(false);
  //         }
  //       });
  //   }

  onSave() {
    this.SaveInvoice();
  }

  private error(error: any) {
    switch (error.status) {
      case 409:
        this.notificationsService.add({
          severity: "warn",
          summary: "Invoice",
          detail: "Unable to save Job",
        });
        this._spinner.hide();
        break;
      default:
        break;
    }
  }

  // OrderIdAssigned(orderId: number) {
  //     let orderNumber = 0;
  //     this.invoiceService.getInvoicebyId(orderId).subscribe(response => {
  //       if (response.Deleted == false) {
  //         orderNumber = response.CustomOrderNumber

  //         var message: string = '';
  //         message = 'Draw Schedule already associated with Invoice # ' + orderNumber + '. Draw Schedule will not be assigned to the invoice. ';

  //         const disposable = this.dialogService.addDialog(ConfirmComponent, {
  //           title: 'Draw Schedule already associated',
  //           message: message,
  //           toShow: false,
  //           isNotification: true
  //         })
  //           .subscribe((isConfirmed) => {
  //             if (isConfirmed) {
  //                 this.hasDrawSchedule = false
  //                 this.CreateInvoice(false);
  //             };
  //           });
  //       }  else {
  //         this.CreateMultipleInvoicesModal();
  //       }
  //     });
  //   }
  private result(res: any, message: string) {
    this.notificationsService.add({
      severity: "success",
      summary: "Invoice",
      detail: message,
    });
    this._spinner.hide();
  }
  oncancel(text) {
    this.sendData.emit(text);
  }
}
