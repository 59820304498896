<!-- <div class="widgets pb35" [ngClass]="!IsSuperAdmin?'orgnzn-page':''"> -->
<div class="widgets commonFixedSidebar">
  <div class="row">
    <div class="col-md-12 ht100">
      <div class="rightcontent">
        <!-- <div [ngClass]="!IsSuperAdmin?'headingwrap clearfix':'sideBarHeader'"> -->
        <div [ngClass]="!IsSuperAdmin?'sideBarHeader clearfix':'sideBarHeader'">
          <h1>{{headerText}} Organization</h1>
        </div>
        <div *ngIf="ID==='0'; then thenBlock else elseBlock"></div>
        <ng-template #thenBlock>
          <div class="add-orgnzn-form">
            <div class="fixed-layout hoverflow">
              <form
                #orgForm="ngForm"
                class="form-horizontal"
                novalidate
                *ngIf="organization!=undefined"
              >
                <div class="ht100">
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group mheight26" *ngIf="IsSuperAdmin">
                        <div class="formfield">
                          <div class="checkbox">
                            <label class="k-form-field">
                              <input
                                type="checkbox"
                                id="Active"
                                name="Active"
                                class="k-checkbox"
                                [(ngModel)]="organization.Active"
                              />
                              <label class="k-checkbox-label" for="Active"
                                >Active</label
                              >
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <span class="ui-float-label">
                          <input
                            type="text"
                            class="form-control"
                            id="organizationName"
                            name="organizationName"
                            pInputText
                            [(ngModel)]="organization.OrganizationName"
                            #organizationName="ngModel"
                            [ngClass]="{'has-error':organizationName.invalid && (organizationName.dirty || organizationName.touched)}"
                            maxlength="200"
                            trim="blur"
                            required
                          />
                          <label
                            for="OrganizationName"
                            class="form-control-label"
                            >{{'organization.organization_name'|translate}}
                            <span class="text-red">*</span>
                          </label>
                        </span>
                      </div>
                      <div class="form-group">
                        <kendo-floatinglabel text="Select Parent Organization">
                          <kendo-combobox
                            [data]="lstOrganization"
                            [valuePrimitive]="true"
                            [filterable]="true"
                            (filterChange)="filterOrganization($event)"
                            [textField]="'OrganizationName'"
                            [disabled]="!IsSuperAdmin"
                            [(ngModel)]="organization.ParentOrgID"
                            name="ParentOrgID"
                            [valueField]="'ID'"
                          >
                          </kendo-combobox>
                        </kendo-floatinglabel>
                      </div>
                      <div class="form-group">
                        <kendo-floatinglabel
                          text="Timezone"
                          [ngClass]="{'k-state-filled':true}"
                        >
                          <kendo-dropdownlist
                            kendoTextBox
                            [defaultItem]="defaultTime"
                            name="TimeZoneOffset"
                            #TimeZoneOffset="ngModel"
                            [valuePrimitive]="true"
                            [data]="timezoneList"
                            textField="key"
                            valueField="value"
                            [(ngModel)]="organization.TimeZoneOffset"
                          >
                          </kendo-dropdownlist>
                        </kendo-floatinglabel>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <div class="formfield picturewrap">
                          <label class="form-control-label">
                            <span class="text-red">* </span>{{ID ===
                            '0'?null:'ID : ' + organization.ID}}
                          </label>
                          <ba-picture-uploader
                            (change)="fileChange($event)"
                            [picture]="organization.LogoUrl"
                            required
                          >
                            <div class="input-group">
                              <input
                                class="form-control"
                                readonly=""
                                type="text"
                              />
                              <span class="input-group-btn" required>
                                <button class="btn btn-success" type="button">
                                  {{'organization.browse'|translate}}
                                </button>
                              </span>
                            </div>
                          </ba-picture-uploader>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <h3 class="pt">Where are they Located</h3>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <span class="ui-float-label">
                          <input
                            type="text"
                            class="form-control"
                            id="address1"
                            name="address1"
                            trim="blur"
                            [(ngModel)]="organization.Address"
                            pInputText
                            maxlength="250"
                          />
                          <label for="text" class="form-control-label"
                            >{{'Address'}}</label
                          >
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                      <div class="form-group">
                        <span class="ui-float-label">
                          <input
                            type="text"
                            class="form-control"
                            id="city"
                            name="city"
                            trim="blur"
                            [(ngModel)]="organization.city"
                            pInputText
                            #city="ngModel"
                            [ngClass]="{'has-error':city.invalid && (city.dirty || city.touched)}"
                            pattern="([a-zA-Z0-9.]{2,100}\s*)+"
                            maxlength="250"
                          />
                          <label for="text" class="form-control-label"
                            >{{'common.city'|translate}}</label
                          >
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                      <div class="form-group">
                        <kendo-floatinglabel
                          text="{{'common.state'|translate}}"
                        >
                          <kendo-combobox
                            [data]="stateList"
                            [valuePrimitive]="true"
                            [filterable]="true"
                            name="State"
                            (filterChange)="filterState($event)"
                            [textField]="'Name'"
                            [(ngModel)]="organization.State"
                            [valueField]="'Id'"
                          ></kendo-combobox>
                        </kendo-floatinglabel>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                      <div class="form-group">
                        <span class="ui-float-label">
                          <i
                            *ngIf="zipValidate"
                            tooltipZIndex="12000"
                            [pTooltip]="zipCodeMessage"
                            class="fa fa-info-circle color-red zip-alert"
                          >
                          </i>
                          <input
                            #inputZip
                            type="text"
                            class="form-control"
                            id="zip"
                            name="zip"
                            pInputText
                            oninput="this.value = this.value.toUpperCase()"
                            [(ngModel)]="organization.Zip"
                            [ngClass]="{
                              'has-error': zipValidate
                            }"
                          />
                          <label for="text" class="form-control-label">
                            {{'common.zip'|translate}}
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <h3 class="pt">How To Contact Them</h3>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <span class="ui-float-label">
                          <input
                            type="email"
                            class="form-control"
                            id="email"
                            name="email"
                            pInputText
                            [(ngModel)]="organization.Email"
                            email="true"
                            trim="blur"
                            #email="ngModel"
                            [pattern]="emailPattern"
                            [ngClass]="{'has-error':email.invalid && (email.dirty || email.touched)}"
                            maxlength="250"
                            required
                          />
                          <label for="Email" class="form-control-label"
                            >{{'common.email'|translate}}
                            <span class="text-red">*</span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <span class="ui-float-label">
                          <p-inputMask
                            [(ngModel)]="organization.Phone"
                            mask="999-999-9999"
                            slotChar=" "
                            autoClear="false"
                            styleClass="form-control"
                            name="primaryPhone"
                            #primaryPhone="ngModel"
                          ></p-inputMask>
                          <label for="text" class="form-control-label"
                            >{{'common.phone'|translate}}</label
                          >
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="IsSuperAdmin">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <h3 class="pt">Organization Identification</h3>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <span class="ui-float-label">
                          <input
                            type="text"
                            class="form-control"
                            id="StreamSessionDescriptionUrl"
                            name="StreamSessionDescriptionUrl"
                            #StreamSessionDescriptionUrl="ngModel"
                            trim="blur"
                            [(ngModel)]="organization.StreamSessionDescriptionUrl"
                            [ngClass]="{'has-error':StreamSessionDescriptionUrl.invalid && (StreamSessionDescriptionUrl.dirty || StreamSessionDescriptionUrl.touched)}"
                            maxlength="1000"
                            pInputText
                            required
                          />
                          <label for="text" class="form-control-label"
                            >{{'organization.stream_session_description_url'|translate}}
                            <span class="text-red">*</span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <span class="ui-float-label">
                          <input
                            type="text"
                            class="form-control"
                            id="StreamApplicationId"
                            name="StreamApplicationId"
                            #StreamApplicationId="ngModel"
                            trim="blur"
                            [(ngModel)]="organization.StreamApplicationId"
                            [ngClass]="{'has-error':StreamApplicationId.invalid && (StreamApplicationId.dirty || StreamApplicationId.touched)}"
                            maxlength="1000"
                            pInputText
                            required
                          />
                          <label for="text" class="form-control-label"
                            >{{'organization.stream_application_id'|translate}}
                            <span class="text-red">*</span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <span class="ui-float-label">
                          <input
                            type="text"
                            class="form-control"
                            name="StreamingURL"
                            trim="blur"
                            id="StreamingURL"
                            #StreamingURL="ngModel"
                            [ngClass]="{'has-error':StreamingURL.invalid && (StreamingURL.dirty || StreamingURL.touched)}"
                            [(ngModel)]="organization.StreamingURL"
                            pInputText
                            maxlength="1000"
                            required
                          />
                          <label for="text" class="form-control-label"
                            >{{'organization.streaming_url'|translate}}
                            <span class="text-red">*</span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <span class="ui-float-label">
                          <input
                            type="number"
                            class="form-control"
                            id="Licence"
                            #Licence="ngModel"
                            min="1"
                            name="Licence"
                            pInputText
                            [(ngModel)]="organization.NumOfLicences"
                            [ngClass]="{'has-error':Licence.invalid && (Licence.dirty || Licence.touched)}"
                            pattern="[0-9]*"
                            required
                          />
                          <label for="text" class="form-control-label"
                            >{{'common.NumberofLicense'|translate}}
                            <span class="text-red">*</span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <span class="ui-float-label">
                          <input
                            type="text"
                            class="form-control"
                            maxlength="500"
                            id="NOP_URI"
                            #NOP_URI="ngModel"
                            name="NOP_URI"
                            pInputText
                            [(ngModel)]="organization.NOP_URI"
                          />
                          <label for="text" class="form-control-label"
                            >{{'common.NOPUrl'|translate}}
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="IsSuperAdmin">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <h3 class="pt">App Store Info</h3>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <span class="ui-float-label">
                          <input
                            type="AppleStoreUrl"
                            class="form-control"
                            id="AppleStoreUrl"
                            name="AppleStoreUrl"
                            [(ngModel)]="organization.AppleStoreUrl"
                            pInputText
                            trim="blur"
                            #AppleStoreUrl="ngModel"
                            maxlength="500"
                          />
                          <label for="AppleStoreUrl" class="form-control-label"
                            >Apple Store Url
                          </label>
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <span class="ui-float-label">
                          <input
                            type="PlayStoreUrl"
                            class="form-control"
                            id="PlayStoreUrl"
                            name="PlayStoreUrl"
                            [(ngModel)]="organization.PlayStoreUrl"
                            pInputText
                            trim="blur"
                            #PlayStoreUrl="ngModel"
                            maxlength="500"
                          />
                          <label for="PlayStoreUrl" class="form-control-label"
                            >Play Store Url
                          </label>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-md-12 col-sm-12 col-xs-12"
                      *ngIf="IsSuperAdmin"
                    >
                      <h3 class="pt">Modules</h3>
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <div class="form-group">
                            <div class="formfield">
                              <div class="checkbox">
                                <label class="k-form-field">
                                  <input
                                    type="checkbox"
                                    id="UseJobScheduling"
                                    name="UseJobScheduling"
                                    class="k-checkbox"
                                    [(ngModel)]="organization.UseJobScheduling"
                                  />
                                  <label
                                    class="k-checkbox-label"
                                    for="UseJobScheduling"
                                    >Use Job Scheduling</label
                                  >
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <div class="form-group">
                            <div class="formfield">
                              <div class="checkbox">
                                <label class="k-form-field">
                                  <input
                                    type="checkbox"
                                    id="UsingMPB"
                                    name="UsingMPB"
                                    class="k-checkbox"
                                    [(ngModel)]="organization.UsingMPB"
                                  />
                                  <label class="k-checkbox-label" for="UsingMPB"
                                    >Use MPB</label
                                  >
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <div class="form-group">
                            <div class="formfield">
                              <div class="checkbox">
                                <label class="k-form-field">
                                  <input
                                    type="checkbox"
                                    id="UsingService"
                                    name="UsingService"
                                    class="k-checkbox"
                                    [(ngModel)]="organization.UsingService"
                                  />
                                  <label
                                    class="k-checkbox-label"
                                    for="UsingService"
                                    >Use Services</label
                                  >
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <div class="form-group">
                            <div class="formfield">
                              <div class="checkbox">
                                <label class="k-form-field">
                                  <input
                                    type="checkbox"
                                    id="UsingPreSales"
                                    name="UsingPreSales"
                                    class="k-checkbox"
                                    [(ngModel)]="organization.UsingPreSales"
                                  />
                                  <label
                                    class="k-checkbox-label"
                                    for="UsingPreSales"
                                    >Use Pre Sales</label
                                  >
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" style="display:none">
                          <div class="form-group">
                            <div class="formfield">
                              <div class="checkbox">
                                <label class="k-form-field">
                                  <input
                                    type="checkbox"
                                    id="UseGoogleCalendar"
                                    name="UseGoogleCalendar"
                                    class="k-checkbox"
                                    [(ngModel)]="organization.UseGoogleCalendar"
                                  />
                                  <label
                                    class="k-checkbox-label"
                                    for="UseGoogleCalendar"
                                    >Use Google Calendar
                                  </label>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <div class="form-group">
                            <div class="formfield">
                              <div class="checkbox">
                                <label class="k-form-field">
                                  <input
                                    type="checkbox"
                                    id="MixMode"
                                    name="MixMode"
                                    class="k-checkbox"
                                    [(ngModel)]="organization.DependentSchedulingMixedMode"
                                  />
                                  <label class="k-checkbox-label" for="MixMode"
                                    >Mix Mode Scheduling</label
                                  >
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <div class="form-group">
                            <div class="formfield">
                              <div class="checkbox">
                                <label class="k-form-field">
                                  <input
                                    type="checkbox"
                                    id="IsDefaultOrg"
                                    name="IsDefaultOrg"
                                    class="k-checkbox"
                                    [(ngModel)]="organization.IsDefaultOrg"
                                  />
                                  <label
                                    class="k-checkbox-label"
                                    for="IsDefaultOrg"
                                    >Default Organization</label
                                  >
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <div class="form-group">
                            <div class="formfield">
                              <div class="checkbox">
                                <label class="k-form-field">
                                  <input
                                    type="checkbox"
                                    id="IsVendor"
                                    name="IsVendor"
                                    class="k-checkbox"
                                    [(ngModel)]="organization.IsVendor"
                                  />
                                  <label class="k-checkbox-label" for="IsVendor"
                                    >Vendor Organization</label
                                  >
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <div class="form-group">
                            <div class="formfield">
                              <div class="checkbox">
                                <label class="k-form-field">
                                  <input
                                    type="checkbox"
                                    id="IsDemo"
                                    name="IsDemo"
                                    class="k-checkbox"
                                    [(ngModel)]="organization.IsDemo"
                                  />
                                  <label class="k-checkbox-label" for="IsDemo"
                                    >Demo Organization</label
                                  >
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <h3 class="pt">Builder App Modules</h3>

                      <div class="row">
                        <div
                          class="col-lg-4 col-md-4 col-sm-4 col-xs-12"
                          hidden
                        >
                          <div class="form-group">
                            <div class="formfield">
                              <div class="checkbox">
                                <label class="k-form-field">
                                  <input
                                    type="checkbox"
                                    id="ShowHotDeals"
                                    name="ShowHotDeals"
                                    class="k-checkbox"
                                    [(ngModel)]="organization.ShowHotDeals"
                                  />
                                  <label
                                    class="k-checkbox-label"
                                    for="ShowHotDeals"
                                  >
                                    Show Hot Deals</label
                                  >
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <div class="form-group">
                            <div class="formfield">
                              <div class="checkbox">
                                <label class="k-form-field">
                                  <input
                                    type="checkbox"
                                    id="ShowLiveStreaming"
                                    name="IsVendor"
                                    class="k-checkbox"
                                    [(ngModel)]="organization.ShowLiveStreaming"
                                  />
                                  <label
                                    class="k-checkbox-label"
                                    for="ShowLiveStreaming"
                                    >Show Live Streaming</label
                                  >
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                          <div class="form-group">
                            <div class="formfield">
                              <div class="checkbox">
                                <label class="k-form-field">
                                  <input
                                    type="checkbox"
                                    id="ShowFinancing"
                                    name="ShowFinancing"
                                    class="k-checkbox"
                                    [(ngModel)]="organization.ShowFinancing"
                                  />
                                  <label
                                    class="k-checkbox-label"
                                    for="ShowFinancing"
                                    >Show Financing</label
                                  >
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row" *ngIf="organization.ShowFinancing">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                          <div class="form-group">
                            <span class="ui-float-label">
                              <input
                                type="text"
                                class="form-control"
                                maxlength="500"
                                id="FinancingUrl"
                                #FinancingUrl="ngModel"
                                name="FinancingUrl"
                                pInputText
                                [(ngModel)]="organization.FinancingUrl"
                                required
                              />
                              <label for="text" class="form-control-label"
                                >Financing Url
                                <span class="text-red">*</span>
                              </label>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 col-sm-12 col-xs-12">
                          <div class="form-group">
                            <span class="ui-float-label">
                              <input
                                class="form-control"
                                maxlength="500"
                                id="BudgetValues"
                                #BudgetValues="ngModel"
                                name="BudgetValues"
                                pInputText
                                [(ngModel)]="organization.BudgetValues"
                              />
                              <label for="text" class="form-control-label"
                                >Lead Budget Values (seprated by semicolons.
                                $100,000-$200,000;$500,000-$700,000;)
                              </label>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <h3 class="pt">Notifications</h3>

                      <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div class="form-group">
                            <div class="formfield">
                              <div class="checkbox">
                                <label class="k-form-field">
                                  <input
                                    type="checkbox"
                                    id="CustomerNotifications"
                                    name="CustomerNotifications"
                                    class="k-checkbox"
                                    [(ngModel)]="organization.CustomerNotifications"
                                  />
                                  <label
                                    class="k-checkbox-label"
                                    for="CustomerNotifications"
                                  >
                                    Send Customer Notification</label
                                  >
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div class="form-group">
                            <div class="formfield">
                              <div class="checkbox">
                                <label class="k-form-field">
                                  <input
                                    type="checkbox"
                                    id="VendorNotifications"
                                    name="VendorNotifications"
                                    class="k-checkbox"
                                    [(ngModel)]="organization.VendorNotifications"
                                  />
                                  <label
                                    class="k-checkbox-label"
                                    for="VendorNotifications"
                                  >
                                    Send Contractor Notification</label
                                  >
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 col-sm-12 col-xs-12">
                      <div class="row">
                        <div class="col-md-12 col-sm-12">
                          <div>
                            <h3 class="pt">Working days</h3>
                            <div class="weekday">
                              <ul class="list-inline list-unstyled padd-week">
                                <li>
                                  <input
                                    type="checkbox"
                                    id="monday"
                                    name="wd1"
                                    value="false"
                                    #workDay1="ngModel"
                                    [(ngModel)]="workDay[0]"
                                  />
                                  <label for="monday">Monday</label>
                                </li>
                                <li>
                                  <input
                                    type="checkbox"
                                    id="tuesday"
                                    name="wd2"
                                    value="false"
                                    #workDay2="ngModel"
                                    [(ngModel)]="workDay[1]"
                                  />
                                  <label for="tuesday">Tuesday</label>
                                </li>
                                <li>
                                  <input
                                    type="checkbox"
                                    id="wednessday"
                                    name="wd3"
                                    value="true"
                                    #workDay3="ngModel"
                                    [(ngModel)]="workDay[2]"
                                  />
                                  <label for="wednessday">Wednesday</label>
                                </li>
                                <li>
                                  <input
                                    type="checkbox"
                                    id="thursday"
                                    name="wd4"
                                    value="true"
                                    #workDay4="ngModel"
                                    [(ngModel)]="workDay[3]"
                                  />
                                  <label for="thursday">Thursday</label>
                                </li>
                                <li>
                                  <input
                                    type="checkbox"
                                    id="friday"
                                    name="wd5"
                                    value="true"
                                    #workDay5="ngModel"
                                    [(ngModel)]="workDay[4]"
                                  />
                                  <label for="friday">Friday</label>
                                </li>
                                <li>
                                  <input
                                    type="checkbox"
                                    id="saturday"
                                    name="wd6"
                                    value="true"
                                    #workDay6="ngModel"
                                    [(ngModel)]="workDay[5]"
                                  />
                                  <label for="saturday">Saturday</label>
                                </li>
                                <li>
                                  <input
                                    type="checkbox"
                                    id="sunday"
                                    name="wd7"
                                    value="true"
                                    #workDay7="ngModel"
                                    [(ngModel)]="workDay[6]"
                                  />
                                  <label for="sunday">Sunday</label>
                                </li>
                              </ul>
                              <div class="clearfix"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="IsSuperAdmin">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <h3 class="pt">Proposal Disclaimer</h3>
                    </div>
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <textarea
                          [(ngModel)]="organization.ProposalDisclaimer"
                          class="form-control mb0"
                          kendoTextBox
                          name="Description"
                          rows="7"
                          cols="50"
                          style="
                            width: 100%;
                            height: 80px !important;
                            resize: vertical;
                          "
                          floatLabelType="Auto"
                          type="text"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="h50"></div>
                </div>
                <div class="fixbtnwrap">
                  <div class="btnwrap">
                    <button
                      type="button"
                      class="btn darkbtn"
                      [disabled]="!orgForm.valid || !isPhotoSelected"
                      *ngIf="orgForm.dirty || isPhotoSelected"
                      (click)="onSubmit(orgForm)"
                    >
                      <i class="fa fa-floppy-o" aria-hidden="true"></i>
                      {{btnText}}
                    </button>
                    <button
                      *ngIf="IsSuperAdmin"
                      type="button"
                      class="btn btn-cancel"
                      (click)="onCancel()"
                    >
                      {{'common.cancel'|translate}}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </ng-template>
        <ng-template #elseBlock>
          <div class="tabBlk">
            <form #orgForm="ngForm" class="form-horizontal" novalidate>
              <div class="ht100">
                <kendo-tabstrip>
                  <kendo-tabstrip-tab [title]="'General'" [selected]="true">
                    <ng-template kendoTabContent>
                      <div class="edit-orgnzn-form">
                        <div class="row">
                          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group mheight26">
                              <div class="formfield" *ngIf="IsSuperAdmin">
                                <div class="checkbox">
                                  <label class="k-form-field">
                                    <input
                                      type="checkbox"
                                      id="Active"
                                      name="Active"
                                      class="k-checkbox"
                                      [(ngModel)]="organization.Active"
                                    />
                                    <label class="k-checkbox-label" for="Active"
                                      >Active</label
                                    >
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <span class="ui-float-label">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="organizationName"
                                  name="organizationName"
                                  [(ngModel)]="organization.OrganizationName"
                                  pInputText
                                  #organizationName="ngModel"
                                  [ngClass]="{'has-error':organizationName.invalid && (organizationName.dirty || organizationName.touched)}"
                                  maxlength="200"
                                  trim="blur"
                                  required
                                />
                                <label
                                  for="OrganizationName"
                                  class="form-control-label"
                                  >{{'organization.organization'|translate}}
                                  <span class="text-red">*</span>
                                </label>
                              </span>
                            </div>
                            <div class="form-group">
                              <kendo-floatinglabel
                                text="Select Parent Organization"
                              >
                                <kendo-combobox
                                  [data]="lstOrganization"
                                  [valuePrimitive]="true"
                                  [disabled]="!IsSuperAdmin"
                                  [filterable]="true"
                                  (filterChange)="filterOrganization($event)"
                                  [textField]="'OrganizationName'"
                                  [(ngModel)]="organization.ParentOrgID"
                                  name="ParentOrgID"
                                  [valueField]="'ID'"
                                ></kendo-combobox>
                              </kendo-floatinglabel>
                            </div>
                            <div class="form-group">
                              <kendo-floatinglabel
                                text="Timezone"
                                [ngClass]="{'k-state-filled':true}"
                              >
                                <kendo-dropdownlist
                                  kendoTextBox
                                  [defaultItem]="defaultTime"
                                  name="TimeZoneOffset"
                                  #TimeZoneOffset="ngModel"
                                  [valuePrimitive]="true"
                                  [data]="timezoneList"
                                  textField="key"
                                  valueField="value"
                                  [(ngModel)]="organization.TimeZoneOffset"
                                >
                                </kendo-dropdownlist>
                              </kendo-floatinglabel>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                              <div class="formfield picturewrap">
                                <label class="form-control-label">
                                  <span class="text-red">* </span>{{ID ===
                                  '0'?null:'ID : ' + organization.ID}}
                                </label>
                                <ba-picture-uploader
                                  (change)="fileChange($event)"
                                  required
                                  [picture]="organization.LogoUrl"
                                >
                                  <div class="input-group">
                                    <input
                                      class="form-control"
                                      readonly=""
                                      type="text"
                                    />
                                    <span class="input-group-btn" required>
                                      <button
                                        class="btn btn-success"
                                        type="button"
                                      >
                                        {{'organization.browse'|translate}}
                                      </button>
                                    </span>
                                  </div>
                                </ba-picture-uploader>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <h3 class="pt">Where are they Located</h3>
                          </div>
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div class="form-group">
                              <span class="ui-float-label">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="address"
                                  name="address"
                                  trim="blur"
                                  [(ngModel)]="organization.Address"
                                  pInputText
                                  maxlength="250"
                                />
                                <label for="text" class="form-control-label"
                                  >{{'Address'}}</label
                                >
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div class="form-group">
                              <span class="ui-float-label">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="city"
                                  name="city"
                                  trim="blur"
                                  [(ngModel)]="organization.City"
                                  #city="ngModel"
                                  [ngClass]="{'has-error':city.invalid && (city.dirty || city.touched)}"
                                  pattern="([a-zA-Z0-9.]{2,100}\s*)+"
                                  maxlength="250"
                                  pInputText
                                />
                                <label for="text" class="form-control-label"
                                  >{{'common.city'|translate}}</label
                                >
                              </span>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div class="form-group">
                              <kendo-floatinglabel
                                text="{{'common.state'|translate}}"
                              >
                                <kendo-combobox
                                  [data]="stateList"
                                  [valuePrimitive]="true"
                                  [filterable]="true"
                                  name="State"
                                  (filterChange)="filterState($event)"
                                  [textField]="'Name'"
                                  [(ngModel)]="organization.State"
                                  [valueField]="'Id'"
                                ></kendo-combobox>
                              </kendo-floatinglabel>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                            <div class="form-group">
                              <span class="ui-float-label">
                                <i
                                  *ngIf="zipValidate"
                                  tooltipZIndex="12000"
                                  [pTooltip]="zipCodeMessage"
                                  class="fa fa-info-circle color-red zip-alert"
                                >
                                </i>
                                <input
                                  #inputZip
                                  type="text"
                                  class="form-control"
                                  id="zip"
                                  name="zip"
                                  pInputText
                                  oninput="this.value = this.value.toUpperCase()"
                                  [(ngModel)]="organization.Zip"
                                  [ngClass]="{
                                    'has-error': zipValidate
                                  }"
                                />
                                <label for="text" class="form-control-label">
                                  {{'common.zip'|translate}}
                                </label>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <h3 class="pt">How To Contact Them</h3>
                          </div>
                          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                              <span class="ui-float-label">
                                <input
                                  type="email"
                                  class="form-control"
                                  id="email"
                                  name="email"
                                  [(ngModel)]="organization.Email"
                                  pInputText
                                  email="true"
                                  trim="blur"
                                  #email="ngModel"
                                  [ngClass]="{'has-error':email.invalid && (email.dirty || email.touched)}"
                                  maxlength="250"
                                  [pattern]="emailPattern"
                                  required
                                />
                                <label for="Email" class="form-control-label"
                                  >{{'common.email'|translate}}
                                  <span class="text-red">*</span>
                                </label>
                              </span>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                              <span class="ui-float-label">
                                <p-inputMask
                                  [(ngModel)]="organization.Phone"
                                  mask="999-999-9999"
                                  slotChar=" "
                                  autoClear="false"
                                  styleClass="form-control"
                                  name="primaryPhone"
                                  #primaryPhone="ngModel"
                                ></p-inputMask>
                                <label for="text" class="form-control-label"
                                  >{{'common.phone'|translate}}</label
                                >
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="row" *ngIf="IsSuperAdmin">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <h3 class="pt">Organization Identification</h3>
                          </div>
                          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="form-group">
                              <span class="ui-float-label">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="StreamSessionDescriptionUrl"
                                  name="StreamSessionDescriptionUrl"
                                  #StreamSessionDescriptionUrl="ngModel"
                                  trim="blur"
                                  [(ngModel)]="organization.StreamSessionDescriptionUrl"
                                  [ngClass]="{'has-error':StreamSessionDescriptionUrl.invalid && (StreamSessionDescriptionUrl.dirty || StreamSessionDescriptionUrl.touched)}"
                                  maxlength="1000"
                                  pInputText
                                  required
                                />
                                <label for="text" class="form-control-label"
                                  >{{'organization.stream_session_description_url'|translate}}
                                  <span class="text-red">*</span>
                                </label>
                              </span>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="form-group">
                              <span class="ui-float-label">
                                <input
                                  type="text"
                                  class="form-control"
                                  id="StreamApplicationId"
                                  name="StreamApplicationId"
                                  #StreamApplicationId="ngModel"
                                  trim="blur"
                                  [(ngModel)]="organization.StreamApplicationId"
                                  [ngClass]="{'has-error':StreamApplicationId.invalid && (StreamApplicationId.dirty || StreamApplicationId.touched)}"
                                  maxlength="1000"
                                  pInputText
                                  required
                                />
                                <label for="text" class="form-control-label"
                                  >{{'organization.stream_application_id'|translate}}
                                  <span class="text-red">*</span>
                                </label>
                              </span>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                              <span class="ui-float-label">
                                <input
                                  type="text"
                                  class="form-control"
                                  name="StreamingURL"
                                  trim="blur"
                                  id="StreamingURL"
                                  #StreamingURL="ngModel"
                                  [ngClass]="{'has-error':StreamingURL.invalid && (StreamingURL.dirty || StreamingURL.touched)}"
                                  [(ngModel)]="organization.StreamingURL"
                                  pInputText
                                  maxlength="1000"
                                  required
                                />
                                <label for="text" class="form-control-label"
                                  >{{'organization.streaming_url'|translate}}
                                  <span class="text-red">*</span>
                                </label>
                              </span>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                              <span class="ui-float-label">
                                <input
                                  type="number"
                                  class="form-control"
                                  *ngIf="ID != 0"
                                  id="ActivationCode"
                                  #ActivationCode="ngModel"
                                  min="0"
                                  name="ActivationCode"
                                  pInputText
                                  [(ngModel)]="organization.ActivationCode"
                                  [ngClass]="{'has-error':ActivationCode.invalid && (ActivationCode.dirty || ActivationCode.touched)}"
                                  pattern="[0-9]*"
                                  required
                                />
                                <label for="text" class="form-control-label"
                                  >{{'common.ActivationCode'|translate}}
                                  <span class="text-red">*</span>
                                </label>
                              </span>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                              <span class="ui-float-label">
                                <input
                                  type="number"
                                  class="form-control"
                                  id="Licence"
                                  #Licence="ngModel"
                                  min="1"
                                  name="Licence"
                                  pInputText
                                  [(ngModel)]="organization.NumOfLicences"
                                  [ngClass]="{'has-error':Licence.invalid && (Licence.dirty || Licence.touched)}"
                                  pattern="[0-9]*"
                                  required
                                />
                                <label for="text" class="form-control-label"
                                  >{{'common.NumberofLicense'|translate}}
                                  <span class="text-red">*</span>
                                </label>
                              </span>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                              <span class="ui-float-label">
                                <input
                                  type="text"
                                  class="form-control"
                                  maxlength="500"
                                  id="NOP_URl"
                                  #NOP_URI="ngModel"
                                  name="NOP_URI"
                                  pInputText
                                  [(ngModel)]="organization.NOP_URI"
                                />
                                <label for="text" class="form-control-label"
                                  >{{'common.NOPUrl'|translate}}
                                </label>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="row" *ngIf="IsSuperAdmin">
                          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <h3 class="pt">App Store Info</h3>
                          </div>
                          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                              <span class="ui-float-label">
                                <input
                                  type="AppleStoreUrl"
                                  class="form-control"
                                  id="AppleStoreUrl"
                                  name="AppleStoreUrl"
                                  [(ngModel)]="organization.AppleStoreUrl"
                                  pInputText
                                  trim="blur"
                                  #AppleStoreUrl="ngModel"
                                  maxlength="500"
                                />
                                <label
                                  for="AppleStoreUrl"
                                  class="form-control-label"
                                  >Apple Store Url
                                </label>
                              </span>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                            <div class="form-group">
                              <span class="ui-float-label">
                                <input
                                  type="PlayStoreUrl"
                                  class="form-control"
                                  id="PlayStoreUrl"
                                  name="PlayStoreUrl"
                                  [(ngModel)]="organization.PlayStoreUrl"
                                  pInputText
                                  trim="blur"
                                  #PlayStoreUrl="ngModel"
                                  maxlength="500"
                                />
                                <label
                                  for="PlayStoreUrl"
                                  class="form-control-label"
                                  >Play Store Url
                                </label>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div
                            class="col-md-12 col-sm-12 col-xs-12"
                            *ngIf="IsSuperAdmin"
                          >
                            <h3 class="pt">Modules</h3>
                            <div class="row">
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div class="form-group">
                                  <div class="formfield">
                                    <div class="checkbox">
                                      <label class="k-form-field">
                                        <input
                                          type="checkbox"
                                          id="UseJobScheduling"
                                          name="UseJobScheduling"
                                          class="k-checkbox"
                                          [(ngModel)]="organization.UseJobScheduling"
                                        />
                                        <label
                                          class="k-checkbox-label"
                                          for="UseJobScheduling"
                                          >Use Job Scheduling</label
                                        >
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div class="form-group">
                                  <div class="formfield">
                                    <div class="checkbox">
                                      <label class="k-form-field">
                                        <input
                                          type="checkbox"
                                          id="UsingMPB"
                                          name="UsingMPB"
                                          class="k-checkbox"
                                          [(ngModel)]="organization.UsingMPB"
                                        />
                                        <label
                                          class="k-checkbox-label"
                                          for="UsingMPB"
                                          >Use MPB</label
                                        >
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div class="form-group">
                                  <div class="formfield">
                                    <div class="checkbox">
                                      <label class="k-form-field">
                                        <input
                                          type="checkbox"
                                          id="UsingService"
                                          name="UsingService"
                                          class="k-checkbox"
                                          [(ngModel)]="organization.UsingService"
                                        />
                                        <label
                                          class="k-checkbox-label"
                                          for="UsingService"
                                          >Use Services</label
                                        >
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div class="form-group">
                                  <div class="formfield">
                                    <div class="checkbox">
                                      <label class="k-form-field">
                                        <input
                                          type="checkbox"
                                          id="UsingPreSales"
                                          name="UsingPreSales"
                                          class="k-checkbox"
                                          [(ngModel)]="organization.UsingPreSales"
                                        />
                                        <label
                                          class="k-checkbox-label"
                                          for="UsingPreSales"
                                          >Use Pre Sales</label
                                        >
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12" style="display:none">
                                <div class="form-group">
                                  <div class="formfield">
                                    <div class="checkbox">
                                      <label class="k-form-field">
                                        <input
                                          type="checkbox"
                                          id="UseGoogleCalendar"
                                          name="UseGoogleCalendar"
                                          class="k-checkbox"
                                          [(ngModel)]="organization.UseGoogleCalendar"
                                        />
                                        <label
                                          class="k-checkbox-label"
                                          for="UseGoogleCalendar"
                                          >Use Google Calendar
                                        </label>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div class="form-group">
                                  <div class="formfield">
                                    <div class="checkbox">
                                      <label class="k-form-field">
                                        <input
                                          type="checkbox"
                                          id="MixMode"
                                          name="MixMode"
                                          class="k-checkbox"
                                          [(ngModel)]="organization.DependentSchedulingMixedMode"
                                        />
                                        <label
                                          class="k-checkbox-label"
                                          for="MixMode"
                                          >Mix Mode Scheduling</label
                                        >
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div class="form-group">
                                  <div class="formfield">
                                    <div class="checkbox">
                                      <label class="k-form-field">
                                        <input
                                          type="checkbox"
                                          id="IsDefaultOrg"
                                          name="IsDefaultOrg"
                                          class="k-checkbox"
                                          [(ngModel)]="organization.IsDefaultOrg"
                                        />
                                        <label
                                          class="k-checkbox-label"
                                          for="IsDefaultOrg"
                                          >Default Organization</label
                                        >
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div class="form-group">
                                  <div class="formfield">
                                    <div class="checkbox">
                                      <label class="k-form-field">
                                        <input
                                          type="checkbox"
                                          id="IsVendor"
                                          name="IsVendor"
                                          class="k-checkbox"
                                          [(ngModel)]="organization.IsVendor"
                                        />
                                        <label
                                          class="k-checkbox-label"
                                          for="IsVendor"
                                          >Vendor Organization</label
                                        >
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div class="form-group">
                                  <div class="formfield">
                                    <div class="checkbox">
                                      <label class="k-form-field">
                                        <input
                                          type="checkbox"
                                          id="IsDemo"
                                          name="IsDemo"
                                          class="k-checkbox"
                                          [(ngModel)]="organization.IsDemo"
                                        />
                                        <label
                                          class="k-checkbox-label"
                                          for="IsDemo"
                                          >Demo Organization</label
                                        >
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 col-sm-12 col-xs-12">
                            <h3 class="pt">Builder App Modules</h3>

                            <div class="row">
                              <div
                                class="col-lg-4 col-md-4 col-sm-4 col-xs-12"
                                hidden
                              >
                                <div class="form-group">
                                  <div class="formfield">
                                    <div class="checkbox">
                                      <label class="k-form-field">
                                        <input
                                          type="checkbox"
                                          id="ShowHotDeals"
                                          name="ShowHotDeals"
                                          class="k-checkbox"
                                          [(ngModel)]="organization.ShowHotDeals"
                                        />
                                        <label
                                          class="k-checkbox-label"
                                          for="ShowHotDeals"
                                        >
                                          Show Hot Deals</label
                                        >
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div class="form-group">
                                  <div class="formfield">
                                    <div class="checkbox">
                                      <label class="k-form-field">
                                        <input
                                          type="checkbox"
                                          id="ShowLiveStreaming"
                                          name="ShowLiveStreaming"
                                          class="k-checkbox"
                                          [(ngModel)]="organization.ShowLiveStreaming"
                                        />
                                        <label
                                          class="k-checkbox-label"
                                          for="ShowLiveStreaming"
                                          >Show Live Streaming</label
                                        >
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div class="form-group">
                                  <div class="formfield">
                                    <div class="checkbox">
                                      <label class="k-form-field">
                                        <input
                                          type="checkbox"
                                          id="ShowFinancing"
                                          name="ShowFinancing"
                                          class="k-checkbox"
                                          [(ngModel)]="organization.ShowFinancing"
                                        />
                                        <label
                                          class="k-checkbox-label"
                                          for="ShowFinancing"
                                          >Show Financing</label
                                        >
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row" *ngIf="organization.ShowFinancing">
                              <div class="col-md-12 col-sm-12 col-xs-12">
                                <div class="form-group">
                                  <span class="ui-float-label">
                                    <input
                                      type="text"
                                      class="form-control"
                                      maxlength="500"
                                      id="FinancingUrl"
                                      #FinancingUrl="ngModel"
                                      name="FinancingUrl"
                                      pInputText
                                      [(ngModel)]="organization.FinancingUrl"
                                      required
                                    />
                                    <label for="text" class="form-control-label"
                                      >Financing Url
                                      <span class="text-red">*</span>
                                    </label>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12 col-sm-12 col-xs-12">
                                <div class="form-group">
                                  <span class="ui-float-label">
                                    <input
                                      disabled="true"
                                      type="text"
                                      class="form-control"
                                      id="WidgetURL"
                                      name="WidgetURL"
                                      pInputText
                                      [(ngModel)]="WidgetURL"
                                    />
                                    <label for="text" class="form-control-label"
                                      >Lead Widget
                                    </label>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12 col-sm-12 col-xs-12">
                                <div class="form-group">
                                  <span class="ui-float-label">
                                    <input
                                      class="form-control"
                                      maxlength="500"
                                      id="BudgetValues"
                                      #BudgetValues="ngModel"
                                      name="BudgetValues"
                                      pInputText
                                      [(ngModel)]="organization.BudgetValues"
                                    />
                                    <label for="text" class="form-control-label"
                                      >Lead Budget Values (seprated by
                                      semicolons.
                                      $100,000-$200,000;$500,000-$700,000;)
                                    </label>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 col-sm-12 col-xs-12">
                            <h3 class="pt">Notifications</h3>

                            <div class="row">
                              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div class="form-group">
                                  <div class="formfield">
                                    <div class="checkbox">
                                      <label class="k-form-field">
                                        <input
                                          type="checkbox"
                                          id="CustomerNotifications"
                                          name="CustomerNotifications"
                                          class="k-checkbox"
                                          [(ngModel)]="organization.CustomerNotifications"
                                        />
                                        <label
                                          class="k-checkbox-label"
                                          for="CustomerNotifications"
                                        >
                                          Send Customer Notification</label
                                        >
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                <div class="form-group">
                                  <div class="formfield">
                                    <div class="checkbox">
                                      <label class="k-form-field">
                                        <input
                                          type="checkbox"
                                          id="VendorNotifications"
                                          name="VendorNotifications"
                                          class="k-checkbox"
                                          [(ngModel)]="organization.VendorNotifications"
                                        />
                                        <label
                                          class="k-checkbox-label"
                                          for="VendorNotifications"
                                        >
                                          Send Contractor Notification</label
                                        >
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 col-sm-12 col-xs-12">
                            <div class="row">
                              <div class="col-md-12 col-sm-12">
                                <div>
                                  <h3 class="pt">Working days</h3>
                                  <div class="weekday">
                                    <ul
                                      class="list-inline list-unstyled padd-week"
                                    >
                                      <li>
                                        <input
                                          type="checkbox"
                                          id="monday"
                                          name="wd1"
                                          value="false"
                                          #workDay1="ngModel"
                                          [(ngModel)]="workDay[0]"
                                        />
                                        <label for="monday">Monday</label>
                                      </li>
                                      <li>
                                        <input
                                          type="checkbox"
                                          id="tuesday"
                                          name="wd2"
                                          value="false"
                                          #workDay2="ngModel"
                                          [(ngModel)]="workDay[1]"
                                        />
                                        <label for="tuesday">Tuesday</label>
                                      </li>
                                      <li>
                                        <input
                                          type="checkbox"
                                          id="wednessday"
                                          name="wd3"
                                          value="true"
                                          #workDay3="ngModel"
                                          [(ngModel)]="workDay[2]"
                                        />
                                        <label for="wednessday"
                                          >Wednesday</label
                                        >
                                      </li>
                                      <li>
                                        <input
                                          type="checkbox"
                                          id="thursday"
                                          name="wd4"
                                          value="true"
                                          #workDay4="ngModel"
                                          [(ngModel)]="workDay[3]"
                                        />
                                        <label for="thursday">Thursday</label>
                                      </li>
                                      <li>
                                        <input
                                          type="checkbox"
                                          id="friday"
                                          name="wd5"
                                          value="true"
                                          #workDay5="ngModel"
                                          [(ngModel)]="workDay[4]"
                                        />
                                        <label for="friday">Friday</label>
                                      </li>
                                      <li>
                                        <input
                                          type="checkbox"
                                          id="saturday"
                                          name="wd6"
                                          value="true"
                                          #workDay6="ngModel"
                                          [(ngModel)]="workDay[5]"
                                        />
                                        <label for="saturday">Saturday</label>
                                      </li>
                                      <li>
                                        <input
                                          type="checkbox"
                                          id="sunday"
                                          name="wd7"
                                          value="true"
                                          #workDay7="ngModel"
                                          [(ngModel)]="workDay[6]"
                                        />
                                        <label for="sunday">Sunday</label>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12 col-sm-12 col-xs-12">
                            <h3 class="pt mb-0">Working Hours</h3>
                            <div class="row mb-2">
                              <div class="col">
                                <label class="startEnd"
                                  >Start Time
                                  <span class="text-red">*</span>
                                </label>
                                <kendo-timepicker
                                  class="form-control"
                                  format="HH:mm"
                                  [(ngModel)]="startTime"
                                  name="startDate"
                                  #startDate="ngModel"
                                  required
                                ></kendo-timepicker>
                              </div>
                              <div class="col">
                                <label class="startEnd"
                                  >End Time
                                  <span class="text-red">*</span>
                                </label>
                                <kendo-timepicker
                                  class="form-control"
                                  format="HH:mm"
                                  [(ngModel)]="endTime"
                                  name="Endtime"
                                  #startDate="ngModel"
                                  required
                                >
                                </kendo-timepicker>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="h20"></div>
                    </ng-template>
                  </kendo-tabstrip-tab>
                  <kendo-tabstrip-tab [title]="'Cameras'" *ngIf="IsSuperAdmin">
                    <ng-template kendoTabContent>
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="rightcontent card orgcam">
                            <div class="x_panel">
                              <div class="x_content">
                                <div class="kendoheight customtable">
                                  <div class="">
                                    <form novalidate #myForm="ngForm">
                                      <kendo-grid
                                        [data]="gridView"
                                        [pageSize]="pageSize"
                                        [skip]="skip"
                                        [pageable]="true"
                                        (pageChange)="pageChange($event)"
                                        [sortable]="{ allowUnsort: allowUnsort,
                                                            mode: multiple ? 'multiple' : 'single'}"
                                        [sort]="sort"
                                        (sortChange)="sortChange($event)"
                                        (edit)="editHandler($event)"
                                        (cancel)="cancelHandler($event)"
                                        (save)="saveHandler($event)"
                                        (remove)="removeHandler($event)"
                                        (add)="addHandler($event)"
                                      >
                                        <ng-template kendoGridToolbarTemplate>
                                          <div
                                            class="headingwrap btnabs clearfix"
                                          >
                                            <div class="addcam">
                                              <span
                                                title="{{'common.addcCamera'|translate}}"
                                              >
                                                <div class="addbtn rightbtn">
                                                  <button
                                                    class="btn btn-info"
                                                    kendoGridAddCommand
                                                  >
                                                    <span class="addtxt"
                                                      >{{' Camera'}}</span
                                                    >
                                                    <i
                                                      aria-hidden="true"
                                                      class="fa fa-plus-circle"
                                                    ></i>
                                                  </button>
                                                </div>
                                              </span>
                                            </div>
                                            <div class="title_right hright">
                                              <div
                                                class="form-group pull-right top_search"
                                              >
                                                <div class="input-group">
                                                  <input
                                                    type="text"
                                                    class="inputS"
                                                    name="filterQuery"
                                                    [(ngModel)]="filterQuery"
                                                    (ngModelChange)="filterGrid()"
                                                    placeholder="{{'common.search_for'|translate}}"
                                                  />
                                                  <i class="fa fa-search"></i>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </ng-template>
                                        <kendo-grid-command-column width="90">
                                          <ng-template
                                            kendoGridCellTemplate
                                            let-isNew="isNew"
                                          >
                                            <div class="acButton">
                                              <button
                                                kendoGridSaveCommand
                                                [disabled]="myForm.invalid"
                                              >
                                                <span
                                                  title="{{'common.save'|translate}}"
                                                >
                                                  <i
                                                    class="fa fa-floppy-o"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                              </button>
                                              <button kendoGridCancelCommand>
                                                <span
                                                  title="{{'common.cancel'|translate}}"
                                                >
                                                  <i
                                                    class="fa fa-ban"
                                                    aria-hidden="true"
                                                  ></i>
                                                </span>
                                              </button>
                                            </div>
                                            <div class="dropdown cud">
                                              <i
                                                class="fa fa-ellipsis-v"
                                                id="menu1"
                                                data-toggle="dropdown"
                                              >
                                              </i>
                                              <ul
                                                class="dropdown-menu"
                                                role="menu"
                                                aria-labelledby="menu1"
                                              >
                                                <li kendoGridEditCommand>
                                                  <a class="edit" title="Edit">
                                                    <i
                                                      class="fa fa-pencil"
                                                      aria-hidden="true"
                                                    ></i>
                                                    {{'common.edit'|translate}}
                                                  </a>
                                                </li>
                                                <li kendoGridRemoveCommand>
                                                  <a title="Delete">
                                                    <i
                                                      class="fa fa-trash-o"
                                                    ></i>
                                                    {{'common.delete'|translate}}
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </ng-template>
                                        </kendo-grid-command-column>
                                        <kendo-grid-column
                                          field="SerialNumber"
                                          title="Serial Number"
                                          width="200"
                                        >
                                          <ng-template
                                            kendoGridEditTemplate
                                            let-dataItem="dataItem"
                                            let-isNew="isNew"
                                          >
                                            <span *ngIf="!isNew"
                                              >{{dataItem.SerialNumber}}</span
                                            >
                                            <kendo-combobox
                                              *ngIf="isNew"
                                              #CameraName="ngModel"
                                              [data]="availableCameraList"
                                              [valuePrimitive]="true"
                                              [filterable]="true"
                                              (filterChange)="filterCameras($event)"
                                              (valueChange)="selectionChange(dataItem)"
                                              [textField]="'CameraSerialNumber'"
                                              [(ngModel)]="dataItem.CameraId"
                                              name="CameraSerialNumber"
                                              [valueField]="'CameraId'"
                                              required
                                            ></kendo-combobox>
                                          </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column
                                          headerClass="gridHeading"
                                          class="gridRow"
                                          field="MACAddress"
                                          title="{{'camera.MAC_Addresss'|translate}}"
                                        >
                                          <ng-template
                                            kendoGridEditTemplate
                                            let-dataItem="dataItem"
                                          >
                                            {{dataItem.MACAddress}}
                                          </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column
                                          headerClass="gridHeading"
                                          class="gridRow"
                                          field="OrgCameraName"
                                          title="Org Camera Name"
                                        >
                                          <ng-template
                                            kendoGridEditTemplate
                                            let-dataItem="dataItem"
                                          >
                                            <input
                                              [(ngModel)]="dataItem.OrgCameraName"
                                              name="CameraName"
                                              class="k-textbox"
                                              maxlength="100"
                                              required
                                            />
                                          </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column
                                          headerClass="gridHeading"
                                          class="gridRow"
                                          field="JobName"
                                          title="{{'camera.job_name'|translate}}"
                                        >
                                        </kendo-grid-column>
                                        <kendo-grid-column
                                          headerClass="gridHeading"
                                          class="gridRow"
                                          field="Model"
                                          title="{{'camera.Model'|translate}}"
                                        >
                                          <ng-template
                                            kendoGridEditTemplate
                                            let-dataItem="dataItem"
                                          >
                                            {{dataItem.Model}}
                                          </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column
                                          headerClass="gridHeading"
                                          class="gridRow"
                                          field="FirewareVerion"
                                          title="{{'camera.current_fw'|translate}}"
                                        >
                                        </kendo-grid-column>
                                        <kendo-grid-column
                                          headerClass="gridHeading"
                                          class="gridRow"
                                          field="DateActivated"
                                          title="{{'camera.date_activated'|translate}}"
                                        >
                                          <ng-template
                                            kendoGridCellTemplate
                                            let-dataItem
                                          >
                                            {{dataItem.DateActivated | date:
                                            'mediumDate'}}
                                          </ng-template>
                                        </kendo-grid-column>
                                        <kendo-grid-column
                                          headerClass="gridHeading"
                                          class="gridRow"
                                          field="Active"
                                          title="{{'common.status'|translate}}"
                                          width="120"
                                        >
                                          <ng-template
                                            kendoGridCellTemplate
                                            let-dataItem
                                          >
                                            {{ dataItem.Active ? 'Active' :
                                            'Inactive' }}
                                          </ng-template>
                                        </kendo-grid-column>
                                      </kendo-grid>
                                    </form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </kendo-tabstrip-tab>
                  <kendo-tabstrip-tab [title]="'Billing'">
                    <ng-template kendoTabContent>
                      <add-gateway-accounts
                        [gatewayAccounts]="gatewayAccountList"
                        (gatewayAccountListChange)="onChangeGatewayAccounts($event)"
                      ></add-gateway-accounts>

                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <h3 class="pt">Billing Information</h3>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div class="form-group">
                            <span class="ui-float-label">
                              <input
                                [(ngModel)]="organization.TaxRate"
                                type="number"
                                class="form-control"
                                min="0.00"
                                max="30.00"
                                value="0.00"
                                placeholder="0.00"
                                id="TaxRate"
                                name="TaxRate"
                                pInputText
                              />
                              <label class="form-control-label"
                                >Default Tax Rate (%)
                                <span class="text-red">*</span>
                              </label>
                            </span>
                          </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <div class="form-group">
                            <kendo-floatinglabel
                              text="Default Billing Type"
                              [ngClass]="{'k-state-filled':true}"
                            >
                              <kendo-dropdownlist
                                [data]="DefaultBillingType"
                                [valuePrimitive]="true"
                                [(ngModel)]="organization.DrawScheduleBilling"
                                name="DrawScheduleBilling"
                                textField="name"
                                valueField="id"
                                #DrawScheduleBilling="ngModel"
                              ></kendo-dropdownlist>
                            </kendo-floatinglabel>
                          </div>
                        </div>
                      </div>

                      <edit-shipping-component
                        [shippingMethods]="orgShippingMethods"
                      ></edit-shipping-component>
                    </ng-template>
                  </kendo-tabstrip-tab>

                  <kendo-tabstrip-tab
                    *ngIf="hasQuickBooks"
                    [title]="'Integration'"
                  >
                    <ng-template kendoTabContent>
                      <organizations-integration
                        [organization]="organization"
                        (organizationChange)="organizationUpdated($event, orgForm)"
                      ></organizations-integration>
                    </ng-template>
                  </kendo-tabstrip-tab>

                  <kendo-tabstrip-tab [title]="'Legal'">
                    <ng-template kendoTabContent>
                      <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <h3 class="pt">Proposal Disclaimer</h3>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                          <div class="form-group">
                            <textarea
                              [(ngModel)]="organization.ProposalDisclaimer"
                              class="form-control mb0 text-area-scrollbar"
                              kendoTextBox
                              name="Description"
                              rows="10"
                              cols="50"
                              style="
                                width: 100%;
                                height: 80px !important;
                                resize: vertical;
                              "
                              floatLabelType="Auto"
                              type="text"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </kendo-tabstrip-tab>

                  <kendo-tabstrip-tab [title]="'Org Settings'">
                    <ng-template kendoTabContent>
                      <organization-settings></organization-settings>
                    </ng-template>
                  </kendo-tabstrip-tab>
                </kendo-tabstrip>
              </div>
              <div class="fixbtnwrap">
                <div class="btnwrap__center">
                  <button
                    type="button"
                    (click)="onSubmit(orgForm)"
                    class="btn darkbtn margin-right__md"
                    [disabled]="!orgForm.valid && !isPhotoSelected && !gatewayAccountsDirty && !integrationDirty"
                    *ngIf="orgForm.dirty || isPhotoSelected || gatewayAccountsDirty || integrationDirty"
                  >
                    <i class="fa fa-floppy-o" aria-hidden="true"></i>
                    {{btnText}}
                  </button>

                  <!-- <button type="button" (click)=" updateGatewayAccount()" class="btn darkbtn"
                              [disabled]="!orgForm.valid" *ngIf=" gatewayAccountsDirty">
                              <i class="fa fa-floppy-o" aria-hidden="true"></i>
                              Update Account</button> -->

                  <button
                    type="button"
                    class="btn btn bthpn2"
                    (click)="onCancel()"
                  >
                    {{'common.cancel'|translate}}
                  </button>
                </div>
              </div>
              <!-- <div class="fixbtnwrap" *ngIf="IsSuperAdmin">
                        <div class="btnwrap">
                           <button type="button" class="btn darkbtn" [disabled]="!orgForm.valid && !isPhotoSelected"
                              *ngIf="orgForm.dirty || isPhotoSelected" (click)="onSubmit(orgForm)">
                              <i class="fa fa-floppy-o" aria-hidden="true"></i> {{btnText}}</button>
                           <button type="button" class="btn btn-cancel"
                              (click)="onCancel()">{{'common.cancel'|translate}}</button>
                        </div>
                     </div> -->
            </form>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
