// tslint:disable:variable-name
export class AddressModel {
  Id: number = 0;
  CountryId: number = 1;
  StateProvinceId: number;
  City: string;
  Address1: string;
  Address2: string;
  ZipPostalCode: string;
  StateProvinceAbbreviation: string;
  StateProvince: StateProvinceModel;
  GateCode: string;
  ParcelCode: string;
  HasPets: boolean;
  Notes: string;
  LotNumber: string;

  constructor() {
    this.LotNumber = "";
    this.ParcelCode = "";
    this.ZipPostalCode = "";
    this.Address2 = "";
  }
}

export class StateProvinceModel {
  Id: number;
  Guid: string = "";
  Name: string;
  Abbreviation: string;

  constructor() {}
}

export class StateProvinceModelCore {
  guid: string;
  abbreviation: string;
  name: string;
  order: number;
  publishied: boolean;
}

export class AddressModelCore {
  guid: string;
  address1: string;
  address2: string;
  city: string;
  stateProvince: StateProvinceModelCore;
  stateProvinceAbbreviation: string;
  zipPostalCode: string;
  gateCode: string;
  parcelCode: string;
  hasPets: boolean;
  notes: string;
}
