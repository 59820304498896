import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";
import { environmentConstant } from "app/common/utility/environment";
import { Observable } from "rxjs";
import { PhaseModel } from "app/model/phaseModel";
import { Checklist } from "app/component/Checklist/Models/Checklist";
import { HttpClient } from "@angular/common/http";
import { environment } from "environments/environment";
@Injectable()
export class PhaseService {
  constructor(private httpService: HttpService, private http: HttpClient) {}

  getAllPhasesByOrgId(orgId: any): Observable<PhaseModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getPhaseByOrgId + orgId
    );
  }

  getAllPhasesByOrgIdNoAuth(orgId: any): Observable<PhaseModel[]> {
    const url = `${environment.baseUrl}${environmentConstant.getPhaseByOrgId}${orgId}`;
    return this.http.get<PhaseModel[]>(url);
  }

  getAllPhasesByOrgIdAndJobTemplateId(
    orgId: any,
    jobTemplateId: number
  ): Observable<PhaseModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getPhaseByOrgId + orgId + "/template/" + jobTemplateId
    );
  }
  getAllBasicPhasesByOrgId(orgId: any): Observable<PhaseModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getPhaseByOrgId + orgId + "/basic"
    );
  }
  getAllSysPhasesByOrgId(): Observable<PhaseModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getSysPhaseByOrgId
    );
  }
  getPhaseById(id: any): Observable<PhaseModel> {
    return this.httpService.getRequestService(
      environmentConstant.getPhaseById + id
    );
  }
  updatePhase(phase: PhaseModel) {
    return this.httpService.putRequestService(
      environmentConstant.updatePhase,
      phase
    );
  }
  savePhase(phase: PhaseModel) {
    return this.httpService.postRequestService(
      environmentConstant.savePhase,
      phase
    );
  }
  deletePhase(id: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.deletePhase + id
    );
  }
  linkPhaseAlert(phase: PhaseModel) {
    return this.httpService.postRequestService(
      environmentConstant.linkPhaseAlert,
      phase
    );
  }
  getPhaseByTradeId(tradeId: any): Observable<PhaseModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getPhaseByTradeId + tradeId
    );
  }
  getContractorByTradeId(tradeId: any) {
    return this.httpService.getRequestService(
      environmentConstant.getTradeById +
        tradeId +
        environmentConstant.getContractor
    );
  }
  getContractorByOrgId(orgId: any) {
    return this.httpService.getRequestService(
      environmentConstant.getCustomer +
        orgId +
        environmentConstant.getContractor
    );
  }
  getCompanyPersonalList(orgGuid: any) {
    return this.httpService.getRequestService(
      "customer/" + orgGuid + "/companypersonals"
    );
  }

  savePhaseChecklist(checklist: any, phaseId: any) {
    return this.httpService.post(
      "JobPhases/phaseChecklist/" + phaseId + "/checklists",
      checklist
    );
  }

  getPhaseChecklists(phaseId: any) {
    return this.httpService.get<Checklist[]>(
      "JobPhases/phaseChecklist/" + phaseId + "/checklists"
    );
  }

  deletePhaseChecklist(phaseId: any, checklistGuid) {
    return this.httpService.delete<any>(
      "JobPhases/phaseChecklist/" + phaseId + "/checklists/" + checklistGuid
    );
  }
}
