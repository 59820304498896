export class OrganizationModel {
  ID: string = "00000000-0000-0000-0000-000000000000";
  OrganizationName: string;
  Address: string;
  State: any;
  Zip: string;
  City: string;
  Phone: string;
  StreamingURL: string;
  StreamSessionDescriptionUrl: string;
  StreamApplicationId: string;
  Email: string;
  HeightIfImage: number;
  WidthIfImage: number;
  IsDefaultOrg: boolean = false;
  ParentOrgID: string;
  UseJobScheduling: boolean = true;
  SendInvoiceForDraws: boolean;
  CollectPaymentForDraws: boolean;
  OrgDrawScheduleId: number;
  NumOfLicences: number = 1;
  CostOfLicences: number = 0;
  Active: boolean = true;
  NOP_URl: string;
  UsingMPB: boolean;
  IsVendor: boolean;
  UsingService: boolean;
  LogoBlobId: number = 0;
  LogoUrl: string;
  ActivationCode: string = "";
  WorkingDays: string = "1111110";
  IsDemo: boolean;
  ShowHotDeals: boolean;
  ShowLiveStreaming: boolean;
  ShowFinancing: boolean;
  FinancingUrl: string;
  UsingPreSales: boolean;
  CustomerNotifications: boolean;
  VendorNotifications: boolean;
  PlayStoreUrl: string;
  AppleStoreUrl: string;
  BudgetValues: string;
  UseGoogleCalendar: boolean;
  EndTime: string;
  StatTime: string;
  TaxRate: number;
  DependentSchedulingMixedMode: boolean;
  ProposalDisclaimer: string;
  QBSyncInvoices: boolean;
  QBSyncProducts: boolean;
  QBSyncCustomers: boolean;
  QBSyncVendors: boolean;
  QBSyncEmployees: boolean;
  QBProductsImported: Date;
  QBCustomersImported: Date;
  QBVendorsImported: Date;
  QBEmployeesImported: Date;
  QBSyncBills: boolean;

  constructor() {}
}

export class BusinessUnitModel {
  ID: number;
  BusinessUnit1: string;
  OrgId: string;
  constructor() {}
}
