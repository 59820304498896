import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";
import { SharedService } from "app/common/utility/SharedService";
import { environmentConstant } from "app/common/utility/environment";
import {
  Checklist,
  ChecklistItem,
} from "app/component/Checklist/Models/Checklist";
import { ChecklistFilter, FilteredSearchModel } from "app/model/SearchModel";

@Injectable()
export class ChecklistService {
  constructor(private httpService: HttpService) {}

  getChecklistByOrg(orgGuid: string, queryParams: any = "") {
    return this.httpService.get<Checklist[]>(
      environmentConstant.coreApi.getChecklistByOrg(orgGuid, queryParams)
    );
  }
  getChecklistByGuid(guid: string) {
    return this.httpService.get<Checklist>(
      environmentConstant.coreApi.getChecklistByGuid(guid)
    );
  }
  postOrgChecklistSearch(orgGuid: string, model: FilteredSearchModel<any>) {
    return this.httpService.post(
      environmentConstant.coreApi.postOrgChecklistSearch(orgGuid),
      model
    );
  }
  postChecklistByOrg(orgGuid: string, model: Checklist) {
    return this.httpService.post(
      environmentConstant.coreApi.getChecklistByOrg(orgGuid),
      model
    );
  }
  deleteOrgChecklist(orgGuid: string, checklistGuid: string) {
    return this.httpService.delete(
      environmentConstant.coreApi.deleteOrgChecklist(orgGuid, checklistGuid)
    );
  }
  getChecklistItems(checklistGuid: string) {
    return this.httpService.get<ChecklistItem[]>(
      environmentConstant.coreApi.getChecklistItems(checklistGuid)
    );
  }
  postChecklist(checklistGuid: string, model: ChecklistItem) {
    return this.httpService.post(
      environmentConstant.coreApi.postChecklist(checklistGuid),
      model
    );
  }
  putChecklist(checklistGuid: string, model: Checklist) {
    return this.httpService.put(
      environmentConstant.coreApi.putChecklist(checklistGuid),
      model
    );
  }
  deleteChecklist(
    checklistGuid: string,
    applyJobs: boolean,
    applyJobPhases: boolean,
    applySettingsPhase: boolean
  ) {
    return this.httpService.delete(
      environmentConstant.coreApi.deleteChecklist(
        checklistGuid,
        applyJobs,
        applyJobPhases,
        applySettingsPhase
      )
    );
  }
  putChecklistItem(checklistItemGuid: string, model: ChecklistItem) {
    return this.httpService.put(
      environmentConstant.coreApi.putChecklistItem(checklistItemGuid),
      model
    );
  }

  putChecklistItemsOrder(orderedItemsGuids: string[]) {
    return this.httpService.put(
      environmentConstant.coreApi.reOrderChecklistItems(),
      orderedItemsGuids
    );
  }

  putChecklistItems(model: ChecklistItem[]) {
    return this.httpService.put(
      environmentConstant.coreApi.putChecklistItems(ChecklistItem),
      model
    );
  }

  deleteChecklistItem(
    checklistItemGuid: string,
    applyJobs,
    applyJobPhases,
    applySettingsPhase
  ) {
    return this.httpService.delete(
      environmentConstant.coreApi.deleteChecklistItem(
        checklistItemGuid,
        applyJobs,
        applyJobPhases,
        applySettingsPhase
      )
    );
  }
  getJobTemplateChecklists(jobTemplateGuid: string) {
    return this.httpService.get<Checklist[]>(
      environmentConstant.coreApi.getJobTemplateChecklists(jobTemplateGuid)
    );
  }
  postJobTemplateChecklists(jobTemplateGuid: string, model: Checklist) {
    return this.httpService.post(
      environmentConstant.coreApi.postJobTemplateChecklists(jobTemplateGuid),
      model
    );
  }
  deleteJobTemplateChecklist(
    jobTemplateGuid: string,
    checklistGuid: string,
    applyJob: boolean
  ) {
    return this.httpService.delete(
      environmentConstant.coreApi.deleteJobTemplateChecklist(
        jobTemplateGuid,
        checklistGuid,
        applyJob
      )
    );
  }
  getJobTemplatePhaseChecklists(jobPhaseTemplateGuid: string) {
    return this.httpService.get<Checklist[]>(
      environmentConstant.coreApi.getJobTemplatePhaseChecklists(
        jobPhaseTemplateGuid
      )
    );
  }
  postJobTemplatePhaseChecklists(
    jobPhaseTemplateGuid: string,
    model: Checklist
  ) {
    return this.httpService.post(
      environmentConstant.coreApi.postJobTemplatePhaseChecklists(
        jobPhaseTemplateGuid
      ),
      model
    );
  }
  deleteJobTemplatePhaseChecklist(
    jobTemplatePhaseGuid: string,
    checklistGuid: string,
    updatePhase: boolean
  ) {
    return this.httpService.delete(
      environmentConstant.coreApi.deleteJobTemplatePhaseChecklist(
        jobTemplatePhaseGuid,
        checklistGuid,
        updatePhase
      )
    );
  }
  getJobChecklists(jobGuid: string) {
    return this.httpService.get<Checklist[]>(
      environmentConstant.coreApi.getJobChecklists(jobGuid)
    );
  }
  putJobChecklist(jobGuid: string, checklistGuid: string, model: Checklist) {
    return this.httpService.put(
      environmentConstant.coreApi.putJobChecklist(jobGuid, checklistGuid),
      model
    );
  }
  postJobChecklists(jobGuid: string, model: Checklist) {
    return this.httpService.post<Checklist>(
      environmentConstant.coreApi.postJobChecklists(jobGuid),
      model
    );
  }
  deleteJobChecklist(jobGuid: string, checklistGuid: string) {
    return this.httpService.delete(
      environmentConstant.coreApi.deleteJobChecklist(jobGuid, checklistGuid)
    );
  }
  getJobPhaseChecklists(jobPhaseGuid: string) {
    return this.httpService.get<Checklist[]>(
      environmentConstant.coreApi.getJobPhaseChecklists(jobPhaseGuid)
    );
  }
  postJobPhaseChecklists(jobPhaseGuid: string, model: Checklist) {
    return this.httpService.post<Checklist>(
      environmentConstant.coreApi.postJobPhaseChecklists(jobPhaseGuid),
      model
    );
  }
  putJobPhaseChecklist(
    jobGuid: string,
    checklistGuid: string,
    model: Checklist
  ) {
    return this.httpService.put(
      environmentConstant.coreApi.putJobPhaseChecklist(jobGuid, checklistGuid),
      model
    );
  }
  deleteJobPhaseChecklist(jobPhaseGuid: string, checklistGuid: string) {
    return this.httpService.delete(
      environmentConstant.coreApi.deleteJobPhaseChecklist(
        jobPhaseGuid,
        checklistGuid
      )
    );
  }
  getChecklistByJob(jobGuid: string) {
    return this.httpService.get<Checklist[]>(
      environmentConstant.coreApi.getChecklistByJob(jobGuid)
    );
  }
}
