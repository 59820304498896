import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class EditJobService {
  private updateEvents = new BehaviorSubject<boolean>(false);
  telecast$ = this.updateEvents.asObservable();

  update() {
    this.updateEvents.next(true);
  }
}
