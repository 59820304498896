import { Component } from "@angular/core";
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";

export interface SchedulErrorModel {
  Scm: SchedulErrorDTO;
}
export class SchedulErrorDTO {
  StartDate: Date;
  EndDate: Date;
}
@Component({
  selector: "app-schedule-error-dialog",
  templateUrl: "./schedule-error-dialog.component.html",
  styleUrls: ["./schedule-error-dialog.component.scss"],
})
export class ScheduleErrorDialogComponent
  extends DialogComponent<SchedulErrorModel, SchedulErrorDTO>
  implements SchedulErrorModel {
  Scm: SchedulErrorDTO;
  title: string;
  message: string;
  constructor(dialogService: DialogService) {
    super(dialogService);
    // this.Scm = new SchedulErrorDTO();
    this.title = "Schedule Error";
    this.message =
      "Completed On date is earlier than you Started On Date. Update the value below";
  }
  Confirm() {
    if (
      new Date(this.Scm.StartDate.setHours(0, 0, 0, 0)) <=
      new Date(this.Scm.EndDate.setHours(0, 0, 0, 0))
    ) {
      this.result = this.Scm;
      this.close();
    }
  }
}
