import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class FileUploadService {
  private _uploadComplete = new Subject<null>();
  get uploadComplete() {
    return this._uploadComplete.asObservable();
  }
  set uploadComplete(val) {
    this._uploadComplete.next();
  }
  constructor() {}
}
