import { ChangeDetectorRef, Component } from "@angular/core";
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";

export interface ConfirmChecklistModel {
  title: string;
  message: string;
  toShow?: boolean;
  showJob?: boolean;
  showPhase?: boolean;
  showSettingsPhase?: boolean;
  handleJobPhase: boolean;
  handleJob: boolean;
  isNotification?: boolean;
}

@Component({
  selector: "checklist-dialog",
  templateUrl: "./checklist-dialog.component.html",
  styleUrls: ["./checklist-dialog.component.scss"],
})
export class ChecklistDialogComponent
  extends DialogComponent<
    ConfirmChecklistModel,
    { result: boolean; job: boolean; phase: boolean; settingsPhase: boolean }
  >
  implements ConfirmChecklistModel
{
  title: string;
  message: string;
  toShow?: boolean = false;
  showJob?: boolean = true;
  showPhase?: boolean = true;
  showSettingsPhase?: boolean = false;
  isNotification?: boolean = false;
  handleJobPhase: boolean = false;
  handleJob: boolean = false;
  handleSettingsPhase: boolean = false;
  changeDetectorRef: ChangeDetectorRef;
  // @Output() dialogData: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    dialogService: DialogService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(dialogService);
    this.changeDetectorRef = changeDetectorRef;
    const that = this;
    setTimeout(function () {
      that.changeDetectorRef.detectChanges();
    }, 300);
  }

  setJob(e) {
    this.handleJob = e;
  }
  setPhase(e) {
    this.handleJobPhase = e;
  }
  setSettingsPhase(e) {
    this.handleSettingsPhase = e;
  }
  confirm() {
    this.result = {
      result: true,
      job: this.handleJob,
      phase: this.handleJobPhase,
      settingsPhase: this.handleSettingsPhase,
    };

    this.close();
  }
  editSave() {
    this.result = {
      result: false,
      job: this.handleJob,
      phase: this.handleJobPhase,
      settingsPhase: this.handleSettingsPhase,
    };
    this.close();
  }
}
