import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";
import { environmentConstant } from "app/common/utility/environment";
import { Observable } from "rxjs";
import { TradeModel } from "app/model/tradeModel";
@Injectable()
export class TradeService {
  constructor(private httpService: HttpService) {}

  getAllTradesByOrgId(id: any): Observable<TradeModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getTrageByOrgId + id
    );
  }
  getTradeById(id: any): Observable<TradeModel> {
    return this.httpService.getRequestService(
      environmentConstant.getTradeById + id
    );
  }
  updateTrade(trade: TradeModel, id: any) {
    return this.httpService.putRequestService(
      environmentConstant.updateTrade + id,
      trade
    );
  }
  saveTrade(trade: TradeModel, id: any) {
    return this.httpService.postRequestService(
      environmentConstant.saveTrade + id,
      trade
    );
  }
  deleteTrade(id: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.deleteTrade + id
    );
  }
}
