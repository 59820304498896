import { Component, OnInit } from "@angular/core";
import { QuickBooksSearchComponent } from "./QuickBooksSearch.component";

@Component({
  selector: "quickbooks-search-employees",
  templateUrl: "./QuickBooksSearch.component.html",
  styleUrls: ["./QuickBooksSearch.css"],
})
export class QuickBooksSearchEmployeesComponent extends QuickBooksSearchComponent {
  public ngOnInit(): void {
    this.context = "employees";
    this.placeholder = "Search by first or last name";
    this.getStatus().subscribe((connected) => (this.connected = connected));
  }

  public onSearch(event: KeyboardEvent, input: any): void {
    super.onSearch(event, input);

    let subscription = this.organizationService
      .searchQuickBooksEmployees(this.search)
      .subscribe((response) => {
        if (response.success) {
          this.items = response.object.items;
        }
        this.didSearch = true;
        this.spinner.hide();
        subscription.unsubscribe();
      });
  }
}
