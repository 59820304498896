import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { BaThemeSpinner } from "app/theme/services";
import { CustomerModel, CustomerPrefrenceModel } from "app/model/customerModel";
import { CustomerService } from "app/common/services/customer.service";
import { MessageService } from "primeng/components/common/messageservice";
import { StateModel } from "app/model/statesModel";
import { AddressModel, StateProvinceModel } from "app/model/addressModel";
import { CustomerRoleModel } from "app/model/customerRoleModel";
import { CommonService } from "app/common/services/common.service";
import { environmentConstant } from "app/common/utility/environment";
import { SharedService } from "app/common/utility/SharedService";
import { TradeModel } from "app/model/tradeModel";
import { BusinessUnitModel } from "app/model/organizationModel";
import { QuickBooksDataModel } from "app/model/Core/QuickBooksDataModel";
import { SelectItem } from "primeng/primeng";
import { UserRoleEnum } from "app/model/Core/UserRoleEnum";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "add-Contact",
  templateUrl: "./contactAddEdit.html",
  styleUrls: ["./contactAddEdit.css"],
})
export class ContactAddEditComponent implements OnInit {
  isValidUserNameVal: boolean;
  isUserNameTaken: boolean;
  isValidUserNameText: string;
  userNameToEdit: string;
  isChecked = false;
  headerText: string;
  btnText: string;
  id: number;
  hideSaveButton: boolean = false;
  customer: CustomerModel;
  stateList: StateModel[];
  isSuperAdmin: boolean = false;
  originalStateList: StateModel[];
  customerRole: CustomerRoleModel[] = [];
  orgCustomerRoles: any[];
  selectState: StateModel = {
    Id: -1,
    Guid: "",
    CountryId: 0,
    DisplayOrder: 0,
    Name: "Select State",
    Abbreviation: "",
  };
  isContractor: boolean = false;
  isUser: boolean = false;
  currPath: any;
  content: any;
  lstTrade: TradeModel[] = [];
  listTrade: TradeModel[] = [];
  emailPattern = environmentConstant.emailPattern;
  lstBusinessUnit: BusinessUnitModel[] = [];
  lstOrignalBusinessUnit: BusinessUnitModel[] = [];
  isUsernameValid: boolean = true;
  employeesOpts: SelectItem[];
  isEmployee: boolean = false;
  @Input() uId: number;
  @Output() updateEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() isQuickAdd: boolean = false;
  @Input() BussinessUnitID: number = 0;
  @Input() type: string = "";
  @Input() noNavigation = false;
  @Input() employees: CustomerModel[] = [];
  isValidRole: boolean = false;

  zipValidate: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private _spinner: BaThemeSpinner,
    private customerService: CustomerService,
    private notificationsService: MessageService,
    private commonService: CommonService,
    private router: Router,
    private sharedService: SharedService,
    private location: Location,
    private toastrService: ToastrService
  ) {
    // this.currPath = location.prepareExternalUrl(location.path());
    // if (this.currPath.indexOf('users') !== -1) {
    //     this.isUser = true;
    //     this.content = "Employee";
    // } else {
    //     this.isContractor = true;
    //     this.content = "Opportunity";
    // }

    this.customer = new CustomerModel();
    this.customer.Address = new AddressModel();
    this.customer.Preference = new CustomerPrefrenceModel();
  }
  ngOnInit() {
    this._spinner.show();
    this.isSuperAdmin = this.sharedService.user.hasRole(
      UserRoleEnum.SuperAdmin
    );
    switch (this.type.toLocaleLowerCase()) {
      /*case "opportunity":
        this.isUser = false;
        this.isContractor = true;
        this.content = "Opportunity";
        break;*/
      case "employee":
        this.isEmployee = true;
        this.isContractor = false;
        this.isUser = true;
        this.content = "Employee";
        this.handleReportsToDropdown();
        break;
      case "customer":
        this.isContractor = true;
        this.isUser = false;
        this.content = "Customer";
        break;
    }
    if (this.isUser) {
      this.getRoles();
      this.getBusinessUnit();
    }

    this.getStatesList();
    this._spinner.hide();
  }

  getBusinessUnit() {
    this._spinner.show();
    this.commonService
      .getBusinessUnit(this.sharedService.selectedOrganization.ID)
      .subscribe((data) => {
        this.lstBusinessUnit = data;
        this.lstOrignalBusinessUnit = data;
        if (this.BussinessUnitID && this.uId === 0) {
          let bu = data.find((x) => x.ID == this.BussinessUnitID);
          if (bu) {
            this.customer.BusinessUnits = [];
            this.customer.BusinessUnits.push(bu);
          }
        }
      });
  }

  // getTrades(id) {
  //     this.tradeService.getAllTradesByOrgId(id).subscribe(
  //         (data) => {
  //             this.lstTrade = data;
  //             this.getStatesList();

  //             //this.listTrade = this.lstTrade.slice();
  //         });
  // }
  getStatesList() {
    this.commonService.getStates().subscribe((states) => {
      this.stateList = states;
      this.originalStateList = states;
      this.getCustomer();
    });
  }
  public filterState(filter: any): void {
    this.stateList = this.originalStateList.filter(
      (s) => s.Name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }
  public filterBussinessUnit(filter: any): void {
    this.lstBusinessUnit = this.lstOrignalBusinessUnit.filter(
      (s) => s.BusinessUnit1.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }
  getRoles() {
    this.commonService.getRoleByOrgId().subscribe((data) => {
      this.customerRole = data.filter(
        (item) => item.Id != 6 && item.Id != 1 && item.Id != 0
      );
      if (this.sharedService.isImpersonated) {
        if (!this.sharedService.selectedOrganization.IsVendor)
          this.customerRole = this.customerRole.filter((item) => item.Id != 3);
      } else {
        if (!this.sharedService.loggedInUser.Org.IsVendor) {
          this.customerRole = this.customerRole.filter((item) => item.Id != 3);
        }
      }

      this.orgCustomerRoles = this.customerRole;
      // this.getTrades(this.sharedService.selectedOrganization.ID);
      // this.getStatesList();
    });
  }
  public filterRoles(filter: any): void {
    this.customerRole = this.orgCustomerRoles.filter(
      (s) => s.Name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }

  getCustomer() {
    if (this.uId === 0) {
      this.headerText = "Add";
      this.btnText = "Save";
      this.customer.Address.StateProvinceId =
        this.commonService.getCurretnOrganizationStateId();
      this._spinner.hide();
    } else {
      this.customerService.getCustomerById(this.uId).subscribe((data) => {
        if (data) {
          //quickbooks deserialize hack
          if ((<any>data).QuickBooksData) {
            data.quickBooksData = <QuickBooksDataModel>{
              sync: (<any>data).QuickBooksData.Sync,
              identifier: (<any>data).QuickBooksData.Identifier,
            };
          }

          this.customer = data;
          this.userNameToEdit = this.customer.UserName;
          this.customer.Disable = !this.customer.Active;
          if (data.Address === undefined || data.Address === null) {
            this.customer.Address = new AddressModel();
          }
        }
        this._spinner.hide();
      });
      this.headerText = "Edit";
      this.btnText = "Update";
    }
  }

  onSubmit(form) {
    if (form.valid && !this.hideSaveButton) {
      this._spinner.show();
      this.hideSaveButton = true;
      const obj = this.customer;
      this.customer.Active = !this.customer.Disable;
      this.validateZipCode(this.customer.Address.ZipPostalCode);
      if (!this.zipValidate) {
        if (this.customer.Email)
          this.customer.Email = this.customer.Email.trim();
        this.customer.UserName = this.customer.UserName.trim();
        this.customer.Roles = this.customer.CustomerRoles.map((x) => x.Id);

        if (this.customer.Address) {
          let selectedStateProvince = new StateProvinceModel();
          selectedStateProvince.Id = this.customer.Address.StateProvinceId;
          let state = this.stateList.find(
            (y) => y.Id === this.customer.Address.StateProvinceId
          );

          if (state) {
            selectedStateProvince.Guid = state.Guid;
            this.customer.Address.StateProvince = selectedStateProvince;
          }
        }
        if (this.uId === 0) {
          const customerObj = new CustomerModel();
          obj.OrgId = this.sharedService.selectedOrganization.ID;
          // if(this.isContractor){
          //     obj.CustomerRoles = this.customerRole;
          // }
          this.customerService
            .saveCustomer(
              this.customer,
              this.sharedService.selectedOrganization.ID
            )
            .subscribe(
              (result) => {
                this.updateEvent.emit(true);
                this.result(result, " Added Successfully!!");
              },
              (error) => {
                this.error(error);
              }
            );
        } else {
          this.customerService.updateCustomerV2(this.customer).subscribe(
            (result) => {
              this.updateEvent.emit(true);
              this.result(result, " Updated Successfully!!");
            },

            (error) => {
              this.error(error);
            }
          );
        }
      } else {
        this._spinner.hide();
        this.notificationsService.add(environmentConstant.zipCodeError);
      }
    }
    this.hideSaveButton = false;
  }
  isValidEmail(Username, isValid) {
    var isFilled = false;
    if (Username && isValid && this.uId == 0 && Username.length != 0) {
      if (this.customer.UserName == undefined || this.customer.UserName == "") {
        this.customer.UserName = Username;
        isFilled = true;
      }

      if (isFilled) {
        this.customerService.isValidUserName(Username).subscribe(
          (data) => {},
          (error) => {
            if (error.status === 302) {
              this.isValidUserNameText = "This User Name already exists";
              this.isValidUserNameVal = true;
              this.isUsernameValid = false;
            } else {
              this.isValidUserNameText = "This is valid email";
              this.isValidUserNameVal = false;
              this.isUsernameValid = true;
            }
          }
        );
        isFilled = false;
      } else {
        this.isValidUserNameVal = undefined;
      }
    }
  }
  isValidUserName(Username, isValid) {
    if (
      !!Username &&
      isValid &&
      this.userNameToEdit != this.customer.UserName
    ) {
      this.customerService.isValidUserName(Username).subscribe(
        (data) => {},
        (error) => {
          if (error.status === 302) {
            this.isValidUserNameText = "This User Name already exists";
            this.isValidUserNameVal = true;
            this.isUserNameTaken = true;
          } else {
            this.isValidUserNameText = "This is valid email";
            this.isValidUserNameVal = false;
            this.isUsernameValid = true;
            this.isUserNameTaken = false;
          }
        }
      );
    } else {
      this.isValidUserNameVal = undefined;
    }
  }
  // createUserName(firstName,LastName){
  //     if(this.id == 0){
  //     if(firstName!=undefined || LastName!=undefined){
  //         this.customer.UserName= (Helper.ConvertToLower(((firstName ? firstName : '') + (LastName ? LastName : '')).split(" ").join(""))).replace(/[^\w\s]/gi, '');
  //     }
  // }
  // }
  private error(error: any) {
    switch (error.status) {
      case 409:
        //   this.notificationsService.add({
        //     severity: "warn",
        //     summary: this.content,
        //     detail: "This User Name or Email already exists",
        //   });
        this.toastrService.error("The user name is already in use");
        this._spinner.hide();
        break;
      case 409:
        // this.notificationsService.add({
        //   severity: "warn",
        //   summary: this.moduleName,
        //   detail: "Unable to save " + this.moduleName,
        // });
        this.toastrService.error("This User Name or Email already exists");
        this._spinner.hide();
        break;
      case 500:
        this.toastrService.error(error.error.Message);
        this._spinner.hide();
        this.updateEvent.emit(false);
        break;
      default:
        this.toastrService.error(error.message);
        this._spinner.hide();
        this.updateEvent.emit(false);
        break;
    }
  }
  onCancel() {
    this.updateEvent.emit(false);
    // this.location.back();
  }
  private result(res: any, message: string) {
    this.notificationsService.add({
      severity: "success",
      summary: this.content,
      detail: message,
    });
    this._spinner.hide();
    if (!this.BussinessUnitID && !this.noNavigation)
      if (this.isUser) {
        this.router.navigate(["pages", "contacts", "users"]);
      }
  }

  private handleReportsToDropdown() {
    this._spinner.show();
    this.customerService
      .getAllOrganizationContacts()
      .subscribe((data) => {
        this.employees = data;

        this.employeesOpts = this.employees
          .filter((x) => x.Id !== this.uId)
          .map((x) => ({ label: `${x.FirstName} ${x.LastName}`, value: x.Id }));
        this.employeesOpts.unshift({ label: "Select Employee", value: null });
      })
      .add(() => {
        this._spinner.hide();
      });
  }

  /**
   * Method for validate Zip Code values
   * @param zipCodeValue
   */
  validateZipCode(zipCodeValue) {
    if (zipCodeValue != "" && zipCodeValue) {
      environmentConstant.zipRegex.test(zipCodeValue)
        ? (this.zipValidate = false)
        : (this.zipValidate = true);
    } else {
      this.zipValidate = false;
    }
  }

  /**
   *
   * @param seletedRoles list of selected roles
   */
  validateSelection(seletedRoles: CustomerRoleModel[]): void {
    if (seletedRoles.length == 1 && seletedRoles[0].Id == UserRoleEnum.Chat) {
      this.isValidRole = false;
      return;
    }
    this.isValidRole = true;
  }
}
