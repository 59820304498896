import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, FormGroupDirective, FormControl } from "@angular/forms";
import { WorksheetDetailForModal } from "app/model/WorksheetDetailObject";
import { ProductSelectorMode } from "app/model/ProductSelectorModalModes";
import { ProductType } from "app/model/ProductType";
import { Helper } from "app/common/utility/helper";
import { ProjectPackageDetail } from "../../../model/ProjectPackageDetail";

@Component({
  selector: "app-product-qty-input",
  templateUrl: "./product-qty-input.component.html",
  styleUrls: ["./product-qty-input.component.scss"],
})
export class ProductQtyInputComponent implements OnInit, OnDestroy {
  @Input() product: WorksheetDetailForModal;
  @Input() parentProduct: WorksheetDetailForModal;
  @Input() rootObject: WorksheetDetailForModal;
  @Input() mode: ProductSelectorMode;
  @Input() packageDetail: ProjectPackageDetail;
  @Input() packageParentProduct: ProjectPackageDetail;
  form: FormGroup;
  showInput: boolean;
  showLabel: boolean;
  showIcon: boolean;
  alive: boolean = true;

  get ProductSelectorMode() {
    return ProductSelectorMode;
  }

  get ProductType() {
    return ProductType;
  }

  constructor(private rootFormGroup: FormGroupDirective) {}

  ngOnInit(): void {
    this.form = this.rootFormGroup.control;
    setTimeout(() => {
      this.checkInputValidity();
    });
    this.updateQtyInput();
    this.fixQty();

    // Subscribing to know when to update the inputQty
    this.product.updateQtyInput.subscribe((needsUpdate) => {
      if (needsUpdate) {
        this.updateQtyInput();
      }
    });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  /**
   * Updates the inputQty with the new information from the product
   */
  private updateQtyInput(): void {
    const productToUse = this.product.productOption || this.product.product;

    if (this.mode === ProductSelectorMode.Edit) {
      const usesCalculator =
        productToUse.productEstimateExt &&
        productToUse.productEstimateExt.materialsCalculatorId &&
        productToUse.productEstimateExt.useCalculator;

      const isNotEditable =
        this.product.kitDetailId && !this.product.kitDetail.editableQty;

      const needsManualQuantity =
        this.product.calculator &&
        this.product.calculator.hasErrorInMaterialCalculator &&
        this.product.calculator.hasErrorInMaterialCalculator.isDeleted;

      const usesKitCalculator =
        this.product.kitDetail && this.product.kitDetail.materialsCalculatorId;
      if (this.product.productOption && !usesCalculator && usesKitCalculator) {
        this.showOnlyInput();
      } else if ((usesCalculator || isNotEditable) && !needsManualQuantity) {
        this.showOnlyLabel();
      } else {
        this.showOnlyInput();
      }
    } else if (this.mode === ProductSelectorMode.Add) {
      const usesCalculator =
        (this.product.kitDetail &&
          this.product.kitDetail.materialsCalculatorId) ||
        (productToUse.productEstimateExt &&
          productToUse.productEstimateExt.materialsCalculatorId &&
          productToUse.productEstimateExt.useCalculator);

      const isEditableQty =
        this.product.kitDetail && this.product.kitDetail.editableQty;

      const usesKitCalculator =
        this.product.kitDetail && this.product.kitDetail.materialsCalculatorId;
      const productCalculator =
        productToUse.productEstimateExt &&
        productToUse.productEstimateExt.materialsCalculatorId &&
        productToUse.productEstimateExt.useCalculator;
      const isProduct =
        this.rootObject.product.productTypeId !== ProductType.ComplexKit &&
        this.rootObject.product.productTypeId !== ProductType.SimpleKit;

      if (
        this.product.productOption &&
        !productCalculator &&
        usesKitCalculator
      ) {
        this.showOnlyInput();
      } else if (usesCalculator) {
        this.showOnlyIcon();
      } else if (isEditableQty || isProduct) {
        this.showOnlyInput();
      } else {
        this.showOnlyLabel();
      }
    }
  }

  private showOnlyLabel(): void {
    this.showLabel = true;
    this.showIcon = false;
    this.showInput = false;
    this.form.get("productQty").setErrors(null);
  }

  private showOnlyIcon(): void {
    this.showIcon = true;
    this.showInput = false;
    this.showLabel = false;
  }

  private showOnlyInput(): void {
    this.showIcon = false;
    this.showInput = true;
    this.showLabel = false;
  }

  private fixQty(): void {
    this.product.qty = Helper.roundNumber(
      this.product.qty,
      this.product.product.unitOfMeasure.valueType
    );
  }

  checkInputValidity(): void {
    const productQty = this.form.get("productQty").value;
    if (productQty) {
      if (this.parentProduct.product.productTypeId === ProductType.SimpleKit) {
        const regexp = new RegExp(/^-?\d+$/);
        if (regexp.test(productQty)) {
          this.form.get("productQty").setErrors(null);
        }
      }
      if (this.parentProduct.product.productTypeId === ProductType.ComplexKit) {
        const regexp = new RegExp(/^\d+$/);
        if (regexp.test(productQty)) {
          this.form.get("productQty").setErrors(null);
        }
      }
    }
  }
}
