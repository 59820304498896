<div class="addpagewrap setup-pwd-pg">
  <div class="editjobtabform m0 hoverflow">
    <!-- <ba-card cardTitle="{{headerText}} Add Lead" baCardClass="with-scroll"> -->
    <div class="headingwrap clearfix">
      <h1>{{ userName }}</h1>
    </div>
    <h3 class="rs">Reset Password</h3>
    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="formfield">
          <span>
            <label class="radio-inline custom-radio nowrap">
              <input
                type="radio"
                name="radio"
                id="Inactive"
                [(ngModel)]="isExisting"
                [value]="true"
              />
              <span>Reset Manually</span>
            </label>
          </span>
          <span class="lm30">
            <label class="radio-inline custom-radio nowrap">
              <input
                type="radio"
                name="radio"
                id="Status"
                (click)="changeReset()"
              />
              <span>Email Password Reset Link</span>
            </label>
          </span>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <br />
    <form #myForm="ngForm" *ngIf="isExisting" (ngSubmit)="onSubmit(myForm)">
      <div class="row">
        <!-- <div class="col-md-12"><p><span class="text-red">*</span>Your Password must be 6-8 characters long.</p></div> -->
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <div class="form-group">
            <span class="ui-float-label">
              <input
                type="password"
                [pattern]="passwordPattern"
                class="form-control"
                pInputText
                minlength="6"
                name="NewPassword"
                #NewPassword="ngModel"
                [ngClass]="{
                  'has-error':
                    NewPassword.pattern &&
                    (NewPassword.dirty || NewPassword.touched)
                }"
                [(ngModel)]="resetPassword.NewPassword"
                (ngModelChange)="onNewPasswordChange($event)"
                required
              />
              <span
                class="form-msg"
                [ngClass]="!NewPassword.valid ? 'red' : 'green'"
              >
                <span [hidden]="!NewPassword.valid">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </span>
                <span [hidden]="NewPassword.valid || NewPassword.pristine">
                  <i class="fa fa-exclamation" aria-hidden="true"></i>
                </span>
              </span>
              <label for="text" class="form-control-label"
                >New Password
                <span class="text-red">*</span>
              </label>
              <div
                [hidden]="NewPassword.valid || NewPassword.pristine"
                class="alert alert-danger z-index-9 position-absolute p-2"
              >
                {{ passValidationMes }}
              </div>
            </span>
          </div>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6">
          <div class="form-group">
            <span class="ui-float-label">
              <input
                type="password"
                [pattern]="passwordPattern"
                class="form-control"
                pInputText
                minlength="6"
                name="OldPassword"
                #OldPassword="ngModel"
                [ngClass]="{
                  'has-error':
                    (OldPassword.pattern &&
                      (OldPassword.dirty || OldPassword.touched)) ||
                    resetPassword.OldPassword != resetPassword.NewPassword
                }"
                [(ngModel)]="resetPassword.OldPassword"
                (ngModelChange)="matchPassword($event)"
                required
              />
              <label for="text" class="form-control-label"
                >Confirm Password
                <span class="text-red">*</span>
              </label>
              <!-- <div [hidden]="OldPassword.valid || OldPassword.pristine"
                          class="alert alert-danger">
                          Confirm Password must be 6-8 characters long including uppercase/lowercase or numbers
                          </div> -->
              <div
                *ngIf="
                  resetPassword.OldPassword &&
                  resetPassword.OldPassword != resetPassword.NewPassword
                "
                class="alert alert-danger z-index-9 position-absolute p-2"
              >
                Password and confirm password does not match
              </div>
            </span>
          </div>
        </div>
      </div>

      <div class="fixbtnwrap">
        <div class="btnwrap">
          <button
            type="submit"
            class="btn darkbtn"
            [disabled]="!myForm.valid || isMatched"
            *ngIf="myForm.dirty"
          >
            Reset
          </button>
          <button type="button" class="btn btn-cancel" (click)="onCancel()">
            Close
          </button>
        </div>
      </div>
    </form>
    <div *ngIf="!isExisting">
      <div class="row">
        <h3 class="col-md-12">{{ systemResetMsg }}</h3>
      </div>
      <div class="fixbtnwrap">
        <div class="btnwrap">
          <button type="submit" class="btn darkbtn" (click)="systemReset()">
            Reset
          </button>
          <button type="button" class="btn btn-cancel" (click)="onCancel()">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
