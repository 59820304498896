<p-toast class="general-toast" position="top-right"></p-toast>
<div class="widgets commonFixedSidebar" *ngIf="!IsDashboard">
  <div class="row">
    <div class="col-md-12 ht100">
      <div class="rightcontent">
        <div class="sideBarHeader" *ngIf="IsHeaderEnable">
          <h1>Notes{{ jobNameDisplay }}</h1>
        </div>
        <div class="tabBlk">
          <div
            class="fixed-layout"
            [ngClass]="!IsHeaderEnable ? 'tabnotes-blk' : ' '"
          >
            <div class="list-unstyled div-scroll">
              <div class="mb-3">
                <div>
                  <form
                    #jobnote="ngForm"
                    novalidate
                    (ngSubmit)="onSubmit(jobnote)"
                    *ngIf="jobNote != undefined"
                    class="form-horizontal"
                  >
                    <input
                      [appFocus]="true"
                      type="text"
                      class="form-control mb-2"
                      placeholder="Title"
                      id="NoteTitle"
                      name="NoteTitle"
                      [(ngModel)]="jobNote.NoteTitle"
                      #NoteTitle="ngModel"
                      maxlength="250"
                      required
                      [ngClass]="{
                        'has-error':
                          NoteTitle.invalid &&
                          (NoteTitle.dirty || NoteTitle.touched)
                      }"
                    />
                    <!-- <p-editor
                      [style]="{
                        'background-color': '#fff',
                        'min-height': '120px'
                      }"
                      id="Note"
                      name="Note"
                      #Note="ngModel"
                      placeholder="Comment"
                      [ngClass]="{
                        'has-error':
                          Note.invalid && (Note.dirty || Note.touched)
                      }"
                      [(ngModel)]="jobNote.Note"
                      required
                    ></p-editor> -->
                    <editor
                      [init]="editorConfig"
                      [ngStyle]="{
                        'background-color': '#fff',
                        'min-height': '120px'
                      }"
                      #jobNotesEditor
                      name="Note"
                      #Note="ngModel"
                      [ngClass]="{
                        'has-error':
                          Note.invalid && (Note.dirty || Note.touched)
                      }"
                      [(ngModel)]="jobNote.Note"
                      required
                    ></editor>
                    <div
                      class="d-flex justify-content-between note-checkbox-height"
                    >
                      <div class="d-flex">
                        <div class="medianame" title="{{ loggedInUser }}">
                          {{ getInitals(loggedInUser) }}
                        </div>
                        <div class="form-group ml-2 mt-2">
                          <label class="checkbox-inline custom-checkbox nowrap">
                            <input
                              type="checkbox"
                              name="public"
                              [(ngModel)]="jobNote.CustomerCanView"
                            />
                            <span>Share With Customer</span>
                          </label>
                        </div>
                        <div class="form-group ml-2 mt-2">
                          <label class="checkbox-inline custom-checkbox nowrap">
                            <input
                              type="checkbox"
                              name="public"
                              [(ngModel)]="jobNote.ContractorCanView"
                            />
                            <span>Share With Contractor</span>
                          </label>
                        </div>
                      </div>
                      <div class="sendbtn-blk">
                        <button
                          type="submit"
                          [disabled]="
                            !jobnote.valid ||
                            (!jobNote.Note ? 0 : jobNote.Note.length) > 10000000
                          "
                          title="{{
                            (!jobNote.Note ? 0 : jobNote.Note.length) > 10000000
                              ? 'Note size is limited to 10 MB'
                              : ''
                          }}"
                        >
                          <i class="fa fa-paper-plane"></i>
                        </button>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between">
                      <div class="d-flex">
                        <div
                          class="form-group ml-2 mt-2"
                          *ngIf="!JobPhaseId && !isSales"
                        >
                          <label
                            class="checkbox-inline custom-checkbox nowrap margin-left-22"
                          >
                            <input
                              type="checkbox"
                              name="public"
                              [(ngModel)]="jobNote.Shared"
                            />
                            <span>Shared With Phases</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="">
                <div class="mb-3" *ngFor="let note of lstNotes">
                  <app-job-note
                    [note]="note"
                    (deleted)="deleteJobNote(note.Guid)"
                    [isSales]="isSales"
                  ></app-job-note>
                  <br /><br /><br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="list-unstyled" *ngIf="IsDashboard">
  <div class="mb-3">
    <div>
      <form
        #jobnote="ngForm"
        novalidate
        (ngSubmit)="onSubmit(jobnote)"
        *ngIf="jobNote != undefined"
        class="form-horizontal"
      >
        <input
          type="text"
          class="form-control mb-2"
          placeholder="Title"
          id="NoteTitle"
          name="NoteTitle"
          [(ngModel)]="jobNote.NoteTitle"
          #NoteTitle="ngModel"
          maxlength="250"
          required
          [ngClass]="{
            'has-error':
              NoteTitle.invalid && (NoteTitle.dirty || NoteTitle.touched)
          }"
        />
        <!-- <p-editor
          [style]="{ 'background-color': '#fff', 'min-height': '120px' }"
          id="Note"
          name="Note"
          #Note="ngModel"
          placeholder="Comment"
          [ngClass]="{
            'has-error': Note.invalid && (Note.dirty || Note.touched)
          }"
          [(ngModel)]="jobNote.Note"
          required
        ></p-editor> -->
        <editor
          [init]="editorConfig"
          [ngStyle]="{ 'background-color': '#fff', 'min-height': '120px' }"
          #jobNotesEditor
          name="Note"
          #Note="ngModel"
          [ngClass]="{
            'has-error': Note.invalid && (Note.dirty || Note.touched)
          }"
          [(ngModel)]="jobNote.Note"
          required
        ></editor>
        <div class="d-flex justify-content-between" style="height: 35px">
          <div class="d-flex">
            <div class="medianame" title="{{ loggedInUser }}">
              {{ getInitals(loggedInUser) }}
            </div>
            <div class="form-group ml-2 mt-2">
              <label class="checkbox-inline custom-checkbox nowrap">
                <input
                  type="checkbox"
                  name="public"
                  [(ngModel)]="jobNote.CustomerCanView"
                />
                <span>Share With Customer</span>
              </label>
            </div>
            <div class="form-group ml-2 mt-2" *ngIf="!JobPhaseId && !isSales">
              <label class="checkbox-inline custom-checkbox nowrap">
                <input
                  type="checkbox"
                  name="public"
                  [(ngModel)]="jobNote.ContractorCanView"
                />
                <span>Share With Contractor</span>
              </label>
            </div>
          </div>
          <div class="sendbtn-blk">
            <button
              type="submit"
              [disabled]="
                !jobnote.valid ||
                (!jobNote.Note ? 0 : jobNote.Note.length) > 10000000
              "
              title="{{
                (!jobNote.Note ? 0 : jobNote.Note.length) > 10000000
                  ? 'Note size is limited to 10 MB'
                  : ''
              }}"
            >
              <i class="fa fa-paper-plane"></i>
            </button>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div class="d-flex">
            <div class="form-group ml-2 mt-2" *ngIf="!JobPhaseId && !isSales">
              <label
                class="checkbox-inline custom-checkbox nowrap margin-left-22"
              >
                <input
                  type="checkbox"
                  name="public"
                  [(ngModel)]="jobNote.Shared"
                />
                <span>Shared With Phases</span>
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="mb-3" *ngFor="let note of lstNotes">
    <app-job-note
      [note]="note"
      (deleted)="deleteJobNote(note.Guid)"
      [isSales]="isSales"
    ></app-job-note>
  </div>
</div>
