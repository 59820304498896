import { EstimateBudgetActual } from "./EstimateBudgetActual";
import { EstimateProductModel } from "./EstimateProductModel";
import { MaterialCalculator } from "./MaterialsCalculator";
import { WorksheetDetailChange } from "./WorksheetDetailChange";

export class EstimateWorksheetProduct {
  id?: number;
  product: EstimateProductModel;
  productOption: EstimateProductModel;
  qty: number;
  unitPrice: number;
  kitProductId?: number;
  kitProduct?: EstimateProductModel;
  requiresUserSelection?: boolean;
  actualProductSelectionId?: number;
  materialsCalculatorId?: number;
  projectPackageDetailId?: number;
  deletedOn?: Date;
  productOptionId?: number;
  lastUpdatedBy?: string;
  createdBy?: string;
  previousQty?: number;
  totalCost?: number;
  totalCostActuals?: number;
  worksheetDetailChange?: WorksheetDetailChange;
  totalToShow?: string;
  unitPriceToShow?: string;
  estimateBudgetActual?: EstimateBudgetActual;
  estimateBudgetCost?: number;
  showItemLink?: boolean;
  complexKitId?: number;
  complexKitProduct?: EstimateProductModel;
  qtyKit?: number;
  simpleKitId?: number;
  simpleKitQty?: SimpleKitQty[];
  complexKitQty?: number;
  unitQty?: number;

  needsRecalculation?: boolean;
  // Calculated field, when the associated alculator is deleted
  needsManualQuantity?: boolean;
  simpleKits?: SimpleKitTree[];
  items?: EstimateWorksheetProduct[];
  materialsCalculator?: MaterialCalculator;
  needsParameterManual?: boolean;
  showPricing?: boolean;
  preferredVendorPrice?: number;
  preferredVendorPriceBookDetailId?: number;
  budgetVendorPrice?: number;
  budgetVendorPriceBookDetailId?: number;
  editableQty?: boolean;
}

export class WorksheetCountItems {
  typeGrid: number;
  numberItems: number;
}

export class SimpleKitTree {
  parent: EstimateProductModel;
  children: EstimateWorksheetProduct[];
}
export class SimpleKitQty {
  id: number;
  qty: number;
}

export enum WorksheetTabsTypes {
  Current = 1,
  Added = 2,
  Deleted = 3,
  UserSelection = 4,
  Ajustments = 5,
  PriceChanges = 6,
  Optionals = 7,
}

export class WorsheetDetailItem {
  worhseetDetailId: number;
  productId: number;
  complexKitId: number;
  simpleKitId: number;
}
