import { FormControl } from "@angular/forms";
export class ValidateProductQtys {
  /**
   * Validates if the formControl has a value of zero.
   * If there is no calculator and qty is 0, the value is invalid
   * @param materialCalculatorId
   * @returns Validator Function
   */
  static checkIfQtyIsZero(materialCalculatorId: number) {
    const fn = (control: FormControl) => {
      if (
        (!materialCalculatorId && control.value === 0) ||
        control.value === "0"
      ) {
        return { invalidValue: true };
      }

      return null;
    };

    return fn.bind(this);
  }
}
