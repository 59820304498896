import { FormGroup } from "@angular/forms";
import { EstimateProductModel } from "./EstimateProductModel";
import { EstimateWorksheetProduct } from "./EstimateWorksheetProduct";
import { MaterialCalculator } from "./MaterialsCalculator";
import { ProductOption } from "./ProductOptionModel";
import { WorksheetDetailChange } from "./WorksheetDetailChange";

export interface Kit {
  id: number;
  kitProduct: EstimateProductModel;
  kitPartProductDetails: KitPartProductDetail[];
  kitPartSimpleKitDetails: KitPartSimpleKitDetail[];
  kitGroupDetails: KitGroupDetail[];
}

export interface KitPartProductDetail {
  id: number;
  kitPartProduct: EstimateProductModel;
  qty: number;
  materialsCalculatorId?: number;
  displayOrder: number;
  kitGroupDetail: KitGroupDetail;
  productOption?: EstimateProductModel;
  editableQty?: boolean;
  showPricing: boolean;
  materialsCalculator?: MaterialCalculator;

  // Calculated field. True when the calculator has been deleted and needs a manual qty
  needsManualQuantity?: boolean;
}

export interface KitPartSimpleKitDetail {
  id: number;
  kitPartSimpleKit: Kit;
  qty: number;
  materialsCalculatorId?: number;
  displayOrder: number;
  kitGroupDetail: KitGroupDetail;
  editableQty?: boolean;
  showPricing: boolean;
  materialsCalculator?: MaterialCalculator;

  // Calculated field. True when the calculator has been deleted and needs a manual qty
  needsManualQuantity?: boolean;
}

export interface KitGroupDetail {
  id: number;
  kitGroup: KitGroup;
  displayOrder: number;
}

export interface KitGroup {
  id: number;
  groupName: string;
  displayOrder: number | string;
  createdOn?: Date;
  deletedOn?: Date;
  description?: string;
  isActive?: boolean;
}

export interface KitGroupRowOntable {
  data: KitGroup;
  edit: boolean;
  form?: FormGroup;
  isInCreationProcess: boolean;
}

export interface UpdateKitGroupDTO {
  groupName: string;
  displayOrder: number;
  description: string;
}

/**
 * extension for kits
 */
export class KitExtension {
  static getWorksheetDetailChange(
    isEstimate: boolean,
    products: EstimateWorksheetProduct[],
    productId: number
  ): WorksheetDetailChange {
    if (!isEstimate) return null;
    if (products.length === 0) {
      return {} as WorksheetDetailChange;
    }

    const detailChange: WorksheetDetailChange = products.find(
      (x) => x.product.id == productId
    ).worksheetDetailChange;
    if (!detailChange) return {} as WorksheetDetailChange;

    return detailChange;
  }

  /**
   * Used to get the worksheet Detail Change of a product inside a complex Kit
   * @param isEstimate
   * @param products
   * @param productId
   * @param complexId
   * @param simpleKitId
   * @returns The WorksheetDetailChange Object of the product
   */
  static getWorksheetDetailChangeForComplex(
    isEstimate: boolean,
    products: EstimateWorksheetProduct[],
    productId: number,
    complexId: number,
    simpleKitId: number | null
  ): WorksheetDetailChange {
    if (!isEstimate) return null;
    if (products.length === 0) {
      return {} as WorksheetDetailChange;
    }

    const detailChange: EstimateWorksheetProduct = products.find(
      (x) =>
        x.product.id === productId &&
        x.complexKitId === complexId &&
        x.simpleKitId === simpleKitId
    );

    if (!detailChange) return {} as WorksheetDetailChange;

    return detailChange.worksheetDetailChange;
  }

  static getWorksheetDetailPreviousQty(
    isEstimate: boolean,
    products: EstimateWorksheetProduct[],
    productQty: number,
    productId: number
  ): number {
    if (!isEstimate) return 0;

    let qty: number = products.find((x) => x.product.id == productId).qty;
    if (!qty) return 0;

    return productQty === qty ? 0 : qty;
  }

  /**
   * Used to get the previous qty of a product inside a complex kit
   * @param isEstimate
   * @param products
   * @param productQty
   * @param productId
   * @param complexKitId
   * @param simpleKitId
   * @returns The previous qty
   */
  static getWorksheetDetailPreviousQtyForComplex(
    isEstimate: boolean,
    products: EstimateWorksheetProduct[],
    productQty: number,
    productId: number,
    complexKitId: number,
    simpleKitId: number | null
  ): number {
    if (!isEstimate) return 0;

    let p: EstimateWorksheetProduct = products.find(
      (x) =>
        x.product.id == productId &&
        x.complexKitId === complexKitId &&
        x.simpleKitId === simpleKitId
    );

    if (p) {
      return productQty === p.qty ? 0 : p.qty;
    }
    return 0;
  }
}

export interface ProductQuantity {
  id: number;
  qty: number;
}
