<div class="rightcontent">
  <div class="headingwrap clearfix">
    <h1>Change Password</h1>
    <div class="title_right hright"></div>
  </div>
  <div class="changepwd-account">
    <form
      class="form-horizontal mt10"
      #myForm="ngForm"
      (ngSubmit)="onSubmit(myForm)"
    >
      <div class="btnwrap fullscreenbtn">
        <button
          type="submit"
          class="btn darkbtn"
          [disabled]="!myForm.valid || isMatched"
        >
          Update
        </button>
        <button type="button" (click)="onCancel()" class="btn btn-cancel">
          Close
        </button>
      </div>
      <div class="row">
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="form-group">
            <span class="ui-float-label">
              <input
                type="password"
                class="form-control"
                id="oldPassword"
                name="oldPassword"
                #OldPassword="ngModel"
                [(ngModel)]="changePassword.OldPassword"
                [ngClass]="{'has-error':OldPassword.invalid && (OldPassword.dirty || OldPassword.touched)}"
                maxlength="50"
                pInputText
                required
              />
              <label for="text" class="form-control-label"
                >Old Password
                <span class="text-red">*</span>
              </label>
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="form-group">
            <span class="ui-float-label">
              <input
                type="password"
                class="form-control"
                id="NewPassword"
                name="NewPassword"
                pInputText
                [pattern]="passwordPattern"
                #NewPassword="ngModel"
                [(ngModel)]="changePassword.NewPassword"
                [ngClass]="{'has-error':NewPassword.pattern && (NewPassword.dirty || NewPassword.touched)}"
                (ngModelChange)="matchPassword(confirmPassword.confirmPassword)"
                minlength="6"
                required
              />
              <span
                class="form-msg"
                [ngClass]="!NewPassword.valid?'red':'green'"
              >
                <span [hidden]="!NewPassword.valid">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </span>
                <span [hidden]="NewPassword.valid || NewPassword.pristine">
                  <i class="fa fa-exclamation" aria-hidden="true"></i>
                </span>
              </span>
              <label for="text" class="form-control-label"
                >New Password
                <span class="text-red">*</span>
              </label>
              <div
                [hidden]="NewPassword.valid || NewPassword.pristine"
                class="alert alert-danger z-index-9 position-absolute p-2"
              >
                {{passValidationMes}}
              </div>
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-sm-6 col-xs-12">
          <div class="form-group">
            <span class="ui-float-label">
              <input
                type="password"
                class="form-control"
                id="confirm-password"
                name="Comfirm Password"
                #confirmPassword="ngModel"
                (ngModelChange)="matchPassword($event)"
                [(ngModel)]="confirmPassModel.confirmPassword"
                pInputText
                [ngClass]="{'has-error': (confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched))|| (confirmPassModel.confirmPassword!=changePassword.NewPassword)}"
                required
              />
              <label for="text" class="form-control-label"
                >Confirm Password
                <span class="text-red">*</span>
              </label>
              <div
                *ngIf="confirmPassModel.confirmPassword && confirmPassModel.confirmPassword!=changePassword.NewPassword"
                class="alert alert-danger z-index-9 position-absolute p-2"
              >
                Password and confirm password does not match
              </div>
            </span>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
