import { Router } from "@angular/router";
import { SettingService } from "../services/settings.service";
import { SharedService } from "./SharedService";
import { UserRoleEnum } from "app/model/Core/UserRoleEnum";

export function RedirectDashboardUrl(
  settingService: SettingService,
  sharedService: SharedService,
  router: Router,
  fromGuard: boolean = false
): void | number {
  const hideWipAreasKey = "HIDE_WIP_AREAS";
  const hideSalesAreasKey = "HIDE_SALES_AREAS";

  let configObj = sharedService.loggedInUser.CustomerRoles;
  let hasAdminRole = configObj.some(
    (x) =>
      x.Id === UserRoleEnum.Admin ||
      x.Id === UserRoleEnum.SuperAdmin ||
      x.Id === UserRoleEnum.Executive
  );

  let hasSalesRole = configObj.some(
    (x) =>
      x.Id === UserRoleEnum.SalesRep ||
      x.Id === UserRoleEnum.SalesManager ||
      x.Id === UserRoleEnum.SalesContact
  );

  if (hasAdminRole) hasSalesRole = false;

  const hideWipAreas = settingService.getBooleanValue(
    sharedService.organizationSettings,
    hideWipAreasKey,
    false
  );

  const hideSalesAreas = settingService.getBooleanValue(
    sharedService.organizationSettings,
    hideSalesAreasKey,
    false
  );

  if (!fromGuard) {
    if (!hideSalesAreas && hasSalesRole) {
      router.navigate(["pages/dashboards/wipdashboard/1"]);
      return;
    }
    if (!hideWipAreas && hideSalesAreas && !hasAdminRole) {
      router.navigate(["pages/dashboards/wipdashboard/2"]);
      return;
    }
    if (hideWipAreas && !hideSalesAreas && !hasAdminRole && hasSalesRole) {
      router.navigate(["pages/dashboards/wipdashboard/1"]);
    } else {
      router.navigate(["pages/dashboards/wipdashboard/2"]);
      return;
    }
    return;
  }

  return RedirectDashboardUrlEnum.All;
}

export enum RedirectDashboardUrlEnum {
  Wip = 2,
  Sales = 1,
  All = 3,
}
