import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ImageViewerService } from "app/common/services/image-viewer.service";
import { JobPhotoModel } from "app/model/jobPhotoModel";

@Component({
  selector: "app-photo-grid",
  templateUrl: "./photo-grid.component.html",
  styleUrls: ["./photo-grid.component.scss"],
})
export class PhotoGridComponent implements OnInit {
  private _photos: JobPhotoModel[];
  @Input()
  set photos(values: JobPhotoModel[]) {
    this._photos = values;
    if (!values) {
      return;
    }
    this.imageUrls = this._photos.map((p) => p.BlobUrl);
  }
  get photos() {
    return this._photos;
  }
  @Input() pageSize: number;
  @Input() lazy: boolean = false;
  @Input() totalRecords: number;
  @Input() enableDelete: boolean = true;
  @Input() enableAnnotation: boolean = true;
  @Input() enableSelection: boolean = true;
  @Input() enableDetails: boolean = true;
  @Input() enablePinning: boolean = true;
  @Input() jobId: string;
  @Input() isWorkOrder: boolean = false;
  @Input() isSideBar: boolean = false;
  private _selectAll = false;
  @Input()
  set selectAll(value: boolean) {
    this._selectAll = value;
    if (!this._photos) {
      return;
    }
    this._photos.forEach((p) => (p.selected = value));
  }
  get selectAll() {
    return this._selectAll;
  }

  @Output() onLazyLoad = new EventEmitter<any>();
  @Output() refresh = new EventEmitter<void>();
  @Output() onSelectedChanged = new EventEmitter();
  @Output() onDelete = new EventEmitter<JobPhotoModel>();

  showCarousel: boolean = false;
  currentIndex: number;
  imageUrls: string[];
  displayPreview: boolean = false;

  constructor(private imageViewerService: ImageViewerService) {}

  ngOnInit() {
    this.enablePinning = !this.isWorkOrder;
  }

  // oto show casousel
  toggleChild(index) {
    this.showCarousel = true;
    this.currentIndex = index;
  }

  // close carousel
  closeCarousel() {
    this.showCarousel = false;
  }

  /** Opens the image viewer to preview */
  openPreview(index: number) {
    this.imageViewerService.setViewerImages(this.imageUrls);
    this.currentIndex = index;
    this.displayPreview = true;
  }

  /** Closes the image viewer */
  closePreview() {
    this.displayPreview = false;
  }
}
