import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";
import { SharedService } from "app/common/utility/SharedService";
import { environmentConstant } from "app/common/utility/environment";
import { Observable } from "rxjs";
import { ApiResponseModel as Api } from "app/model/ApiResponseModel";
import { UserModel } from "app/model/Core/UserModel";
import { Catalog } from "app/model/Core/catalogModel";
import { Category } from "app/model/Core/categoryModel";
import { Product } from "app/model/Core/productModel";
import {
  SearchFilter,
  FilteredSearchModel,
  SearchModel,
} from "app/model/SearchModel";
import { Featured, EntityType } from "app/model/Core/featured";
import { AppTheme } from "app/model/Core/appTheme";
import { Pager } from "app/model/Pager";

@Injectable()
export class AccountService {
  constructor(private httpService: HttpService) {}

  searchContractors(
    model: FilteredSearchModel<SearchFilter>
  ): Observable<Api<Pager<UserModel>>> {
    return this.httpService.post<Pager<UserModel>>(
      environmentConstant.coreApi.searchAccountContractors(),
      model
    );
  }

  searchLeads(
    model: FilteredSearchModel<SearchFilter>
  ): Observable<Api<Pager<UserModel>>> {
    return this.httpService.post<Pager<UserModel>>(
      environmentConstant.coreApi.searchAccountLeads(),
      model
    );
  }

  searchCustomers(
    model: FilteredSearchModel<SearchFilter>
  ): Observable<Api<Pager<UserModel>>> {
    return this.httpService.post<Pager<UserModel>>(
      environmentConstant.coreApi.searchAccountCustomers(),
      model
    );
  }
}
