<div>
  <div *ngIf="!editingNote">
    <div class="d-flex justify-content-between">
      <h5 class="mt-0 mb-1">{{ note.NoteTitle }}</h5>
      <a data-toggle="tooltip" data-placement="top" title="Update Comment">
        <i
          class="fa fa-pencil"
          aria-hidden="true"
          *ngIf="note.CanEdit"
          (click)="editingNote = note"
        ></i>
      </a>
    </div>
    <div class="note-display-class" [innerHTML]="note.Note | safeHtml"></div>
    <div class="time-date">
      {{ note.CreatedOn | date : "medium" }} &nbsp;&nbsp;<i
        *ngIf="note.JobPhaseId"
        title="{{ note.PhaseName }}"
        class="fa fa-briefcase"
      ></i
      ><i *ngIf="note.Public" title="Public" class="fa fa-bullhorn"></i>
    </div>
    <div
      class="medianame"
      title="{{ note.CreatedBy }}"
      *ngIf="note.CreatedBy && note.CreatedBy.trim() != ''"
    >
      {{ getInitals(note.CreatedBy) }}
    </div>
    <div *ngIf="!note.CreatedBy || note.CreatedBy.trim() == ''">
      <img
        class="medianame"
        src="./assets/images/no-user.jpg"
        alt="Generic placeholder image"
      />
    </div>
  </div>
  <div *ngIf="editingNote">
    <form #editjobnote="ngForm" novalidate class="form-horizontal">
      <div class="d-flex">
        <div>
          <input
            type="text"
            class="form-control mb-2"
            placeholder="Title"
            id="EditNoteTitle"
            name="EditNoteTitle"
            [(ngModel)]="note.NoteTitle"
            #EditNoteTitle="ngModel"
            maxlength="250"
            required
            [ngClass]="{
              'has-error':
                EditNoteTitle.invalid &&
                (EditNoteTitle.dirty || EditNoteTitle.touched)
            }"
          />
          <!-- <p-editor
            [style]="{ 'background-color': '#fff', 'min-height': '120px' }"
            id="EditNote"
            name="EditNote"
            #EditNote="ngModel"
            placeholder="Comment"
            [ngClass]="{
              'has-error':
                EditNote.invalid && (EditNote.dirty || EditNote.touched)
            }"
            [(ngModel)]="note.Note"
            required
          >
          </p-editor> -->
          <editor
            [init]="editorConfig"
            [ngStyle]="{ 'background-color': '#fff', 'min-height': '120px' }"
            [id]="'EditNote' + note.ID"
            name="EditNote"
            #EditNote="ngModel"
            [ngClass]="{
              'has-error':
                EditNote.invalid && (EditNote.dirty || EditNote.touched)
            }"
            [(ngModel)]="note.Note"
            required
          ></editor>
          <div class="d-flex">
            <div class="medianame" title="{{ loggedInUser }}">
              {{ getInitals(loggedInUser) }}
            </div>
            <div class="form-group ml-2 mt-2 note-actions-update row">
              <label
                class="checkbox-inline custom-checkbox nowrap padding-right-1 form__checkbox col-6"
              >
                <input
                  type="checkbox"
                  name="public"
                  [(ngModel)]="note.CustomerCanView"
                />
                <span>Share With Customer</span>
              </label>
              <label
                class="checkbox-inline custom-checkbox nowrap form__checkbox col-6"
              >
                <input
                  type="checkbox"
                  name="contractor"
                  [(ngModel)]="note.ContractorCanView"
                />
                <span>Share With Contractor</span>
              </label>
            </div>
          </div>
          <div class="d-flex">
            <div class="form-group ml-2 mt-2 note-actions-update row">
              <label
                *ngIf="showShareWithPhases"
                class="checkbox-inline custom-checkbox nowrap form__checkbox col-6 margin-left-22"
              >
                <input
                  type="checkbox"
                  name="shared"
                  [(ngModel)]="note.Shared"
                />
                <span>Shared With Phases</span>
              </label>
            </div>
          </div>
        </div>
        <span style="white-space: nowrap">
          <a
            data-toggle="tooltip"
            data-placement="top"
            title="Delete Comment"
            (click)="deleteJobNote(i, note)"
            style="margin-right: 5px; margin-left: 5px"
          >
            <i class="fa fa-trash plusBtn" aria-hidden="true"></i>
          </a>
          <a
            *ngIf="!note.Note ? true : note.Note.length < 10000000"
            data-toggle="tooltip"
            data-placement="top"
            title="Update Comment"
            (click)="updateJobNote(note)"
          >
            <i class="fa fa-check plusBtn" aria-hidden="true"></i>
          </a>
          <a
            *ngIf="!note.Note ? false : note.Note.length > 9999999"
            data-toggle="tooltip"
            data-placement="top"
            title="Note size is limited to 10 MB"
          >
            <i
              style="color: #cccccc"
              class="fa fa-check plusBtn"
              aria-hidden="true"
            ></i>
          </a>
        </span>
      </div>
    </form>
  </div>
</div>
