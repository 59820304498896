import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { BaThemeSpinner } from "app/theme";
import { EstimateProductService } from "app/common/services/estimate-products.service";
import { SharedService } from "app/common/utility/SharedService";
import { EstimateProductModel } from "app/model/EstimateProductModel";
import {
  EstimateWorksheetProduct,
  WorksheetTabsTypes,
  WorsheetDetailItem,
} from "app/model/EstimateWorksheetProduct";
import { Kit, KitExtension, KitPartProductDetail } from "app/model/KitModel";
import { ToastrService } from "ngx-toastr";
import { ProductOption } from "app/model/ProductOptionModel";
import { KitDetailsService } from "app/common/services/kitdetails.service";
import * as _ from "lodash";
import { KitWorksheetAttachabilityResponse } from "app/model/KitWorksheetAttachabilityResponse";
import { WorksheetWorkAreaParameter } from "app/model/WorksheetWorkAreaParameter";
import { MaterialCalculatorService } from "app/common/services/material-calculator.service";
import {
  buildFormulaWithParameterNames,
  getErrorMessageForInifinityResult,
} from "../../common/utility/calculator-utilities";
import { Helper } from "app/common/utility/helper";
import { ValidateProductQtys } from "app/common/validators/validate-qty-not-zero";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "app-simple-kit-selection-modal",
  templateUrl: "./simple-kit-selection-modal.component.html",
  styleUrls: [
    "../catalog-picker/catalog-picker.scss",
    "./simple-kit-selection-modal.component.scss",
  ],
})
export class SimpleKitSelectionModalComponent implements OnInit, OnDestroy {
  @Input()
  product: EstimateProductModel;
  @Output()
  handleDisplay = new EventEmitter<boolean>();
  @Output()
  selectedProduct: EventEmitter<EstimateWorksheetProduct[]> = new EventEmitter<
    EstimateWorksheetProduct[]
  >();
  @Output()
  updateEvent = new EventEmitter<boolean>();
  @Input()
  estimateProduct: EstimateWorksheetProduct;
  @Input()
  worksheetDetailsItems: WorsheetDetailItem[] = [];
  @Input()
  filteredWorksheetProducts: EstimateWorksheetProduct[];
  @Input()
  currentSubTab: number;
  @Input()
  selectOnly: boolean;
  @Input()
  isEdit: boolean;
  @Input()
  worksheetId: number;
  @Input()
  workAreaParameters: WorksheetWorkAreaParameter[][];
  // if yes, calculate the values on entry
  @Input()
  showCalculatedValues: boolean;
  kit: Kit;
  productsForm: FormGroup;
  productRows: FormArray;
  productSearchText: string;
  useKitPricing: boolean;
  worksheetsSubTabTypes = WorksheetTabsTypes;
  kitLabel: string = "Add Simple Kit";
  kitName: string;
  skuKit: string;
  totalKit: number;
  calculatorsIds: number[] = [];
  attachableToWorksheet: boolean = false;
  price: number = 0;
  alive: boolean = false;

  constructor(
    private spinner: BaThemeSpinner,
    private formBuilder: FormBuilder,
    private productService: EstimateProductService,
    private shareService: SharedService,
    private toastrService: ToastrService,
    private kitService: KitDetailsService,
    private calculatorService: MaterialCalculatorService
  ) {}

  get kitProducts(): KitPartProductDetail[] {
    return (this.kit && this.kit.kitPartProductDetails) || [];
  }

  /** Returns if the worksheet has the required parameters to calculate a product or kit */
  private getKitEligibilityForWorksheet() {
    this.spinner.show();
    this.kitService
      .checkKitWorksheetAvailability(this.calculatorsIds, this.worksheetId)
      .subscribe(
        (data: KitWorksheetAttachabilityResponse) => {
          this.attachableToWorksheet = data.attachableToWorksheet;
          if (!this.attachableToWorksheet) {
            const missingParams = data.missingParameters
              .map((x) => x.parameterName)
              .join(", ");
            const missingParamsQty = data.missingParameters.length > 1;
            this.toastrService.warning(
              `The following parameter${
                missingParamsQty ? "s are" : " is"
              } missing: ${missingParams}.`,
              "Kit can't be attached to Worksheet"
            );
          }
        },
        (error) =>
          this.toastrService.error(
            "Error defining eligibility of kit",
            "Kit Eligibility Validation"
          )
      )
      .add(() => this.spinner.hide());
  }

  ngOnInit(): void {
    this.alive = true;
    this.setLabel();
    this.product = this.product || this.estimateProduct.product;
    this.useKitPricing = this.product.useKitPricing;
    this.spinner.show();
    this.productRows = this.formBuilder.array([]);
    this.productsForm = this.formBuilder.group({
      productRows: this.productRows,
      kitQty: [1, [Validators.required]],
    });
    this.productService
      .getKit(this.product.id, this.shareService.selectedOrganization.ID)
      .subscribe(
        (data) => {
          if (data) {
            this.kit = data;
            //this.setPriceToShow();
            if (this.worksheetDetailsItems.length > 0) {
              data.kitPartProductDetails = data.kitPartProductDetails.filter(
                (x) =>
                  this.worksheetDetailsItems.some(
                    (y) => y.productId == x.kitPartProduct.id
                  )
              );
            }
            data.kitPartProductDetails.forEach((el) => {
              el.kitPartProduct.priceToShow = el.kitPartProduct.priceBookPrice;
              this.configProductOptions(el.kitPartProduct);
              if (this.filteredWorksheetProducts && this.isEdit) {
                this.configEditProduct(el);
              } else if (!this.isEdit) {
                el.kitPartProduct.priceToShow = el.kitPartProduct.activePrice;
                el.kitPartProduct.originalPrice = el.kitPartProduct.activePrice;
              }
            });

            if (this.worksheetId) {
              this.setCalculatorIds();
              this.getKitEligibilityForWorksheet();
            } else {
              this.attachableToWorksheet = true;
            }
            this.setPriceToShow();
            this.initForm();
          }
          this.kitName = data.kitProduct.name;
          this.skuKit = data.kitProduct.sku;
        },
        (error) => {
          this.error(error, "Error getting data");
        }
      )
      .add(() => this.spinner.hide());
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  private configEditProduct(el: KitPartProductDetail) {
    const reversedArrayToGetMostCurrents = [
      ...this.filteredWorksheetProducts.filter(
        (x) => x.complexKitId == null && x.simpleKitId == null
      ),
    ].reverse();
    let productOption: EstimateProductModel =
      reversedArrayToGetMostCurrents.find(
        (x) => x.product.id == el.kitPartProduct.id && !x.deletedOn
      ).productOption;

    if (productOption) el.productOption = productOption;

    let product = reversedArrayToGetMostCurrents.find(
      (x) =>
        x.product.id == el.kitPartProduct.id &&
        x.deletedOn == null &&
        x.complexKitId === null &&
        x.kitProductId === this.kit.kitProduct.id
    );

    if (product) {
      el.qty = product.unitQty;
      el.kitPartProduct.priceToShow =
        el.kitPartProduct.usePriceBookPricing && !productOption
          ? el.kitPartProduct.activePrice
          : product.unitPrice;

      if (el.kitPartProduct.usePriceBookPricing) {
        el.kitPartProduct.originalPrice = el.kitPartProduct.activePrice;
      } else if (productOption) {
        el.kitPartProduct.originalPrice = el.kitPartProduct.price;
      } else {
        el.kitPartProduct.originalPrice = product.unitPrice;
      }
    }
  }

  private configProductOptions(product: EstimateProductModel) {
    product.productOptionsMap = product.productOptions.map((val) => ({
      label: val.productName,
      value: val,
    }));
    product.productOptionsMap.unshift({
      label: "Select an option",
      value: null,
    });
  }

  private setCalculatorIds() {
    this.kit.kitPartProductDetails.forEach((product) => {
      const { materialsCalculatorId } = product;
      if (materialsCalculatorId) {
        this.calculatorsIds.push(materialsCalculatorId);
      }
    });
    this.calculatorsIds = _.uniq(this.calculatorsIds);
  }

  private initForm(): void {
    let kitQty = 1;
    this.productsForm.get("kitQty").valueChanges.subscribe((qty) => {
      this.calculateTotal(qty);
    });
    // Getting the kitQty and setting it to the form
    if (this.isEdit) {
      const kitProductId = this.kit.kitProduct.id;
      const oneProduct = this.filteredWorksheetProducts.find(
        (x) =>
          x.deletedOn === null &&
          x.kitProductId === kitProductId &&
          x.complexKitId === null
      );
      const kitQtyFromDb = oneProduct && oneProduct.qtyKit;
      kitQty = kitQtyFromDb || 1;
      this.productsForm.get("kitQty").setValue(kitQtyFromDb);
    } else {
      this.productsForm.get("kitQty").setValue(kitQty);
    }

    this.kit &&
      this.kit.kitPartProductDetails.forEach((kd) => {
        kd.kitPartProduct.priceToShow = kd.kitPartProduct.priceBookPrice;
        const productOptionsControl = new FormControl(null);
        const formGroup = this.formBuilder.group({
          qtyControl: [
            kd.qty,
            [
              Validators.required,
              ValidateProductQtys.checkIfQtyIsZero(kd.materialsCalculatorId),
            ],
          ],
          productOptionsControl: productOptionsControl,
          productId: kd.id,
        });

        if (this.selectOnly) {
          productOptionsControl.disable();
          formGroup.get("qtyControl").disable();
        }

        if (kd.editableQty) {
          this.handleQuantitiesChanges(formGroup);
        }
        this.productRows.push(formGroup);

        if (
          kd.kitPartProduct.productOptions &&
          kd.kitPartProduct.productOptions.length > 0 &&
          this.requiredSelection(kd.kitPartProduct)
        ) {
          productOptionsControl.setValidators(Validators.required);
          productOptionsControl.setValue(null);
        }
        if (kd.productOption) {
          let selected = kd.kitPartProduct.productOptions.find(
            (x) => x.productId == kd.productOption.id
          );
          productOptionsControl.setValue(selected);
        }
      });
  }

  private calculateTotal(qty: number) {
    if (this.kit) {
      this.totalKit = this.useKitPricing
        ? this.product.priceBookPrice * qty
        : qty * this.sumTotalByPriceToShow();
    }
  }

  requiredSelection(kd: EstimateProductModel): boolean {
    return kd.productEstimateExt.requiresUserSelection && !kd.productOptionId;
  }

  selectKit(): void {
    const kitQty = this.productsForm.get("kitQty").value;
    let isGoodToSend = { value: true };
    Promise.all(
      this.kitProducts.map(async (kit, index) => {
        const { kitPartProduct: product } = kit;
        if (kit.materialsCalculatorId) {
          const formulaResult = await this.calculatorService
            .evaluateFormulaByWorkAreas(
              this.workAreaParameters,
              kit.materialsCalculator.formula
            )
            .toPromise();
          if (formulaResult.isInfinite) {
            const formulaWithNames = buildFormulaWithParameterNames(
              this.workAreaParameters,
              kit.materialsCalculator.formula
            );
            const errorMessage = getErrorMessageForInifinityResult(
              kit.materialsCalculator.calculatorName,
              formulaWithNames,
              product.name
            );
            this.toastrService.error(errorMessage);
            isGoodToSend.value = false;
          }
          this.productRows
            .at(index)
            .get("qtyControl")
            .setValue(
              Helper.roundNumber(
                formulaResult.result,
                kit.kitPartProduct.unitOfMeasure.valueType
              )
            );
        }
        const unitQty = Helper.truncateNumber(
          this.productRows.at(index).get("qtyControl").value,
          6
        );
        const qty = Helper.roundNumber(
          unitQty,
          kit.kitPartProduct.unitOfMeasure.valueType
        );
        return {
          id:
            this.worksheetDetailsItems.length > 0 && this.isEdit
              ? this.worksheetDetailsItems.find(
                  (x) => x.productId == product.id && x.simpleKitId == null
                ).worhseetDetailId
              : 0,
          productId: product.id,
          product: product,
          unitPrice: product.priceToShow,
          kitProduct: this.kit.kitProduct,
          kitProductId: this.kit.kitProduct.id,
          worksheetDetailChange: KitExtension.getWorksheetDetailChange(
            this.worksheetDetailsItems.length > 0,
            this.filteredWorksheetProducts,
            product.id
          ),
          previousQty: KitExtension.getWorksheetDetailPreviousQty(
            this.worksheetDetailsItems.length > 0,
            this.filteredWorksheetProducts,
            qty,
            product.id
          ),
          qty: qty,
          qtyKit: kitQty,
          productOption: this.productRows.at(index).get("productOptionsControl")
            .value,
          requiresUserSelection: this.useRequiredSelection(
            this.productRows.at(index).get("productOptionsControl").value,
            product
          ),
          materialsCalculatorId: kit.materialsCalculatorId,
          materialsCalculator: kit.materialsCalculator,
          unitQty: unitQty,
          totalCost: this.totalKit,
          editableQty: kit.editableQty,
        };
      })
    ).then((data) => {
      if (isGoodToSend.value) {
        this.selectedProduct.emit(data);
      }
      this.handleDisplay.emit(false);
    });
  }

  useRequiredSelection(
    optionSelected: any,
    product: EstimateProductModel
  ): boolean {
    return (
      (!optionSelected || optionSelected.id === 0) &&
      product.productOptions.length > 0
    );
  }

  hasProductOptions(product: EstimateProductModel): boolean {
    return product.productOptions && product.productOptions.length > 0;
  }

  hideSidebar() {
    this.handleDisplay.emit(false);
  }

  changePrice(e, product: EstimateProductModel): void {
    if (!e.value) {
      this.setPriceOrginal(product);
    } else {
      this.setPriceProducOption(e, product);
    }
  }

  private setPriceOrginal(product: EstimateProductModel) {
    let index = this.kit.kitPartProductDetails.findIndex(
      (prod) => prod.kitPartProduct.id === product.id
    );
    if (index >= 0) {
      let price =
        this.kit.kitPartProductDetails[index].kitPartProduct.originalPrice;
      this.kit.kitPartProductDetails[index].kitPartProduct.priceToShow = price;
    }
  }

  private setPriceProducOption(e, product: EstimateProductModel) {
    let index = this.kit.kitPartProductDetails.findIndex(
      (prod) => prod.kitPartProduct.id === product.id
    );
    if (index >= 0) {
      let priceProductOption = e.value.price;
      this.kit.kitPartProductDetails[index].kitPartProduct.priceToShow =
        priceProductOption;
    }
  }

  /**
   * handles error messages
   * @param error error from process
   * @param message message to show in toaster
   */
  error(error: any, message: string): void {
    console.error(error);
    this.toastrService.error(message, "Estimate");
  }

  /**
   * sets label text depending on process
   */
  setLabel() {
    if (!this.estimateProduct) return;
    if (this.selectOnly) this.kitLabel = `Review Simple Kit`;
    if (!this.selectOnly && this.isEdit) this.kitLabel = `Edit Kit`;
  }

  /** handles item quantity changes */
  private handleQuantitiesChanges(form: FormGroup) {
    form
      .get("qtyControl")
      .valueChanges.pipe(takeWhile(() => this.alive))
      .subscribe((value: number) => {
        const id = form.get("productId").value;
        const product = this.kit.kitPartProductDetails.find(
          (detail) => detail.id === id
        );
        const kitQty = this.productsForm.get("kitQty").value;
        product.kitPartProduct.priceToShow =
          product.kitPartProduct.priceBookPrice * value;
        this.totalKit = this.useKitPricing
          ? this.product.priceBookPrice * kitQty
          : this.sumTotalByPriceToShow() * kitQty;
      });
  }

  /** sum all total of kit by price to show */
  private sumTotalByPriceToShow(): number {
    let result = 0;
    this.kit.kitPartProductDetails.forEach(
      (item) => (result += item.kitPartProduct.priceToShow)
    );
    return result;
  }

  private setPriceToShow(): void {
    this.kit.kitPartProductDetails.forEach(
      (x) => (x.kitPartProduct.priceToShow = x.kitPartProduct.priceBookPrice)
    );
  }
}
