import {
  Component,
  OnInit,
  Input,
  Output,
  OnDestroy,
  EventEmitter,
} from "@angular/core";
import { ChatService } from "app/common/services/Chat.service";
import { GroupModel } from "app/model/Chat/GroupModel";
import { UserRoleEnum } from "app/model/Core/UserRoleEnum";
import { BaThemeSpinner } from "app/theme/services";
import { Subscription } from "rxjs";
import {
  ChatPanelContextModel,
  ChatPanelContextEnum,
} from "app/model/Chat/ChatPanelContextModel";
import { UserModel } from "app/model/Core/UserModel";
import { DialogService } from "ng2-bootstrap-modal";
import { ConfirmComponent } from "../dialog/dialog.component";
import { ChatUserModel } from "app/model/Chat/ChatUserModel";

@Component({
  selector: "chat-add-edit-group-panel",
  templateUrl: "./ChatAddEditGroupPanel.component.html",
  styles: [
    `
      :host {
        width: 100%;
        height: 100%;
      }

      ul.chat-users {
        list-style: none;
        width: 100%;
      }

      ul.chat-users li {
        color: #2c3e50;
      }

      ul.chat-users li i {
        cursor: pointer;
        color: red;
        float: right;
        font-size: 20px;
      }

      button {
        margin-top: 10px;
        width: 100%;
      }

      .chat-participants h3 {
        color: #6c757d;
      }
    `,
  ],
})
export class ChatAddEditGroupPanelComponent implements OnInit, OnDestroy {
  @Input() context: ChatPanelContextModel<GroupModel>;
  @Input() user: ChatUserModel;
  @Output() completed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() title: EventEmitter<string> = new EventEmitter<string>();

  public group: GroupModel = <GroupModel>{};
  public roles: any[] = Object.keys(UserRoleEnum)
    .filter((x) => !isNaN(Number(x)))
    .map((x) => ({ id: parseInt(x), name: UserRoleEnum[x] }))
    .sort((a, b) => a.name.localeCompare(b.name));
  public edit: boolean = false;
  private subscriptions: Subscription = new Subscription();
  public users: ChatUserModel[] = [];

  constructor(
    private dialogService: DialogService,
    private spinner: BaThemeSpinner,
    private chatService: ChatService
  ) {}

  public ngOnInit(): void {
    this.edit = this.context.context === ChatPanelContextEnum.EditGroup;
    this.title.emit(this.edit ? "Edit Group" : "Add group");
    this.group = this.context.object;

    if (this.edit) {
      if (!this.group.system) {
        this.users = Object.assign([], this.group.users);
      }
    }

    this.setSubscriptions();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private setSubscriptions(): void {
    this.subscriptions.add(
      this.chatService.onAddGroup.subscribe(() => {
        this.spinner.hide();
        this.onComplete();
      })
    );
  }

  public onUserSelected(user: ChatUserModel): void {
    if (
      !user ||
      this.users.findIndex((x) => x.guid === user.guid) > -1 ||
      user.guid === this.user.guid
    )
      return;
    this.users.push(user);
  }

  private updateUsers(): void {
    if (!this.edit) return;

    for (let user of this.group.users) {
      let index = this.users.findIndex((x) => x.guid === user.guid);
      if (index < 0) this.chatService.removeFromGroup(this.group, user);
    }

    for (let user of this.users) {
      let index = this.group.users.findIndex((x) => x.guid === user.guid);
      if (index < 0) this.chatService.addToGroup(this.group, user);
    }

    this.spinner.hide();
    this.onComplete();
  }

  public onRemoveUser(user: ChatUserModel): void {
    var index = this.users.findIndex((x) => x.guid === user.guid);
    if (index > -1) this.users.splice(index, 1);
  }

  public onComplete(): void {
    this.completed.emit(true);
  }

  public onDeleteGroup(): void {
    if (!this.context) return;
    this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Delete",
        message: `Are you sure you want to delete this group?`,
        toShow: false,
        isNotification: false,
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          this.chatService.deleteGroup(this.group);
          this.onComplete();
        }
      });
  }

  public onSubmit(form: any): void {
    if (this.edit) {
      this.updateUsers();
      return;
    }

    this.spinner.show();
    this.group.system = this.context.object.system;

    this.users.push(this.user);
    this.group.users = this.users;

    this.chatService.addGroup(this.group);
    this.users = [];
    this.group = <GroupModel>{};
  }
}
