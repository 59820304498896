<form [formGroup]="form" (ngSubmit)="submitForm()">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="title">Signers</div>
      </div>
    </div>
    <div class="row">
      <div
        class="col-12"
        *ngFor="let control of signersArray.controls; let i = index"
      >
        <div class="dropdown-pentair-std w-100">
          <label
            class="dropdown-pentair-std__title dropdown-pentair-std__title-kendo"
            style="font-weight: 700"
            >{{ signersLabelArray[i] }} *</label
          >
          <kendo-combobox
            id="taxbox"
            [data]="signersList"
            [valuePrimitive]="true"
            [filterable]="true"
            (filterChange)="filterDropDownSigners($event)"
            [textField]="'fullName'"
            [valueField]="'id'"
            class="catalog-picker__search-combobox"
            (selectionChange)="dropDownSignerChange($event, i)"
            [formControl]="control"
          >
            <ng-template
              kendoComboBoxItemTemplate
              let-dataItem
              let-index="index"
            >
              <div
                [ngStyle]="{
                  'border-bottom':
                    dataItem.index === 1 ? '2px solid #0a244f' : 'none',
                  'margin-bottom': dataItem.index === 1 ? '4px' : '0',
                  'padding-bottom': dataItem.index === 1 ? '6px' : '4px',
                  width: '100%'
                }"
              >
                {{ dataItem.fullName }}
              </div>
            </ng-template>
          </kendo-combobox>
          <div class="small text-danger" *ngIf="submitted">
            <div class="small text-danger" *ngIf="submitted && control.invalid">
              <span *ngIf="control.errors?.required">Field is required</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <mat-dialog-actions align="end">
    <button
      (click)="closeModal(false)"
      type="button"
      class="estimate-button estimate-button--secondary"
    >
      Close
    </button>
    <button
      type="submit"
      class="estimate-button estimate-button--primary nowrap"
      [disabled]="form.invalid"
    >
      Sign & Share
    </button>
  </mat-dialog-actions>
</form>
