import { Injectable } from "@angular/core";
import { BreadcrumbModel } from "app/model/BreadcrumbModel";

/** breadcrumb service - used to manage breadcrumb component */
@Injectable({
  providedIn: "root",
})
export class BreadcrumbService {
  constructor() {}

  public segments: Array<BreadcrumbModel> = [];

  /** add a segment to existing segments */
  public addSegment(label: string, url?: string) {
    this.segments.push(new BreadcrumbModel(label, url));
  }

  /** remove a segment from existing segments */
  public removeSegment() {
    this.segments.pop();
  }

  /** restart segments with a new segment */
  public clearSegments(label: string, url?: string) {
    this.segments.length = 0;
    this.addSegment(label, url);
  }

  /** Returns the number of total segments */
  public getSegmentsCount() {
    return this.segments.length;
  }

  /** update segment on a given index */
  public updateSegment(index: number, breadcrumbModel: BreadcrumbModel) {
    this.segments[index] = breadcrumbModel;
  }
}
