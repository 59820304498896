<div>
  <div class="row">
    <div class="col-md-12 ht100">
      <div class="rightcontent">
        <div class="sideBarHeader">
          <h1>{{ headerText }}</h1>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
