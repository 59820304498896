export class OrgCameraModel {
  orgGUID: string = "00000000-0000-0000-0000-000000000000";
  CameraId: number;
  OrgCameraName: string;
  ServiceDate: Date;
  CameraModelId: number;
  CameraCurrentFirmwareId: number;
  CameraSerialNumber: string;
  CameraSerialNumbertxt: string;
  CameraMACAddress: string;
  CameraDateActivated: Date;
  CameraInitialFirmwareId: number;
  CameraDateFirmwareUpdated: Date;
  CameraActive: boolean;
  CameraName: string;
  CameraModel: string;
  StreamingURL: string;
  JobName: string;
  StreamSessionDescriptionUrl: string;
  StreamApplicationId: string;
  constructor() {}
}
