import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { changePasswordService } from "app/common/services/changePassword.service";
import { PasswordModel } from "app/model/PasswordModel";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "primeng/components/common/messageservice";
import { Location } from "@angular/common";
import { BaThemeSpinner } from "app/theme/services";
import { ChangePasswordModel } from "app/model/Core/ChangePasswordModel";
import { CustomerService } from "app/common/services/customer.service";

@Component({
  selector: "app-setup-password",
  templateUrl: "./setup-password.component.html",
})
export class SetupPasswordComponent implements OnInit {
  resetPassword: PasswordModel;
  isMatched: boolean;
  confirmPassModel = { OldPassword: "" };
  isExisting: boolean = false;
  systemResetMsg: String;
  page: string;
  passwordPattern: string;
  passValidationMes: string = "";
  @Input() userName: string = "";
  @Input() orgId: string = "";
  @Input() userId: number = 0;
  @Output() updateEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private passwordService: changePasswordService,
    private _location: Location,
    private route: ActivatedRoute,
    private _spinner: BaThemeSpinner,
    private notificationsService: MessageService,
    private router: Router,
    private customerService: CustomerService
  ) {
    this.resetPassword = new PasswordModel();
    this.confirmPassModel.OldPassword = null;
    this.passwordPattern = "^([a-zA-Z0-9_\\W\\D\\S]{6,32})$";
  }

  ngOnInit() {
    this.isExisting = true;
    // this.route.params.subscribe(params => {
    //   this.userName = params['userName'];
    // });
    if (this.router.url.includes("users")) {
      this.page = "User";
    } else if (this.router.url.includes("clients")) {
      this.page = "Customer";
    } else {
      this.page = "Super Admin";
    }
  }
  onNewPasswordChange(value: any) {
    if (this.resetPassword.NewPassword.length > 32) {
      this.passValidationMes =
        "Password max length exceeding " +
        (this.resetPassword.NewPassword.length - 32) +
        " characters";
    } else {
      this.passValidationMes =
        "Password must be 6-32 characters long including uppercase/lowercase or numbers";
    }
  }
  changeReset() {
    this.isExisting = false;
    this.systemResetMsg =
      "This will send system generated password on user email. Please ask user to check his/her email to see password.";
  }
  matchPassword(value) {
    if (this.resetPassword.NewPassword != value) {
      this.isMatched = true;
    } else {
      this.isMatched = false;
    }
  }
  onSubmit(form) {
    if (form.valid) {
      this._spinner.show();

      this.resetPassword.ValidateRequest = false;
      this.resetPassword.NewPasswordFormat = 0;
      this.resetPassword.UserName = this.userName;
      this.passwordService
        .updatePassword(this.resetPassword)
        .subscribe((result) =>
          this.result(result, "Password Changed Successfully!!")
        );
      this.updateEvent.emit();
    }
  }

  private result(res: any, message: string) {
    this.notificationsService.add({
      severity: "success",
      summary: this.page,
      detail: message,
    });
    this._spinner.hide();
    this.onCancel();
  }
  onCancel() {
    this.updateEvent.emit();
  }

  /**
   * Senda an email to the user to reset the password
   */
  systemReset(): void {
    this._spinner.show();

    let model = <ChangePasswordModel>{
      userName: this.userName,
      deliveryType: 1,
      orgId: this.orgId,
      userId: this.userId,
    };

    this.customerService
      .generateResetPasswordCode(model)
      .subscribe((response) => {
        this._spinner.hide();
        if (!response.success) {
          this.error(response.response.description);
          return;
        }
        this.updateEvent.emit();
      });
  }

  /**
   * Shows error if the requests throws any exception
   * @param message error message
   */
  private error(message: string): void {
    this.notificationsService.add({
      severity: "warn",
      summary: "Forgot Password",
      detail: message,
    });
    this._spinner.hide();
  }
}
