<div class="row photo-container">
  <!--    HEADER     -->
  <!-- Header -->
  <div class="col-6 estimate-panel-title p-3">
    <span>{{ title }}</span>
  </div>
  <div class="col-6 estimate-panel-title p-3 size-message">
    <span>{{ titleSizeMessage }}</span>
  </div>

  <!--    VIEW       -->
  <!-- List -->
  <div class="col-12" *ngIf="itemSelected.id == 2">
    <p-table [value]="listJobFile" responsiveLayout="scroll">
      <ng-template pTemplate="header">
        <tr>
          <th scope="col" class="text-center width__2" *ngIf="!isLocked"></th>
          <th scope="col" class="w-10">File Name</th>
          <th scope="col" class="w-10">File Type</th>
          <th scope="col" class="width__6">Created On</th>
          <th scope="col" class="text-center width__8"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-files>
        <tr>
          <td *ngIf="!isLocked">
            <button
              type="button"
              class="estimate-button estimate-button--flat"
              (click)="deleteFile(files)"
            >
              <i class="fa fa-trash"></i>
            </button>
          </td>
          <td>
            {{
              files.title && files.title.length > 0
                ? files.title
                : files.fileName
            }}
          </td>
          <td>{{ extractExtension(files.blob.fileName) }}</td>
          <td>{{ files.createdOn | date : "MM/dd/yy" }}</td>
          <td>
            <button
              type="button"
              class="estimate-button estimate-button--flat"
              (click)="downloadFile(files)"
            >
              Download
              <i class="fa fa-download"></i>
            </button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <!-- Tiles -->
  <div class="col-12" *ngIf="itemSelected.id == 1">
    <div class="estimate-photo-gallery">
      <div
        style="width: 100%"
        *ngFor="let file of listJobFile; let index = index"
      >
        <!--New placeholder-->
        <ng-container *ngIf="file === ''; else jobFileTemplate">
          <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-12"></div>
            <div class="col-lg-8 col-md-8 col-sm-12">
              <div
                class="estimate-photo-gallery__upload-placeholder"
                [ngClass]="{
                  'estimate-photo-gallery__upload-placeholder-clickable':
                    !isLocked
                }"
                (click)="addFile(index + 1)"
              >
                <div class="estimate-photo-gallery__upload-placeholder-content">
                  <div *ngIf="!isLocked" class="custom-plus-icon"></div>
                  <span *ngIf="!isLocked">Upload Proposal Photo</span>
                  <span *ngIf="isLocked">Proposal Photo Not Selected</span>
                </div>
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-12"></div>
          </div>
        </ng-container>

        <!--End New placeholder-->

        <ng-template #jobFileTemplate>
          <div class="row">
            <div class="col-lg-2 col-md-2 col-sm-12"></div>
            <div class="col-lg-8 col-md-8 col-sm-12">
              <div
                class="estimate-photo-gallery__photo estimate-photo-gallery-container"
              >
                <!-- Img -->
                <img
                  class="estimate-photo-gallery__photo--image"
                  [src]="file.blob.url"
                  [alt]="file.fileName"
                />

                <div class="estimate-photo-gallery__photo--description">
                  <!-- Text description -->
                  <div class="estimate-photo-gallery__photo--text">
                    <div class="estimate-photo-gallery__photo--text-filename"  style="display:none">
                      {{ file.fileName }}
                    </div>
                    <div class="estimate-photo-gallery__photo--text-createdate"  style="display:none">
                      Added on {{ file.createdOn | date : "MM/dd/yy" }}
                    </div>
                    <div class="estimate-photo-gallery__photo--text-createdate"  style="display:none">
                      By {{ file.createdBy.firstName }}
                      {{ file.createdBy.lastName }}
                    </div>
                    <div class="estimate-photo-gallery__photo--text-createdate"  style="display:none">
                      Taken on: {{ file.blob.creationDate }}
                    </div>
                  </div>

                  <!-- Menu three dots -->
                  <div
                    class="btn-group estimate-combobox estimate-photo-gallery__photo--menu"
                  >
                    <button
                      width="100px"
                      type="button"
                      class="estimate-button estimate-button--flat"
                      style="background-color: #96a5bd !important;padding: 2px 5px 2px 5px; width:23px; height:23px"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      *ngIf="!isLocked"
                    >
                      <i class="fa fa-ellipsis-v"></i>
                    </button>
                    <div class="dropdown-menu dropdown-menu-right">
                      <button
                        class="dropdown-item"
                        type="button"
                        (click)="editFile(file)"
                        *ngIf="false"
                      >
                        Edit
                      </button>
                      <button
                        class="dropdown-item"
                        type="button"
                        (click)="shareFile(file)"
                        *ngIf="false"
                      >
                        Share
                      </button>
                      <button
                        class="dropdown-item"
                        type="button"
                        (click)="downloadFile(file)"
                      >
                        Download
                      </button>
                      <div class="dropdown-divider"></div>
                      <button
                        class="dropdown-item text-danger"
                        type="button"
                        (click)="deleteFile(file)"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  *ngIf="false"
                  class="overlay-estimate-photo-container"
                  (click)="clickImage(i)"
                ></div>
              </div>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-12"></div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<!-- Dialog 1: Modal carousel pictures -->
<kendo-dialog
  *ngIf="dialogCarousel"
  (close)="dialogCarousel = false"
  ngClass="estimate-dialog"
>
  <div class="estimate-dialog__content">
    <div
      id="carouselExampleControls"
      class="carousel slide estimate-carousel"
      data-ride="carousel"
      data-interval="2000"
    >
      <!-- Images-->
      <div class="carousel-inner estimate-carousel__photo">
        <div
          class="carousel-item"
          *ngFor="let item of listJobFile; let i = index"
          [ngClass]="{ active: i == imageSelectedCarousel }"
        >
          <img
            class="estimate-carousel__photo--image"
            [src]="item.blob.url"
            [alt]="item.fileName"
          />
          <div class="estimate-carousel__photo--text-filename">
            {{ item.title }}
          </div>
          <div class="estimate-carousel__photo--text-createdate">
            {{ item.createdOn | date : "shortDate" }}
          </div>
        </div>
      </div>

      <!-- Control prev -->
      <a
        class="carousel-control-prev"
        href="#carouselExampleControls"
        role="button"
        data-slide="prev"
      >
        <div class="box-control-carousel">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </div>
      </a>

      <!-- Control next -->
      <a
        class="carousel-control-next"
        href="#carouselExampleControls"
        role="button"
        data-slide="next"
      >
        <div class="box-control-carousel">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </div>
      </a>
    </div>
  </div>

  <kendo-dialog-actions>
    <button
      (click)="dialogCarousel = false"
      class="estimate-button estimate-button--primary"
    >
      Close
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<!-- Dialog 2: Add photo -->
<kendo-dialog
  *ngIf="dialogAddPhoto"
  (close)="dialogAddPhoto = false"
  ngClass="estimate-dialog estimate-dialog__edit-photo"
>
  <div class="estimate-dialog__content">
    <photosAdd
      [type]="typeFile"
      [workOrderId]="jobGuid"
      [allowMultiple]="false"
      (photoAdded)="photoAdded($event)"
      (newPhotos)="emitEventNewPhotos($event)"
      (newFiles)="emitEventNewFiles($event)"
      (closeSlideout)="dialogAddPhoto = false"
      [isFromProposal]="true"
      [worksheetFileData]="worksheetFileData"
    >
    </photosAdd>
  </div>
</kendo-dialog>

<!-- Dialog 3: Edit photo  -->
<kendo-dialog
  *ngIf="dialogEdit"
  title="Edit Photo"
  (close)="dialogEdit = false"
  ngClass="estimate-dialog estimate-dialog__edit-photo"
>
  <div class="estimate-dialog__content">
    <!-- Each of tables have their own business logic -->
    <kendo-tabstrip ngClass="estimate-panel job-file-tab" #panel>
      <kendo-tabstrip-tab title="Details" [selected]="true">
        <ng-template kendoTabContent>
          <form [formGroup]="jobFileForm" *ngIf="jobFileForm">
            <div class="row">
              <div class="col-6">
                <!-- Img -->
                <img
                  class="estimate-photo-gallery__photo--image"
                  [src]="
                    fileForm.blob.thumbnailUrl
                      ? fileForm.blob.thumbnailUrl
                      : fileForm.blob.url
                  "
                  [alt]="fileForm.fileName"
                />
              </div>

              <div
                class="col-6 estimate-photo-comment-title-container d-flex layout-flex-column"
              >
                <!-- Title -->
                <div class="workarea-textfield input-pentair-std">
                  <label class="estimate-photo-edit-title">Title</label>
                  <input
                    type="text"
                    formControlName="title"
                    class="p-1 w-100"
                  />
                </div>
                <div class="small text-danger" *ngIf="submittedJobFile">
                  <span *ngIf="f.title.errors?.required">
                    Field is required
                  </span>
                  <span *ngIf="f.title.errors?.maxlength">
                    Field must have equal or less letters than
                    {{ f.title.errors?.maxlength.requiredLength }}
                  </span>
                </div>

                <!-- Tags -->
                <div class="workarea-textfield multiselect-pentair-std">
                  <label class="label-title">Tags</label>
                  <p-multiSelect
                    [options]="listTags"
                    formControlName="jobFileJobFileTags"
                    ngClass="estimate-select w-100"
                  >
                  </p-multiSelect>
                </div>
              </div>

              <div class="col-12 mt-5 estimate-comment-description">
                <!-- Description -->
                <div class="workarea-textfield input-pentair-std">
                  <label>Description</label>
                  <textarea
                    type="text"
                    formControlName="description"
                    class="p-1 w-100"
                  ></textarea>
                </div>
                <div class="small text-danger" *ngIf="submittedJobFile">
                  <span *ngIf="f.description.errors?.required">
                    Field is required
                  </span>
                  <span *ngIf="f.description.errors?.maxlength">
                    Field must have equal or less letters than
                    {{ f.description.errors?.maxlength.requiredLength }}
                  </span>
                </div>
              </div>
            </div>
          </form>

          <!-- Comments-->
          <form [formGroup]="jobFileNoteForm" *ngIf="jobFileNoteForm">
            <h3 class="estimate-comment-tittle mt-2 mb-2">Comments</h3>
            <div class="row estimate-comment-container">
              <div class="col-12">
                <!-- Description -->
                <div class="workarea-textfield input-pentair-std mt-4">
                  <label>Comments</label>
                  <textarea
                    type="text"
                    formControlName="note"
                    class="p-1 w-100 comments-text-area"
                  ></textarea>
                </div>
                <div
                  class="small text-danger text-left"
                  *ngIf="submittedJobFileNote"
                >
                  <span *ngIf="fn.note.errors?.required">
                    Field is required
                  </span>
                  <span *ngIf="fn.note.errors?.maxlength">
                    Field must have equal or less letters than
                    {{ fn.note.errors?.maxlength.requiredLength }}
                  </span>
                </div>
              </div>
              <div class="col-12 d-flex layout-vertical-center">
                <div class="file-note-signature">
                  {{ signatureName.username | uppercase | slice : 0 : 2 }}
                </div>
                <div class="w-100 text-right mt-1 mb-2">
                  <button
                    type="button"
                    class="estimate-button estimate-button--secondary"
                    (click)="saveComment()"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
          <!-- enf od form comments -->

          <!-- List of comments -->
          <div class="row estimate-photo-comment">
            <div
              class="col-12 estimate-photo-comment__photo"
              *ngFor="let item of comments"
            >
              <div class="d-flex">
                <!-- signature owner -->
                <div class="file-note-signature mr-2">
                  {{
                    (!!item.createdBy ? item.createdBy.username : user.email)
                      | uppercase
                      | slice : 0 : 2
                  }}
                </div>
                <!--Comment owner-->
                <div class="estimate-photo-comment__photo--owner">
                  {{ !!item.createdBy ? item.createdBy.username : user.email }}
                </div>
                <!--Comment date-->
                <div class="estimate-photo-comment__photo--date ml-2">
                  {{ item.created | date : "EE MM-dd-yy  hh:mm:ss a" }}
                </div>
              </div>
              <!--Comment content-->
              <div class="estimate-photo-comment__photo--text mt-3">
                {{ item.note }}
              </div>
            </div>
          </div>

          <div
            class="row d-flex layout-flex-end estimate-dialog-fix-footer mr-2"
          >
            <button
              (click)="closeForm()"
              class="estimate-button estimate-button--secondary"
            >
              Cancel
            </button>
            <button
              (click)="save()"
              class="estimate-button estimate-button--primary"
            >
              Save
            </button>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>

      <kendo-tabstrip-tab title="Versions">
        <ng-template kendoTabContent>
          <div class="estimate-photo-version" *ngFor="let item of versions">
            <!-- Text description -->
            <div class="estimate-photo-version__photo">
              <img
                class="estimate-photo-version__photo--image"
                [src]="item.ThumbnailUrl"
                [alt]="item.FileName"
              />
              <div class="estimate-photo-version__photo--title">
                {{ item.FileName }}
              </div>
              <div class="estimate-photo-version__photo--date">
                {{ item.CreatedOn | date : "MM/dd/yy" }}
              </div>
              <div class="estimate-photo-version__photo--owner">
                {{ item.UploadedBy?.Email }}
              </div>
            </div>
          </div>
          <div
            class="row d-flex layout-flex-end estimate-dialog-fix-footer mr-2"
          >
            <button
              (click)="closeForm()"
              class="estimate-button estimate-button--secondary"
            >
              Cancel
            </button>
            <button
              (click)="save()"
              class="estimate-button estimate-button--primary"
            >
              Save
            </button>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
</kendo-dialog>
