import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { JobChangeOrderService } from "app/common/services/jobChangeOrder.service";
import { JobFileService } from "app/common/services/jobFile.service";
import { BaThemeSpinner } from "app/theme/services";
import { MarkerArea } from "markerjs";
import { MessageService } from "primeng/primeng";
import { Subscription } from "rxjs";
import {
  AnnotationHandle,
  ImageAnnotatorService,
  JobPhotoForAnnotation,
} from "./image-annotator.service";

@Component({
  selector: "app-image-annotator",
  templateUrl: "./image-annotator.component.html",
  styleUrls: ["./image-annotator.component.scss"],
})
export class ImageAnnotatorComponent implements AfterViewInit, OnDestroy {
  public photo: JobPhotoForAnnotation;
  public mark: MarkerArea;
  public showBackdrop = false;
  public annotationActive = false;
  public readonly = false;

  @ViewChild("image", { static: false })
  public image: ElementRef;
  @ViewChild("container", { static: false })
  public container: ElementRef;

  private subscription: Subscription;
  private handle: AnnotationHandle;
  private isChangeOrderImage: boolean = false;

  constructor(
    private annotatorService: ImageAnnotatorService,
    private jobFileService: JobFileService,
    private notificationsService: MessageService,
    private spinner: BaThemeSpinner,
    private jobChangeOrderService: JobChangeOrderService
  ) {
    this.subscription = this.annotatorService.showAnnotator.subscribe(
      ({ show, photo, readonly, handle }) => {
        this.showBackdrop = show;
        this.readonly = readonly;
        this.photo = photo;
        this.handle = handle;
        this.isChangeOrderImage = photo && !!photo.JobChangeOrderId;
        if (show) {
          //this.showMarker();
        } else {
          this.hideMarker();
        }
      }
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {}

  showMarker() {
    if (this.annotationActive || this.readonly) {
      return;
    }
    this.annotationActive = true;
    setTimeout(() => {
      this.mark = new MarkerArea(this.image.nativeElement, {
        targetRoot: this.container.nativeElement,
        renderAtNaturalSize: true,
      });
      this.mark.show(
        (dataUrl) => {
          // annotation accepted
          this.handleAcceptedAnnotationRequest(dataUrl);
        },
        () => {
          // annotation cancelled
          this.annotationActive = false;
        }
      );
    });
  }

  hideMarker() {
    this.showBackdrop = false;
    this.annotationActive = false;
    if (!this.mark) {
      return;
    }
    try {
      this.mark.close();
    } catch (e) {
      /* errors ignored */
    }
  }

  /** Handles an accepted annotation */
  private handleAcceptedAnnotationRequest(dataUrl: string) {
    this.image.nativeElement.src = dataUrl;
    this.isChangeOrderImage
      ? this.createChangeOrderPhoto(dataUrl)
      : this.createJobPhoto(dataUrl);
  }

  /** Triggers request to create a job photo */
  private createJobPhoto(dataUrl: string) {
    this.spinner.show();
    this.jobFileService
      .postNewVersion(this.photo.ID, dataUrl, this.photo.FileName)
      .subscribe(() => {
        this.onRequestSuccess();
      })
      .add(() => this.spinner.hide());
  }

  /** Triggers request to create a change order photo */
  private createChangeOrderPhoto(dataUrl: string) {
    this.spinner.show();
    this.jobChangeOrderService
      .postNewVersion(dataUrl, this.photo.FileName, this.photo.JobChangeOrderId)
      .subscribe((data) => {
        this.onRequestSuccess();
      })
      .add(() => this.spinner.hide());
  }

  /** Handles a successful request */
  private onRequestSuccess() {
    this.notificationsService.add({
      severity: "success",
      summary: "Photo",
      detail: "Annotated Successfully!",
    });
    this.handle.accept();
    this.annotationActive = false;
  }
}
