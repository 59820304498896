<div class="modal-dialog modal-sm">
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close" (click)="close()">&times;</button>
      <h4 class="modal-title">
        {{ "Share (" + title + ") with specific people" }}
      </h4>
    </div>
    <div class="modal-body">
      <div class="row p2row-bg scrollable-content">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="form-group">
            <ejs-multiselect
              id="multiselectelement"
              [dataSource]="EmployeeList"
              [fields]="{ text: 'Name', value: 'Id' }"
              [placeholder]="'Select Contact'"
              [allowCustomValue]="true"
              [(ngModel)]="selectedContact"
            ></ejs-multiselect>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="form-group">
            <ejs-dropdownlist
              id="ddlelement"
              [dataSource]="permission"
              [fields]="{ text: 'displayValue', value: 'value' }"
              [placeholder]="'Permissions'"
              [(ngModel)]="selectedPermission"
            >
            </ejs-dropdownlist>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn darkbtn dpbtn" (click)="Confirm()">
        Send
      </button>
      <button type="button" class="btn btn-default" (click)="close()">
        Cancel
      </button>
    </div>
  </div>
</div>
