import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpService } from "../utility/http.service";
import { environmentConstant } from "../utility/environment";

@Injectable()
export class WeatherService {
  baseUrl = "http://api.openweathermap.org/data/2.5/";
  iconUrl = "http://openweathermap.org/img/wn/";
  APIKEY = "da1edc825fd3d35b353b679840a6e702";

  constructor(private http: HttpClient, private httpService: HttpService) {}

  getForecastByZipCode(zipCode) {
    return this.http.get(
      this.baseUrl + "forecast/daily?zip=" + zipCode + "&appid=" + this.APIKEY
    );
  }

  getWeatherIcon(code) {
    return this.http.get(this.iconUrl + code + "@2x.png");
  }

  GetWeatherAddressLookUp(orgGuid: any) {
    return this.httpService.getRequestService(
      environmentConstant.getWeatherAddressLookUp + orgGuid
    );
  }

  // ConvertKelvintoFahrenheit(value){
  //   var answer = (value - 273.15)*9/5+32;
  //   return answer;

  // }
}
