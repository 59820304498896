export class ProcessPaymentModel {
  IsCreditMemoUse: boolean;
  CreditMemoAmtDetect: number;
  CreditMemoIds: number[];
  LateFee: number;
  ConvFee: number;
  CustomerId: number;
  OrderTotal: number;
  PaymentMethodSystemName: string;
  PaymentMethodDisplayName: string;
  SaveCard: boolean;
  CreditCardType: number;
  transactionType: string;
  CreditCardName: string;
  CreditCardNumber: string;
  CreditCardExpireYear: number;
  CreditCardExpireMonth: number;
  CreditCardCvv2: string;
  Zip: number;
  Street: string;
  OrderId: number;
  Memo: string;
  PaymentToken: string;
  TrxType: number;
  GatewayId: number;
  GatewayAccountName: string;
  PaymentMethodId: number;
  AdditionalEmails: string;
  IsSendEmailToAccountHolder: boolean;
  PaymentAccountId: number;
  InvoiceDrawScheduleIds: number[];
  OrderGuid: string;
  InitialOrderId: number;
  IsAdminPortal: boolean;
}

//new paymenet request model PATRICK 02/08/20
export class MakePaymentRequest {
  LateFee: number;
  PrincipalFee: number;
  AdditionalFee: number;
  TransactionTotal: number;
  TransactionId: number;
  PaymentType: number;
  PaymentMethodSystemName: string;
  Order: RequestOrder;
  OrderId: number;
  TransType: number;
  CustomerId: number;
  UserCardOnFile: boolean;
  PaymentAcountId: number;
  OrgGuid: string;
  Memo: string;
  EmailAddress: string;
  EmailReceipt: boolean;
  SMSPhoneNumber: boolean;
  SMSReceipt: boolean;
  AllowOverPayment: boolean;
}

export class RequestOrder {
  CustomerId: number;
  OrderId: number;
  CustomOrderNumber: string;
  HasSchedules: boolean;
  AdditionalFee: number;
  PrincipalFee: number;
  LateFee: number;
  Schedules: ScheduleOrder[];
}

export class ScheduleOrder {
  Order: Number;
  ScheduleId: Number;
}
