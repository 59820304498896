import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpService } from "../utility/http.service";
import { environmentConstant } from "../utility/environment";
import { Response } from "@angular/http";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { SharedService } from "../utility/SharedService";
import { environment } from "environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LocalStorageService } from "./localStorage.service";

@Injectable()
export class AuthenticationService {
  token: string;
  signInUrl = "user/authentication";
  constructor(
    private http: HttpClient,
    private router: Router,
    private sharedService: SharedService,
    private localStorageService: LocalStorageService
  ) {
    this.token = localStorage.getItem("token");
  }

  /** verifies user is currently authenticated */
  isAuthenticated() {
    const jwt = this.getJWT();
    if (jwt) {
      const currentUnixTime = Math.floor(Date.now() / 1000);
      return currentUnixTime < parseInt(jwt.exp, 0);
    }
    return false;
  }

  /** retrieves authentication token */
  getToken(): string {
    return localStorage.getItem("token");
  }

  /** parse JWT token */
  parseJWT(tokenString: string): any {
    if (tokenString) {
      let retVal = null;
      try {
        retVal = JSON.parse(
          atob(
            tokenString.substring(
              tokenString.indexOf(".") + 1,
              tokenString.lastIndexOf(".")
            )
          )
        );
      } catch (ex) {}
      return retVal;
    }
    return null;
  }

  /** retrieve and parse JWT token */
  getJWT(): any {
    const tokenString = this.getToken();
    if (tokenString) {
      return this.parseJWT(tokenString);
    } else {
      return null;
    }
  }

  login(username: string, password: string): Observable<boolean> {
    const url = environment.baseUrl + environmentConstant.getToken;
    let headers: HttpHeaders;

    headers = new HttpHeaders({
      authorization: "Basic " + btoa(username + ":" + password),
      "Content-Type": "application/json",
      AppName: "MPB-Web",
    });

    return this.http.get(url, { headers: headers }).pipe(
      map((response: string) => {
        if ((response != undefined && response != "") || response != null) {
          localStorage.setItem("token", response);
          return true;
        } else {
          return false;
        }
      })
    );
  }
  logout(): void {
    this.token = null;

    this.localStorageService.clear();

    this.sharedService.dispose();
    this.router.navigate(["login"]);
  }
}
