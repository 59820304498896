<div class="container-fluid">
  <div class="row mb-2">
    <div class="col-8"></div>
    <div class="col-4 text-right">
      <button (click)="reloadGrid($event)" class="k-button reload">
        Reload <span class="k-icon k-i-reload-sm"></span>
      </button>
    </div>
  </div>
  <kendo-grid [data]="jobList">
    <kendo-grid-column
      headerClass="gridHeading"
      class="gridRow"
      title=""
      width="30"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <div class="dropdown cud">
          <i class="fa fa-ellipsis-v" id="menu1" data-toggle="dropdown"> </i>
          <ul class="dropdown-menu" aria-labelledby="menu1">
            <li>
              <a (click)="viewDetail(dataItem.ID)">
                <i class="fa fa-eye"></i>{{ "actions.view" | translate }}
              </a>
            </li>
          </ul>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      headerClass="gridHeading"
      class="gridRow"
      field="JobName"
      title="Name"
    ></kendo-grid-column>
    <kendo-grid-column
      headerClass="gridHeading"
      class="gridRow text-center"
      field="JobStatus.Name"
      title="Status"
      [width]="60"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <i
          class="fa fa-circle"
          [attr.title]="dataItem.JobStatus ? dataItem.JobStatus.Name : ''"
          [ngClass]="{
            'text-warning': dataItem.JobStatusId === 2,
            'text-success': dataItem.JobStatusId === 3,
            'text-muted': dataItem.JobStatusId === 1
          }"
        ></i>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      headerClass="gridHeading"
      class="gridRow"
      field="JobTypeId"
      title="Type"
      [width]="60"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <svg
          *ngIf="dataItem.JobTypeId === 2"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0.00 0.00 24.00 22.00"
          style="height: 24px"
        >
          <path
            fill="#002060"
            d="   M 15.22 4.32   L 13.33 3.11   C 12.92 2.85 12.88 2.54 13.22 2.19   L 14.47 0.89   C 14.77 0.58 15.12 0.51 15.51 0.69   C 17.95 1.78 19.78 3.58 20.99 6.09   C 21.12 6.36 21.07 6.69 20.86 6.91   L 19.46 8.43   C 19.23 8.67 18.84 8.64 18.66 8.37   L 17.38 6.51   C 17.15 6.17 16.89 6.15 16.60 6.45   L 14.88 8.21   C 14.59 8.51 14.28 8.53 13.95 8.27   L 13.50 7.90   C 13.09 7.57 13.08 7.22 13.45 6.86   L 15.30 5.06   C 15.58 4.78 15.55 4.53 15.22 4.32   Z"
          ></path>
          <path
            fill="#002060"
            d="   M 9.93 5.73   L 19.11 14.48   C 19.54 14.88 19.54 15.28 19.11 15.69   L 18.61 16.17   C 18.17 16.58 17.74 16.57 17.32 16.14   L 8.57 7.11   C 8.22 6.75 7.85 6.74 7.48 7.08   C 7.07 7.46 6.58 7.74 6.01 7.91   C 3.88 8.56 3.39 7.93 4.53 6.02   C 5.05 5.14 5.37 4.21 5.50 3.24   C 5.56 2.77 5.83 2.55 6.30 2.58   C 7.12 2.65 7.74 2.32 8.15 1.60   C 8.55 0.89 9.04 0.82 9.63 1.39   L 10.77 2.49   C 11.49 3.19 11.43 3.81 10.58 4.34   L 10.04 4.68   C 9.55 4.99 9.52 5.34 9.93 5.73   Z"
          ></path>
          <rect
            fill="#002060"
            x="-3.68"
            y="-1.19"
            transform="translate(8.38,13.29) rotate(-44.3)"
            width="7.36"
            height="2.38"
            rx="0.55"
          ></rect>
        </svg>
        <span *ngIf="dataItem.JobTypeId != 2"
          ><i
            title="Sales"
            class="fa fa-usd"
            style="color: rgb(0, 32, 96); margin-left: 9px; font-size: 12pt"
            aria-hidden="true"
          ></i
        ></span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      headerClass="gridHeading"
      class="gridRow"
      field="StartDate"
      title="Started"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.StartDate | date : "M/d/yyyy" }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      headerClass="gridHeading"
      class="gridRow"
      field="CompleteDate"
      title="Completed"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.CompleteDate | date : "M/d/yyyy" }}
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
  <div class="fixbtnwrap">
    <div class="btnwrap">
      <button type="button" class="btn btn-cancel" (click)="onCancel()">
        Close
      </button>
    </div>
  </div>
</div>
