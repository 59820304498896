import { FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { MaterialCalculator } from "./MaterialsCalculator";
import { UnitOfMeasure } from "./UnitOfMeasure";
import { SelectItem } from "primeng/primeng";

export interface Parameter {
  id: number;
  parameterName: string;
  unitOfMeasureId: number;
  unitOfMeasureName?: string;
  systemName: string;
  orgGuid: string;
  lastUpdatedBy: string;
  createdBy: string;
  lastUpdatedOn: Date;
  createdOn: Date;
  deletedOn?: any;
  unitOfMeasure: UnitOfMeasure;
  entryType: number;
  numberType: ValueTypeValidationEnum;
  materialsCalculator?: MaterialCalculator;
  canShow: boolean;
  visibleParameterGroupId: number;
}

export interface ParameterRowOntable {
  data: {
    id: number;
    parameterName: string;
    unitOfMeasure: UnitOfMeasure;
    entryType: MaterialCalculator;
    numberType: UnitType;
    showOnCustomerProposal: boolean;
    customValue?: string;
    valueType: ValueType;
  };
  entryTypeOptions: SelectItem[];
  isInCreationProcess: boolean;
  edit: boolean;
  form?: FormGroup;
}

export interface ParametersTableColumns {
  parameterName: string;
  unitOfMeasureId: number;
  entryType: string;
  valueType: number;
}

export interface ParameterFromDB {
  id: number;
  parameterName: string;
  unitOfMeasureId: number;
  systemName: string;
  orgGuid: string;
  entryType: MaterialCalculator;
  numberType: ValueTypeValidationEnum;
  valueType: ValueTypeEnum;
  showOnCustomerProposal: boolean;
  customValue?: string;
}

export interface ParameterPaginated {
  itemsList: ParameterFromDB[];
  skip: number;
  itemsPerPage: number;
  totalItems: number;
}

export interface UnitType {
  label: string;
  value: ValueTypeValidationEnum;
}

export interface ValueType {
  label: string;
  value: ValueTypeEnum;
}

export enum ValueTypeValidationEnum {
  ZeroDecimals = 0,
  TwoDecimals = 2,
  FourDecimals = 4,
}

export enum ValueTypeEnum {
  Number = 1,
  Text = 2,
  YesNo = 3,
  DropDownNumeric = 4,
  DropDownText = 5,
}

/**
 * Extension DecimalDigitValidation
 */
export class DecimalDigitValidationExtension {
  static decimalValidation(digit: ValueTypeValidationEnum): ValidatorFn {
    switch (digit) {
      case ValueTypeValidationEnum.ZeroDecimals:
        return Validators.pattern(/^(?:[\d,]+)$/);
      case ValueTypeValidationEnum.TwoDecimals:
        return Validators.pattern(/^(?:[\d,]*\.\d{1,2}|[\d,]+)$/);
      case ValueTypeValidationEnum.FourDecimals:
        return Validators.pattern(/^(?:[\d,]*\.\d{1,4}|[\d,]+)$/);
    }
  }

  static decimalMsgValidation(digit: ValueTypeValidationEnum): string {
    switch (digit) {
      case ValueTypeValidationEnum.ZeroDecimals:
        return `Integer`;
      case ValueTypeValidationEnum.TwoDecimals:
        return `2 Decimals`;
      case ValueTypeValidationEnum.FourDecimals:
        return `4 Decimals`;
    }
  }
}

export interface ParameterToBeInserted {
  parameterName: string;
  unitOfMeasureId: number;
  entryType: number;
  valueType: number;
  orgGuid: string;
  customValue?: string;
  showOnCustomerProposal: boolean;
}

export interface ParamaterToUpdate {
  id: number;
  parameterName: string;
  unitOfMeasureId: number;
  entryType: number;
  valueType: number;
  orgGuid: string;
  customValue?: string;
  showOnCustomerProposal?: boolean;
}

export interface CheckIfAffectsWorksheetsResponse {
  affectsOtherWorksheets: boolean;
  affectedWorksheetsIds: number[];
}
