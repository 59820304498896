import {
  Component,
  Input,
  EventEmitter,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { JobNoteService } from "../../common/services/jobNotes.service";
import { JobNoteModel } from "../../model/jobNoteModel";
import { BaThemeSpinner } from "../../theme/services";
import { DialogService } from "ng2-bootstrap-modal";
import { ConfirmComponent } from "app/component/dialog/dialog.component";
import { SharedService } from "app/common/utility/SharedService";
import { Helper } from "app/common/utility/helper";
import { ToastrService } from "ngx-toastr";
import { EditorComponent } from "@tinymce/tinymce-angular";
import { TextEditorConfigService } from "../../common/services/textEditorConfig.service";

@Component({
  selector: "app-job-note",
  templateUrl: "./job-note.component.html",
  styleUrls: ["./job-note.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class JobNoteComponent {
  @Input() note: JobNoteModel;
  @Input() isSales: boolean = false;
  @Output() deleted = new EventEmitter<void>();
  loggedInUser: string;

  jobNameDisplay: string;
  editingNote: JobNoteModel = null;
  showShareWithPhases: boolean = false;
  editorConfig: EditorComponent["init"];
  constructor(
    private jobNoteService: JobNoteService,
    private _spinner: BaThemeSpinner,
    private sharedService: SharedService,
    private dialogService: DialogService,
    private toastr: ToastrService,
    private textEditorConfig: TextEditorConfigService
  ) {}

  ngOnInit() {
    this.editorConfig = { ...this.textEditorConfig.editorConfig, height: 200 };
    try {
      this.loggedInUser =
        this.sharedService.loggedInUser.FirstName +
        " " +
        (this.sharedService.loggedInUser.LastName
          ? this.sharedService.loggedInUser.LastName
          : "");
    } catch {}
    this.showShareWithPhases = !this.note.JobPhaseId && !this.isSales;
  }
  getInitals(str: string) {
    return Helper.getInitials(str);
  }

  updateJobNote(note: JobNoteModel) {
    this._spinner.show();
    this.jobNoteService.updateJobNote(note).subscribe((result) => {
      this.editingNote = null;
      // TODO: high z index for this toaster
      this.result(result, "Updated Successfully!");
    });
  }
  deleteJobNote(index: number, note: JobNoteModel) {
    this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Delete Note",
        message: "Are you sure you want to delete this note?",
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          this._spinner.show();
          this.jobNoteService.deleteJobNote(note.ID).subscribe((result) => {
            this.editingNote = null;
            this.deleted.emit();
            this.result(result, "Deleted Successfully!");
          });
        }
      });
  }

  private result(res: any, message: string) {
    // this.notificationsService.add({
    //   severity: "success",
    //   summary: "Note",
    //   detail: message,
    // });
    this.toastr.success(message, "Note");
    this._spinner.hide();
  }
}
