import { KitGroup, UpdateKitGroupDTO } from "./../../model/KitModel";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CheckIfAffectsWorksheets } from "app/model/CheckIfAffectsWorksheet";
import {
  Calculators,
  ComplexKitDetails,
  KitDetailOption,
  KitDetails,
  KitGroups,
  UpdateKitDetailDTO,
} from "app/model/KitDetails";
import { KitWorksheetAttachabilityResponse } from "app/model/KitWorksheetAttachabilityResponse";
import { environment } from "environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class KitDetailsService {
  private baseUrl = environment.baseEstimateUrl;
  constructor(private http: HttpClient) {}

  getKitDetails(productId: number): Observable<KitDetails[]> {
    const url = `${this.baseUrl}KitDetails/${productId}`;
    return this.http.get<KitDetails[]>(url);
  }
  getComplexKitDetails(
    productId: number,
    productTypes: number[]
  ): Observable<ComplexKitDetails[]> {
    const url = `${this.baseUrl}ComplexKitDetails/${productId}`;
    return this.http.post<ComplexKitDetails[]>(url, productTypes);
  }

  getAllCalculators(orgGuid: string): Observable<Calculators[]> {
    const url = `${this.baseUrl}materialscalculator/${orgGuid}`;
    return this.http.get<Calculators[]>(url);
  }

  getAllKitGroups(orgGuid: string, origin = 0) {
    const url = `${this.baseUrl}Kitgroups/${orgGuid}/${origin}`;
    return this.http.get<KitGroups[]>(url);
  }

  getGridKitGroups(orgGuid: string, origin = 1) {
    const url = `${this.baseUrl}Kitgroups/${orgGuid}/${origin}`;
    return this.http.get<KitGroup[]>(url);
  }

  bulk(model: KitDetails): Observable<Object> {
    const url = `${this.baseUrl}kitDetails/bulk`;
    return this.http.post(url, model);
  }

  editRequired(id: number, checked: { checked: boolean }) {
    return this.http.post(`${this.baseUrl}kitdetails/${id}/required`, checked);
  }

  editEditableQty(kitDetailId: number, checked: { checked: boolean }) {
    return this.http.patch(
      `${this.baseUrl}kitdetails/${kitDetailId}/editEditableQty`,
      checked
    );
  }
  editShowPrice(kitDetailId: number, checked: { checked: boolean }) {
    return this.http.patch(
      `${this.baseUrl}kitdetails/${kitDetailId}/showPricing`,
      checked
    );
  }
  editUseKitPricing(id: number, checked: { checked: boolean }) {
    return this.http.post(
      `${this.baseUrl}products/${id}/usekitprincing`,
      checked
    );
  }

  deleteProduct(productId: number) {
    return this.http.delete(`${this.baseUrl}${productId}`);
  }
  saveGroup(kitProductId: number, group: KitGroups) {
    return this.http.post(
      `${this.baseUrl}Kitdetails/${kitProductId}/groups`,
      group
    );
  }

  getKitDetailsValid(kitDetailsComplex: ComplexKitDetails[]): boolean {
    let detailsComplexITemQty: number = 0;
    kitDetailsComplex.forEach((item) => {
      let sumItems = item.details.filter((x) => {
        return x.type == "Item" && x.id !== 0;
      }).length;
      detailsComplexITemQty += sumItems;
    });
    let detailsComplexISimpleKitQty: number = 0;
    kitDetailsComplex.forEach((item) => {
      let sumItems = item.details.filter((x) => {
        return x.type == "Kit" && x.id !== 0;
      }).length;
      detailsComplexISimpleKitQty += sumItems;
    });

    return detailsComplexITemQty > 0 && detailsComplexISimpleKitQty > 0;
  }

  /** Returns if a worksheet have the required parameters for a set of calculators, and missing parameters if any */
  checkKitWorksheetAvailability(
    calculatorIds: number[],
    worksheetId: number,
    isForProduct: boolean = false
  ): Observable<KitWorksheetAttachabilityResponse> {
    return this.http.post<KitWorksheetAttachabilityResponse>(
      `${this.baseUrl}KitDetails/worksheet/${worksheetId}`,
      { calculatorIds, isForProduct }
    );
  }

  /**
   * Get all kit detail options.
   * @returns All kit detail options.
   */
  getAllKitDetailOptions() {
    return this.http.get<KitDetailOption[]>(`${this.baseUrl}KitDetailOptions`);
  }

  /**
   * Updates a kit detail.
   * @param id A kit detail Id.
   * @param properties A properties to update.
   */
  updateKitDetail(id: number, properties: UpdateKitDetailDTO) {
    return this.http.patch(`${this.baseUrl}KitDetails/${id}`, properties);
  }

  /**
   * Updates a kit group.
   * @param id A kit group Id.
   * @param properties A properties to update.
   */
  updateKitGroup(id: number, kitGroup: UpdateKitGroupDTO) {
    return this.http.put(`${this.baseUrl}kitgroup/${id}`, kitGroup);
  }

  /**
   * Delete kit group
   * @param kitGroupId
   * @returns
   */
  deleteKitGroup(kitGroupId: number) {
    return this.http.delete(`${this.baseUrl}kitgroup/${kitGroupId}`);
  }
}
