import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { WorkOrderService } from "app/common/services/workOrder.service";
import { BaThemeSpinner } from "app/theme/services";
import { WorkOrderModel } from "app/model/workOrderModel ";
import {
  SharedService,
  CommonSubscription,
} from "app/common/utility/SharedService";
import { JobListModelV2 } from "app/model/jobListModelV2";
import { MessageService } from "primeng/components/common/messageservice";
import { JObChangeOrderModel } from "app/model/jobChangeOrderModel";
import { WorkOrderLineItemModel } from "app/model/WorkOrderLineItemModel";
import { Resource } from "app/model/ResourceModel";
import { DialogService } from "ng2-bootstrap-modal";
import { ConfirmComponent } from "app/component/dialog/dialog.component";
import { JobResourcesRequestPayload } from "app/model/JobAssignResourceModel";

@Component({
  selector: "Add-LineItem-Component",
  templateUrl: "./AddLineItem.component.html",
  styleUrls: ["./AddLineItem.component.css"],
})
export class AddLineItemComponent implements OnInit {
  headerText: string = "";
  jobId: any;
  changeOrderId: any;
  workOrder: WorkOrderModel;
  jobsList: JobListModelV2[] = [];
  originalJobsList = [];
  parentJobChangeOrder: JObChangeOrderModel = new JObChangeOrderModel();
  visibleSidebar: boolean = false;
  showAddEdit: boolean = false;
  buttonText: string = "";
  workOrderlineItem: WorkOrderLineItemModel;
  statusLst: any[] = [];
  originalStatusList: any[] = [];
  lstCategory: any[] = [];
  originalCategoryList: any[] = [];
  showSlides3: string = "";
  isUpdated: boolean = false;
  lstAssignedResource: any;
  EnableStartDateEdit: boolean = false;
  @Input() WorkOrderID: any;
  @Input() lineItemId: number;
  @Input() isAddWorkOrder: boolean;
  @Output() updateData: EventEmitter<any> = new EventEmitter<any>();
  previousStartDate: Date;
  previousResources: any = [];
  assignResourceModel: any;

  constructor(
    private route: ActivatedRoute,
    private workOrderService: WorkOrderService,
    private _spinner: BaThemeSpinner,
    private sharedService: SharedService,
    private notificationsService: MessageService,
    private dialogService: DialogService,
    private commonsubs: CommonSubscription
  ) {
    this.workOrder = new WorkOrderModel();
    this.workOrderlineItem = new WorkOrderLineItemModel();
    this.workOrderlineItem.Resources = new Array<Resource>();
    this.workOrder.OrgGuid = this.sharedService.selectedOrganization.ID;
  }

  ngOnInit() {
    let that: any;
    that = this;
    if (this.lineItemId == 0) {
      this.headerText = "ADD";
      this.buttonText = "Save";
    } else {
      this.headerText = "EDIT";
      this.buttonText = "Update";
    }
    that.getStatus();
    that.getCategory();
    this.getLineItemById();
    this.route.params.subscribe((params) => {
      if (params["id"] && params["id"] != 0) {
        that.workOrder.ParentJobGuid = params["id"];
      }
      if (params["ChangeOrderId"]) {
        that.changeOrderId = params["ChangeOrderId"];
        that.workOrder.ParentJobGuid = params["ChangeOrderId"];
        that.jobChangeOrderService
          .getJobChangeOrdersByID(that.changeOrderId)
          .subscribe((data) => {
            that.parentJobChangeOrder = data;
          });
      }
    });
  }
  getLineItemById() {
    if (this.lineItemId > 0) {
      this.workOrderService
        .getLineItemById(this.lineItemId)
        .subscribe((data) => {
          this.workOrderlineItem = data;
          this.workOrderlineItem.ScheduledDate = this.workOrderlineItem
            .ScheduledDate
            ? new Date(this.workOrderlineItem.ScheduledDate)
            : null;
          this.workOrderlineItem.CompleteDate = this.workOrderlineItem
            .CompleteDate
            ? new Date(this.workOrderlineItem.CompleteDate)
            : null;
          this.workOrderlineItem.StartDate = this.workOrderlineItem.StartDate
            ? new Date(this.workOrderlineItem.StartDate)
            : null;
          this.workOrderlineItem.TargetDate = this.workOrderlineItem.TargetDate
            ? new Date(this.workOrderlineItem.TargetDate)
            : null;
          this.workOrderlineItem.RemindMeOnDay = this.workOrderlineItem
            .RemindMeOnDay
            ? new Date(this.workOrderlineItem.RemindMeOnDay)
            : null;
          if (
            this.workOrderlineItem.Resources &&
            this.workOrderlineItem.Resources.length > 0
          ) {
            this.lstAssignedResource = JSON.parse(
              JSON.stringify(this.workOrderlineItem.Resources)
            );
            this.previousResources = JSON.parse(
              JSON.stringify(this.workOrderlineItem.Resources)
            );
          } else {
            this.lstAssignedResource = [];
            this.previousResources = [];
          }
          this._spinner.hide();
        });
    }
  }
  getStatus() {
    this._spinner.show();
    this.workOrderService.getStatusId(12).subscribe((data) => {
      data.map(n => n.Id = n.Value);
      this.statusLst = data;
      this.workOrderlineItem.StatusId = this.statusLst.find(
        (item) => item.Status == "Not Started"
      )
        ? this.statusLst.find((item) => item.Status == "Not Started").Id
        : null;
      this.originalStatusList = data;
      this._spinner.hide();
    });
  }
  changeCompleteDateStatus(completeDate) {
    if (!completeDate) return;

    this.workOrderlineItem.StatusId = 16;
    if (!this.workOrderlineItem.StartDate) {
      this.workOrderlineItem.StartDate = new Date();
    }
  }
  getCategory() {
    this.workOrderService
      .getCategoriesByEntityId(this.sharedService.selectedOrganization.ID, 4)
      .subscribe((data) => {
        this.lstCategory = data;
        this.originalCategoryList = data;
        this._spinner.hide();
      });
  }
  public filterStatus(filter: any): void {
    this.statusLst = this.originalStatusList.filter(
      (s) => s.Status.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }
  public filterCategory(filter: any): void {
    this.lstCategory = this.originalCategoryList.filter(
      (s) => s.CatetoryName.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }
  getInitals(str) {
    if (!str) return "";
    var result = str
      .split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), "");
    if (result && result.length > 2) {
      return result.slice(0, 2);
    }
    return result;
  }
  updateAssignedResource(event) {
    if (event == "cancel") {
      this.showSlides3 = "";
    } else {
      if (event.length > 0) {
        event.forEach((element) => {
          this.workOrderlineItem.Resources.push(element);
        });
        this.lstAssignedResource = JSON.parse(
          JSON.stringify(this.workOrderlineItem.Resources)
        );
        this.isUpdated = true;
      }
      this.showSlides3 = "";
    }
  }
  deleteAssignedResource(assignedId) {
    var assignedTo = this.workOrderlineItem.Resources.find(
      (obj) => obj.Id == assignedId
    );
    var index = this.workOrderlineItem.Resources.findIndex(
      (obj) => obj.Id == assignedId
    );
    const disposable = this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Delete Assigned Resource",
        // tslint:disable-next-line:prefer-template
        message:
          "Are you sure you want to delete assigned resource" +
          " " +
          assignedTo.Name +
          "?",
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this._spinner.show();
          if (this.lineItemId > 0) {
            this.workOrderService
              .deleteAssignedResources(
                assignedId,
                this.WorkOrderID,
                this.lineItemId,
                assignedTo.IsExternal
              )
              .subscribe(
                (result) => {
                  this.result(
                    result,
                    "Deleted Successfully!!",
                    "Assign Resource"
                  );
                  this.workOrderlineItem.Resources.splice(index, 1);
                  this.isUpdated = true;
                  this._spinner.hide();
                },
                (error) =>
                  this.error(error, "Unable to Delete assigned resource")
              );
          }
          if (this.lineItemId == 0) {
            this.workOrderlineItem.Resources.splice(index, 1);
            this.lstAssignedResource = JSON.parse(
              JSON.stringify(this.workOrderlineItem.Resources)
            );
            this.result(true, "Deleted Successfully!!", "Assign Resource");
          }
          this._spinner.hide();
        }
      });
  }
  saveWorkOrderLineItem() {
    this._spinner.show();
    if (this.workOrderlineItem.StartDate) {
      this.workOrderlineItem.StartDate = new Date(
        this.workOrderlineItem.StartDate
      );
      this.workOrderlineItem.StartDate = new Date(
        Date.UTC(
          this.workOrderlineItem.StartDate.getFullYear(),
          this.workOrderlineItem.StartDate.getMonth(),
          this.workOrderlineItem.StartDate.getDate()
        )
      );
    }
    if (this.workOrderlineItem.ScheduledDate) {
      this.workOrderlineItem.ScheduledDate = new Date(
        this.workOrderlineItem.ScheduledDate
      );
      this.workOrderlineItem.ScheduledDate = new Date(
        Date.UTC(
          this.workOrderlineItem.ScheduledDate.getFullYear(),
          this.workOrderlineItem.ScheduledDate.getMonth(),
          this.workOrderlineItem.ScheduledDate.getDate()
        )
      );
    }
    if (this.workOrderlineItem.CompleteDate) {
      this.workOrderlineItem.CompleteDate = new Date(
        this.workOrderlineItem.CompleteDate
      );
      this.workOrderlineItem.CompleteDate = new Date(
        Date.UTC(
          this.workOrderlineItem.CompleteDate.getFullYear(),
          this.workOrderlineItem.CompleteDate.getMonth(),
          this.workOrderlineItem.CompleteDate.getDate()
        )
      );
    }
    if (this.workOrderlineItem.RemindMeOnDay) {
      this.workOrderlineItem.RemindMeOnDay = new Date(
        this.workOrderlineItem.RemindMeOnDay
      );
      this.workOrderlineItem.RemindMeOnDay = new Date(
        Date.UTC(
          this.workOrderlineItem.RemindMeOnDay.getFullYear(),
          this.workOrderlineItem.RemindMeOnDay.getMonth(),
          this.workOrderlineItem.RemindMeOnDay.getDate()
        )
      );
    }
    if (this.workOrderlineItem.StartDate) {
      this.workOrderlineItem.StartDate = new Date(
        this.workOrderlineItem.StartDate
      );
      this.workOrderlineItem.StartDate = new Date(
        Date.UTC(
          this.workOrderlineItem.StartDate.getFullYear(),
          this.workOrderlineItem.StartDate.getMonth(),
          this.workOrderlineItem.StartDate.getDate()
        )
      );
    }
    if (this.workOrderlineItem.TargetDate) {
      this.workOrderlineItem.TargetDate = new Date(
        this.workOrderlineItem.TargetDate
      );
      this.workOrderlineItem.TargetDate = new Date(
        Date.UTC(
          this.workOrderlineItem.TargetDate.getFullYear(),
          this.workOrderlineItem.TargetDate.getMonth(),
          this.workOrderlineItem.TargetDate.getDate()
        )
      );
    }
    this.workOrderlineItem.OrgGuid = this.sharedService.selectedOrganization.ID;
    if (this.lineItemId == 0) {
      this.workOrderService
        .saveWorkOrderLineitem(
          this.WorkOrderID,
          this.isAddWorkOrder,
          this.workOrderlineItem
        )
        .subscribe(
          (result) => {
            this.commonsubs.updateAssignedResource(true);
            this.result(result, "Added Successfully!!", "Line Item");
            this.updateData.emit(true);
          },
          (error) => {
            this.error(error, "Unable to Add new Work Order Line Item!!");
          }
        );
    } else {
      this.workOrderService
        .updateWorkOrderLineitem(this.isAddWorkOrder, this.workOrderlineItem)
        .subscribe(
          (result) => {
            let yFilter = this.previousResources.map((itemY) => {
              return itemY.Id;
            });
            let filteredAssignTo = this.workOrderlineItem.Resources.filter(
              (itemX) => !yFilter.includes(itemX.Id)
            );
            if (filteredAssignTo.length > 0) {
              this.assignResourceModel = new JobResourcesRequestPayload();
              this.assignResourceModel.Resources = filteredAssignTo;
              this.assignResourceModel.JobPhaseId = this.workOrderlineItem.ID;
              this.assignResourceModel.JobID = this.WorkOrderID;
              this.workOrderService
                .saveAssignedResource(this.assignResourceModel)
                .subscribe((result) => {
                  this.result(result, "Updated Successfully!!", "Line Item");
                  this.updateData.emit(true);
                });
            } else {
              this.result(result, "Updated Successfully!!", "Line Item");
              this.updateData.emit(true);
            }
            //this.commonsubs.updateAssignedResource(true);
          },
          (error) => {
            this.error(error, "Unable to Add new Work Order Line Item!!");
          }
        );
    }
  }
  private error(error: any, message) {
    this.notificationsService.add({
      severity: "warn",
      summary: "Work Order",
      detail: message,
    });
    this._spinner.hide();
  }
  private result(res: any, message, title) {
    if (res !== null) {
      this.notificationsService.add({
        severity: "success",
        summary: title,
        detail: message,
      });
      this._spinner.hide();
    }
  }
  onCanel() {
    this.updateData.emit(false);
  }
  onStatusChange() {
    if (this.workOrderlineItem.StatusId === 15) {
      if (!this.workOrderlineItem.StartDate) {
        this.workOrderlineItem.CompleteDate = null;
        this.workOrderlineItem.StartDate = new Date();
      } else {
        this.workOrderlineItem.CompleteDate = null;
      }
    } else if (
      this.workOrderlineItem.StatusId === 16 &&
      !this.workOrderlineItem.CompleteDate
    ) {
      this.workOrderlineItem.StartDate = new Date();
      this.workOrderlineItem.CompleteDate = new Date();
    } else if (this.workOrderlineItem.StatusId === 14) {
      this.workOrderlineItem.StartDate = null;
      this.workOrderlineItem.CompleteDate = null;
    }
  }
  editStartDate(IsEdit) {
    if (IsEdit) {
      this.EnableStartDateEdit = true;
      this.previousStartDate = this.workOrderlineItem.StartDate;
    } else {
      this.EnableStartDateEdit = false;
      this.workOrderlineItem.StartDate = this.previousStartDate;
    }
  }
}
