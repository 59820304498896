import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";
import { environmentConstant } from "app/common/utility/environment";
import { Observable } from "rxjs";
import { PasswordModel } from "app/model/PasswordModel";

@Injectable()
// tslint:disable:prefer-template
export class changePasswordService {
  constructor(private httpService: HttpService) {}

  updatePassword(Password: PasswordModel): any {
    return this.httpService.putRequestService(
      environmentConstant.changePassword,
      Password
    );
  }
}
