<div class="editjobtabform new_work_order hoverflow padding-top-0">
  <div class="mt0">
    <div class="row">
      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-12">
        <label class="checkbox-inline custom-checkbox nowrap">
          <input
            type="checkbox"
            name="IsPublic"
            [(ngModel)]="punchlistV2.IsPublic"
          />
          <span>Public</span>
        </label>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <label class="checkbox-inline custom-checkbox nowrap">
          <input
            type="checkbox"
            [(ngModel)]="punchlistV2.IsWarranty"
            name="IsWarranty"
            type="checkbox"
            (change)="onChangeWaranty()"
          />
          <span>Is this a warranty item?</span>
        </label>
      </div>
      <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <div class="ccol1" id="billTo">
              <div class="Customer">
                <span class="Customertxt">
                  <span>Job</span>
                </span>
                <address class="address">
                  <span *ngIf="punchlistV2 && punchlistV2.Address">
                    <span>{{ punchlistV2.JobName }}</span>
                    <span>{{ punchlistV2.Address.Address1 }}</span>
                    <span
                      >{{ punchlistV2.Address.City }}
                      <span
                        *ngIf="
                          punchlistV2.Address.City && punchlistV2.Address.State
                        "
                        >,</span
                      >
                      {{ punchlistV2.Address.State
                      }}{{
                        punchlistV2.Address.State &&
                        punchlistV2.Address.StateProvinceAbbreviation
                          ? ","
                          : ""
                      }}{{ punchlistV2.Address.StateProvinceAbbreviation
                      }}{{
                        (punchlistV2.Address.State &&
                          punchlistV2.Address.ZipPostalCode) ||
                        (punchlistV2.Address.StateProvinceAbbreviation &&
                          punchlistV2.Address.ZipPostalCode)
                          ? ","
                          : ""
                      }}{{ punchlistV2.Address.ZipPostalCode }}
                    </span>
                    <span>{{ punchlistV2.Phone }}</span>
                  </span>
                </address>
                <button
                  type="button"
                  class="order cursor-pointer"
                  *ngIf="isFindJob"
                  (click)="showSlides = 'findJob'"
                >
                  <i class="fa fa-search"></i> Find Job
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-5 col-md-5 col-sm-6 col-xs-12 pt10">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb12">
            <div class="form-group mb5">
              <span class="ui-float-label">
                <kendo-floatinglabel text="Priority">
                  <kendo-combobox
                    [data]="priorities"
                    name="Priority"
                    [filterable]="true"
                    [valuePrimitive]="true"
                    (filterChange)="filterPriority($event)"
                    [textField]="'key'"
                    [(ngModel)]="punchlistV2.Priority"
                    [valueField]="'value'"
                  >
                  </kendo-combobox>
                </kendo-floatinglabel>
              </span>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb5">
            <div class="form-group mb5 Mandat">
              <kendo-floatinglabel text="Status">
                <kendo-combobox
                  [data]="statusLst"
                  [valuePrimitive]="true"
                  [filterable]="true"
                  (filterChange)="filterStatus($event)"
                  [textField]="'Status'"
                  (valueChange)="onStatusChange()"
                  [(ngModel)]="punchlistV2.StatusId"
                  name="Status"
                  [valueField]="'Id'"
                >
                </kendo-combobox>
              </kendo-floatinglabel>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12 mt15">
        <div class="form-group mb0 Mandat">
          <span class="ui-float-label">
            <input
              [appFocus]="true"
              type="text"
              [(ngModel)]="punchlistV2.LineItemName"
              id="LineItemName"
              name="LineItemName"
              #LineItemName="ngModel"
              maxlength="50"
              class="form-control"
              pInputText
              required
              [ngClass]="{
                'has-error':
                  LineItemName.invalid &&
                  (LineItemName.dirty || LineItemName.touched)
              }"
            />
            <label for="text" class="form-control-label text-overflow" required
              >Item Name
            </label>
          </span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 mt15 mb5">
        <div class="form-group mb0">
          <kendo-floatinglabel text="Category">
            <kendo-combobox
              [data]="lstCategory"
              [valuePrimitive]="true"
              [filterable]="true"
              (filterChange)="filterCategory($event)"
              [textField]="'CatetoryName'"
              [(ngModel)]="punchlistV2.CategoryId"
              name="CategoryId"
              [valueField]="'Id'"
            ></kendo-combobox>
          </kendo-floatinglabel>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 mt15">
        <div class="form-group">
          <kendo-floatinglabel text="Business Unit">
            <kendo-combobox
              [data]="lstBusinessUnit"
              [valuePrimitive]="true"
              [filterable]="true"
              (filterChange)="filterBussinessUnit($event)"
              [textField]="'BusinessUnit1'"
              [(ngModel)]="punchlistV2.BusinessUnitId"
              name="businessUnitId"
              [valueField]="'ID'"
            >
            </kendo-combobox>
          </kendo-floatinglabel>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12 mt15">
        <div class="form-group mb0">
          <label for="" class="fu">Reminder On</label>
          <span class="ui-float-label">
            <kendo-datepicker
              class="full-date full-height"
              name="RemindMeOnDay"
              #RemindMeOnDay
              [(ngModel)]="punchlistV2.RemindMeOnDay"
              placeholder=""
            ></kendo-datepicker>
          </span>
        </div>
      </div>
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="background-p2 addpunchlist-bg">
          <div class="row p2row-bg">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <h3 class="pt">Schedule</h3>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div class="form-group">
                <label class="fu">Target Date</label>
                <span class="ui-float-label">
                  <kendo-datepicker
                    class="full-date"
                    name="TargetDate"
                    [(ngModel)]="punchlistV2.TargetDate"
                    #TargetDate="ngModel"
                  ></kendo-datepicker>
                </span>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div class="form-group">
                <label class="fu">Scheduled Date</label>
                <span class="ui-float-label">
                  <kendo-datepicker
                    class="full-date"
                    name="ScheduledDate"
                    [(ngModel)]="punchlistV2.ScheduledDate"
                    #ScheduledDate="ngModel"
                  >
                  </kendo-datepicker>
                </span>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div class="form-group mar15-p2">
                <label class="fu">Completed On</label>
                <span class="ui-float-label p2-float-label">
                  <kendo-datepicker
                    class="full-date"
                    name="CompleteDate"
                    (valueChange)="
                      changeCompleteDateStatus(punchlistV2.CompleteDate)
                    "
                    [(ngModel)]="punchlistV2.CompleteDate"
                    [ngClass]="{
                      'has-error':
                        CompleteDate.invalid &&
                        (CompleteDate.dirty || CompleteDate.touched)
                    }"
                    #CompleteDate="ngModel"
                  ></kendo-datepicker>
                </span>
              </div>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <div class="form-group">
                <span class="ui-float-label p2-float-label">
                  <input
                    type="number"
                    class="form-control bg-white"
                    min="1"
                    pInputText
                    name="DaysToComplete"
                    [(ngModel)]="punchlistV2.DaysToComplete"
                    #DaysToComplete="ngModel"
                  />
                  <label for="number" class="form-control-label"
                    >Days To Complete</label
                  >
                </span>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="Calinmarket ml0">
                <p class="startedon_para mt0" *ngIf="punchlistV2.StartDate">
                  This punchlist was marked started on :
                  <span>
                    {{ punchlistV2.StartDate | date : "shortDate" }}
                    <span class="calin-icons">
                      <i
                        *ngIf="!EnableStartDateEdit"
                        class="fa fa-pencil"
                        title="Edit"
                        (click)="editStartDate(true)"
                      ></i>
                      <i
                        class="fa fa-ban"
                        *ngIf="EnableStartDateEdit"
                        title="Cancel"
                        (click)="editStartDate(false)"
                      ></i
                    ></span>
                  </span>
                </p>
              </div>
            </div>
            <div
              *ngIf="EnableStartDateEdit"
              class="col-lg-6 col-md-6 col-sm-6 col-xs-12"
            >
              <div class="form-group mb5">
                <label class="fu">Started On</label>
                <span class="ui-float-label">
                  <kendo-datepicker
                    class="full-date"
                    name="StartDate"
                    [(ngModel)]="punchlistV2.StartDate"
                    #StartDate="ngModel"
                  ></kendo-datepicker>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="mt0">
          <div class="form-group mb10">
            <span class="ui-float-label">
              <textarea
                class="form-control"
                name="Comments"
                maxlength="500"
                id="description"
                name="description"
                #description="ngModel"
                [(ngModel)]="punchlistV2.Description"
                rows="3"
                pInputText
              ></textarea>
              <label for="text" class="form-control-label">Description</label>
            </span>
          </div>
        </div>
        <div class="">
          <div class="additem assignto-item clearfix">
            <h3 class="bg-none p0">Assign To</h3>
            <div class="addbtn">
              <a
                data-placement="top"
                data-toggle="tooltip"
                title="Assign To"
                (click)="showSlides = 'showAssignTo'"
              >
                <i aria-hidden="true" class="fa fa-plus-circle"></i>
              </a>
            </div>
          </div>
          <div class="assigned-items">
            <ul>
              <li *ngFor="let resource of punchlistV2.Resources">
                <a
                  class="cancelbtnHead ui-sidebar-close"
                  (click)="deleteAssignedResource(resource.Id)"
                >
                  <span class="pi pi-times"></span>
                </a>
                <span
                  class="short-name"
                  [ngClass]="resource.IsExternal ? 'bg-green' : 'bg-blue'"
                  >{{ getInitals(resource.Name) }}</span
                ><span class="hover-content">{{ resource.Name }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div [ngClass]="showSlides != '' ? 'sidebar2 open ' : 'sidebar2'">
    <a class="cancelbtnHead ui-sidebar-close" (click)="showSlides = ''">
      <span class="pi pi-times"></span>
    </a>
    <assign-job
      *ngIf="showSlides == 'findJob'"
      (sendData)="getPunchlistData($event)"
    ></assign-job>
    <AssignResource-Component
      *ngIf="showSlides == 'showAssignTo'"
      [AssignedResourceList]="lstAssignedResource"
      [BusinessUnitID]="punchlistV2.BusinessUnitId"
      [workOrderId]="punchlistV2.JobID"
      [LineItemId]="punchlistV2.ID"
      (sendData)="updateAssignedResource($event)"
    ></AssignResource-Component>
  </div>
</div>
