import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { DialogService } from "ng2-bootstrap-modal";
import { ConfirmComponent } from "../dialog/dialog.component";
import { OrganizationService } from "app/common/services/Core/Organization.service";
import { BaThemeSpinner } from "app/theme/services";
import { SafeResourceUrl } from "@angular/platform-browser";
import { SharedService } from "app/common/utility/SharedService";
import { OrganizationModel } from "app/model/organizationModel";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "quickbooks-connector",
  templateUrl: "./QuickBooksConnector.component.html",
  styles: [],
})
export class QuickBooksConnectorComponent implements OnInit {
  @Output() connectedChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public connected: boolean;
  public disconnected: boolean;
  public redirectUrl: SafeResourceUrl;
  @Input() organization: OrganizationModel;
  @Output() organizationChange: EventEmitter<OrganizationModel> =
    new EventEmitter<OrganizationModel>();

  constructor(
    private _spinner: BaThemeSpinner,
    private dialogService: DialogService,
    private sharedService: SharedService,
    private organizationService: OrganizationService,
    private toastrService: ToastrService
  ) {}

  public ngOnInit(): void {
    this.getStatus();
  }

  private getStatus(): void {
    this._spinner.show();
    let subscription = this.organizationService
      .getQuickbooksStatus()
      .subscribe((response) => {
        this.connected = response.success && response.object;
        this.disconnected = !this.connected;
        this.sharedService.quickBooksConnected = this.connected;
        if (subscription) subscription.unsubscribe();
        this._spinner.hide();
      });
  }

  public onConnect(): void {
    this._spinner.show();
    let subscription = this.organizationService
      .connectQuickbooks()
      .subscribe((response) => {
        if (response.success) {
          window.open(response.object);
        }
        if (subscription) subscription.unsubscribe();
        this._spinner.hide();
      });
  }

  public onDisconnect(): void {
    this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Disconnect",
        message: `All Existing Data will be Disconnected,Are you sure you want to disconnect QuickBooks?`,
        toShow: false,
        isNotification: false,
      })
      .subscribe((confirmed) => {
        if (confirmed) {
          this._spinner.show();
          let subscription = this.organizationService
            .disconnectQuickbooks()
            .subscribe((response) => {
              if (response.success == true) {
                this.disconnected = response.object;
                this.connected = !this.disconnected;
                this.sharedService.quickBooksConnected = this.connected;
              } else {
                let error: any = response;
                this.toastrService.error(error.Response.Message);
              }
              if (subscription) subscription.unsubscribe();
              this._spinner.hide();
            });
        }
      });
  }
  organizationUpdated(newValue) {
    this.organization = newValue;
    this.organizationChange.emit(this.organization);
  }
}
