<div class="estimate-theme mat-dialog funnel-container-contact">
  <mat-dialog-content>
    <div class="mat-dialog__header mb-4">{{ jobName }}</div>
    <table class="mat-dialog__table--stretch-width">
      <thead>
        <tr>
          <th>Contact Name</th>
          <th>Phone Number</th>
          <th>Email Address</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let contact of contacts">
          <td>{{ contact.FullName }}</td>
          <td>
            <a href="tel:+{{ contact.Phone }}">{{ contact.Phone }}</a>
          </td>
          <td>{{ contact.Address }}</td>
        </tr>
      </tbody>
    </table>
  </mat-dialog-content>
  <mat-dialog-actions
    class="mat-dialog__actions mat-dialog__actions--align-right"
  >
    <button class="button button--primary" (click)="close()">Ok</button>
  </mat-dialog-actions>
</div>
