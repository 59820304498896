import { NgModule } from "@angular/core";
import { CommonModule, CurrencyPipe } from "@angular/common";

import { OnlyNumber } from "./onlyNumeric";
import { TabContentLoadOnDemandDirective } from "./loadOnDemand";
import { CurrencyMinValidatorDirective } from "./currencyValidator.directive";
import { DefaultFocusDirective } from "app/theme/directives/HtmlDirectives/default-focus.directive";
import { CommaSeperatedDecimalNumberDirective } from "./commaSeperatedDecimalNumber.directive";
import { DecimalFormatDirective } from "./decimalFormat.directive";
import { CurrencyInputDirective } from "./currency-input.directive";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { TooltipModule } from "primeng/tooltip";
import { CharLengthPipe } from "./char-length.pipe";
import { PreventBackSlashAndDoubleQuote } from "./preventBackslashAndDoubleQuote.directive";

@NgModule({
  imports: [CommonModule, CurrencyMaskModule, TooltipModule],
  declarations: [
    OnlyNumber,
    TabContentLoadOnDemandDirective,
    CurrencyMinValidatorDirective,
    DefaultFocusDirective,
    CommaSeperatedDecimalNumberDirective,
    DecimalFormatDirective,
    CurrencyInputDirective,
    CharLengthPipe,
    PreventBackSlashAndDoubleQuote,
  ],
  providers: [CurrencyPipe],
  exports: [
    OnlyNumber,
    TabContentLoadOnDemandDirective,
    CurrencyMinValidatorDirective,
    DefaultFocusDirective,
    CommaSeperatedDecimalNumberDirective,
    DecimalFormatDirective,
    CurrencyInputDirective,
    CurrencyMaskModule,
    TooltipModule,
    CharLengthPipe,
    PreventBackSlashAndDoubleQuote,
  ],
})
export class CommonDirectiveModule {}
