<kendo-window
  #chatWindow
  title="Chat"
  *ngIf="visible"
  (close)="onClose()"
  [resizable]="true"
  [keepContent]="true"
  (stateChange)="onStateChange($event); onAfterResize(chatWindow)"
  [(top)]="top"
  [(left)]="left"
  [(height)]="height"
  [minHeight]="height"
  [minWidth]="width"
  [(width)]="width"
>
  <kendo-window-titlebar>
    <ul>
      <li>{{ selectedGroup | async | groupName: user }}</li>
    </ul>
    <span *ngIf="notifications > 0 && minimized" class="chat-notification">{{
      notifications
    }}</span>
    <div class="chat-window-buttons">
      <button kendoWindowMinimizeAction></button>
      <button kendoWindowMaximizeAction></button>
      <button kendoWindowRestoreAction></button>
      <button kendoWindowCloseAction></button>
    </div>
  </kendo-window-titlebar>
  <div class="container-fluid p-0 h-100 chat-wrapper">
    <div class="row m-0 h-100">
      <div class="col-4 p-0 pb-2 h-100">
        <chat-manager
          (panelContextChanged)="onPanelContextChanged($event)"
          [user]="user"
        >
        </chat-manager>
      </div>
      <div class="col-8 p-0 h-100">
        <chat-conversation [user]="user"> </chat-conversation>
      </div>
    </div>
  </div>
  <div
    *ngIf="panelContext"
    class="container-fluid p-0 h-100 chat-shadow-overlay"
  ></div>
  <div
    *ngIf="panelContext"
    class="container-fluid p-0 h-100 chat-sidebar-panel-container"
  >
    <div class="row m-0 h-100">
      <div class="col-5 p-0 h-100"></div>
      <div class="col-7 p-0 h-100 chat-sidebar-panel">
        <div class="row m-0 chat-sidebar-panel-header">
          <h3 #panelTitle></h3>
          <i
            (click)="onPanelCompleted(true)"
            class="fa fa-times"
            aria-hidden="true"
          ></i>
        </div>
        <div class="row m-0 chat-sidebar-panel-content">
          <chat-add-edit-group-panel
            (title)="panelTitle.innerHTML = $event"
            [user]="user"
            [context]="panelContext"
            (completed)="onPanelCompleted($event)"
          >
          </chat-add-edit-group-panel>
        </div>
      </div>
    </div>
  </div>
</kendo-window>
