import { Injectable } from "@angular/core";

@Injectable()
export class GuidMatcherFactoryService {
  create<T>() {
    return new GuidMatcherService<T>();
  }
}

@Injectable()
export class GuidMatcherService<T> {
  private addedGuids: { [key: string]: true } = {};

  public addGuid(guid: string | string[]) {
    if (typeof guid === "string") {
      this.addedGuids[guid] = true;
    } else {
      guid.forEach((g) => {
        this.addedGuids[g] = true;
      });
    }
  }

  public matchGuids(
    objects: T[],
    getter: (o: T) => string = (o: any) => o.Guid
  ) {
    return objects.filter((o) => this.addedGuids[getter(o)]);
  }
}
