import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";

@Injectable()
export class JobQuoteService {
  constructor(private httpService: HttpService) {}

  deleteJobQuoteById(JobQuoteID: number) {
    return this.httpService.deleteRequestService("jobquote/" + JobQuoteID);
  }

  markJobQuoteActive(JobQuoteID: number, JobID: string) {
    return this.httpService.putRequestService(
      "jobquote/markactive/" + JobQuoteID + "/job/" + JobID,
      {}
    );
  }
  getJobQuotes(JobID: string) {
    return this.httpService.getRequestService("jobquote/job/" + JobID);
  }
  getJobQuoteById(JobQuoteID: number) {
    return this.httpService.getRequestService("jobquote/" + JobQuoteID);
  }
}
