import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { SpectrumService } from "app/common/services/spectrum.service";
import { ExportModel } from "app/model/ExportModel";
import { BaThemeSpinner } from "app/theme";
import { GridDataResult, PageChangeEvent } from "@progress/kendo-angular-grid";
import { JobFileModel } from "app/model/jobFile";

@Component({
  selector: "app-export-file-dialog",
  templateUrl: "./export-file-dialog.component.html",
  styleUrls: ["./export-file-dialog.component.scss"],
})
export class ExportFileDialogComponent implements OnInit {
  title: string;
  message: string;
  jobId: string;
  isLoading: boolean = true;

  fileList: JobFileModel[];
  gridView: GridDataResult;
  currentPage: number = 1;
  pageSize: number = 25;
  skip: number = 0;

  constructor(
    private spectrumService: SpectrumService,
    private spinner: BaThemeSpinner,
    private dialogRef: MatDialogRef<ExportFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: ExportModel
  ) {
    this.title = data.title;
    this.message = data.message;
    this.jobId = data.jobId;
  }

  ngOnInit() {
    this.spinner.show();

    this.spectrumService.exportSpectrumFiles(this.jobId).subscribe((data) => {
      data.forEach((obj) => {
        obj.Extension = obj.FileName.split(".")[1];
        obj.selected = true;
      });

      this.fileList = data;

      this.isLoading = false;
      this.spinner.hide();
    });
  }
}
