<div class="crousalpop" *ngIf="showing">
  <i
    class="fa fa-times close-button"
    title="close"
    *ngIf="useService"
    (click)="hide()"
  ></i>
  <div class="cslide">
    <kendo-scrollview
      [data]="thumbnailUrls"
      [width]="width"
      [height]="height"
      [arrows]="arrows"
      [activeIndex]="currentIndex"
    >
      <ng-template let-item="item">
        <img
          src="{{fullImageUrls[item]}}"
          [ngStyle]="{minWidth: width}"
          draggable="false"
        />
      </ng-template>
    </kendo-scrollview>
  </div>
</div>
