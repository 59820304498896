import {
  Component,
  ViewContainerRef,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import * as $ from "jquery";

import { GlobalState } from "./global.state";
import {
  BaImageLoaderService,
  BaThemePreloader,
  BaThemeSpinner,
} from "./theme/services";
import { BaThemeConfig } from "./theme/theme.config";
import { layoutPaths } from "./theme/theme.constants";
import { Message } from "primeng/primeng";
import { CustomerService } from "app/common/services/customer.service";
import { SharedService } from "app/common/utility/SharedService";
import { AuthGuard } from "app/common/authGuard/auth.guard";
import {
  Router,
  RouteConfigLoadStart,
  RouteConfigLoadEnd,
  NavigationEnd,
} from "@angular/router";
import { Subscription } from "rxjs";
import { ChatService } from "./common/services/Chat.service";
declare var setLableFloat: any;
declare var kendo: any;
/*
 * App Component
 * Top Level Component
 */
@Component({
  selector: "app",
  styleUrls: ["./app.component.scss"],
  template: `
    <main [class.menu-collapsed]="isMenuCollapsed" baThemeRun>
      <div class="additional-bg"></div>
      <router-outlet></router-outlet>
      <p-growl [(value)]="msgs"></p-growl>
    </main>
  `,
})
export class App implements AfterViewInit, OnDestroy {
  isMenuCollapsed: boolean = false;
  msgs: Message[] = [];

  routerEvents$: Subscription;

  constructor(
    private _state: GlobalState,
    private _imageLoader: BaImageLoaderService,
    private _spinner: BaThemeSpinner,
    private viewContainerRef: ViewContainerRef,
    private themeConfig: BaThemeConfig,
    private customerService: CustomerService,
    private chatService: ChatService,
    private sharedService: SharedService,
    private router: Router,
    private authGaurd: AuthGuard
  ) {
    if (window !== undefined) {
      (window as any).$ = kendo.jQuery;
    }

    themeConfig.config();
    this._state.subscribe("menu.isCollapsed", (isCollapsed) => {
      this.isMenuCollapsed = isCollapsed;
    });
    // if (this.authGaurd.getToken() && this.authGaurd.getToken() !== '') {
    // this.customerService.getLoginUser().subscribe(data => {
    //
    // this.sharedService.loggedInUser = data;
    // this.sharedService.selectedOrganization = data.Organizations[0];
    // });
    // }
  }

  ngAfterViewInit(): void {
    // hide spinner once all loaders are completed
    // BaThemePreloader.load().then((values) => {
    //   // this._spinner.hide();
    // });
    setLableFloat();
    this.routerEvents$ = this.router.events.subscribe(async (event) => {
      if (event instanceof RouteConfigLoadStart) {
        // this._spinner.show();
      } else if (event instanceof RouteConfigLoadEnd) {
        // this._spinner.show();
      } else if (event instanceof NavigationEnd) {
        if (this.sharedService.loggedInUser) {
          //await this.chatService.connect();
          window["Appcues"].identify(this.sharedService.loggedInUser.Id, {
            username: this.sharedService.loggedInUser.UserName,
            email: this.sharedService.loggedInUser.Email,
            userCreateDate: this.sharedService.loggedInUser.CreatedOnUtc,
          });
        }
        window["Appcues"].page();
      }
    });
  }

  private _loadImages(): void {
    // register some loaders
    BaThemePreloader.registerLoader(
      this._imageLoader.load("assets/img/sky-bg.jpg")
    );
  }

  ngOnDestroy() {
    this.routerEvents$.unsubscribe();
  }
}
