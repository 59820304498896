export class JobNoteModel {
  ID: number = 0;
  JobGUID: string;
  JobPhaseId: number;
  NoteType: string;
  NoteTypeText: string;
  PhaseName: string;
  CreatedOn: Date;
  Note: string;
  NoteTitle: string;
  CreatedBy: string;
  CanEdit: boolean;
  Guid: string;
  Public: boolean;
  Shared: boolean;
  ContractorCanView: boolean;
  CustomerCanView: boolean;
  constructor() {}
}
