<div class="hoverflow todoitems">
  <div
    class="todolist-phases"
    *ngFor="let phaseData of items | groupBy: 'groupingStatus'; let i = index"
  >
    <div
      class="todolist-phase-title"
      data-toggle="collapse"
      [attr.data-target]="'#collaps' + i"
      role="button"
      aria-expanded="false"
      aria-controls="collapseExample"
    >
      <i class="fa fa-caret-right"></i>
      {{ phaseData.value[0].groupingStatus }}
    </div>
    <div class="todolist-phase-listing collapse show" id="collaps{{ i }}">
      <ul>
        <li
          *ngFor="let phase of phaseData.value"
          [ngClass]="getStatusClass(phase)"
          (click)="onEditPhase.emit(phase)"
        >
          <div class="d-flex justify-content-between">
            <span [ngClass]="getIfCompletedToday(phase)">{{
              phase.PhaseName
            }}</span>

            <a
              class="fa fa-trash-o trash"
              title="Delete"
              (click)="$event.stopPropagation(); deleteTask(phase)"
              *ngIf="
                getTaskPermissions(phase).delete &&
                phase.Resources.length > 0 &&
                phase.JobTypeId === 7
              "
            ></a>
          </div>
          <!-- <div class="todolist-jobs-dtl">{{getPhaseType(phase.JobTypeId)}}</div> -->
          <div>
            <span class="todo_owner" *ngIf="shouldShowTaskOwner(phase)">
              Created By
              <span class="statuscolor bg-green">{{
                phase.OwnerInitials ? phase.OwnerInitials : "-"
              }}</span>
            </span>
            <span class="todo_owner" *ngIf="shouldShowTaskAssigned(phase)">
              Assigned To
              <span class="statuscolor bg-green">{{
                phase.AssignedToInitials ? phase.AssignedToInitials : "-"
              }}</span>
            </span>
          </div>

          <div class="timeblk">
            <div class="timeblk-item">
              <div>
                <i class="fa fa-clock-o"></i>
                {{
                  (phase.Status == 1
                    ? phase.ScheduledDate
                    : phase.Status == 2
                    ? phase.StartDate
                    : phase.EndDate
                  ) | date: "shortDate"
                }}
              </div>
            </div>
            <div class="timeblk-item">
              <div>{{ phase.JobTypeId == 7 ? "Task" : phase.JobName }}</div>
            </div>
            <div
              *ngIf="phase.groupingStatus != 'Completed'"
              class="timeblk-item"
            >
              <div>
                Click To
                <button
                  [disabled]="!hasPermissionToUseAction(phase)"
                  (click)="$event.stopPropagation(); onChangeStatus.emit(phase)"
                >
                  {{
                    phase.groupingStatus == "Not Started"
                      ? "Start"
                      : phase.groupingStatus != "Completed"
                      ? "Complete"
                      : ""
                  }}
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
