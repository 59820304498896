import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BlobService } from "app/common/services/Blob.service";
import { MediaResourceService } from "app/common/services/MediaResource.service";
import { MediaResourcesComponent } from "./MediaResources.component";
import { MediaResourceComponent } from "./MediaResource.component";
import { DialogModule } from "primeng/primeng";
import { DialogService } from "ng2-bootstrap-modal";
import { ImageCropperModule } from "ngx-image-cropper";
import { DragulaModule, DragulaService } from "ng2-dragula";

@NgModule({
  imports: [CommonModule, DialogModule, ImageCropperModule, DragulaModule],
  declarations: [MediaResourcesComponent, MediaResourceComponent],
  exports: [MediaResourcesComponent],
  providers: [DragulaService, DialogService, MediaResourceService, BlobService],
})
export class MediaResourcesModule {}
