import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environmentConstant } from "app/common/utility/environment";

@Injectable({ providedIn: "root" })
export class GoogleService {
  constructor(private http: HttpClient) {}

  searchByZIP(zipCode: string) {
    const geocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${zipCode}&key=${environmentConstant.apiKey}`;

    return this.http.get<any>(geocodingUrl);
  }
}
