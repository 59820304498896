import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ComponentsRelevantChangesFromDb } from "app/model/ComponentsRelevantChanges";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { SharedService } from "../utility/SharedService";

@Injectable({
  providedIn: "root",
})
export class ComponentsRelevantChangesService {
  constructor(private http: HttpClient, private sharedService: SharedService) {}

  /**
   * Gets all the changes related to one worksheet
   * @param worksheetId
   * @returns THe list of Changes
   */
  getChangesForWorksheet(
    worksheetId: number
  ): Observable<ComponentsRelevantChangesFromDb[]> {
    return this.http.get<ComponentsRelevantChangesFromDb[]>(
      `${environment.baseEstimateUrl}componentRelevantChanges/${worksheetId}`
    );
  }

  /**
   * Confirms all the relevant changes related to a worksheet and applies them.
   * This updates the worksheet
   * @param worksheetId Worksheet ID
   * @returns null
   */
  confirmAllTheChanges(worksheetId: string): Observable<null> {
    return this.http.patch<null>(
      `${environment.baseEstimateUrl}componentRelevantChanges/${worksheetId}`,
      {
        guid: worksheetId,
        userId: this.sharedService.user.orgGuid,
      }
    );
  }
}
