<div
  class="estimate-theme mat-dialog catalog-picker__modal catalog-picker__modal--kit product-dialog"
  [ngClass]="isReview || isEditK ? 'review-item' : ''"
>
  <h1 matDialogTitle class="estimate-typography-pop-up-titles">
    {{ kitLabel }}
  </h1>
  <h3 class="mb-2 product-name-title">{{ kitName }}</h3>
  <h3 class="product-name-sku">SKU# {{ skuKit }}</h3>
  <mat-dialog-content>
    <form [formGroup]="kitForm">
      <div class="workarea-fields">
        <div>
          <div class="input-pentair-std input-pentair-workarea">
            <label> Qty </label>
            <input
              type="text"
              formControlName="complexKitQty"
              currencyMask
              [options]="{
                prefix: '',
                thousands: '',
                decimal: '',
                align: 'left',
                precision: 0,
                allowNegative: false
              }"
              min="1"
              max="999999"
              class="mt-3 p-2 w-100"
              [readonly]="selectOnly"
            />
          </div>
        </div>
        <div class="product-kit-total">
          Total
          <span>
            {{ totalKit | currency : "USD" : "symbol" : "1.2-2" }}
          </span>
        </div>
      </div>
      <p class="mt-3">
        Complex Kit quantities can only be positive, negative, or zero. No
        decimals are allowed.
      </p>
      <p class="my-2">
        If the editable quantity feature is enabled
        <i
          class="fa fa-info-circle green-color"
          pTooltip="This feature can be enabled in the Catalog (per product) in the Kit Details Tab."
          tooltipPosition="bottom"
          tooltipStyleClass="tooltip"
        ></i>
        , product quantities within the Complex Kit can be positive or zero,
        including decimals
        <i
          class="fa fa-info-circle green-color"
          pTooltip="Decimal values can be allowed or not, depending on the Value Type of the Unit of Measure. This can be configured in the Units of Measure module."
          tooltipPosition="bottom"
          tooltipStyleClass="tooltip"
        ></i>
        . No negatives are allowed.
      </p>
      <p class="mb-3">
        Simple kit quantities within the Complex Kit can be positive or zero. No
        negatives or decimals are allowed. However, quantities for items within
        the Simple Kit can be positive or negative, including decimals
        <i
          class="fa fa-info-circle green-color"
          pTooltip="Decimal values can be allowed or not, depending on the Value Type of the Unit of Measure. This can be configured in the Units of Measure module."
          tooltipPosition="bottom"
          tooltipStyleClass="tooltip"
        ></i>
        . No zero quantities are allowed.
      </p>
      <div
        formArrayName="kitGroupsForm"
        class="simple-kit__scroll-table-container"
      >
        <div>
          <table>
            <ng-container
              [formGroupName]="groupIndex"
              *ngFor="let group of kitGroups; let groupIndex = index"
            >
              <tr>
                <th colspan="5">{{ group.groupName }}</th>
              </tr>
              <tr class="catalog-picker__modal-row-header">
                <th class="width-8 align-left">Qty</th>
                <th class="width-8"></th>
                <th class="width-15 align-left">SKU</th>
                <th class="complex-kit-item-name">Product Name</th>
                <th class="wihth-8" *ngIf="useKitPricing"></th>
                <th
                  class="catalog-picker__modal-cell--align-right price width-20 nowrap"
                  *ngIf="!useKitPricing"
                >
                  Unit Price
                </th>
              </tr>
              <ng-container formArrayName="simpleKitFormRows">
                <!-- Simple Kit -->
                <ng-container *ngIf="hasSimpleKitsFromGroup(group)">
                  <ng-container
                    [formGroupName]="kitIndex"
                    *ngFor="
                      let kd of getSimpleKitsFromGroup(group);
                      let kitIndex = index
                    "
                  >
                    <tr>
                      <td class="width-8 align-left">
                        <span
                          *ngIf="
                            !kd.editableQty &&
                            kd.qty > 0 &&
                            !kd.needsManualQuantity
                          "
                        >
                          {{ kd.qty }}
                        </span>
                        <span
                          *ngIf="
                            !kd.editableQty &&
                            kd.materialsCalculatorId &&
                            !showCalculatedValues &&
                            !kd.needsManualQuantity
                          "
                        >
                          <i class="fa svg-icon__function"></i>
                        </span>
                        <input
                          *ngIf="kd.editableQty || kd.needsManualQuantity"
                          decimalFormat
                          decimals="0"
                          [negative]="false"
                          [maxSize]="6"
                          min="0"
                          class="child-complex-input input-number"
                          type="number"
                          formControlName="qtyControl"
                        />
                      </td>
                      <td class="width-8"></td>
                      <td class="width-15 align-left">
                        {{ kd.kitPartSimpleKit.kitProduct.sku }}
                      </td>
                      <td class="complex-kit-item-name">
                        {{ kd.kitPartSimpleKit.kitProduct.name }}
                      </td>
                      <td class="catalog-picker__modal-cell--align-right">
                        <span>
                          {{
                            kd.kitPartSimpleKit.kitProduct.activePrice
                              | currency : "USD" : "symbol" : "1.2-2"
                          }}
                        </span>
                      </td>
                    </tr>
                    <!-- SimpleKit Items -->
                    <ng-container formArrayName="simpleKitItemFormRows">
                      <ng-container
                        [formGroupName]="itemIndex"
                        *ngFor="
                          let item of kd.kitPartSimpleKit.kitPartProductDetails;
                          let itemIndex = index
                        "
                      >
                        <ng-container
                          *ngIf="
                            item.editableQty ||
                            (item.qty === 0 && !item.materialsCalculatorId) ||
                            item.kitPartProduct.productOptions.length > 0
                          "
                        >
                          <ng-container
                            *ngTemplateOutlet="
                              productRowTemplate;
                              context: {
                                fullItem: item,
                                product: item.kitPartProduct,
                                form: getKitItemFormGroup(
                                  groupIndex,
                                  kitIndex,
                                  itemIndex
                                ),
                                isChildrenItem: true
                              }
                            "
                          >
                          </ng-container>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>

              <!-- Items -->
              <ng-container formArrayName="productFormRows">
                <ng-container *ngIf="hasProductsFromGroup(group)">
                  <ng-container
                    [formGroupName]="productIndex"
                    *ngFor="
                      let item of getProductsFromGroup(group);
                      let productIndex = index
                    "
                  >
                    <ng-container
                      *ngTemplateOutlet="
                        productRowTemplate;
                        context: {
                          fullItem: item,
                          product: item.kitPartProduct,
                          form: getItemFormGroup(groupIndex, productIndex),
                          isCollapsed: false
                        }
                      "
                    >
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </table>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions
    class="mat-dialog__actions mat-dialog__actions--align-right pb-2 pt-3 pr-3 mat-dialog__actions--sidebar"
  >
    <button class="button button--secondary" (click)="hideSidebar()">
      Close
    </button>
    <button
      *ngIf="!selectOnly"
      class="button button--primary"
      (click)="selectKit()"
      [disabled]="!kitForm.valid || !attachableToWorksheet"
    >
      {{ isEdit ? "Save" : "Add Selection" }}
    </button>
  </mat-dialog-actions>
</div>

<ng-template
  #productRowTemplate
  let-item="fullItem"
  let-product="product"
  let-form="form"
  let-kd="kitDetail"
  let-isCollapsed="isCollapsed"
  let-isChildrenItem="isChildrenItem"
>
  <tr
    [ngClass]="{
      'catalog-picker__has-product-options': false,
      'child-complex-row': isChildrenItem
    }"
  >
    <td class="width-8 align-right" *ngIf="isChildrenItem"></td>
    <td [ngClass]="{ 'text-left': isChildrenItem }" class="width-8 align-left">
      <span
        *ngIf="
          !item.editableQty &&
          (!item.materialsCalculatorId || showCalculatedValues) &&
          !item.needsManualQuantity
        "
        [ngClass]="{ 'w-100': isChildrenItem }"
      >
        {{ item.qty }}
      </span>
      <span
        *ngIf="
          !item.editableQty &&
          item.materialsCalculatorId &&
          !showCalculatedValues &&
          !item.needsManualQuantity
        "
        [ngClass]="{ 'w-100': isChildrenItem }"
      >
        <i class="fa svg-icon__function"></i>
      </span>
      <input
        *ngIf="item.editableQty || item.needsManualQuantity"
        decimalFormat
        [decimals]="product.unitOfMeasure.valueType"
        [negative]="isChildrenItem"
        [maxSize]="6"
        [allowZero]="!isChildrenItem"
        class="input-number child-complex-input"
        type="number"
        [min]="!isChildrenItem ? '0' : ''"
        [formControl]="form.get('qtyControl')"
      />
    </td>
    <td class="width-8" *ngIf="!isChildrenItem"></td>
    <td class="width-15 align-left">
      {{ product.sku }}
    </td>
    <td class="complex-kit-item-name">
      {{ product.name }}
    </td>
    <td class="catalog-picker__modal-cell--align-right">
      <span>
        {{ product.price | currency : "USD" : "symbol" : "1.2-2" }}
      </span>
    </td>
  </tr>

  <!-- Product Option hidden -->
  <!-- <tr
    class="catalog-picker__product-options"
    *ngIf="hasProductOptions(product)"
  >
    <td></td>
    <td></td>
    <td class="align-right">
      <div class="dropdown-pentair-std layout-flex-end">
        <p-dropdown
          [options]="product.productOptionsMap"
          [autoWidth]="false"
          [formControl]="form.get('productOptionsControl')"
          appentTo="body"
          styleClass="prime-dropdown w-100 align-left"
          (onChange)="changePrice($event, product)"
        ></p-dropdown>
      </div>
    </td>
    <td></td>
  </tr> -->
</ng-template>
