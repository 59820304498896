export class CustomerInfo {
  Id: number;
  Email: string;
  FirstName: string;
  LastName: string;
  PrimaryPhone: string;
  SecondaryPhone: string;
  StateProvinceId: number;
  City: string;
  AddressId: number;
  Address1: string;
  Address2: string;
  ZipPostalCode: string;
  StateProvinceAbbreviation: string;
  isExternal: boolean;
}
