import { Injectable } from "@angular/core";

@Injectable()
export class CommonDataService {
  rolesData = [
    {
      Id: 2,
      Name: "Admin",
      DisplayName: "Admin",
      Active: true,
    },
    {
      Id: 3,
      Name: "Contractors",
      DisplayName: "Contractors",
      Active: true,
    },
    {
      Id: 6,
      Name: "Customer",
      DisplayName: "Customer",
      Active: true,
    },
    {
      Id: 8,
      Name: "Project Manager",
      DisplayName: "Project Manager",
      Active: true,
    },
    {
      Id: 5,
      Name: "Sales",
      DisplayName: "Sales",
      Active: true,
    },
    {
      Id: 4,
      Name: "Sales Manager",
      DisplayName: "Sales Manager",
      Active: true,
    },
    {
      Id: 7,
      Name: "Scheduler",
      DisplayName: "Scheduler",
      Active: true,
    },

    {
      Id: 9,
      Name: "Superintendent",
      DisplayName: "Superintendent",
      Active: true,
    },
  ];
  statusData = [
    {
      Id: 1,
      Name: "Not Started",
      DisplayName: "Not Started",
      Active: true,
    },
    {
      Id: 2,
      Name: "In Progress",
      DisplayName: "In Progress",
      Active: true,
    },
    {
      Id: 3,
      Name: "Complete",
      DisplayName: "Today's Phases",
      Active: true,
    },
    {
      Id: 4,
      Name: "Dead",
      DisplayName: "Phases Past Due",
      Active: true,
    },
    {
      Id: 5,
      Name: "Stopped",
      DisplayName: "Stopped",
      Active: false,
    },
    {
      Id: 6,
      Name: "Active",
      DisplayName: "Active",
      Active: false,
    },
    {
      Id: 7,
      Name: "Any",
      DisplayName: "Any",
      Active: false,
    },
  ];
  entityData = [
    {
      Id: 6,
      Name: "Draw Schedule Template",
      Active: true,
    },
    {
      Id: 1,
      Name: "Job Phase",
      Active: true,
    },
    {
      Id: 3,
      Name: "Job Template",
      Active: true,
    },
    {
      Id: 5,
      Name: "Phase Change Reason",
      Active: true,
    },
    {
      Id: 2,
      Name: "Punch List Item",
      Active: true,
    },

    {
      Id: 4,
      Name: "Work Order Item",
      Active: true,
    },
    {
      Id: 9,
      Name: "Business Unit",
      Active: true,
    },
    {
      Id: 10,
      Name: "Home Builder Type",
      Active: true,
    },
    {
      Id: 11,
      Name: "Report",
      Active: true,
    },
  ];
  getRoles(): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.rolesData);
      }, 2000);
    });
  }
  getCustomerByRoleId(id): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.rolesData.find((obj) => obj.Id === id));
      }, 2000);
    });
  }
  getAllActiveJobStatus(): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.statusData.filter((x) => x.Active));
      }, 0);
    });
  }
  getStatusById(ID): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.statusData.find((obj) => obj.Id === ID));
      }, 0);
    });
  }
  getEntities(): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.entityData);
      });
    });
  }
  getEntityById(ID): Promise<any> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.entityData.find((obj) => obj.Id === ID));
      }, 0);
    });
  }
}
