import { Injectable } from "@angular/core";
import {
  BuildPlanFilters,
  EventStatus,
  JobPhaseEditResponse,
  JobPhasModel,
} from "../../model/jobPhaseModel";
import { BehaviorSubject, Observable } from "rxjs";
import { JobPhaseService } from "./jobPhase.service";
import { BaThemeSpinner } from "../../theme/services/baThemeSpinner/baThemeSpinner.service";
import { StatusModel } from "app/model/statusModel";
import { ToastrService } from "ngx-toastr";
import { ChecklistService } from "./Core/Checklist.service";
import { PhaseService } from "./phase.service";
import { Checklist } from "app/component/Checklist/Models/Checklist";

@Injectable({
  providedIn: "root",
})
export class BuildPlanStoreService {
  private _phases: BehaviorSubject<JobPhasModel[]> = new BehaviorSubject([]);
  private _upsertResponse: BehaviorSubject<JobPhaseEditResponse> =
    new BehaviorSubject(null);
  private _filters: BehaviorSubject<BuildPlanFilters> = new BehaviorSubject({
    status: [],
    query: "",
  });
  private _jobPhaseCount: BehaviorSubject<number> = new BehaviorSubject(null);
  private _phase: BehaviorSubject<JobPhasModel> = new BehaviorSubject(null);
  private _afterEdit: BehaviorSubject<JobPhaseEditResponse> =
    new BehaviorSubject(null);
  private allPhases: JobPhasModel[] = [];
  private readonly filtersKey: string = "BuildPlan_Filter";
  private readonly constructionPhaseTypeId: number = 12;
  private readonly constructionPhaseDeadTypeId: number = 27;
  private readonly updateSuccessMsg: string = "Updated Successfully";
  private readonly toastrTitle: string = "Build Plan Phase";
  private readonly errorMsg: string = "An error has ocurred";

  get phases(): Observable<JobPhasModel[]> {
    return this._phases.asObservable();
  }
  get filters(): Observable<BuildPlanFilters> {
    return this._filters.asObservable();
  }
  get upsertResponse(): Observable<JobPhaseEditResponse> {
    return this._upsertResponse.asObservable();
  }
  get jobPhaseCount(): Observable<number> {
    return this._jobPhaseCount.asObservable();
  }
  get phase(): Observable<JobPhasModel> {
    return this._phase.asObservable();
  }

  get afterEdit(): Observable<JobPhaseEditResponse> {
    return this._afterEdit.asObservable();
  }

  constructor(
    private jobPhaseService: JobPhaseService,
    private _spinner: BaThemeSpinner,
    private toastrService: ToastrService,
    private phaseService: PhaseService,
    private checklistService: ChecklistService
  ) {
    this.initLocalFilter();
  }

  loadInitialData(jobId: string) {
    this._spinner.show();
    this.jobPhaseService
      .getJobPhaseByJobId(jobId)
      .subscribe(
        (res) => {
          this.allPhases = res;
          this.filter(this._filters.getValue());
        },
        (err) => this.toastrService.error(this.errorMsg)
      )
      .add(() => this._spinner.hide());
  }

  editJobPhase(
    jobPhase: JobPhasModel,
    reload: boolean,
    shouldClose: boolean = true
  ) {
    this._spinner.show();
    this.jobPhaseService
      .updateJobPhase(jobPhase, false)
      .subscribe(
        (response: JobPhaseEditResponse) => {
          if (response.Order > this.allPhases.length) {
            response.Order = this.allPhases.length;
          }
          const currentEditedPhase = this.allPhases.find(
            (p) => p.ID === response.ID
          );
          const phaseAfterEdit = this.newJobPhase(currentEditedPhase, response);
          this.allPhases = this.allPhases.map((p) =>
            p.ID === response.ID ? phaseAfterEdit : p
          );
          if (
            !!currentEditedPhase &&
            phaseAfterEdit.PhaseOrder !== currentEditedPhase.PhaseOrder
          ) {
            this.allPhases.splice(
              phaseAfterEdit.PhaseOrder - 1,
              0,
              this.allPhases.splice(currentEditedPhase.PhaseOrder - 1, 1)[0]
            );
            this.reOrder(this.allPhases);
          }
          response.ShouldClose = shouldClose;
          this._afterEdit.next(response);
          this.filter(this._filters.getValue());
          if (reload) this._phase.next(jobPhase);
        },
        (error) => this.toastrService.error(this.errorMsg)
      )
      .add(() => this._spinner.hide());
  }

  createJobPhase(
    jobPhase: JobPhasModel,
    jobId: string,
    shouldClose: boolean = true
  ) {
    this._spinner.show();
    this.jobPhaseService
      .saveJobPhase(jobPhase, false, jobId)
      .subscribe((response: JobPhaseEditResponse) => {
        this.addChecklistsToJobPhase(jobPhase, response.Guid);
        const newPhase = this.newJobPhase(null, response);

        if (response.Order > this.allPhases.length) {
          response.Order = this.allPhases.length + 1;
        }
        if (!!jobPhase.PhaseReplacingId) {
          const phaseToSwitch = this.allPhases.find(
            (p) => p.PhaseOrder === response.Order
          );
          phaseToSwitch.Dependents = response.PhaseName;
        }
        this.allPhases.splice(newPhase.PhaseOrder - 1, 0, newPhase);
        this.reOrder(this.allPhases);
        this.filter(this._filters.getValue());
        response.ShouldClose = shouldClose;
        this._upsertResponse.next(response);
        this._jobPhaseCount.next(this.allPhases.length);
      })
      .add(() => this._spinner.hide());
  }

  async addChecklistsToJobPhase(jobPhase: JobPhasModel, jobPhaseGuid: string) {
    try {
      const res = await this.phaseService
        .getPhaseChecklists(jobPhase.OriginalPhaseId)
        .toPromise();
      if (res.object) {
        res.object.forEach(async (checklist: Checklist) => {
          try {
            await this.checklistService
              .postJobPhaseChecklists(jobPhaseGuid, checklist)
              .toPromise();
          } catch (error) {
            console.log("addChecklistsToJobPhase", error);
          }
        });
      }
    } catch (error) {
      console.log("addChecklistsToJobPhase", error);
    }
  }

  deleteJobPhase(jobPhaseId: number) {
    this._spinner.show();
    this.jobPhaseService
      .deleteJobPhase(jobPhaseId)
      .subscribe((x) => {
        const index = this.allPhases.findIndex((p) => p.ID === jobPhaseId);
        if (index > -1) {
          this.allPhases.splice(index, 1);
          this.reOrder(this.allPhases);
        }
        const currentPhasesVal = this._phases.getValue();
        const subIndex = currentPhasesVal.findIndex((p) => p.ID === jobPhaseId);
        if (subIndex > -1) {
          currentPhasesVal.splice(subIndex, 1);
          this._phases.next(this.reOrder(currentPhasesVal));
        }
        this._jobPhaseCount.next(this.allPhases.length);
      })
      .add(() => this._spinner.hide());
  }

  onFilterChange(filters: BuildPlanFilters) {
    this.setLocalFilter(filters.status);
    this._filters.next(filters);
    this.filter(filters);
  }

  findPhase(id: number): JobPhasModel {
    return this._phases.value.find((x) => x.ID === id);
  }

  updatePhase(phase: JobPhasModel) {
    const index = this.allPhases.findIndex((p) => p.ID === phase.ID);
    if (index > -1) {
      let phases = [...this.allPhases];
      phases[index] = this.newJobPhase(phases[index], phase);
      this._phases.next(phases);
    }
  }

  resetUpsert() {
    this._upsertResponse.next(null);
  }

  resetAfterEdit() {
    this._afterEdit.next(null);
  }

  handleUpdateDependentDate(phases: JobPhasModel[]) {
    const value = [...this.allPhases];

    phases.forEach((element) => {
      const index = value.findIndex((x) => x.ID === element.ID);
      if (index > -1) {
        const newVal = { ...value[index] };
        value[index] = this.newJobPhase(newVal, element);
      }
    });
    this.allPhases = [...phases];
    const { status } = this._filters.getValue();
    this._phases.next(this.filterByStatus(phases, status));
  }

  /**
   * Copy all target date to phases with missing schedules
   */
  copyAllToSchedule(jobId) {
    this._spinner.show();
    const { value } = this._phases;
    const filtered = value
      .filter((p) => p.EstStartDate !== null && p.ScheduledDate === null)
      .map((p) => p.ID);
    if (filtered.length) {
      this.jobPhaseService
        .scheduleMultiple(filtered)
        .subscribe((data: JobPhasModel[]) => {
          this.loadPhasesAftercopy(data, jobId);
        });
    } else this._spinner.hide();
  }

  private filter(filters: BuildPlanFilters) {
    const { query, status } = filters;
    const queryFilter = this.filterBySearchInput(query);
    this._phases.next(this.filterByStatus(queryFilter, status));
  }

  private filterByStatus(phases: JobPhasModel[], statusFilters: EventStatus[]) {
    const filters: number[] = statusFilters
      .filter((x) => x.checked)
      .map((x) => x.key);
    if (filters.length) {
      return phases.filter((x) => filters.includes(x.StatusId));
    } else {
      return phases;
    }
  }

  private filterBySearchInput(query: string) {
    const filteredPhases = this.allPhases.filter((p) =>
      p.PhaseName.toLowerCase().includes(query.trim().toLowerCase())
    );
    return filteredPhases;
  }

  private newJobPhase(
    prevJobPhase: JobPhasModel,
    response: JobPhaseEditResponse | JobPhasModel
  ): JobPhasModel {
    const newPhase = new JobPhasModel();

    if (!!prevJobPhase) {
      //newPhase.Status = prevJobPhase.Guid;
      newPhase.AlertCount = prevJobPhase.AlertCount;
      newPhase.Guid = prevJobPhase.Guid;
      newPhase.ContractorId = prevJobPhase.ContractorId;
      newPhase.Contractor = prevJobPhase.Contractor;
      newPhase.LastPhotoUploaded = prevJobPhase.LastPhotoUploaded;
      newPhase.JobCameraCount = prevJobPhase.JobCameraCount;
      newPhase.ContractorGUID = prevJobPhase.ContractorGUID;
      newPhase.StatusName = prevJobPhase.StatusName;
      newPhase.OriginalPhaseId = prevJobPhase.OriginalPhaseId;
    }
    newPhase.ID = response.ID;
    newPhase.PhaseName = response.PhaseName;
    newPhase.PhaseOrder = response.Order
      ? response.Order
      : (response as JobPhasModel).PhaseOrder;
    newPhase.ActualStartDate = response.ActualStartDate;
    newPhase.EstEndDate = response.EstEndDate;
    newPhase.ActualEndDate = response.ActualEndDate;
    newPhase.EstStartDate = response.EstStartDate;
    newPhase.PhotoCount = response.PhotoCount;
    newPhase.NoteCount = response.NoteCount;
    newPhase.FileCount = response.FileCount;
    newPhase.JobID = response.JobID;
    //newPhase.Job_Id = response.JobID; // in old phase is Job_Id
    newPhase.OrgGUID = response.OrgGUID;
    newPhase.IsComplete = response.IsComplete;
    newPhase.DaysToComplete = response.DaysToComplete;
    newPhase.DependsOnMe = response.DependsOnMe;
    newPhase.UseContractorOrg = response.UseContractorOrg;
    newPhase.ScheduledDate = response.ScheduledDate;
    newPhase.Resources = response.ResourcesDetails
      ? response.ResourcesDetails
      : (response as JobPhasModel).Resources;
    newPhase.BusinessUnitId = response.BusinessUnitId;
    newPhase.TradeId = response.TradeId;
    newPhase.IsReady = response.isReady ? response.isReady : response.IsReady;
    newPhase.StatusId =
      typeof response.Status === "number" ? response.Status : response.StatusId;
    newPhase.JobTypeId = response.JobTypeId;
    newPhase.Status = response.Status;
    if (Array.isArray(response.Dependents)) {
      response.Dependents = response.Dependents.map((d) => d.PhaseName).join(
        ","
      );
    }
    newPhase.Dependents = response.Dependents;
    newPhase.OriginalPhaseId =
      newPhase.OriginalPhaseId || response.OriginalPhaseId;

    return newPhase;
  }

  private reOrder(phases: JobPhasModel[]): JobPhasModel[] {
    phases.forEach((phase, i) => {
      phase.PhaseOrder = i + 1;
    });
    return phases;
  }

  private initLocalFilter() {
    if (localStorage.getItem(this.filtersKey) === null) {
      this.getFilters();
    } else {
      this._filters.next({
        status: JSON.parse(localStorage.getItem(this.filtersKey)),
        query: "",
      });
    }
  }

  private getFilters() {
    this.jobPhaseService
      .getStatusId(this.constructionPhaseTypeId)
      .subscribe((data: StatusModel[]) => {
        const status = this.mapStatusModelToEventStatus(data);
        this.setLocalFilter(status);
        this._filters.next({ status: status, query: "" });
      });
  }

  private mapStatusModelToEventStatus(model: StatusModel[]): EventStatus[] {
    return model.map((data) => {
      const result = { Value: data.Status, key: data.Value, checked: null };
      result.checked = true; //data.Id !== this.constructionPhaseDeadTypeId;
      return result;
    });
  }

  private setLocalFilter(evStatus: EventStatus[]) {
    localStorage.setItem(this.filtersKey, JSON.stringify(evStatus));
  }

  private loadPhasesAftercopy(data, jobId) {
    this.jobPhaseService
      .getJobPhaseByJobId(jobId)
      .subscribe(
        (res) => {
          this.allPhases = res;
          this.filter(this._filters.getValue());
        },
        (err) => this.toastrService.error(this.errorMsg)
      )
      .add(() => {
        this._spinner.hide();
        this.toastrService.success(this.updateSuccessMsg, this.toastrTitle);
      });
  }

  updatePhases(phase) {
    this._phase.next(phase);
  }

  reArrangePhases(
    jobId: string,
    phaseToSwitchId: number,
    phaseToSwitchWithId: number
  ) {
    this._spinner.show();
    this.jobPhaseService
      .reArrangePhases(jobId, phaseToSwitchId, phaseToSwitchWithId)
      .subscribe(
        (response) => {
          this._phases.next(response);
        },
        (error) => this.toastrService.error(this.errorMsg)
      )
      .add(() => this._spinner.hide());
  }
}
