import {
  Component,
  EventEmitter,
  Output,
  Input,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { OrganizationService } from "app/common/services/Core/Organization.service";
import { UserModel } from "app/model/Core/UserModel";
import { BaThemeSpinner } from "app/theme/services";
import { AccountService } from "app/common/services/Core/Account.service";
import { JobModel } from "app/model/Core/JobModel";
import { FilteredSearchModel, SearchFilter } from "app/model/SearchModel";

@Component({
  selector: "search-user",
  templateUrl: "./SearchUser.component.html",
  styles: [
    `
      :host {
        width: 100%;
      }

      .table-responsive {
        border: 2px solid #6c757d;
        border-radius: 5px;
      }

      th {
        color: #6c757d;
      }

      tr {
        cursor: pointer;
      }

      tbody tr:hover {
        background-color: #a9bed1 !important;
        color: #ffffff !important;
      }

      .user-search {
        position: relative;
      }

      .user-search label {
        position: absolute;
        margin: 5px 0 0 15px;
        color: #32465a;
      }
      .user-search input {
        padding: 0 0 0 35px;
        color: #32465a;
        width: 100%;
        background-color: white;
        border-radius: 5px;
      }

      .form-check label {
        left: 0;
        top: 15px;
        margin: 0;
      }
    `,
  ],
})
export class SearchUserComponent {
  @Output() public userSelected: EventEmitter<UserModel> = new EventEmitter<
    UserModel
  >();

  public items: UserModel[];
  public jobs: JobModel[];

  private search: FilteredSearchModel<SearchFilter> = <
    FilteredSearchModel<SearchFilter>
  >{
    filter: {
      term: null,
    },
    pagerInfo: {
      getCounts: false,
      take: 25,
      skip: 0,
    },
  };

  public searchContext: number = 0;
  public didSearch: boolean = false;

  constructor(
    private spinner: BaThemeSpinner,
    private accountService: AccountService,
    private organizationService: OrganizationService
  ) {}

  public onUserSelected(user: UserModel): void {
    if (!user) return;
    this.userSelected.emit(user);
  }

  private onSearchEmployees(): void {
    let subscription = this.organizationService
      .searchEmployees(this.search)
      .subscribe((response) => {
        if (response.success) {
          this.items = response.object.items;
        }
        this.didSearch = true;
        this.spinner.hide();
        subscription.unsubscribe();
      });
  }

  private onSearchCustomers(): void {
    let subscription = this.accountService
      .searchCustomers(this.search)
      .subscribe((response) => {
        if (response.success) {
          this.items = response.object.items;
        }
        this.didSearch = true;
        this.spinner.hide();
        subscription.unsubscribe();
      });
  }

  private onSearchContractors(): void {
    let subscription = this.organizationService
      .searchContractors(this.search)
      .subscribe((response) => {
        if (response.success) {
          this.items = response.object.items;
        }
        this.didSearch = true;
        this.spinner.hide();
        subscription.unsubscribe();
      });
  }

  private onSearchLeads(): void {
    let subscription = this.accountService
      .searchLeads(this.search)
      .subscribe((response) => {
        if (response.success) {
          this.items = response.object.items;
        }
        this.didSearch = true;
        this.spinner.hide();
        subscription.unsubscribe();
      });
  }

  public onSearch(input: any): void {
    this.search.filter.term = String(input.value);
    input.value = "";

    this.spinner.show();
    this.items = [];

    if (this.searchContext == 3) {
      this.onSearchLeads();
      return;
    }

    if (this.searchContext == 2) {
      this.onSearchContractors();
      return;
    }

    if (this.searchContext == 1) {
      this.onSearchCustomers();
      return;
    }

    this.onSearchEmployees();
  }
}
