<div class="container" style="text-align: center">
  <div class="d-flex flex-column" style="height: 100%">
    <div
      class="image-container"
      [ngStyle]="imageWidth ? { width: imageWidth } : {}"
    >
      <img class="image center" [src]="thumbnail" (click)="openDetail()" />
      <div
        style="width: 100%"
        class="d-flex justify-content-between image-overlay image-overlay-top"
      >
        <span
          class="action"
          *ngIf="checkbox"
          [ngStyle]="selected ? { display: 'inline' } : {}"
        >
          <label class="checkbox-inline custom-checkbox nowrap">
            <input
              type="checkbox"
              (change)="selectDeselect()"
              [(ngModel)]="selected"
            />
            <span></span>
          </label>
        </span>
        <span *ngIf="!checkbox || isPinned">&nbsp;</span>
        <div class="d-flex justify-content-end">
          <span
            (click)="share()"
            class="action icon"
            *ngIf="canAnnotate"
            [ngStyle]="actionStyle()"
          >
            <i class="fa fa-share"></i>
          </span>
          <span
            (click)="annotatePhoto()"
            class="action icon"
            *ngIf="canAnnotate"
            [ngStyle]="actionStyle()"
          >
            <i class="fa fa-pencil-square-o"></i>
          </span>
          <span
            (click)="onPreview()"
            class="action icon"
            [ngStyle]="actionStyle()"
          >
            <i class="fa fa-search"></i>
          </span>
          <span class="action icon" [ngStyle]="actionStyle()">
            <a href="javascript:void(0)" (click)="download()" title="Download"
              ><i class="fa fa-cloud-download" aria-hidden="true"></i
            ></a>
          </span>
          <span class="action icon" [ngStyle]="actionStyle()">
            <a *ngIf="canDelete" (click)="deletePhoto()" title="Delete"
              ><i class="fa fa-trash-o" aria-hidden="true"></i
            ></a>
          </span>
          <span
            *ngIf="canPin"
            class="action icon"
            [ngStyle]="pinnedStyle()"
            (click)="pinnedClicked()"
          >
            <span [ngStyle]="pinnedWrapperStyle()">
              <span
                class="pinned-icon ping"
                [ngClass]="isPinned ? 'pinned' : ''"
                title="{{ isPinned ? 'Unpin' : 'Pin' }}"
                ><i class="fa fa-thumb-tack"></i
                ><i *ngIf="isPinned" class="fa fa-ban"></i
              ></span>
            </span>
          </span>
        </div>
      </div>
      <div
        style="width: 100%"
        class="d-flex justify-content-between image-overlay image-overlay-bottom"
      >
        <span *ngIf="plexPhoto.OwnerInitials != ''" class="initials">
          <label style="font-size: smaller">{{
            plexPhoto.OwnerInitials
          }}</label>
        </span>
        <span *ngIf="plexPhoto.OwnerInitials == ''"> &nbsp;</span>
        <div class="d-flex justify-content-end">
          <span>
            <i *ngIf="plexPhoto.Tags" class="fa fa-tag tag"></i>
          </span>
          <span>
            <i *ngIf="plexPhoto.Comments" class="fa fa-comment comment"></i>
          </span>
        </div>
      </div>
    </div>
    <div class="below-image">
      <div class="d-flex flex-column">
        <span>
          <div class="dateString">
            Date Uploaded: {{ plexPhoto.UploadedOn }}
          </div>
          <div class="dateString">
            Date Taken: {{ plexPhoto.DisplayCreatedOn }}
          </div>
        </span>
        <span>
          <div class="title" *ngIf="titleDisplay">
            {{ titleDisplay }}
          </div>
          <div *ngIf="!titleDisplay">&nbsp;</div>
        </span>
      </div>
    </div>
  </div>
</div>

<p-sidebar
  [(visible)]="visibleSidebar"
  position="right"
  (onHide)="onHideSliderHide($event)"
  [baseZIndex]="90000"
  appendTo="body"
  class="addeditclient"
>
  <job-photo-roll-detail
    *ngIf="visibleSidebar && showDetails"
    [imageData]="plexPhoto"
    (close)="onHideSliderHide($event)"
    (emitIcons)="updateIcons($event)"
    (updated)="detailUpdated($event)"
  >
  </job-photo-roll-detail>
</p-sidebar>
