import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { CustomerService } from "app/common/services/customer.service";
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { AuthService } from "app/common/utility/AuthService";
export interface EmployeeModel {
  title: string;
  id: string;
}
export class ContactModel {
  FirstName: string;
  LastName: string;
  Email: string;
  Id: number;
  Name: string;
}
export class ACLModel {
  Contact: ContactModel[];
  permission: string;
  calendarId: string;
}
@Component({
  selector: "app-sales-calendar-share",
  templateUrl: "./sales-calendar-share.component.html",
  styleUrls: ["./sales-calendar-share.component.scss"],
})
export class SalesCalendarShareComponent
  extends DialogComponent<EmployeeModel, ACLModel>
  implements OnInit, EmployeeModel {
  id: string;
  title: string;
  permission: any[] = [];
  EmployeeList: ContactModel[] = [];
  changeDetectorRef: ChangeDetectorRef;
  selectedPermission: any;
  selectedContact: any[] = [];
  output: ACLModel;
  constructor(
    dialogService: DialogService,
    private customerService: CustomerService,
    changeDetectorRef: ChangeDetectorRef,
    private auth: AuthService
  ) {
    super(dialogService);
    this.getAllEmployee();
    this.changeDetectorRef = changeDetectorRef;
    this.permission = [
      {
        displayValue: "See only free/busy (hide details)",
        value: "freeBusyReader",
      },
      { displayValue: "See all event details", value: "reader" },
      { displayValue: "Make changes to events", value: "writer" },
      { displayValue: "Make Changes and manage sharing", value: "owner" },
    ];
    this.selectedPermission = this.permission[0].value;
  }
  ngOnInit() {}
  getAllEmployee() {
    this.customerService.getAllOrganizationContacts().subscribe((data) => {
      if (data) {
        this.EmployeeList = data;
        this.EmployeeList.forEach((data) => {
          data.Name = data.FirstName + " " + data.LastName;
        });
      }
    });
  }
  Confirm() {
    var that = this;
    this.output = new ACLModel();
    var Contact: ContactModel[] = [];
    this.selectedContact.forEach((id) => {
      var res = that.EmployeeList.find((data) => data.Id == id);
      Contact.push(res);
    });
    this.output.Contact = Contact;
    this.output.permission = this.selectedPermission;
    this.output.calendarId = this.id;
    this.result = this.output;
    this.close();
  }
}
