import { ReportServerEnvironment } from "app/common/directives/commonEnum";
import { environment } from "environments/environment";

export class GeneralReportModel {
  //serviceUrl: string;
  reportSource: ReportSourceModel;
  viewMode: string;
  scaleMode: string;
  scale: string;
  reportServer: any;
  constructor() {
    //this.serviceUrl = 'http://104.43.242.251/api/reports';
    this.reportSource = new ReportSourceModel();
    this.viewMode = "INTERACTIVE";
    this.scaleMode = "FIT_PAGE";
    this.scale = "1";
    this.reportServer = {
      url: "https://report.mobilepoolbuilder.com/",
      username: "sportscrmadmin",
      password: "Nickens123",
    };
  }
}

export class ReportSourceModel {
  report: string;
  parameters: any;
  constructor() {
    this.parameters = {};
  }
}
