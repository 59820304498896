import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";
import { SharedService } from "app/common/utility/SharedService";
import { environmentConstant } from "app/common/utility/environment";
import { Observable } from "rxjs";
import { ApiResponseModel as Api } from "app/model/ApiResponseModel";
import { VendorContactModel } from "app/model/Core/VendorContactModel";

@Injectable()
export class VendorContactService {
  constructor(private httpService: HttpService) {}

  getVendorContacts(orgVendorGuid): Observable<Api<VendorContactModel[]>> {
    return this.httpService.get<VendorContactModel[]>(
      environmentConstant.coreApi.getVendorContacts(orgVendorGuid)
    );
  }

  getContactInfo(contactGuid): Observable<Api<VendorContactModel>> {
    return this.httpService.get<VendorContactModel>(
      environmentConstant.coreApi.getContactInfo(contactGuid)
    );
  }

  putContactInfo(
    contactData: VendorContactModel
  ): Observable<Api<VendorContactModel>> {
    return this.httpService.put<VendorContactModel>(
      environmentConstant.coreApi.putContactInfo(contactData.guid),
      contactData
    );
  }

  postVendorContacts(
    orgVendorGuid: string,
    contactData: VendorContactModel
  ): Observable<Api<VendorContactModel[]>> {
    return this.httpService.post<VendorContactModel[]>(
      environmentConstant.coreApi.postVendorContacts(orgVendorGuid),
      contactData
    );
  }

  deleteVendorContact(contactGuid): Observable<Api<VendorContactModel[]>> {
    return this.httpService.delete<VendorContactModel[]>(
      environmentConstant.coreApi.deleteVendorContact(contactGuid)
    );
  }
}
