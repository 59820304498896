import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from "@angular/material/radio";
import { MatStepperModule } from "@angular/material/stepper";
import { RouterModule } from "@angular/router";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { DialogsModule, WindowModule } from "@progress/kendo-angular-dialog";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { ExcelModule, GridModule } from "@progress/kendo-angular-grid";
import { SwitchModule, TextBoxModule } from "@progress/kendo-angular-inputs";
import {
  LayoutModule,
  TabStripComponent,
  TabStripTabComponent,
} from "@progress/kendo-angular-layout";
import { PDFExportModule } from "@progress/kendo-angular-pdf-export";
import { UploadsModule } from "@progress/kendo-angular-upload";
import { TelerikReportingModule } from "@progress/telerik-angular-report-viewer";
import { RadioButtonModule } from "@syncfusion/ej2-angular-buttons";
import {
  DropDownListModule,
  MultiSelectModule,
} from "@syncfusion/ej2-angular-dropdowns";
import {
  TextBoxModule as tb,
  UploaderModule,
} from "@syncfusion/ej2-angular-inputs";
import { ArchwizardModule } from "angular-archwizard";
import { AppTranslationModule } from "app/app.translation.module";
import { CommonDirectiveModule } from "app/common/directives/commondirective.module";
import { CommonFilter } from "app/common/directives/FilterPipe";
import { TabContentLoadOnDemandDirective } from "app/common/directives/lazyLoadTab.directive";
import { CommonService } from "app/common/services/common.service";
import { CommonDataService } from "app/common/services/commonData.service";
import { ChecklistService } from "app/common/services/Core/Checklist.service";
import { OrgVendorService } from "app/common/services/Core/OrgVendor.service";
import { UserService } from "app/common/services/Core/User.service";
import { VendorContactService } from "app/common/services/Core/VendorContact.service";
import { EmailMessageService } from "app/common/services/emailmessage.service";
import { InvoiceService } from "app/common/services/invoice.service";
import { JobFileService } from "app/common/services/jobFile.service";
import { JobNoteService } from "app/common/services/jobNotes.service";
import { JobPhotoService } from "app/common/services/jobphoto.service";
import { JobQuoteService } from "app/common/services/jobQuote.service";
import { SalesService } from "app/common/services/sales.service";
import { CommonSubscription } from "app/common/utility/SharedService";
import { BuilPlanDialogModule } from "app/component/buildPlan-dialog/buildPlan-dialog.module";
import { AddEditBuildPlanModule } from "app/component/buildPlan/addEditBuildPlan/addEditbuildPlan.module";
import { EditDrawScheduleComponent } from "app/component/EditDrawSchedule/EditDrawSchedule.component";
import { FindCustomerComponent } from "app/component/FindCustomer/FindCustomer.component";
import { JobBillComponent } from "app/component/jobBill/jobBill.component";
import { JobPhaseBillComponent } from "app/component/jobPhaseBill/jobPhaseBill.component";
import { JobTemplateDialogModule } from "app/component/jobTemplate-dialog/jobTemplate-dialog.module";
import { ScheduleChangeComponent } from "app/component/ScheduleChange/ScheduleChange.component";
import { JobChecklistsCardComponent } from "app/dashboard/job-checklists-info-card/job-checklists-info-card";
import { JobPhotoCardComponent } from "app/dashboard/job-photo-info-card/job-photo-info.component";
import { JobfileInfoCardComponent } from "app/dashboard/jobfile-info-card/jobfile-info-card.component";
import { Bcc, Cc, EmailMessageModel, To } from "app/model/emailMessageModel";
import { AccountModule } from "app/pages/account/account.module";
import { ContactAddEditComponent } from "app/pages/contacts/contact/contactAddEdit/contactAddEdit.component";
import { ContractorContactComponent } from "app/pages/contacts/contact/contractor/AddEditContractorContact/AddEditContractorContact.component";
import { ContractorAddEditComponent } from "app/pages/contacts/contact/contractor/contractorAddEdit/contractorAddEdit.component";
import { EstimateDashboardChecklistItemModalComponent } from "app/pages/estimate/estimate-dashboard/estimate-dashboard-checklist-item-modal/estimate-dashboard-checklist-item-modal.component";
import { JobAddressEntryComponent } from "app/pages/jobs/job-address-entry/job-address-entry.component";
import { JobInfoConstructionComponent } from "app/pages/jobs/job-info-construction/job-info-construction.component";
import { JobInfoSalesComponent } from "app/pages/jobs/job-info-sales/job-info-sales.component";
import { JodAddEditComponent } from "app/pages/jobs/job/jobAddEdit/jobAddEdit.component";
import { jobSendMailComponent } from "app/pages/jobs/job/jobSendMail/jobSendMail.component";
import { EditChangeOrderComponent } from "app/pages/jobs/jobEdit/changeOrder/EditChangeOrder/EditChangeOrder.component";
import { DrawSchedulesComponent } from "app/pages/jobs/jobEdit/draw-schedules/draw-schedules.component";
import { DrawSchedulesFilterPipe } from "app/pages/jobs/jobEdit/draw-schedules/draw-schedulesFilter.pipe";
import { FilesComponent } from "app/pages/jobs/jobEdit/files/files.component";
import { JobChecklistsComponent } from "app/pages/jobs/jobEdit/jobchecklists/jobchecklists";
import { JobCostVsActualsComponent } from "app/pages/jobs/jobEdit/jobCostVsActuals/jobcostvsactuals.component";
import { NoteAddEditComponent } from "app/pages/jobs/jobEdit/notes/noteAddEdit/noteAddEdit.component";
import { NotesComponent } from "app/pages/jobs/jobEdit/notes/notes.component";
import { PhotosComponent } from "app/pages/jobs/jobEdit/photos/photos.component";
import { FileUploadComponent } from "app/pages/jobs/jobEdit/workOrder/EditWorkOrder/filePhoto/file.component";
import { JobPhotoRollDetailComponent } from "app/pages/jobs/photoRoll/job-photo-roll-detail/job-photo-roll-detail.component";
import { PhotoRollImagesComponent } from "app/pages/jobs/photoRoll/photo-roll-images/photo-roll-images.component";
import { PhotoViewerComponent } from "app/pages/jobs/photoRoll/photo-viewer/photo-viewer.component";
import { AddEditGalleryComponent } from "app/pages/sales/gallery/addEditGallery/addEditGallery.component";
import { saleAddEditComponent } from "app/pages/sales/sale/saleAddEdit/saleAddEdit.component";
import { SalesCalendarShareComponent } from "app/pages/sales/salesCalendar/sales-calendar-share/sales-calendar-share.component";
import { EditJobDetailsComponent } from "app/pages/sales/salesOnBoard/salesCard/edit-jobDetails/edit-jobDetails.component";
import { PipesModule } from "app/shared/pipes.module";
import { SharedModule } from "app/shared/shared.module";
import { NgaModule } from "app/theme/nga.module";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { DragulaModule } from "ng2-dragula";
import { ColorPickerModule } from "ngx-color-picker";
import { NgxGalleryModule } from "ngx-gallery";
import { ImageCropperModule } from "ngx-image-cropper";
import { OrderModule } from "ngx-order-pipe";
import { ToastrModule, ToastrService } from "ngx-toastr";
import {
  CarouselModule,
  ChipsModule,
  DataGridModule,
  DialogModule,
  EditorModule,
  InputMaskModule,
  InputSwitchModule,
  InputTextModule,
  MenuModule,
  MultiSelectModule as MultiSelectModuleNg,
  PanelModule,
  SidebarModule,
  TabViewModule,
  TreeModule,
} from "primeng/primeng";
import { TableModule } from "primeng/table";
import { ToastModule } from "primeng/toast";
import { TooltipModule } from "primeng/tooltip";
import { JobNotesInfoCardComponent } from "../dashboard/job-notes-info-card/job-notes-info-card.component";
import { ClientAddEdit } from "../pages/clients/client/clientAddEdit/clientAddEdit.component";
import { CalculationUpdateModal } from "../pages/estimate/estimate-summary/calculation-update-modal/calculation-update-modal.component";
import { WorkareaEstimateTableComponent } from "../pages/estimate/workarea-estimate-table/workarea-estimate-table.component";
import { AddChecklistComponent } from "../pages/jobs/jobEdit/jobchecklists/checklistAdd/checklistAdd";
import { AddPhotosComponent } from "../pages/jobs/jobEdit/photos/photosAdd/photosAdd.component";
import { OptionalItemsGridComponent } from "../pages/share-proposal/optional-items-grid/optional-items-grid.component";
import { ProposalComponent } from "../pages/share-proposal/proposal/proposal.component";
import { AddCalendarPopupComponent } from "./add-calendar-popup/add-calendar-popup.component";
import { AssignJobComponent } from "./AssignJob/AssignJob.component";
import { CalendarMoreOptionComponent } from "./calendar-more-option/calendar-more-option.component";
import { CatalogPickerComponent } from "./catalog-picker/catalog-picker.component";
import { AddChecklistTemplateComponent } from "./Checklist/add-checklist-template/add-checklist-template.component";
import { ChecklistDialogComponent } from "./Checklist/checklist-dialog/checklist-dialog.component";
import { ChecklistDropdownComponent } from "./Checklist/checklist-dropdown/checklist-dropdown.component";
import { ChecklistTemplateComponent } from "./Checklist/checklist-template/checklist-template.component";
import { ChecklistitemSidebarComponent } from "./Checklist/checklistitem-sidebar/checklistitem-sidebar.component";
import { ChecklistitemsComponent } from "./Checklist/checklistitems/checklistitems.component";
import { ComplexKitSelectionModalComponent } from "./complex-kit-selection-modal/complex-kit-selection-modal.component";
import { CustomerInfoComponent } from "./customer-basicInfo/customer-basicInfo.component";
import { EditAddressComponent } from "./edit-address/edit-address.component";
import { EditJobService } from "./edit-job/edit-job.service";
import { EstimateListModalComponent } from "./estimate-list-modal/estimate-list-modal.component";
import { EstimateWizardModalComponent } from "./estimate-wizard-modal/estimate-wizard-modal.component";
import { AddFilesComponent } from "./FileComponent/AddFile.component";
import { GeneralReportsComponent } from "./general-reports/general-reports.component";
import { GridKitDetails } from "./grid-kit-details/grid-kit-details.component";
import { AddGroupSelectionModalComponent } from "./group-selection-modal/add-group-selection-modal.component";
import { GroupSelectionModalComponent } from "./group-selection-modal/group-selection-modal.component";
import { ImageAnnotatorModule } from "./image-annotator/image-annotator/image-annotator.module";
import { ImageThumbnailComponent } from "./image-thumbnail/image-thumbnail.component";
import { ImageViewerComponent } from "./image-viewer/image-viewer.component";
import { ImportEquipmentComponent } from "./import-equipment/import-equipment.component";
import { AppJobContactsComponent } from "./job-contacts/job-contacts.component";
import { JobFileCrudComponent } from "./job-file-crud/job-file-crud.component";
import { JobNoteComponent } from "./job-note/job-note.component";
import { JobQuoteComponent } from "./jobQuote/jobQuote.component";
import { JobQuoteDetailComponent } from "./jobQuote/jobQuoteDetail/jobQuoteDetail.component";
import { LeadContactsComponent } from "./lead-contacts/lead-contacts.component";
import { MaterialUIComponentsModule } from "./material-ui-components.module";
import { MediaResourcesModule } from "./MediaResource/MediaResources.module";
import { PhotoCarouselModule } from "./photo-carousel/photo-carousel.module";
import { PhotoGridComponent } from "./photo-grid/photo-grid.component";
import { ProductSelectionModalComponent } from "./product-selection-modal/product-selection-modal.component";
import { ProductQtyInputComponent } from "./product-selector-modal/product-qty-input/product-qty-input.component";
import { ProductSelectorModal } from "./product-selector-modal/product-selector-modal.component";
import { QuickBooksModule } from "./QuickBooks/QuickBooks.module";
import { ReportComponent } from "./report/report.component";
import { RichTextEditorModule } from "./richtexteditor/richtexteditor.module";
import { SalesQuickAddComponent } from "./sales-quick-add/sales-quick-add.component";
import { SelectCustomerComponent } from "./select-customer/select-customer.component";
import { ShareJobfileDialogComponent } from "./share-jobfile-dialog/share-jobfile-dialog.component";
import { SimpleKitSelectionModalComponent } from "./simple-kit-selection-modal/simple-kit-selection-modal.component";
import { EstimateModalModule } from "../shared/estimate-modal.module";
import { CatalogComponent } from "app/pages/catalog/catalog.component";
import { CategoriesTreeComponent } from "app/pages/catalog/categories/categories-tree.component";
import { ChecklistsSidebarComponent } from "./Checklist/checklists-sidebar/checklists-sidebar.component";
import { ConfirmationService } from "primeng/api";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ExportFileDialogComponent } from "./export-file-dialog/export-file-dialog.component";
import { FilesListComponent } from "./files-list/files-list.component";
import { ExportFileListComponent } from "./export-file-list/export-file-list.component";
import { ProposalFeeScheduleComponent } from "app/pages/share-proposal/proposal-fee-schedule/proposal-fee-schedule.component";
import { ContactsDialogComponent } from "./contacts-tabbed-entry/contacts-dialog/contacts-dialog.component";
import {
  EditorModule as TinyMceEditorModule,
  TINYMCE_SCRIPT_SRC,
} from "@tinymce/tinymce-angular";
import { TextEditorConfigService } from "../common/services/textEditorConfig.service";
import { ChangeOrdersEDocuments } from "../pages/jobs/jobEdit/changeOrder/changeOrdersEDocuments/changeOrdersEDocuments.component";
import { FileUploadService } from "../common/services/fileUpload.service";
import { ChangeOrderFormLayoutComponent } from "app/pages/jobs/jobEdit/changeOrder/form-layout/change-order-form-layout.component";
import { SignersModalComponent } from "./SignNow/signers-modal/signers-modal.component";

@NgModule({
  imports: [
    CommonModule,
    AccountModule,
    FormsModule,
    DialogModule,
    ImageCropperModule,
    DropDownsModule,
    TextBoxModule,
    DateInputsModule,
    CurrencyMaskModule,
    DataGridModule,
    AppTranslationModule,
    LayoutModule,
    GridModule,
    ExcelModule,
    InputTextModule,
    AddEditBuildPlanModule,
    DragulaModule,
    SidebarModule,
    BuilPlanDialogModule,
    JobTemplateDialogModule,
    RouterModule,
    PanelModule,
    PhotoCarouselModule,
    NgaModule,
    SharedModule,
    InputMaskModule,
    ArchwizardModule,
    ColorPickerModule,
    EditorModule,
    UploaderModule,
    RadioButtonModule,
    DropDownListModule,
    MultiSelectModule,
    tb,
    TelerikReportingModule,
    QuickBooksModule,
    MediaResourcesModule,
    ChipsModule,
    SwitchModule,
    ToastModule,
    CommonDirectiveModule,
    MultiSelectModuleNg,
    CarouselModule,
    NgxGalleryModule,
    ImageAnnotatorModule,
    TabViewModule,
    ReactiveFormsModule,
    WindowModule,
    ButtonsModule,
    MatStepperModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatDialogModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MaterialUIComponentsModule,
    TableModule,
    DialogsModule,
    InputSwitchModule,
    MenuModule,
    TooltipModule,
    PDFExportModule,
    RichTextEditorModule,
    PipesModule,
    UploadsModule,
    EstimateModalModule,
    TreeModule,
    TinyMceEditorModule,
    ToastrModule.forRoot({
      positionClass: "toast-top-center",
    }),
    ConfirmDialogModule,
  ],
  declarations: [
    ChecklistsSidebarComponent,
    EditDrawScheduleComponent,
    ScheduleChangeComponent,
    NotesComponent,
    JobNotesInfoCardComponent,
    FilesComponent,
    JobChecklistsComponent,
    PhotosComponent,
    NoteAddEditComponent,
    AddPhotosComponent,
    AddChecklistComponent,
    FileUploadComponent,
    JobPhaseBillComponent,
    JobBillComponent,
    JobCostVsActualsComponent,
    JodAddEditComponent,
    FindCustomerComponent,
    CommonFilter,
    AssignJobComponent,
    jobSendMailComponent,
    ContractorAddEditComponent,
    ContractorContactComponent,
    ContactAddEditComponent,
    AddFilesComponent,
    JobPhotoCardComponent,
    JobQuoteDetailComponent,
    EditChangeOrderComponent,
    JobQuoteComponent,
    SalesQuickAddComponent,
    CalendarMoreOptionComponent,
    SalesCalendarShareComponent,
    AddCalendarPopupComponent,
    EditJobDetailsComponent,
    ImportEquipmentComponent,
    DrawSchedulesComponent,
    DrawSchedulesFilterPipe,
    saleAddEditComponent,
    GeneralReportsComponent,
    AppJobContactsComponent,
    EditAddressComponent,
    AddEditGalleryComponent,
    ChecklistTemplateComponent,
    ChecklistitemSidebarComponent,
    CustomerInfoComponent,
    AddChecklistTemplateComponent,
    ChecklistDropdownComponent,
    ChecklistitemsComponent,
    JobChecklistsCardComponent,
    JobPhotoRollDetailComponent,
    PhotoRollImagesComponent,
    PhotoViewerComponent,
    PhotoGridComponent,
    SelectCustomerComponent,
    ImageThumbnailComponent,
    ShareJobfileDialogComponent,
    JobNoteComponent,
    ChecklistDialogComponent,
    JobInfoConstructionComponent,
    JobInfoSalesComponent,
    JobAddressEntryComponent,
    EstimateWizardModalComponent,
    LeadContactsComponent,
    EstimateWizardModalComponent,
    EstimateListModalComponent,
    EstimateDashboardChecklistItemModalComponent,
    JobFileCrudComponent,
    CatalogPickerComponent,
    ProductSelectionModalComponent,
    ProductSelectorModal,
    ProductQtyInputComponent,
    SimpleKitSelectionModalComponent,
    ComplexKitSelectionModalComponent,
    GroupSelectionModalComponent,
    AddGroupSelectionModalComponent,
    CalculationUpdateModal,
    ProposalComponent,
    GridKitDetails,
    ReportComponent,
    WorkareaEstimateTableComponent,
    JobfileInfoCardComponent,
    TabContentLoadOnDemandDirective,
    ImageViewerComponent,
    OptionalItemsGridComponent,
    ProposalFeeScheduleComponent,
    CatalogComponent,
    CategoriesTreeComponent,
    FilesListComponent,
    ExportFileDialogComponent,
    ExportFileListComponent,
    ContactsDialogComponent,
    ChangeOrdersEDocuments,
    ChangeOrderFormLayoutComponent,
    SignersModalComponent,
  ],
  exports: [
    ChecklistsSidebarComponent,
    EditDrawScheduleComponent,
    ScheduleChangeComponent,
    PhotosComponent,
    AddPhotosComponent,
    AddChecklistComponent,
    ChecklistitemsComponent,
    NotesComponent,
    JobChecklistsCardComponent,
    JobNotesInfoCardComponent,
    FilesComponent,
    JobChecklistsComponent,
    NoteAddEditComponent,
    FileUploadComponent,
    JobPhaseBillComponent,
    JobBillComponent,
    JobCostVsActualsComponent,
    JodAddEditComponent,
    ClientAddEdit,
    FindCustomerComponent,
    CommonFilter,
    AssignJobComponent,
    jobSendMailComponent,
    OrderModule,
    ContractorAddEditComponent,
    ContractorContactComponent,
    CustomerInfoComponent,
    ContactAddEditComponent,
    PhotoCarouselModule,
    AddFilesComponent,
    JobPhotoCardComponent,
    JobQuoteDetailComponent,
    EditChangeOrderComponent,
    JobQuoteComponent,
    EditJobDetailsComponent,
    SalesQuickAddComponent,
    ImportEquipmentComponent,
    DrawSchedulesComponent,
    saleAddEditComponent,
    GeneralReportsComponent,
    AppJobContactsComponent,
    EditAddressComponent,
    AddEditGalleryComponent,
    ChecklistDropdownComponent,
    JobPhotoRollDetailComponent,
    PhotoRollImagesComponent,
    PhotoViewerComponent,
    ImageAnnotatorModule,
    SelectCustomerComponent,
    ImageThumbnailComponent,
    ShareJobfileDialogComponent,
    JobNoteComponent,
    EstimateWizardModalComponent,
    EstimateListModalComponent,
    EstimateDashboardChecklistItemModalComponent,
    JobFileCrudComponent,
    CatalogPickerComponent,
    ProductSelectionModalComponent,
    ProductSelectorModal,
    ProductQtyInputComponent,
    SimpleKitSelectionModalComponent,
    ComplexKitSelectionModalComponent,
    InputSwitchModule,
    TableModule,
    MenuModule,
    GroupSelectionModalComponent,
    AddGroupSelectionModalComponent,
    CalculationUpdateModal,
    ProposalComponent,
    GridKitDetails,
    JobfileInfoCardComponent,
    ImageViewerComponent,
    OptionalItemsGridComponent,
    ProposalFeeScheduleComponent,
    CatalogComponent,
    CategoriesTreeComponent,
    FilesListComponent,
    ChangeOrdersEDocuments,
    ChangeOrderFormLayoutComponent,
    SignersModalComponent,
  ],
  entryComponents: [
    ShareJobfileDialogComponent,
    LeadContactsComponent,
    EstimateWizardModalComponent,
    EstimateListModalComponent,
    EstimateDashboardChecklistItemModalComponent,
    JobFileCrudComponent,
    ProductSelectionModalComponent,
    ProductSelectorModal,
    SimpleKitSelectionModalComponent,
    ComplexKitSelectionModalComponent,
    GroupSelectionModalComponent,
    AddGroupSelectionModalComponent,
    ReportComponent,
    TabStripComponent,
    TabStripTabComponent,
    ExportFileDialogComponent,
    ContactsDialogComponent,
    SignersModalComponent,
  ],
  providers: [
    UserService,
    JobFileService,
    JobPhotoService,
    JobNoteService,
    CommonService,
    CommonDataService,
    InvoiceService,
    EmailMessageService,
    EmailMessageModel,
    JobQuoteService,
    VendorContactService,
    OrgVendorService,
    Bcc,
    To,
    Cc,
    CommonSubscription,
    SalesService,
    EditJobService,
    ChecklistService,
    ToastrService,
    ConfirmationService,
    { provide: TINYMCE_SCRIPT_SRC, useValue: "tinymce/tinymce.min.js" },
    TextEditorConfigService,
    FileUploadService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommonComponentModule {}
