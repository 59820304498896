import { ComponentsRelevantChangesFromDb } from "./ComponentsRelevantChanges";
import { ProspectRatingModel } from "./Core/ProspectRatingModel";
import { DrawSchedulePaymentModel } from "./DrawSchedulePaymentModel";
import { EstimateProductModel } from "./EstimateProductModel";
import { MPBStatusModel } from "./MPBStatusModel";
import { WorksheetWorkArea } from "./workAreaSpecsModel";
import { WorksheetDetailFromDB } from "./WorksheetDetailObject";
import { WorksheetStatusModel } from "./WorksheetStatusModel";

export interface EstimateWorksheetInfo {
  id?: number;
  jobGuid: string;
  estimateName: string;
  projectName?: string;
  projectId: number;
  projectCategoryId: number;
  projectPackageId: number;
  customerId: number;
  estimateOwnerId: number;
  lastUpdatedOn?: Date;
  orgGuid: string;
  worksheetStatus: WorksheetStatus;
  contractId?: string;
  contractStatus?: WorksheetStatusModel;
  contractSentDate?: Date;
  customerName?: string;
  firstName?: string;
  lastName?: string;
  prospectRating?: ProspectRatingModel;
  expirationDate?: Date;
  markupPercentage: number;
  markupAmount: number;
  orgTaxAuthorityId: number;
  taxRate: number;
  customerGuid?: string;
  estimateOwnerName?: string;
  totalPrice?: string | number;
  hasActivity?: boolean;
  contractSignedDate?: Date;
  worksheetStatusModel?: WorksheetStatusModel;
  jobStatus?: MPBStatusModel;
  actuals?: number | string;
  currentBudget?: number | string;
  originalBudget?: number | string;
  email?: string;
  componentsRelevantChanges?: ComponentsRelevantChangesFromDb[];
  worksheetDetail?: WorksheetDetailFromDB[];
  totalPriceWithTax?: number;
  totalPriceWithoutTax?: number;
  worksheetStatusId?: number;
  taxAuthorityName?: string;
  templateDesignGuid?: string;
  estimateLastSharedDate?: Date;

  // This is a calculated field. If one of its worksheetDetails needs recalculation, this field is true
  needsRecalculation?: boolean;
  isThereNewActivePriceBook?: boolean;
  isProposalSent?: boolean;
  isExpired?: boolean;
  approvedDate?: Date;
  guid: string;
  locked: boolean;
  margin: number;
  drawScheduleId?: number;
  includeOnProposal?: boolean;
  includeOnContract?: boolean;
  isHomeBuilderMarkup?: boolean;
}

export interface NewEstimateWorksheetInfo {
  id: number;
  jobGuid: string;
  estimateName: string;
  projectName?: any;
  projectId: number;
  projectCategoryId: number;
  projectPackageId: number;
  customerId: number;
  estimateOwnerId: number;
  lastUpdatedOn: Date;
  orgGuid: string;
  worksheetStatus: number;
  firstName?: string;
  lastName?: string;
  contractId?: string;
  contractStatus?: WorksheetStatusModel;
  contractSentDate?: Date;
  prospectRating?: ProspectRatingModel;
  customerName?: string;
  expirationDate: Date;
  markupPercentage: number;
  markupAmount: number;
  taxingAuthority: string;
  taxRate: number;
  projectCategoryName?: string;
  totalPrice: number;
  customerGuid?: string;
  estimateOwnerName?: string;
  contractSignedDate?: Date;
  hasActivity: boolean;
  worksheetStatusModel?: WorksheetStatusModel;
  jobStatus?: MPBStatusModel;
  originalBudget: number;
  currentBudget: number;
  actuals: number;
  email?: string;
  needsRecalculation?: boolean;
  isThereNewActivePriceBook?: boolean;
  totalPriceWithoutTax: number;
  totalPriceWithTax: number;
  totalPriceWithTaxAndMarkup: number;
  componentsRelevantChanges: ComponentsRelevantChangesFromDb[];
  worksheetDetail: WorksheetDetailFromDB[];
  product: EstimateProductModel;
  showPricing?: boolean;
  unitOfMeasure?: string;
  priceWithTax?: number;
  priceWithoutTax?: number;
  qty?: number;
  orgTaxAuthorityId: number;
  feeSchedules: DrawSchedulePaymentModel[];
  customerProposalViewed?: boolean;

  photos?: string[];
  warranties?: string[];
  disclaimers?: string[];
  guid: string;
  hasTemplateAssignedSignNow: boolean;
  locked: boolean;
  isOptional: boolean;
  margin: number;
  proposalImages?: string[];
  changeOrderId?: number;
}

export interface CreateEstimateWorksheetArgs extends EstimateWorksheetInfo {
  workAreas?: WorksheetWorkArea[];
}

export enum WorksheetStatus {
  All = 0,
  Active = 1,
  Deleted = 2,
  Approved = 3,
  Shared = 4,
  ModifiedByCustomer = 14,
  ReadyForCustomerApproval = 15,
}
export enum WorksheetContractStatus {
  Contract_ESigned = 5,
  Contract_Pending = 6,
  Contract_Expired = 7,
  Contract_Declined = 8,
  Contrat_Canceled = 9,
  Contract_Sent = 10,
  Contract_Signed_Manually = 11,
}

export interface UpdateWorksheetStatusDTO {
  id: number;
  guid: string;
  status: WorksheetStatus;
}

export enum MarginMarkupStatus {
  Off = 1,
  Markup = 2,
  Margin = 3,
}

export interface EstimateDrawSchedule {
  estimateWorksheetGuid: string;
  drawScheduleId: number;
  includeOnProposal: boolean;
  includeOnContract: boolean;
}
