<section>
  <div class="row w-100 button-container d-flex justify-content-end">
    <button
      class="download"
      type="button"
      (click)="downloadJobFiles()"
      style="height: 25px"
      *ngIf="fileList.length"
    >
      <i class="fa fa-download" style="margin-right: 1px; font-size: 11px"> </i>
      Download
    </button>
  </div>
</section>

<!-- Grid -->
<kendo-grid
  [data]="gridData"
  #grid="kendoGrid"
  (edit)="handleEdit($event)"
  (cancel)="cancelHandler($event)"
  (save)="saveHandler($event)"
  (remove)="deleteJobFile($event)"
  [filter]="state.filter"
  [sort]="state.sort"
>
  <kendo-grid-column
    [style]="{
      'background-color': 'inherit',
      color: '#666666',
      padding: '0px'
    }"
    [headerStyle]="{
      'font-size': '13px',
      padding: '8px 6px !important',
      color: '#666666',
      'background-color': '#ffffff'
    }"
    [width]="isediting ? 75 : 30"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="acButton">
        <button
          kendoGridSaveCommand
          [disabled]="formGroup?.invalid"
          class="files--action-button__transparent"
        >
          <span class="updatebtn">
            <span title="Save">
              <i class="fa fa-floppy-o" aria-hidden="true"></i>
            </span>
          </span>
        </button>
        <button
          kendoGridCancelCommand
          class="files--action-button__transparent"
        >
          <span class="cancelbtn">
            <span title="Cancel">
              <i class="fa fa-ban" aria-hidden="true"></i>
            </span>
          </span>
        </button>
      </div>
      <div class="dropdown cud">
        <i class="fa fa-ellipsis-v" id="menu1" data-toggle="dropdown"> </i>
        <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
          <li class="bg-white border-0" (click)="view(dataItem)">
            <a style="text-decoration: none !important">
              <i class="fa fa-download"> </i>View/Download</a
            >
          </li>
          <li class="bg-white border-0" kendoGridEditCommand>
            <a style="text-decoration: none !important"
              ><i class="fa fa-pencil"></i>Edit</a
            >
          </li>
          <li
            class="bg-white border-0"
            kendoGridRemoveCommand
            data-toggle="modal"
          >
            <a style="text-decoration: none !important">
              <i class="fa fa-trash-o"></i>
              Delete
            </a>
          </li>
        </ul>
      </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [style]="{
      'background-color': 'inherit',
      color: '#666666',
      padding: '5px 3px 3px 10px'
    }"
    [headerStyle]="{
      'font-size': '13px',
      padding: '8px 6px !important',
      color: '#666666',
      'background-color': '#ffffff'
    }"
    class="gridRow"
    width="34"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <input
        type="checkbox"
        name="{{ dataItem.ID }}"
        [(ngModel)]="dataItem.selected"
      />
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    class="gridrow"
    field="FileName"
    title="Name"
    [style]="{ 'background-color': 'inherit', color: '#666666' }"
    [headerStyle]="{
      'font-size': '13px',
      padding: '8px 6px !important',
      color: '#666666',
      'background-color': '#ffffff'
    }"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      <span class="pointer" (click)="view(dataItem)">
        {{ dataItem.FileName }}
      </span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="FileTypeName"
    title="File Type"
    [style]="{ 'background-color': 'inherit', color: '#666666' }"
    [headerStyle]="{
      'font-size': '13px',
      padding: '8px 6px !important',
      color: '#666666',
      'background-color': '#ffffff'
    }"
  >
    <ng-template
      kendoGridEditTemplate
      let-dataItem="dataItem"
      let-formGroup="formGroup"
    >
      <kendo-dropdownlist
        [data]="fileTypes"
        textField="FileTypeName"
        valueField="Id"
        [valuePrimitive]="true"
        [formControl]="formGroup.get('FileTypeName')"
      >
      </kendo-dropdownlist>
    </ng-template>
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.FileTypeName }}
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="CreatedOn"
    title="Created On"
    filter="date"
    format="{0: yyyy-MM-dd HH:mm:ss}"
    [style]="{ 'background-color': 'inherit', color: '#666666' }"
    [headerStyle]="{
      'font-size': '13px',
      color: '#666666',
      'background-color': '#ffffff'
    }"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
      {{ dataItem.CreatedOn | date : "short" }}
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
