import { Injectable } from "@angular/core";
import { HttpService } from "../../../app/common/utility/http.service";
import { environmentConstant } from "../../../app/common/utility/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { SharedService } from "app/common/utility/SharedService";
import {
  ProcessPaymentModel,
  MakePaymentRequest,
} from "app/model/PaymentModel";
import { GatewayAccountModel } from "app/model/GatewayAccountModel";
@Injectable()
// tslint:disable:prefer-template
export class PaymentService {
  constructor(
    private httpService: HttpService,
    private sharedService: SharedService
  ) {}

  //Payment service 02/08/20
  postProcessPayment(paymentmodel: MakePaymentRequest) {
    return this.httpService.postRequestService(
      environmentConstant.processPayment,
      paymentmodel
    );
  }

  getPaymentMethod(): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getPaymentList +
        this.sharedService.selectedOrganization.ID
    );
  }
  getPaymentData(): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getPaymentData +
        this.sharedService.selectedOrganization.ID
    );
  }
  getPaymentbyDate(startDate: any, endDate: any): Observable<any> {
    return this.httpService.getRequestService(
      environmentConstant.getPaymentbyDate +
        this.sharedService.selectedOrganization.ID +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate
    );
  }

  //Gateway Account 12/13/19
  getGatewayAccountListByOrg(orgGuid) {
    return this.httpService.getRequestService(
      environmentConstant.getGatewayAccountByOrg + orgGuid
    );
  }

  getGatewayAccountByOrg(orgGuid) {
    return this.httpService.getRequestService(
      environmentConstant.gatewayAccountDefault + orgGuid
    );
  }

  postGatwayAccount(gatewayAccount: GatewayAccountModel) {
    return this.httpService.postRequestService(
      environmentConstant.gatewayAccount,
      gatewayAccount
    );
  }

  putGatwayAccount(gatewayAccount: GatewayAccountModel) {
    return this.httpService.putRequestService(
      environmentConstant.gatewayAccount,
      gatewayAccount
    );
  }

  //Processing payment new 12/10/19
  getPaymentsById(tId: number) {
    return this.httpService
      .getRequestService(
        environmentConstant.getPaymentById +
          tId +
          this.sharedService.selectedOrganization.ID
      )
      .pipe(map((res) => res.json()));
  }
  getNewCardResponsebyId(id: number) {
    return this.httpService
      .getRequestService(
        environmentConstant.cardlookupId +
          id +
          this.sharedService.selectedOrganization.ID
      )
      .pipe(map((res) => res.json()));
  }
}
