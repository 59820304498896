import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { map, mergeMap, toArray } from "rxjs/operators";
import { from } from "rxjs";
import { CommonDataService } from "app/common/services/commonData.service";
import { SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { CustomerModel } from "app/model/customerModel";
import { OrderModel } from "app/model/OrderModel";
import { InvoiceService } from "app/common/services/invoice.service";

@Component({
  selector: "mpbui-order-grid",
  templateUrl: "./mpbuiorder-grid.component.html",
  styleUrls: ["./mpbuiorder-grid.component.scss"],
})
export class MPBUIOrderGridComponent implements OnInit {
  @Input() customer: CustomerModel;
  @Output() closed = new EventEmitter<boolean>();

  public selectedDateRange = { start: null, end: null };

  orderList: OrderModel[];

  public gridView: GridDataResult;
  public sort: SortDescriptor[] = [
    {
      field: "ModuleId",
      dir: "asc",
    },
    {
      field: "CreatedOn",
      dir: "desc",
    },
  ];

  constructor(
    private commonDataService: CommonDataService,
    private invoiceService: InvoiceService
  ) {}

  ngOnInit() {
    this.getOrders();
  }

  getOrders() {
    this.invoiceService
      .getInvoiceListByCustomerId(
        this.customer.OrgId,
        this.customer.CustomerGuid,
        false,
        this.selectedDateRange.start,
        this.selectedDateRange.end
      )
      .pipe(
        mergeMap((response) => {
          return from(response).pipe(
            map((x) => {
              x.OrderDate = new Date(x.OrderDate);
              return x;
            })
          );
        }),
        toArray(),
        map((z) => {
          this.orderList = z;
          this.loadGrid();
        })
      )
      .subscribe();
  }

  getModuleIdDisplayName(moduleId) {
    let moduleIdDisplayName = "";

    switch (moduleId) {
      case 1:
        moduleIdDisplayName = "Invoice";
        break;
      case 2:
        moduleIdDisplayName = "Order";
        break;
    }

    return moduleIdDisplayName;
  }

  getStatusIdDisplayName(statusId) {
    let statusIdDisplayName = "";

    switch (statusId) {
      case 10:
        statusIdDisplayName = "Pending";
        break;
      case 20:
        statusIdDisplayName = "Authorized";
        break;
      case 30:
        statusIdDisplayName = "Paid";
        break;
      case 40:
        statusIdDisplayName = "Partial Refund";
        break;
      case 50:
        statusIdDisplayName = "Refund";
        break;
      case 60:
        statusIdDisplayName = "Voided";
        break;
    }

    return statusIdDisplayName;
  }

  loadGrid() {
    this.gridView = {
      data: orderBy(this.orderList, this.sort),
      total: this.orderList.length,
    };
  }

  // ***************************************************************
  // Events
  // ***************************************************************
  dateRangeSelected(e) {
    console.log(this.selectedDateRange.start);
    this.getOrders();
  }

  reloadGrid(e) {
    e.preventDefault();
    this.getOrders();
  }

  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadGrid();
  }

  viewDetail(itemGuid) {
    window.open("./#/pages/jobs/jobEdit/" + itemGuid);
  }

  onCancel() {
    this.closed.emit(true);
  }
}
