<div class="estimate-theme mat-dialog mat-dialog--l">
  <mat-dialog-content>
    <h3 class="mat-dialog__header">New Group</h3>
    <form [formGroup]="groupsForm">
      <div class="form-container">
        <div class="form-container__row">
          <div class="form-container__row--items row-xl padding-right-1">
            <div class="input-pentair-std">
              <label>Group Name</label>
              <input
                type="text"
                formControlName="groupName"
                max="50"
                class="width-100"
              />
              <div class="small text-danger padding-top-1" *ngIf="submitted">
                <span *ngIf="groupsForm.get('groupName').errors?.required">
                  Field is required
                </span>
                <span *ngIf="groupsForm.get('groupName').errors?.maxlength.requiredLength">
                  Maximum length is {{ groupsForm.get('groupName').errors.maxlength.requiredLength }} characters
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions
    class="mat-dialog__actions mat-dialog__actions--align-right"
  >
    <button class="button button--secondary button--md" matDialogClose>
      Cancel
    </button>
    <button class="button button--primary button--md" (click)="saveGroup()">
      Save
    </button>
  </mat-dialog-actions>
</div>
