import { Injectable } from "@angular/core";
import { MaterialCalculator } from "app/model/MaterialsCalculator";
import { ParameterFromDB } from "app/model/ParameterOnProject";
import {
  Parameter,
  RelatedParamsFromCalcService,
} from "app/model/workAreaSpecsModel";
import { Helper } from "../utility/helper";
import { ParameterFromDB as ParamFromDb } from "app/model/ParameterOnProject";
import { ParamaterToUpdate } from "app/model/Parameter";

@Injectable({
  providedIn: "root",
})
export class CalculatorsParametersService {
  calculators: MaterialCalculator[];
  parameters: Parameter[] | ParameterFromDB[] | ParamFromDb[];
  hasParamErrors: boolean = false;
  constructor() {}

  /**
   * Initializer of properties
   * @param parameters
   * @param calculators
   */
  init(
    parameters: ParameterFromDB[] | ParamFromDb[] | Parameter[],
    calculators: MaterialCalculator[]
  ) {
    this.calculators = calculators;
    this.parameters = parameters;
  }

  /**
   * Get related parameters of a parameter
   * @param parameterId
   * @param parameters
   * @param calculators
   * @returns
   */
  getRelatedParameters(parameterId: number): RelatedParamsFromCalcService {
    // Starting operation without errors
    this.hasParamErrors = false;

    let visiteds = [];
    const parameter = (this.parameters as Parameter[]).find(
      (p) => p.id === parameterId
    );
    let relatedParams = [parameter];

    if (!parameter) {
      // Reseting the field
      this.hasParamErrors = false;
      console.warn(
        `The parameter with id ${parameterId} couldn't been evaluated because there was an error`
      );
      return {
        parameters: [],
        hasErrors: true,
      };
    }

    if (parameter.entryType) {
      const otherParams = this.getCalculatorParams(parameter.entryType);
      let stack = otherParams ? [...otherParams] : [];
      while (stack.length > 0) {
        const curr = stack.pop();
        if (!curr) {
          console.warn(
            "One parameter was needed but couldn't be found because it was deleted"
          );
          continue;
        }

        if (!visiteds.includes(curr.id)) {
          relatedParams.push(curr);
        }

        if (curr.entryType) {
          const moreParams = this.getCalculatorParams(curr.entryType);
          const unvisitedParams = moreParams.filter(
            (p) => p && !visiteds.includes(p.id)
          );
          stack = [...stack, ...unvisitedParams];
        }

        visiteds.push(curr.id);
      }
    }
    const res: RelatedParamsFromCalcService = {
      parameters: relatedParams,
      hasErrors: this.hasParamErrors,
    };

    this.hasParamErrors = false;

    return res;
  }

  /**
   * Get parameters by formula
   * @param formula
   * @returns
   */
  private getFormulaParams(formula: string, paramsFromWorkAreas?): Parameter[] {
    const paramsAsString = Helper.stringToLettersArray(formula, "%");
    const paramsAsNumber = paramsAsString.map((s) => +s);
    const paramsToUse = paramsFromWorkAreas || this.parameters;
    const params = paramsAsNumber.map((n) =>
      (paramsToUse as Parameter[]).find((p) => p.id === n)
    );

    if (params.some((x) => !x)) {
      console.warn(
        "There was a problem trying to obtain the parameters of this formula: ",
        formula
      );
      this.hasParamErrors = true;
      return params.filter((x) => x);
    }

    return params;
  }

  /**
   * Get calculator
   * @param id
   * @returns
   */
  private getCalculator(id: number): MaterialCalculator {
    return this.calculators.find((c) => c.id === id);
  }

  /**
   * Get the parameters of a calculators formula
   * @param id
   * @returns
   */
  getCalculatorParams(id: number, paramsFromWorkAreas?): Parameter[] {
    // Starting operation without errors
    this.hasParamErrors = false;

    const calc = this.getCalculator(id);
    if (calc) {
      return this.getFormulaParams(calc.formula, paramsFromWorkAreas);
    } else {
      console.warn(
        "One calculators was needed was deleted and it coulnd't be found"
      );
      this.hasParamErrors = true;
      return [];
    }
  }

  /**
   * Checks if a parameter is valid for a calculator
   * @param parameter
   * @param calculatorId
   * @returns
   */
  isValidParameterForCalculatorDropdown(
    parameter: ParamaterToUpdate,
    calculatorId: number
  ): boolean {
    // Starting operation without errors
    this.hasParamErrors = false;

    // If doesn't have an entry type, is valid
    if (!parameter.entryType) {
      return true;
    }

    let currentCalculatorParams: ParamaterToUpdate[] = this.getCalculatorParams(
      parameter.entryType
    ).filter((x) => x);

    // If any parameter is calculated by the calculator itself, it is invalid
    if (
      currentCalculatorParams.some((param) => param.entryType === calculatorId)
    ) {
      return false;
    }

    const res = this.traverseTree(currentCalculatorParams, calculatorId, true);

    // Reseting the errors field
    if (this.hasParamErrors) {
      console.warn("This parameter had errors: ", parameter);
      this.hasParamErrors = false;
      return false;
    }

    return res;
  }

  /**
   * Checks if a calculator is valid for a parameter
   * @param calculator
   * @param parameterId
   * @returns
   */
  isValidCalculatorForParameterDropdown(
    calculator: MaterialCalculator,
    parameterId: number
  ): boolean {
    // Starting operation without errors
    this.hasParamErrors = false;

    if (calculator.id === 0) {
      return true;
    }
    const relatedParams = this.getCalculatorParams(calculator.id);
    if (relatedParams.some((p) => p && p.id === parameterId)) {
      return false;
    }

    const res = this.traverseTree(relatedParams, parameterId);

    if (this.hasParamErrors) {
      console.warn("This calculator had errors: ", calculator);
      this.hasParamErrors = false;
      return false;
    } else {
      return res;
    }
  }

  /**
   * Traverse the tree structure of relations between parameters and calculators
   * @param relatedParams
   * @param targetId
   * @param forParameter
   * @returns
   */
  private traverseTree(
    relatedParams: Parameter[] | ParamaterToUpdate[],
    targetId: number,
    forParameter?: boolean
  ): boolean {
    let stack = [...relatedParams];
    let visitedParamsIds: number[] = [];

    while (stack.length > 0) {
      const current = stack.pop();
      const id = forParameter ? current.entryType : current.id;

      if (id === targetId) {
        return false;
      }
      if (current.entryType) {
        const paramCalcParams = this.getCalculatorParams(current.entryType);
        if (
          paramCalcParams.some((param) => {
            const target = forParameter ? param.entryType : param.id;
            return target === targetId;
          })
        ) {
          return false;
        }

        const unvisitedParams = paramCalcParams.filter(
          (param) => !visitedParamsIds.includes(param.id)
        );
        stack = [...stack, ...unvisitedParams];
      }

      visitedParamsIds.push(current.id);
    }
    return true;
  }

  /**
   * Get related parameters of a parameter
   * If parameter hasn't orphan child, It hasn't include at form.
   * @param parameterId
   * @param parameters
   * @param calculators
   * @returns true if param hasn't orphan child
   */
  isParameterValid(parameterId: number): boolean {
    // Starting operation without errors
    this.hasParamErrors = false;

    let visiteds = [];

    let stack = [
      (this.parameters as Parameter[]).find((p) => p.id === parameterId),
    ];

    while (stack.length > 0) {
      const curr = stack.pop();
      if (!curr.entryType) {
        visiteds.push(curr.id);
        continue;
      }
      const moreParams = this.getCalculatorParams(curr.entryType);
      if (this.hasParamErrors) {
        console.warn("This parameterId had errors: ", parameterId);
        this.hasParamErrors = false;
        return false;
      }
      const unvisitedParams = moreParams.filter(
        (p) => !visiteds.includes(p.id)
      );
      stack = [...stack, ...unvisitedParams];

      visiteds.push(curr.id);
    }

    return true;
  }

  /** Returns if the parameters required by the calculator are available */
  isValidCalculator(calculator: MaterialCalculator): boolean {
    const paramsAsString = Helper.stringToLettersArray(calculator.formula, "%");
    const paramsAsNumber = paramsAsString.map((s) => +s);
    const paramIds = (this.parameters as unknown as Parameter[]).map(
      (param) => param.id
    );
    return paramsAsNumber.every((id) => paramIds.includes(id));
  }
}
