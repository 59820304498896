<div class="container-fluid m-0 p-0 pb-2">
  <div #messages (scroll)="onScroll($event)" class="row chat-messages">
    <p class="chat-loading" *ngIf="isLoading || items.length < 1">
      {{ isLoading ? "Select a group..." : "Start the conversation..." }}
    </p>
    <ul>
      <li
        *ngFor="let item of items"
        class="{{
          user.guid === item.user.guid ? 'chat-sent' : 'chat-received'
        }}"
        [class.chat-system]="item.system"
      >
        <p class="chat-message">{{ item.content }}</p>
        <p *ngIf="!item.system" class="chat-meta">
          <span class="chat-message-name">{{ item.user.fullName }}</span>
          <span class="chat-created">{{
            item.created | date: "EEEE, MM-d-yyyy, h:mm a"
          }}</span>
        </p>
        <div *ngIf="user?.isAdmin && !item.system" class="message-options">
          <p (click)="onDeleteMessage(item)" class="text-danger">delete</p>
        </div>
      </li>
    </ul>
  </div>
  <div class="row chat-message-input">
    <input
      #message
      [attr.readonly]="(readonly | async) ? '' : null"
      (keyup.enter)="onMessage(message)"
      type="text"
      placeholder="Write your message..."
    />
    <button (click)="onMessage(message)" class="chat-message-button">
      <i class="fa fa-paper-plane" aria-hidden="true"></i>
    </button>
  </div>
</div>
