import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { LocalStorageService } from "../services/localStorage.service";
import { SharedService } from "../utility/SharedService";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private sharedService: SharedService,
    private localStorageService: LocalStorageService
  ) {}

  canActivate() {
    if (localStorage.getItem("token")) {
      return true;
    }
    this.router.navigate(["/"]);
    return false;
  }
  getToken(withScheme: boolean = true, allowAnonymous: boolean = false) {
    if (localStorage.getItem("token")) {
      let token = localStorage.getItem("token");
      if (!withScheme) return token;
      return "Bearer " + token;
    } else if (!allowAnonymous) {
      this.router.navigate(["/"]);
    }
    return null;
  }
  logOutUser() {
    this.localStorageService.clear();
    this.sharedService.dispose();
    this.router.navigate(["login"]);
  }
}
