import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SharedService } from "app/common/utility/SharedService";
import { ValidateProductQtys } from "app/common/validators/validate-qty-not-zero";
import { EstimateProductModel } from "app/model/EstimateProductModel";
import { ProductTypeEnum } from "app/model/KitDetails";
import { ProductSelectorMode } from "app/model/ProductSelectorModalModes";
import { ProductType } from "app/model/ProductType";
import {
  IWorksheetDetailFromDB,
  WorksheetDetailForModal,
  WorksheetDetailFromDB,
} from "app/model/WorksheetDetailObject";
import * as _ from "lodash";
import { ToastrService } from "ngx-toastr";
import { distinctUntilChanged, takeWhile } from "rxjs/operators";
import { WorksheetGridTabDataService } from "../../common/services/worksheet-grid-tab-data.service";
import { WorksheetTabsTypes } from "../../model/EstimateWorksheetProduct";
import { ProductQuantity } from "app/model/KitModel";
import { ValidateHasGreaterThanZero } from "app/common/validators/validate-complexkit-qty";
import {
  ProductSelectionValidatorService,
  SelectionValidationErrors,
} from "../../common/services/product-selection-validator.service";

@Component({
  selector: "product-selector-modal",
  templateUrl: "./product-selector-modal.component.html",
  styleUrls: [
    "../catalog-picker/catalog-picker.scss",
    "./product-selector-modal.component.scss",
  ],
})
export class ProductSelectorModal implements OnInit, OnDestroy {
  productObject: WorksheetDetailForModal[];
  rootObject: WorksheetDetailForModal;
  productForm: FormGroup;
  readonly groupBy: string = "kitDetail.kitGroupDetail.kitGroup.id";
  selectorLabel: string;
  private _attachableToWorksheet: boolean = true;
  get attachableToWorksheet(): boolean {
    return this._attachableToWorksheet;
  }
  set attachableToWorksheet(newVal: boolean) {
    this._attachableToWorksheet = newVal;
    this._productSelectionValidatorService.processErrorMessages(
      this.validationErrors
    );
  }
  showHeader: boolean;
  rowGroupMetadata: any;
  totalPriceProduct: number;
  private _isKitEmpty: boolean;
  get isKitEmpty(): boolean {
    return this._isKitEmpty;
  }
  set isKitEmpty(newVal: boolean) {
    this._isKitEmpty = newVal;
    this._productSelectionValidatorService.processErrorMessages(
      this.validationErrors
    );
  }
  alive: boolean = true;
  canEdit: boolean = false;
  private currentSelectedWorksheetTab: WorksheetTabsTypes;
  productsQuantity: ProductQuantity[] = [];
  productsToValidate: FormArray;
  errorMsg: string = "";
  showDuplicateWarning: boolean = false;
  // Inputs
  @Input() mode: ProductSelectorMode;
  @Input() mainWorksheetDetailsObject: WorksheetDetailForModal;
  @Input() estimateWorksheetId: number;
  @Input() currentWorksheetDetails: IWorksheetDetailFromDB[];

  // Outputs
  @Output()
  handleDisplay: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  onCompleteOperation: EventEmitter<WorksheetDetailFromDB> =
    new EventEmitter<WorksheetDetailFromDB>();
  private _isDuplicate: boolean;
  get isDuplicate(): boolean {
    return this._isDuplicate;
  }
  set isDuplicate(newVal: boolean) {
    this._isDuplicate = newVal;
    this._productSelectionValidatorService.processErrorMessages(
      this.validationErrors
    );
  }
  // Getters:
  get ProductSelectorMode() {
    return ProductSelectorMode;
  }

  get ProductType() {
    return ProductType;
  }

  get FilterQuantities(): boolean {
    return (
      this.mode === ProductSelectorMode.Review &&
      this.rootObject.product.productTypeId === ProductTypeEnum.ComplexKit
    );
  }

  private get isOptional(): boolean {
    return (
      this.mode === ProductSelectorMode.Add &&
      this.currentSelectedWorksheetTab === WorksheetTabsTypes.Optionals
    );
  }

  private get validationErrors(): SelectionValidationErrors {
    return {
      isDuplicate: this.isDuplicate,
      isKitEmpty: this.isKitEmpty,
      hasWorkAreaError: this.rootObject.hasErrorsByWorkArea(),
      hasRootObjError: this.rootObject.hasErrors(),
      form: this.productForm,
    };
  }

  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private sharedService: SharedService,
    private worksheetTabDataService: WorksheetGridTabDataService,
    private _productSelectionValidatorService: ProductSelectionValidatorService
  ) {}

  ngOnInit(): void {
    this.setDisplayOrder();
    this.rootObject = this.mainWorksheetDetailsObject;
    this.checkEmptyAndRemove();
    this.totalPriceProduct = this.rootObject.priceWithoutTax;
    if (this.rootObject.hasChildren()) {
      this.productObject = this.rootObject.worksheetDetailChildren;
    } else {
      this.productObject = [this.rootObject];
    }

    this.setLabel();
    this.rootObject.setShowPricing(true);
    this.showHeader =
      this.productObject.filter(
        (x) =>
          x.kitDetail &&
          x.kitDetail.kitGroupDetail &&
          x.kitDetail.kitGroupDetail.kitGroup &&
          x.kitDetail.kitGroupDetail.kitGroup.id
      ).length !== this.productObject.length;

    if (!this.showHeader) {
      this.productObject = this.productObject.sort((a, b) => {
        return (
          a.kitDetail.kitGroupDetail.kitGroup.id -
          b.kitDetail.kitGroupDetail.kitGroup.id
        );
      });
    }

    this.showErrorMsg();
    this.buildForm();
    this.rootObject.setShowItem();
    this.checkDuplicates();
    this.subscribeToTabs();
    this._productSelectionValidatorService.errorMessage
      .pipe(
        takeWhile(() => this.alive),
        distinctUntilChanged()
      )
      .subscribe((val) => (this.errorMsg = val));
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  buildForm(): void {
    this.rootObject.setProductOptionMap();
    let vendorCost = 0;
    if (
      this.mode === ProductSelectorMode.Edit ||
      this.mode === ProductSelectorMode.Review
    )
      vendorCost = this.rootObject.vendorCost;
    else vendorCost = this.canEdit ? this.rootObject.preferredVendorPrice : 0;

    this.productForm = this.formBuilder.group({
      productQty: [
        this.rootObject.qty,
        [Validators.required, Validators.min(1)],
      ],
      productOptions: [],
      childrenProducts: this.buildChildrenForms(
        this.productObject,
        this.rootObject
      ),
      productName: [
        this.getProductName(this.rootObject),
        [Validators.required, Validators.maxLength(400)],
      ],
      price: [this.rootObject.priceToShow, [Validators.required]],
      vendorCost: [vendorCost, [Validators.required]],
      userDescription: [this.rootObject.userDescription],
    });

    // To aovid overiding the form reference when there is only one item, labor or service
    if (this.rootObject.hasChildren()) {
      this.rootObject.form = this.productForm;
    }

    if (this.mode === ProductSelectorMode.Review) {
      this.productForm.disable();
    }
    this.productForm
      .get("productQty")
      .valueChanges.pipe(takeWhile(() => this.alive))
      .subscribe(() => {
        this.calculateTotalPrice();
      });

    this.productForm.statusChanges
      .pipe(
        takeWhile(() => this.alive),
        distinctUntilChanged()
      )
      .subscribe((status) =>
        this._productSelectionValidatorService.processErrorMessages(
          this.validationErrors
        )
      );
    this.setProductsToValidate();
  }

  private calculateTotalPrice(): void {
    // We use interval to run the callback function after the reactive form recieves the new entered value
    setTimeout(() => {
      const productToSend = this.getObjectMergedWithForm();
      const newTotalPrice = productToSend.getTotalPrice(); // productToSend.qty * currentQty;
      this.totalPriceProduct = newTotalPrice;
    }, 0);
  }

  /** To calculate total price when a editable cost changes */
  private calculateTotalPriceWithChildCostChange(): void {
    setTimeout(() => {
      const productToSend = this.getObjectMergedWithForm();
      const sender = WorksheetDetailForModal.createObj(productToSend);
      const newTotalPrice = sender.getTotalPrice(); // productToSend.qty * currentQty;
      this.totalPriceProduct = newTotalPrice;
    }, 0);
  }
  /**
   * Building the form of the children recursively
   * @param children
   * @returns
   */
  private buildChildrenForms(
    children: WorksheetDetailForModal[],
    parent: WorksheetDetailForModal
  ): FormArray {
    const tempArray = this.formBuilder.array([]);
    children.forEach((element) => {
      if (parent.product.productTypeId !== ProductType.SimpleKit) {
        this.productsQuantity.push({ id: element.productId, qty: element.qty });
      }
      let vendorCost = 0;
      if (
        this.mode === ProductSelectorMode.Edit ||
        this.mode === ProductSelectorMode.Review
      )
        vendorCost = element.vendorCost;
      else vendorCost = this.canEdit ? element.preferredVendorPrice : 0;

      const tempGroup = this.formBuilder.group({
        productId: [element.productId],
        productQty: [
          element.qty,
          [
            Validators.required,
            parent.product.productTypeId === ProductType.SimpleKit
              ? ValidateProductQtys.checkIfQtyIsZero(null).bind(this)
              : ValidateHasGreaterThanZero.checkIfHasGreaterThanZero(
                  this.productsQuantity,
                  this.productsToValidate,
                  true
                ).bind(this),
          ],
        ],
        productOptions: [element.getProducOptionSelected()],
        childrenProducts: element.hasChildren()
          ? this.buildChildrenForms(element.worksheetDetailChildren, element)
          : [],
        productName: [
          this.getProductName(element),
          [Validators.required, Validators.maxLength(400)],
        ],
        price: [element.priceToShow, [Validators.required]],
        vendorCost: [vendorCost, [Validators.required]],
        userDescription: [element.userDescription],
      });

      if (this.canEdit) {
        if (!element.canEditProductName) tempGroup.get("productName").disable();
        if (!element.canEditCost) {
          tempGroup.get("price").disable();
          tempGroup.get("vendorCost").disable();
        }
      }

      // Saving a reference of the form on the object
      element.form = tempGroup;
      tempGroup
        .get("productQty")
        .valueChanges.pipe(takeWhile(() => this.alive))
        .subscribe((value) => {
          // We use interval to run the callback function after the reactive form recieves the new entered value
          const productId = tempGroup.get("productId").value;
          this.updateProductQtyCheck(productId, value);
          setTimeout(() => {
            this.rootObject.updatePriceToShow(true);
            this.calculateTotalPrice();
          }, 0);
        });

      tempGroup.statusChanges
        .pipe(
          takeWhile(() => this.alive),
          distinctUntilChanged()
        )
        .subscribe((data) =>
          this._productSelectionValidatorService.processErrorMessages(
            this.validationErrors
          )
        );

      tempGroup
        .get("productOptions")
        .valueChanges.pipe(takeWhile(() => this.alive))
        .subscribe(() => {
          // We use interval to run the callback function after the reactive form recieves the new entered value
          setTimeout(() => {
            this.rootObject.updatePriceToShow(true);
            this.calculateTotalPrice();
          }, 0);
        });

      tempGroup
        .get("price")
        .valueChanges.pipe(takeWhile(() => this.alive))
        .subscribe(() => {
          setTimeout(() => {
            this.rootObject.updatePriceToShow(true);
            this.calculateTotalPriceWithChildCostChange();
          }, 0);
        });
      tempArray.push(tempGroup);
    });

    return tempArray;
  }

  /**
   * Sets label text depending on the mode
   */
  setLabel(): void {
    if (this.canEdit) {
      this.selectorLabel = "Add Product";
      return;
    }

    switch (this.mode) {
      case ProductSelectorMode.Add:
        this.selectorLabel = "Add";
        break;
      case ProductSelectorMode.Edit:
        this.selectorLabel = "Edit";
        break;
      case ProductSelectorMode.Review:
        this.selectorLabel = "Review";
        break;
    }

    const LABEL_BY_PRODUCT_TYPE = [
      "Item",
      "Simple Kit",
      "Complex Kit",
      "Service",
      "Labor",
    ];

    this.selectorLabel +=
      " " + LABEL_BY_PRODUCT_TYPE[this.rootObject.product.productTypeId - 1];
  }

  showSidebar(): void {
    this.handleDisplay.emit(false);
  }

  /**
   * Functions that responds to the onSort event from the p-table
   * Decides if the data on the table can be grouped
   * Sends the groupBy string to the group method
   */
  onSort(): void {
    const canBeGrouped =
      this.productObject.filter(
        (x, index, array) =>
          x.kitDetail &&
          x.kitDetail.kitGroupDetail &&
          x.kitDetail.kitGroupDetail.kitGroup &&
          x.kitDetail.kitGroupDetail.kitGroup.id
      ).length === this.productObject.length;

    if (canBeGrouped) {
      this.showHeader = false;
      this.updateRowGroupMetaData(this.groupBy);
    } else {
      this.showHeader = true;
    }
  }

  /**
   * Method to sort and group the data on the p-table
   * Must not be altered. onSort method can send a diferent groupBy string if needed
   * @param sortByString
   */
  private updateRowGroupMetaData(sortByString: string): void {
    const getValueByStrinKeys = (stringKeys: string, mainObject: Object) => {
      const keys = stringKeys.split(".");
      let value: any = mainObject;
      keys.forEach((x) => {
        value = value[x];
      });
      return value;
    };

    this.rowGroupMetadata = {};

    if (this.productObject) {
      for (let i = 0; i < this.productObject.length; i++) {
        let rowData = this.productObject[i];

        let sortByValue: any = getValueByStrinKeys(sortByString, rowData);

        if (i == 0) {
          this.rowGroupMetadata[sortByValue] = { index: 0, size: 1 };
        } else {
          let previousRowData = this.productObject[i - 1];
          let previousRowGroup = getValueByStrinKeys(
            sortByString,
            previousRowData
          );

          if (sortByValue === previousRowGroup) {
            this.rowGroupMetadata[sortByValue].size++;
          } else {
            this.rowGroupMetadata[sortByValue] = { index: i, size: 1 };
          }
        }
      }
    }
  }

  /**
   * On product options changes, changes the new prices to show
   * @param event
   * @param product
   */
  handleProductOptionChange(
    event: { originalEvent: PointerEvent; value: EstimateProductModel },
    product: WorksheetDetailForModal
  ): void {
    const productSelected = event.value;
    const productOptionCalculator =
      productSelected &&
      productSelected.productEstimateExt &&
      productSelected.productEstimateExt.calculator;
    const productCalculator =
      product.product.productEstimateExt &&
      product.product.productEstimateExt.calculator;
    const kitDetailCalculator =
      product.kitDetailId && product.kitDetail.calculator;

    // Checking errors for product Options
    if (
      productSelected &&
      productOptionCalculator &&
      productOptionCalculator.value === null
    ) {
      this.toastrService.error(
        "The calculator of this product option had problems. Please choose another"
      );
      this.attachableToWorksheet = false;
      this._productSelectionValidatorService.processErrorMessages({
        ...this.validationErrors,
        hasCalculatorProductOptionError: true,
      });
      return;
    }
    // Checking errors for products with calculators
    else if (
      productSelected === null &&
      productCalculator &&
      productCalculator.value === null
    ) {
      this.toastrService.error(
        "The calculator of the product without product options had problems"
      );
      this.attachableToWorksheet = false;
      this._productSelectionValidatorService.processErrorMessages({
        ...this.validationErrors,
        hasCalculatorProductError: true,
      });
      return;
    }
    // Checking errors for product with kit Detail calculators
    else if (
      productSelected === null &&
      kitDetailCalculator &&
      kitDetailCalculator.value === null
    ) {
      this.toastrService.error("The calculator of the kitDetail had problems");
      this.attachableToWorksheet = false;
      this._productSelectionValidatorService.processErrorMessages({
        ...this.validationErrors,
        hasKitItemError: true,
      });
      return;
    }

    // Chaging the price to show
    if (productSelected) {
      // This is to be able to check errors in case a productOption was selected.
      // It is not sent to the DB
      product.productOption = productSelected;

      product.currentPricePerUnit = productSelected.priceBookDetailId
        ? productSelected.priceBookPrice
        : productSelected.price;

      if (productOptionCalculator) {
        product.qty = productOptionCalculator.value;
      } else if (
        product.kitDetail &&
        !product.kitDetail.materialsCalculatorId
      ) {
        // To avoid null values. In that case, we would put 1 instead
        product.qty =
          product.kitDetail.qty || product.kitDetail.qty === 0
            ? product.kitDetail.qty
            : 1;
      } else {
        product.qty = 1;
      }
    } else {
      product.productOption = null;

      product.currentPricePerUnit = product.product.priceBookDetailId
        ? product.product.priceBookPrice
        : product.product.price;

      if (productCalculator) {
        product.qty = productCalculator.value;
      } else if (
        product.kitDetail &&
        !product.kitDetail.materialsCalculatorId
      ) {
        // To avoid null values. In that case, we would put 1 instead
        product.qty =
          product.kitDetail.qty || product.kitDetail.qty === 0
            ? product.kitDetail.qty
            : 1;
      } else if (product.kitDetail && product.kitDetail.materialsCalculatorId) {
        product.qty = product.kitDetail.calculator.value || 1;
      } else {
        product.qty = 1;
      }
    }

    product.form.get("productQty").setValue(product.qty);

    this.attachableToWorksheet = !this.rootObject.hasErrors();
    product.updateQtyInput.next(true);

    this.calculateTotalPrice();
  }

  private checkDuplicates(): boolean {
    if (this.mode !== ProductSelectorMode.Add) {
      return false;
    }

    let isDuplicate;

    if (!this.rootObject.hasChildren()) {
      const producOption =
        this.productForm.value.childrenProducts[0].productOptions;
      const productId = this.rootObject.productId;
      const productOptionId = producOption && producOption.id;

      isDuplicate = this.currentWorksheetDetails.some(
        (x) =>
          x.deletedOn === null &&
          x.productId === productId &&
          x.productOptionId === productOptionId
      );
    } else {
      isDuplicate = this.currentWorksheetDetails.some(
        (x) => x.deletedOn === null && x.productId === this.rootObject.productId
      );
    }

    if (isDuplicate) {
      this.showDuplicateWarning = true;
      return false;
    }

    return isDuplicate;
  }

  /**
   * Emits and event where it sends the workSheetDetail edited by the user
   */
  sendProduct(): void {
    if (this.checkDuplicates()) {
      return;
    }
    const productToSend = this.getObjectMergedWithForm();
    productToSend.cleanInnerObject();
    this.onCompleteOperation.emit(productToSend);
  }

  private getObjectMergedWithForm(): WorksheetDetailForModal {
    let formOfproductToSend;
    if (this.rootObject.hasChildren()) {
      formOfproductToSend = this.productForm.value;
    } else {
      formOfproductToSend = this.productForm.getRawValue();
      let userDescription: string = "";
      if (
        this.rootObject.materialsCalculatorId ||
        (!this.canEdit && !this.rootObject.isKit)
      )
        userDescription = formOfproductToSend.userDescription;

      formOfproductToSend = formOfproductToSend.childrenProducts[0];

      if (
        this.rootObject.materialsCalculatorId ||
        (!this.canEdit && !this.rootObject.isKit)
      )
        formOfproductToSend.userDescription = userDescription;
    }
    const cloneRootObject = _.cloneDeep(this.rootObject);
    // Cleaning form reference to avoid bugs
    cloneRootObject.form = null;
    const productToSend = this.createProductToSendFromFormData(
      formOfproductToSend,
      cloneRootObject
    );

    return productToSend;
  }
  /**
   * Merges the formData with the initialWorksheetDetail object.
   * Gives the final worksheetDetail object to be updated or added on backend
   * @param formData
   * @param initialWorksheetDetail
   * @returns Final WorksheetDetail Object with its children
   */
  private createProductToSendFromFormData(
    formData,
    initialWorksheetDetail: WorksheetDetailForModal
  ): WorksheetDetailForModal {
    const newWorksheeDetail = _.cloneDeep(initialWorksheetDetail);
    // Cleaning the temp form reference to avoid bugs
    newWorksheeDetail.form = null;
    newWorksheeDetail.qty = formData.productQty;
    newWorksheeDetail.unitOfMeasure =
      newWorksheeDetail.product.unitOfMeasure &&
      newWorksheeDetail.product.unitOfMeasure.unitOfMeasureName;
    newWorksheeDetail.needsRecalculation = false;
    newWorksheeDetail.productTypeId = newWorksheeDetail.product.productTypeId;
    newWorksheeDetail.estimateBudgetCost =
      newWorksheeDetail.currentPricePerUnit;
    newWorksheeDetail.productName = formData.productName;
    newWorksheeDetail.vendorCost = formData.vendorCost || 0;
    newWorksheeDetail.isOptional = this.isOptional;
    const productOptionSelected: EstimateProductModel = formData.productOptions;

    if (productOptionSelected) {
      newWorksheeDetail.productOption = productOptionSelected;
      newWorksheeDetail.productOptionId = productOptionSelected.id;
      newWorksheeDetail.estimateBudgetCost =
        productOptionSelected.priceBookDetailId
          ? productOptionSelected.priceBookPrice
          : productOptionSelected.price;
      newWorksheeDetail.estimateBudgetPriceBookDetailId =
        productOptionSelected.priceBookDetailId;
      newWorksheeDetail.unitPrice = productOptionSelected.price;
      newWorksheeDetail.unitOfMeasure =
        productOptionSelected.unitOfMeasure &&
        productOptionSelected.unitOfMeasure.unitOfMeasureName;
    } else {
      newWorksheeDetail.productOptionId = null;
      newWorksheeDetail.estimateBudgetPriceBookDetailId =
        newWorksheeDetail.product.priceBookDetailId;
      newWorksheeDetail.estimateBudgetCost = formData.price;
    }

    // Setting the ideal materialCalculator to use, this is not the final calculatorId
    if (
      this.mode === ProductSelectorMode.Edit &&
      newWorksheeDetail.wasCalculatorDeleted()
    ) {
      newWorksheeDetail.materialsCalculatorId = null;
    } else if (this.mode === ProductSelectorMode.Add) {
      const tempmaterialsCalculatorId =
        newWorksheeDetail.getMaterialCalculatorIdToUsed();
      newWorksheeDetail.materialsCalculatorId = tempmaterialsCalculatorId;
    }

    // Setting real material calculator values and ids
    // This will override the inputQty only if there is a valid calculator an qty
    newWorksheeDetail.setQtyToUseAndCalculatorId();

    newWorksheeDetail.requiresUserSelection =
      newWorksheeDetail.needsUserSelection();

    // Registering Qty changes in case of Edit
    if (
      this.mode === ProductSelectorMode.Edit &&
      newWorksheeDetail.qty !== initialWorksheetDetail.qty
    ) {
      newWorksheeDetail.worksheetDetailChange = {
        id:
          (newWorksheeDetail.worksheetDetailChange &&
            newWorksheeDetail.worksheetDetailChange.id) ||
          0,
        newQty: newWorksheeDetail.qty,
        previousQty: initialWorksheetDetail.qty,
        createdBy: this.sharedService.user.email,
        worksheetDetailId: newWorksheeDetail.id,
      };
    } else {
      newWorksheeDetail.worksheetDetailChange = null;
    }

    // Applying this method to all children
    if (newWorksheeDetail.hasChildren()) {
      newWorksheeDetail.worksheetDetailChildren =
        newWorksheeDetail.worksheetDetailChildren.map((oldChildren, index) => {
          const newChildren = this.createProductToSendFromFormData(
            formData.childrenProducts[index],
            oldChildren
          );

          return newChildren;
        });
    }

    newWorksheeDetail.userDescription = formData.userDescription;

    return newWorksheeDetail;
  }

  /**
   * Shows Errors msg only when the mode is ADD
   */
  showErrorMsg(): void {
    if (this.mode !== ProductSelectorMode.Add) {
      return;
    }

    if (this.rootObject.hasErrorsByWorkArea()) {
      this.toastrService.warning(
        `There are missing parameters in the work areas`,
        "Product can't be attached to Worksheet"
      );

      this.attachableToWorksheet = false;
    } else if (this.rootObject.hasErrors()) {
      this.toastrService.error(
        "There was an error in the calculator of one of the products"
      );

      this.attachableToWorksheet = false;
    }
  }

  /**
   * checks if the kits are empty and remove it
   */
  checkEmptyAndRemove(): void {
    this.canEdit =
      this.mainWorksheetDetailsObject.product.productEstimateExt &&
      (this.mainWorksheetDetailsObject.product.productEstimateExt.canEditCost ||
        this.mainWorksheetDetailsObject.product.productEstimateExt
          .canEditProductName);

    if (this.rootObject.productTypeId == ProductType.SimpleKit) {
      this.isKitEmpty = this.rootObject.worksheetDetailChildren.length == 0;
      return;
    }

    if (this.rootObject.productTypeId == ProductType.ComplexKit) {
      if (this.rootObject.worksheetDetailChildren.length == 0) {
        this.isKitEmpty = true;
        return;
      }

      this.rootObject.worksheetDetailChildren.filter((x, i) => {
        if (x.productTypeId == ProductType.SimpleKit) {
          if (x.worksheetDetailChildren.length == 0) {
            this.rootObject.worksheetDetailChildren.splice(i, 1);
            this.isKitEmpty = true;
          }
        }
      });
      this.isKitEmpty = this.rootObject.worksheetDetailChildren.length == 0;
    }
  }

  /** To get the product's name according to the modal's mode */
  private getProductName(wd: WorksheetDetailForModal): string {
    return this.mode === ProductSelectorMode.Add
      ? wd.product.name
      : wd.productName;
  }

  /** Subscribes to observer to listen to the worksheet tab changes */
  private subscribeToTabs() {
    this.worksheetTabDataService.currentTab
      .pipe(takeWhile(() => this.alive))
      .subscribe((tab) => (this.currentSelectedWorksheetTab = tab));
  }

  /**
   * Method to update the product quantity in the array
   */
  private updateProductQtyCheck(id: number, qty: number) {
    const index = this.productsQuantity.findIndex((x) => x.id === id);
    if (index !== -1) {
      this.productsQuantity[index].qty = qty;
    }
  }

  /**
   * Method to set the products to validate
   */
  private setProductsToValidate(): void {
    this.productsToValidate = this.productForm.controls[
      "childrenProducts"
    ] as FormArray;

    const formProduct = this.productsToValidate.controls;
    formProduct.forEach((control: FormGroup) => {
      control.controls.productQty.clearValidators();
      control.controls.productQty.updateValueAndValidity();
      control.controls.productQty.setValidators(
        ValidateHasGreaterThanZero.checkIfHasGreaterThanZero(
          this.productsQuantity,
          this.productsToValidate,
          true
        ).bind(this)
      );
      control.controls.productQty.updateValueAndValidity();
    });
  }

  setDisplayOrder(): void {
    this.mainWorksheetDetailsObject.worksheetDetailChildren.sort((a, b) => {
      return a.kitDetail.displayOrder - b.kitDetail.displayOrder;
    });
  }
}
