import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";
import { OrganizationModel } from "app/model/organizationModel";

@Component({
  selector: "organizations-integration",
  templateUrl: "./organizations-integration.component.html",
  styleUrls: ["./organizations-integration.component.scss"],
})
export class OrganizationsIntegrationComponent implements OnInit {
  @Input() organization: OrganizationModel;
  @Output() organizationChange: EventEmitter<OrganizationModel> =
    new EventEmitter<OrganizationModel>();
  constructor() {}

  ngOnInit() {}
  organizationUpdated(newValue) {
    this.organization = newValue;
    this.organizationChange.emit(this.organization);
  }
}
