<div class="calculation-update-modal-container">
  <div class="title">This Worksheet has been updated as follows:</div>
  <div class="calculation-update-modal-table">
    <p-table
      id="changesTable"
      [value]="relevantChanges"
      [paginator]="false"
      styleClass="calculation-update-table"
    >
      <ng-template pTemplate="header">
        <tr>
          <th>Source Change</th>
          <th>Updated Issue</th>
          <th>Description</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-relevantChanges>
        <tr>
          <td>{{ SOURCE_OF_CHANGE[relevantChanges.component - 1] }}</td>
          <td>{{ relevantChanges.componentName }}</td>
          <td>{{ relevantChanges.changeDescription }}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div
    class="calculation-update-modal-note"
    *ngIf="showCalculatorChangedMessage"
  >
    Calculator is updated, Please review the proposal total
  </div>

  <div class="mt-2">
    Please confirm these updates in order to open the worksheet.
  </div>

  <div class="layout-flex layout-flex-end mt-4">
    <button
      type="button"
      class="button button--secondary button--md margin-right__md"
      (click)="closeUpdateModal()"
    >
      Cancel
    </button>
    <button
      type="button"
      class="button button--primary button--md"
      (click)="handleConfirmUpdates()"
    >
      Confirm
    </button>
  </div>
</div>
