import { Component, OnInit } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SettingService } from "app/common/services/settings.service";
import { SharedService } from "app/common/utility/SharedService";
import {
  AddRemoveOrganizationOrganizationSetting,
  DataType,
  OrganizationOrganizationSetting,
  OrganizationSettingValue,
  Settings,
} from "app/model/Settings";
import { BaThemeSpinner } from "app/theme/services";
import { formatDate } from "@angular/common";
import { MessageService } from "primeng/components/common/messageservice";

@Component({
  selector: "organization-settings",
  templateUrl: "organization-settings.component.html",
  styleUrls: ["./organization-settings.component.scss"],
})
export class OrganizationSettingsComponent implements OnInit {
  public formArray: FormArray;
  organizationGuid: string;
  organizationSettings: Settings[] = [];
  dataTypeEnum = DataType;
  editedRowIndex: number;
  editableValue: Settings;
  organizationOrganizationSetting: OrganizationOrganizationSetting;
  organizationOrganizationsettingsList: AddRemoveOrganizationOrganizationSetting;
  isSuperAdmin: boolean = false;

  constructor(
    private organizationSettingsService: SettingService,
    private sharedService: SharedService,
    private _spinner: BaThemeSpinner,
    private notificationService: MessageService
  ) {}

  ngOnInit() {
    this.organizationGuid = this.sharedService.selectedOrganization.ID;
    this.isSuperAdmin = this.sharedService.user.isSuperAdmin;
    this.getOrganizationSettingsByOrganization();
  }

  getOrganizationSettingsByOrganization(): void {
    this._spinner.show();
    this.organizationSettingsService
      .getOrganizationsSettings(this.organizationGuid)
      .subscribe((data) => {
        this.organizationSettings = data as Settings[];
        this.organizationSettings = this.organizationSettings.filter(
          (x) => x.Visible
        );
        this.setLabelForDropdownValues();
        this.setValuesTypes();
        this._spinner.hide();
      });
  }

  editHandler({ sender, rowIndex, dataItem }): void {
    this.editableValue = Object.assign({}, dataItem);
    if (dataItem.SettingValues.length > 0) {
      const settingValue = dataItem.SettingValues.find(
        (x) => x.Description == dataItem.Value
      );
      dataItem.NumericValue = settingValue.Value;
    }
    this.closeEditor(sender);
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex);
  }

  cancelHandler({ sender, rowIndex }): void {
    if (rowIndex >= 0) {
      const val = this.editableValue;
      const method = this.organizationSettings.find((x) => x.Id === val.Id);
      method.Value = val.Value;
      method.ReadOnly = val.ReadOnly;
    }
    this.closeEditor(sender, rowIndex);
  }

  saveHandler({ sender, rowIndex, dataItem }): void {
    let item = JSON.parse(JSON.stringify(dataItem));
    let setting = item as Settings;

    this.formatSettingValue(setting);

    this.organizationOrganizationSetting = {
      Id: setting.OrganizationOrganizationSettingId,
      OrganizationId: setting.OrganizationId,
      SettingId: setting.Id,
      Value: setting.Value,
      CreatedOn: new Date(),
    };

    this.organizationOrganizationsettingsList = {
      AddSettings: [],
      RemoveSettings: [],
      UpdateSettings: [],
    };

    this.organizationOrganizationsettingsList.UpdateSettings.push(
      this.organizationOrganizationSetting
    );

    if (setting.OrganizationOrganizationSettingId == 0) {
      this.organizationOrganizationSetting.OrganizationId =
        this.organizationGuid;
      this.organizationOrganizationsettingsList.AddSettings.push(
        this.organizationOrganizationSetting
      );
      this.organizationOrganizationsettingsList.UpdateSettings.pop();
    }

    this._spinner.show();
    this.organizationSettingsService
      .postOrganizationOrganizationSettings(
        this.organizationOrganizationsettingsList
      )
      .subscribe(
        (result) => {
          this.result(result, "Updated Successfully!!");
          this.getOrganizationSettingsByOrganization();
          sender.closeRow(rowIndex);
          this.editedRowIndex = undefined;
          this._spinner.hide();
        },
        (error) => this.error(error)
      );
  }

  private formatSettingValue(setting: Settings): void {
    if (setting.DataTypeId === DataType.Date) {
      setting.Value = formatDate(
        setting.DateValue,
        "yyyy-MM-ddTHH:mm:ss",
        "en-US"
      );
      return;
    }

    if (setting.DataTypeId === DataType.Boolean) {
      setting.Value = setting.BooleanValue ? "1" : "0";
      return;
    }

    if (setting.DataTypeId === DataType.Numeric) {
      setting.Value = setting.NumericValue.toString();
    }
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex): void {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
  }

  private error(error: any): void {
    this.notificationService.add({
      severity: "warn",
      summary: "Organization Settings",
      detail: "Update Error!!",
    });
    console.log(error);
  }

  private result(res: any, message: string): void {
    this.notificationService.add({
      severity: "success",
      summary: "Organizartion Settings",
      detail: message,
    });
  }

  setValuesTypes(): void {
    this.organizationSettings.forEach((data) => {
      if (data.DataTypeId == DataType.Date && data.Value != null) {
        data.Value = formatDate(data.Value, "yyyy-MM-ddTHH:mm:ss", "en-US");
        data.DateValue = new Date(data.Value);
        data.Value = formatDate(data.DateValue, "yyyy-MM-dd", "en-US");
        return;
      }

      if (data.DataTypeId == DataType.Boolean) {
        data.BooleanValue = data.Value == "0" ? false : true;
        return;
      }

      if (data.DataTypeId == DataType.Numeric) data.NumericValue = +data.Value;
    });
  }

  setSettingValuesDescription(setting: Settings): void {
    if (setting.SettingValues != null) {
      setting.SettingValues.forEach((data) => {
        data.Description = `${data.Value} - ${data.Description}`;
      });
    }
  }

  onSelectionChange(event: OrganizationSettingValue, dataItem: Settings): void {
    dataItem.Value = event.Value.toString();
  }

  validateNumber(event: any): void {
    const pattern = /^[0-9]*$/;
    let inputChar = event.key;

    if (
      !pattern.test(inputChar) &&
      inputChar !== "Backspace" &&
      inputChar !== "Delete" &&
      inputChar !== "ArrowLeft" &&
      inputChar !== "ArrowRight"
    ) {
      event.preventDefault();
    }
  }

  setLabelForDropdownValues() {
    this.organizationSettings.forEach((item) => {
      if (item.SettingValues.length > 0  && item.Value != null) {
        const settingValue = item.SettingValues.find(
          (x) => x.Value.toString() == item.Value
        );
        item.Value = settingValue.Description;
      }
    });
  }
}
