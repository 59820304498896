import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";
import { environmentConstant } from "app/common/utility/environment";
import { Observable, from } from "rxjs";
import { map, mergeMap, toArray, tap } from "rxjs/operators";
import { WorkOrderModel } from "app/model/workOrderModel ";
import { JobPhasModel } from "app/model/jobPhaseModel";
import { JobNoteModel } from "app/model/jobNoteModel";
import { WorkOrderModelV2 } from "app/model/WorkOrderV2Model";
import { StatusModel } from "app/model/statusModel";
import { CategoryModel } from "app/model/categoryModel";
import { WorkOrderLineItemModel } from "app/model/WorkOrderLineItemModel";
import { SharedService } from "app/common/utility/SharedService";
import { JobResourcesRequestPayload } from "app/model/JobAssignResourceModel";

// tslint:disable:prefer-template
@Injectable()
export class WorkOrderService {
  constructor(
    private httpService: HttpService,
    private sharedService: SharedService
  ) {}

  genericToWorkOrderV2(genericObj: any): WorkOrderModelV2 {
    let target = new WorkOrderModelV2();

    for (let prop in genericObj) {
      target[prop] = genericObj[prop];
    }

    return target;
  }

  responseToWorkOrderV2Array(response): Observable<WorkOrderModelV2[]> {
    return from(response).pipe(
      map((x) => this.genericToWorkOrderV2(x)),
      toArray()
    );
  }

  getWorkOrderByJobID(jobId: any): Observable<WorkOrderModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getWorkOrderByJobID + jobId + "/workorder"
    );
  }
  getWorkOrderListByJobId(jobId: any): Observable<any> {
    return this.httpService.getRequestService(
      "workorder/organization/" +
        this.sharedService.selectedOrganization.ID +
        "?JobId=" +
        jobId
    );
  }
  getWorkOrderListByCustomerId(
    customerId: any,
    limit?: number,
    startDate?: Date,
    endDate?: Date
  ): Observable<WorkOrderModelV2[]> {
    var queryString = "?limit=" + limit || 25;
    if (startDate) {
      queryString += "&startDate=" + this.formatDate(startDate);
    }
    if (endDate) {
      queryString += "&endDate=" + this.formatDate(endDate);
    }

    return this.httpService
      .getRequestService(
        "workorder/organization/" +
          this.sharedService.selectedOrganization.ID +
          "/customer/" +
          customerId +
          queryString
      )
      .pipe(mergeMap((x) => this.responseToWorkOrderV2Array(x)));
  }
  getWorkOrderByOrderId(orderId: any): Observable<WorkOrderModel> {
    return this.httpService.getRequestService(
      environmentConstant.getWorkOrderByJobID + "workorder/" + orderId
    );
  }
  updateWorkOrder(workOrder: WorkOrderModel) {
    return this.httpService.putRequestService(
      environmentConstant.getWorkOrderByJobID + "workorder/",
      workOrder
    );
  }
  saveWorkOrder(workOrder: WorkOrderModel) {
    return this.httpService.postRequestService(
      environmentConstant.getWorkOrderByJobID + "workorder/",
      workOrder
    );
  }
  createWorkOrder(workOrder: WorkOrderModel, id: number) {
    return this.httpService.postRequestService(
      environmentConstant.createWorkOrder + id,
      workOrder
    );
  }
  LinkWorkOrderWithPunchlistItem(WorkorderID: string, punchListItemId: number) {
    return this.httpService.putRequestService(
      environmentConstant.LinkWorkOrderWithPunchlistItem +
        WorkorderID +
        "/link/" +
        punchListItemId,
      null
    );
  }
  completeWorkOrder(id: any) {
    return this.httpService.putRequestService(
      environmentConstant.workOrder + id + "/complete/",
      null
    );
  }
  startWorkOrder(id: any) {
    return this.httpService.putRequestService(
      environmentConstant.workOrder + id + "/start/",
      null
    );
  }
  deleteWorkOrder(id: any) {
    return this.httpService.deleteRequestService("workorder/" + id);
  }
  getWorkOrderByOrgId(orgID: any): Observable<WorkOrderModel[]> {
    return this.httpService.getRequestService(
      "organization/" + orgID + "/workorder"
    );
  }
  saveWorkOrderItem(workOrderId: any, item: JobPhasModel) {
    return this.httpService.postRequestService(
      "workorder/" + workOrderId + "/item",
      item
    );
  }
  updateWorkOrderItem(item: JobPhasModel) {
    return this.httpService
      .putRequestService("workorder/item", item)
      .pipe(map((res) => res));
  }
  saveFiles(workOrderId: any, filetype: any, jobPhaseId: any, formData: any) {
    return this.httpService
      .UploadRequestService(
        "workorder/" +
          workOrderId +
          "/filetype/" +
          filetype +
          "/item/" +
          jobPhaseId,
        formData
      )
      .pipe(map((res) => res));
  }
  // tslint:disable-next-line:whitespace
  saveItemNote(workOrderId: any, notes: JobNoteModel) {
    return this.httpService.postRequestService(
      "workorder/" + workOrderId + "/workorderitemnote",
      notes
    );
  }
  getItemNote(workOrderId: any, phaseId: any): Observable<JobNoteModel[]> {
    return this.httpService.getRequestService(
      "jobs/" + workOrderId + "/notes/" + phaseId
    );
  }
  setWorkOrderDummy(workOrderV2: WorkOrderModelV2) {
    return this.httpService.postRequestService("workorder/dummy", workOrderV2);
  }
  getWorkorderbyId(workorderId: number): Observable<WorkOrderModelV2> {
    return this.httpService.getRequestService("workorder/" + workorderId);
  }
  getCompanyPersonalList(orgGuid: any) {
    return this.httpService.getRequestService(
      "customer/" + orgGuid + "/companypersonals"
    );
  }
  getContractor(orgId: any) {
    return this.httpService.getRequestService("contractorlist/" + orgId);
  }
  getStatusId(statusId: number): Observable<StatusModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getStatus + "/" + statusId
    );
  }
  updateWorkOrderv2(firstPass: boolean, workOrderV2: WorkOrderModelV2) {
    return this.httpService
      .putRequestService("workorder?firstPass=" + firstPass, workOrderV2)
      .pipe(map((res) => res));
  }
  getCategoriesByEntityId(
    orgId: any,
    entityId: any
  ): Observable<CategoryModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getJobCategoryByOrgId + orgId + "/entity/" + entityId
    );
  }
  saveWorkOrderLineitem(
    workorderid: any,
    firstPass: boolean,
    workOrderLineitem: WorkOrderLineItemModel
  ) {
    return this.httpService.postRequestService(
      "workorder/" + workorderid + "/lineitem?firstPass=" + firstPass,
      workOrderLineitem
    );
  }
  updateWorkOrderLineitem(
    firstPass: boolean,
    workOrderLineitem: WorkOrderLineItemModel
  ) {
    return this.httpService.putRequestService(
      "workorder/lineitem?firstPass=" + firstPass,
      workOrderLineitem
    );
  }
  getWorkOrderLineItem(
    workOrderItemId: any
  ): Observable<WorkOrderLineItemModel[]> {
    return this.httpService.getRequestService(
      "workorder/" + workOrderItemId + "/lineitem"
    );
  }
  getLineItemById(lineItemId: number): Observable<WorkOrderLineItemModel> {
    return this.httpService.getRequestService(
      "workorder/lineitem/" + lineItemId
    );
  }
  deleteWorkOrderLineItem(id: any) {
    return this.httpService.deleteRequestService("workorder/lineitem/" + id);
  }
  deleteAssignedResources(
    assignedId: any,
    jobId: any,
    phaseid: number,
    isExternal: any
  ) {
    return this.httpService.deleteRequestService(
      "assignto/" +
        assignedId +
        "/" +
        jobId +
        "/" +
        (isExternal == 0 ? false : true) +
        "?jobPhaseId=" +
        phaseid
    );
  }
  getAssignResource(
    orgId: any,
    scheduledDate: any,
    isExternal: boolean,
    resourceEntityType: number
  ): Observable<any> {
    return this.httpService.getRequestService(
      "assignto/organization/" +
        orgId +
        "?scheduledDate=" +
        scheduledDate +
        "&isExternal=" +
        isExternal +
        "&resourceEntityType=" +
        resourceEntityType
    );
  }
  getAssignResourceOld(orgId: any): Observable<any> {
    return this.httpService.getRequestService(
      "assignto/organization/" + orgId + "/old"
    );
  }
  saveAssignedResource(assignModel: JobResourcesRequestPayload) {
    return this.httpService.postRequestService("assignto", assignModel);
  }
  getWorkorderbyIdForContractor(workorderId: string): Observable<any> {
    return this.httpService.getNoTokenRequestService(
      "contractor/workorder/" + workorderId
    );
  }
  sendWorkOrderEmail(workorderId: string) {
    return this.httpService.postRequestService(
      "workorder/" + workorderId + "/send",
      null
    );
  }

  getWorkOrdersByJobIds(
    jobIds: string[],
    startDate: Date,
    endDate: Date
  ): Observable<WorkOrderModel[]> {
    return this.httpService.postRequestService(
      `workorders/start/${startDate}/end/${endDate}`,
      jobIds
    );
  }

  formatDate(date: Date) {
    let month =
      date.getMonth() < 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let year = date.getFullYear();

    return [year, month, day].join("");
  }
}
