import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { PhaseModel } from "app/model/phaseModel";
import { environmentConstant } from "app/common/utility/environment";
import { ActivatedRoute } from "@angular/router";
import { MessageService } from "primeng/components/common/messageservice";
import { JobPhotoModel } from "app/model/jobPhotoModel";
import { JobPhasModel } from "app/model/jobPhaseModel";
import { BaThemeSpinner } from "app/theme/services";
import { UpdateCountService } from "app/common/utility/updateCount.service";
import { CommonService } from "app/common/services/common.service";
import { JobService } from "app/common/services/job.service";
import { FileInfo } from "@syncfusion/ej2-angular-inputs";
import { AddFilesComponent } from "../../../../../component/FileComponent/AddFile.component";
import { EnumJobFileTypes, PhotoEnum } from "app/common/directives/commonEnum";
import { FileRestrictions } from "@progress/kendo-angular-upload";
import { SharedService } from "app/common/utility/SharedService";
import { WorksheetFileData } from "app/model/WorksheetFile";

@Component({
  selector: "photosAdd",
  templateUrl: "./photosAdd.html",
  styleUrls: ["./photosadd.scss"],
})
export class AddPhotosComponent implements OnInit {
  paramid: number = 0;
  fileStage: any;
  lstFileType: FileType[]; //= environmentConstant.staticData.jobFileType;
  originalFileList: any = environmentConstant.staticData.jobFileType;
  lstFilePhase: PhaseModel[] = [];
  lstFileStage: any = environmentConstant.staticData.jobFileStage;
  originalFileStages: any = environmentConstant.staticData.jobFileStage;
  jobId: string;
  headertext: string;
  jobphoto: JobPhotoModel;
  disabled: boolean = true;
  isAdmin: boolean = false;
  photoDocumentFormData: FormData = null;
  lstJobPhase: JobPhasModel[] = [];
  originalJobPhaseList: JobPhasModel[] = [];
  isImageValid: boolean = false;
  isSalesFile: boolean = false;
  previousUrl: string;
  defaultItem: FileType;
  isThisForPhase: boolean;
  isVendorFile: boolean = false;
  allowedExtensionsText: string = "";
  fileRestrictions: FileRestrictions = {
    minFileSize: 10,
    maxFileSize: 20000000,
    allowedExtensions: [
      "jpg",
      "png",
      "jpg",
      "jpeg",
      "jpe",
      "jif",
      "jfif",
      "jfi",
      "webp",
      "heic",
    ],
  };

  @Input() workOrderId: any;
  @Input() saleJobId: string;
  @Input() type: string;
  @Input() phaseId: any;
  @Input() isPhase = false;
  @Input() isVendor: boolean = false;
  @Input() vendorId: number;
  @Input() changeOrderId: number;
  @Output() closeSlideout: EventEmitter<any> = new EventEmitter<any>();
  @Output() fileAdded: EventEmitter<any> = new EventEmitter<any>();
  @Output() photoAdded: EventEmitter<any> = new EventEmitter<any>();
  @Output() newPhotos = new EventEmitter<string[]>();
  @Output() newFiles = new EventEmitter<string[]>();
  @Input() fromBuilPlan: boolean;
  @Input() allowMultiple: boolean = true;
  @Input() isFromProposal: boolean = false;
  @Input() worksheetFileData: WorksheetFileData;
  @Input() productId: number = 0;

  @ViewChild("addFiles", { static: false }) addFiles: AddFilesComponent;

  FileList: FileInfo[] = [];
  constructor(
    private route: ActivatedRoute,
    private _spinner: BaThemeSpinner,
    private notificationsService: MessageService,
    private jobService: JobService,
    private commonService: CommonService,
    private updateCountService: UpdateCountService,
    private sharedService: SharedService
  ) {}
  ngOnInit() {
    this._spinner.show();
    this.setExtensionsRestrictions();
    this.isThisForPhase = this.phaseId || this.isPhase;
    this.getJobId();
    this.getFileType();
    this.jobphoto = new JobPhotoModel();
  }

  FileSelected(files) {
    this.isImageValid = files.length > 0;
  }
  getFileType() {
    this.isAdmin =
      this.sharedService.user.isSuperAdmin || this.sharedService.user.isAdmin;
    const params = this.isAdmin ? "?filterACL=false" : "?filterACL=true";
    this.commonService.getFileTypeByOrgId(params).subscribe((data) => {
      this.lstFileType = data;
      this.loadFileType();
    });
  }
  // get jobId
  getJobId() {
    if (this.type === "Photo") {
      this.headertext = "Photo";
    } else {
      this.headertext = "File";
    }
    this.route.parent.params.subscribe((params) => {
      this.jobId = params["id"];
    });
    this.route.params.subscribe((params) => {
      this.previousUrl = params["pageSource"];
    });
    if (this.jobId === undefined) {
      this.isSalesFile = true;
      this.route.params.subscribe((params) => {
        this.jobId = params["id"];
      });
    }
    if (this.saleJobId) {
      this.jobId = this.saleJobId;
    }
    if (this.workOrderId) {
      this.jobId = this.workOrderId;
    }
    // if (this.saleJobId || this.isThisForPhase) {
    //   this.headertext = this.type;
    // }
    if (!this.isSalesFile) this.getJobPhases();
  }

  loadFileType() {
    if (this.type === "Photo") {
      this.lstFileType = this.lstFileType.filter((item) => item.Id == 9);

      //temp fix to allow all roles to upload photos
      if (this.lstFileType.length == 0)
        this.lstFileType.push({ Id: 9, FileTypeName: "User Photos" });

      this.originalFileList = this.lstFileType;
      this.disabled = this.lstFileType.length == 0;
      if (!this.disabled) {
        this.jobphoto.JobFileTypeId = this.lstFileType[0].Id;
      }
    } else {
      this.lstFileType = this.lstFileType.filter((item) => item.Id != 19);
      this.originalFileList = this.lstFileType;
    }
    this._spinner.hide();
  }

  getJobPhases() {
    this.jobService.getJobPhaseListbasic(this.jobId).subscribe((data) => {
      this.lstJobPhase = data;
      this.originalJobPhaseList = data;
      this._spinner.hide();
    });
  }

  // on submit
  onSubmit(file) {
    if (file.valid && this.addFiles.uploader.fileList.files.length > 0) {
      if (this.worksheetFileData)
        this.addFiles.worksheetFileData = this.worksheetFileData;

      this.addFiles.changeOrderId = this.changeOrderId;
      this._spinner.show();
      if (this.phaseId) {
        this.jobphoto.JobPhaseId = this.phaseId;
      }
      if (this.jobphoto.JobPhaseId === undefined) {
        this.jobphoto.JobPhaseId = 0;
      }
      if (this.type == "Photo") {
        this.jobphoto.JobFileTypeId = PhotoEnum.User;
        // this.jobphoto.JobFileTypeId = 1;
        this.addFiles.filetype = this.jobphoto.JobFileTypeId;
        this.addFiles.jobPhaseId = this.jobphoto.JobPhaseId;
        this.addFiles.phasephotoFlag = this.fileStage;
        this.addFiles.jobId =
          this.addFiles.jobId == null ? this.workOrderId : this.addFiles.jobId;
        this.addFiles.uploader.uploadFiles();
      } else {
        this.addFiles.phasephotoFlag = this.fileStage;
        if (this.isVendor && this.vendorId) {
          this.addFiles.vendorId = this.vendorId;
          this.addFiles.uploader.uploadFiles();
          /* if(this.isVendor && this.vendorId){
            console.log(this.isVendor,this.vendorId,'INIT')
          } */
        } else {
          this.addFiles.jobPhaseId = this.jobphoto.JobPhaseId;
          this.addFiles.uploader.uploadFiles();
        }
      }
    }
  }
  uploadDone(guids: string[]) {
    this.notificationsService.add({
      severity: "success",
      summary: this.headertext,
      detail: "Added Successfully!!",
    });
    if (!this.fromBuilPlan) {
      this.updateCountService.update();
    }
    if (this.type === "Photo") {
      this.photoAdded.emit(this.workOrderId);
      this.newPhotos.emit(guids);
    } else {
      this.fileAdded.emit();
      this.newFiles.emit(guids);
    }
    if (
      this.fromBuilPlan &&
      this.type !== "Photo" &&
      guids.length &&
      this.jobphoto.JobFileTypeId === EnumJobFileTypes.CameraPhoto
    ) {
      this.newPhotos.emit(guids);
    }
    this._spinner.hide();
  }

  cancel() {
    // if (this.saleJobId || this.isThisForPhase || this.workOrderId) {
    this.photoAdded.emit();
    this.fileAdded.emit();
    this.closeSlideout.emit();
    // }
    // else {
    //   this._location.back();
    // }
  }

  setExtensionsRestrictions(): void {
    if (this.isFromProposal) {
      this.fileRestrictions.allowedExtensions =
        this.fileRestrictions.allowedExtensions.filter(
          (extension) => extension !== "heic"
        );
    }
    this.allowedExtensionsText = this.fileRestrictions.allowedExtensions
      .map((ext) => `.${ext.toUpperCase()}`)
      .join(", ");
  }
}

interface FileType {
  Id: number;
  FileTypeName: string;
}
