import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import {
  AddParametersToWorkAreasModel,
  CheckIfValidNameModel,
  DeleteWorkAreaOperationRes,
  PagedSearchParams,
  ParametersResponseModel,
  WorkArea,
  WorkAreaParameterOrder,
  WorKAreaParameters,
} from "app/model/WorkArea";
import { Parameter, WorkAreaSpecs } from "app/model/workAreaSpecsModel";
import { environment } from "environments/environment";
import { Observable } from "rxjs";
import { SharedService } from "../utility/SharedService";

const base_url = environment.baseEstimateUrl;

@Injectable({
  providedIn: "root",
})
export class WorkAreasService {
  constructor(private http: HttpClient, private sharedService: SharedService) {}

  getWorkAreas(): Observable<WorkArea[]> {
    const url = `${base_url}WorkAreas/${this.sharedService.selectedOrganization.ID}`;
    return this.http.get<WorkArea[]>(url);
  }

  getWorkArea(workAreaId: number): Observable<WorkArea> {
    const url = `${base_url}WorkArea/${workAreaId}`;
    return this.http.get<WorkArea>(url);
  }
  getWorkAreaParameters(
    searchParams: PagedSearchParams
  ): Observable<ParametersResponseModel> {
    const url = `${base_url}WorkAreaParameters`;
    return this.http.post<ParametersResponseModel>(url, searchParams);
  }

  getParameters(searchText: string): Observable<Parameter[]> {
    const url = `${base_url}parameters/search`;
    return this.http.post<Parameter[]>(url, {
      OrgGuid: this.sharedService.selectedOrganization.ID,
      SearchText: searchText,
    });
  }
  /**save workaprea parameter and mark delete unchecked paramters */
  Bulk(workareaParameters: WorKAreaParameters[]) {
    const url = `${base_url}parameters`;
    return this.http.post(url, workareaParameters);
  }
  AddWorkArea(workarea: WorkArea): Observable<number> {
    const url = `${base_url}workarea`;
    return this.http.post<number>(url, workarea);
  }
  UpdateWorkArea(workarea: WorkArea) {
    const url = `${base_url}workarea`;
    return this.http.put(url, {
      id: workarea.id,
      workAreaName: workarea.workAreaName,
      description: workarea.description,
      orgGuid: workarea.orgGuid,
      showOnCustomerProposal: workarea.showOnCustomerProposal,
    });
  }
  UpdateOrder(workareaParameter: WorkAreaParameterOrder) {
    const url = `${base_url}workareaParameter`;
    return this.http.put(url, workareaParameter);
  }

  deleteWorkArea(id: number): Observable<DeleteWorkAreaOperationRes> {
    const url = `${base_url}workarea/${id}`;
    return this.http.delete<DeleteWorkAreaOperationRes>(url);
  }
  deleteWorkAreaParamter(id: number) {
    let url = `${base_url}workarea/parameter/${id}`;
    return this.http.delete(url);
  }

  /**
   * Gets all the work areas with the same name
   */
  checkIfValidName(
    checkNameReqBody: CheckIfValidNameModel
  ): Observable<WorkArea | null> {
    return this.http.post<WorkArea | null>(
      `${environment.baseEstimateUrl}workarea/checkIfValidName`,
      checkNameReqBody
    );
  }

  addParametersToWorkAreas(data: AddParametersToWorkAreasModel) {
    return this.http.post(`${base_url}workarea/parameters`, data);
  }

  getAllWorkAreaParameters(workareaId: number) {
    return this.http.get(`${base_url}workarea/parameters/all/${workareaId}`);
  }

  /**
   * Determines whether a WorkArea is in use.
   * @param id
   * @returns True, if a WorkArea is in use; otherwise, false.
   */
  isInUse(id: number) {
    return this.http.get(`${base_url}WorkAreas/${id}/IsInUse`);
  }

  /** Get Work Area Specs by Organization */
  getActiveWorkAreaSpecs(orgGuid: string): Observable<WorkAreaSpecs[]> {
    return this.http.get<WorkAreaSpecs[]>(
      `${base_url}workareaspecs/active/${orgGuid}`
    );
  }
}
