<div class="photoList wordr-photolist ht100">
  <p-dataGrid
    [value]="photos"
    [paginator]="true"
    [rows]="pageSize"
    [lazy]="lazy"
    (onLazyLoad)="onLazyLoad.emit($event)"
    [totalRecords]="totalRecords"
    [class.photos-grid-h]="isSideBar"
  >
    <ng-template let-dataItem let-i="index" pTemplate="item">
      <p-panel>
        <app-image-thumbnail
          [jobPhoto]="dataItem"
          [allJobPhotos]="photos"
          [checkbox]="enableSelection"
          [clickable]="enableDetails"
          [canAnnotate]="enableAnnotation"
          [canDelete]="enableDelete"
          [canPin]="enablePinning"
          (onChecked)="onSelectedChanged.emit()"
          (onDelete)="onDelete.emit(dataItem)"
          (onUpdated)="refresh.emit()"
          (openPreview)="openPreview($event)"
          [jobId]="jobId"
        >
        </app-image-thumbnail>
      </p-panel>
    </ng-template>
  </p-dataGrid>
</div>
<image-viewer
  *ngIf="displayPreview"
  [currentIndex]="currentIndex"
  (close)="closePreview()"
>
</image-viewer>
