import {
  Directive,
  Input,
  Output,
  ElementRef,
  EventEmitter,
  OnChanges,
} from "@angular/core";

import "jquery-slimscroll";
declare var jQuery: any;
@Directive({
  selector: "[baSlimScroll]",
})
export class BaSlimScroll implements OnChanges {
  @Input() public baSlimScrollOptions: Object;

  constructor(private _elementRef: ElementRef) {}

  ngOnChanges(changes) {
    this._scroll();
  }

  private _scroll() {
    this._destroy();
    this._init();
  }

  private _init() {
    jQuery(this._elementRef.nativeElement).scroll(this.baSlimScrollOptions);
  }

  private _destroy() {
    jQuery(this._elementRef.nativeElement).scroll({ destroy: true });
  }
}
