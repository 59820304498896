import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { BaThemeSpinner } from "app/theme/services";
import { MessageService } from "primeng/components/common/messageservice";
import { StateModel } from "app/model/statesModel";
import { CommonService } from "app/common/services/common.service";
import { SharedService } from "app/common/utility/SharedService";
import { TradeService } from "app/common/services/trade.service";
import { TradeModel } from "app/model/tradeModel";
import { ContractorService } from "app/common/services/contractor.service";
import { OrgVendorModel } from "app/model/contractorModel";
import { OrganizationBasicModel } from "app/model/organisation_customer_MappingModel";
import { environmentConstant } from "app/common/utility/environment";
import { VendorContactService } from "app/common/services/Core/VendorContact.service";
import { VendorContactModel } from "app/model/Core/VendorContactModel";
import { Contact, Preferences } from "app/model/Core/ContactModel";
import { DialogService } from "ng2-bootstrap-modal";
import { ConfirmComponent } from "app/component/dialog/dialog.component";
import { QuickBooksDataModel } from "app/model/Core/QuickBooksDataModel";
import { fromEvent, merge } from "rxjs";
import { map, takeWhile } from "rxjs/operators";
import { ControlContainer, NgForm } from "@angular/forms";
import { UserRoleEnum } from "app/model/Core/UserRoleEnum";
import { ToastrService } from "ngx-toastr";
@Component({
  selector: "add-contract",
  templateUrl: "./contractorAddEdit.html",
  styleUrls: ["./contractorAddEdit.component.scss"],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ContractorAddEditComponent implements OnInit {
  @ViewChild("inputZip", { static: false })
  inputZip: ElementRef;
  headerText: string;
  btnText: string;
  id: number;
  stateList: StateModel[];
  orignalStateList: StateModel[];
  selectState: StateModel = {
    Id: -1,
    Guid: "",
    CountryId: 0,
    DisplayOrder: 0,
    Name: "Select State",
    Abbreviation: "",
  };
  content: any;
  hideSaveButton: boolean = false;
  lstTrade: TradeModel[] = [];
  orgTrades: TradeModel[] = [];
  lstContractors: any;
  orgContractors: any;
  isExisting: boolean = false;
  contractor: OrgVendorModel;
  emailPattern = environmentConstant.emailPattern;
  showAddContractorContact: boolean = false;
  showSlides: string = "";
  contractorContacts: VendorContactModel[];
  contactData: VendorContactModel;
  contactGuid: string;
  passwordResetUserName = "";
  isSuperAdmin: boolean = false;
  @Input() contractorGuid: string;
  @Input() vendorId: number;
  @Input() TradeId: any;
  @Input() isQuickAdd: boolean = false;
  @Output() updateEvent: EventEmitter<any> = new EventEmitter<any>();

  //Zip Codes Variables
  zipCodeMessage = environmentConstant.zipCodeMessage;
  alive: boolean;
  zipValidate: boolean = false;
  isFinancial: boolean = false;

  constructor(
    private tradeService: TradeService,
    private _spinner: BaThemeSpinner,
    private commonService: CommonService,
    private notificationsService: MessageService,
    private contractorService: ContractorService,
    private dialogService: DialogService,
    private vendorcontactService: VendorContactService,
    private sharedService: SharedService,
    private toastrService: ToastrService
  ) {
    this.contractor = <OrgVendorModel>{
      Organization: new OrganizationBasicModel(),
    };
  }

  ngOnInit() {
    this.isSuperAdmin = this.sharedService.user.hasRole(
      UserRoleEnum.SuperAdmin
    );
    this.isFinancial = this.sharedService.user.isFinancial;
    this.alive = true;
    this._spinner.show();
    this.getTrades(this.sharedService.selectedOrganization.ID);
    this.getStatesList();
    this.getContractorData();
  }

  ngAfterContentInit() {
    setTimeout(() => {
      this.setZipCodes();
    }, 500);
  }

  editContact(data) {
    this.showSlides = "showAddContractorContact";
    this.contactData = data;
  }

  addContact() {
    this.contactData = <VendorContactModel>{ guid: undefined };
    this.contactData.contact = <Contact>{};
    this.contactData.contact.userPreference = <Preferences>{};
    this.showSlides = "showAddContractorContact";
  }

  updateData(e) {
    this.showSlides = "";
    this.getVendorContacts(this.contractorGuid);
  }

  deleteContact(_contact) {
    this.dialogService
      .addDialog(ConfirmComponent, {
        title: "Delete Contractor Contact",
        message:
          "Are you sure you want to delete " + _contact.contact.fullName + "?",
      })
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.vendorcontactService
            .deleteVendorContact(_contact.guid)
            .subscribe((res) => {
              this.getVendorContacts(this.contractorGuid);
              this.result(res, "Deleted Successfully");
            });
        }
      });
  }

  get organizationPhone(): string {
    return this.contractor && this.contractor.Organization
      ? this.contractor.Organization.Phone || ""
      : "";
  }

  set organizationPhone(value: string) {
    if (this.contractor && this.contractor.Organization) {
      this.contractor.Organization.Phone = value;
    }
  }

  get contractorPhone(): string {
    return this.contractor ? this.contractor.SchedulerContactPhone || "" : "";
  }

  set contractorPhone(value: string) {
    if (this.contractor) {
      this.contractor.SchedulerContactPhone = value;
    }
  }

  getVendorContacts(Id) {
    this.vendorcontactService.getVendorContacts(Id).subscribe((data) => {
      this.contractorContacts = data.object;
    });
  }
  getContractors() {
    this.contractorService
      .getAssignedContractors(this.sharedService.selectedOrganization.ID)
      .subscribe((data) => {
        this.lstContractors = data;
        this.orgContractors = data;
        this._spinner.hide();
      });
  }

  public filterContractors(filter: any): void {
    this.lstContractors = this.orgContractors.filter(
      (s) => s.Company.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }
  public filterState(filter: any): void {
    this.stateList = this.orignalStateList.filter(
      (s) => s.Abbreviation.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }
  getTrades(id) {
    this.tradeService.getAllTradesByOrgId(id).subscribe((data) => {
      this.lstTrade = data;
      this.orgTrades = data;
      var res = data.filter((obj) => obj.ID == this.TradeId);
      if (this.TradeId && this.TradeId > 0) {
        this.contractor.Trades = res;
      }
    });
  }
  public filterTrades(filter: any): void {
    this.lstTrade = this.orgTrades.filter(
      (s) => s.TradeName.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }
  getStatesList() {
    this.commonService.getStates().subscribe((states) => {
      this.stateList = states;
      this.orignalStateList = states;
    });
  }

  getContractorData() {
    if (this.vendorId === 0) {
      this.headerText = "Add";
      this.btnText = "Save";
      this.getContractors();
      this.contractor.Organization.State =
        this.commonService.getCurretnOrganizationStateId();
    } else {
      this.isExisting = true;
      this.headerText = "Edit";
      this.btnText = "Update";

      this._spinner.show();
      this.contractorService
        .getContractorByVendorId(this.vendorId)
        .subscribe((data) => {
          //quickbooks deserialize hack
          if (data.QuickBooksData) {
            data.quickBooksData = <QuickBooksDataModel>{
              sync: data.QuickBooksData.Sync,
              identifier: data.QuickBooksData.Identifier,
            };
          }

          this.contractor = data;
          this.contractor.Organization.State = +data.Organization.State;
          // if (this.contractor.SchedulerEmail) { this.hasSchedulerEmail = true; }
          // if (this.contractor.SchedulerSMSPhone) { this.hasSchedulerSMSPhone = true; }
          // if (this.contractor.SchedulerPhone) { this.hasSchedulerPhone = true; }

          this._spinner.hide();
        });
      this.getVendorContacts(this.contractorGuid);
    }
  }

  onSubmit(form) {
    if (form.valid && !this.hideSaveButton) {
      this.hideSaveButton = true;
      this._spinner.show();
      // if (!this.hasSchedulerEmail) { this.contractor.SchedulerEmail = null; }
      // if (!this.hasSchedulerSMSPhone) { this.contractor.SchedulerSMSPhone = null; }
      // if (!this.hasSchedulerPhone) { this.contractor.SchedulerPhone = null; }
      this.validateZipCode(this.contractor.Organization.Zip);
      if (!this.zipValidate) {
        if (this.vendorId === 0) {
          this.contractor.OrgId = this.sharedService.selectedOrganization.ID;
          this.contractorService
            .saveContractor(this.contractor, !this.isExisting)
            .subscribe(
              (result) => this.result(result, " Added Successfully!!"),
              (error) => this.error(error)
            );
        } else {
          this.contractor.OrgId = this.sharedService.selectedOrganization.ID;

          this.contractorService.updateContractor(this.contractor).subscribe(
            (result) => this.result(result, " Updated Successfully!!"),
            (error) => this.error(error)
          );
        }
      } else {
        this._spinner.hide();
        this.zipValidate = true;
        this.notificationsService.add(environmentConstant.zipCodeError);
      }
    }
    this.hideSaveButton = false;
  }

  private error(error: any) {
    switch (error.status) {
      // case 409:
      //   this.notificationsService.add({
      //     severity: "warn",
      //     summary: "Contractor",
      //     detail: "This User Name already exists",
      //   });
      //   this._spinner.hide();
      //   break;

      case 409:
        this.toastrService.error("This User Name already exists");
        this._spinner.hide();
        break;
      case 500:
        this.toastrService.error(error.error.Message);
        this._spinner.hide();
        break;
      default:
        this.toastrService.error(error.message);
        this._spinner.hide();
        break;
    }
  }

  private result(res: any, message: string) {
    this.updateEvent.emit(true);
    this.notificationsService.add({
      severity: "success",
      summary: "Contractor",
      detail: message,
    });
    this._spinner.hide();
  }
  onCancel() {
    this.updateEvent.emit(false);
  }
  resetPassword(userName: string) {
    this.showSlides = "resetpassword";
    this.passwordResetUserName = userName;
  }

  /**
   * Method for validate Zip Code values
   * @param zipCodeValue
   */
  validateZipCode(zipCodeValue) {
    if (zipCodeValue != "" && zipCodeValue) {
      environmentConstant.zipRegex.test(zipCodeValue)
        ? (this.zipValidate = false)
        : (this.zipValidate = true);
    } else {
      this.zipValidate = false;
    }
  }

  /**
   * Sets the zip input subscriptions
   */
  setZipCodes() {
    const keyup = fromEvent(this.inputZip.nativeElement, "keyup");
    const paste = fromEvent(this.inputZip.nativeElement, "paste");
    const allEvents = merge(keyup, paste);
    allEvents
      .pipe(
        takeWhile(() => this.alive),
        map((event: Event) => {
          return (event.target as HTMLInputElement).value.trim();
        })
      )
      .subscribe((text: string) => {
        if (text != "") {
          environmentConstant.zipRegex.test(text)
            ? (this.zipValidate = false)
            : (this.zipValidate = true);
        } else {
          this.zipValidate = false;
        }
      });
  }
}
