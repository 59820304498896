import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { TextBoxModule } from "@progress/kendo-angular-inputs";
import { JobTemplateDialogComponent } from "app/component/jobTemplate-dialog/jobTemplate-dialog.component";

@NgModule({
  imports: [CommonModule, FormsModule, TextBoxModule],
  declarations: [JobTemplateDialogComponent],
  exports: [JobTemplateDialogComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class JobTemplateDialogModule {}
