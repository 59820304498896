import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { KitDetailsService } from "app/common/services/kitdetails.service";
import { SharedService } from "app/common/utility/SharedService";
import { KitGroups } from "app/model/KitDetails";
import { SelectItem } from "primeng/primeng";
import { ToastrService } from "ngx-toastr";
import { BaThemeSpinner } from "app/theme";

@Component({
  selector: "app-add-group-selection-modal",
  templateUrl: "./add-group-selection-modal.component.html",
})
export class AddGroupSelectionModalComponent implements OnInit {
  groupsForm: FormGroup;
  groupSelectItems: SelectItem[];
  submitted: boolean = false;
  kitProductId: number;
  constructor(
    private kitDetailsService: KitDetailsService,
    private sharedService: SharedService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private _spinner: BaThemeSpinner,
    private dialogRef: MatDialogRef<AddGroupSelectionModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      kitProductId: number;
    }
  ) {
    this.kitProductId = data.kitProductId;
  }

  ngOnInit() {
    this.groupsForm = this.formBuilder.group({
      groupName: ["", [Validators.required, Validators.maxLength(50)]],
    });
  }

  saveGroup() {
    this.submitted = true;
    if (!this.groupsForm.valid) {
      return;
    }
    this._spinner.show();
    const group: KitGroups = {
      groupName: this.groupsForm.get("groupName").value,
      orgGuid: this.sharedService.selectedOrganization.ID,
      displayOrder: 0,
    };
    this.kitDetailsService
      .saveGroup(this.kitProductId, group)
      .subscribe(
        () => {
          this.submitted = false;
          this.dialogRef.close();
        },
        (error) => {
          this.error(error, "Error saving information");
        }
      )
      .add(() => this._spinner.hide());
  }

  private error(error: any, message: string) {
    console.log(error);
    this.toastrService.error(message, "Kit Groups");
  }
}
