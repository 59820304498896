import { Injectable } from "@angular/core";
import { JobPhotoModel } from "app/model/jobPhotoModel";
import { BehaviorSubject, Observable, Subject } from "rxjs";

export class AnnotationHandle {
  private accepted$ = new Subject<void>();

  public accepted = this.accepted$.asObservable();

  accept() {
    this.accepted$.next();
    this.accepted$.complete();
  }
}

export type JobPhotoForAnnotation = Pick<
  JobPhotoModel,
  "ID" | "FileName" | "BlobUrl" | "JobChangeOrderId" | "JobGUID"
>;

export interface ShowInterface {
  show: boolean;
  photo?: JobPhotoForAnnotation;
  readonly?: boolean;
  handle?: AnnotationHandle;
}

@Injectable({
  providedIn: "root",
})
export class ImageAnnotatorService {
  private showAnnotator$ = new BehaviorSubject<ShowInterface>({ show: false });

  public showAnnotator = this.showAnnotator$.asObservable();

  public show(payload: Omit<ShowInterface, "show" | "accepted">) {
    const accepted$ = new Subject<void>();
    const handle = new AnnotationHandle();
    this.showAnnotator$.next({
      show: true,
      readonly: false,
      ...payload,
      handle,
    });
    return handle;
  }

  public hide() {
    this.showAnnotator$.next({ show: false });
  }
}
