import { UserRoleEnum } from "app/model/Core/UserRoleEnum";

export const PAGES_MENU = [
  {
    path: "pages",
    children: [
      {
        path: ["configuration", "dashboard"],
        data: {
          menu: {
            title: "Dashboard",
            icon: "fa fa-university",
            selected: false,
            expanded: false,
            order: 200,
          },
        },
      },
      {
        path: "scheduler",
        data: {
          menu: {
            title: "Jobs (WIP)",
            icon: "fa fa-briefcase",
            selected: false,
            expanded: false,
            order: 0,
          },
        },
        children: [
          {
            path: ["/", "pages", "dashboards", "wipdashboard", "2"],
            data: {
              menu: {
                title: "Whiteboard",
              },
            },
          },
          {
            path: ["/", "pages", "scheduler", "scheduled", "2"],
            data: {
              menu: {
                title: "general.menu.JobActive",
              },
            },
          },
          {
            path: "",
            data: {
              menu: {
                title: "Scheduling Assistant",
              },
            },
          },
          {
            path: ["/", "pages", "maps", "maps-board", "2"],
            data: {
              menu: {
                title: "Maps",
              },
            },
          },
          {
            path: "userphotos",
            data: {
              menu: {
                title: "User Photos",
              },
            },
          },
          // {
          //   path: 'toBeSchedule',
          //   data: {
          //     menu: {
          //       title: 'Needs to be Scheduled',
          //     },
          //   },
          // },
          {
            path: ["/", "pages", "dashboards", "job", "checklists"],
            data: {
              menu: {
                title: "Checklists",
              },
            },
          },
          {
            path: "workOrder",
            data: {
              menu: {
                title: "general.menu.WorkOrder",
              },
            },
          },
          {
            path: "punchlist",
            data: {
              menu: {
                title: "Punchlist",
              },
            },
          },
          {
            path: ["/", "pages", "changeOrders"],
            data: {
              menu: {
                title: "general.menu.Change_Orders",
              },
              showOnRoles: [
                UserRoleEnum.SuperAdmin,
                UserRoleEnum.Admin,
                UserRoleEnum.Accounting,
                UserRoleEnum.ViewFinancialData,
              ],
            },
          },
          {
            path: "completed",
            data: {
              menu: {
                title: "general.menu.JobComplete",
              },
            },
          },
          {
            path: ["/", "pages", "dashboards", "job", "reports"],
            data: {
              menu: {
                title: "Reports",
              },
              showOnRoles: [
                UserRoleEnum.SuperAdmin,
                UserRoleEnum.Admin,
                UserRoleEnum.Accounting,
                UserRoleEnum.ViewFinancialData,
              ],
            },
          },
        ],
      },
      {
        path: ["/", "pages", "liveViews", "available"],
        data: {
          menu: {
            title: "general.menu.LiveCamera",
            icon: "fa fa-video-camera",
            selected: false,
            expanded: false,
            order: 200,
          },
        },
      },
      {
        path: "sales",
        data: {
          menu: {
            title: "Sales",
            // icon: 'fa fa-clone',
            icon: "fa fa-line-chart",
            selected: false,
            expanded: false,
            order: 100,
          },
        },
        children: [
          {
            path: ["/", "pages", "dashboards", "wipdashboard", "1"],
            data: {
              menu: {
                title: "Sales Board",
              },
            },
          },
          {
            path: "funnel",
            data: {
              menu: {
                title: "general.menu.SalesDashboard",
              },
            },
          },
          {
            path: "worksheet",
            data: {
              menu: {
                title: "Estimate Worksheets",
              },
              showOnRoles: [
                UserRoleEnum.SuperAdmin,
                UserRoleEnum.Admin,
                UserRoleEnum.Accounting,
                UserRoleEnum.ViewFinancialData,
                UserRoleEnum.SalesContact,
                UserRoleEnum.SalesManager,
                UserRoleEnum.SalesRep,
              ],
            },
          },
          /*{
            path: ["/", "pages", "scheduler", "scheduled", "1"],
            data: {
              menu: {
                title: "Calendar",
              },
            },
          },*/
          {
            path: ["/", "pages", "maps", "maps-board", "1"],
            data: {
              menu: {
                title: "Maps",
              },
            },
          },
          /*{
            path: "salesCalendar",
            data: {
              menu: {
                title: "Google Calendar",
              },
            },
          },*/
          {
            path: "userphotos",
            data: {
              menu: {
                title: "User Photos",
              },
            },
          },
          {
            path: "salesDashboard",
            data: {
              menu: {
                title: "Metrics",
              },
              showOnRoles: [
                UserRoleEnum.SuperAdmin,
                UserRoleEnum.Admin,
                UserRoleEnum.Accounting,
                UserRoleEnum.ViewFinancialData,
              ],
            },
          },
          {
            path: ["/", "pages", "dashboards", "sales", "checklists"],
            data: {
              menu: {
                title: "Checklists",
              },
            },
          },
          {
            path: ["/", "pages", "changeOrders", "sales"],
            data: {
              menu: {
                title: "Change Orders",
              },
            },
          },
          {
            path: "searchSales",
            data: {
              menu: {
                title: "Lead Search",
              },
            },
          },
          {
            path: ["/", "pages", "dashboards", "sales", "reports"],
            data: {
              menu: {
                title: "Reports",
              },
              showOnRoles: [
                UserRoleEnum.SuperAdmin,
                UserRoleEnum.Admin,
                UserRoleEnum.Accounting,
                UserRoleEnum.ViewFinancialData,
              ],
            },
          },
        ],
      },
      {
        path: "estimate",
        data: {
          menu: {
            title: "general.menu.Estimate",
            icon: "fa fa-money green-color",
            selected: false,
            expanded: false,
            order: 240,
          },
          showOnRoles: [
            UserRoleEnum.SuperAdmin,
            UserRoleEnum.Admin,
            UserRoleEnum.Accounting,
            UserRoleEnum.ViewFinancialData,
            UserRoleEnum.SalesContact,
            UserRoleEnum.SalesManager,
            UserRoleEnum.SalesRep,
          ],
        },
        children: [
          {
            path: "worksheet",
            data: {
              menu: {
                title: "Worksheets",
              },
            },
          },
          {
            path: "contracts",
            data: {
              menu: {
                title: "Contracts",
              },
            },
          },
          {
            path: "product-catalog",
            data: {
              menu: {
                title: "Product Catalog",
              },
              showOnRoles: [UserRoleEnum.SuperAdmin, UserRoleEnum.Admin],
            },
          },
          {
            path: "estimateview",
            data: {
              menu: {
                title: "Views",
              },
              showOnRoles: [UserRoleEnum.SuperAdmin, UserRoleEnum.Admin],
            },
          },
          {
            path: "projecttype",
            data: {
              menu: {
                title: "Projects",
              },
              showOnRoles: [UserRoleEnum.SuperAdmin, UserRoleEnum.Admin],
            },
          },
          {
            path: "workareas",
            data: {
              menu: {
                title: "Work Areas",
              },
              showOnRoles: [UserRoleEnum.SuperAdmin, UserRoleEnum.Admin],
            },
          },
          {
            path: ["estimate", "parameters"],
            data: {
              menu: {
                title: "Parameters",
              },
              showOnRoles: [UserRoleEnum.SuperAdmin, UserRoleEnum.Admin],
            },
          },
          {
            path: "parameters-group",
            data: {
              menu: {
                title: "Parameters Group",
              },
              showOnRoles: [UserRoleEnum.SuperAdmin, UserRoleEnum.Admin],
            },
          },
          {
            path: "calculator-builder",
            data: {
              menu: {
                title: "Calculator Builder",
              },
              showOnRoles: [UserRoleEnum.SuperAdmin, UserRoleEnum.Admin],
            },
          },
          {
            path: "UnitOfMeasure",
            data: {
              menu: {
                title: "Units of Measure",
              },
              showOnRoles: [UserRoleEnum.SuperAdmin, UserRoleEnum.Admin],
            },
          },
          {
            path: "kit-group",
            data: {
              menu: {
                title: "Kit Groups",
              },
              showOnRoles: [UserRoleEnum.SuperAdmin, UserRoleEnum.Admin],
            },
          },
        ],
      },
      {
        path: "jobs",
        data: {
          menu: {
            title: "general.menu.JobSetup",
            // icon: 'fa fa-wrench',
            icon: "icon icon-jobsetup f20",
            selected: false,
            expanded: false,
            order: 250,
          },
        },
        children: [
          // {
          //     path: 'job',
          //     data: {
          //         menu: {
          //             title: 'general.menu.JobsDashboard',
          //         },
          //     },
          // },
          {
            path: ["jobs", "jobTemplates", "2"],
            data: {
              menu: {
                title: "general.menu.Job_Templates",
              },
            },
          },
          {
            path: ["/", "pages", "jobs", "jobTemplates", "1"],
            data: {
              menu: {
                title: "Sales Templates",
              },
            },
          },
          {
            path: "drawSchedule",
            data: {
              menu: {
                title: "general.menu.Draw_Schedule",
              },
            },
          },
          {
            path: "categories",
            data: {
              menu: {
                title: "general.menu.Categories",
              },
            },
          },
          {
            path: "phases",
            data: {
              menu: {
                title: "general.menu.Phases",
              },
            },
          },
          {
            path: "trade",
            data: {
              menu: {
                title: "general.menu.Trade",
              },
            },
          },
          {
            path: "scheduleReason",
            data: {
              menu: {
                title: "general.menu.ScheduleReason",
              },
            },
          },
          {
            path: "fileTypes",
            data: {
              menu: {
                title: "general.menu.FileTypes",
              },
            },
          },
          {
            path: ["/", "pages", "sales", "leadSource"],
            data: {
              menu: {
                title: "general.menu.LeadSource",
              },
            },
          },
          {
            path: "notifications",
            data: {
              menu: {
                title: "Message Templates",
              },
              showOnRoles: [UserRoleEnum.SuperAdmin, UserRoleEnum.Admin],
            },
          },
          {
            path: ["/", "pages", "campaigns"],
            data: {
              menu: {
                title: "general.menu.Campaigns",
                //icon: "fa fa-envelope-o",
                selected: false,
                expanded: false,
                order: 200,
              },
              showOnRoles: [UserRoleEnum.SuperAdmin, UserRoleEnum.Admin],
            },
          },
        ],
      },
      {
        path: "accounting",
        data: {
          menu: {
            title: "Accounting",
            // icon: 'fa fa-wrench',
            icon: "fa fa-dollar f20",
            selected: false,
            expanded: false,
            order: 250,
          },
          showOnRoles: [
            UserRoleEnum.SuperAdmin,
            UserRoleEnum.Admin,
            UserRoleEnum.Accounting,
            UserRoleEnum.ViewFinancialData,
          ],
        },
        children: [
          {
            path: ["/", "pages", "dashboards", "financedashbord"],
            data: {
              menu: {
                title: "Dashboard",
              },
            },
          },
          {
            path: "receivables",
            data: {
              menu: {
                title: "Receivables",
              },
            },
          },
          {
            path: "invoice",
            data: {
              menu: {
                title: "Invoices",
              },
            },
          },

          {
            path: "pastdue",
            data: {
              menu: {
                title: "Past Due",
              },
            },
          },
          {
            path: "payments",
            data: {
              menu: {
                title: "Payments",
              },
            },
          },
          {
            path: "manufacturers",
            data: {
              menu: {
                title: "Manufacturers",
              },
            },
          },
          {
            path: "catalog",
            data: {
              menu: {
                title: "Catalog",
              },
            },
          },
          {
            path: "budget",
            data: {
              menu: {
                title: "Budget",
              },
            },
          },
          {
            path: ["/", "pages", "dashboards", "accounting", "reports"],
            data: {
              menu: {
                title: "Reports",
              },
            },
          },
        ],
      },
      {
        path: "selectionSheet",
        data: {
          menu: {
            title: "general.menu.selectionSheet",
            icon: "fa fa-file-text-o",
            selected: false,
            expanded: false,
            order: 200,
          },
        },
        children: [
          {
            path: "feature",
            data: {
              menu: {
                title: "general.menu.Feature",
              },
            },
          },

          {
            path: "materials",
            data: {
              menu: {
                title: "general.menu.Materials",
              },
            },
          },
          {
            path: "manufacturer",
            data: {
              menu: {
                title: "MANF/DIS",
              },
            },
          },
        ],
      },
      {
        path: "clients",
        data: {
          menu: {
            title: "general.menu.Customers",
            // icon: 'fa fa-id-badge',
            icon: "fa fa-address-book-o f20",
            selected: false,
            expanded: false,
            order: 200,
          },
        },
      },
      {
        path: ["/", "pages", "contacts", "contractors"],
        data: {
          menu: {
            title: "general.menu.contractor",
            icon: "fa fa-truck  yellow-color",
            selected: false,
            expanded: false,
            order: 400,
          },
        },
      },

      {
        path: ["/", "pages", "notification", "notification"],
        data: {
          menu: {
            title: "Notifications",
            icon: "fa fa-bell-o",
            selected: false,
            expanded: false,
            order: 300,
          },
        },
      },
      // {
      //    path: 'Phasereport',
      //    data: {
      //        menu: {
      //            title: 'general.menu.Phase_Reports',
      //            icon: 'fa fa-file-text',
      //            selected: false,
      //            expanded: false,
      //            order: 200,
      //        },
      //    },

      // },
      {
        path: "administration",
        data: {
          menu: {
            title: "Administration",
            icon: "fa fa-university",
            selected: false,
            expanded: false,
            order: 400,
          },
        },
        children: [
          {
            // path: ['configuration', 'organizationsAddEdit'],
            slideOut: true,
            data: {
              menu: {
                title: "Organization",
              },
            },
          },
          /*{
            path: ["/", "pages", "administration", "mobileConfigurator"],
            data: {
              menu: {
                title: "general.menu.presales",
              },
            },
          },*/
          {
            path: "users",
            data: {
              menu: {
                title: "general.menu.users",
              },
            },
          },
          {
            path: "userRole",
            data: {
              menu: {
                title: "general.menu.userRole",
              },
            },
          },
          {
            path: "businessUnit",
            data: {
              menu: {
                title: "Business Units",
              },
            },
          },
          {
            path: "homebuilders",
            data: {
              menu: {
                title: "Home Builders",
              },
            },
          },
          {
            path: "checklists",
            data: {
              menu: {
                title: "Checklists",
              },
            },
          },
          {
            path: "custom-field-checklists",
            data: {
              menu: {
                title: "Custom Field Checklists",
              },
            },
          },
          {
            path: "stores",
            data: {
              menu: {
                title: "Stores",
              },
            },
          },
          {
            path: ["/", "pages", "Camera", "orgCamera"],
            data: {
              menu: {
                title: "general.menu.Cameras",
                // icon: 'fa fa-camera',
                selected: false,
                expanded: false,
                order: 200,
              },
            },
          },
          {
            path: "e-documents",
            data: {
              menu: {
                title: "E-Templates",
              },
            },
          },
          {
            path: "document-fields",
            data: {
              menu: {
                title: "Document Fields",
              },
            },
          },
          {
            path: "taxing-authorities",
            data: {
              menu: {
                title: "Taxing Authorities",
              },
            },
          },
        ],
      },
      {
        path: "configuration",
        data: {
          menu: {
            title: "Configuration",
            // icon: 'fa fa-cog',
            icon: "icon icon-config",
            selected: false,
            expanded: false,
            order: 500,
          },
        },
        children: [
          {
            path: "dashboard",
            data: {
              menu: {
                title: "general.menu.configdashboard",
              },
            },
          },

          {
            path: "organizations",
            data: {
              menu: {
                title: "general.menu.Organizations",
              },
            },
          },
          {
            path: "cameras",
            data: {
              menu: {
                title: "general.menu.Config_Cameras",
              },
            },
          },

          {
            path: "models",
            data: {
              menu: {
                title: "general.menu.Models",
              },
            },
          },
          {
            path: "firmware",
            data: {
              menu: {
                title: "general.menu.Firmware",
              },
            },
          },
          {
            path: "import-catalog",
            data: {
              menu: {
                title: "general.menu.ImportCatalog",
              },
            },
          },
          {
            path: "superAdmins",
            data: {
              menu: {
                title: "general.menu.Super_Admins",
              },
            },
          },
          {
            path: "systemJobTemplates",
            data: {
              menu: {
                title: "general.menu.System_Job_Template",
              },
            },
          },
          {
            path: "systemCategories",
            data: {
              menu: {
                title: "general.menu.SystemCategories",
              },
            },
          },
          {
            path: "systemphase",
            data: {
              menu: {
                title: "general.menu.SystemPhase",
              },
            },
          },
          {
            path: "fileTypes",
            data: {
              menu: {
                title: "general.menu.SystemFileTypes",
              },
            },
          },
          {
            path: "userRole",
            data: {
              menu: {
                title: "general.menu.userRole",
              },
            },
          },
          {
            path: "bitRate",
            data: {
              menu: {
                title: "general.menu.bitRate",
              },
            },
          },
        ],
      },
    ],
  },
];
