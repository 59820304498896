import { values, chain } from "lodash";

export class EntityCache<T> {
  private cache: { [guid: string]: T } = {};

  constructor(private keyGetter: (t: T) => string = (t: any) => t.Guid) {}

  addItems(items: T[]) {
    items.forEach((item) => (this.cache[this.keyGetter(item)] = item));
  }

  get(key: string) {
    return this.cache[key];
  }

  getItems(keys: string[]) {
    return chain(this.cache).pick(keys).values().value();
  }

  delete(key: string) {
    delete this.cache[key];
  }

  deleteItems(keys: string[]) {
    keys.forEach((key) => {
      delete this.cache[key];
    });
  }

  clear() {
    this.cache = {};
  }

  items() {
    return values(this.cache);
  }
}
