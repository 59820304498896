<div class="">
  <div class="headingwrap clearfix">
    <div class="editjobtopbtn">
      <h1 class="">Notifications</h1>
      <div class="addbtn">
        <!-- <a (click)="editAlert()" data-toggle="tooltip" data-placement="top" title="Add Work Order">
                    <i class="fa fa-plus-circle" aria-hidden="true"></i>
                </a> -->
        <a
          routeLink=""
          data-toggle="tooltip"
          data-placement="top"
          (click)="exportToExcel(grid)"
          title="Export"
        >
          <i class="icon icon-excel-solid" aria-hidden="true"></i>
        </a>
      </div>
    </div>
    <div class="title_right hright datasearch">
      <div class="form-group pull-right top_search">
        <div class="input-group">
          <input
            [appFocus]="true"
            type="text"
            class="inputS"
            [(ngModel)]="filterQuery"
            (ngModelChange)="filterGrid()"
            placeholder="{{'common.search_for'|translate}}"
          />
          <i class="fa fa-search"></i>
        </div>
      </div>
    </div>
  </div>
  <div class="">
    <div class="">
      <div class="x_panel">
        <div class="x_content">
          <div class="kendoheight customtable kendoheight2">
            <kendo-grid
              [data]="gridView"
              [pageSize]="pageSize"
              #grid="kendoGrid"
              [skip]="skip"
              [pageable]="true"
              (pageChange)="pageChange($event)"
              [sortable]="{
              allowUnsort: allowUnsort,
              mode: multiple ? 'multiple' : 'single'
              }"
              [sort]="sort"
              (sortChange)="sortChange($event)"
            >
              <kendo-grid-column
                headerClass="gridHeading"
                class="gridRow"
                title="{{''|translate}}"
                width="30"
              >
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                  <div class="dropdown cud">
                    <i
                      class="fa fa-ellipsis-v"
                      id="menu1"
                      data-toggle="dropdown"
                    >
                    </i>
                    <ul
                      class="dropdown-menu"
                      role="menu"
                      aria-labelledby="menu1"
                    >
                      <!-- <li>
                                                <a routerLink="/pages/Notification/{{dataItem.Id}}}}">
                                                    <i class="fa fa-pencil"></i> View
                                                </a>
                                            </li> -->
                      <li>
                        <a
                          (click)="deleteNotification(dataItem.Id)"
                          data-toggle="modal"
                          data-target=".bs-example-modal-sm"
                        >
                          <i class="fa fa-trash-o"></i>
                          {{'common.delete'|translate}}
                        </a>
                      </li>
                    </ul>
                  </div>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column
                headerClass="gridHeading"
                class="gridRow"
                width="70"
                field="NotificationType"
                title="Type"
              >
              </kendo-grid-column>
              <kendo-grid-column
                headerClass="gridHeading"
                class="gridRow"
                width="200"
                field="NotificationName"
                title="Notification"
              >
              </kendo-grid-column>
              <kendo-grid-column
                headerClass="gridHeading"
                class="gridRow"
                width="200"
                field="JobName"
                title="Job Name"
              >
              </kendo-grid-column>
              <kendo-grid-column
                headerClass="gridHeading"
                class="gridRow"
                field="Message"
                title="Message"
              >
                <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                  <span [innerHTML]="dataItem.Message"></span>
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-excel
                fileName="Notifications.xlsx"
                [fetchData]="allData"
              ></kendo-grid-excel>
            </kendo-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
