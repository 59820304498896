import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { GeneralReportModel } from "app/model/GeneralReportModel";

@Component({
  selector: "app-report",
  templateUrl: "./report.component.html",
  styleUrls: ["./report.component.scss"],
})
export class ReportComponent implements OnInit {
  reportModel: GeneralReportModel;
  hasData: boolean = false;
  constructor(
    private dialogRef: MatDialogRef<ReportComponent>,
    @Inject(MAT_DIALOG_DATA) data: GeneralReportModel
  ) {
    if (data) this.hasData = true;
    this.reportModel = data;
    this.reportModel.scaleMode = "FIT_PAGE_WIDTH";
  }

  ngOnInit() {}

  /**
   * close this dialog
   */
  close(): void {
    this.dialogRef.close();
  }
}
