<div class="widgets commonFixedSidebar">
  <div class="row">
    <div class="col-md-12 ht100">
      <div class="rightcontent">
        <div class="sideBarHeader">
          <h1>Photo Detail</h1>
        </div>
        <kendo-tabstrip>
          <kendo-tabstrip-tab title="Details" [selected]="true">
            <ng-template kendoTabContent>
              <div class="tabBlk">
                <div class="list-unstyled">
                  <div class="mb-3" style="height: 200px">
                    <section class="photoContainer">
                      <div class="imageSide">
                        <div style="padding: 0 10px 10px; height: 225px">
                          <app-image-thumbnail
                            [userPhoto]="image"
                            [checkbox]="false"
                            [clickable]="false"
                            [canDelete]="false"
                            (onUpdated)="thumbnailUpdated()"
                            [hover]="false"
                            (openPreview)="openPreview($event)"
                          ></app-image-thumbnail>
                        </div>
                      </div>
                      <form #form="ngForm">
                        <div class="titleSide">
                          <input
                            [appFocus]="true"
                            type="text"
                            class="form-control mb-2"
                            placeholder="Title"
                            id="Title"
                            name="Title"
                            #Title="ngModel"
                            [(ngModel)]="image.Title"
                            maxlength="250"
                          />
                          <div class="tags">
                            <div class="d-flex w-100">
                              <p-multiSelect
                                [options]="tags"
                                id="tags"
                                name="Tags"
                                ngModel
                                #Tags="ngModel"
                                [(ngModel)]="selectedTags"
                                optionLabel="FileTag"
                                class="multiselect-custom width-90"
                                defaultLabel="Tags"
                              >
                                <ng-template
                                  let-selectedTags
                                  pTemplate="selectedItems"
                                >
                                  <div
                                    class="tag-item tag-item-value"
                                    *ngFor="let option of selectedTags"
                                  >
                                    <div>{{ option.FileTag }}</div>
                                  </div>
                                  <div
                                    *ngIf="
                                      !selectedTags || selectedTags.length === 0
                                    "
                                    class="tag-placeholder"
                                  >
                                    Tags
                                  </div>
                                </ng-template>
                              </p-multiSelect>
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Add Tag"
                                (click)="AddTag()"
                                *ngIf="!addTag"
                                class="width-10 d-flex justify-content-center align-items-center"
                              >
                                <i
                                  class="fa fa-plus-circle plusBtn"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </div>
                            <div>
                              <input
                                [appFocus]="true"
                                type="text"
                                class="form-control mb-2 addTag"
                                placeholder="Add Tag"
                                id="tag"
                                name="tag"
                                maxlength="250"
                                [(ngModel)]="tagEntry"
                                *ngIf="addTag"
                                (keyup.enter)="SaveTag()"
                              />

                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Cancel Tag"
                                (click)="CancelTag()"
                                *ngIf="addTag"
                              >
                                <i
                                  class="fa fa-times timesBtn"
                                  aria-hidden="true"
                                ></i>
                              </a>
                              <a
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Save Tag"
                                (click)="SaveTag()"
                                *ngIf="addTag"
                              >
                                <i
                                  class="fa fa-check plusBtn"
                                  aria-hidden="true"
                                ></i>
                              </a>
                            </div>
                          </div>
                          <textarea
                            class="form-control mb-0 h-70"
                            style="display:none"
                            placeholder="Description"
                            id="Desc"
                            name="Desc"
                            #Desc="ngModel"
                            [(ngModel)]="image.Description"
                            pInputText
                          ></textarea>                          
                          <div class="checkbox-area">
                            <label for="ContractorCanView">
                              <input
                                type="checkbox"
                                name="ContractorCanView"
                                [(ngModel)]="jobFile.ContractorCanView"
                              />
                              <span class="pl-1">Shared with Contractors</span>
                            </label>

                            <label class="pl-3" for="CustomerCanView">
                              <input
                                type="checkbox"
                                name="CustomerCanView"
                                [(ngModel)]="jobFile.CustomerCanView"
                              />
                              <span class="pl-1">Shared with Customer</span>
                            </label>
                          </div>
                        </div>
                      </form>
                    </section>
                  </div>

                    <!-- <div>
                  <h3 style="float: left">Uploaded By</h3>
                  <div>
                    <div style="width: 5px">
                      <div class="circle">
                        <label style="font-size: smaller">{{
                          image.OwnerInitials
                        }}</label>
                      </div>
                    </div>
                    <div>
                      <label style="font-size: smaller; margin: 12px">
                        {{ image.DisplayDateTime }}</label
                      >
                    </div>
                  </div>
                </div> -->

                    <div class="bkgComments">
                      <h3>Comments</h3>
                      <div>
                        <div class="d-flex">
                          <!-- <p-editor
                            class="mb-0 p-2"
                            placeholder="Comments"
                            id="Note"
                            name="Note"
                            [(ngModel)]="comment"
                          ></p-editor> -->
                          <editor
                            [init]="editorConfig"
                            [id]="'Note' + imageData.JobFileId"
                            name="Note"
                            [(ngModel)]="comment"
                            class="mb-0 p-2"
                          ></editor>
                        </div>
                        <div style="width: 100%">
                          <div class="circle" style="display: inline-block">
                            <label style="font-size: smaller">{{
                              user.CustomerInitials
                            }}</label>
                          </div>
                          <div class="sendbtn-blk" style="float: right;margin-right: 20px;line-height: 0px;margin-top: 0px;">
                            <button
                              style="height:20px;width:20px;line-height: 10px;"
                              (click)="AddComment()"
                              [disabled]="comment == ''"
                            >
                              <i class="fa fa-paper-plane" style="margin-left:-3px;font-size:11px;"></i>
                            </button>
                          </div>

                        </div>
                      </div>
                    </div>
                  
                  <br />
                  <div class="notes-scrolled" style="width: 100%">
                    <div *ngFor="let comment of lstComments; let i = index">
                      <div style="padding: 20px">
                        <div class="d-flex">
                          <label
                            *ngIf="position != i"
                            style="
                              width: 85%;
                              overflow-wrap: anywhere;
                              margin: 0 10px;
                            "
                            [innerHTML]="comment.Note"
                            >{{ comment.Note }}</label
                          >
                          <!-- <p-editor
                            class="p-2"
                            *ngIf="editComment && position == i"
                            [(ngModel)]="comment.Note"
                          ></p-editor> -->
                          <editor
                            *ngIf="editComment && position == i"
                            [init]="editorConfig"
                            [(ngModel)]="comment.Note"
                            class="p-2"
                          ></editor>
                          <!-- <input *ngIf="editComment" style="margin: 8px 0 0 10px; width: 85%">{{
                        comment.comment
                      }}</input> -->
                          <a
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Delete Comment"
                            (click)="DeleteComment(i, comment)"
                            style="margin-right: 5px; margin-left: 5px"
                            *ngIf="editComment && position == i"
                          >
                            <i
                              class="fa fa-trash plusBtn"
                              style="float: none"
                              aria-hidden="true"
                            ></i>
                          </a>
                          <a
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Update Comment"
                            (click)="UpdateComment(comment)"
                            *ngIf="editComment && position == i"
                          >
                            <i
                              class="fa fa-check plusBtn"
                              style="float: none"
                              aria-hidden="true"
                            ></i>
                          </a>
                          <a
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Edit Comment"
                            (click)="EditComment(i)"
                            *ngIf="position != i && comment.canEdit"
                          >
                            <i
                              class="fa fa-pencil plusBtn"
                              style="float: none"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>
                        <div style="width: 5px">
                          <div class="circle">
                            <label style="font-size: smaller">{{
                              comment.CustomerInitials
                            }}</label>
                          </div>
                        </div>
                        <div>
                          <label
                            style="
                              margin: 0 0 10px 10px;
                              width: 85%;
                              font-size: xx-small;
                            "
                            >{{ comment.DateString }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="fixbtnwrap">
                    <div class="btnwrap">
                      <button
                        class="btn darkbtn"
                        *ngIf="form.dirty"
                        (click)="Save()"
                      >
                        <i class="fa fa-floppy-o"></i>
                        Update
                      </button>
                      <button
                        type="button"
                        class="btn btn-cancel"
                        (click)="Cancel()"
                      >
                        {{ "common.cancel" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </kendo-tabstrip-tab>
          <kendo-tabstrip-tab title="Versions">
            <ng-template kendoTabContent>
              <app-photo-grid
                [photos]="allVersions"
                [pageSize]="100"
                [enableSelection]="false"
                [enableAnnotation]="false"
                [enableDelete]="false"
                [enableDetails]="false"
                [totalRecords]="allVersions?.length"
                (refresh)="getAllVersions()"
              >
              </app-photo-grid>
            </ng-template>
          </kendo-tabstrip-tab>
        </kendo-tabstrip>
      </div>
    </div>
  </div>
</div>
<image-viewer *ngIf="displayPreview" (close)="closePreview()"> </image-viewer>
