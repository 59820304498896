import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpService } from "../utility/http.service";
import { environmentConstant } from "../utility/environment";
import { Observable } from "rxjs";
import { EmailMessageModel, EmailPayload } from "app/model/emailMessageModel";

@Injectable()
export class EmailMessageService {
  constructor(private httpService: HttpService) {}
  saveEmailMessage(emailMessage: EmailMessageModel) {
    return this.httpService
      .postRequestService(environmentConstant.saveEmail, emailMessage)
      .pipe(map((res) => res));
  }
  sendEmailMessage(emailMessage: EmailPayload) {
    return this.httpService
      .postRequestService(environmentConstant.sendEmail, emailMessage)
      .pipe(map((res) => res));
  }

  composeNativeEmail(email: string, subject: string, body: string) {
    if (email == null) email = "";
    var email =
      "mailto:" +
      email +
      "?subject=" +
      escape(subject) +
      "&body=" +
      escape(body);
    //save here
    window.location.href = email;
  }
}
