import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import {
  SidebarModule,
  InputMaskModule,
  InputTextModule,
  CardModule,
  ChipsModule,
  DataGridModule,
  DialogModule,
  EditorModule,
  PanelModule,
  RadioButtonModule,
  SelectButtonModule,
  PaginatorModule,
  TabViewModule,
} from "primeng/primeng";

import { TranslateModule } from "@ngx-translate/core";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { TextBoxModule } from "@progress/kendo-angular-inputs";
import { ExcelModule, GridModule } from "@progress/kendo-angular-grid";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { ScrollViewModule } from "@progress/kendo-angular-scrollview";

import { JobUIModule } from "app/modules/JobUI/JobUI.module";
import { ChatModule } from "app/component/Chat/Chat.module";
import { MPBUIOrderModule } from "app/modules/OrderUI/mpbuiorder.module";
import { CommonDirectiveModule } from "app/common/directives/commondirective.module";
import { QuickBooksModule } from "app/component/QuickBooks/QuickBooks.module";
import { NotificationsModule } from "app/component/notifications/notifications.module";
import { AppTranslationModule } from "app/app.translation.module";
import { NgxUploaderModule } from "ngx-uploader";
import { AuthenticationService } from "app/common/services/authentication.service";
import { HttpService } from "app/common/utility/http.service";
import { AuthGuard } from "app/common/authGuard/auth.guard";
import { CommonService } from "app/common/services/common.service";
import { CommonDataService } from "app/common/services/commonData.service";
import { OrganizationService } from "app/common/services/organization.service";
import { WorkOrderService } from "app/common/services/workOrder.service";
import { ShippingService } from "app/common/services/Core/Shipping.service";
import { UserService } from "app/common/services/Core/User.service";
import { ClientAddEdit } from "app/pages/clients/client/clientAddEdit/clientAddEdit.component";
import { ImagePipe } from "app/common/directives/ImageFilter";
import { GroupByPipe } from "app/common/directives/groupByPipe";
import { AddGatewayAccountsComponent } from "app/component/add-gateway-accounts/add-gateway-accounts.component";
import { EditShippingComponent } from "app/component/edit-shipping/edit-shipping.component";
import { WeatherReschedulerComponent } from "app/pages/scheduler/weatherRescheduler/weather-rescheduler/weather-rescheduler.component";
import { SlideoutContainerComponent } from "app/component/Container/slideout-container/slideout-container.component";
import { WeatherRescheduleModalComponent } from "app/component/weather-reschedule-modal/weather-reschedule-modal.component";
import { ChartsModule } from "@progress/kendo-angular-charts";
import { CheckBoxModule } from "@syncfusion/ej2-angular-buttons";
import { ColorPickerModule } from "ngx-color-picker";
import { CurrencyMaskModule } from "ng2-currency-mask";
import {
  DatePickerModule,
  DateTimePickerModule,
  TimePickerModule,
} from "@syncfusion/ej2-angular-calendars";
import {
  DropDownListModule,
  MultiSelectModule,
} from "@syncfusion/ej2-angular-dropdowns";
import { ImageCropperModule } from "ngx-image-cropper";
import { PhotoCarouselModule } from "app/component/photo-carousel/photo-carousel.module";
import {
  RecurrenceEditorModule,
  ScheduleModule,
} from "@syncfusion/ej2-angular-schedule";
import { SortableModule } from "@progress/kendo-angular-sortable";
import { ContactsTabbedEntryComponent } from "app/component/contacts-tabbed-entry/contacts-tabbed-entry.component";
import { ToastModule } from "primeng/toast";
import { DashboardService } from "../common/services/dashboard.service";
import { JobChangeOrderService } from "../common/services/jobChangeOrder.service";
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { FloatingLabelModule } from "@progress/kendo-angular-label";
import { CreateCategoryDialogComponent } from "app/pages/catalog/dialogs/create-category-dialog/create-category-dialog.component";

const SHARED_MODULES = [
  AppTranslationModule,
  CardModule,
  ChartsModule,
  ChatModule,
  CheckBoxModule,
  ChipsModule,
  ColorPickerModule,
  CommonDirectiveModule,
  CommonModule,
  CurrencyMaskModule,
  DataGridModule,
  DateInputsModule,
  DatePickerModule,
  DateTimePickerModule,
  DialogModule,
  DropDownListModule,
  DropDownsModule,
  EditorModule,
  ExcelModule,
  FormsModule,
  GridModule,
  ImageCropperModule,
  InputMaskModule,
  InputTextModule,
  JobUIModule,
  LayoutModule,
  MPBUIOrderModule,
  MultiSelectModule,
  NgxUploaderModule,
  NotificationsModule,
  PaginatorModule,
  PanelModule,
  PhotoCarouselModule,
  QuickBooksModule,
  RadioButtonModule,
  ReactiveFormsModule,
  RecurrenceEditorModule,
  RouterModule,
  ScheduleModule,
  SelectButtonModule,
  ScrollViewModule,
  SidebarModule,
  SortableModule,
  TextBoxModule,
  TimePickerModule,
  TranslateModule,
  TabViewModule,
  ToastModule,
  GooglePlaceModule,
  FloatingLabelModule,
];

@NgModule({
  declarations: [
    ImagePipe,
    ClientAddEdit,
    EditShippingComponent,
    AddGatewayAccountsComponent,
    WeatherReschedulerComponent,
    WeatherRescheduleModalComponent,
    GroupByPipe,
    SlideoutContainerComponent,
    ContactsTabbedEntryComponent,
    CreateCategoryDialogComponent,
  ],
  imports: [...SHARED_MODULES],
  exports: [
    ...SHARED_MODULES,
    ImagePipe,
    ClientAddEdit,
    EditShippingComponent,
    AddGatewayAccountsComponent,
    WeatherReschedulerComponent,
    WeatherRescheduleModalComponent,
    GroupByPipe,
    SlideoutContainerComponent,
    ContactsTabbedEntryComponent,
    CreateCategoryDialogComponent,
  ],
  providers: [
    HttpService,
    AuthGuard,
    AuthenticationService,
    CommonService,
    CommonDataService,
    OrganizationService,
    WorkOrderService,
    EditShippingComponent,
    ShippingService,
    UserService,
    DashboardService,
    JobChangeOrderService,
  ],
  entryComponents: [WeatherRescheduleModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BaseSharedModule {}
