import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { BaThemeSpinner } from "app/theme/services";
import { MessageService } from "primeng/components/common/messageservice";
import { VendorContactService } from "app/common/services/Core/VendorContact.service";
import { VendorContactModel } from "app/model/Core/VendorContactModel";

@Component({
  selector: "ContractorContact-Component",
  templateUrl: "./AddEditContractorContact.component.html",
  styleUrls: ["./AddEditContractorContact.component.css"],
})
export class ContractorContactComponent implements OnInit {
  buttonText: string;
  mobileOptions: boolean = false;
  headerText: string;
  contactInfo: VendorContactModel;
  @Input() OrgVendorId: string;
  @Input() contactGuid: string;
  @Input() contactdata: VendorContactModel;
  @Output() updateData: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _spinner: BaThemeSpinner,
    private notificationsService: MessageService,
    private vendorcontactService: VendorContactService
  ) {}

  ngOnInit() {
    if (this.contactdata.guid !== undefined) {
      this.headerText = "Edit Contact";
      this.buttonText = "Update";
      this._spinner.show();
      this.vendorcontactService
        .getContactInfo(this.contactdata.guid)
        .subscribe((data) => {
          this.contactInfo = data.object;
          this._spinner.hide();
        });
    } else {
      this.headerText = "Add Contact";
      this.buttonText = "Save";
      this.contactInfo = this.contactdata;
    }
  }

  onSubmit(form) {
    if (form.valid) {
      if (this.contactInfo.guid !== undefined) {
        this.vendorcontactService.putContactInfo(this.contactInfo).subscribe(
          (result) => this.result(result, "Updated Successfully!!"),
          (error) => this.error(error)
        );
        this.updateData.emit(true);
      } else {
        this.vendorcontactService
          .postVendorContacts(this.OrgVendorId, this.contactInfo)
          .subscribe(
            (result) => this.result(result, "Added Successfully!!"),
            (error) => this.error(error)
          );
      }
    }
  }
  onCancel() {
    this.updateData.emit(false);
  }
  private error(error: any) {
    switch (error.status) {
      case 409:
        this.notificationsService.add({
          severity: "warn",
          summary: "Contact",
          detail: "This Contact already exists",
        });
        this._spinner.hide();
        break;
      default:
        break;
    }
  }

  private result(res: any, message: string) {
    this.updateData.emit(true);
    this.notificationsService.add({
      severity: "success",
      summary: "Contact",
      detail: message,
    });
    this._spinner.hide();
  }
}
