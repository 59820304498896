import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MPBGenericCardComponent } from "./CardUI/MPBGenericCard.component";
import { MPBCardWidgetAddressComponent } from "./CardUI/MPBCardWidgetAddress.component";
import { MPBUserListComponent } from "./Widgets/MPBUserList/MPBUserList.component";
import { MPBCardHeaderComponent } from "./CardUI/mpbcard-header/mpbcard-header.component";
import { MPBCardStatusComponent } from "./CardUI/mpbcard-status/mpbcard-status.component";
import { MPBCardWidgetUserListComponent } from "./CardUI/mpbcard-widget-user-list/mpbcard-widget-user-list.component";
import { MPBCardWidgetActionComponent } from "./CardUI/mpbcard-widget-action/mpbcard-widget-action.component";
import { MPBCardWidgetAgeComponent } from "./CardUI/mpbcard-widget-age/mpbcard-widget-age.component";

@NgModule({
  imports: [CommonModule],
  declarations: [
    MPBGenericCardComponent,
    MPBCardWidgetAddressComponent,
    MPBUserListComponent,
    MPBCardHeaderComponent,
    MPBCardStatusComponent,
    MPBCardWidgetUserListComponent,
    MPBCardWidgetActionComponent,
    MPBCardWidgetAgeComponent,
  ],
  exports: [MPBGenericCardComponent, MPBCardStatusComponent],
})
export class MPBGenericUIModule {}
