import { Injectable } from "@angular/core";
import { JobPhasModel } from "app/model/jobPhaseModel";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RequestForServiceService {
  private _jobPhaseDetails: BehaviorSubject<JobPhasModel> = new BehaviorSubject(
    null
  );
  get jobPhaseDetails(): Observable<JobPhasModel> {
    return this._jobPhaseDetails.asObservable();
  }

  setJobPhaseDetails(jobPhase: JobPhasModel) {
    this._jobPhaseDetails.next(jobPhase);
  }

  private _hasBeenUpdated: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  get hasBeenUpdated(): Observable<boolean> {
    return this._hasBeenUpdated.asObservable();
  }

  constructor() {}

  updateAndReset() {
    this._hasBeenUpdated.next(true);
    this._hasBeenUpdated.next(false);
  }
}
