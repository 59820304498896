import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import {
  ParameterGroup,
  ParameterGroupAddRemove,
  ParameterGroupComplex,
  ParameterGroupParameter,
  ParameterGroupWorAreaAddRemove,
  ParameterGroupWorkArea,
  ParameterGroupsVisibleParameters,
} from "app/model/ParameterGroupModel";

@Injectable({ providedIn: "root" })
export class ParameterGroupService {
  constructor(private http: HttpClient) {}

  getParameterGroupsByOrganization(
    orGuid: string,
    returnDefault: boolean = false
  ): Observable<ParameterGroupComplex[]> {
    return this.http.get<ParameterGroupComplex[]>(
      `${environment.baseEstimateUrl}ParameterGroup/${orGuid}?returnDefault=${returnDefault}`
    );
  }

  addRemoveParameterGroupParameter(data: ParameterGroupAddRemove) {
    return this.http.post(
      `${environment.baseEstimateUrl}ParameterGroupParameter`,
      data
    );
  }

  removeParameterGroupParameter(data: ParameterGroupParameter) {
    return this.http.put(
      `${environment.baseEstimateUrl}ParameterGroupParameter`,
      data
    );
  }

  updateParameterGroupParameterOrder(data: ParameterGroupParameter[]) {
    return this.http.put(
      `${environment.baseEstimateUrl}ParameterGroupParameter/bulk`,
      data
    );
  }

  addParameterGroup(data: ParameterGroup) {
    return this.http.post(`${environment.baseEstimateUrl}ParameterGroup`, data);
  }

  updateParameterGroup(data: ParameterGroup) {
    return this.http.put(`${environment.baseEstimateUrl}ParameterGroup`, data);
  }

  getParameterGroupsByWorkArea(
    workAreaId: number
  ): Observable<ParameterGroupComplex[]> {
    return this.http.get<ParameterGroupComplex[]>(
      `${environment.baseEstimateUrl}WorkAreaParameterGroup/${workAreaId}`
    );
  }

  addRemoveParameterGroupWorArea(data: ParameterGroupWorAreaAddRemove) {
    return this.http.post(
      `${environment.baseEstimateUrl}WorkAreaParameterGroup`,
      data
    );
  }

  removeParameterGroupWorkArea(data: ParameterGroupWorkArea) {
    return this.http.put(
      `${environment.baseEstimateUrl}WorkAreaParameterGroup`,
      data
    );
  }

  updateParameterGroupWorkAreaOrder(data: ParameterGroupWorkArea[]) {
    return this.http.put(
      `${environment.baseEstimateUrl}WorkAreaParameterGroup/bulk`,
      data
    );
  }

  deleteParameterGroup(parameterGroupId: number) {
    return this.http.delete(
      `${environment.baseEstimateUrl}ParameterGroup/${parameterGroupId}`
    );
  }

  getParameterGroupsByWorkAreasIds(
    workAreasIds: number[]
  ): Observable<ParameterGroupComplex[]> {
    return this.http.post<ParameterGroupComplex[]>(
      `${environment.baseEstimateUrl}ParameterGroup/ByWorkAreasId`,
      workAreasIds
    );
  }

  addRemoveVisibleParameterGroupWorArea(
    data: ParameterGroupsVisibleParameters
  ) {
    return this.http.post(
      `${environment.baseEstimateUrl}WorkAreaParameterGroupVisible`,
      data
    );
  }
}
