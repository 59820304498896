import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { BuidPlanDialogComponent } from "app/component/buildPlan-dialog/buildPlan-dialog.component";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { TextBoxModule } from "@progress/kendo-angular-inputs";

@NgModule({
  imports: [CommonModule, FormsModule, DropDownsModule, TextBoxModule],
  declarations: [BuidPlanDialogComponent],
  exports: [BuidPlanDialogComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BuilPlanDialogModule {}
