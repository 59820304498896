import { FormGroup } from "@angular/forms";
import { Helper } from "app/common/utility/helper";
import * as _ from "lodash";
import { SelectItem } from "primeng/primeng";
import { Subject } from "rxjs";
import { Calculator } from "./CalculatorErrorsData";
import { EstimateBudgetActual } from "./EstimateBudgetActual";
import { EstimateProductModel } from "app/model/EstimateProductModel";
import { KitDetails } from "./KitDetails";
import { MaterialCalculator } from "./MaterialsCalculator";
import { ProductType } from "./ProductType";
import { WorksheetDetailChange } from "./WorksheetDetailChange";

export interface IWorksheetDetailFromDB {
  id: number;
  estimateWorksheetId: number;
  productId: number;
  qty: number;
  unitPrice: number;
  projectPackageDetailId?: number;
  productOptionId?: number;
  kitDetailId?: number;
  useKitPricing: boolean;
  worksheetDetailIdParent?: number;
  requiresUserSelection: boolean;
  estimateBudgetCost: number;
  estimateBudgetPriceBookDetailId?: number;
  preferredVendorPrice?: number;
  preferredVendorPriceBookDetailId?: number;
  budgetVendorPrice?: number;
  budgetVendorPriceBookDetailId?: number;
  unitOfMeasure: string;
  materialsCalculatorId?: number;
  needsRecalculation: boolean;
  createdBy: string;
  createdOn: string;
  lastUpdatedBy: string;
  lastUpdatedOn: string;
  deletedOn: string;
  isTaxExempt: boolean;

  product: EstimateProductModel;
  productOption: EstimateProductModel;
  worksheetDetailChange: WorksheetDetailChange;
  materialsCalculator: MaterialCalculator;
  kitDetail: KitDetails;
  calculator: Calculator;

  photos: string[];
  warranties: string[];
  disclaimers: string[];

  tax: number;
  priceWithoutTax: number;
  priceWithTax: number;
  showAsLineItem: boolean;
  showPricing: boolean;
  KitGroupId?: number;
  kitGroupName: string;
  estimateViewGroupIds: number[];

  worksheetDetailChildren: IWorksheetDetailFromDB[];
}

export class WorksheetDetailFromDB implements IWorksheetDetailFromDB {
  id: number;
  estimateWorksheetId: number;
  productId: number;
  qty: number;
  unitPrice: number;
  projectPackageDetailId?: number;
  productOptionId?: number;
  kitDetailId?: number;
  useKitPricing: boolean;
  worksheetDetailIdParent?: number;
  requiresUserSelection: boolean;
  estimateBudgetCost: number;
  estimateBudgetPriceBookDetailId?: number;
  preferredVendorPrice?: number;
  preferredVendorPriceBookDetailId?: number;
  budgetVendorPrice?: number;
  budgetVendorPriceBookDetailId?: number;
  unitOfMeasure: string;
  materialsCalculatorId?: number;
  needsRecalculation: boolean;
  createdBy: string;
  createdOn: string;
  lastUpdatedBy: string;
  lastUpdatedOn: string;
  deletedOn: string;
  productTypeId: number;

  product: EstimateProductModel;
  productOption: EstimateProductModel;
  worksheetDetailChange: WorksheetDetailChange;
  materialsCalculator: MaterialCalculator;
  kitDetail: KitDetails;
  calculator: Calculator;

  photos: string[];
  warranties: string[];
  disclaimers: string[];

  tax: number;
  priceWithoutTax: number;
  priceWithTax: number;
  priceBeforeTaxWithMarkup: number;
  priceWithTaxAndMarkup: number;
  showAsLineItem: boolean;
  showPricing: boolean;
  KitGroupId?: number;
  kitGroupName: string;
  estimateViewGroupIds: number[];

  estimateBudgetActual?: EstimateBudgetActual;

  productName?: string;
  vendorCost: number;
  totalCostActuals?: number;
  isOptional: boolean;
  worksheetDetailChildren: WorksheetDetailFromDB[];
  optionalDetail: WorksheetOptionalDetail;
  userDescription: string;
  isTaxExempt: boolean;
  excludePriceAdjustment: boolean;
  get realUnitPrice(): number {
    return this.priceWithoutTax / this.qty;
  }
  adjustedPrice?: AdjustedPrice;

  constructor() {}

  static createObj(target): WorksheetDetailFromDB {
    const obj = _.merge(new WorksheetDetailFromDB(), target);

    // The productOption that comes from the back does not have all the information of calculator
    // Here we are using the productOption from product since it has full information of calculator
    if (obj.productOption) {
      const tempProdOpt = obj.product.productOptions.find(
        (x) => x.id === obj.productOptionId
      );
      obj.productOption = tempProdOpt || obj.productOption;
    }

    if (
      target.worksheetDetailChildren &&
      target.worksheetDetailChildren.length > 0
    ) {
      obj.worksheetDetailChildren = target.worksheetDetailChildren.map((x) =>
        this.createObj(x)
      );
    }

    return obj;
  }

  static removeUnplublishedChildren(target: IWorksheetDetailFromDB): void {
    const newChildren = target.worksheetDetailChildren.filter(
      (x) => x.product.published
    );
    newChildren.forEach((x) =>
      WorksheetDetailFromDB.removeUnplublishedChildren(x)
    );
    target.worksheetDetailChildren = newChildren;
  }

  static flat(
    target: WorksheetDetailFromDB | WorksheetDetailFromDB[]
  ): WorksheetDetailFromDB[] {
    let tempArray;

    if (Array.isArray(target)) {
      tempArray = target;
    } else {
      tempArray = [target];
    }

    return tempArray.reduce((acc, x) => {
      acc = acc.concat(x);
      if (x.worksheetDetailChildren) {
        acc = acc.concat(WorksheetDetailFromDB.flat(x.worksheetDetailChildren));
        x.worksheetDetailChildren = [];
      }
      return acc;
    }, []);
  }

  hasChildren(): boolean {
    return (
      this.worksheetDetailChildren && this.worksheetDetailChildren.length > 0
    );
  }

  get isKit(): boolean {
    return (
      this.product.productTypeId === ProductType.ComplexKit ||
      this.product.productTypeId === ProductType.SimpleKit
    );
  }

  isChildren(): boolean {
    return this.kitDetailId && !!this.kitDetail;
  }

  hasProductOptions(): boolean {
    return (
      this.product &&
      this.product.productOptions &&
      this.product.productOptions.length > 0
    );
  }

  /**
   * Calculated way to know if a product needs user selection
   * @returns
   */
  needsUserSelection(): boolean {
    if (this.hasProductOptions() && !this.productOption) {
      return true;
    } else if (this.hasChildren()) {
      return this.worksheetDetailChildren.some((x) => x.needsUserSelection());
    } else {
      return false;
    }
  }

  getParametersIdsFromFormula(): number[] {
    if (this.materialsCalculator && this.materialsCalculator.id !== 0) {
      const formula = this.materialsCalculator.formula;
      const parametersIds: number[] = Helper.stringToLettersArray(
        formula,
        "%"
      ).map((x) => +x);

      return parametersIds;
    } else {
      return [];
    }
  }

  wasCalculatorDeleted(): boolean {
    if (!this.calculator) {
      return false;
    }

    const wasDeleted =
      this.calculator.hasErrorInMaterialCalculator &&
      this.calculator.hasErrorInMaterialCalculator.isDeleted;

    if (wasDeleted) {
      return true;
    } else if (this.hasChildren()) {
      return this.worksheetDetailChildren.some((x) => x.wasCalculatorDeleted());
    } else {
      return false;
    }
  }

  /**
   * Gives the preffered vendor price for the worksheet detail
   * @returns
   */
  getPreferredVendorPrice(): number {
    let preferredVendorPrice = 0;
    if (this.hasChildren()) {
      this.worksheetDetailChildren.forEach((x) => {
        preferredVendorPrice += x.getPreferredVendorPrice() * x.qty;
      });
    } else {
      preferredVendorPrice = this.preferredVendorPrice;
    }

    return preferredVendorPrice;
  }

  /**
   * Sets the preffered vendor price for the worksheet detail and all its children
   */
  setPreferredVendorPrice(): void {
    this.preferredVendorPrice = this.getPreferredVendorPrice();

    if (this.hasChildren()) {
      this.worksheetDetailChildren.forEach((x) => x.setPreferredVendorPrice());
    }
  }

  /**
   * Gives the preffered vendor price for the worksheet detail
   * @returns
   */
  getBudgetVendorPrice(): number {
    let budgetVendorPrice = 0;
    if (this.hasChildren()) {
      this.worksheetDetailChildren.forEach((x) => {
        budgetVendorPrice += x.getBudgetVendorPrice() * x.qty;
      });
    } else {
      budgetVendorPrice = this.budgetVendorPrice;
    }

    return budgetVendorPrice;
  }

  /**
   * Sets the preffered vendor price for the worksheet detail and all its children
   */
  setBudgetVendorPrice(): void {
    this.budgetVendorPrice = this.getBudgetVendorPrice();

    if (this.hasChildren()) {
      this.worksheetDetailChildren.forEach((x) => x.setBudgetVendorPrice());
    }
  }
}

export class WorksheetDetailForModal extends WorksheetDetailFromDB {
  // Price that is shown in the modal
  priceToShow: number;
  // Current price that changes when the user selects another productOption
  currentPricePerUnit: number;

  showItem: boolean = true;
  productOptionsMap: SelectItem[];
  form?: FormGroup;
  parent?: WorksheetDetailForModal;
  nodeLevel?: number;
  updateQtyInput: Subject<boolean>;
  originalQty: number;
  productName: string;
  canEditCost: boolean;
  canEditProductName: boolean;

  // Overiding property type
  worksheetDetailChildren: WorksheetDetailForModal[];

  constructor() {
    super();
  }

  static createObj(
    target,
    parent?: WorksheetDetailForModal,
    nodeLevel?: number
  ): WorksheetDetailForModal {
    const obj: WorksheetDetailForModal = _.merge(
      new WorksheetDetailForModal(),
      target
    );

    if (obj.productOption) {
      const tempProdOpt: any = obj.product.productOptions.find(
        (x) => x.id === obj.productOptionId
      );
      obj.productOption = tempProdOpt || obj.productOption;
    }

    obj.priceToShow = obj.estimateBudgetCost;
    obj.currentPricePerUnit = obj.estimateBudgetCost;
    obj.updateQtyInput = new Subject();
    obj.originalQty = obj.qty;
    if (parent) {
      obj.parent = parent;
    }

    // Assing the node level. First node level is 0, and root object doesn not have a nodeLevel
    if (nodeLevel) {
      obj.nodeLevel = nodeLevel;
    } else {
      nodeLevel = 0;
    }

    if (
      target.worksheetDetailChildren &&
      target.worksheetDetailChildren.length > 0
    ) {
      obj.worksheetDetailChildren = target.worksheetDetailChildren.map((x) =>
        this.createObj(x, obj, nodeLevel)
      );
    }
    return obj;
  }

  setProductOptionMap(): void {
    if (this.product.productOptions && this.product.productOptions.length > 0) {
      this.productOptionsMap = this.product.productOptions.map(
        (productOption: any) => {
          return {
            label: productOption.name,
            value: { ...productOption },
          };
        }
      );

      this.productOptionsMap.unshift({
        label: "Select an Option",
        value: null,
      });
    }

    if (
      this.worksheetDetailChildren &&
      this.worksheetDetailChildren.length > 0
    ) {
      this.worksheetDetailChildren.forEach((item: WorksheetDetailForModal) => {
        item.setProductOptionMap();
      });
    }
  }

  /**
   * Gives total price of the product and its children
   * Qyt of the parent is included in the total
   * Qtys can be from the form or from the objs data
   * Include Qty is to include the qty of the product in the calculation
   * If it is false, the total would be unitPrice of that product
   * @returns totalPrice
   */
  getTotalPrice(
    useFormDataInstead: boolean = false,
    includeQty: boolean = true
  ): number {
    let total = 0;
    let qtyToUse = this.qty;

    // We use  form data only if the data exists in the obj, this.qty is by default in case of error
    if (useFormDataInstead && this.form && this.form.get("productQty")) {
      qtyToUse = this.form.get("productQty").value;
    } else if (useFormDataInstead) {
      console.info(
        "FormGroup is not stored in the obj. Default qty of obj was used instead",
        this
      );
    }

    // Not considering unpublished products on the total
    if (!this.product.published) {
      return total;
    }

    if (this.useKitPricing || !this.hasChildren()) {
      total += this.currentPricePerUnit;
    } else {
      let tempPricePerUnit = 0;
      this.worksheetDetailChildren.forEach((children) => {
        const childrenTotalPrice = children.getTotalPrice(useFormDataInstead);
        tempPricePerUnit += childrenTotalPrice;
        total += childrenTotalPrice;
      });
      // In case this is a kit, the currentPricePerUnit is the total of its children
      this.currentPricePerUnit = tempPricePerUnit;
    }

    if (includeQty) {
      return total * qtyToUse;
    } else {
      return total;
    }
  }

  /**
   * RECURSIVE Updates the priceToSHow of the product and all its children
   * @param useFormDataInstead
   */
  updatePriceToShow(useFormDataInstead: boolean = false): void {
    // This is nodeLevel 0, all prices must be unit Prices, but the rest of the levels, price depends on qty
    if (
      this.product.productTypeId === ProductType.SimpleKit ||
      this.hasProductOptions()
    ) {
      const computedPrice = this.getTotalPrice(useFormDataInstead, false);
      this.priceToShow = computedPrice;
    } else {
      this.priceToShow = this.estimateBudgetCost;
    }

    if (this.hasChildren()) {
      this.worksheetDetailChildren.forEach((x) =>
        x.updatePriceToShow(useFormDataInstead)
      );
    }
  }

  /**
   * Cleans the inner objects that are not needed in the backend
   */
  cleanInnerObject(): void {
    this.product = null;
    this.productOption = null;
    this.productOptionsMap = null;
    this.materialsCalculator = null;
    this.kitDetail = null;
    this.calculator = null;
    this.form = null;
    this.parent = null;
    this.updateQtyInput = null;
    this.originalQty = null;

    if (this.worksheetDetailChildren) {
      this.worksheetDetailChildren.forEach((child) => child.cleanInnerObject());
    }
  }

  /**
   * Checks if the product or any of its children has errors
   * Mostly related to calculator errors
   * @returns hasError
   */
  hasErrors(): boolean {
    if (
      this.calculator &&
      this.calculator.value !== 0 &&
      !this.calculator.value
    ) {
      return true;
    } else if (
      this.product.productEstimateExt &&
      this.product.productEstimateExt.calculator &&
      this.product.productEstimateExt.calculator.value !== 0 &&
      !this.product.productEstimateExt.calculator.value
    ) {
      return true;
    } else if (
      this.kitDetailId &&
      this.kitDetail.calculator &&
      this.kitDetail.calculator.value !== 0 &&
      !this.kitDetail.calculator.value
    ) {
      return true;
    } else if (
      this.productOption &&
      this.productOption.productEstimateExt &&
      this.productOption.productEstimateExt.calculator &&
      this.productOption.productEstimateExt.calculator.value === null
    ) {
      return true;
    } else if (this.hasChildren()) {
      return this.worksheetDetailChildren.some((children) =>
        children.hasErrors()
      );
    } else {
      return false;
    }
  }

  hasErrorsByWorkArea(): boolean {
    const inWorksheetDetail =
      this.calculator &&
      this.calculator.value === null &&
      this.calculator.hasErrorInWorkAreaParameter;

    const inProduct =
      this.product.productEstimateExt &&
      this.product.productEstimateExt.calculator &&
      this.product.productEstimateExt.calculator.value === null &&
      this.product.productEstimateExt.calculator.hasErrorInWorkAreaParameter;

    const inKitDetail =
      this.kitDetail &&
      this.kitDetail.calculator &&
      this.kitDetail.calculator.value === null &&
      this.kitDetail.calculator.hasErrorInWorkAreaParameter;

    let inChildren = false;

    if (this.hasChildren()) {
      inChildren = this.worksheetDetailChildren.some((x) =>
        x.hasErrorsByWorkArea()
      );
    }

    return !!inWorksheetDetail || !!inProduct || !!inKitDetail || inChildren;
  }

  getProducOptionSelected(): void {
    if (!this.productOptionId || !this.productOptionsMap) {
      return null;
    } else {
      const option = this.productOptionsMap.find(
        (x) => x.value && x.value.id === this.productOptionId
      );
      return option ? option.value : null;
    }
  }

  getAllMaterialsCalculatorsUsed(): number[] {
    const objToUse = _.cloneDeep(this);
    const flatted = WorksheetDetailFromDB.flat(objToUse);
    const calculators = [];

    flatted.forEach((x) => {
      if (
        x.product.productEstimateExt &&
        x.product.productEstimateExt.materialsCalculatorId &&
        x.product.productEstimateExt.useCalculator
      ) {
        calculators.push(x.product.productEstimateExt.materialsCalculatorId);
      } else if (x.kitDetail && x.kitDetail.materialsCalculatorId) {
        calculators.push(x.kitDetail.materialsCalculatorId);
      }
    });

    return calculators;
  }

  /**
   * Gives the materialCalculatorId that must be used for the product.
   * It does not condier errors in the calculator
   * @returns materialCalculatorId
   */
  getMaterialCalculatorIdToUsed(): number {
    // For Product Option
    if (this.productOptionId) {
      const productOptionCalculator =
        this.productOption.productEstimateExt &&
        this.productOption.productEstimateExt.materialsCalculatorId &&
        this.productOption.productEstimateExt.useCalculator
          ? this.productOption.productEstimateExt.calculator
              .materialCalculatorId
          : null;

      return productOptionCalculator;
    }
    // For Kit Item
    else if (this.kitDetailId && this.kitDetail.materialsCalculatorId) {
      const kitCalculator =
        this.kitDetail && this.kitDetail.materialsCalculatorId
          ? this.kitDetail.materialsCalculatorId
          : null;
      return kitCalculator;
    }
    // For product (item alone)
    else {
      const productCalculator =
        this.product.productEstimateExt &&
        this.product.productEstimateExt.materialsCalculatorId &&
        this.product.productEstimateExt.useCalculator
          ? this.product.productEstimateExt.materialsCalculatorId
          : null;
      return productCalculator;
    }
  }

  /**
   * Sets the qty and the materialCalculatorId for the object
   * based on the productOption selected, the kitDetail calculator
   * or the product calculator. If there are errors, qty keeps with the current value
   */
  setQtyToUseAndCalculatorId(): void {
    if (this.productOption) {
      const hasValidCalculator =
        this.productOption.productEstimateExt &&
        this.productOption.productEstimateExt.calculator &&
        this.productOption.productEstimateExt.calculator.value !== null;

      if (hasValidCalculator) {
        this.qty = this.productOption.productEstimateExt.calculator.value;
        this.materialsCalculatorId =
          this.productOption.productEstimateExt.calculator.materialCalculatorId;
      }
    } else if (this.kitDetailId && this.kitDetail.materialsCalculatorId) {
      const hasValidCalculator =
        this.kitDetail &&
        this.kitDetail.calculator &&
        this.kitDetail.calculator.value !== null;

      if (hasValidCalculator) {
        this.qty = this.kitDetail.calculator.value;
        this.materialsCalculatorId =
          this.kitDetail.calculator.materialCalculatorId;
      }
    } else {
      const hasValidCalculator =
        this.product.productEstimateExt &&
        this.product.productEstimateExt.calculator &&
        this.product.productEstimateExt.calculator.value !== null;

      if (hasValidCalculator) {
        this.qty = this.product.productEstimateExt.calculator.value;
        this.materialsCalculatorId =
          this.product.productEstimateExt.calculator.materialCalculatorId;
      }
    }
  }

  setShowPricing(setForChildren: boolean): void {
    const showFromProduct =
      !this.product.productWorksheetViewGroup ||
      this.product.productWorksheetViewGroup.some(
        (x) => x.showPricing === true
      ) ||
      this.product.productWorksheetViewGroup.length === 0;

    // worksheetFeatureId 3 means that kitDetails price is visible
    // For more details about it, see DB table
    const showFromKit =
      this.kitDetailId &&
      this.kitDetail &&
      this.kitDetail.worksheetFeatureId === 3;

    if (this.kitDetailId === null) {
      this.showPricing = showFromProduct;
    } else {
      this.showPricing = showFromKit;
    }

    if (setForChildren && this.hasChildren()) {
      this.worksheetDetailChildren.forEach((x) => x.setShowPricing(true));
    }
  }

  setShowItem(): void {
    const showItem = !(
      this.kitDetail && this.kitDetail.worksheetFeatureId === 1
    );
    this.showItem = showItem;
    if (this.hasChildren()) {
      this.worksheetDetailChildren.forEach((x) => x.setShowItem());
    }
  }
}

export class WorksheetDetailForTable extends WorksheetDetailFromDB {
  parameterPending: boolean;
  calculatorDeleted: boolean;
  userSelection: boolean;
  displayable?: boolean;
  displayablePrice?: boolean;
  productName: string;
  // Overiding property type
  worksheetDetailChildren: WorksheetDetailForTable[];

  constructor() {
    super();
  }

  static createObj(target): WorksheetDetailForTable {
    const obj = _.merge(new WorksheetDetailForTable(), target);

    if (
      target.worksheetDetailChildren &&
      target.worksheetDetailChildren.length > 0
    ) {
      obj.worksheetDetailChildren = target.worksheetDetailChildren.map((x) =>
        this.createObj(x)
      );
    }
    return obj;
  }
}

export interface DetailTableItem extends WorksheetDetailFromDB {
  groupId?: number;
  groupName?: string;
  originalQty?: number;
  originalPrice?: number;
  currentQty?: number;
  currentPrice?: number;
  groupDisplayOrder?: number;
  isChild?: boolean;
  productOptionName?: string;
  taxable?: string;
}

export interface WorksheetOptionalDetail {
  id: number;
  worksheetId: number;
  worksheetDetailId: number;
  isOptional: boolean;
  deletedOn?: Date;
}

export interface AdjustedPrice {
  markupPrice: number;
  marginPrice: number;
}
