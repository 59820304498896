import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppTranslationModule } from "app/app.translation.module";
import { GridModule } from "@progress/kendo-angular-grid";

import { MPBUIOrderGridComponent } from "./mpbuiorder-grid/mpbuiorder-grid.component";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";

@NgModule({
  declarations: [MPBUIOrderGridComponent],
  imports: [GridModule, CommonModule, AppTranslationModule, DateInputsModule],
  exports: [MPBUIOrderGridComponent],
})
export class MPBUIOrderModule {}
