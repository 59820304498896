import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "environments/environment";
import { EstimateViewType } from "app/model/EstimateViewType";
import { MPBStatusModel } from "app/model/MPBStatusModel";
import { Observable } from "rxjs";
import { EstimateCustomerModel } from "app/model/EstimateCustomerModel";

@Injectable({
  providedIn: "root",
})
export class DropdownService {
  private baseUrl = environment.baseEstimateUrl;

  constructor(private httpService: HttpClient) {}

  getManufacturersForDropdown(orgGuid: string) {
    const queryParams = new HttpParams().set("isActive", "true");
    return this.httpService.get(`${this.baseUrl}${orgGuid}/manufacturers`, {
      params: queryParams,
    });
  }

  getProductTypesForDropdown() {
    return this.httpService.get(`${this.baseUrl}producttypes`);
  }

  getUnitsOfMeasureForDropdown(orgGuid: string) {
    return this.httpService.get(`${this.baseUrl}unitsofmeasure/${orgGuid}`);
  }

  getTaxClassesForDropdown(orgGuid: string) {
    return this.httpService.get(`${this.baseUrl}taxes/${orgGuid}`);
  }

  getCategoriesForDropdown(orgGuid: string) {
    return this.httpService.get(`${this.baseUrl}categories/${orgGuid}`);
  }

  getEmployeesForDropdown() {
    return this.httpService.get(`${this.baseUrl}customers/employees/dropdown`);
  }

  getEstimateViewsForDropdown(orgGuid: string) {
    return this.httpService.get(
      `${this.baseUrl}estimates/views/dropdown/${orgGuid}`
    );
  }

  getVendorsForDropdown(orgGuid: string, productId: number) {
    return this.httpService.get(
      `${this.baseUrl}Vendor/dropdown/${orgGuid}/${productId}`
    );
  }

  getPhasesForDropdown(orgGuid: string) {
    return this.httpService.get(`${this.baseUrl}phases/dropdown/${orgGuid}`);
  }

  getEstimateViewGroup(orgGuid: string) {
    return this.httpService.get(
      `${this.baseUrl}estimates/groups/dropdown/${orgGuid}`
    );
  }
  /** get estimate view types */
  getEstimateViewTypes() {
    return this.httpService.get<EstimateViewType[]>(
      `${this.baseUrl}estimateviewtypes`
    );
  }
  /** Get mps status for dropdown */
  getMpbStatusForDropdown() {
    return this.httpService.get<MPBStatusModel[]>(
      `${this.baseUrl}mpbstatus/dropdown`
    );
  }

  getCustomersSignersDropdown(
    estimateGuid: string,
    shouldReturnSalesPersons: boolean
  ): Observable<EstimateCustomerModel[]> {
    return this.httpService.get<EstimateCustomerModel[]>(
      `${this.baseUrl}customers/signers/${estimateGuid}/dropdown/${shouldReturnSalesPersons}`
    );
  }
}
