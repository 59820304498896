import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ImageViewerService } from "app/common/services/image-viewer.service";
import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";
import { takeWhile } from "rxjs/operators";

@Component({
  selector: "image-viewer",
  templateUrl: "./image-viewer.component.html",
  styleUrls: ["./image-viewer.component.scss"],
})
export class ImageViewerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("imageViewer", { static: false }) imagesOnDom: ElementRef;
  @Input() currentIndex: number = 0;
  @Output() close: EventEmitter<null> = new EventEmitter<null>();
  imageUrls: string[] = [];
  viewer: Viewer = null;
  private alive: boolean = true;

  constructor(private imageViewerService: ImageViewerService) {}

  ngOnInit() {
    this.subscribe();
  }

  ngAfterViewInit(): void {
    this.render();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  /**Subsribe to image viewer service */
  private subscribe() {
    this.imageViewerService.images
      .pipe(takeWhile(() => this.alive))
      .subscribe((imgs) => {
        this.imageUrls = imgs;
      });
  }

  /** Render viewer */
  private render() {
    const closer = this.close;
    this.viewer = new Viewer(this.imagesOnDom.nativeElement, {
      zIndex: 99000,
      initialViewIndex: this.currentIndex,
      transition: false,
      title: false,
      hidden(event) {
        closer.emit();
      },
    });
    this.viewer.show(true);
  }

  /** Destroy viewer and unsubscribe */
  private destroy() {
    this.viewer.destroy();
    this.alive = false;
  }
}
