import { Injectable } from "@angular/core";

@Injectable()
export class BaThemeSpinner {
  private _selector: string = "preloader";
  private _element: HTMLElement;

  constructor() {
    this._element = document.getElementById(this._selector);
  }

  public show(): void {
    var ele = document.getElementById(this._selector);
    ele.style["display"] = "block";
  }

  public hide(delay: number = 0): void {
    setTimeout(() => {
      var ele = document.getElementById(this._selector);
      ele.style["display"] = "none";
      //this._element.style['display'] = 'none';
    }, delay);
  }
}
