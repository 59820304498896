import { Injectable } from "@angular/core";
import { EditorComponent } from "@tinymce/tinymce-angular";

@Injectable()
export class TextEditorConfigService {
  /** Configuration for TinyMCE editor. */
  readonly editorConfig: EditorComponent["init"] = {
    selector: "textarea",
    base_url: "/tinymce",
    suffix: ".min",
    menubar: "format",
    contextmenu: false,
    height: "100%",
    content_style:
      "#tinymce { min-height: 500px; line-height: 1.2 } p { margin: 10px 0; }",
    browser_spellcheck: true,
    toolbar:
      "undo redo | alignleft alignright aligncenter | bold italic | bullist numlist | forecolor | fontsizeinput | link image code | removeformat",
    plugins: "link image code lists",
    file_picker_types: "image",
    paste_data_images: true,
    automatic_uploads: true,
    toolbar_mode: "sliding",
    resize: true,
    file_picker_callback: (cb) => {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.onchange = () => {
        const file = input.files[0];
        const reader = new FileReader();
        reader.onload = () => {
          cb(reader.result.toString());
          input.remove();
        };
        reader.readAsDataURL(file);
      };
      input.click();
    },
    setup: (editor) => {
      editor.on("drop", (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length) {
          const file = files[0];
          const reader = new FileReader();
          reader.onload = (event) => {
            const base64 = (event.target as FileReader).result.toString();
            editor.insertContent(`<img src="${base64}" />`);
          };
          reader.readAsDataURL(file);
        }
      });
    },
  };
}
