import { Injectable } from "@angular/core";
import { HttpService } from "app/common/utility/http.service";
import { environmentConstant } from "app/common/utility/environment";
import { Observable } from "rxjs";
import { PunchListModel, PunchListModelV2 } from "app/model/punchListModel";
import { JobPhasModel } from "app/model/jobPhaseModel";
import { SharedService } from "app/common/utility/SharedService";

@Injectable()
export class PunchListService {
  constructor(
    private httpService: HttpService,
    private sharedService: SharedService
  ) {}
  getPunchListByJobID(jobId: any): Observable<JobPhasModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getBuildPlanByJobID + jobId + "/punchlistitems"
    );
  }
  getPunchListByJobIDV2(jobId: any): Observable<JobPhasModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.v2 +
        environmentConstant.getBuildPlanByJobID +
        jobId +
        "/punchlistitems"
    );
  }
  getPunchList(): Observable<any> {
    return this.httpService.getRequestService(
      "punchlist/organization/" + this.sharedService.selectedOrganization.ID
    );
  }
  getPunchListV2(): Observable<any> {
    return this.httpService.getRequestService(
      "punchlist/organization/" +
        this.sharedService.selectedOrganization.ID +
        "/v2"
    );
  }
  setPunchlistDummy(punchlist: PunchListModelV2) {
    return this.httpService.postRequestService("punchlist/dummy", punchlist);
  }

  GetPunchlistItemById(lineItemId: any): Observable<PunchListModelV2> {
    return this.httpService.getRequestService("punchlist/" + lineItemId);
  }

  deletePunchlistItem(lineItemId: any): any {
    return this.httpService.deleteRequestService("punchlist/" + lineItemId);
  }

  updatePunchlistV2(isadd: boolean, punchlistV2: PunchListModelV2): any {
    return this.httpService.putRequestService(
      "punchlist?firstPass=" + isadd,
      punchlistV2
    );
  }
  getPunchListItemsByJobID(jobId: any): Observable<PunchListModelV2[]> {
    return this.httpService.getRequestService("punchlist/job/" + jobId);
  }
}
