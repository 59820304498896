import { Injectable } from "@angular/core";
import { environmentConstant } from "app/common/utility/environment";
import { HttpService } from "app/common/utility/http.service";
import { JObChangeOrderModel } from "app/model/jobChangeOrderModel";
import { JobChangeOrderNoteModel } from "app/model/jobChangeOrderNote";
import { JobPhotoPaginationModel } from "app/model/jobPhotoModel";
import { from, Observable } from "rxjs";
import { map, mergeMap } from "rxjs/operators";
import { v4 as uuidv4 } from "uuid";
import {
  ChangeOrdersFilter,
  FilteredSearchModel,
} from "../../model/SearchModel";
import { EnumJobFileTypes } from "../directives/commonEnum";
import { ApiResponseModel } from "../../model/ApiResponseModel";
import { Pager } from "../../model/Pager";
import { MPBStatusModel } from "../../model/MPBStatusModel";
import { ChangeOrderModel } from "../../model/Core/JobChangeOrderModel";
// tslint:disable:prefer-template

@Injectable()
export class JobChangeOrderService {
  constructor(private httpService: HttpService) {}

  getJobChangeOrdersByJobID(jobId: any): Observable<JObChangeOrderModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getJobChangeOrdersByJobID + jobId + "/changeorder"
    );
  }
  getJobChangeOrdersByID(
    orderId: any,
    includeEstimateWorksheet: boolean = false
  ): Observable<JObChangeOrderModel> {
    let url =
      environmentConstant.getJobChangeOrdersByJobID + "changeorder/" + orderId;
    if (includeEstimateWorksheet) url = url + "?includeEstimateWorksheet=true";
    return this.httpService.getRequestService(url);
  }
  updateChangeOrder(chengeOrder: JObChangeOrderModel) {
    return this.httpService.putRequestService(
      environmentConstant.getJobChangeOrdersByJobID + "changeorder/",
      chengeOrder
    );
  }
  addNewNoteToChangeOrder(note: JobChangeOrderNoteModel) {
    return this.httpService.postRequestService(
      environmentConstant.getJobChangeOrdersByJobID + "changeorder/note",
      note
    );
  }
  updateNoteToChangeOrder(note: JobChangeOrderNoteModel) {
    return this.httpService.putRequestService(
      environmentConstant.getJobChangeOrdersByJobID + "changeorder/note",
      note
    );
  }
  deleteChangeOrderNote(noteId: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.getJobChangeOrdersByJobID +
        "changeorder/note/" +
        noteId
    );
  }
  deleteChangeOrder(orderId: any) {
    return this.httpService
      .deleteRequestService(
        environmentConstant.getJobChangeOrdersByJobID + "changeorder/" + orderId
      )
      .pipe(map((res) => res));
  }
  saveChangeOrder(
    chengeOrder: JObChangeOrderModel,
    createDrawSchedule,
    liveScheduling: boolean
  ) {
    return this.httpService.postRequestService(
      environmentConstant.getJobChangeOrdersByJobID +
        "changeorder?createDrawSchedule=" +
        createDrawSchedule +
        "&liveScheduling=" +
        liveScheduling,
      chengeOrder
    );
  }
  getChangeOrderByOrgID(orgId: any) {
    return this.httpService.getRequestService(
      "organization/" + orgId + "/changeorder"
    );
  }
  getChangeOrderNotes(changeOrderId) {
    return this.httpService.getRequestService(
      environmentConstant.getJobChangeOrdersByJobID +
        "changeorder/" +
        changeOrderId +
        "/notes"
    );
  }
  uploadChangeOrderFile(
    orderId,
    fileType,
    jobPhaseId: number,
    formData,
    annotate: boolean = true,
    isPhoto: boolean = true
  ) {
    return this.httpService.UploadRequestService(
      "jobs/changeorder/" +
        orderId +
        "/filetype/" +
        fileType +
        "/jobPhaseId/" +
        jobPhaseId +
        `?annotate=${annotate}&isPhoto=${isPhoto}`,
      formData
    );
  }
  deleteChangeOrderFile(fileId) {
    return this.httpService.deleteRequestService(
      "jobs/changeorder/file/" + fileId
    );
  }
  getChangeOrderFile(
    orderId,
    currnetPage: any,
    pageSize: any,
    searchText: string = ""
  ): Observable<JobPhotoPaginationModel> {
    return this.httpService.getRequestService(
      "jobs/changeorder/" +
        orderId +
        "/files?page=" +
        currnetPage +
        "&pageSize=" +
        pageSize +
        "&search=" +
        searchText
    );
  }
  getChangeOrderPhoto(
    orderId,
    currnetPage: any,
    pageSize: any
  ): Observable<JobPhotoPaginationModel> {
    return this.httpService.getRequestService(
      "jobs/changeorder/" +
        orderId +
        "/photos?page=" +
        currnetPage +
        "&pageSize=" +
        pageSize
    );
  }

  getBlob(id: any): any {
    return this.httpService.getBlobRequestService(
      environmentConstant.getBlobModelUrl + id
    );
  }

  deleteChangeOrderFiles(ids: number[]) {
    const params = ids.map((x) => `ids=${x}&`).join("");
    return this.httpService.deleteRequestService(
      "jobs/changeorder/files?" + params
    );
  }

  postNewVersion(
    newDataUrl: string,
    originalFileName: string,
    changeOrderId: number
  ) {
    var promise = fetch(newDataUrl).then((res) => res.blob());
    return from(promise).pipe(
      mergeMap((blob) => {
        var formData = new FormData();
        formData.append(
          "uploadFile",
          blob,
          `annotation${uuidv4()}__${originalFileName}`
        );
        return this.uploadChangeOrderFile(
          changeOrderId,
          EnumJobFileTypes.UserPhotos,
          null,
          formData,
          false
        );
      })
    );
  }

  searchChangeOrders(
    orgGuid: string,
    model: FilteredSearchModel<ChangeOrdersFilter>
  ): Observable<ApiResponseModel<Pager<ChangeOrderModel>>> {
    return this.httpService.post(
      environmentConstant.coreApi.searchChangeOrders(orgGuid),
      model
    );
  }

  getChangeOrdersStatuses(): Observable<ApiResponseModel<MPBStatusModel[]>> {
    return this.httpService.get(environmentConstant.coreApi.getCOStatuses());
  }
}
