import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { KitDetailsService } from "app/common/services/kitdetails.service";
import { SharedService } from "app/common/utility/SharedService";
import { EstimateProductModel } from "app/model/EstimateProductModel";
import { KitGroups } from "app/model/KitDetails";
import { BaThemeSpinner } from "app/theme";
import { SelectItem } from "primeng/primeng";

@Component({
  selector: "app-group-selection-modal",
  templateUrl: "./group-selection-modal.component.html",
})
export class GroupSelectionModalComponent implements OnInit {
  product: EstimateProductModel;
  groups: KitGroups[];
  groupsForm: FormGroup;
  groupSelectItems: SelectItem[];

  constructor(
    private kitDetailsService: KitDetailsService,
    private sharedService: SharedService,
    private formBuilder: FormBuilder,
    private spinner: BaThemeSpinner,
    private dialogRef: MatDialogRef<GroupSelectionModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    data: {
      product: EstimateProductModel;
    }
  ) {
    this.product = data.product;
  }

  ngOnInit() {
    this.groupsForm = this.formBuilder.group({
      groupsControl: new FormControl(null, [Validators.required]),
    });
    this.kitDetailsService
      .getAllKitGroups(this.sharedService.selectedOrganization.ID)
      .subscribe((data) => {
        this.groups = data as KitGroups[];
        this.configGroupSelectItems();
      })
      .add(() => this.spinner.hide());
  }

  configGroupSelectItems() {
    this.groups.unshift({
      groupName: "Select Group",
      id: null,
      displayOrder: 0,
    });
    this.groupSelectItems = this.groups.map((val) => ({
      label: val.groupName,
      value: val.id,
    }));
  }

  selectProduct() {
    if (!this.groupsForm.valid) {
      return;
    }
    let groupSelected = this.groupsForm.get("groupsControl").value;
    let grupo = this.groupSelectItems.find((x) => x.value === groupSelected);
    this.product.group = groupSelected;
    this.product.groupName = grupo.label;
    this.dialogRef.close(this.product);
  }
}
