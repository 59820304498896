import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { JobPhaseModel } from "app/model/Core/JobPhaseModel";
import { JobTemplatePhasModel } from "app/model/JobTemplatePhasModel";
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
export interface ConfirmModel {
  title: string;
  message: string;
  phases: JobTemplatePhasModel[];
  toPhases: JobTemplatePhasModel[];
  fromPhase: JobTemplatePhasModel;
  toPhase: JobTemplatePhasModel;
  startWorkflow: boolean;
  toShow?: boolean;
  isNotification?: boolean;
}

@Component({
  selector: "dialog-move-phase",
  templateUrl: "./dialog-move-phase.component.html",
  styleUrls: ["./dialog-move-phase.component.scss"],
})
export class DialogMovePhaseComponent
  extends DialogComponent<ConfirmModel, any>
  implements ConfirmModel {
  title: string;
  message: string;
  message2: string =
    "Promoting this phase will create a Job in WIP. To continue click done or to demote select a phase from the drop down.";
  phases: JobTemplatePhasModel[];
  toPhases: JobTemplatePhasModel[];
  fromPhase: JobTemplatePhasModel;
  toPhase: JobTemplatePhasModel;
  startWorkflow: boolean;
  toShow?: boolean = false;
  isNotification?: boolean = false;
  changeDetectorRef: ChangeDetectorRef;
  constructor(
    dialogService: DialogService,
    changeDetectorRef: ChangeDetectorRef
  ) {
    super(dialogService);
    this.changeDetectorRef = changeDetectorRef;
    const that = this;
    setTimeout(function () {
      that.changeDetectorRef.detectChanges();
    }, 300);
  }
  confirm() {
    // we set dialog result as true on click on confirm button,
    // then we can get dialog result from caller code
    this.result = {
      result: true,
      toPhase: this.toPhase,
    };
    this.close();
  }
  editSave() {
    this.result = {
      result: false,
      toPhase: this.toPhase,
    };
    this.close();
  }

  changeToPhaseChanged(e) {
    this.toPhase = e;
  }
}
