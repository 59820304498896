import { Component, OnInit, Input } from "@angular/core";
import { ShippingService } from "app/common/services/Core/Shipping.service";
import { FormGroup } from "@angular/forms";
import { ShippingModel } from "app/model/Core/ShippingModel";
import { MessageService } from "primeng/components/common/messageservice";

@Component({
  selector: "edit-shipping-component",
  templateUrl: "./edit-shipping.component.html",
})
export class EditShippingComponent implements OnInit {
  isEditValue: any;
  formGroup: FormGroup;
  orgShippingMethods: ShippingModel[];
  editedRowIndex: number;

  @Input() ID: string;
  @Input() shippingMethods: ShippingModel[];

  constructor(
    private shippingService: ShippingService,
    private notificationService: MessageService
  ) {}

  ngOnInit() {}

  editHandler({ sender, rowIndex, dataItem }) {
    this.isEditValue = Object.assign({}, dataItem);
    this.closeEditor(sender);
    this.editedRowIndex = rowIndex;
    sender.editRow(rowIndex);
  }

  cancelEdit({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  cancelHandler({ sender, rowIndex }) {
    if (rowIndex >= 0) {
      const val = this.isEditValue;
      const method = this.shippingMethods.find((x) => x.guid === val.guid);
      method.rate = val.rate;
      method.active = val.active;
    }
    this.closeEditor(sender, rowIndex);
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
  }

  saveHandler({ sender, rowIndex, dataItem }) {
    this.shippingService.editShippingOptions(dataItem).subscribe(
      (result) => {
        this.result(result, "Updated Successfully!!");
        sender.closeRow(rowIndex);
        this.editedRowIndex = undefined;
      },
      (error) => this.error(error)
    );
    sender.closeRow(rowIndex);
    this.editedRowIndex = undefined;
  }

  private error(error: any) {
    switch (error.status) {
      case 409:
        this.notificationService.add({
          severity: "warn",
          summary: "Shipping Method",
          detail: "Shipping Method Error!!",
        });
        break;
      default:
        break;
    }
  }
  private result(res: any, message: string) {
    this.notificationService.add({
      severity: "success",
      summary: "Shipping Method",
      detail: message,
    });
  }
}
