<div
  class="estimate-theme mat-dialog catalog-picker__modal catalog-picker__modal--kit product-dialog"
>
  <h1 matDialogTitle class="estimate-typography-pop-up-titles">
    {{ selectorLabel }}
  </h1>
  <h3 class="mb-2 product-name-title" *ngIf="!canEdit">
    {{ rootObject.productName }}
  </h3>
  <h3
    *ngIf="
      rootObject.product.sku &&
      (rootObject.product.productTypeId == ProductType.SimpleKit ||
        rootObject.product.productTypeId == ProductType.ComplexKit)
    "
    class="product-name-sku"
  >
    SKU#: {{ rootObject.product.sku }}
  </h3>
  <mat-dialog-content class="content">
    <form [formGroup]="productForm">
      <div
        *ngIf="
          rootObject.worksheetDetailChildren &&
          rootObject.worksheetDetailChildren.length > 0
        "
        class="workarea-fields"
      >
        <div>
          <div class="input-pentair-std input-pentair-workarea">
            <label> Qty </label>
            <input
              type="number"
              formControlName="productQty"
              class="mt-3 p-2 w-100"
              decimalFormat
              [decimals]="0"
              [negative]="false"
              [maxSize]="6"
              [allowZero]="false"
            />
          </div>
        </div>
        <div
          *ngIf="
            rootObject.product.productTypeId == ProductType.SimpleKit ||
            rootObject.product.productTypeId == ProductType.ComplexKit
          "
          class="product-kit-total"
        >
          Total
          <span>{{
            totalPriceProduct | currency : "USD" : "symbol" : "1.2-2"
          }}</span>
        </div>
      </div>
      <ng-container
        *ngIf="
          !(
            rootObject.product.productTypeId == ProductType.SimpleKit ||
            rootObject.product.productTypeId == ProductType.ComplexKit
          );
          else kits
        "
      >
        <p class="mb-3" *ngIf="!rootObject.materialsCalculatorId && !canEdit">
          Item/Labor/Service quantities can only be positive. Decimals can be
          allowed or not depending on the Value Type of the Unit of Measure.
          This can be configured in the Units of Measure module.
        </p>
      </ng-container>
      <ng-template #kits>
        <ng-container
          *ngIf="
            rootObject.product.productTypeId == ProductType.SimpleKit;
            else complex
          "
        >
          <p class="mt-3 mb-2">
            Simple Kit quantities can only be positive, no decimals are allowed.
          </p>
          <p class="mb-3">
            If the editable quantity feature is enabled
            <i
              class="fa fa-info-circle green-color"
              pTooltip="This feature can be enabled in the Catalog (per product) in the Kit Details Tab."
              tooltipPosition="bottom"
              tooltipStyleClass="tooltip"
            ></i>
            , product quantities within the Simple Kit can be positive or
            negative, including decimals
            <i
              class="fa fa-info-circle green-color"
              pTooltip="Decimal values can be allowed or not, depending on the Value Type of the Unit of Measure. This can be configured in the Units of Measure module."
              tooltipPosition="bottom"
              tooltipStyleClass="tooltip"
            ></i>
            . No zeros are allowed.
          </p>
        </ng-container>
      </ng-template>
      <ng-template #complex>
        <p class="mt-3">
          Complex Kit quantities can only be positive, negative, or zero. No
          decimals are allowed.
        </p>
        <p class="my-2">
          If the editable quantity feature is enabled
          <i
            class="fa fa-info-circle green-color"
            pTooltip="This feature can be enabled in the Catalog (per product) in the Kit Details Tab."
            tooltipPosition="bottom"
            tooltipStyleClass="tooltip"
          ></i>
          , product quantities within the Complex Kit can be positive or zero,
          including decimals
          <i
            class="fa fa-info-circle green-color"
            pTooltip="Decimal values can be allowed or not, depending on the Value Type of the Unit of Measure. This can be configured in the Units of Measure module."
            tooltipPosition="bottom"
            tooltipStyleClass="tooltip"
          ></i>
          . No negatives are allowed.
        </p>
        <p class="mb-3">
          Simple kit quantities within the Complex Kit can be positive or zero.
          No negatives or decimals are allowed. However, quantities for items
          within the Simple Kit can be positive or negative, including decimals
          <i
            class="fa fa-info-circle green-color"
            pTooltip="Decimal values can be allowed or not, depending on the Value Type of the Unit of Measure. This can be configured in the Units of Measure module."
            tooltipPosition="bottom"
            tooltipStyleClass="tooltip"
          ></i>
          . No zero quantities are allowed.
        </p>
      </ng-template>
      <div *ngIf="showDuplicateWarning" class="row warning-row">
        <div class="col col-lg-12 col-md-12 col-sm-12 warning-col">
          <p class="mb-3 warning-title">
            Warning: This product is already in the estimate
          </p>
          <p class="mb-3">
            This action will add an additional line item to the estimate. If you
            just want to add more of this product to the estimate, consider
            updating the quantity on the original product.
          </p>
        </div>
      </div>
      <!-- List Of Children -->
      <div class="margin-children" formArrayName="childrenProducts">
        <div *ngIf="canEdit">
          <ng-template
            ngFor
            let-product
            [ngForOf]="productObject"
            let-i="index"
            [ngTemplateOutlet]="itemOnTableTemplate"
            [ngTemplateOutletContext]="{
              node: product,
              index: 0,
              formForTemplate: productForm.get('childrenProducts').get('' + 0),
              childrenNodeLevel: 0,
              parentProduct: rootObject
            }"
          >
          </ng-template>
        </div>
        <div
          class="simple-kit__scroll-table-container"
          [ngClass]="{
            'simple-kit__scroll-table-container-edit': mode == 1,
            'simple-kit__scroll-table-container-review': mode == 3
          }"
        >
          <p-table
            *ngIf="!canEdit"
            [value]="productObject"
            sortField="group.id"
            sortMode="single"
            rowGroupMode="subheader"
            (onSort)="onSort()"
          >
            <ng-template pTemplate="header" *ngIf="showHeader">
              <tr>
                <th class="width-10 align-right">Qty</th>
                <th class="width-8"></th>
                <th class="width-10 align-right">SKU</th>
                <th class="">Product Name</th>
                <th class="width-20 align-right">Unit Price</th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-product let-rowIndex="rowIndex">
              <!-- Rendering the groupHeader if needed -->
              <ng-container
                *ngIf="
                  rowGroupMetadata &&
                  product.kitDetail?.kitGroupDetail?.kitGroup?.id &&
                  rowGroupMetadata[product.kitDetail.kitGroupDetail.kitGroup.id]
                    .index === rowIndex
                "
              >
                <tr class="complex-kit-data-intercalated">
                  <td
                    class="width-8 estimate-worksheet-complex-headers-blue estimate-title-group"
                  >
                    <span style="font-weight: bold">{{
                      product.kitDetail.kitGroupDetail.kitGroup.groupName
                    }}</span>
                  </td>
                  <td
                    class="width-8 estimate-worksheet-complex-headers-blue"
                  ></td>
                  <td
                    class="width-10 estimate-worksheet-complex-headers-blue"
                  ></td>
                  <td class="estimate-worksheet-complex-headers-blue"></td>
                  <td
                    class="width-20 estimate-worksheet-complex-headers-blue"
                  ></td>
                </tr>
                <tr class="ui-widget-header complex-kit-headers">
                  <th class="width-8 align-right">Qty</th>
                  <th class="width-8"></th>
                  <th class="width-10 align-right">SKU</th>
                  <th class="">Product Name</th>
                  <th class="width-20 align-right">Unit Price</th>
                </tr>
              </ng-container>
              <!-- Initiate the recursive template rendering -->
              <ng-template
                *ngIf="FilterQuantities ? product.qty > 0 : true"
                [ngTemplateOutlet]="itemOnTableTemplate"
                [ngTemplateOutletContext]="{
                  node: product,
                  index: rowIndex,
                  formForTemplate: productForm
                    .get('childrenProducts')
                    .get('' + rowIndex),
                  childrenNodeLevel: 0,
                  parentProduct: rootObject
                }"
              >
              </ng-template>
            </ng-template>
          </p-table>
        </div>
      </div>
      <div
        *ngIf="
          !(
            rootObject.product.productTypeId == ProductType.SimpleKit ||
            rootObject.product.productTypeId == ProductType.ComplexKit
          )
        "
      >
        <div
          class="form-container__row"
          *ngIf="
            (!rootObject.materialsCalculatorId && !canEdit) ||
            rootObject.materialsCalculatorId
          "
        >
          <div class="form-container__row--items width-100">
            <div class="textarea-pentair-std">
              <label>Add Custom Description</label>
              <textarea
                pInputTextarea
                class="width-100 product-detail-description"
                rows="14"
                autoResize="false"
                placeholder="Enter Description"
                formControlName="userDescription"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="rootObject.product.productTypeId == ProductType.SimpleKit">
        <div class="form-container__row">
          <div class="form-container__row--items width-100">
            <div class="textarea-pentair-std">
              <label>Add Custom Description</label>
              <textarea
                pInputTextarea
                class="width-100 product-detail-description"
                rows="14"
                autoResize="false"
                placeholder="Enter Description"
                formControlName="userDescription"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="rootObject.product.productTypeId == ProductType.ComplexKit">
        <div class="form-container__row">
          <div class="form-container__row--items width-100">
            <div class="textarea-pentair-std">
              <label>Add Custom Description</label>
              <textarea
                pInputTextarea
                class="width-100 product-detail-description"
                rows="14"
                autoResize="false"
                placeholder="Enter Description"
                formControlName="userDescription"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions
    class="mat-dialog__actions mat-dialog__actions--align-right pb-2 mat-dialog__actions--sidebar"
    [ngClass]="
      rootObject.product.productTypeId == ProductType.ComplexKit ? 'pr-3' : ''
    "
  >
    <button class="button button--secondary" (click)="showSidebar()">
      Close
    </button>
    <button
      *ngIf="mode !== ProductSelectorMode.Review"
      class="button button--primary"
      (click)="sendProduct()"
      [disabled]="
        !productForm.valid ||
        !attachableToWorksheet ||
        isKitEmpty ||
        isDuplicate
      "
      [pTooltip]="errorMsg"
      tooltipZIndex="999999"
      tooltipPosition="left"
      tooltipStyleClass="tooltip error-tooltip"
      [tooltipDisabled]="!errorMsg"
    >
      {{
        mode == ProductSelectorMode.Add && !canEdit ? "Add Selection" : "Save"
      }}
    </button>
  </mat-dialog-actions>
</div>

<!-- MAIN RECURSIVE TEMPLATE -->
<!-- The main Recursive Template For each Product (Item, Simple, or Complex Kit) -->
<ng-template
  #itemOnTableTemplate
  let-product="node"
  let-index="index"
  let-formOnTemplate="formForTemplate"
  let-childrenNodeLevel="childrenNodeLevel"
  let-parentProduct="parentProduct"
>
  <ng-container [formGroup]="formOnTemplate">
    <div
      class="container-editable"
      *ngIf="canEdit && (FilterQuantities ? product.qty > 0 : true)"
    >
      <div class="row">
        <div class="col col-lg-12 col-md-12 col-sm-12">
          <div class="input-pentair-std w-100">
            <label class="padding-input">Product Name</label>
            <input formControlName="productName" type="text" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col col-lg-6 col-md-6 col-sm-12">
          <div class="input-pentair-std w-100">
            <label class="padding-input">Unit Cost</label>
            <input
              formControlName="price"
              type="text"
              currencyMask
              [options]="{
                prefix: '$',
                allowNegative: true
              }"
            />
          </div>
        </div>
        <div class="col col-lg-6 col-md-6 col-sm-12">
          <div class="col col-lg-6 col-md-6 col-sm-12">
            <div class="input-pentair-std w-100">
              <label class="padding-input">QTY</label>
              <input formControlName="productQty" type="text" />
            </div>
          </div>
          <div class="col col-lg-6 col-md-6 col-sm-12"></div>
        </div>
      </div>
      <div class="form-container__row">
        <div class="form-container__row--items width-100">
          <div class="textarea-pentair-std">
            <label>Add Custom Description</label>
            <textarea
              pInputTextarea
              class="width-100 product-detail-description"
              rows="14"
              autoResize="false"
              placeholder="Enter Description"
              formControlName="userDescription"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
    <tr
      *ngIf="
        !canEdit &&
        (!rootObject.worksheetDetailChildren ||
          rootObject.worksheetDetailChildren.length === 0 ||
          (childrenNodeLevel === 0 && product.showItem) ||
          product.kitDetail?.editableQty ||
          product.product.productOptions?.length > 0)
      "
      [ngClass]="[
        product.productOptionsMap && product.productOptionsMap.length > 0
          ? 'product-selector-data-modal'
          : '',
        childrenNodeLevel > 0 ? 'child-complex-row' : ''
      ]"
    >
      <!-- Quantity -->
      <td style="min-width: 200px" class="align-right">
        <!-- Identation for Children -->
        <div class="d-flex layout-flex-end" *ngIf="childrenNodeLevel < 1">
          <app-product-qty-input
            style="transform: translateX(1em)"
            [product]="product"
            [parentProduct]="parentProduct"
            [rootObject]="rootObject"
            [mode]="mode"
          ></app-product-qty-input>
        </div>
      </td>
      <!-- Quantity -->
      <td style="min-width: 200px" class="align-right">
        <!-- Identation for Children -->
        <div class="d-flex layout-flex-end" *ngIf="childrenNodeLevel > 0">
          <app-product-qty-input
            [product]="product"
            [parentProduct]="parentProduct"
            [rootObject]="rootObject"
            [mode]="mode"
          ></app-product-qty-input>
        </div>
      </td>
      <td style="min-width: 200px" class="product-sku">
        {{ product.product.sku }}
      </td>
      <td style="min-width: 200px">
        <span *ngIf="!product.canEditProductName">{{
          product.productName
        }}</span>
        <input
          *ngIf="product.canEditProductName"
          type="text"
          formControlName="productName"
        />
        <div
          *ngIf="
            product.productOptionsMap && product.productOptionsMap.length > 0
          "
          class="dropdown-pentair-std layout-flex-end pb-2"
        >
          <div class="dropdown-pentair-std layout-flex-end pb-2">
            <p-dropdown
              [options]="product.productOptionsMap"
              [autoWidth]="false"
              appentTo="body"
              styleClass="prime-dropdown w-100 align-left"
              (onChange)="handleProductOptionChange($event, product)"
              formControlName="productOptions"
            ></p-dropdown>
          </div>
        </div>
      </td>
      <td
        [ngClass]="product.canEditCost ? 'w-20' : 'mw-200px'"
        class="align-right"
      >
        <span *ngIf="!!product.showPricing && !product.canEditCost">
          {{ product.priceToShow | currency : "USD" : "symbol" : "1.2-2" }}
        </span>
        <input
          type="text"
          *ngIf="product.canEditCost"
          formControlName="price"
          class="w-100"
          currencyMask
          [options]="{
            prefix: '$',
            allowNegative: true
          }"
        />
      </td>
    </tr>

    <!-- Product Options -->
    <!-- <tr
      *ngIf="
        product.productOptionsMap && product.productOptionsMap.length > 0
      "
      class="product-selector-dropdown-modal"
    >
      <td></td>
      <td></td>
      <td class="align-right">
        <div class="dropdown-pentair-std layout-flex-end pb-2">
          <p-dropdown
            [options]="product.productOptionsMap"
            [autoWidth]="false"
            appentTo="body"
            styleClass="prime-dropdown w-100 align-left"
            (onChange)="handleProductOptionChange($event, product)"
            formControlName="productOptions"
          ></p-dropdown>
        </div>
      </td>
      <td></td>
    </tr> -->
  </ng-container>

  <!-- List Of Children
    - Using Recursive Template -->
  <ng-container
    *ngIf="
      !canEdit &&
      product.worksheetDetailChildren &&
      product.worksheetDetailChildren.length > 0
    "
    class="childrenProducts"
  >
    <ng-template
      ngFor
      let-childProduct
      [ngForOf]="product.worksheetDetailChildren"
      let-childIndex="index"
    >
      <ng-template
        [ngTemplateOutlet]="itemOnTableTemplate"
        [ngTemplateOutletContext]="{
          node: childProduct,
          index: childIndex,
          formForTemplate: formOnTemplate
            .get('childrenProducts')
            .get('' + childIndex),
          childrenNodeLevel: childrenNodeLevel + 1,
          parentProduct: product
        }"
      >
      </ng-template>
    </ng-template>
  </ng-container>
</ng-template>
