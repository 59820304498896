import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { TextBoxModule } from "@progress/kendo-angular-inputs";
import { CommonModule } from "@angular/common";
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { CurrencyMaskModule } from "ng2-currency-mask";
import {
  DataGridModule,
  EditorModule,
  InputTextModule,
  SidebarModule,
  PanelModule,
  InputMaskModule,
} from "primeng/primeng";
import { AppTranslationModule } from "app/app.translation.module";
import { LayoutModule } from "@progress/kendo-angular-layout";
import { GridModule, ExcelModule } from "@progress/kendo-angular-grid";
import { DragulaModule } from "ng2-dragula";
import { Routes, RouterModule } from "@angular/router";
import { JobFileService } from "app/common/services/jobFile.service";
import { JobPhotoService } from "app/common/services/jobphoto.service";
import { JobNoteService } from "app/common/services/jobNotes.service";
import { ArchwizardModule } from "angular-archwizard";
import { ColorPickerModule } from "ngx-color-picker";
import { CommonService } from "app/common/services/common.service";
import { CommonDataService } from "app/common/services/commonData.service";
import { AssignResourceComponent } from "./AssignResource.component";
import { CommonComponentModule } from "../commoncomponent.module";
import { SharedModule } from "app/shared/shared.module";
import { NgaModule } from "app/theme/nga.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DropDownsModule,
    TextBoxModule,
    DateInputsModule,
    CurrencyMaskModule,
    DataGridModule,
    AppTranslationModule,
    LayoutModule,
    GridModule,
    ExcelModule,
    InputTextModule,
    DragulaModule,
    SidebarModule,
    RouterModule,
    PanelModule,
    NgaModule,
    SharedModule,
    InputMaskModule,
    ArchwizardModule,
    ColorPickerModule,
    EditorModule,
    CommonComponentModule,
  ],
  declarations: [AssignResourceComponent],
  exports: [AssignResourceComponent],
  providers: [
    JobFileService,
    JobPhotoService,
    JobNoteService,
    CommonService,
    CommonDataService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AssignResourceModule {}
