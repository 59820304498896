<p-toast class="general-toast" position="top-right"></p-toast>
<div class="widgets commonFixedSidebar">
  <div class="row">
    <div class="col-md-12 ht100">
      <div class="rightcontent">
        <div class="sideBarHeader">
          <h1>{{headerText}} {{content}}</h1>
        </div>
        <div class="tabBlk">
          <form
            class="form-horizontal"
            #myForm="ngForm"
            *ngIf="customer!=undefined"
          >
            <div class="fixed-layout hoverflow">
              <div class="d-flex justify-content-between align-items-center">
                <div class="form-group w-50 m-0">
                  <div class="formfield m-0">
                    <div class="checkbox m-0">
                      <label class="k-form-field">
                        <input
                          type="checkbox"
                          id="Disable"
                          name="Disable"
                          class="k-checkbox"
                          [(ngModel)]="customer.Disable"
                        />
                        <span class="k-checkbox-label" for="Disable"
                          >Login disabled</span
                        >
                      </label>
                    </div>
                  </div>
                </div>
                <div class="id" *ngIf="customer.Id && customer.Id>0">
                  ID : {{customer.Id}}
                </div>
              </div>
              <div class="mt-4 mb-2" *ngIf="!isUser">
                <div class="form-group mb-0">
                  <span class="ui-float-label">
                    <input
                      type="text"
                      id="Company"
                      name="Company"
                      pInputText
                      [(ngModel)]="customer.Company"
                      #Company="ngModel"
                      class="form-control"
                    />
                    <label for="text" class="form-control-label"
                      >Company Name</label
                    >
                  </span>
                </div>
              </div>
              <br />
              <contacts-tabbed-entry
                [model]="customer"
                [jobType]="0"
                [type]="type"
              ></contacts-tabbed-entry>

              <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="isUser">
                  <div class="form-group mt-4 mb-3">
                    <kendo-floatinglabel text="Business Unit">
                      <kendo-multiselect
                        [data]="lstBusinessUnit"
                        [filterable]="true"
                        [textField]="'BusinessUnit1'"
                        [valueField]="'ID'"
                        (filterChange)="filterBussinessUnit($event)"
                        name="businessUnits"
                        id="businessUnits"
                        name="businessUnits"
                        #businessUnits="ngModel"
                        [ngClass]="{'has-error':businessUnits.invalid && (businessUnits.dirty || businessUnits.touched)}"
                        [(ngModel)]="customer.BusinessUnits"
                      ></kendo-multiselect>
                    </kendo-floatinglabel>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12" *ngIf="isUser">
                  <div class="form-group Mandat mb-0">
                    <kendo-floatinglabel text="Roles">
                      <kendo-multiselect
                        [data]="customerRole"
                        [filterable]="true"
                        [textField]="'Name'"
                        [valueField]="'Id'"
                        (filterChange)="filterRoles($event)"
                        name="customerRoles"
                        id="customerRoles"
                        name="customerRoles"
                        #customerRoles="ngModel"
                        [ngClass]="{'has-error':customerRoles.invalid && (customerRoles.dirty || customerRoles.touched)}"
                        [(ngModel)]="customer.CustomerRoles"
                        required
                        (valueChange)="validateSelection($event)"
                      ></kendo-multiselect>
                    </kendo-floatinglabel>
                  </div>
                </div>
              </div>

              <div *ngIf="isEmployee" class="dropdown-pentair-std my-3">
                <label class="fw-bold" for="reportsToId">Reports To</label>
                <p-dropdown
                  [options]="employeesOpts"
                  [(ngModel)]="customer.ReportsToId"
                  name="reportsToId"
                  filter="true"
                  appendTo="body"
                  styleClass="prime-dropdown w-50"
                ></p-dropdown>
              </div>
              <h3 class="pt mb-2 mt-2" *ngIf="!isQuickAdd">Preferences</h3>
              <div class="d-flex" *ngIf="!isQuickAdd">
                <div class="form-group mb-0">
                  <div class="checkbox m-0">
                    <label class="k-form-field">
                      <input
                        type="checkbox"
                        id="LiveView"
                        name="LiveView"
                        [(ngModel)]="customer.Preference.LiveView"
                        class="k-checkbox"
                      />
                      <span class="k-checkbox-label" for="LiveView"
                        >Live View</span
                      >
                    </label>
                  </div>
                </div>
                <div class="form-group mb-0">
                  <div class="checkbox m-0">
                    <label class="k-form-field">
                      <input
                        type="checkbox"
                        id="NotificationEmail"
                        name="NotificationEmail"
                        [(ngModel)]="customer.Preference.NotificationEmail"
                        class="k-checkbox"
                      />
                      <span class="k-checkbox-label" for="emailnoti"
                        >Email Notification</span
                      >
                    </label>
                  </div>
                </div>
                <div class="form-group mb-0">
                  <div class="checkbox m-0">
                    <label class="k-form-field">
                      <input
                        type="checkbox"
                        id="NotificationSMS"
                        name="NotificationSMS"
                        [(ngModel)]="customer.Preference.NotificationSMS"
                        class="k-checkbox"
                      />
                      <span class="k-checkbox-label" for="smsnoti"
                        >SMS Notification</span
                      >
                    </label>
                  </div>
                </div>
              </div>

              <!-- <div class="row mt-2" *ngIf="!isQuickAdd">
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <h3 class="pt">Customer Address</h3>
                </div>

                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="form-group">
                    <span class="ui-float-label" *ngIf="isContractor">
                      <input
                        type="text"
                        class="form-control"
                        id="address1"
                        name="address1"
                        pInputText
                        [(ngModel)]="customer.Address.Address1"
                        #Address1="ngModel"
                        [ngClass]="{'has-error':Address1.invalid && (Address1.dirty || Address1.touched)}"
                        maxlength="250"
                        required
                      />
                      <label for="text" class="form-control-label"
                        >Address
                        <span class="text-red">*</span>
                      </label>
                    </span>
                    <span class="ui-float-label" *ngIf="isUser">
                      <input
                        type="text"
                        class="form-control"
                        id="address1"
                        name="address1"
                        pInputText
                        [(ngModel)]="customer.Address.Address1"
                        #Address1="ngModel"
                        maxlength="250"
                      />
                      <label for="text" class="form-control-label"
                        >Address
                      </label>
                    </span>
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div class="form-group">
                    <span class="ui-float-label">
                      <input
                        type="text"
                        class="form-control"
                        id="address2"
                        name="address2"
                        pInputText
                        [(ngModel)]="customer.Address.Address2"
                        maxlength="250"
                      />
                      <label for="text" class="form-control-label"
                        >Subdivision/Area</label
                      >
                    </span>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                  <div class="form-group">
                    <span class="ui-float-label">
                      <input
                        type="text"
                        class="form-control"
                        id="city"
                        name="city"
                        pInputText
                        [(ngModel)]="customer.Address.City"
                        #city="ngModel"
                        [ngClass]="{'has-error':city.invalid && (city.dirty || city.touched)}"
                        pattern="([a-zA-Z0-9.]{1,100}\s*)+"
                        maxlength="250"
                      />
                      <label for="text" class="form-control-label">City</label>
                    </span>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                  <div class="form-group">
                    <kendo-textbox-container
                      floatingLabel="State"
                      [ngClass]="{'k-state-filled':true}"
                    >
                      <kendo-combobox
                        name="StateProvinceId"
                        [data]="stateList"
                        [valuePrimitive]="true"
                        [filterable]="true"
                        (filterChange)="filterState($event)"
                        [textField]="'Abbreviation'"
                        [(ngModel)]="customer.Address.StateProvinceId"
                        [valueField]="'Id'"
                        #StateProvinceId="ngModel"
                        [ngClass]="{'has-error':StateProvinceId.invalid && (StateProvinceId.dirty || StateProvinceId.touched)}"
                      >
                      </kendo-combobox>
                    </kendo-textbox-container>
                  </div>
                </div>

                <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                  <div class="form-group">
                    <span class="ui-float-label">
                      <p-inputMask
                        type="text"
                        id="zip"
                        name="zip"
                        trim="blur"
                        [(ngModel)]="customer.Address.ZipPostalCode"
                        mask="99999"
                        slotChar=" "
                        autoClear="false"
                        styleClass="form-control"
                      ></p-inputMask>
                      <label for="text" class="form-control-label">Zip</label>
                    </span>
                  </div>
                </div>
              </div> -->

              <div class="form-group mb-10"></div>

              <div class="col-sm-12" *ngIf="!isQuickAdd">
                <div class="row slidebg-adp2">
                  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div class="row">
                      <div class="col-lg-12 col-md-12 col-sm-12">
                        <h3 class="pt">Login Info</h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="form-group mt-1">
                      <span class="ui-float-label">
                        <input
                          type="text"
                          class="form-control"
                          id="userName"
                          name="userName"
                          minlength="6"
                          pInputText
                          [(ngModel)]="customer.UserName"
                          #userName="ngModel"
                          [ngClass]="{'has-error':userName.invalid && (userName.dirty || userName.touched)}"
                          (blur)="isValidUserName(customer.UserName,userName.valid)"
                          maxlength="250"
                          required
                          preventBackSlashAndDoubleQuote
                        />
                        <span
                          class="form-msg"
                          [ngClass]="isValidUserNameVal?'red':'green'"
                          *ngIf="isValidUserNameVal!==undefined"
                        >
                          <span *ngIf="isValidUserNameVal"
                            ><i class="fa fa-times" aria-hidden="true"></i
                          ></span>
                          <span *ngIf="!isValidUserNameVal">
                            <i class="fa fa-check" aria-hidden="true"></i
                          ></span>
                        </span>
                        <label for="text" class="form-control-label"
                          >User Name<span class="text-red">*</span></label
                        >
                      </span>
                      <span
                        class="red error-label-below"
                        *ngIf="isValidUserNameVal !== undefined && isUserNameTaken === true"
                        >Username already exists.</span
                      >
                    </div>
                  </div>
                </div>
                <div class="form-group mb-10"></div>
                <div *ngIf="isUser && isSuperAdmin">
                  <quickbooks-options
                    [identifier]="quickBooksEmployeeSearch?.identifier"
                    [form]="myForm"
                    [model]="customer"
                  ></quickbooks-options>
                  <quickbooks-search-employees
                    #quickBooksEmployeeSearch
                  ></quickbooks-search-employees>
                </div>

                <div *ngIf="isContractor && isSuperAdmin">
                  <quickbooks-options
                    [identifier]="quickBooksVendorSearch?.identifier"
                    [form]="myForm"
                    [model]="customer"
                  ></quickbooks-options>
                  <quickbooks-search-vendors
                    #quickBooksVendorSearch
                  ></quickbooks-search-vendors>
                </div>
              </div>
              <div class="h50"></div>
            </div>
            <div class="fixbtnwrap">
              <div class="btnwrap">
                <button
                  type="button"
                  (click)="onSubmit(myForm)"
                  class="btn darkbtn"
                  [disabled]="!myForm.valid || !isUsernameValid || hideSaveButton"
                  *ngIf="myForm.dirty"
                >
                  <i class="fa fa-floppy-o"></i> {{btnText}}
                </button>
                <button
                  type="button"
                  class="btn btn-cancel"
                  (click)="onCancel()"
                >
                  Close
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
