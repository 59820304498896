import { Injectable } from "@angular/core";

@Injectable()
export class PlaceholderConstants {
  public static thumbnailPlaceholder = {
    thumbnail: {
      blobData: "assets/images/poolblob.svg",
      guid: "error",
      fileName: "error",
      url: "error",
      createdOn: new Date(),
      deletedOn: new Date(),
      fileSize: 0,
      blobContainer: "error",
    },
  };
  public static thumbnailPlaceholderGrey = {
    thumbnail: {
      blobData: "assets/images/poolblobgrey.svg",
      guid: "error",
      fileName: "error",
      url: "error",
      createdOn: new Date(),
      deletedOn: new Date(),
      fileSize: 0,
      blobContainer: "error",
    },
  };
  public static thumbnailPlaceholderRedX = {
    thumbnail: {
      blobData: "assets/images/poolblobRedX.svg",
      guid: "error",
      fileName: "error",
      url: "error",
      createdOn: new Date(),
      deletedOn: new Date(),
      fileSize: 0,
      blobContainer: "error",
    },
  };
}
