import { Injectable } from "@angular/core";
import { DetailTableItem } from "app/model/WorksheetDetailObject";
import { BehaviorSubject, Observable } from "rxjs";
import { WorksheetTabsTypes } from "../../model/EstimateWorksheetProduct";
import { ListItem } from "app/model/Core/ListItemModel";
import { WorksheetEstimateViewValues } from "app/model/WorksheetEstimateViewValues";

@Injectable({
  providedIn: "root",
})
export class WorksheetGridTabDataService {
  private _tabData: BehaviorSubject<DetailTableItem[]> = new BehaviorSubject<
    DetailTableItem[]
  >(null);
  private _currentTab: BehaviorSubject<WorksheetTabsTypes> =
    new BehaviorSubject<WorksheetTabsTypes>(WorksheetTabsTypes.Current);

  private _currentView: BehaviorSubject<ListItem<WorksheetEstimateViewValues>> =
    new BehaviorSubject<ListItem<WorksheetEstimateViewValues>>(null);

  get tabData(): Observable<DetailTableItem[]> {
    return this._tabData.asObservable();
  }

  get currentTab(): Observable<WorksheetTabsTypes> {
    return this._currentTab.asObservable();
  }

  get currentView(): Observable<ListItem<WorksheetEstimateViewValues>> {
    return this._currentView.asObservable();
  }

  private tabDataStore: DetailTableItem[] = [];

  constructor() {}

  /** Resets tab data */
  resetData() {
    this.tabDataStore = [];
    this.sendData();
  }

  /** Determines how the data will be added and sends it */
  onNewDataHandler(data: DetailTableItem[]) {
    this.tabDataStore.forEach((item, index) => {
      const exists = data.some((x) => x.id == item.id);
      if (exists) {
        this.tabDataStore[index] = data.find((x) => x.id == item.id);
      }
    });

    const dataToAdd = data.filter(
      (i) => !this.tabDataStore.some((x) => x.id == i.id)
    );

    this.tabDataStore = [...this.tabDataStore, ...dataToAdd];

    this.sendData();
  }

  /** Listens to estimate worksheet tab changes */
  onTabChange(tab: WorksheetTabsTypes) {
    this._currentTab.next(tab);
  }

  /** Sets the data and send it to subscriptors */
  private sendData() {
    this._tabData.next(this.tabDataStore);
  }

  onCurrentViewChange(view: ListItem<WorksheetEstimateViewValues>) {
    this._currentView.next(view);
  }
}
