import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BaThemeSpinner } from "app/theme";
import { ComponentsRelevantChangesService } from "app/common/services/componentsRelevantChanges.service";
import { ComponentsRelevantChangesFromDb } from "app/model/ComponentsRelevantChanges";
import { Router } from "@angular/router";
import { ComponentTypesEnum } from "app/model/Core/ComponentTypesEnum";
import {
  EstimateWorksheetInfo,
  UpdateWorksheetStatusDTO,
  WorksheetStatus,
} from "app/model/EstimateWorksheetInfo";
import { ToastrService } from "ngx-toastr";
import { EstimateWorksheetService } from "app/common/services/estimate-worksheet.service";
import { forkJoin } from "rxjs";
import { ExpiredWorksheetsHistoryService } from "app/common/services/expired-worksheets-history.service";
import { ExpiredWorksheetsHistoryDTO } from "app/model/ExpiredWorksheetsHistoryModel";

@Component({
  selector: "app-update-calculation-modal",
  templateUrl: "./calculation-update-modal.component.html",
  styleUrls: ["./calculation-update-modal.component.scss"],
})
export class CalculationUpdateModal implements OnInit {
  @Input() worksheet: EstimateWorksheetInfo;
  @Input() openWorksheetOnNewTab: boolean;
  @Output() closeEvent: EventEmitter<null> = new EventEmitter<null>();
  @Output() updateSuccessEvent?: EventEmitter<null> = new EventEmitter<null>();
  relevantChanges: ComponentsRelevantChangesFromDb[];
  showCalculatorChangedMessage: boolean = false;

  readonly SOURCE_OF_CHANGE: string[] = [
    "Calculator",
    "Price Book",
    "Catalog",
    "Parameter",
    "WorkAreas Tab",
  ];

  constructor(
    private _spinner: BaThemeSpinner,
    private componentsChangesService: ComponentsRelevantChangesService,
    private router: Router,
    private toastrService: ToastrService,
    private estimateWorksheetService: EstimateWorksheetService,
    private expiredHistoryService: ExpiredWorksheetsHistoryService
  ) {}

  get ComponentType() {
    return ComponentTypesEnum;
  }

  get WorksheetStatusEnum() {
    return WorksheetStatus;
  }

  ngOnInit(): void {
    this._spinner.show();
    this.componentsChangesService
      .getChangesForWorksheet(this.worksheet.id)
      .subscribe((relevantChanges: ComponentsRelevantChangesFromDb[]) => {
        this.relevantChanges = relevantChanges;
        this.showCalculatorChangedMessage = relevantChanges.some(
          (item) => item.component === ComponentTypesEnum.Calculator
        );
        if (this.worksheet.isThereNewActivePriceBook) {
          this.relevantChanges.push({
            wasAccepted: false,
            id: 0,
            componentName: "Active Pricebook",
            component: ComponentTypesEnum.Pricebook,
            estimateWorksheetId: this.worksheet.id,
            changeDescription: "Changed",
          } as ComponentsRelevantChangesFromDb);
        }
      })
      .add(() => this._spinner.hide());
  }

  closeUpdateModal(): void {
    this.closeEvent.emit();
  }

  /**
   * Function called when the user accepts to update the worksheet with the changes
   */
  handleConfirmUpdates(): void {
    this._spinner.show();

    if (this.worksheet.isExpired) {
      this.activateWorksheet(this.worksheet.guid);
      return;
    }

    this.confirmChanges();
  }

  /**
   * Actives worksheet and triggers refresh date method
   * @param worksheetId worksheet Guid
   */
  private activateWorksheet(worksheetId: string) {
    this._spinner.show();
    const statusEntity: UpdateWorksheetStatusDTO = {
      id: 0,
      guid: worksheetId,
      status: this.WorksheetStatusEnum.Active,
    };

    this.estimateWorksheetService.updateStatus(statusEntity).subscribe(
      (data) => {
        this.refreshExpirationDate(worksheetId);
      },
      (error) =>
        this.handleError("An error ocurred updating the worksheet status")
    );
  }

  /**
   * Displays a toast when an error has ocurred
   */
  handleError(message: string): void {
    this.toastrService.error(message, "Worksheet Summary");
  }

  /**
   * Refresh expiration date and triggers the confirm changes method
   * @param worksheetId worksheet Guid
   */
  private refreshExpirationDate(worksheetId: string) {
    let services = [];
    let estimateId = this.worksheet.id;
    const entity: ExpiredWorksheetsHistoryDTO = {
      id: 0,
      worksheetId: estimateId,
      oldDate: this.worksheet.expirationDate,
      newDate: null,
    };

    services.push(this.expiredHistoryService.add(entity));
    services.push(
      this.estimateWorksheetService.refreshExpirationDate(worksheetId)
    );

    forkJoin(services).subscribe(
      (data) => {
        let worksheet = this.worksheet;
        worksheet.expirationDate = null;
        worksheet.isExpired = false;
        worksheet.worksheetStatus = this.WorksheetStatusEnum.Active;
        worksheet.needsRecalculation = false;
        this.confirmChanges();
      },
      (error) => {
        this.handleError("An error ocurred updating the expiration date");
        this._spinner.hide();
      }
    );
  }

  /**
   * Confirms all changes and updates
   */
  confirmChanges(): void {
    this.componentsChangesService
      .confirmAllTheChanges(this.worksheet.guid)
      .subscribe(() => {
        this.updateSuccessEvent.emit();
        if (this.openWorksheetOnNewTab) {
          window.open(`#/pages/estimate/worksheet/${this.worksheet.guid}`);
          this.closeUpdateModal();
        } else {
          this.router.navigateByUrl(
            `pages/estimate/worksheet/${this.worksheet.guid}`
          );
        }
      })
      .add(() => this._spinner.hide());
  }
}
