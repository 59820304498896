export enum EntityTypeEnum {
  None = 0,
  Product = 1,
  JobFile = 2,
  Category = 4,
  Vendor = 8,
  Employee = 16,
  Customer = 32,
  User = 64,
  Order = 128,
  Transaction = 256,
  ChangeOrder = 512,
  PaymentPlan = 1024,
  Invoice = 2048,
  RecurringInvoice = 4096,
  Job = 8192,
  JobPhase = 16384,
  Billing = 65536,
}
