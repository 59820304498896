import { ParameterGroupComplex } from "app/model/ParameterGroupModel";
import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
} from "@angular/forms";
import { WizardService } from "app/common/services/wizard.service";
import { PrincigTemplate } from "app/model/pricingTemplateModel";
import { Project } from "app/model/projectModel";
import { ProjectCategory } from "app/model/projectCategoryModel";
import { WorkAreaSpecs, WorksheetWorkArea } from "app/model/workAreaSpecsModel";
import { Router } from "@angular/router";
import { BaThemeSpinner } from "app/theme";
import { MessageService } from "primeng/primeng";
import {
  CreateEstimateWorksheetArgs,
  EstimateWorksheetInfo,
  WorksheetStatus,
} from "app/model/EstimateWorksheetInfo";
import { SharedService } from "app/common/utility/SharedService";
import { debounceTime, takeWhile } from "rxjs/operators";
import { ParameterFromDB } from "app/model/ParameterOnProject";
import { MaterialCalculator } from "app/model/MaterialsCalculator";
import { ParametersService } from "app/common/services/parameters.service";
import { MaterialCalculatorService } from "app/common/services/material-calculator.service";
import { forkJoin } from "rxjs";
import { CalculatorsParametersService } from "app/common/services/calculators-parameters.service";
import * as _ from "lodash";
import { BaseFormula } from "app/common/utility/BaseFormula";
import { WorkAreasService } from "../../common/services/workareas.service";
import { TaxingAuthoritiesService } from "../../common/services/taxing-authorities.service";
import { TaxingAuthority } from "../../model/TaxingAuthority";
import { TaxingAuthoritiesHelperService } from "app/common/services/taxing-authorities-helper.service";
import { ParameterGroupService } from "app/common/services/parameter-group.service";
import { MatStep, MatStepper } from "@angular/material/stepper";
import { DrawScheduleService } from "app/common/services/drawSchedule.service";
import { DrawScheduleTemplate } from "app/model/drawScheduleTemplate";
import { EstimateJobService } from "app/common/services/estimate-job.service";
import { CurrencyPipe } from "@angular/common";
import { ValueTypeEnum } from "app/model/Parameter";
import { conditionalRequired } from "app/common/validators/validate-conditional-required";

@Component({
  selector: "app-estimate-wizard",
  templateUrl: "./estimate-wizard-modal.component.html",
  styleUrls: ["./estimate-wizard-modal.scss"],
})
export class EstimateWizardModalComponent
  extends BaseFormula
  implements OnInit, OnDestroy
{
  @ViewChild("stepper", { static: false }) stepper: MatStepper;
  projectTypeForm!: FormGroup;
  ProjectSubtypeForm!: FormGroup;
  pricingTempleteForm!: FormGroup;
  worksheetWorkAreasForm!: FormGroup;
  worksheetParameterGroups!: FormGroup;
  workareasForm!: FormGroup;
  estimateInfo!: FormGroup;
  projectDetailForm!: FormGroup;

  taxForm!: FormGroup;

  projects: Project[] = [];
  private allProjects: Project[] = [];
  projectsSubType: ProjectCategory[] = [];
  private allProjectsSubType: ProjectCategory[] = [];
  princingTemplate: PrincigTemplate[] = [];
  private allPrincingTemplate: PrincigTemplate[] = [];
  workAreaSpecs: WorkAreaSpecs[] = [];
  private allWorkAreaSpecs: WorkAreaSpecs[] = [];

  jobName: string;
  jobId: string;
  loading = true;
  chargingInfo = true;
  submitted = false;
  parametersFormSubmitted = false;
  estimateWorksheetId: string;
  private customerGuid: string;
  alive: boolean;
  parameters: ParameterFromDB[];
  calculators: MaterialCalculator[];
  isValidFormula: boolean = true;
  workAreasForm: FormGroup;
  selectedWorkAreaSpecs: WorkAreaSpecs[] = [];
  taxingAuthorities: TaxingAuthority[] = [];
  private allTaxingAuthorities: TaxingAuthority[] = [];
  parameterGroups: ParameterGroupComplex[] = [];
  drawSchedules: DrawScheduleTemplate[] = [];
  originalDrawSchedules: DrawScheduleTemplate[] = [];
  customerId: number;
  markupPercentage: number;
  isHomebuilderSelected: boolean;
  parameterValueTypes = ValueTypeEnum;
  estimateNameMaxLength = 50;
  estimateNameSuffix = " - New Estimate";

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private wizardService: WizardService,
    public dialogRef: MatDialogRef<EstimateWizardModalComponent>,
    private _spinner: BaThemeSpinner,
    private notificationsService: MessageService,
    private sharedService: SharedService,
    private parametersService: ParametersService,
    public calculatorService: MaterialCalculatorService,
    public calculatorParametersService: CalculatorsParametersService,
    private workAreasService: WorkAreasService,
    private taxAuthService: TaxingAuthoritiesService,
    private taxAuthHelperService: TaxingAuthoritiesHelperService,
    private cdref: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    data: {
      jobId: string;
      jobName: string;
      customerGuid: string;
      markupPercentage: number;
      isHomebuilderSelected: boolean;
    },
    private parameterGroupService: ParameterGroupService,
    private drawScheduleService: DrawScheduleService,
    private estimateJobService: EstimateJobService,
    private currencyPipe: CurrencyPipe
  ) {
    super(calculatorParametersService, calculatorService);
    this.jobName = data.jobName;
    this.jobId = data.jobId;
    this.customerGuid = data.customerGuid;
    this.markupPercentage = data.markupPercentage;
    this.isHomebuilderSelected = data.isHomebuilderSelected;
  }

  ngOnInit(): void {
    this.alive = true;
    this.getCustomerByJobId();
    this.getProjects();
    this.getCalculatorsData();
    this.buildForms();
    this.buildWorkAreasForm();
    this.subscribeOnTaxValueChange();
    this.getTaxAuthorities();
    this.getDrawSchedules();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  private getCalculatorsData() {
    //this._spinner.show();
    const orgGuid = this.sharedService.selectedOrganization.ID;
    const requests = [
      this.parametersService.getParameterList(orgGuid),
      this.calculatorService.getMaterialCalculators(orgGuid),
      this.parameterGroupService.getParameterGroupsByOrganization(orgGuid),
    ];
    forkJoin(requests)
      .subscribe(
        (data) => {
          this.parameters = data[0] as unknown as ParameterFromDB[];
          this.calculators = data[1] as unknown as MaterialCalculator[];
          this.parameterGroups = data[2] as unknown as ParameterGroupComplex[];
          this.calculatorParametersService.init(
            this.parameters,
            this.calculators
          );
          this.getWorkAreas();
        },
        (error) => this.error(error, "Error querying data.")
      )
      .add(() => this._spinner.hide());
  }

  private buildForms() {
    const availableLenght =
      this.estimateNameMaxLength - this.estimateNameSuffix.length;
    const truncatedJobName =
      this.jobName.length > availableLenght
        ? this.jobName.substring(0, availableLenght)
        : this.jobName;

    const estimateName = `${truncatedJobName}${this.estimateNameSuffix}`;

    this.projectTypeForm = this.fb.group({
      projectType: ["", Validators.required],
    });
    this.ProjectSubtypeForm = this.fb.group({
      projectSubType: ["", Validators.required],
    });
    this.pricingTempleteForm = this.fb.group({
      pricingTemplete: ["", Validators.required],
    });
    this.workareasForm = this.fb.group({
      workareas: [""],
    });
    this.taxForm = this.fb.group({
      estimateName: [estimateName],
      orgTaxAuthorityId: [null, Validators.required],
      taxRate: [],
      percentage: [
        { value: null, disabled: true },
        [Validators.required, Validators.min(0)],
      ],
    });
    this.estimateInfo = this.fb.group({
      estimateName: [estimateName],
      orgTaxAuthorityId: [null, Validators.required],
      taxRate: [],
      customerName: [null, Validators.required],
      customerEmail: [null, [Validators.required, Validators.email]],
      drawScheduleId: [null],
      includeOnProposal: [false],
      includeOnContract: [false],
    });

    this.projectDetailForm = this.fb.group({
      projectTypeForm: this.projectTypeForm,
      ProjectSubtypeForm: this.ProjectSubtypeForm,
      pricingTempleteForm: this.pricingTempleteForm,
      workareasForm: this.workareasForm,
    });
  }

  private configForm(model: WorkAreaSpecs[]): void {
    const worksheetWorkArea = [];
    const worksheetWorkAreaTest = [];
    let workAreaParameterGroups = [];
    model.forEach((element) => {
      const worksheetWorkAreaParameterGroups = [];

      const parameterGroups = element.parameterGroups;
      parameterGroups.forEach((x) => {
        const worksheetWorkAreaParameters = [];
        const tempParams = x.parameters.filter((x) => {
          return !x.deletedOn;
        });

        x.parameters = tempParams;

        this.calculatorParametersService.init(
          x.parameters as unknown as ParameterFromDB[],
          this.calculators
        );

        x.parameters.sort((a, b) => {
          return a.orderView - b.orderView;
        });

        if (x.parameters.length === 0) {
          return;
        }
        x.parameters.forEach((y) => {
          let param = this.parameters.find((z) => z.id === y.id);
          if (!param) {
            console.log(y.id, y.parameterName);
          }
          y.entryType = param.entryType;
          y.valueType = param.valueType;
          // If parameter hasn't orphan child, It hasn't include at form.
          if (!this.calculatorParametersService.isParameterValid(y.id)) {
            return;
          }
          if (!this.calculatorParametersService.isParameterValid(y.id)) {
            return;
          }
          let formula = null;
          if (y.entryType) {
            const calc = this.calculators.find((ca) => ca.id === y.entryType);
            if (calc) {
              formula = calc.formula;
            }
          }
          worksheetWorkAreaParameters.push(
            this.fb.group({
              parameterId: [y.id, []],
              value: [
                {
                  value: y.entryType ? 0 : null,
                  disabled: !!y.entryType,
                },
                [
                  conditionalRequired(
                    y.valueType == this.parameterValueTypes.Number
                  ),
                ],
              ],
              textValue: [
                null,
                [
                  conditionalRequired(
                    y.valueType == this.parameterValueTypes.Text ||
                      y.valueType == this.parameterValueTypes.DropDownNumeric ||
                      y.valueType == this.parameterValueTypes.DropDownText
                  ),
                ],
              ],
              booleanValue: [
                null,
                [
                  conditionalRequired(
                    y.valueType == this.parameterValueTypes.YesNo
                  ),
                ],
              ],
              formula: [formula],
              calculatorId: [y.entryType],
              valueType: [y.valueType],
              parameterName: [y.parameterName],
              isCalculating: [false],
              parameterGroupId: [x.id],
              errorMsg: [],
              canShow: [y.canShow],
              visibleParameterGroupId: [y.visibleParameterGroupId],
            })
          );
        });

        if (worksheetWorkAreaParameters.length > 0) {
          const control = this.fb.group(
            {
              estimateWorksheetId: [null, []],
              workAreaId: [element.id, [Validators.required]],
              parameterGroupName: x.description,
              worksheetWorkAreaParameters: this.fb.array(
                worksheetWorkAreaParameters
              ),
            },
            { updateOn: "blur" }
          );

          worksheetWorkAreaParameterGroups.push(control);
        }
      });

      const paramGroup = this.fb.group({
        parameterGroups: this.fb.array(worksheetWorkAreaParameterGroups),
      });
      workAreaParameterGroups.push(paramGroup);
    });

    this.worksheetWorkAreasForm = this.fb.group({
      worksheetWorkAreas: this.fb.array(workAreaParameterGroups),
    });

    // Calculating new values for parameters with calculators
    // This considers changes in the whole workareas to send one time all the parameters
    this.worksheetWorkAreasForm.valueChanges
      .pipe(
        debounceTime(1000),
        takeWhile(() => this.alive)
      )
      .subscribe(() => {
        model.forEach((x, index, array) => {
          const control = this.worksheetWorkAreasForm
            .get("worksheetWorkAreas")
            .get(`${index}`);

          // Reseting error variable
          this.isValidFormula = true;

          let params = [];
          x.parameterGroups.forEach((z) => {
            params = params.concat(z.parameters);
          });

          this.setValuesToHiddenFields(control);

          (control.get("parameterGroups") as FormArray).controls.forEach(
            (y) => {
              (
                y.get("worksheetWorkAreaParameters") as FormArray
              ).controls.forEach((val) => {
                const errorMessage = this.setGeneralErrMsgs(val as FormGroup);
                val
                  .get("errorMsg")
                  .setValue(errorMessage, { emitEvent: false });

                if (val.value && val.value.calculatorId) {
                  this.setParams(val, y, null, params);
                }
              });
            }
          );
        });
      });
  }

  async generateWorksheet() {
    this.submitted = true;

    const formulaCheck = await this.checkFormulas(this.workAreaSpecs);

    if (!this.worksheetWorkAreasForm.valid || !formulaCheck) {
      return;
    }

    this._spinner.show();
    const estimateWorksheet: CreateEstimateWorksheetArgs = {
      jobGuid: this.jobId,
      estimateName: this.estimateInfo.controls["estimateName"].value,
      projectId: this.projectTypeForm.controls["projectType"].value,
      projectCategoryId:
        this.ProjectSubtypeForm.controls["projectSubType"].value,
      projectPackageId:
        this.pricingTempleteForm.controls["pricingTemplete"].value,
      customerId: 1, // se envia (1) y en el backend se resuelve con customerGuid
      estimateOwnerId: this.sharedService.user.id,
      orgGuid: this.sharedService.selectedOrganization.ID,
      worksheetStatus: WorksheetStatus.Deleted,
      markupPercentage: this.markupPercentage,
      markupAmount: 0,
      orgTaxAuthorityId: this.estimateInfo.get("orgTaxAuthorityId").value,
      taxRate: this.estimateInfo.get("taxRate").value,
      customerGuid: this.customerGuid,
      guid: null,
      locked: false,
      margin: 0,
      drawScheduleId: this.estimateInfo.get("drawScheduleId").value,
      includeOnProposal: this.estimateInfo.get("includeOnProposal").value,
      includeOnContract: this.estimateInfo.get("includeOnContract").value,
      isHomeBuilderMarkup: this.isHomebuilderSelected ? true : false,
    };

    const customerId = this.customerId;
    const customerEmail = this.estimateInfo.controls["customerEmail"].value;

    this.estimateWorksheetId = "0";
    if (
      this.worksheetWorkAreasForm.getRawValue().worksheetWorkAreas.length > 0
    ) {
      let workAreas =
        this.worksheetWorkAreasForm.getRawValue().worksheetWorkAreas;
      let workAreasToSend: WorksheetWorkArea[] = [];
      workAreas.forEach((element) => {
        if (element.parameterGroups && element.parameterGroups.length) {
          let workArea: WorksheetWorkArea = {} as WorksheetWorkArea;
          element.parameterGroups.forEach((param, i) => {
            if (i == 0) {
              workArea.workAreaId = param.workAreaId;
              workArea.estimateWorksheetId = 0;
              workArea.worksheetWorkAreaParameters =
                param.worksheetWorkAreaParameters;
            } else {
              workArea.worksheetWorkAreaParameters =
                workArea.worksheetWorkAreaParameters.concat(
                  param.worksheetWorkAreaParameters
                );
            }
          });
          workAreasToSend.push(workArea);
        }
      });

      estimateWorksheet.workAreas = this.mapping(workAreasToSend);
    }

    this.wizardService
      .AddEstimate(estimateWorksheet, customerId, customerEmail)
      .subscribe(
        (newEstimate: EstimateWorksheetInfo) => {
          this.submitted = false;
          this.estimateWorksheetId = newEstimate.guid;
          this.closeAndRedirect();
        },
        (error) => {
          this.error(error, "Error saving estimate worksheet");
          this._spinner.hide();
        }
      );
  }

  private AssignEstimateWorksheetId() {
    //assign Id Estimate to the form
    this.getWorksheetWorkAreasFormArray(this.worksheetWorkAreasForm).forEach(
      (key) => {
        key.controls["estimateWorksheetId"].setValue(this.estimateWorksheetId);
      }
    );
  }

  private closeAndRedirect() {
    this.router.navigate([
      `/pages/estimate/worksheet/${this.estimateWorksheetId}`,
      { fromWizard: true },
    ]);
    this.close();
  }

  getWorksheetWorkAreasFormArray(form) {
    //fix

    return form.controls.worksheetWorkAreas.controls;
  }

  getWorksheetWorkAreaParametersGroup(form) {
    return form.controls.parameterGroups.controls;
  }

  getWorksheetWorkAreaParametersFormArray(form) {
    //fix
    return form.controls.worksheetWorkAreaParameters.controls;
  }

  private getProjects() {
    this.wizardService.getProjects().subscribe((projects) => {
      this.loading = false;
      this.projects = projects;
      this.allProjects = projects;
    });
  }

  public changeProject(project: Project) {
    if (!project) {
      this.projectTypeForm.controls["projectType"].setValue(null);
      this.ProjectSubtypeForm.controls["projectSubType"].setValue(null);
      this.pricingTempleteForm.controls["pricingTemplete"].setValue(null);
      this.workareasForm.controls.workareas.setValue([]);
      this.changeWorkAreaSpecs([]);
      return;
    }
    this._spinner.show();
    this.wizardService.getProjectSubType(project.id).subscribe((pst) => {
      this.projectsSubType = pst;
      this.allProjectsSubType = pst;
      if (this.projectsSubType.length == 1) {
        this.ProjectSubtypeForm.controls["projectSubType"].setValue(
          this.projectsSubType[0].id
        );
        this.changeProjectSubType(this.projectsSubType[0]);
      } else {
        this.ProjectSubtypeForm.controls["projectSubType"].setValue(null);
        this.pricingTempleteForm.controls["pricingTemplete"].setValue(null);
      }
      this._spinner.hide();
    });
  }

  public changeProjectSubType(projectSubType: ProjectCategory) {
    if (!projectSubType) {
      this.ProjectSubtypeForm.controls["projectSubType"].setValue(null);
      this.pricingTempleteForm.controls["pricingTemplete"].setValue(null);
      this.workareasForm.controls.workareas.setValue([]);
      this.changeWorkAreaSpecs([]);
      return;
    }

    this._spinner.show();
    this.wizardService
      .getPricingTemplate(projectSubType.id)
      .subscribe((pricingTemp) => {
        pricingTemp.forEach((x) => {
          const packageCost = this.currencyPipe.transform(
            x.packageCost,
            "USD",
            "symbol",
            "1.2-2"
          );
          x.packageName = `${x.packageName} -- (${packageCost})`;
        });
        this.princingTemplate = pricingTemp;
        this.allPrincingTemplate = pricingTemp;

        if (this.princingTemplate.length == 1) {
          this.pricingTempleteForm.controls["pricingTemplete"].setValue(
            this.princingTemplate[0].id
          );
          this.changePricingTemplate(this.princingTemplate[0]);
        } else {
          this.pricingTempleteForm.controls["pricingTemplete"].setValue(null);
        }
        this._spinner.hide();
      });
  }

  private buildWorkAreasForm() {
    this.workAreasForm = this.fb.group({
      workAreas: new FormArray([]),
    });
    this.subscribeWorkAreasForm();
  }

  private handleWorkAreasForm() {
    this.buildWorkAreasCheckboxes();
  }

  get workAreasFormArray(): FormArray {
    return this.workAreasForm.controls.workAreas as FormArray;
  }

  private buildWorkAreasCheckboxes() {
    this.workAreaSpecs.forEach((wa) =>
      this.workAreasFormArray.push(new FormControl(false))
    );
  }

  private subscribeWorkAreasForm() {
    this.workAreasForm
      .get("workAreas")
      .valueChanges.pipe(takeWhile(() => this.alive))
      .subscribe((values: boolean[]) => {
        const specs = this.workAreaSpecs;
        const selectedWorkAreasIds = values.reduce(function (
          prev: number[],
          curr: boolean,
          currIndex: number
        ) {
          if (curr) {
            prev.push(specs[currIndex].id);
          }
          return prev;
        },
        []);

        const selectedWorkAreas = this.workAreaSpecs.filter((x) =>
          selectedWorkAreasIds.includes(x.id)
        );

        this.selectedWorkAreaSpecs = selectedWorkAreas;

        this.configForm(selectedWorkAreas);
      });
  }

  /** Triggers request to API to get active taxing authorities */
  private getTaxAuthorities() {
    //this._spinner.show();
    this.taxAuthService
      .getAll(this.sharedService.selectedOrganization.ID, true)
      .subscribe(
        (data) => {
          data.forEach((x) => {
            x.label = `${x.name} (${x.percentage}%)`;
          });
          this.allTaxingAuthorities = data;
          this.taxingAuthorities = [...this.allTaxingAuthorities];
          // automatically select when there's only one taxing authority
          if (this.taxingAuthorities.length == 1) {
            this.estimateInfo.patchValue({
              orgTaxAuthorityId: this.taxingAuthorities[0].id,
            });
            this.onTaxAuthorityChange(this.taxingAuthorities[0]);
          }
        },
        (error) => this.error(error, "An error has ocurred")
      )
      .add(() => this._spinner.hide());
  }

  /** Filters taxing authorities by text */
  filterTaxAuthorities(filter: string): void {
    this.taxingAuthorities = this.taxAuthHelperService.filterByName(
      filter,
      this.allTaxingAuthorities
    );
  }

  public close() {
    this.dialogRef.close();
  }
  private error(error: any, message: string) {
    console.error(error);
    this.notificationsService.add({
      severity: "error",
      summary: "Estimate Wizard",
      detail: message,
    });
  }

  mapping(value: WorksheetWorkArea[]): WorksheetWorkArea[] {
    value.forEach((x) => {
      if (x.worksheetWorkAreaParameters.length > 0) {
        x.worksheetWorkAreaParameters.forEach((y) => {
          if (!y.value) y.value = 0;
        });
      }
    });
    return value;
  }

  private getWorkAreas(): void {
    this.workAreasService
      .getActiveWorkAreaSpecs(this.sharedService.selectedOrganization.ID)
      .subscribe((data) => {
        this.workAreaSpecs = data;
        this.allWorkAreaSpecs = data;
        this.configForm(data);
        this.chargingInfo = false;

        this.handleWorkAreasForm();
      });
  }

  /** Subscription to handle form changes on tax authority change */
  private subscribeOnTaxValueChange(): void {
    this.taxForm
      .get("orgTaxAuthorityId")
      .valueChanges.pipe(takeWhile(() => this.alive))
      .subscribe((val: number) => {
        this.taxAuthHelperService.handleFormSubscription(
          this.allTaxingAuthorities,
          this.taxForm,
          val
        );
      });
  }

  getBackgroundImage(url) {
    if (url) {
      return { "background-image": `url(${url})` };
    }
    return {};
  }

  setValuesToHiddenFields(control: AbstractControl) {
    const parameterToSet = new Map<number, number>();

    (control.get("parameterGroups") as FormArray).controls.forEach((y) => {
      (y.get("worksheetWorkAreaParameters") as FormArray).controls.forEach(
        (val) => {
          let param = val as FormGroup;
          const canShow = param.get("canShow").value;
          const visibleParameter = param.get("visibleParameterGroupId").value;

          if (canShow && visibleParameter > 0) {
            parameterToSet.set(
              param.get("parameterId").value,
              param.get("value").value
            );
          }
        }
      );
    });

    (control.get("parameterGroups") as FormArray).controls.forEach((y) => {
      (y.get("worksheetWorkAreaParameters") as FormArray).controls.forEach(
        (val) => {
          let param = val as FormGroup;
          const canShow = param.get("canShow").value;
          const visibleParameter = param.get("visibleParameterGroupId").value;
          if (!canShow && visibleParameter > 0) {
            const parameterId = param.get("parameterId").value;
            const valueToSet = parameterToSet.get(parameterId);
            param.get("value").setValue(valueToSet, { emitEvent: false });
          }
        }
      );
    });
  }

  /**
   * method to got to the next step of the wizard depending on the number of projects and project subtypes
   */
  nextStepEstimateInfo(): void {
    if (!this.estimateInfo.valid) {
      this.submitted = true;
      return;
    }
    if (this.projects.length != 1 || this.projects[0].categories.length != 1)
      this.stepper.next();
    if (this.projects.length == 1 && this.projects[0].categories.length == 1) {
      this.projectTypeForm.controls["projectType"].setValue(
        this.projects[0].id
      );
      this.ProjectSubtypeForm.controls["projectSubType"].setValue(
        this.projects[0].categories[0].id
      );
      const projectTypeId = this.projectTypeForm.controls["projectType"].value;
      this.changeProject(projectTypeId);
      const projectSubtypeId =
        this.ProjectSubtypeForm.controls["projectSubType"].value;
      this.changeProjectSubType(projectSubtypeId);
      if (this.estimateInfo.valid) this.goToStep(3);
    } else if (
      this.projects.length == 1 &&
      this.projects[0].categories.length != 1
    ) {
      this.projectTypeForm.controls["projectType"].setValue(
        this.projects[0].id
      );
    } else if (
      this.projects.length != 1 &&
      this.projects[0].categories.length == 1
    ) {
      this.ProjectSubtypeForm.controls["projectSubType"].setValue(
        this.projects[0].categories[0].id
      );
    }
    this.submitted = false;
  }

  /**
   * Method to go to the indexof the step
   * @param stepIndex step index to go
   */
  goToStep(stepIndex: number): void {
    const currentStepIndex = this.stepper.selectedIndex;

    while (this.stepper.selectedIndex < stepIndex) {
      this.stepper.next();
    }

    while (this.stepper.selectedIndex > stepIndex) {
      this.stepper.previous();
    }
  }

  /**
   * Method to get the draw schedules
   */
  getDrawSchedules(): void {
    this.drawScheduleService
      .getDrawSchedules(this.sharedService.selectedOrganization.ID)
      .subscribe((data) => {
        this.drawSchedules = data;
        this.originalDrawSchedules = data;
      });
  }

  /**
   * Method to filter the draw schedules
   * @param filter text to filter the draw schedules
   */
  filterDrawScheduleList(filter: any): void {
    this.drawSchedules = this.originalDrawSchedules.filter(
      (s) => s.ScheduleName.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }

  /**
   * Method to get the customer by job id
   */
  getCustomerByJobId(): void {
    this.estimateJobService.getCustomerByJobId(this.jobId).subscribe((data) => {
      this.estimateInfo.controls["customerName"].setValue(
        `${data.firstName} ${data.lastName}`
      );
      this.estimateInfo.controls["customerEmail"].setValue(`${data.email}`);
      this.customerId = data.id;
      this.estimateInfo.updateValueAndValidity();
    });
  }

  /**
   * Method to set the tax rate
   * @param taxAuthority tax authority to set the tax rate
   */
  onTaxAuthorityChange(taxAuthority: TaxingAuthority) {
    if (taxAuthority)
      this.estimateInfo.controls["taxRate"].setValue(taxAuthority.taxRate);
  }

  /**
   * Method to filter the projects in the dropdown
   * @param filter text to filter the projects
   */
  filterProjects(filter: string): void {
    this.projects = this.allProjects.filter(
      (s) => s.projectName.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }

  /**
   * Method to filter the project subtypes in the dropdown
   * @param filter text to filter the project subtypes
   */
  filterSubProjects(filter: string): void {
    this.projectsSubType = this.allProjectsSubType.filter(
      (s) => s.categoryName.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }

  /**
   * Method to filter the pricing templates in the dropdown
   * @param filter text to filter the pricing templates
   */
  filterPricingTemplates(filter: string): void {
    this.princingTemplate = this.allPrincingTemplate.filter(
      (s) => s.packageName.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }

  /**
   * Method to filter the work areas in the dropdown
   * @param filter text to filter the work areas
   */
  filterWorkAreaSpecs(filter: string): void {
    this.workAreaSpecs = this.allWorkAreaSpecs.filter(
      (s) => s.workAreaName.toLowerCase().indexOf(filter.toLowerCase()) !== -1
    );
  }

  /**
   * Method triggered when the user selects a work area
   * @param workAreaSpecs list of work areas selected
   */
  changeWorkAreaSpecs(workAreaSpecs: WorkAreaSpecs[]): void {
    this.selectedWorkAreaSpecs = workAreaSpecs;

    workAreaSpecs.forEach(function (x) {
      x.parameterGroups = x.parameterGroups.filter(
        (y) => y.parameters.length > 0
      );
    });

    this.configForm(workAreaSpecs);
  }

  /**
   * Method to go to Work Area Specs step
   */
  goToWorkAreaSpecs(): void {
    if (!this.projectDetailForm.valid) {
      this.submitted = true;
      return;
    }
    this.submitted = false;
    this.goToStep(2);
  }

  /**
   * Method to go to Project Details step
   */
  nextStepProjectDetails(): void {
    if (!this.estimateInfo.valid) {
      this.submitted = true;
      return;
    }
    if (this.projects.length == 1) {
      this.projectTypeForm.controls["projectType"].setValue(
        this.projects[0].id
      );
      this.changeProject(this.projects[0]);
    }

    if (this.estimateInfo.valid) this.goToStep(1);
  }

  getOptions(
    valueType: ValueTypeEnum,
    customValue: string
  ): { value: any; label: string }[] {
    let options: { value: any; label: string }[] = [
      { value: null, label: "Select..." },
    ];

    if (valueType === this.parameterValueTypes.YesNo) {
      options.push({ value: true, label: "Yes" });
      options.push({ value: false, label: "No" });
    } else if (
      valueType === this.parameterValueTypes.DropDownNumeric ||
      valueType === this.parameterValueTypes.DropDownText
    ) {
      let customValues = customValue.split(",").map((value) => value.trim());
      customValues.forEach((value) => {
        options.push({ value, label: value });
      });
    }

    return options;
  }

  getFormControlName(valueType: ValueTypeEnum): string {
    if (valueType === this.parameterValueTypes.YesNo) {
      return "booleanValue";
    } else if (
      valueType === this.parameterValueTypes.DropDownNumeric ||
      valueType === this.parameterValueTypes.DropDownText
    ) {
      return "textValue";
    }
    return "";
  }

  changePricingTemplate(event: PrincigTemplate) {
    if (!event) {
      this.workareasForm.controls.workareas.setValue([]);
      this.changeWorkAreaSpecs([]);
      return;
    }

    var workareas = this.workAreaSpecs.filter((x) =>
      event.workAreasIds.includes(x.id)
    );

    if (workareas.length > 0) {
      this.workareasForm.controls.workareas.setValue(workareas);
      this.changeWorkAreaSpecs(workareas);
    } else {
      this.workareasForm.controls.workareas.setValue([]);
      this.changeWorkAreaSpecs([]);
    }
  }
}
