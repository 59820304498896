<div class="modal-dialog modal-sm">
  <div class="modal-content">
    <div class="modal-header">
      <button type="button" class="close" (click)="close()">&times;</button>
      <h4 class="modal-title">{{ title }}</h4>
    </div>
    <div class="modal-body">
      <div class="row p2row-bg">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="form-group">
            <ejs-radiobutton
              label="This Event"
              value="single"
              [(ngModel)]="Scm.selectedOption"
            >
            </ejs-radiobutton>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="form-group">
            <ejs-radiobutton
              label="All Event"
              value="all"
              [(ngModel)]="Scm.selectedOption"
            ></ejs-radiobutton>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn darkbtn dpbtn" (click)="Confirm()">
        Update
      </button>
      <button type="button" class="btn btn-default" (click)="close()">
        Cancel
      </button>
    </div>
  </div>
</div>
