import { Injectable } from "@angular/core";
import { HttpService } from "../../../app/common/utility/http.service";
import { environmentConstant } from "../../../app/common/utility/environment";
import {
  OrganizationModel,
  BusinessUnitModel,
} from "../../../app/model/organizationModel";
import { OrgCameraModel } from "app/model/orgCameraModel";
import { Observable } from "rxjs";
import { CameraOpCodeModel } from "app/model/cameraOpCode";
import { BlobUploadmodel } from "app/model/BlobUpoadModel";
import { OData } from "app/model/OData";
// tslint:disable:prefer-template
@Injectable()
export class OrganizationService {
  constructor(private httpService: HttpService) {}

  getOrganizations(): Observable<OrganizationModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getOrganizationUrl
    );
  }
  getOrganizationsLookUp(): Observable<OrganizationModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getOrganizationLookUp
    );
  }

  getOrganizationsWithFilter(filter): Observable<OrganizationModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.getOrganizationUrl + "&filter=" + filter
    );
  }
  getOrganizationsViewWithFilter(filter): any {
    return this.httpService.getRequestService(
      environmentConstant.getOrganizationViewUrl + "&searchByName=" + filter
    );
  }

  getOrganisationsWithViewFilterOData(
    filter: any
  ): Observable<OData<OrganizationModel>> {
    return this.httpService.getOData(`OrganizationViews?${filter}`);
  }

  getOrganizationById(id: string): Observable<OrganizationModel> {
    return this.httpService.getRequestService(
      environmentConstant.getOrganizationByIdUrl + id
    );
  }
  updateOrganization(organization: OrganizationModel) {
    return this.httpService.putRequestService(
      environmentConstant.updateOrganization,
      organization
    );
  }
  saveOrganization(organization: OrganizationModel) {
    return this.httpService.postRequestService(
      environmentConstant.saveOrganization,
      organization
    );
  }
  deleteOrganization(id: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.deleteOrganization + id
    );
  }
  getParentChildOrgs(id: any) {
    return this.httpService.getRequestService(
      environmentConstant.parentChildOrgs + id
    );
  }
  getOrganizationCameras(orgId: any): Observable<OrgCameraModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.organizationCameras + orgId + "/camera"
    );
  }
  AddOrganizationDefaultData(orgId: any) {
    return this.httpService.postRequestService(
      environmentConstant.saveOrganization + "/defaults/" + orgId,
      null
    );
  }
  getOrganizationCamerasv2(orgId: any): Observable<any[]> {
    return this.httpService.getRequestService(
      "v2/" + environmentConstant.organizationCameras + orgId + "/camera"
    );
  }
  getOrganizationCameraById(
    orgId: any,
    cameraId: any
  ): Observable<OrgCameraModel> {
    return this.httpService.getRequestService(
      environmentConstant.organizationCameras + orgId + "/camera/" + cameraId
    );
  }
  updateOrganizationCamera(orgCameraModel: OrgCameraModel, orgId: any) {
    return this.httpService.putRequestService(
      environmentConstant.updateOrganizationCamera + orgId + "/camera",
      orgCameraModel
    );
  }
  saveOrganizationCamera(orgCameraModel: OrgCameraModel, orgId: any) {
    return this.httpService.postRequestService(
      environmentConstant.saveOrganizationCamera + orgId + "/camera",
      orgCameraModel
    );
  }
  deleteOrganizationCamere(orgId: any, cameraId: any) {
    return this.httpService.deleteRequestService(
      environmentConstant.deleteOrganizationCamera +
        orgId +
        "/camera/" +
        cameraId
    );
  }
  getAvailableCameras(): Observable<OrgCameraModel[]> {
    return this.httpService.getRequestService(
      environmentConstant.availableCamere
    );
  }
  uploadLogo(orgId: any, formData: any): Observable<BlobUploadmodel> {
    return this.httpService.UploadRequestService(
      "organization/" + orgId,
      formData
    );
  }
  sendCameraControls(
    cantrolModel: CameraOpCodeModel
  ): Observable<CameraOpCodeModel> {
    return this.httpService.postRequestService("common/opcode", cantrolModel);
  }
  getAvailableCameraByOrg(orgId: any) {
    return this.httpService.getRequestService(
      environmentConstant.availableCameraByOrg + orgId + "/availablecamera"
    );
  }
  getCameraLogfilesByCameraID(cameraId: any, currnetPage: any, pageSize: any) {
    return this.httpService.getRequestService(
      environmentConstant.getCameraLogFilesByCameraID +
        cameraId +
        "/logs?page=" +
        currnetPage +
        "&pageSize=" +
        pageSize
    );
  }

  getBusinessUnitById(id: string): Observable<BusinessUnitModel> {
    return this.httpService.getRequestService("businessunit/" + id);
  }
  updateBusinesUnit(businessUnit: BusinessUnitModel) {
    return this.httpService.putRequestService("businessunit", businessUnit);
  }
  saveBusinesUnit(buinsessUnit: BusinessUnitModel) {
    return this.httpService.postRequestService("businessunit", buinsessUnit);
  }
  deleteBusinesUnit(id: BusinessUnitModel) {
    return this.httpService.deleteRequestService("businessunit/" + id);
  }

  getCameraOpCodeById(cameraId: number, opCode: number): Observable<any> {
    const code = opCode ? `?opCode=${opCode}` : "";
    return this.httpService.getRequestService(
      `common/opcode/${cameraId}${code}`
    );
  }
}
