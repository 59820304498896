<div class="row chat-top-bar">
  <div class="chat-search">
    <label for=""><i class="fa fa-search" aria-hidden="true"></i></label>
    <input type="text" [(ngModel)]="search" placeholder="Search contacts..." />
  </div>
</div>

<div class="row chat-contacts">
  <mat-accordion multi="true" togglePosition="before">
    <mat-expansion-panel expanded="true" #channelPanel>
      <mat-expansion-panel-header (click)="expandPanel(channelPanel, $event)">
        <div class="row">
          <div class="col-12">
            <h3>Channels</h3>
          </div>
        </div>
        <button
          *ngIf="!isLoading && user?.isAdmin"
          class="chat-add"
          (click)="onAddGroup(true)"
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
      </mat-expansion-panel-header>
      <p class="chat-loading" *ngIf="isLoading || items?.length < 1">
        {{ isLoading ? "Loading..." : "No groups" }}
      </p>
      <ng-container
        *ngFor="let item of items"
        [ngTemplateOutlet]="tpl"
        [ngTemplateOutletContext]="{ $implicit: item, system: true }"
      ></ng-container>
    </mat-expansion-panel>

    <mat-expansion-panel expanded="true" #messagePanel>
      <mat-expansion-panel-header (click)="expandPanel(messagePanel, $event)">
        <div class="row">
          <div class="col-12">
            <h3>Messages</h3>
          </div>
        </div>
        <button
          *ngIf="!isLoading && user?.isEmployee"
          class="chat-add"
          (click)="onAddGroup(false)"
        >
          <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
      </mat-expansion-panel-header>
      <p class="chat-loading" *ngIf="isLoading || items?.length < 1">
        {{ isLoading ? "Loading..." : "No groups" }}
      </p>
      <ng-container
        *ngFor="let item of items | filterGroups: search"
        [ngTemplateOutlet]="tpl"
        [ngTemplateOutletContext]="{ $implicit: item, system: false }"
      ></ng-container>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<ng-template #tpl let-item let-system="system">
  <div
    *ngIf="(system && item.system) || (!system && !item.system)"
    class="row chat-contact"
    [class.chat-selected]="item.selected"
  >
    <div class="chat-group-selector" (click)="onGroupSelected(item)"></div>
    <div class="col-2 chat-user">
      <ng-container *ngIf="!system">
        <p *ngIf="item.users?.length > 2">
          <i class="fa fa-users" aria-hidden="true"></i>
        </p>
        <p
          *ngIf="item.users?.length <= 2"
          [ngClass]="(item | userActivity: user) ? 'active' : 'inactive'"
        >
          {{ item?.users | groupIcon: user }}
        </p>
      </ng-container>
    </div>
    <div class="col-9 chat-name">
      <p>{{ item | groupName: user }}</p>
    </div>
    <div class="col-1">
      <p *ngIf="item.unreadCount > 0" class="chat-notification">
        {{ item.unreadCount }}
      </p>
      <p
        *ngIf="!isLoading && ((user?.isEmployee && !system) || user?.isAdmin)"
        class="chat-edit"
        (click)="onEditGroup(item)"
      >
        <i class="fa fa-pencil" aria-hidden="true"></i>
      </p>
    </div>
  </div>
</ng-template>
