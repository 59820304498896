import { takeWhile, first } from "rxjs/operators";
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { BaThemeSpinner } from "app/theme/services";
import { MessageService } from "primeng/components/common/messageservice";
import { NotificationService } from "../../common/services/notifications.service";
import { Observable } from "rxjs";
import { IntervalObservable } from "rxjs/observable/IntervalObservable";
import { environmentConstant } from "app/common/utility/environment";
import { SharedService } from "app/common/utility/SharedService";
import { HttpService } from "app/common/utility/http.service";

@Component({
  selector: "notifications",
  templateUrl: "./notifications.html",
  styleUrls: ["./notifications.css"],
})
export class NotificationsComponent {
  notificationData: any = [];
  visibleSidebar: boolean = false;
  private alive: boolean;
  openMessageItem: boolean = false;
  notificationItem: any;
  display: boolean;
  unViewedItems: any;
  viewedFlag: boolean = false;

  constructor(
    private _spinner: BaThemeSpinner,
    private notificationsService: MessageService,
    private notificationService: NotificationService,
    private sharedService: SharedService,
    private httpService: HttpService
  ) {
    this.display = false;
    this.alive = true;
  }
  ngOnInit() {
    // get our data immediately when the component inits
    this.notificationService
      .getNotifications(true)
      .pipe(first()) // only gets fired once
      .subscribe((data) => {
        this.unViewedItems = data.UnReadNotification;
        this.notificationData = data.Notifications;
        this.display = true;
      });

    // get our data every subsequent 20 seconds
    // IntervalObservable.create(20000)
    //   .pipe(takeWhile(() => this.alive)) // only fires when component is alive
    //   .subscribe(() => {
    //     this.notificationService.getNotifications(true).subscribe((data) => {
    //       this.unViewedItems = data.UnReadNotification;
    //       this.notificationData = data.Notifications;
    //     });
    //   });
  }
  openMessage(Item) {
    this.viewedFlag = true;
    this.openMessageItem = true;
    if (!Item.Viewed) {
      this.notificationService.viewNotification(Item.Id).subscribe(() => {
        if (this.unViewedItems != 0 && !Item.Viewed) {
          this.unViewedItems = this.unViewedItems - 1;
          Item.Viewed = true;
        }
      });
    }
    this.notificationItem = Item;
  }
  closeMsg() {
    this.openMessageItem = false;
  }

  getNotifications(TakeTop25: boolean) {
    return this.httpService.getRequestServiceNoError(
      environmentConstant.notification +
        this.sharedService.loggedInUser.Id +
        "?TakeTop25=" +
        TakeTop25
    );
  }
  onHideSliderHide(e) {
    this.getNotifications(true);
    this.notificationService.getNotifications(true).subscribe((result) => {
      this.notificationData = result.Notifications;
      this.unViewedItems = result.UnReadNotification;
    });
    this.visibleSidebar = false;
    this.openMessageItem = false;
  }

  deleteNotification(id) {
    if (this.notificationItem == id) this.openMessageItem = false;
    this.notificationService.deleteNotification(id).subscribe(() => {
      const index: number = this.notificationData.findIndex((x) => x.Id === id);
      if (index !== -1) {
        this.notificationData.splice(index, 1);
      }
    });
  }
  show() {
    this.visibleSidebar = true;
  }
  clearAllNotifications() {
    this.visibleSidebar = false;
    this.openMessageItem = false;
    this.notificationService.clearAllNotification().subscribe(() => {
      this.notificationData = [];
      this.unViewedItems = 0;
    });
  }
}
